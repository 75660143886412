import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { Button, EButtonType, Input, UserBadge } from '@dealsyte/poki';

import { UPDATE_PROFILE } from 'api/graphql/users/mutations';
import {
  UPDATE_PROFILE_MUTATION,
  UPDATE_PROFILE_MUTATIONVariables,
} from 'api/graphql/users/types/UPDATE_PROFILE_MUTATION';
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { EButtonMessages } from 'app/core-tools/due-diligence/types/types';
import { userAccountSettingsValidationSchema } from 'app/auth/HelpersAuth';
import { getError } from 'app/utils/helpers/helpers';

import { useTouchedForm } from 'app/utils/hooks/useTouchedForm';
import { ESettingsAccountLabels } from '../../TypedUsersSettingsModal';
import {
  BodySectionContainer,
  DisplayName,
  InputContainer,
  UserBadgerContainer,
  SubmitButtonsContainer,
  Text,
  ErrorMessage,
} from '../../StyledUsersSettingsModal';

import { IProps } from './TypedUserAccountSettings';

function UserAccountSettings({ user }: IProps) {
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);
  const { setIsTouched } = useTouchedForm();

  const [updateProfileMutation] = useMutation<
    UPDATE_PROFILE_MUTATION,
    UPDATE_PROFILE_MUTATIONVariables
  >(UPDATE_PROFILE, {
    onError(error) {
      showErrorToast({ title: getError(error) });
    },
    onCompleted() {
      showSuccessToast({
        title: ESettingsAccountLabels.SUCCESS_UPDATE_PROFILE_MESSAGE,
      });
    },
  });

  return (
    <>
      <UserBadgerContainer>
        <UserBadge
          name={user.displayName}
          badgeStyle={{ width: '30px', height: '30px', fontSize: '14px' }}
          containerStyle={{ width: '30px', height: '30px' }}
        />

        <DisplayName>{user.displayName}</DisplayName>
      </UserBadgerContainer>

      <Formik
        validationSchema={userAccountSettingsValidationSchema}
        initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        }}
        onSubmit={({ firstName, lastName, email }) => {
          updateProfileMutation({
            variables: { newProfile: { firstName, lastName, email } },
          });
        }}
      >
        {({ handleChange: handle, handleBlur, values, handleSubmit, errors }) => {
          const handleChange = (e: React.ChangeEvent<any>) => {
            setIsTouched(true);
            handle(e);
          };
          return (
            <>
              <BodySectionContainer>
                <InputContainer>
                  <Text isSubTitle={false} style={{ color: '#000' }}>
                    {ESettingsAccountLabels.NAME}
                  </Text>

                  <Input
                    id="firstName"
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    data-testid="UsersSettingsAccountFirstNameInput"
                  />
                  <ErrorMessage>
                    {errors.firstName && (
                      <span data-testid="UsersSettingsAccountFirstNameInput">
                        {errors.firstName}
                      </span>
                    )}
                  </ErrorMessage>
                </InputContainer>

                <InputContainer>
                  <Text isSubTitle={false} style={{ color: '#000' }}>
                    {ESettingsAccountLabels.LAST_NAME}
                  </Text>

                  <Input
                    id="lastName"
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    data-testid="UsersSettingsAccountLastNameInput"
                  />

                  <ErrorMessage>
                    {errors.lastName && (
                      <span data-testid="UsersSettingsAccountLastNameInput">{errors.lastName}</span>
                    )}
                  </ErrorMessage>
                </InputContainer>
              </BodySectionContainer>

              <BodySectionContainer>
                <InputContainer>
                  <Text isSubTitle>{ESettingsAccountLabels.INFO_TITLE}</Text>

                  <Text isSubTitle={false}>
                    {ESettingsAccountLabels.INFO_BODY} {dayjs(user.createdAt).format('MM-DD-YYYY')}.
                  </Text>
                </InputContainer>
              </BodySectionContainer>

              <SubmitButtonsContainer>
                <Button
                  buttonType={EButtonType.primary}
                  disabled={
                    Object.keys(errors).length !== 0 ||
                    (values.firstName === user.firstName &&
                      values.lastName === user.lastName &&
                      values.email === user.email)
                  }
                  onClick={() => {
                    handleSubmit();
                    setIsTouched(false);
                  }}
                  data-testid="UsersSettingsAccountSaveChangesButton"
                >
                  {EButtonMessages.SAVE_ACTION}
                </Button>
              </SubmitButtonsContainer>
            </>
          );
        }}
      </Formik>
    </>
  );
}

export default UserAccountSettings;
