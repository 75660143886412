import styled from 'styled-components';
import { Button } from '@dealsyte/poki';
import Select from 'react-select';

export const SelectStyled = styled(Select)`
  .react-select__option:nth-child(3) {
    color: red;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EmailContainer = styled.div`
  color: ${({ theme }) => theme.palette.gray.darker};
  display: inline-block;
  margin-top: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 13px;
`;

export const AddNewMemberButton = styled(Button)`
  margin-top: 20px;
  width: 150px;
`;

export const UserRole = styled.p`
  padding-right: 8px;
  color: ${({ theme }) => theme.palette.gray.darker};
`;
