// vendors
import styled from 'styled-components';

// poki
import { Input } from '@dealsyte/poki';

// custom theme
import { darkGrayFont } from '../../../theme/customColors';

export const FormGroupWrapper = styled.div`
  label {
    line-height: 1rem;
  }
`;

export const RadioInput = styled(Input)`
  :focus,
  :active {
    outline: none;
    border: none;
    box-shadow: none;
  }
`;

export const RadioButton = styled.span`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;

export const RadioLabel = styled.label`
  cursor: pointer;
  margin: 0.25rem 3rem 0 0.25rem;
  color: ${darkGrayFont};
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

export const RadioRow = styled.div`
  display: flex;
  flex-direction: row;
`;
