import styled from 'styled-components';

export const Text = styled.div`
  ${({ theme }) => {
    const { colors, typography } = theme.foundations;
    return `
      color: ${colors.text.default};
      font-size: ${typography.sizes['03']};
      font-family: ${typography.fonts.body};
      text-align: justify;
      text-justify: inter-word;
      margin-bottom: 18px;    
    `;
  }}
`;

export const Label = styled.div`
  ${({ theme }) => {
    const { colors, typography } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['03']};
      color: ${colors.text.default};
      margin-bottom: 24px;
      text-align: justify;
      text-justify: inter-word;
`;
  }}
`;
