import React from 'react';

import EngagementLetterForm from 'app/compliance/components/forms/EngagementLetter/EngagementLetter';

import { Button, HStack, SideDrawer } from 'app/common/components';
import {
  useEngagementLetterHandler,
  EngagementLetterHandlerProvider,
} from './EngagementLetterHandler';

const EngagementLetterFormDrawer = () => {
  const handler = useEngagementLetterHandler();
  const { onSubmitEngagementLetterForm, submitting, form, modal } = handler;
  const { hide, modalState } = modal;
  const { isValid } = form;

  const onClose = () => {
    form.resetForm();
    hide();
  };

  return (
    <EngagementLetterHandlerProvider value={handler}>
      <SideDrawer
        show={modalState.show}
        title="Client engagement"
        onClose={onClose}
        promptOnClose={form.dirty}
        footerContent={({ onClose: closeWithPrompt }) => (
          <HStack justifyContent="flex-end" spacing={16}>
            <Button onClick={closeWithPrompt} className="tertiary">
              Dismiss
            </Button>
            <Button
              style={{ width: 176 }}
              disabled={!isValid || submitting}
              onClick={onSubmitEngagementLetterForm}
            >
              Submit
            </Button>
          </HStack>
        )}
      >
        <EngagementLetterForm />
      </SideDrawer>
    </EngagementLetterHandlerProvider>
  );
};

export default EngagementLetterFormDrawer;
