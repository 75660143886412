import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

// Styled components
import { CreditCardInputWrapper } from './CreditCardInputStyled';

const CARD_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      iconColor: '#47455F',
      color: '#6A678E',
      fontWeight: 400,
      fontSize: '16px',
      letterSpacing: '0.25px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#6A678E',
      },
      '::placeholder': {
        color: '#6A678E',
      },
      fontFamily: 'Effra, Helvetica Neue, Helvetica, Arial, sans-serif',
    },
    invalid: {
      iconColor: '#47455F',
      color: '#47455F',
    },
  },
};

const CardField = ({ onChange }: any) => (
  <CreditCardInputWrapper>
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </CreditCardInputWrapper>
);

interface CreditCardInputProps {
  setCardError: Function;
  setCardComplete: Function;
}

export const CreditCardInput = ({ setCardError, setCardComplete }: CreditCardInputProps) => (
  <div className="AppWrapper">
    <CardField
      onChange={(e: {
        error: React.SetStateAction<null>;
        complete: boolean | ((prevState: boolean) => boolean);
      }) => {
        setCardError(e.error);
        setCardComplete(e.complete);
      }}
    />
  </div>
);
