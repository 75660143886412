import { CardBody, CardFooter } from 'app/common/components';
import styled from 'styled-components';

export const GridItem = styled.div`
  width: 100%;
  height: 100%;
`;

export const Subtitle = styled.p`
  ${({ theme }) => {
    const { typography, colors, spacing } = theme.foundations;
    return `
        font-family: ${typography.fonts.body};
        font-size: ${typography.sizes['02']};
        font-weight: ${typography.weights.regular};
        color: ${colors.palette.neutral[600]};
        margin-bottom: ${spacing['02']};
        margin-top: ${spacing['01']};
        letter-spacing: 0.25px;
        line-height: 150%;
        width: 100%;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;

        &.outline {
          color: ${colors.palette.neutral[500]}
        }
  `;
  }}
`;

export const Title = styled.p`
  ${({ theme }) => {
    const { typography, colors } = theme.foundations;
    return `
        font-family: ${typography.fonts.body};
        font-size: ${typography.sizes['06']};
        font-weight: ${typography.weights.bold};
        letter-spacing: 0.25px;
        line-height: 125%;
        color: ${colors.primary.default};
        letter-spacing: 0.25px;
        flex: none;
        order: 0;
        flex-grow: 0;

        &.outline {
          color: ${colors.palette.neutral[500]}
        }
    `;
  }}
`;

export const CardActionPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardIconContainer = styled.div`
  ${({ theme }) => {
    const { sizes, radii, colors } = theme.foundations;
    return `
        width: ${sizes['08']};
        height: ${sizes['08']};
        background-color: ${colors.palette.purple[100]};
        border-radius: ${radii.xl};
        display: flex; 
        justify-content: center;
        align-items: center;

        &.outline {
            background-color: transparent;
            border: 1px solid ${colors.primary.default};
        }
    `;
  }}
`;

export const CardPlusButton = styled.button`
  ${({ theme }) => {
    const { typography, sizes, radii, colors } = theme.foundations;
    return `
        font-family: ${typography.fonts.body};
        width: ${sizes['07']};
        height: ${sizes['07']};
        border-radius: ${radii.md};
        border: 1px solid ${colors.primary.default};
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: transparent;

        &.default:hover  {
          border: 0px;
          background-color: ${colors.palette.neutral[200]}
        }

        &.outline {
          color: ${colors.palette.neutral[400]}
          border: 1px solid ${colors.palette.neutral[400]};
          cursor: default;
        }
        &:disabled {
          border-color: ${colors.palette.neutral[400]};
          color: ${colors.palette.neutral[400]};
          cursor: default;
          &:hover {
            border: 1px solid ${colors.palette.neutral[400]};
            background-color: transparent;
          }
        }
    `;
  }}
`;

export const CompleteButton = styled.p`
  ${({ theme }) => {
    const { typography, colors } = theme.foundations;
    return `
        font-family: ${typography.fonts.body};
        font-size: ${typography.sizes['03']};
        font-weight: ${typography.weights.regular};
        letter-spacing: 0.25px;
        line-height: 125%;
        color: ${colors.primary.default};
        letter-spacing: 0.25px;
        flex: none;
        order: 0;
        flex-grow: 0;        
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.25px;
        flex: none;
        order: 0;
        flex-grow: 0;
        cursor: pointer;

        &.complete {
            color: ${colors.palette.blue[700]};
        }
        &.view {
            color: ${colors.palette.neutral[600]}
        }
    `;
  }}
`;

export const CardBodyStyled = styled(CardBody)`
  flex: 1;
  padding: 8px 24px 24px 24px;
  min-height: 124px;
`;

export const CardFooterStyled = styled(CardFooter)`
  min-height: 57px;
`;

export const BoldText = styled.span`
  font-weight: 600;
  font-size: 16px;
`;
