import * as React from 'react';
import { IconProps } from './types';

export const ChatBubbleIcon = ({
  width = '14',
  height = '15',
  color = 'currentColor',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.66683 6.83435H3.66683C3.49002 6.83435 3.32045 6.90459 3.19543 7.02961C3.0704 7.15464 3.00016 7.32421 3.00016 7.50102C3.00016 7.67783 3.0704 7.8474 3.19543 7.97242C3.32045 8.09745 3.49002 8.16768 3.66683 8.16768H7.66683C7.84364 8.16768 8.01321 8.09745 8.13823 7.97242C8.26326 7.8474 8.3335 7.67783 8.3335 7.50102C8.3335 7.32421 8.26326 7.15464 8.13823 7.02961C8.01321 6.90459 7.84364 6.83435 7.66683 6.83435ZM10.3335 4.16768H3.66683C3.49002 4.16768 3.32045 4.23792 3.19543 4.36295C3.0704 4.48797 3.00016 4.65754 3.00016 4.83435C3.00016 5.01116 3.0704 5.18073 3.19543 5.30576C3.32045 5.43078 3.49002 5.50102 3.66683 5.50102H10.3335C10.5103 5.50102 10.6799 5.43078 10.8049 5.30576C10.9299 5.18073 11.0002 5.01116 11.0002 4.83435C11.0002 4.65754 10.9299 4.48797 10.8049 4.36295C10.6799 4.23792 10.5103 4.16768 10.3335 4.16768ZM11.6668 0.834351H2.3335C1.80306 0.834351 1.29436 1.04506 0.919283 1.42014C0.54421 1.79521 0.333496 2.30392 0.333496 2.83435V9.50102C0.333496 10.0315 0.54421 10.5402 0.919283 10.9152C1.29436 11.2903 1.80306 11.501 2.3335 11.501H10.0602L12.5268 13.9743C12.5891 14.0361 12.663 14.085 12.7442 14.1182C12.8254 14.1514 12.9124 14.1682 13.0002 14.1677C13.0876 14.1699 13.1744 14.1517 13.2535 14.1144C13.3752 14.0643 13.4795 13.9794 13.553 13.8703C13.6266 13.7611 13.6662 13.6326 13.6668 13.501V2.83435C13.6668 2.30392 13.4561 1.79521 13.081 1.42014C12.706 1.04506 12.1973 0.834351 11.6668 0.834351ZM12.3335 11.8944L10.8068 10.361C10.7445 10.2992 10.6707 10.2503 10.5894 10.2172C10.5082 10.184 10.4212 10.1672 10.3335 10.1677H2.3335C2.15669 10.1677 1.98712 10.0974 1.86209 9.97242C1.73707 9.8474 1.66683 9.67783 1.66683 9.50102V2.83435C1.66683 2.65754 1.73707 2.48797 1.86209 2.36295C1.98712 2.23792 2.15669 2.16768 2.3335 2.16768H11.6668C11.8436 2.16768 12.0132 2.23792 12.1382 2.36295C12.2633 2.48797 12.3335 2.65754 12.3335 2.83435V11.8944Z"
      fill={color}
    />
  </svg>
);
