import _get from 'lodash/get';
import { DEAL_FORM_LABELS, DEAL_TYPES_LABELS } from 'app/utils/helpers/deal';
import { FormType } from 'types/graphql-types';
import { EngagementLetterForm, SelectValue } from './EngagementLetterHandler';
import { FormikStub } from 'app/utils/hooks/useForm';

export const buildELForm = (form: FormikStub<EngagementLetterForm>, dealId: string) => {
  const { values, touched } = form;
  const mapYesNoToString = (value: boolean | undefined): string => (value ? 'Yes' : 'No');
  const {
    projectName,
    name,
    type,
    companyName,
    headquarterAddress,
    country,
    state,
    zipCode,
    industryType,
    industryTypeSubOptions,
    publiclyTradedCompany,
    ticker,
    note,
    files,
    isInitialDueDiligence,
    initialDueDiligenceNote,
    isAnyRedFlag,
    anyRedFlagNote,
    isPotentialConflicts,
    potentialConflictsNote,
    targetInvestors,
    foreignSolicitation,
    foreignSolicitationIntitutionalClients,
    foreignSolicitationPreexistingRelationship,
    foreignSolicitationCountries,
    conclusions,
  } = values;

  const mapCountries = (countries: SelectValue[]) => {
    if (countries.length > 1) {
      return countries.reduce((previousValue, currentValue, currentIndex) => {
        if (currentIndex === countries.length - 1) {
          return `${previousValue} and ${currentValue.value}`;
        }
        return `${previousValue} ${currentValue.value},`;
      }, '');
    }
    return countries[0].value;
  };

  const variables = {
    type: FormType.EL,
    dealId,
    content: [
      {
        question: DEAL_FORM_LABELS.PROJECT_NAME,
        input: projectName || '',
      },
      {
        question: DEAL_FORM_LABELS.DEAL_NAME,
        input: name || '',
      },
      {
        question: DEAL_FORM_LABELS.DEAL_TYPE,
        input: DEAL_TYPES_LABELS[type.value as keyof typeof DEAL_TYPES_LABELS],
      },
      {
        question: DEAL_FORM_LABELS.CLIENT_NAME,
        input: companyName || '',
      },
      {
        question: DEAL_FORM_LABELS.CLIENT_ADDRESS,
        input: headquarterAddress || '',
      },
      {
        question: DEAL_FORM_LABELS.COUNTRY,
        input: country || '',
      },
      {
        question: DEAL_FORM_LABELS.STATE,
        input: state || '',
      },
      {
        question: DEAL_FORM_LABELS.ZIP_CODE,
        input: zipCode || '',
      },
      {
        question: DEAL_FORM_LABELS.INDUSTRY_TYPE,
        input: _get(industryType, 'value', ''),
      },
      {
        question: DEAL_FORM_LABELS.INDUSTRY_SUB_TYPE,
        input: _get(industryTypeSubOptions, 'value', ''),
      },
      {
        question: DEAL_FORM_LABELS.PUBLICLY_TRADED_LABEL,
        input: touched.publiclyTradedCompany ? mapYesNoToString(publiclyTradedCompany) : '',
      },
    ],
    note: note,
    files,
  };

  if (publiclyTradedCompany) {
    variables.content.push({
      question: DEAL_FORM_LABELS.TICKER_EXCHANGE_LABEL,
      input: ticker,
    });
  }
  variables.content.push({
    question: DEAL_FORM_LABELS.DUE_DILIGENCE_LABEL,
    input: touched.isInitialDueDiligence ? mapYesNoToString(isInitialDueDiligence) : '',
  });
  if (isInitialDueDiligence) {
    variables.content.push({
      question: DEAL_FORM_LABELS.DUE_DILIGENCE_NOTE_LABEL,
      input: initialDueDiligenceNote,
    });
  }
  variables.content.push({
    question: DEAL_FORM_LABELS.RED_FLAG_LABEL,
    input: touched.isAnyRedFlag ? mapYesNoToString(isAnyRedFlag) : '',
  });
  if (isAnyRedFlag) {
    variables.content.push({
      question: DEAL_FORM_LABELS.RED_FLAG_NOTE_LABEL,
      input: anyRedFlagNote,
    });
  }
  variables.content.push({
    question: DEAL_FORM_LABELS.POTENTIAL_CONFLICTS_LABEL,
    input: touched.isPotentialConflicts ? mapYesNoToString(isPotentialConflicts) : '',
  });
  if (isPotentialConflicts) {
    variables.content.push({
      question: DEAL_FORM_LABELS.POTENTIAL_CONFLICTS_NOTE_LABEL,
      input: potentialConflictsNote,
    });
  }
  variables.content.push({
    question: DEAL_FORM_LABELS.TARGET_INVESTORS,
    input: _get(targetInvestors, 'value', ''),
  });
  variables.content.push({
    question: DEAL_FORM_LABELS.FOREIGN_SOLICITATION_LABEL,
    input: touched.foreignSolicitation ? mapYesNoToString(foreignSolicitation) : '',
  });
  if (foreignSolicitation) {
    variables.content.push({
      question: DEAL_FORM_LABELS.FOREIGN_SOLICITATION_INSTITUTIONAL_CLIENTS,
      input: mapYesNoToString(foreignSolicitationIntitutionalClients),
    });
    variables.content.push({
      question: DEAL_FORM_LABELS.FOREIGN_SOLICITATION_PREEXISTING_RELATIONSHIP,
      input: foreignSolicitationPreexistingRelationship,
    });
    variables.content.push({
      question: DEAL_FORM_LABELS.FOREIGN_SOLICITATION_COUNTRIES_LABEL,
      input: mapCountries(foreignSolicitationCountries || []),
    });
  }
  variables.content.push({
    question: DEAL_FORM_LABELS.CONCLUSION_LABEL,
    input: conclusions || '',
  });
  return variables;
};
