import React, { useState } from 'react';
import { useStoredState } from './useStoredState';

export type StateTypes = 'default' | 'stored' | 'custom';
export interface SwappedStatesConfig<T = any> {
  initialValues: T;
  customState?: any;
  storedStateKey?: string;
  storedStateConfig?: any;
}

export function useSwappedStates<T = any>(
  type: StateTypes,
  config: SwappedStatesConfig<T>
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const { customState = (iValues: T) => [iValues, () => {}] } = config;
  const [state, setState] = useState<T>(config.initialValues);
  const [storedState, setStoredState] = useStoredState(
    config.storedStateKey || `stored_default_key`,
    type === 'stored' ? config.initialValues : ('' as any),
    config.storedStateConfig
  );
  const [customStateValue, setCustomState] = customState(config.initialValues);

  if (type === 'stored') {
    return [storedState, setStoredState];
  }
  if (type === 'custom') {
    return [customStateValue, setCustomState] as [T, React.Dispatch<React.SetStateAction<T>>];
  }
  return [state, setState];
}
