import styled from 'styled-components';

const MarketplaceDealDetails = styled.div`
  ${({ theme }) => {
    const { colors, spacing, typography, radii } = theme.foundations;
    return `
      background-color: ${colors.palette.neutral['100']};
      border-radius: ${radii.md};
      margin-top: ${spacing['05']};
      
      .top-container {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: space-between;
        padding: ${spacing['05']};
        font-family: ${typography.fonts.body};
        
        .right-container {
          padding-right: ${spacing['12']};
        }
        
        .title {
          color: ${colors.palette.neutral['500']};
          font-weight: ${typography.weights.regular}
          font-size: ${typography.sizes['02']}
          line-height: 20px;
          margin: 0;
        }
        
        .value {
          margin: ${spacing['02']} 0 0;
          color: ${colors.palette.neutral['800']};
          font-weight: ${typography.weights.medium}
          font-size: ${typography.sizes['03']}
          
          .icon-container {
            display: inline-block;
            margin-right: ${spacing['02']}
          }
        }
      }
      
      .line {
        border: 0.5px solid ${colors.palette.neutral['300']};;
      }
      
      .bottom-container {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: space-between;
        padding: ${spacing['05']};
        align-items: center;
        font-family: ${typography.fonts.body};
        
        .left-container {
          display: flex;
          
          .icon-container {
            margin-top: ${[spacing['01']]};
            margin-right: ${spacing['02']};
          }
          
          .description {
            width: 327px;
            font-size: ${typography.sizes['02']};
            font-weight: ${typography.weights.regular};
            color: ${colors.palette.neutral['500']};
            display: inline-block;
          }
        }
      }
    `;
  }}
`;

export default MarketplaceDealDetails;
