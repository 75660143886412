import styled from 'styled-components';

export const CheckboxTextContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const CheckboxTextLabel = styled.span`
  ${({ theme }) => {
    const { colors, typography, spacing } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['03']};
      color: ${colors.text.default};
      margin-left: ${spacing['03']};
      text-align: justify;
      line-height: 24px;
    `;
  }};
`;

export const Description = styled.p`
  ${({ theme }) => {
    const { colors, typography, spacing } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['04']};
      letter-spacing: 0.25px;
      line-height: 24px;
      color: ${colors.text.default};
      margin-bottom: ${spacing['08']};

      strong {
        font-weight: ${typography.weights.medium};
      }
    `;
  }}
`;
