export const REQUIRED_MESSAGE = 'This field is required';
export const PROHIBITED_CHARS_MESSAGE = 'Valid characters include A-z, 0-9, and / ( ) & _ - , .';
export const BOTH_FIELDS_REQUIRED_MESSAGE =
  'Both fields need to be completed; or you may opt to leave both blank.';
export const MAX_PERCENT_MESSAGE = 'The percentage value cannot exceed 100%.';
export const MAX_LOWER_THAN_MIN_MESSAGE = 'The max must be greater than the min';
export const MAX_DEAL_SIZE_REACHED_MESSAGE =
  'The expected deal size cannot exceed $999,999,999,999,999';
export const MIN_VALUE_CANNOT_BE_MESSAGE = 'Value cannot be 0.';
export const MIN_DEAL_LENGTH_DESCRIPTION =
  'This is a required field—with the deal description at least 100 characters in length.';
export const MIN_DEAL_SIZE = 'Minimum amount for deal size is $100,000.';
