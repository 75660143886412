import * as React from 'react';
import { IconProps } from './types';

export const PaperPlaneIcon = ({
  width = '14px',
  height = '14px',
  color = 'currentColor',
  style = {},
}: IconProps) => (
  <svg
    width={width}
    height={height}
    style={style}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5602 5.21407L3.22683 0.547403C2.85849 0.364076 2.44258 0.29881 2.03579 0.360504C1.629 0.422198 1.25114 0.60785 0.953719 0.892147C0.656301 1.17644 0.4538 1.54555 0.373827 1.94914C0.293853 2.35274 0.340299 2.77117 0.506832 3.1474L2.10683 6.7274C2.14314 6.81396 2.16183 6.90688 2.16183 7.00074C2.16183 7.0946 2.14314 7.18752 2.10683 7.27407L0.506832 10.8541C0.371298 11.1585 0.314002 11.4921 0.34015 11.8243C0.366297 12.1566 0.47506 12.477 0.656553 12.7565C0.838046 13.0361 1.08652 13.2658 1.37938 13.4249C1.67224 13.584 2.00022 13.6673 2.3335 13.6674C2.64565 13.6643 2.95316 13.5914 3.2335 13.4541L12.5668 8.7874C12.8979 8.62086 13.1762 8.36561 13.3706 8.05012C13.5651 7.73464 13.668 7.37133 13.668 7.00074C13.668 6.63014 13.5651 6.26684 13.3706 5.95135C13.1762 5.63587 12.8979 5.38061 12.5668 5.21407H12.5602ZM11.9668 7.59407L2.6335 12.2607C2.51094 12.3196 2.37332 12.3396 2.23909 12.318C2.10486 12.2964 1.98044 12.2343 1.88251 12.14C1.78458 12.0457 1.71782 11.9237 1.69119 11.7904C1.66455 11.657 1.67932 11.5188 1.7335 11.3941L3.32683 7.81407C3.34746 7.76626 3.36527 7.71729 3.38016 7.6674H7.9735C8.15031 7.6674 8.31988 7.59717 8.4449 7.47214C8.56993 7.34712 8.64016 7.17755 8.64016 7.00074C8.64016 6.82393 8.56993 6.65436 8.4449 6.52933C8.31988 6.40431 8.15031 6.33407 7.9735 6.33407H3.38016C3.36527 6.28418 3.34746 6.23521 3.32683 6.1874L1.7335 2.6074C1.67932 2.48271 1.66455 2.34444 1.69119 2.21112C1.71782 2.0778 1.78458 1.95581 1.88251 1.8615C1.98044 1.7672 2.10486 1.70509 2.23909 1.6835C2.37332 1.66192 2.51094 1.68189 2.6335 1.74074L11.9668 6.4074C12.076 6.46335 12.1677 6.54835 12.2317 6.65303C12.2957 6.75772 12.3295 6.87804 12.3295 7.00074C12.3295 7.12343 12.2957 7.24375 12.2317 7.34844C12.1677 7.45313 12.076 7.53812 11.9668 7.59407Z"
      fill={color}
    />
  </svg>
);
