import { StyleVal } from 'app/utils/types/types';
import { StackProps } from 'app/utils/interfaces/interfaces';

export const stackControl = ({
  justifyContent,
  alignItems,
  spacing = 0,
  direction = 'row',
}: StackProps) => {
  const setVal = (val: StyleVal): string => (typeof val === 'string' ? val : `${val}px`);
  return `
       ${justifyContent ? `justify-content: ${justifyContent};` : ''}
       ${alignItems ? `align-items: ${alignItems};` : ''}
       ${direction ? `flex-direction: ${direction};` : ''}
       & > * {
          ${
            direction === 'row'
              ? `margin-right: ${setVal(spacing)};`
              : `margin-bottom: ${setVal(spacing)};`
          }
          &:last-child {
            ${direction === 'row' ? `margin-right: 0;` : `margin-bottom: 0;`}
          }
       }
      `;
};
