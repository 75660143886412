import React, { useReducer, useContext, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Input, UsersIcon, EButtonType } from '@dealsyte/poki';
import { useMutation } from '@apollo/client';

// context
import { AlertContext } from 'app/notifications/context/Alert/Alert';

import { INVITE_USERS } from 'api/graphql/users/mutations';
import { GET_INVITATION_GROUPS } from 'api/graphql/users/queries';
import {
  INVITE_USERS_MUTATION,
  INVITE_USERS_MUTATIONVariables,
} from 'api/graphql/users/types/INVITE_USERS_MUTATION';
import {
  GET_INVITATION_GROUPS_QUERY,
  GET_INVITATION_GROUPS_QUERYVariables,
  GET_INVITATION_GROUPS_QUERY_groups_users,
} from 'api/graphql/users/types/GET_INVITATION_GROUPS_QUERY';
import { Column, Row } from '../StyledGroupsSettings';
import InviteUsersSelector from './InviteUsersSelector';
import { Role, DealSide } from 'app/core-tools/due-diligence/types/types';

import { IAction, Invitation, IProps, EOptionDescription } from './TypedNewDealMembersModal';
import { InviteUsersButton, Modal, BottomButton } from './StyledNewDealMembersModal';
import { getError } from 'app/utils/helpers/helpers';
import useGoogleAnalytics from 'app/utils/hooks/useGoogleAnalytics';
import { GA_ACTION, GA_CATEGORY } from 'app/utils/types/types';
import useGoogleAnalytics4 from 'app/utils/hooks/useGoogleAnalytics4';

const baseInvitation = {
  email: '',
  role: Role.USER,
  expiresAt: null,
  expired: false,
  invitationId: null,
};
const initialState = [{ ...baseInvitation }, { ...baseInvitation }, { ...baseInvitation }];

const OPTIONS = [
  {
    label: 'Member',
    displayLabel: 'Member',
    value: Role.USER,
    description: EOptionDescription.USER,
  },
  {
    label: 'Admin',
    displayLabel: 'Admin',
    value: Role.ADMIN,
    description: EOptionDescription.ADMIN,
  },
];

/**
 * Renders a modal to setup and trigger a new member invitation to a deal.
 * @param show Flag that indicates if the modal should or not be rendered.
 * @param setShow Function called when close modal btn is clicked, in order to set the -show- flag to false.
 * @param side Current user deal side.
 * @param organizationId Current user organization id.
 * @param organizationName Current user organization name.
 */
function NewDealMembersModal({
  dealId,
  show,
  setShow,
  side,
  organizationId,
  organizationName,
  refetchInvitations,
}: IProps) {
  const [invitations, dispatch] = useReducer((invitations: Invitation[], action: IAction) => {
    switch (action.type) {
      case 'add':
        return invitations.concat({ ...baseInvitation });
      case 'modify': {
        const invitationIndex = action.invitationIndex;
        const invitation = invitations[invitationIndex];
        return [
          ...invitations.slice(0, invitationIndex),
          { ...invitation, ...action.invitation },
          ...invitations.slice(invitationIndex + 1),
        ];
      }
      case 'remove': {
        const invitationIndex = action.invitationIndex;
        return [
          ...invitations.slice(0, invitationIndex),
          ...invitations.slice(invitationIndex + 1),
        ];
      }
      case 'reset': {
        return initialState;
      }
      default:
        return invitations;
    }
  }, initialState);

  const [invalidEmails, setInvalidEmails] = useState<Array<boolean>>([]);

  const { showErrorToast } = useContext(AlertContext);
  const { trackSingleEvent } = useGoogleAnalytics();
  const { trackSingleEvent: trackSingleEventG4 } = useGoogleAnalytics4();

  const regexEmail = /^(?!\.)(?!.*\.\.)[A-Za-z0-9._%+-]+(?!\.)@((?!-)[A-Za-z0-9-]+(?:\.[A-Za-z0-9-]+)*(?:\.[A-Za-z]{2,}))$/i; //eslint-disable-line
  const getInvitedUsers = () =>
    invitations.filter(
      (invitation: Invitation) =>
        invitation.email && regexEmail.test(String(invitation.email).toLowerCase())
    );

  const [inviteUsersMutation] = useMutation<INVITE_USERS_MUTATION, INVITE_USERS_MUTATIONVariables>(
    INVITE_USERS,
    {
      update(cache, { data }) {
        const usersWithInvitation = data
          ? data.inviteUsers
              .filter(invitation => invitation.invitationId)
              .map((invitation, index) => ({ ...invitation, id: `invitationId-${index}` }))
          : [];

        const registeredUsers = data ? data.inviteUsers.filter(invitation => invitation.id) : [];

        if (side === DealSide.SELL) {
          const query = cache.readQuery<
            GET_INVITATION_GROUPS_QUERY,
            GET_INVITATION_GROUPS_QUERYVariables
          >({
            query: GET_INVITATION_GROUPS,
            variables: { dealId, types: ['sellOrganizations' as any] },
          });

          const newGroups =
            (query &&
              query.groups &&
              query.groups.map(group => {
                if (!group) return group;

                if (group.id !== organizationId) return group;
                else {
                  return {
                    ...group,
                    invites: [...group.invites, ...usersWithInvitation],
                    users: [
                      ...group.users,
                      ...registeredUsers.map(usr => {
                        return { ...usr, side };
                      }),
                    ] as GET_INVITATION_GROUPS_QUERY_groups_users[],
                  };
                }
              })) ||
            [];

          cache.writeQuery<GET_INVITATION_GROUPS_QUERY, GET_INVITATION_GROUPS_QUERYVariables>({
            query: GET_INVITATION_GROUPS,
            variables: { dealId, types: ['sellOrganizations' as any] },
            data: {
              groups: newGroups,
            },
          });
        } else {
          const query = cache.readQuery<
            GET_INVITATION_GROUPS_QUERY,
            GET_INVITATION_GROUPS_QUERYVariables
          >({ query: GET_INVITATION_GROUPS, variables: { dealId, types: ['buy' as any] } });

          const newGroups =
            (query &&
              query.groups &&
              query.groups.map(group => {
                if (!group) return group;
                return {
                  ...group,
                  relatedGroups: {
                    __typename: 'RelatedGroups' as any,
                    children:
                      (group.relatedGroups &&
                        group.relatedGroups.children.map(child => {
                          if (child.id === organizationId)
                            return {
                              ...child,
                              invites: [...child.invites, ...usersWithInvitation],
                              users: [
                                ...group.users,
                                ...registeredUsers.map(usr => {
                                  return { ...usr, side };
                                }),
                              ] as GET_INVITATION_GROUPS_QUERY_groups_users[],
                            };
                          else return child;
                        })) ||
                      [],
                    parents: [],
                  },
                };
              })) ||
            [];

          cache.writeQuery<GET_INVITATION_GROUPS_QUERY, GET_INVITATION_GROUPS_QUERYVariables>({
            query: GET_INVITATION_GROUPS,
            variables: { dealId, types: ['buy' as any] },
            data: {
              groups: newGroups,
            },
          });
        }
      },
      onError(error) {
        showErrorToast({ title: getError(error) });
      },
      onCompleted(data) {
        data &&
          data.inviteUsers.forEach(user => {
            trackSingleEvent(GA_CATEGORY.VDR, GA_ACTION.INVITE_USER, '', {
              dimension1: user.email,
            });
            trackSingleEventG4(GA_CATEGORY.VDR, GA_ACTION.INVITE_USER);
          });
        refetchInvitations && refetchInvitations();
      },
    }
  );
  const resetAndClose = () => {
    setShow(false);
    dispatch({ type: 'reset' });
  };

  const isEmailInvalid = (index: number) => {
    return invalidEmails[index] || false;
  };

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    const hasEmails = invitations.some(user => !!user.email);
    const hasInvalids = !invalidEmails.length || invalidEmails.some(isInvalid => isInvalid);
    setIsSubmitButtonDisabled(!hasEmails || hasInvalids);
  }, invitations);

  return (
    <Modal style={{ padding: '30px 0 0 0', width: 440 }} show={show} onHide={() => setShow(false)}>
      <Column style={{ alignItems: 'center', width: '100%' }}>
        <UsersIcon />
        <div style={{ fontSize: 16, marginTop: 15 }}>Invite members</div>
        <div style={{ fontSize: 13, color: 'rgba(68,68,68,.7)', marginTop: 5, marginBottom: 35 }}>
          They will receive a link to sign up and join {organizationName}
        </div>
        <Column id="newUsersList" style={{ flex: 1, maxHeight: 300, overflowY: 'auto' }}>
          {invitations.map((invitation: Invitation, invitationIndex: number) => (
            <Row
              key={`${invitationIndex}-invitation`}
              style={{
                borderBottom: '1px solid rgba(222,224,226,.3)',
                marginTop: 17,
                width: 360,
                justifyContent: 'space-between',
              }}
            >
              <Input
                type="email"
                placeholder="Enter email address"
                value={invitation.email}
                error={isEmailInvalid(invitationIndex)}
                onChange={e => {
                  const newInvitations = [...invitations];
                  newInvitations[invitationIndex] = {
                    ...newInvitations[invitationIndex],
                    email: e.target.value,
                    role: newInvitations[invitationIndex].role,
                  };
                  dispatch({
                    type: 'modify',
                    invitationIndex,
                    invitation: { email: e.target.value },
                  });
                  const allInvalidEmails = invalidEmails;
                  allInvalidEmails[invitationIndex] =
                    !!e.target.value && !regexEmail.test(String(e.target.value).toLowerCase());
                  setInvalidEmails(allInvalidEmails);
                }}
                style={{ marginLeft: 1, flex: 1, marginRight: 15 }}
              />

              <InviteUsersSelector
                options={OPTIONS as any}
                value={invitation.role}
                onChange={(selectedOptionIndex: number) => {
                  dispatch({
                    type: 'modify',
                    invitationIndex,
                    invitation: { role: OPTIONS[selectedOptionIndex].value },
                  });
                }}
              />
            </Row>
          ))}
        </Column>
        <InviteUsersButton
          onClick={() => {
            dispatch({ type: 'add' });
            setTimeout(() => {
              const list = document.getElementById('newUsersList');
              if (list) list.scrollTop = list.scrollHeight;
            }, 100);
          }}
        >
          Add more people
        </InviteUsersButton>
        <Row style={{ width: '100%', height: 55 }}>
          <BottomButton
            onClick={() => resetAndClose()}
            style={{
              borderBottomLeftRadius: 3,
            }}
          >
            Cancel
          </BottomButton>
          <BottomButton
            disabled={isSubmitButtonDisabled}
            onClick={() => {
              resetAndClose();
              const users = getInvitedUsers().map(({ email, role }) => ({
                email,
                role,
                side,
                organizationId,
              }));

              inviteUsersMutation({
                variables: { users },
                optimisticResponse: {
                  inviteUsers: users.map(({ email, role }) => {
                    const expiresAt = dayjs().add(5, 'day');

                    return {
                      email,
                      role,
                      expiresAt,
                      invitationId: null,
                      expired: false,
                      displayName: '',
                      id: null,
                      __typename: 'InvitedUser',
                    };
                  }),
                },
              });
            }}
            style={{
              borderBottomRightRadius: 3,
            }}
            buttonType={EButtonType.primary}
          >
            Submit
          </BottomButton>
        </Row>
      </Column>
    </Modal>
  );
}

export default NewDealMembersModal;
