import * as React from 'react';
import { IconProps } from './types';

export const FilesIconAlt = ({
  width = '110px',
  height = '110px',
  color = '#2C6EF2',
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 115 129"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2636_75219)">
      <path
        d="M20 27.0233C20 20.9353 24.9353 16 31.0233 16H60.4186V31.6163H76.0349V83.9767C76.0349 90.0647 71.0996 95 65.0116 95H31.0232C24.9353 95 20 90.0647 20 83.9767V27.0233Z"
        fill="#FEFEFE"
      />
      <path d="M60.4189 16L67.7678 23.8081L76.0352 31.6163H60.4189V16Z" fill="#D8D7EA" />
      <rect x="30" y="51" width="36" height="6" rx="3" fill="#EDEDF7" />
      <rect x="30" y="75" width="36" height="6" rx="3" fill="#EDEDF7" />
      <rect x="30" y="39" width="22" height="6" rx="3" fill="#EDEDF7" />
      <rect x="30" y="63" width="22" height="6" rx="3" fill="#EDEDF7" />
    </g>
    <g filter="url(#filter1_d_2636_75219)">
      <path
        d="M38 37.0233C38 30.9353 42.9353 26 49.0233 26H78.4186V41.6163H94.0349V93.9767C94.0349 100.065 89.0996 105 83.0116 105H49.0232C42.9353 105 38 100.065 38 93.9767V37.0233Z"
        fill="#FEFEFE"
      />
      <path d="M78.4189 26L85.7678 33.8081L94.0352 41.6163H78.4189V26Z" fill="#D8D7EA" />
      <rect x="48" y="61" width="36" height="6" rx="3" fill="#EDEDF7" />
      <rect x="48" y="85" width="36" height="6" rx="3" fill="#EDEDF7" />
      <rect x="48" y="49" width="22" height="6" rx="3" fill="#EDEDF7" />
      <rect x="48" y="73" width="22" height="6" rx="3" fill="#EDEDF7" />
    </g>
    <defs>
      <filter
        id="filter0_d_2636_75219"
        x="0"
        y="0"
        width="96.0352"
        height="119"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_2636_75219"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="8" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.254902 0 0 0 0 0.305882 0 0 0 0 0.505882 0 0 0 0.1 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2636_75219" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2636_75219"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_2636_75219"
        x="18"
        y="10"
        width="96.0352"
        height="119"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_2636_75219"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="8" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.254902 0 0 0 0 0.305882 0 0 0 0 0.505882 0 0 0 0.1 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2636_75219" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2636_75219"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
