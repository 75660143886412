// vendors
import styled, { DefaultTheme } from 'styled-components';

export const TextArea = styled.textarea`
  ${({ theme, error }: { error?: boolean; theme: DefaultTheme }) => {
    const {
      foundations: { colors, radii, spacing, typography, shadows },
    } = theme;
    return `
        resize: none;
        border-radius: ${radii.md};
        min-height: 120px;
        border: 1px solid ${error ? colors.states.error : colors.outline.default};
        background: ${colors.background.primary};
        transition: box-shadow 300ms ease-out;
        font-family: ${typography.fonts.body};
        font-size: ${typography.sizes['04']};
        line-height: ${typography.sizes['06']};
        color: ${colors.text.default};
        padding: ${spacing['03']} ${spacing['03']};

        &::placeholder {
          color: ${colors.text.light};
        }

        &:disabled {
          color: ${colors.text.light};
          background: ${colors.background.secondary};
          border: 1px solid ${colors.background.other};
        }


        &.error {
          border: 1px solid ${colors.states.error};
        }

        &:disabled {
          background: ${colors.background.secondary};
          border: 1px solid ${colors.background.other};
        }

        &:focus {
            border: 1px solid ${colors.outline.dark};
            box-shadow: ${shadows['01']};
        }
      `;
  }}
`;
