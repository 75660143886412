import React from 'react';
import * as S from './StyledProgressBar';

type ProgressBarVariants = 'round' | 'accent';
export interface ProgressBarProps {
  percentage?: number;
  label?: string | React.ReactNode;
  showPercentage?: boolean;
  variant?: ProgressBarVariants | ProgressBarVariants[];
  style?: React.CSSProperties;
}

const resolveClassNames = (variant: ProgressBarVariants | ProgressBarVariants[] | undefined) => {
  if (Array.isArray(variant)) {
    return variant.join(' ');
  }
  return variant;
};

export const ProgressBar = ({
  label,
  showPercentage,
  percentage = 0,
  variant,
  style,
}: ProgressBarProps) => {
  return (
    <S.MainContainer style={style}>
      {label && <S.LabelContainer>{label}</S.LabelContainer>}
      <S.BarContainer>
        <S.Bar percentage={percentage} className={resolveClassNames(variant)} />
        {showPercentage && <S.PercentageLabel>{percentage}%</S.PercentageLabel>}
      </S.BarContainer>
    </S.MainContainer>
  );
};
