import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import dayjs from 'dayjs';
import { ThemeContext } from 'styled-components';
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { RESEND_INVITATION } from 'api/graphql/users/mutations';
import {
  RESEND_INVITATION_MUTATION,
  RESEND_INVITATION_MUTATIONVariables,
} from 'api/graphql/users/types/RESEND_INVITATION_MUTATION';
import { Column, SpaceBetweenRow, Container } from '../StyledGroupsSettings';
import { DealSide, Role, Sort, EGroupRightLabel } from 'app/core-tools/due-diligence/types/types';
import GroupMembersTable from '../GroupMembersTable/GroupMembersTable';
import { IGroup, IUserInvitation } from 'app/users/types/types';

function Members({
  group,
  side,
  refetchInvitations,
}: {
  group?: IGroup;
  side: DealSide;
  refetchInvitations: Function;
}) {
  const theme = useContext(ThemeContext);
  const { showSuccessToast, showErrorToast } = useContext(AlertContext);

  const [resendInvitationMutation, { loading: resendMutationLoading }] = useMutation<
    RESEND_INVITATION_MUTATION,
    RESEND_INVITATION_MUTATIONVariables
  >(RESEND_INVITATION, {
    onError() {
      showErrorToast({ title: 'The invitation could not be resent, please try again later.' });
    },
    onCompleted() {
      refetchInvitations();
      showSuccessToast({ title: 'The invitation has been sent successfully' });
    },
  });

  if (!group) return null;

  const users = get(group, 'users', []);

  const sortedUsers = [...users].sort((a, b) => {
    if (a.role === Role.ADMIN && b.role === Role.ADMIN) return Sort.EQUAL;
    if (a.role === Role.ADMIN) return Sort.A_GOES_FIRST;
    if (b.role === Role.ADMIN) return Sort.B_GOES_FIRST;
    return Sort.EQUAL;
  });

  const registeredUsers = sortedUsers.map(user => ({
    id: get(user, 'id'),
    name: get(user, 'displayName'),
    email: get(user, 'email'),
    role: get(user, 'role'),
  }));

  const pendingUsers = get(group, 'invites', []);

  return (
    <Container>
      <Column>
        <div style={{ fontWeight: 'bold', margin: '30px 0 20px 0' }}>Members</div>

        <GroupMembersTable
          groupName={get(group, 'name')}
          groupId={get(group, 'id')}
          side={side}
          leftLabel="User"
          rightLabel={EGroupRightLabel.ACCESS_LEVEL}
          members={registeredUsers as any}
          refetchInvitations={refetchInvitations}
        />

        <div style={{ fontWeight: 'bold', margin: '40px 0 20px' }}>Pending Invites</div>

        {pendingUsers.length === 0 ? (
          <p style={{ color: theme.palette.gray.darker, height: '15px', marginBottom: '50px' }}>
            No pending invites.
          </p>
        ) : (
          pendingUsers.map(
            ({ email, role, expired, expiresAt, invitationId }: IUserInvitation, index: number) => (
              <SpaceBetweenRow
                key={`${index}-pendingInvite`}
                style={{ marginBottom: index === pendingUsers.length - 1 ? 50 : 10 }}
              >
                <span style={{ color: theme.palette.gray.darker }}>
                  {email} <i>({role === Role.USER ? 'Member' : 'Admin'})</i>
                </span>
                <span style={{ color: theme.palette.gray.darker }}>
                  {expired ? (
                    <span>
                      Invitation expired (
                      <button
                        style={{ textDecoration: 'underline', color: theme.palette.blue.default }}
                        disabled={resendMutationLoading}
                        onClick={() => {
                          if (!resendMutationLoading) {
                            resendInvitationMutation({
                              variables: {
                                invitationId,
                              },
                            });
                          }
                        }}
                      >
                        Resend
                      </button>
                      )
                    </span>
                  ) : (
                    <span>{`Invitation expires on ${dayjs(expiresAt).format(
                      'MMM D, YYYY [at] HH:mm'
                    )}`}</span>
                  )}
                </span>
              </SpaceBetweenRow>
            )
          )
        )}
      </Column>
    </Container>
  );
}

export default Members;
