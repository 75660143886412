export function getSelectUserRoleCustomStyles(theme: any, isInviteSelector?: boolean) {
  return {
    //@ts-ignore
    control(base) {
      return {
        ...base,
        border: 'none',
        width: isInviteSelector ? '95px' : '200px',
        boxShadow: 'none',
      };
    },

    //@ts-ignore
    container(base) {
      return {
        ...base,
        borderStyle: 'none',
        fontSize: '12px',
        marginRight: '5px',
      };
    },

    //@ts-ignore
    valueContainer(base) {
      return {
        ...base,
        justifyContent: 'flex-end',
        padding: '2px 4px 2px 8px',
        color: theme.palette.gray.darker,
      };
    },

    //@ts-ignore
    singleValue(base) {
      return {
        ...base,
        color: theme.palette.gray.darker,
      };
    },

    //@ts-ignore
    dropdownIndicator(base) {
      return { ...base, padding: 0 };
    },

    //@ts-ignore
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#F9FAFB' : '#ffffff',
      color: theme.palette.gray.darker,
    }),

    //@ts-ignore
    indicatorSeparator() {
      return { display: 'none' };
    },
  };
}
