export const OBA_FORM_MESSAGES = {
  ENTITY: 'Entity name',
  START_DATE_LABEL: 'Start date',
  START_DATE_PLACEHOLDER: 'Select a date',
  INVESTMENT_LABEL: 'Is the business investment-related?',
  INVESTMENT_TOOLTIP:
    'Pertains to securities, commodities, banking, insurance, or real estate (including, but not limited to, acting as or being associated with a broker-dealer, issuer, investment company, investment adviser, futures sponsor, bank, or savings association)',
  POSITION_LABEL: 'Position, title or association',
  POSITION_PLACEHOLDER: 'Position / Title / Association',
  ADDRESS: 'Street address',
  CITY: 'City',
  STATE_PROVINCE: 'State / Province',
  POSTAL_ZIP: 'Postal / Zip Code',
  COUNTRY: 'Country',
  NATURE_OF_BUSINESS: 'Nature of business',
  WEBSITES_CONNECTED_LABEL: 'List the websites connected to the business or organization',
  WEBSITES_CONNECTED_PLACEHOLDER: 'https://my.finalis.com',
  DUTY_LABEL: 'Describe your duties in this OBA',
  DUTY_PLACEHOLDER: 'Description...',
  HOURS_AMOUNT_LABEL: 'Approximate hours/months devoted to this potential OBA',
  HOURS_AMOUNT_PLACEHOLDER: 'Approximate hours/months',
  HOURS_DEVOTED_LABEL: 'Number of hours you devoted during securities trading hours',
  HOURS_DEVOTED_PLACEHOLDER: 'Devoted hours',
  REASONABLE_COMPENSATION_LABEL:
    'Will you be compensated, or do you have the reasonable expectation of compensation, in connection with this OBA?',
  COMPENSATION_LABEL: 'What form of compensation will you receive?',
  CAPITAL_RAISE:
    'In the past or in the foreseeable future, do you see this translating into a capital raise/ securities transaction?',
  NOTES_LABEL: 'Do you want to add any comments / notes',
  NOTES_PLACEHOLDER: 'Comments...',
  OTHERS_COMPENSATION: 'Describe compensation...',
  OTHER_LICENSES_LABEL: 'Please provide other licenses besides securities license(s)',
  OTHER_LICENSES_PLACEHOLDER: 'Other licenses besides securities license(s)',
  OTHER_INFO_LABEL:
    "Provide any other information you believe would be helpful in the Firm's consideration of your request",
  OTHER_INFO_PLACEHOLDER: "Other helpful information in the Firm's consideration of your request",
};

export const QUESTION_LABELS = {
  ENTITY_NAME: 'Entity Name',
  START_DATE: 'Start Date',
  IS_BUSINESS_RELATED: 'Is the business investment-related?',
  POSITION: 'Position, Title or Association',
  STREET_ADDRESS: 'Street Address',
  CITY: 'City',
  STATE: 'State / Province',
  ZIP_CODE: 'Postal / Zip Code',
  COUNTRY: 'Country',
  NATURE_OF_BUSINESS: 'Nature of Business',
  DUTIES: 'Describe your duties in this OBA',
  MONTHLY_HOURS: 'On a monthly basis, approximate hours devoted to OBA',
  TRADING_HOURS: 'Number of hours you devoted during securities trading hours',
  WILL_BE_COMPENSATED:
    'Will you be compensated, or do you have the reasonable expectation of compensation, in connection with this OBA?',
  CAPITAL_RAISE_SECURITIES:
    'In the past or in the foreseeable future, do you see this translating into a capital raise/ securities transaction?',
  COMPENSATIONS: 'What form of compensation will you receive',
  OTHER_LICENSES: OBA_FORM_MESSAGES.OTHER_LICENSES_LABEL,
  WEBSITE: OBA_FORM_MESSAGES.WEBSITES_CONNECTED_LABEL,
  OTHER_INFO: OBA_FORM_MESSAGES.OTHER_INFO_LABEL,
};
