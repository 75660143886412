import gql from 'graphql-tag';

import { DealFragment } from './fragments';

export const CREATE_DEAL = gql`
  mutation CREATE_DEAL_MUTATION(
    $name: String!
    $type: DealType!
    $companyName: String!
    $companyAddress: String!
    $companyAgent: String
    $projectName: String!
    $dealStatus: String!
    $expectedCommissions: Int!
    $expectedSize: Float!
    $closingDate: Date!
    $loiProvided: Boolean
    $private: Boolean
    $firmId: String
    $metadata: [DealMetadataV2Input]
  ) {
    createDeal(
      name: $name
      type: $type
      companyName: $companyName
      companyAddress: $companyAddress
      companyAgent: $companyAgent
      projectName: $projectName
      dealStatus: $dealStatus
      expectedCommissions: $expectedCommissions
      expectedSize: $expectedSize
      closingDate: $closingDate
      loiProvided: $loiProvided
      private: $private
      firmId: $firmId
      metadata: $metadata
    ) {
      ...DealBasicFragment
    }
  }
  ${DealFragment}
`;

export const LOGIN_TO_DEAL = gql`
  mutation LOGIN_TO_DEAL_MUTATION($dealId: String!, $firmId: String) {
    loginToDeal(dealId: $dealId, firmId: $firmId) {
      token
    }
  }
`;

export const UPDATE_DEAL = gql`
  mutation UPDATE_DEAL_MUTATION(
    $dealId: String!
    $name: String
    $type: DealType
    $companyName: String
    $companyAddress: String
    $companyAgent: String
    $projectName: String!
    $mode: DealMode
    $dealStatus: String!
    $expectedCommissions: Int!
    $expectedSize: Float!
    $closingDate: Date!
    $loiProvided: Boolean!
    $private: Boolean
    $firmId: String
    $metadata: [DealMetadataV2Input]
  ) {
    updateDeal(
      dealId: $dealId
      name: $name
      type: $type
      companyName: $companyName
      companyAddress: $companyAddress
      companyAgent: $companyAgent
      projectName: $projectName
      mode: $mode
      dealStatus: $dealStatus
      expectedCommissions: $expectedCommissions
      expectedSize: $expectedSize
      closingDate: $closingDate
      loiProvided: $loiProvided
      private: $private
      firmId: $firmId
      metadata: $metadata
    ) {
      ...DealBasicFragment
    }
  }
  ${DealFragment}
`;

export const UPDATE_DEAL_STATUS = gql`
  mutation UPDATE_DEAL_STATUS_MUTATION($dealId: String!) {
    updateDealStatus(dealId: $dealId) {
      id
      status
    }
  }
`;

export const UPDATE_FORM_STATUS = gql`
  mutation UPDATE_FORM_STATUS_MUTATION($id: ID!, $active: Boolean!) {
    updateFormActiveStatus(id: $id, active: $active) {
      id
      type
      isActive
    }
  }
`;

export const GET_EXPORT_VDR_TOKEN = gql`
  mutation GET_EXPORT_VDR_TOKEN_MUTATION {
    exportVdrToken {
      status
      statusText
    }
  }
`;

export const UPDATE_DEAL_METADATA = gql`
  mutation UPDATE_DEAL_METADATA_MUTATION($input: [DealMetadataInput]!) {
    updateDealMetadata(input: $input) {
      dealId
      dealName
      dealStatus
      expectedCommissions
      expectedSize
      loiProvided
      closingDate
    }
  }
`;
