import React from 'react';
import { Link, Route } from 'react-router-dom';
import { HamburgerIcon, CaretIcon, EDirections } from '@dealsyte/poki';

import { TabName } from 'app/core-tools/due-diligence/types/diligence';
import HeaderSessionTools from './components/HeaderSessionTools/HeaderSessionTools';

import {
  MainContainer,
  HeaderSessionToolsContainer,
  ToggleSidebarContainer,
  NavButton,
} from './StyledHeader';
import HeaderFirmContextSwitcher from './components/HeaderSessionTools/HeaderFirmContextSwitcher';

type IProps = {
  toggleSidebar?: () => void;
};

/**
 * Renders the app main header bar.
 * @param toggleSidebar Dispatch function to toggle if the header is shown or not.
 */
function Header({ toggleSidebar }: IProps): JSX.Element {
  return (
    <MainContainer>
      <ToggleSidebarContainer>
        <Route path={Object.values(TabName).map(tab => `/${tab}/:threadId?`)}>
          {({ match }) => {
            const threadId = (match && match.params.threadId) || '';

            const splitArray = match ? match.url.split('/') : [];

            splitArray.pop();

            const backUrl = splitArray.join('/');

            return (
              <NavButton
                onClick={!threadId ? toggleSidebar : undefined}
                as={threadId ? Link : undefined}
                to={backUrl}
              >
                {!threadId ? (
                  <HamburgerIcon />
                ) : (
                  <>
                    <CaretIcon direction={EDirections.left} /> List
                  </>
                )}
              </NavButton>
            );
          }}
        </Route>
      </ToggleSidebarContainer>

      <HeaderSessionToolsContainer>
        <HeaderSessionTools />
        <HeaderFirmContextSwitcher />
      </HeaderSessionToolsContainer>
    </MainContainer>
  );
}

export default Header;
