/**
 * Simple event service approach for handle global events without dom elements
 */
const EventService = {
  events: {},

  /**
   * @param {string} eventName
   * @param {Array} args array of arguments for event function callback
   */
  triggerEvent(eventName, args = []) {
    if (this.events[eventName]) {
      Object.keys(this.events[eventName]).forEach(id => {
        this.events[eventName][id](...args);
      });
    }
  },

  /**
   * @param {string} eventName name of event
   * @param {string} id callback identifier
   * @param {Function} callback
   */
  addListener(eventName, id, callback) {
    this.events[eventName] = {};
    this.events[eventName][id] = callback;
  },

  /**
   * @param {string} eventName name of event
   * @param {string} id callback identifier
   */
  removeListener(eventName, id) {
    delete this.events[eventName][id];
  },
};

export default EventService;
