// vendors
import styled from 'styled-components';

export const FilePickerContainer = styled.div`
  ${({ displayAs }: { displayAs: string }) => {
    return `
      display: flex;
      flex-direction: column;
      width: ${displayAs === 'button' && '100%'};
      `;
  }}
`;

export const FilePickerLabel = styled.p`
  ${({ theme }) => {
    const { colors, typography, spacing } = theme.foundations;
    return `  
    color: ${colors.text.default};
    font-family: ${typography.fonts.body};
    font-size: ${typography.sizes['03']};
    font-weight: ${typography.weights.regular};
    letter-spacing: 0.25px;
    line-height: 24.5px;
    margin-bottom: ${spacing['02']};
  `;
  }};
`;

export const FilePickerDescription = styled.p`
  ${({ theme }) => {
    const { colors, typography, spacing } = theme.foundations;
    return `  
      color: ${colors.palette.neutral['500']};
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['02']};
      font-weight: ${typography.weights.regular};
      margin-top: ${spacing['02']};
      letter-spacing: 0.25px;
      line-height: 18px;
      display: flex;
      justify-content: flex-end;
    `;
  }}
`;

export const FilePickerErrorParagraph = styled.p`
  ${({ theme }) => {
    const { colors, typography, spacing } = theme.foundations;
    return `
      width: 100%;
      text-align: right;
      margin-top: ${spacing['02']};
      color: ${colors.states.error};
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['02']};
      letter-spacing: 0.25px;
      line-height: 16px;
  `;
  }}
`;

export const FilePickerDropArea = styled.div`
  ${({ theme }) => {
    const { colors, radii } = theme.foundations;
    return `
      min-height: 278px;
      border: 1px dashed ${colors.outline.light};
      border-radius: ${radii.xl};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      svg {
        path {
          fill: ${colors.primary.light};
        }
      }

    `;
  }}
`;

export const ButtonContainerDropArea = styled.div`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return `
      display: flex;
      max-width: 500px;
      max-height: ${spacing['08']};
  `;
  }}
`;

export const Label = styled.div`
  ${({ theme }) => {
    const { typography, colors, spacing } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['04']};
      color: ${colors.text.dark};
      margin: ${spacing['07']} 0px ${spacing['05']} 0px;
    `;
  }}
`;

export const FileListContainer = styled.div`
  width: 100%;
`;

export const FileListHeading = styled.div`
  ${({ theme }) => {
    const { typography, colors, spacing } = theme.foundations;
    return `
    font-family: ${typography.fonts.body};
    font-size: ${typography.sizes['02']};
    font-weight: 700;
    color: ${colors.text.dark};
    margin: ${spacing['02']} 0px;
  `;
  }}
`;

export const FileListGrid = styled.div`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return `
      display: grid;
      grid-template-columns: ${spacing['04']} 2fr 1fr 1fr ${spacing['07']};
      column-gap: ${spacing['02']};
    `;
  }}
`;

export const FileListCell = styled.div`
  ${({ theme }) => {
    const { sizes } = theme.foundations;
    return `
      display: flex;
      align-items: center;
      overflow: hidden;
      height: ${sizes['08']};
    `;
  }}
`;

export const FileListItemLabel = styled.span`
  ${({ theme, displayAs }: { theme: any; displayAs: string }) => {
    const { typography, colors } = theme.foundations;
    return `
      display: block;
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['03']};
      color: ${displayAs === 'dropZone' ? colors.text.default : colors.palette.blue['700']};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `;
  }}
`;

export const FileListStatusLabel = styled.span`
  ${({ theme }) => {
    const { typography, colors, spacing } = theme.foundations;
    return `
      display: flex;
      align-items: center;
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['02']};

      svg {
        margin-right: ${spacing['02']};
      }

      &.completed {
        color: ${colors.text.light};
        svg {
          path {
            fill: ${colors.palette.accent};
          }
        }
      }

      &.failed, &.invalid {
        color: ${colors.states.error};
        svg {
          path {
            fill: ${colors.states.error};
          }
        }
      }

      &.invalid {
        color: ${colors.text.light};
        svg {
          path {
            fill: ${colors.states.error};
          }
        }
      }

    `;
  }}
`;

export const FileListItemInfo = styled.span`
  ${({ theme }) => {
    const { typography, colors } = theme.foundations;
    return `
      display: block;
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['02']};
      color: ${colors.text.light};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `;
  }}
`;
