import styled from 'styled-components';
import { Button } from '@dealsyte/poki';

export const StatusContainer = styled.div<{ totalProgress: number }>`
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: calc(100% - 466px);
  height: 45px;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 22px;
  border-radius: 3px;
  z-index: 2;
  background-color: ${({ totalProgress, theme }) =>
    totalProgress < 100 ? theme.palette.blue.lighter : '#0fc54b'};
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

export const StatusBar = styled.div<{ totalProgress: number }>`
  height: 100%;
  width: ${({ totalProgress }) => totalProgress}%;
  text-align: center;
  background-color: ${({ totalProgress, theme }) =>
    totalProgress < 100 ? theme.colors.primary.default : '#0fc54b'};
  transition: width 0.2s;
`;

export const StatusText = styled.p`
  position: absolute;
  top: 10px;
  margin-left: 10px;
  color: #ececec;
`;

export const DetailedStatusText = styled.p`
  padding: 5px;
  text-align: left;
  color: #ececec;
`;

export const TransactionsDetailsButton = styled(Button)`
  position: absolute;
  right: 0px;
  margin-left: 10px;
  color: #ececec;
  z-index: 1;

  &:hover {
    background-color: transparent;
  }
`;

export const TransactionsDetailsTitle = styled.h1`
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 20px;
`;

export const FsosInTransferProgressesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
`;

export const FsosInTransferDetailsName = styled.p`
  margin-bottom: 5px;
  color: ${({ theme }) => theme.palette.gray.darker};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

ButtonsContainer.displayName = 'ModalButtonsContainer';

export const ModalStatusContainer = styled(StatusContainer)`
  width: calc(100% - 100px);
  border-radius: none;
  border: none;
`;

export const ModalStatusText = styled(StatusText)`
  color: #fff;
  font-weight: bold;
`;

export const ModalTransactionsTitle = styled.h1`
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 20px;
`;

export const ModalTransactionDescription = styled.p``;
