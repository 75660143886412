import React, { useContext, useMemo } from 'react';
import get from 'lodash/get';

// Icons
import {
  ArrowRightIcon,
  BigLockIcon,
  CheckCircleOutlineIcon,
  UserCheckIcon,
  UsersIcon,
} from 'app/common/icons';

// Constants
import { KYCLabels, STEPS } from './KYCFormConstants';

// Context
import { FormsContext } from '../../../../../compliance/context/FormsContext';

// Styles
import { BoldText } from '../../DealRegulationScreen/DealRegulationScreenStyled';
import * as S from './KYCFormStyled';
import { EFormStatus, EFormType } from '../../../../types/formsTypes';

interface OptionSelectorParams {
  setCurrentStep: (option: string) => void;
  setPreviousStep: (option: string) => void;
}

const OptionSelector = ({ setCurrentStep, setPreviousStep }: OptionSelectorParams) => {
  const {
    dealRegulation: { query },
  } = useContext(FormsContext);

  const { data } = query;
  const forms = get(data, 'getPaginatedFormsByDeal.forms') || [];

  const KYC_CLIENT_EXIST = forms
    .filter((form: any) => form.type === EFormType.KYCcl)
    .some((form: any) =>
      [
        EFormStatus.APPROVED,
        EFormStatus.IN_REVIEW,
        EFormStatus.PASS_TO_LEGAL_TEAM,
        EFormStatus.REVIEWED_BY_LEGAL,
        EFormStatus.REVIEWED_BY_CO,
        EFormStatus.REQUEST_FOR_INFORMATION,
        EFormStatus.APPROVED_WITH_CONDITIONS,
      ].includes(form.status)
    );

  const handleOptionClick = (option: string) => {
    setPreviousStep(STEPS.OPTION_SELECTOR);
    setCurrentStep(option);
  };

  const clientOptionActive = !KYC_CLIENT_EXIST ? 'active' : 'locked';
  const counterPartyOptionActive = KYC_CLIENT_EXIST ? 'active' : 'locked';

  const optionPropsMap = {
    clientOption: {
      active: {
        optionContainerProps: {
          onClick: () => handleOptionClick(STEPS.CLIENT),
        },
        icon: <ArrowRightIcon width={15} height={17} />,
      },
      locked: {
        optionContainerProps: {
          className: 'blocked',
        },
        icon: <CheckCircleOutlineIcon width={17} height={19} />,
      },
    },
    counterPartyOption: {
      active: {
        optionContainerProps: {
          onClick: () => handleOptionClick(STEPS.COUNTERPARTY),
        },
        icon: <ArrowRightIcon width={15} height={17} />,
        subtitle: KYCLabels.COUNTERPARTIES_SUBTITLE,
      },
      locked: {
        optionContainerProps: {
          className: 'blocked',
        },
        icon: <BigLockIcon width={17} height={19} />,
        subtitle: KYCLabels.COUNTERPARTIES_BLOCKED,
      },
    },
  };

  const optionProps = useMemo(
    () => ({
      clientOptions: optionPropsMap.clientOption[clientOptionActive],
      counterPartyOptions: optionPropsMap.counterPartyOption[counterPartyOptionActive],
    }),
    [optionPropsMap, clientOptionActive, counterPartyOptionActive]
  );

  return (
    <>
      <S.Text>
        Complete the KYC/AML forms for all parties participating in the deal, starting with your
        client. <br /> If there are several counterparties,
        <BoldText> fill out individual forms for each of them.</BoldText> You can add more at the
        end, if necessary.
      </S.Text>

      <S.OptionsContainer>
        <S.OptionContainer {...optionProps.clientOptions.optionContainerProps}>
          <S.TextContainer>
            <S.IconContainer>
              <UserCheckIcon width={17} height={18} style={{ marginTop: '3px' }} />
            </S.IconContainer>
            <div>
              <S.Title>Client</S.Title>
              <S.Subtitle className="subtitle">{KYCLabels.CLIENT_SUBTITLE}</S.Subtitle>
            </div>
          </S.TextContainer>
          <S.IconContainer>{optionProps.clientOptions.icon}</S.IconContainer>
        </S.OptionContainer>
        <S.OptionContainer {...optionProps.counterPartyOptions.optionContainerProps}>
          <S.TextContainer>
            <S.IconContainer>
              <UsersIcon width={18} height={18} style={{ marginTop: '3px' }} />
            </S.IconContainer>
            <div>
              <S.Title>Counterparties</S.Title>
              <S.Subtitle className="subtitle">
                {optionProps.counterPartyOptions.subtitle}
              </S.Subtitle>
            </div>
          </S.TextContainer>
          <S.IconContainer>{optionProps.counterPartyOptions.icon}</S.IconContainer>
        </S.OptionContainer>
      </S.OptionsContainer>
    </>
  );
};

export default OptionSelector;
