import styled from 'styled-components';

export const DealTypeSubHeader = styled.div`
  ${({ theme }) => {
    const { colors, spacing, typography } = theme.foundations;
    return `
      flex-direction: row;
      margin-top: ${spacing['03']};
      div {
        display: inline-block;
      }

      div {
        font-size: ${typography.sizes['04']};
        color: ${colors.palette.blue['700']};
        font-family: ${typography.fonts.body};
        line-height: ${spacing['04']};
        letter-spacing: 0.25px;

        &.middle {
          margin-left: 5px;
          margin-right: 5px;
          vertical-align: text-top;
        }
      }
  `;
  }}
`;

export const DealTypeBody = styled.div`
  ${({ theme }) => {
    const { colors, spacing, typography } = theme.foundations;
    return `
      flex-direction: row;
      margin-top: ${spacing['04']};
      overflow-y: auto;
      max-height: 335px;

      .item-count {
        flex: 1;
        text-align: end;
        color: ${colors.palette.neutral[500]};
        font-size: ${typography.sizes['03']};
        font-family: ${typography.fonts.body};
        letter-spacing: 0.5px;
      }
    `;
  }}
`;
