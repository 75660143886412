import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// routes
import { ERoutePatterns } from 'app/core-tools/due-diligence/types/types';

import PrivateRoutes from './private/PrivateRoutes';
import PublicRoutes from './public/PublicRoutes';

/**
 * Wraps and define a route-based rendering for app main modules.
 */
function Routes() {
  return (
    <Switch>
      <Route
        path={[
          ERoutePatterns.CONNECT,
          ERoutePatterns.DOCUMENT_BANK,
          ERoutePatterns.VDR,
          ERoutePatterns.NOTIFICATIONS,
          ERoutePatterns.HOME,
          ERoutePatterns.FAQ,
          ERoutePatterns.COMPLIANCE_TRACKER,
          ERoutePatterns.REGULATORY_PROFILE_TRACKER,
          ERoutePatterns.ONBOARDING,
          ERoutePatterns.ATTESTATION,
          ERoutePatterns.MY_ACCOUNT,
          ERoutePatterns.DEAL_REGULATION,
          ERoutePatterns.MY_DEALS,
        ]}
        component={PrivateRoutes}
      />

      <Route
        path={[
          ERoutePatterns.RECOVERY,
          ERoutePatterns.LOGIN,
          `${ERoutePatterns.SIGNUP}/:signupToken`,
          ERoutePatterns.SIGNATURE_COMPLETED,
          ERoutePatterns.LOGOUT,
          ERoutePatterns.PROXY_LOGIN,
        ]}
        component={PublicRoutes}
      />

      <Redirect to={ERoutePatterns.LOGIN} />
    </Switch>
  );
}

export default Routes;
