import React, { useState } from 'react';
import { ChevronIcon } from 'app/common/icons';

import * as S from './PaginationStyled';

export interface PaginationProps {
  totalPages: number;
  handleClick: (pageNumber: number) => void;
}

export const Pagination = ({ totalPages, handleClick }: PaginationProps) => {
  const [selectedPage, setSelectedPage] = useState(1);
  const pages: number[] = [];
  const hasPagesBefore = selectedPage !== 1;
  const hasPagesAfter = selectedPage !== totalPages;

  for (let i = 1; i <= totalPages; i += 1) {
    pages.push(i);
  }

  const handlePage = (pageNumber: number) => {
    handleClick(pageNumber);
    setSelectedPage(pageNumber);
  };

  return (
    <S.PaginationWrapper>
      <S.ChevronWrapper
        onClick={() => hasPagesBefore && handlePage(selectedPage - 1)}
        disabled={!hasPagesBefore}
      >
        <ChevronIcon
          color={!hasPagesBefore ? '#B9B6D2' : 'currentColor'}
          style={{ marginRight: '26px', transform: 'rotate(90deg)' }}
        />
      </S.ChevronWrapper>
      {pages.map(page => (
        <S.Page selected={selectedPage === page} onClick={() => handlePage(page)}>
          {page}
        </S.Page>
      ))}
      <S.ChevronWrapper
        onClick={() => hasPagesAfter && handlePage(selectedPage + 1)}
        disabled={!hasPagesAfter}
      >
        <ChevronIcon
          color={!hasPagesAfter ? '#B9B6D2' : 'currentColor'}
          style={{ marginLeft: '26px', transform: 'rotate(270deg)' }}
        />
      </S.ChevronWrapper>
    </S.PaginationWrapper>
  );
};
