import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

// api
import {
  GET_MY_COLLABORATIONS_V2,
  GET_COLLABORATIONS_IN_MY_OFFERINGS_V3,
  GET_CONVERSATION_MESSAGES,
  GET_COLLABORATIONS_IN_MY_FIRMS_OFFERINGS_BASICS,
  GET_NEW_MESSAGES_AMOUNT_IN_MY_OFFERINGS_V3,
  GET_NEW_MESSAGES_AMOUNT_IN_MY_COLLABORATIONS_V2,
} from 'api/graphql/marketplace/queries';

// Types
import { IConversationsContext, IFirmData } from './TypedFilterContext';

// Context
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { UPDATE_LAST_INTERACTION_IN_COLABORATION } from 'api/graphql/marketplace/mutations';
import { UserContext } from 'app/users/context/UserContext';

const ConversationsContext = createContext({} as IConversationsContext);

function ConversationsContextProvider({ children }: { children: React.ReactNode }) {
  const [myCollaborations, setMyCollaborations] = useState([]);
  const [collaborationsInMyOfferings, setCollaborationsInMyOfferings] = useState([]);
  const { showToastV2 } = useContext(AlertContext);
  const [conversationMessages, setConversationMessages] = useState([]);
  const [activeCollaborationId, setActiveCollaborationId] = useState('');
  const [collaborationsInMyOferingsBasics, setCollaborationsInMyOfferingsBasics] = useState([]);
  const [activeFirmData, setActiveFirmData] = useState<IFirmData>({
    firmName: '',
    firmMembers: [],
  });
  const [activeOfferingId, setActiveOfferingId] = useState('');
  const [messageAmountInMyOfferings, setMessageAmountInMyOfferings] = useState([]);
  const [messageAmountInMyCollaborations, setMessageAmountInMyCollaborations] = useState([]);
  const { isCustomer } = useContext(UserContext);

  const [myCollaborationsQuery, { loading: loadingMyCollaborations }] = useLazyQuery(
    GET_MY_COLLABORATIONS_V2,
    {
      variables: {
        offeringTypesToInclude: ['DEAL', 'FUND'],
        collaborationStatusToInclude: ['PENDING'],
      },
      fetchPolicy: 'no-cache',
      onCompleted(response: any) {
        setMyCollaborations(response.findCollaborationsByRequesterFirmV2);
      },
      onError(error: any) {
        showToastV2({
          description: 'Error searching collaborations. Try again later.',
          variant: 'danger',
        });
      },
    }
  );

  const [
    collaborationsInMyOfferingsQuery,
    { loading: loadingCollaborationsInMyOfferings },
  ] = useLazyQuery(GET_COLLABORATIONS_IN_MY_OFFERINGS_V3, {
    variables: {
      offeringTypesToInclude: ['DEAL', 'FUND'],
      collaborationStatusToInclude: ['PENDING'],
    },
    fetchPolicy: 'no-cache',
    onCompleted(response: any) {
      setCollaborationsInMyOfferings(response.findCollaborationsInFirmOfferingsV3);
    },
    onError(error: any) {
      showToastV2({
        description: 'Error searching collaborations. Try again later.',
        variant: 'danger',
      });
    },
  });

  const [getConvoSideBarData, { loading: loadingConvoSideBarData }] = useLazyQuery(
    GET_COLLABORATIONS_IN_MY_FIRMS_OFFERINGS_BASICS,
    {
      fetchPolicy: 'no-cache',
      onCompleted(response: any) {
        const sideBarData = response.findCollaborationsInFirmOfferingsBasics.sort(
          (elementA: any, elementB: any) => {
            return (
              new Date(elementB.lastMessage.dateTime).getTime() -
              new Date(elementA.lastMessage.dateTime).getTime()
            );
          }
        );
        setCollaborationsInMyOfferingsBasics(sideBarData);
        setActiveCollaborationId(sideBarData[0].collaborationId);
        setActiveFirmData({
          firmName: sideBarData[0].requesterName,
          firmMembers: sideBarData[0].offeringFirmMembers,
        });
      },
      onError(error: any) {
        showToastV2({
          description: 'Error searching collaborations. Try again later.',
          variant: 'danger',
        });
      },
    }
  );

  const { refetch: messageAmountInMyOfferingsQuery } = useQuery(
    GET_NEW_MESSAGES_AMOUNT_IN_MY_OFFERINGS_V3,
    {
      variables: {
        offeringTypesToInclude: ['DEAL', 'FUND'],
        collaborationStatusToInclude: ['PENDING'],
      },
      fetchPolicy: 'no-cache',
      onCompleted(response: any) {
        setMessageAmountInMyOfferings(response.findCollaborationsInFirmOfferingsV3);
      },
      onError(error: any) {
        if (isCustomer)
          showToastV2({
            description: 'Error searching collaborations. Try again later.',
            variant: 'danger',
          });
      },
    }
  );

  const { refetch: messageAmountInMyCollaborationsQuery } = useQuery(
    GET_NEW_MESSAGES_AMOUNT_IN_MY_COLLABORATIONS_V2,
    {
      variables: {
        offeringTypesToInclude: ['DEAL', 'FUND'],
        collaborationStatusToInclude: ['PENDING'],
      },
      fetchPolicy: 'no-cache',
      onCompleted(response: any) {
        setMessageAmountInMyCollaborations(response.findCollaborationsByRequesterFirmV2);
      },
      onError(error: any) {
        if (isCustomer)
          showToastV2({
            description: 'Error searching collaborations. Try again later.',
            variant: 'danger',
          });
      },
    }
  );

  const [getConversationMessages] = useLazyQuery(GET_CONVERSATION_MESSAGES, {
    fetchPolicy: 'no-cache',
    onCompleted(response: any) {
      setConversationMessages(response.getMessagesConversation);
      updateLastInteraction({
        variables: {
          collaborationId: activeCollaborationId,
        },
      });
      messageAmountInMyCollaborationsQuery({
        variables: {
          offeringTypesToInclude: ['DEAL', 'FUND'],
          collaborationStatusToInclude: ['PENDING'],
        },
      });
      messageAmountInMyOfferingsQuery({
        variables: {
          offeringTypesToInclude: ['DEAL', 'FUND'],
          collaborationStatusToInclude: ['PENDING'],
        },
      });
    },
    onError(error: any) {
      showToastV2({
        description: 'Error searching messages. Try again later.',
        variant: 'danger',
      });
    },
  });

  useEffect(() => {
    if (activeCollaborationId) {
      getConversationMessages({
        variables: {
          collaborationId: activeCollaborationId,
        },
      });
    }
  }, [activeCollaborationId]);

  const [updateLastInteraction] = useMutation(UPDATE_LAST_INTERACTION_IN_COLABORATION);

  return (
    <ConversationsContext.Provider
      value={{
        myCollaborations: {
          data: myCollaborations,
          loading: loadingMyCollaborations,
          refetch: myCollaborationsQuery,
        },
        collaborationsInMyOfferings: {
          data: collaborationsInMyOfferings,
          loading: loadingCollaborationsInMyOfferings,
          refetch: collaborationsInMyOfferingsQuery,
        },
        convoSideBarData: {
          data: collaborationsInMyOferingsBasics,
          loading: loadingConvoSideBarData,
          setData: setCollaborationsInMyOfferingsBasics,
        },
        conversationMessages: {
          data: conversationMessages,
          getConversations: getConversationMessages,
          setConversationMessages: setConversationMessages,
        },
        messageAmountInMyOfferings: {
          data: messageAmountInMyOfferings,
          refetch: messageAmountInMyOfferingsQuery,
        },
        messageAmountInMyCollaborations: {
          data: messageAmountInMyCollaborations,
          refetch: messageAmountInMyCollaborationsQuery,
        },
        getConvoSideBarData,
        setActiveCollaborationId,
        activeCollaborationId,
        setActiveFirmData,
        activeFirmData,
        setActiveOfferingId,
        activeOfferingId,
      }}
    >
      {children}
    </ConversationsContext.Provider>
  );
}

export { ConversationsContext, ConversationsContextProvider };
