// vendor
import React, { useContext } from 'react';

import { FormFilePicker, FormGroup, Input, TextArea } from 'app/common/components';

import { INVLabels } from './InvoiceFormConstants';
import { InvoiceForm as InvoiceFormType, InvoiceHandlerContext } from './InvoiceFormHandler';
import { formControlChangeHandlers } from '../../FormControlChangeHandlers/FormControlChangeHandlers';

const InvoiceForm = () => {
  const { form } = useContext(InvoiceHandlerContext);
  const { values, setFieldValue, errors, touched } = form;

  const { handleFieldChange, handleFilesChange } = formControlChangeHandlers<InvoiceFormType>(
    setFieldValue
  );

  return (
    <>
      <FormFilePicker
        error={touched.invoiceFiles && errors.invoiceFiles}
        label={INVLabels.CLOSING_INVOICE}
        files={values.invoiceFiles}
        setFiles={handleFilesChange('invoiceFiles')}
        acceptedFiles=".jpg, .jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .png, image/jpeg, image/png, text/plain, .doc, .docx, .xls, .xlsx, .ppt, .pptx, application/excel, application/vnd.ms-excel, application/x-excel, application/x-msexcel, application/doc, application/ms-doc, application/msword, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
        description="DOC, DOCX, JPG, JPEG, PDF, PNG, PPT, PPTX, XLS, XLSX and plain text are supported."
      />

      <FormGroup
        id="breakdown"
        label={INVLabels.BREAKDOWN}
        style={{ marginTop: 24 }}
        error={touched.breakdown && errors.breakdown}
        required
      >
        <Input
          id="breakdown"
          name="breakdown"
          value={values.breakdown}
          onChange={handleFieldChange('breakdown')}
        />
      </FormGroup>

      <FormGroup label="Do you want to add any comments / notes:">
        <TextArea
          placeholder="Comments here..."
          onChange={handleFieldChange('note')}
          value={values.note}
        />
      </FormGroup>
    </>
  );
};

export default InvoiceForm;
