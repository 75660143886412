import styled from 'styled-components';

import { Button } from '@dealsyte/poki';

const ContextMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

// @ts-ignore
const ActionsButton = styled(Button)<{ isMenuOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ isMenuOpen }) => (isMenuOpen ? 1 : 0)};
  background-image: none;
  border: none;
  color: #9299a0;
  font-size: 20px;
  cursor: pointer;
  border-radius: 2px;
  height: 20px;
  width: 24px;

  &:hover {
    background-color: #e3e3e3;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &:active {
    box-shadow: none;
  }

  &:active:focus {
    box-shadow: none;
  }

  ${ContextMenuContainer}:hover & {
    opacity: 1;
  }
`;

export { ContextMenuContainer, ActionsButton };
