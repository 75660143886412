import styled from 'styled-components';

export const RadioOption = styled.span`
  ${({ theme }) => {
    const { colors } = theme.foundations;
    return `  
    width: 18px;
    height: 18px;
    border: 2px solid ${colors.palette.neutral['500']};
    border-radius: 50px;
    &:hover {
      border: 2px solid ${colors.palette.neutral['700']};
    }
    transition: box-shadow 300ms ease-out;
  `;
  }}
`;

export const RadioLabel = styled.label`
  ${({ theme }) => {
    const { colors, typography } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['04']};
      color: ${colors.text.default}; 
    `;
  }}
`;

export const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:hover ~ ${RadioOption} {
    border: ${({ theme }) => `2px solid ${theme.foundations.colors.palette.neutral['700']}`};
  }

  &:checked ~ ${RadioOption} {
    border: 0px;
    box-shadow: ${({ theme }) =>
      `0px 0px 0px 6px ${theme.foundations.colors.primary.default} inset`};
    &:hover {
      box-shadow: ${({ theme }) =>
        `0px 0px 0px 6px ${theme.foundations.colors.primary.dark} inset`};
    }
  }
`;
