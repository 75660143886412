import * as React from 'react';

import { IconProps } from './types';

export const FolderIconVDR = ({
  width = '10px',
  height = '10px',
  color = '#807DA1',
}: IconProps): JSX.Element => (
  <svg width={width} height={height} fill="none" viewBox="0 0 10 10">
    <path
      d="M8.5 1.75001H5.36L5.2 1.25001C5.09628 0.956626 4.90387 0.702763 4.64945 0.523592C4.39503 0.34442 4.09118 0.248812 3.78 0.250011H1.5C1.10218 0.250011 0.720644 0.408046 0.43934 0.689351C0.158035 0.970656 0 1.35219 0 1.75001V8.25001C0 8.64784 0.158035 9.02937 0.43934 9.31067C0.720644 9.59198 1.10218 9.75001 1.5 9.75001H8.5C8.89782 9.75001 9.27936 9.59198 9.56066 9.31067C9.84196 9.02937 10 8.64784 10 8.25001V3.25001C10 2.85219 9.84196 2.47066 9.56066 2.18935C9.27936 1.90805 8.89782 1.75001 8.5 1.75001ZM9 8.25001C9 8.38262 8.94732 8.5098 8.85355 8.60357C8.75979 8.69733 8.63261 8.75001 8.5 8.75001H1.5C1.36739 8.75001 1.24021 8.69733 1.14645 8.60357C1.05268 8.5098 1 8.38262 1 8.25001V1.75001C1 1.6174 1.05268 1.49023 1.14645 1.39646C1.24021 1.30269 1.36739 1.25001 1.5 1.25001H3.78C3.88482 1.24974 3.98708 1.28242 4.07231 1.34343C4.15755 1.40444 4.22146 1.4907 4.255 1.59001L4.525 2.41001C4.55854 2.50932 4.62245 2.59558 4.70769 2.65659C4.79292 2.7176 4.89518 2.75028 5 2.75001H8.5C8.63261 2.75001 8.75979 2.80269 8.85355 2.89646C8.94732 2.99023 9 3.1174 9 3.25001V8.25001Z"
      fill={color}
    />
  </svg>
);
