import get from 'lodash/get';
import { FormType } from 'types/graphql-types';
import { PositionMap } from 'app/utils/helpers/formDataMappers';

import { dealCounterpart, KYCLabels, transactionStructures } from './KYCFormConstants';
import { KYCForm } from './KYCFormHandler';

const buildStructure = (
  transactionStructure: { label: string; value: string } | null,
  otherStructure: string = ''
): string => {
  if (transactionStructure && transactionStructure.value) {
    return transactionStructure.value === 'Other'
      ? `Other (${otherStructure})`
      : transactionStructure.value;
  }
  return '';
};

const getTransactionStructureFromString = (transactionStructureString: string) => {
  let otherString;
  const isOther = transactionStructureString.includes('Other');
  const transactionStructureSearchStr = isOther
    ? transactionStructureString.split('(')[0].trim()
    : transactionStructureString;
  const otherSearch = transactionStructureString.match(/\((.*?)\)/);
  if (otherSearch && isOther) {
    const [, extracted] = otherSearch;
    otherString = extracted;
  }

  const transactionStructure = transactionStructures.find(
    item => item.value === transactionStructureSearchStr
  ) || { label: '', value: '' };

  return {
    transactionStructure,
    otherStructure: otherString,
  };
};

export const buildKYCForm = (props: KYCForm, dealId: string) => {
  const {
    clientName,
    clientEmail,
    clientEntityName,
    clientTitle,
    transactionStructure,
    otherStructure,
    counterpartDeal,
    counterpartEntityName,
    counterpartEntityLegalName,
    contactName,
    contactLastName,
    contactEmail,
    contactTitle,
    note,
  } = props;
  const variables = {
    type: FormType.KYC,
    dealId,
    content: [
      {
        question: KYCLabels.NAME,
        input: clientName.trim(),
        key: 'clientName',
        value: JSON.stringify(clientName.trim()),
      },
      {
        question: KYCLabels.EMAIL,
        input: clientEmail.trim(),
        key: 'clientEmail',
        value: JSON.stringify(clientEmail.trim()),
      },
      {
        question: KYCLabels.ENTITY_NAME,
        input: clientEntityName.trim(),
        key: 'clientEntityName',
        value: JSON.stringify(clientEntityName.trim()),
      },
      {
        question: KYCLabels.TITLE,
        input: clientTitle.trim(),
        key: 'clientTitle',
        value: JSON.stringify(clientTitle.trim()),
      },
      {
        question: KYCLabels.TRANSACTION_STRUCTURE,
        input: buildStructure(transactionStructure, otherStructure),
        key: 'transactionStructure',
        value: JSON.stringify({
          transactionStructure,
          otherStructure,
        }),
      },
      {
        question: '6. Deal Counterpart',
        input: get(counterpartDeal, 'value', ''),
        key: 'counterpartDeal',
        value: JSON.stringify(counterpartDeal),
      },
      {
        question: KYCLabels.COUNTERPART_ENTITY_NAME,
        input: counterpartEntityName.trim(),
        key: 'counterpartEntityName',
        value: JSON.stringify(counterpartEntityName.trim()),
      },
      {
        question: KYCLabels.COUNTERPART_ENTITY_LEGAL_NAME,
        input: counterpartEntityLegalName.trim(),
        key: 'counterpartEntityLegalName',
        value: JSON.stringify(counterpartEntityLegalName.trim()),
      },
      {
        question: KYCLabels.COUNTERPART_CONTACT_INFO,
        input: '',
        key: '',
        value: '',
      },
      {
        question: `9A. ${KYCLabels.FIRST_NAME}`,
        input: contactName.trim(),
        key: 'contactName',
        value: JSON.stringify(contactName.trim()),
      },
      {
        question: `9B. ${KYCLabels.LAST_NAME}`,
        input: contactLastName.trim(),
        key: 'contactLastName',
        value: JSON.stringify(contactLastName.trim()),
      },
      {
        question: `9C. ${KYCLabels.CONTACT_EMAIL}`,
        input: contactEmail.trim(),
        key: 'contactEmail',
        value: JSON.stringify(contactEmail.trim()),
      },
      {
        question: `9D. ${KYCLabels.CONTACT_TITLE}`,
        input: contactTitle.trim(),
        key: 'contactTitle',
        value: JSON.stringify(contactTitle.trim()),
      },
    ],
    note,
  };
  return variables;
};

export const KYC_VALUE_INFERENCE_MAP: PositionMap = {
  clientName: { match: KYCLabels.NAME },
  clientEmail: { match: KYCLabels.EMAIL },
  clientEntityName: { match: KYCLabels.ENTITY_NAME },
  clientTitle: { match: KYCLabels.TITLE },
  transactionStructure: {
    match: KYCLabels.TRANSACTION_STRUCTURE,
    value: ({ input }) => getTransactionStructureFromString(input).transactionStructure,
  },
  otherStructure: {
    match: KYCLabels.TRANSACTION_STRUCTURE,
    value: ({ input }) => getTransactionStructureFromString(input).otherStructure,
  },
  counterpartDeal: {
    match: KYCLabels.COUNTERPART_DEAL,
    value: ({ input }) => dealCounterpart.find(item => item.value === input),
  },
  counterpartEntityName: { match: KYCLabels.COUNTERPART_ENTITY_NAME },
  counterpartEntityLegalName: { match: KYCLabels.COUNTERPART_ENTITY_LEGAL_NAME },
  contactName: { match: `9A. ${KYCLabels.FIRST_NAME}` },
  contactLastName: { match: `9B. ${KYCLabels.LAST_NAME}` },
  contactEmail: { match: `9C. ${KYCLabels.CONTACT_EMAIL}` },
  contactTitle: { match: `9D. ${KYCLabels.CONTACT_TITLE}` },
};
