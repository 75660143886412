import { Locale } from 'react-modern-calendar-datepicker';

export const GREGORIAN_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const GREGORIAN_WEEK_DAYS = [
  {
    name: 'Sunday',
    short: 'S',
    isWeekend: true,
  },
  {
    name: 'Monday',
    short: 'M',
  },
  {
    name: 'Tuesday',
    short: 'T',
  },
  {
    name: 'Wednesday',
    short: 'W',
  },
  {
    name: 'Thursday',
    short: 'T',
  },
  {
    name: 'Friday',
    short: 'F',
  },
  {
    name: 'Saturday',
    short: 'S',
    isWeekend: true,
  },
];

const localeLanguages = {
  en: {
    months: GREGORIAN_MONTHS,
    weekDays: GREGORIAN_WEEK_DAYS,
    weekStartingIndex: 0,
    getToday(gregorainTodayObject: any) {
      return gregorainTodayObject;
    },
    toNativeDate(date: any) {
      return new Date(date.year, date.month - 1, date.day);
    },
    getMonthLength(date: any) {
      return new Date(date.year, date.month, 0).getDate();
    },
    transformDigit(digit: any) {
      return digit;
    },
    nextMonth: 'Next Month',
    previousMonth: 'Previous Month',
    openMonthSelector: 'Open Month Selector',
    openYearSelector: 'Open Year Selector',
    closeMonthSelector: 'Close Month Selector',
    closeYearSelector: 'Close Year Selector',
    from: 'from',
    to: 'to',
    defaultPlaceholder: 'Select...',
    digitSeparator: ',',
    yearLetterSkip: 0,
    isRtl: false,
  },
};

const getLocaleDetails = (locale: string | Locale | undefined) => {
  if (!locale) return localeLanguages.en;
  if (typeof locale === 'string') return localeLanguages.en;
  return locale;
};

export { localeLanguages };
export default getLocaleDetails;
