import React, { useContext } from 'react';

// components
import { FormFilePicker, FormGroup, Input, TextArea } from 'app/common/components';

// form handler
import { CollaborationAgreementsHandlerContext } from './CAFormHandler';
import { CAExternalFormType } from './CAExternalFormHandler';
import { formControlChangeHandlers } from '../../FormControlChangeHandlers/FormControlChangeHandlers';

// constants
import { ACCEPTED_FILES, CA_EXTERNAL_FORM_LABELS, EXTERNAL_STEP } from './CAConstants';

// styles
import * as S from './CAFormStyled';

export const CAExternalForm = () => {
  const { externalHandler } = useContext(CollaborationAgreementsHandlerContext);
  const { values, setFieldValue, touched, errors } = externalHandler.form;

  const { handleFilesChange, handleFieldChange } = formControlChangeHandlers<CAExternalFormType>(
    setFieldValue
  );

  return (
    <>
      <S.FormDescription>{EXTERNAL_STEP.description}</S.FormDescription>
      <FormGroup
        label={CA_EXTERNAL_FORM_LABELS.FILES}
        required
        error={touched.files && errors.files}
      >
        <FormFilePicker
          setFiles={handleFilesChange('files')}
          files={values.files}
          acceptedFiles={ACCEPTED_FILES}
          multiple={false}
          description="DOC, DOCX, JPG, JPEG, PDF, PNG, PPT, PPTX, XLS, XLSX and plain text are supported."
        />
      </FormGroup>
      <S.FormTitle>{EXTERNAL_STEP.title}</S.FormTitle>
      <S.FormTitleDescription>{EXTERNAL_STEP.titleDescription}</S.FormTitleDescription>
      <FormGroup
        required
        label={CA_EXTERNAL_FORM_LABELS.FIRM_NAME}
        error={touched.firmName && errors.firmName}
      >
        <Input
          id="firmName"
          name="firmName"
          value={values.firmName}
          onChange={handleFieldChange('firmName')}
          placeholder="Enter name"
        />
      </FormGroup>
      <S.FormSubtitle> {EXTERNAL_STEP.subtitle}</S.FormSubtitle>
      <FormGroup
        required
        label={CA_EXTERNAL_FORM_LABELS.SIGNER}
        error={touched.signer && errors.signer}
      >
        <Input
          id="signer"
          name="signer"
          value={values.signer}
          onChange={handleFieldChange('signer')}
          placeholder="Enter full name"
        />
      </FormGroup>
      <FormGroup
        required
        label={CA_EXTERNAL_FORM_LABELS.EMAIL_ADDRESS}
        error={touched.emailAddress && errors.emailAddress}
      >
        <Input
          id="emailAddress"
          name="emailAddress"
          value={values.emailAddress}
          onChange={handleFieldChange('emailAddress')}
          placeholder="Enter email address"
        />
      </FormGroup>
      <FormGroup required label={CA_EXTERNAL_FORM_LABELS.CRD} error={touched.CRD && errors.CRD}>
        <Input
          id="CRD"
          name="CRD"
          value={values.CRD}
          onChange={handleFieldChange('CRD')}
          placeholder="Enter the CRD number"
        />
      </FormGroup>
      <FormGroup
        label={CA_EXTERNAL_FORM_LABELS.NOTE}
        optional
        helperText="Enter necessary details to complete the form."
        error={touched.note && errors.note}
        showCharCount
      >
        {ref => (
          <TextArea
            ref={ref as any}
            rows={2}
            name="note"
            onChange={handleFieldChange('note')}
            value={values.note}
            placeholder="Write your notes"
            maxLength={300}
          />
        )}
      </FormGroup>
    </>
  );
};
