import React, { useContext, useRef } from 'react';

// Components
import {
  Alert,
  FormGroup,
  Input,
  Select,
  SingleOptionRadioButtons,
} from '../../../../../common/components';

// Constants
import { DEAL_TYPES_OPTIONS } from '../../../../../utils/helpers/deal';
import { NDPrimaryInfoTexts } from '../NewDealFormTexts';

// Styles
import * as S from '../NewDealFormStyled';

// Context
import { NewDealForm, NewDealHandlerContext } from '../NewDealFormHandler';
import { UserContext } from '../../../../../users/context/UserContext';

// Helper
import { formControlChangeHandlers } from '../../../FormControlChangeHandlers/FormControlChangeHandlers';
import { countries } from 'app/utils/helpers/countries';
import { CloseEyeIcon } from 'app/common/icons';

const NDPrimaryInfo = () => {
  const { form, displayShareDealWithFirm } = useContext(NewDealHandlerContext);
  const { currentFirm } = useContext(UserContext);
  const { values, touched, errors, setFieldValue } = form;
  const { handleFieldChange, handleSelectChange, handleYesNoChange } = formControlChangeHandlers<
    NewDealForm
  >(setFieldValue);
  const DATA_TEST_ID_BASE = "NDPrimaryInfo__"

  return (
    <>
      <S.NewDealSubtitle>{NDPrimaryInfoTexts.SUBTITLE}</S.NewDealSubtitle>
      <Alert
        text={
          <S.AlertText>
            Neither the <strong>deal name</strong> nor the <strong>client's entity name </strong>
            will be shown to other Finalis users.
          </S.AlertText>
        }
        icon={<CloseEyeIcon width={20} height={20} />}
      />
      <FormGroup
        label={NDPrimaryInfoTexts.NAME}
        showCharCount
        required
        error={touched.newDealName && errors.newDealName}
      >
        {ref => (
          <Input
            ref={ref as any}
            id="newDealName"
            data-testid={DATA_TEST_ID_BASE+"newDealName"}
            maxLength={120}
            name="newDealName"
            placeholder={NDPrimaryInfoTexts.NAME_PLACEHOLDER}
            value={values.newDealName}
            onChange={handleFieldChange('newDealName')}
          />
        )}
      </FormGroup>
      <FormGroup
        label={NDPrimaryInfoTexts.LEGAL_NAME_LABEL}
        helperText={NDPrimaryInfoTexts.LEGA_NAME_DESCRIPTION}
        required
        error={touched.companyName && errors.companyName}
      >
        <Input
          id="companyName"
          data-testid={DATA_TEST_ID_BASE+"companyName"}
          name="companyName"
          placeholder={NDPrimaryInfoTexts.LEGAL_NAME_PLACEHOLDER}
          value={values.companyName}
          onChange={handleFieldChange('companyName')}
        />
      </FormGroup>
      <FormGroup label={NDPrimaryInfoTexts.TYPE} required>
        <Select
          options={DEAL_TYPES_OPTIONS}
          inputId="transactionType"
          data-testid={DATA_TEST_ID_BASE+"transactionType"}
          name="transactionType"
          value={values.transactionType}
          onChange={handleSelectChange('transactionType')}
          maxMenuHeight={200}
          placeholder={NDPrimaryInfoTexts.TYPE_PLACEHOLDER}
          isSearchable={false}
        />
      </FormGroup>

      <S.NewDealTitle style={{ marginBottom: '12px' }}>Location</S.NewDealTitle>

      <FormGroup
        label={NDPrimaryInfoTexts.HEADQUARTER_LABEL_LINE1}
        required
        error={touched.headquarterAddressLine1 && errors.headquarterAddressLine1}
      >
        <Input
          id="headquarterAddress1"
          data-testid={DATA_TEST_ID_BASE+"headquarterAddress1"}
          name="headquarterAddress1"
          placeholder={NDPrimaryInfoTexts.HEADQUARTER_PLACEHOLDER}
          value={values.headquarterAddressLine1}
          onChange={handleFieldChange('headquarterAddressLine1')}
        />
      </FormGroup>
      <FormGroup label={NDPrimaryInfoTexts.HEADQUARTER_LABEL_LINE2} optional>
        <Input
          id="headquarterAddress2"
          data-testid={DATA_TEST_ID_BASE+"headquarterAddress2"}
          name="headquarterAddress2"
          placeholder={NDPrimaryInfoTexts.HEADQUARTER_PLACEHOLDER}
          value={values.headquarterAddressLine2}
          onChange={handleFieldChange('headquarterAddressLine2')}
        />
      </FormGroup>
      <FormGroup
        label={NDPrimaryInfoTexts.COUNTRY_LABEL}
        required
        error={touched.country && errors.country}
      >
        <Select
          options={countries}
          inputId="country"
          name="country"
          data-testid={DATA_TEST_ID_BASE+"country"}
          value={values.country}
          onChange={handleSelectChange('country')}
          maxMenuHeight={200}
          placeholder={NDPrimaryInfoTexts.COUNTRY_PLACEHOLDER}
          isSearchable
        />
      </FormGroup>
      <FormGroup
        label={NDPrimaryInfoTexts.STATE_LABEL}
        required
        error={touched.state && errors.state}
      >
        <Input
          id="state"
          name="state"
          data-testid={DATA_TEST_ID_BASE+"state"}
          placeholder={NDPrimaryInfoTexts.STATE_PLACEHOLDER}
          value={values.state}
          onChange={handleFieldChange('state')}
        />
      </FormGroup>
      <FormGroup
        label={NDPrimaryInfoTexts.ZIPCODE_LABEL}
        required
        error={touched.zipCode && errors.zipCode}
      >
        <Input
          id="zipCode"
          name="zipCode"
          data-testid={DATA_TEST_ID_BASE+"zipCode"}
          placeholder={NDPrimaryInfoTexts.ZIPCODE_PLACEHOLDER}
          value={values.zipCode}
          onChange={handleFieldChange('zipCode')}
        />
      </FormGroup>

      {displayShareDealWithFirm && (
        <>
          <S.NewDealTitle>Share with your firm</S.NewDealTitle>
          <FormGroup
            label={`Do you want to share the deal with the other users from ${
              currentFirm ? `${currentFirm.name}?` : 'the firm?'
            }`}
            required
          >
            <SingleOptionRadioButtons
              strictValueOnChange
              value={values.private}
              id="private"
              data-testid={DATA_TEST_ID_BASE+"private"}
              name="private"
              onChange={handleYesNoChange('private')}
            />
          </FormGroup>
        </>
      )}

      <S.NewDealTitle>Client engagement</S.NewDealTitle>

      <FormGroup
        label="Do you have an engagement letter, a finder’s fee agreement or a placement agreement draft to provide at the moment?"
        required
      >
        <SingleOptionRadioButtons
          strictValueOnChange
          value={values.engagementLetterProvided}
          id="engagementLetterProvided"
          name="engagementLetterProvided"
          data-testid={DATA_TEST_ID_BASE+"engagementLetterProvided"}
          onChange={handleYesNoChange('engagementLetterProvided')}
        />
      </FormGroup>
    </>
  );
};

export default NDPrimaryInfo;
