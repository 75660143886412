import * as React from 'react';
import { IconProps } from './types';

export const DoubleChevronIcon = ({
  color = 'currentColor',
  style = {},
  width = '8px',
  height = '6px',
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 8 6"
    fill="none"
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.64001 0.526844C1.51448 0.397772 1.34281 0.323855 1.16278 0.321355C0.982741 0.318854 0.809087 0.387975 0.680015 0.513511C0.550943 0.639047 0.477026 0.810714 0.474525 0.990749C0.472025 1.17078 0.541145 1.34444 0.666681 1.47351L2.22668 3.00018L0.666681 4.52684C0.604196 4.58882 0.554599 4.66255 0.520754 4.74379C0.486908 4.82503 0.469482 4.91217 0.469482 5.00018C0.469482 5.08819 0.486908 5.17532 0.520754 5.25656C0.554599 5.3378 0.604196 5.41154 0.666681 5.47351C0.728657 5.536 0.802391 5.58559 0.88363 5.61944C0.96487 5.65328 1.05201 5.67071 1.14001 5.67071C1.22802 5.67071 1.31516 5.65328 1.3964 5.61944C1.47764 5.58559 1.55137 5.536 1.61335 5.47351L3.61335 3.47351C3.67583 3.41154 3.72543 3.3378 3.75928 3.25656C3.79312 3.17532 3.81055 3.08819 3.81055 3.00018C3.81055 2.91217 3.79312 2.82503 3.75928 2.74379C3.72543 2.66255 3.67583 2.58882 3.61335 2.52684L1.64001 0.526844ZM7.30668 2.52684L5.30668 0.526844C5.18115 0.401308 5.01088 0.330783 4.83335 0.330783C4.65581 0.330783 4.48555 0.401308 4.36001 0.526844C4.23448 0.65238 4.16395 0.822643 4.16395 1.00018C4.16395 1.17771 4.23448 1.34797 4.36001 1.47351L5.89335 3.00018L4.36001 4.52684C4.29753 4.58882 4.24793 4.66255 4.21409 4.74379C4.18024 4.82503 4.16282 4.91217 4.16282 5.00018C4.16282 5.08819 4.18024 5.17532 4.21409 5.25656C4.24793 5.3378 4.29753 5.41154 4.36001 5.47351C4.42199 5.536 4.49572 5.58559 4.57696 5.61944C4.6582 5.65328 4.74534 5.67071 4.83335 5.67071C4.92136 5.67071 5.00849 5.65328 5.08973 5.61944C5.17097 5.58559 5.24471 5.536 5.30668 5.47351L7.30668 3.47351C7.37094 3.41331 7.42265 3.34098 7.4588 3.2607C7.49495 3.18041 7.51485 3.09375 7.51732 3.00574C7.5198 2.91772 7.50482 2.83008 7.47325 2.74788C7.44167 2.66569 7.39412 2.59056 7.33335 2.52684H7.30668Z"
      fill={color}
    />
  </svg>
);
