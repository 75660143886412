import styled, { DefaultTheme } from 'styled-components';

export const CollapsibleContainer = styled.div<{ withBorder: Boolean }>`
  ${({ theme, withBorder }) => {
    const { colors, sizes } = theme.foundations;
    return `
      width: 100%;
      min-height: ${sizes['11']};
      ${withBorder && `border-bottom: 1px solid ${colors.outline.light};`}
    `;
  }}
`;

export const CollapsibleButton = styled.button`
  ${({ theme, isActive }: { theme: DefaultTheme; isActive?: boolean }) => {
    const { typography, sizes, colors, spacing } = theme.foundations;
    return `
      display: flex;
      background: ${colors.palette.transparent};
      align-items: center;
      outline: 0;
      border: 0;
      height: ${sizes['11']};
      width: 100%;
      cursor: pointer;
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['05']};
      font-weight: ${typography.weights.medium};
      color: ${colors.text.default};
      &.isTab {
        border-radius: ${spacing['02']} 0px 0px ${spacing['02']};
        padding: 0 0 0 ${spacing['04']};
        ${isActive && `background-color: ${colors.background.secondary}`};
        
        :hover {
          background-color: ${colors.background.tertiary};
        }
      }
    `;
  }}
`;

export const CollapsibleIconContainer = styled.div`
  ${({ theme }) => {
    const { sizes, spacing } = theme.foundations;
    return `
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${sizes['06']};
      height: ${sizes['06']};
      margin-right: ${spacing['04']};
      transition: all 300ms ease-out;
      transform: rotate(0deg);
      &.open {
        transform: rotate(180deg);
      }
    `;
  }}
`;

export const CollapsibleContent = styled.div<{ maxContentHeight: number }>`
  ${({ theme, maxContentHeight }) => {
    const { spacing } = theme.foundations;
    return `
      transition: all 300ms ease-out;
      height: 0px;
      opacity: 0;
      overflow: hidden;
      &.open {
        height: ${maxContentHeight}px;
        opacity: 1;
        padding-bottom: ${spacing['05']};
      }
    `;
  }}
`;
