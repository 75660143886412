import styled from 'styled-components';

export const DropdownContainer = styled.div`
  display: flex;
`;

export const ModalContainer = styled.div`
  ${({ theme }) => {
    const { colors, radii, shadows, spacing } = theme.foundations;
    return `
      display: flex;
      flex-direction: column;
      min-width: 68px;
      background-color: ${colors.background.primary};
      padding: ${spacing['06']};
      box-shadow: ${shadows['02']};
      border-radius: ${radii.md};
      position: absolute;
      z-index: 1;
    `;
  }}
`;

export const TitleModalContainer = styled.div`
  ${({ theme }) => {
    const { typography, colors } = theme.foundations;
    return `
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: ${typography.sizes['04']};
      span {
        font-family: ${typography.fonts.body};
        font-weight: ${typography.weights.bold};
        font-size: ${typography.sizes['05']};
        color: ${colors.text.dark};
        line-height: 28px;
        letter-spacing: 0.25px;
      }
    `;
  }}
`;

export const CloseContainer = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

export const BlueText = styled.span`
  ${({ theme }) => {
    const { typography, colors } = theme.foundations;
    return `  
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['03']};
      color: ${colors.text.link};
      line-height: 21px;
      letter-spacing: 0.25px;
    `;
  }}
`;

export const OptionsContainer = styled.div`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return `
      display: flex;
      flex-direction: column;
      margin-top: ${spacing['04']};
      margin-bottom: ${spacing['06']};
    `;
  }}
`;

export const OptionContainer = styled.div`
  ${({ theme }) => {
    const { typography, spacing, colors } = theme.foundations;
    return `
      display: flex;
      justify-content: space-between;
      padding: ${spacing['03']};
      span {
        font-family: ${typography.fonts.body};
        font-weight: ${typography.weights.regular};
        font-size: ${typography.sizes['03']};
        color: ${colors.palette.neutral['500']};
        line-height: 21px;
        letter-spacing: 0.75px;
      }
    `;
  }}
`;
