import React, { useContext, useState } from 'react';

export interface TouchedFormProps {
  isTouched: boolean;
  setIsTouched: (value: boolean) => void;
}

export const TouchedFormContext = React.createContext({} as TouchedFormProps);

const TouchedFormProvide = TouchedFormContext.Provider;

export const TouchedFormProvider = ({ children }: { children: React.ReactNode }) => {
  const [isTouched, setIsTouched] = useState(false);

  return <TouchedFormProvide value={{ isTouched, setIsTouched }}>{children}</TouchedFormProvide>;
};

export const useTouchedForm = () => {
  const context = useContext(TouchedFormContext);
  return context;
};

export const withTouchedComponent = <P extends object>(Component: React.ComponentType<P>) => (
  props: P
) => (
  <TouchedFormProvider>
    <Component {...props} />
  </TouchedFormProvider>
);
