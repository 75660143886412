import React, { useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import _get from 'lodash/get';

// mutations
import { CREATE_FORM } from '../../../../../api/graphql/compliance/mutations';

// context
import { AlertContext } from '../../../../notifications/context/Alert/Alert';
import { UserContext } from '../../../../users/context/UserContext';
import { FormsContext } from '../../../context/FormsContext';

// helpers
import { buildAMNForm } from './AmendmentHelper';
import useStateModal from '../../../../utils/hooks/useStateModal';
import { FormikStub, useForm } from '../../../../utils/hooks/useForm';

// validation schema
import { validationSchema } from './AmendmentValidations';

export type AmendmentForm = {
  files: File[];
  explanation: string;
};

export interface UseAmendmentFormHandler {
  form: FormikStub<AmendmentForm>;
  onSubmitAmendmentForm: () => Promise<void>;
  submitting: boolean;
  modal: ReturnType<typeof useStateModal>;
}

export const AmendmentHandlerContext = React.createContext<UseAmendmentFormHandler>(
  {} as UseAmendmentFormHandler
);

export const AmendmentHandlerProvider = AmendmentHandlerContext.Provider;

const sanitizeErrorMessage = (message = '') => {
  return message.replace('GraphQL error:', '');
};

export const useAmendmentHandler = (): UseAmendmentFormHandler => {
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);
  const { activeDeal, refetchActiveDeal } = useContext(UserContext);
  const { refetchAll } = useContext(FormsContext);
  const modal = useStateModal('amendmentForm');
  const { hide, modalState } = modal;
  const initialValues: AmendmentForm = {
    files: [],
    explanation: '',
  };

  const form = useForm({
    initialValues,
    validationSchema: validationSchema(),
  });

  useEffect(() => {
    if (activeDeal) {
      form.setValues({
        ...form.values,
      });
    }
  }, [activeDeal, modalState.show]);

  const creationSuccess = () => {
    showSuccessToast({ title: 'Success!', description: 'Form was created successfully' });
    form.resetForm();
    hide();
    refetchAll(['complianceTracker', 'dealRegulation']);
    refetchActiveDeal && refetchActiveDeal();
  };

  const [createFormMutation, { loading: loadingForm }] = useMutation(CREATE_FORM, {
    onCompleted() {
      creationSuccess();
    },
    onError() {
      showErrorToast({ title: 'Error', description: 'An error occurred while saving the form' });
    },
  });

  const onSubmitAmendmentForm = async (): Promise<void> => {
    const dealId = _get(activeDeal, 'id', '');
    try {
      await createFormMutation({
        variables: buildAMNForm(form, dealId),
      });
    } catch (e) {
      showErrorToast({
        title: 'Error',
        description: sanitizeErrorMessage(e && _get(e, 'message')),
      });
    }
  };

  return {
    form,
    onSubmitAmendmentForm,
    submitting: loadingForm,
    modal,
  };
};
