// Vendors
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import _get from 'lodash/get';

// Helpers
import { maxPercentValueAllowed } from 'app/utils/helpers/helpers';

// Components
import ResetButton from '../resetButton/ResetButton';

// Constants
import { RFLabels } from './RangeFilterConstants';

// Styled components
import { DealSizeBody, DealSizeSubHeader } from './StyledRangeFilter';

import { ERangeType } from './RangeFilterType';

type IRangeProps = {
  value: {
    from: number | undefined;
    to: number | undefined;
  };
  handleChange: (value: any) => void;
  options?: {
    allowVoidEntries?: boolean;
    type?: ERangeType | string;
    setDisable?: (disable: boolean) => void;
    validations?: (min: number | undefined, max: number | undefined) => boolean;
  };
  disable?: boolean;
};

const DealSizeFilter = ({ options, value, handleChange, disable }: IRangeProps) => {
  const { from: minSelected, to: maxSelected } = value;
  const [error, setError] = useState<string>('');
  const setDisable = _get(options, 'setDisable');
  const allowVoidEntries = _get(options, 'allowVoidEntries', false);

  const validate = (min: number | undefined, max: number | undefined) => {
    if (options && options.validations && options.validations(min, max)) {
      return true;
    }
    if (!allowVoidEntries && min === undefined && max === undefined) {
      return true;
    }
    if (_get(options, 'type', ERangeType.NUMERIC) === ERangeType.PERCENTAGE && max && max > 100) {
      setError(RFLabels.PERCENT_LOWER);
      return true;
    }
    if (min !== undefined && max !== undefined) {
      if (min > max) {
        setError(RFLabels.MAX_LOWER_THAN_MIN);
        return true;
      }
    }

    setError('');
    return false;
  };

  const handleMinValueChange = (newValue: any) => {
    if (setDisable) {
      setDisable(validate(newValue.floatValue, maxSelected));
    }
    handleChange({ from: newValue.floatValue, to: maxSelected });
  };

  const handleMaxValueChange = (newValue: any) => {
    if (setDisable) {
      setDisable(validate(minSelected, newValue.floatValue));
    }
    handleChange({ from: minSelected, to: newValue.floatValue });
  };

  const handleResetAllOptions = () => {
    handleChange({ from: undefined, to: undefined });
    setError('');
  };

  const showValue = (newValue: number | undefined) => {
    if (newValue === undefined) {
      return '';
    }
    return newValue;
  };

  const handleAllowedProp = () => {
    if (_get(options, 'type', ERangeType.NUMERIC) === ERangeType.PERCENTAGE) {
      return maxPercentValueAllowed;
    }
    return undefined;
  };

  return (
    <>
      <DealSizeSubHeader>
        <ResetButton handleOnClick={handleResetAllOptions} />
      </DealSizeSubHeader>
      <DealSizeBody>
        <p className="error-label">{error}</p>
        <div className="input-container">
          <NumberFormat
            placeholder="Minimum"
            value={showValue(minSelected)}
            onValueChange={handleMinValueChange}
            allowNegative={false}
            decimalScale={2}
            displayType="input"
            thousandSeparator
            disabled={disable}
            isAllowed={handleAllowedProp()}
          />
          <div className="separator">
            <p>to</p>
          </div>
          <NumberFormat
            placeholder="Maximum"
            value={showValue(maxSelected)}
            allowNegative={false}
            decimalScale={2}
            displayType="input"
            thousandSeparator
            onValueChange={handleMaxValueChange}
            disabled={disable}
            isAllowed={handleAllowedProp()}
          />
        </div>
      </DealSizeBody>
    </>
  );
};

export default DealSizeFilter;
