import React from 'react';
import get from 'lodash/get';

import { IDeal } from 'app/utils/types/types';
import { Role as ERole, DealSide as EDealSide } from 'app/core-tools/due-diligence/types/types';
import DealInformationSettings from './components/DealInformationSettings/DealInformationSettings';
import VdrSettings from './components/VdrSettings/VdrSettings';
import GroupsSettings from './components/GroupsSettings/GroupSettings';
import MarketplaceSettings from './components/MarketplaceSettings/MarketplaceSettings.container';
import { EActiveSetting, validatePermissions, validateLightRole } from './SettingsModal';

function DueDiligenceActiveSettingsSelector({
  activeSetting,
  deal,
  originRole,
}: {
  activeSetting: EActiveSetting;
  deal?: IDeal;
  originRole: string;
}) {
  switch (activeSetting) {
    case EActiveSetting.DEAL_INFORMATION:
      return validatePermissions(get(deal, 'UserDeal.role'), get(deal, 'UserDeal.side')) &&
        validateLightRole(get(deal, 'mode'), originRole) ? (
        <DealInformationSettings />
      ) : (
        <GroupsSettings
          internal={false}
          showAddButton={validatePermissions(
            get(deal, 'UserDeal.role', ''),
            get(deal, 'UserDeal.side', '')
          )}
        />
      );

    case EActiveSetting.INVESTORS:
      return (
        <GroupsSettings
          internal={false}
          showAddButton={validatePermissions(
            get(deal, 'UserDeal.role', ''),
            get(deal, 'UserDeal.side', '')
          )}
        />
      );
    case EActiveSetting.BUYERS:
      return (
        <GroupsSettings
          internal={false}
          showAddButton={validatePermissions(
            get(deal, 'UserDeal.role', ''),
            get(deal, 'UserDeal.side', '')
          )}
        />
      );
    case EActiveSetting.MARKETPLACE:
      return <MarketplaceSettings />;
    case EActiveSetting.INTERNAL_GROUPS:
      return (
        <GroupsSettings
          internal
          showAddButton={validatePermissions(
            get(deal, 'UserDeal.role', ''),
            get(deal, 'UserDeal.side', '')
          )}
        />
      );
    case EActiveSetting.VDR:
      return <VdrSettings />;

    default:
      return get(deal, 'UserDeal.role') === ERole.ADMIN &&
        get(deal, 'UserDeal.side') === EDealSide.SELL ? (
        <DealInformationSettings />
      ) : (
        <GroupsSettings internal={false} />
      );
  }
}
DueDiligenceActiveSettingsSelector.displayName = 'DueDiligenceActiveSettingsSelector';

export default DueDiligenceActiveSettingsSelector;
