/* eslint-disable camelcase */
import { useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import { DealsContext } from 'app/compliance/context/DealsContext';
import { UserContext } from 'app/users/context/UserContext';
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { UPDATE_DEAL } from 'api/graphql/deals/mutations';
import { EDealStatus } from 'app/utils/types/types';
import {
  UPDATE_DEAL_MUTATION,
  UPDATE_DEAL_MUTATIONVariables,
} from 'api/graphql/deals/types/UPDATE_DEAL_MUTATION';
import { DEAL_FORM_LABELS } from 'app/utils/helpers/deal';
import { getError } from 'app/utils/helpers/helpers';
import { createDirtyFieldsMap, createErrorFieldsMap, useForm } from 'app/utils/hooks/useForm';
import { updateDealValidationSchema } from './DealInformationSettingsValidations';
import { UpdateDealForm } from './TypedDealInformationSettings';
import {
  dealUpdateFormInitialValues,
  mapDealValuesToForm,
  mapFormValuesToDeal,
} from './HelpersDealInformationSettings';

export const useDealInformationSettingsHandler = (isAttestation?: boolean) => {
  const { activeDeal, id: userId, currentFirm, refetchActiveDeal } = useContext(UserContext);
  const { dealsQuery } = useContext(DealsContext);
  const form = useForm<UpdateDealForm>({
    initialValues: dealUpdateFormInitialValues,
    validationSchema: updateDealValidationSchema,
  });
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);

  const resetFormErrorTouchState = () => {
    form.setTouched(createDirtyFieldsMap(dealUpdateFormInitialValues));
    form.setErrors(createErrorFieldsMap(dealUpdateFormInitialValues));
  };

  useEffect(() => {
    form.setValues(mapDealValuesToForm(activeDeal));
    const timeout = setTimeout(() => {
      resetFormErrorTouchState();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [activeDeal]);

  const [updateDealMutation, { loading }] = useMutation<
    UPDATE_DEAL_MUTATION,
    UPDATE_DEAL_MUTATIONVariables
  >(UPDATE_DEAL, {
    onCompleted: ({ updateDeal }) => {
      showSuccessToast({
        title: `${DEAL_FORM_LABELS.DEAL_UPDATE_SUCCESS_MESSAGE} ${get(updateDeal, 'name')}.`,
      });
      refetchActiveDeal && refetchActiveDeal();
      if (isAttestation) {
        dealsQuery.refetch && dealsQuery.refetch();
      }
      resetFormErrorTouchState();
    },
    onError: error => {
      showErrorToast({ title: getError(error) });
    },
  });

  const isDealOwner = get(activeDeal, 'DealOwner.id') === userId;
  const isStatusDDClosing =
    get(form.values, 'dealStatus.value') === EDealStatus.DUE_DILIGENCE_CLOSING;
  const hasActiveFirm = !!currentFirm;
  const hasEnoughMembersToShare = currentFirm && (currentFirm.firmMembersCount || 0) > 1;

  const updateDeal = () =>
    updateDealMutation({
      variables: mapFormValuesToDeal(form.values, {
        dealId: get(activeDeal, 'id', null),
        firmId: get(currentFirm, 'id', null),
        isStatusDDClosing,
      }),
    });

  return {
    updateDeal,
    form,
    isDealOwner,
    hasActiveFirm,
    hasEnoughMembersToShare,
    isStatusDDClosing,
    currentFirm,
    loadingUpdateDeal: loading,
  };
};
