import React from 'react';
import { AppState, Auth0Provider, User } from '@auth0/auth0-react';
import { ERoutePatterns } from 'app/core-tools/due-diligence/types/types';
import { useHistory } from 'react-router-dom';

export interface Auth0ProviderWithHistoryProps {
  children: React.ReactNode;
}

const Auth0ProviderWithHistory = ({
  children,
}: Auth0ProviderWithHistoryProps): React.ReactElement => {
  const history = useHistory();
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const onRedirectCallback = (appState?: AppState, user?: User) => {
    appState && appState.returnTo
      ? history.push(appState.returnTo)
      : history.push(ERoutePatterns.LOGIN);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}${ERoutePatterns.LOGIN}`,
        audience,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
