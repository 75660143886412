import * as React from 'react';
import { IconProps } from './types';

export const ExclamationMarkCircleIcon = ({
  width = '17',
  height = '16',
  color = 'currentColor',
  style = {},
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.667 4.667A.667.667 0 0 0 8 5.334V8a.667.667 0 1 0 1.333 0V5.334a.667.667 0 0 0-.666-.667Zm.613 5.747a.507.507 0 0 0-.06-.12l-.08-.1a.667.667 0 0 0-.727-.14.767.767 0 0 0-.22.14.667.667 0 0 0-.14.726.6.6 0 0 0 .36.36.626.626 0 0 0 .507 0 .6.6 0 0 0 .36-.36.667.667 0 0 0 .053-.253.908.908 0 0 0 0-.133.427.427 0 0 0-.053-.12Zm-.613-9.08a6.667 6.667 0 1 0 0 13.333 6.667 6.667 0 0 0 0-13.333Zm0 12a5.334 5.334 0 1 1 0-10.668 5.334 5.334 0 0 1 0 10.668Z"
      fill={color}
    />
  </svg>
);
