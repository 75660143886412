import React from 'react';
import { FilesIcon } from 'app/common/icons';
import CheckCircleIcon from 'app/marketplace/assets/checkCircleIcon';
import { ToastContainer } from './styledCopyLinkAlert';

interface IProps {
  message?: string;
}

const CopyLinkAlert = (props: IProps) => {
  const { message = 'Link copied to clipboard' } = props;
  return (
    <ToastContainer>
      <FilesIcon />
      <p>{message}</p>
      <CheckCircleIcon />
    </ToastContainer>
  );
};

export default CopyLinkAlert;
