import * as React from 'react';
import { IconProps } from './types';

export const EditIcon = ({
  color = 'currentColor',
  style = {},
  height = '20px',
  width = '20px',
}: IconProps): JSX.Element => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.17632 9.12516C6.70578 9.59392 6.44159 10.2308 6.44337 10.8944V13.5565H9.10553C9.76999 13.5583 10.4078 13.2941 10.8765 12.8236L17.2 6.49754L13.5025 2.79999L7.17632 9.12516ZM9.90884 11.8577C9.69537 12.0712 9.40629 12.1903 9.10564 12.1903H7.80965V10.8944C7.80876 10.5928 7.92884 10.3028 8.14231 10.0885L11.2253 7.00732L12.9927 8.77474L9.90884 11.8577ZM13.9578 7.80968L12.1903 6.04226L13.5023 4.73026L15.2698 6.49768L13.9578 7.80968Z"
      fill={color}
    />
    <path
      d="M5.3048 17.2H13.5024C14.8855 17.2 16.0072 16.0783 16.0072 14.6952V11.9627H14.6409V14.6952C14.6409 14.9967 14.5208 15.2867 14.3074 15.5002C14.0939 15.7137 13.8039 15.8337 13.5024 15.8337H5.3048C4.67593 15.8337 4.16625 15.324 4.16625 14.6952V6.49761C4.16625 5.86874 4.67593 5.35906 5.3048 5.35906H8.03732V3.9928H5.3048C3.92164 3.9928 2.79999 5.11445 2.79999 6.49761V14.6952C2.79999 16.0783 3.92164 17.2 5.3048 17.2Z"
      fill={color}
    />
  </svg>
);
