import React, { useRef } from 'react';
import * as S from './AlertDialogStyled';
import { CloseIcon } from 'app/common/icons';
import { HStack } from '../Stack/HStack';
import { Button } from '../Button/Button';

type AlertDialogOnClose = () => void;

interface AlertDialogContext {
  onClose?: AlertDialogOnClose;
  cancelRef: React.MutableRefObject<HTMLElement | null>;
}

interface AlertDialogProps {
  show?: boolean;
  onClose?: AlertDialogOnClose;
  actionButtons?: React.ReactNode | ((context: AlertDialogContext) => React.ReactNode);
  label?: string | React.ReactNode;
  description?: string | React.ReactNode;
  noCloseIcon?: boolean;
  alertDialogContentStyles?: React.CSSProperties;
}

export const AlertDialog = ({
  show = true,
  noCloseIcon,
  label,
  description,
  actionButtons,
  onClose,
  alertDialogContentStyles,
}: AlertDialogProps) => {
  const cancelRef = useRef<HTMLElement | null>(null);

  const defaultActionButtons = (
    <HStack spacing={16} justifyContent="flex-end">
      <Button ref={cancelRef as any} className="tertiary small">
        Cancel
      </Button>
      <Button className="primary small">Confirm</Button>
    </HStack>
  );

  const actionButtonsHandler =
    typeof actionButtons === 'function'
      ? actionButtons({ onClose, cancelRef })
      : actionButtons || defaultActionButtons;

  return show ? (
    <S.AlertDialogOverlay leastDestructiveRef={cancelRef}>
      <S.AlertDialogContent style={alertDialogContentStyles}>
        <S.AlertDialogBody>
          {!noCloseIcon && (
            <S.AlertDialogCloseButtonContainer>
              <S.AlertDialogCloseButton onClick={onClose}>
                <CloseIcon />
              </S.AlertDialogCloseButton>
            </S.AlertDialogCloseButtonContainer>
          )}
          <S.AlertDialogLabel>{label}</S.AlertDialogLabel>

          <S.AlertDialogDescription>{description}</S.AlertDialogDescription>
        </S.AlertDialogBody>
        <S.AlertDialogFooter>{actionButtonsHandler}</S.AlertDialogFooter>
      </S.AlertDialogContent>
    </S.AlertDialogOverlay>
  ) : null;
};
