import styled from 'styled-components';
import { ThemeAsProp, Checkbox as PokiCheckbox } from '@dealsyte/poki';
import { darken } from 'polished';

const rowHeight = 40;
const headerRowHeight = (rowHeight * 5) / 6;

export const SettingsDescription = styled.p`
  padding-bottom: 50px;
  font-size: ${({ theme }) => theme.fontSizes.regular};
`;

export const PermissionRow = styled.div<ThemeAsProp>`
  display: flex;
  align-items: center;
  height: ${rowHeight}px;
  padding: 0 20px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.rowSeparator};
`;

export const TitleRow = styled(PermissionRow)`
  background-color: #fafbfc;

  height: ${headerRowHeight}px;
  box-shadow: inset 0 7px 9px -8.5px rgba(161, 161, 161, 0.2);
  user-select: none;
  cursor: default;
`;

export const GroupName = styled.div`
  flex: 1;
`;

export const Checkbox = styled(PokiCheckbox)`
  border-color: #cacaca;
`;

export const CheckboxColumn = styled.div`
  flex-basis: 65px;
  width: 65px;
  min-width: 0;
  text-align: center;

  &:not(:last-child) {
    margin-right: 15px;
  }
`;

export const FileRoomPermissionsHeader = styled.h5`
  display: flex;
  align-items: center;

  height: ${headerRowHeight}px;
  padding: 0 20px 0 5px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.rowSeparator};

  &:hover {
    background-color: ${darken(0.01, '#fff')};
  }
`;

export const PermissionTableContainer = styled.div``;

export const FileRoomTag = styled.span`
  opacity: 0.6;
  margin-left: 0.3em;
`;

export const SideSection = styled.div`
  padding-bottom: 60px;
`;

export const IsCollapsedTag = styled.span<ThemeAsProp>`
  margin-left: auto;
  color: ${({ theme }) => theme.colors.primary.default};

  ${FileRoomPermissionsHeader}:hover & {
    text-decoration: underline;
  }
`;

export const EmptyTableMessage = styled(PermissionRow)`
  opacity: 0.7;
  font-style: italic;
`;
