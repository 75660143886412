// Libraries
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import compose from 'lodash/flowRight';

// Styled components
import * as S from './StyledSideDrawer';

// Types
import { SideDrawerBaseProps } from './TypesSideDrawer';

// HOC
import { ModalPromptOptions, withClosePrompt } from '../Modal/ModalClosePrompt';
import { withIntercomModifier } from './IntercomModifierHOC';

const portal = document.createElement('div');

const SideDrawerBaseComponent = ({
  show,
  onClose,
  children,
  progress,
  closeWhenClickOutside = true,
}: SideDrawerBaseProps) => {
  useEffect(() => {
    if (show) {
      portal.setAttribute('id', 'drawer-portal');
      document.body.append(portal);
    }
  }, [show]);

  const removePortal = () => {
    if (
      document.body.contains(portal) &&
      (portal.childElementCount < 2 || !portal.hasChildNodes())
    ) {
      document.body.removeChild(portal);
    }
  };

  const closeHandler = () => {
    closeWhenClickOutside && onClose && onClose();
  };

  return createPortal(
    <CSSTransition
      classNames="drawer-state"
      timeout={300}
      in={show}
      unmountOnExit
      onExited={removePortal}
    >
      <S.Backdrop onClick={closeHandler}>
        <S.SideDrawerContainer id="drawer-container" onClick={evt => evt.stopPropagation()}>
          {typeof progress === 'number' && <S.SideDrawerProgressBar percentage={progress} />}
          {children}
        </S.SideDrawerContainer>
      </S.Backdrop>
    </CSSTransition>,
    portal
  );
};

interface ComposedSideDrawerBase extends ModalPromptOptions, SideDrawerBaseProps {}

export const SideDrawerBase: (props: ComposedSideDrawerBase) => JSX.Element = compose(
  withClosePrompt,
  withIntercomModifier
)(SideDrawerBaseComponent);
