import styled from 'styled-components';
import { History } from 'history';
import { Button } from '@dealsyte/poki';

type ISidebarSubTitleProps = {
  disabled?: boolean;
  to?: History.LocationDescriptor<any>;
};

export const MainContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 100vh;
  align-items: stretch;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

export const SidebarSection = styled.section`
  padding: 0 24px 15px 24px;

  &:last-child {
    padding-bottom: 0;
  }
`;

export const SidebarTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

//@ts-ignore
export const SidebarButton = styled(Button)<{ isMenuOpen?: boolean }>`
  opacity: ${({ isMenuOpen }) => (isMenuOpen === undefined ? 1 : isMenuOpen === true ? 1 : 0)};
  background-image: none;
  border-radius: 3px;
  position: relative;
  height: 18px;
  width: 18px;
  padding: 0;
  color: ${({ theme }) => theme.palette.gray.darker};
  font-weight: 400;
  border-color: ${({ theme }) => theme.palette.gray.darker};

  ${SidebarTitleContainer}:hover & {
    opacity: 1;
  }
`;

export const SidebarTitle = styled.h6<{ to?: History.LocationDescriptor<any> }>`
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizes.xxsmall};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.gray.darker};
`;

export const SidebarSubTitle = styled.h4<ISidebarSubTitleProps>`
  display: block;
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  margin-bottom: 5px;
  text-decoration: none;
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.gray.dark : theme.palette.gray.darker};
`;

export const SidebarText = styled.a<{ disabled?: boolean; to?: History.LocationDescriptor<any> }>`
  display: flex;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  margin: 0 0 15px 5px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.gray.dark : theme.palette.gray.darker};

  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }
`;
