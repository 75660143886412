import * as React from 'react';
import { IconProps } from './types';

export const OpenEyeIcon = ({
  width = '14px',
  height = '12px',
  color = 'currentColor',
  style = {},
}: IconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 14 12" fill="none">
    <path
      d="M13.6133 5.73366C12.2667 2.60699 9.73332 0.666992 6.99998 0.666992C4.26665 0.666992 1.73332 2.60699 0.38665 5.73366C0.349941 5.81777 0.330994 5.90855 0.330994 6.00033C0.330994 6.0921 0.349941 6.18288 0.38665 6.26699C1.73332 9.39366 4.26665 11.3337 6.99998 11.3337C9.73332 11.3337 12.2667 9.39366 13.6133 6.26699C13.65 6.18288 13.669 6.0921 13.669 6.00033C13.669 5.90855 13.65 5.81777 13.6133 5.73366ZM6.99998 10.0003C4.87998 10.0003 2.88665 8.47366 1.73332 6.00033C2.88665 3.52699 4.87998 2.00033 6.99998 2.00033C9.11998 2.00033 11.1133 3.52699 12.2667 6.00033C11.1133 8.47366 9.11998 10.0003 6.99998 10.0003ZM6.99998 3.33366C6.47257 3.33366 5.95699 3.49006 5.51846 3.78307C5.07993 4.07609 4.73814 4.49257 4.5363 4.97984C4.33447 5.46711 4.28166 6.00328 4.38456 6.52057C4.48745 7.03785 4.74143 7.513 5.11437 7.88594C5.48731 8.25888 5.96246 8.51286 6.47974 8.61575C6.99703 8.71865 7.5332 8.66584 8.02047 8.464C8.50774 8.26217 8.92422 7.92038 9.21724 7.48185C9.51025 7.04331 9.66665 6.52774 9.66665 6.00033C9.66665 5.29308 9.3857 4.6148 8.8856 4.11471C8.3855 3.61461 7.70723 3.33366 6.99998 3.33366ZM6.99998 7.33366C6.73628 7.33366 6.47849 7.25546 6.25922 7.10895C6.03996 6.96244 5.86906 6.7542 5.76814 6.51057C5.66723 6.26693 5.64082 5.99885 5.69227 5.7402C5.74372 5.48156 5.8707 5.24399 6.05717 5.05752C6.24364 4.87105 6.48122 4.74406 6.73986 4.69261C6.9985 4.64116 7.26659 4.66757 7.51023 4.76849C7.75386 4.8694 7.9621 5.0403 8.10861 5.25956C8.25512 5.47883 8.33332 5.73662 8.33332 6.00033C8.33332 6.35395 8.19284 6.69309 7.94279 6.94313C7.69274 7.19318 7.35361 7.33366 6.99998 7.33366Z"
      fill={color}
    />
  </svg>
);
