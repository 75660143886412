import { EFormStatus } from 'app/compliance/types/formsTypes';
import { RuleContext, RuleFn, RuleKeys, WorkflowContext } from './DealRegulationWorkflowTypes';

// Utility functions

const findWorkflowContextByIdentifier = (
  identifier: string | string[],
  workflow: WorkflowContext
) => {
  const identifierIsArray = (id: string | string[]) => Array.isArray(id);
  if (identifierIsArray(identifier)) {
    return workflow.find(wf => {
      const isWfIdArray = identifierIsArray(wf.identifier);
      return isWfIdArray
        ? (wf.identifier as string[]).some(id => identifier.includes(id))
        : identifier.includes(wf.identifier as string);
    });
  }
  return workflow.find(wf => {
    const isWfIdArray = identifierIsArray(wf.identifier);
    return isWfIdArray
      ? (wf.identifier as string[]).some(id => id === identifier)
      : wf.identifier === identifier;
  });
};

// Workflow rules

const FormSubmitted = (options?: { identifier: string[] }) => (
  ctx: RuleContext,
  workflow: WorkflowContext
) => {
  if (options && options.identifier && options.identifier.length) {
    return workflow
      .filter(config => {
        if (Array.isArray(config.identifier)) {
          return config.identifier.some(id => options.identifier.includes(id));
        }
        return options.identifier.includes(config.identifier);
      })
      .every(wf => {
        if (wf.metadata.forms && wf.metadata.forms.length) {
          return wf.metadata.forms.length > 0;
        }
        return false;
      });
  }
  return ctx.metadata.forms ? ctx.metadata.forms.length > 0 : false;
};

const FormWithStatus = (options?: { statuses: EFormStatus[]; identifier: string }) => (
  ctx: RuleContext,
  workflow: WorkflowContext
) => {
  const identifier = (options && options.identifier) || ctx.identifier;
  const context = findWorkflowContextByIdentifier(identifier, workflow);
  const allForms = (context && context.metadata && context.metadata.forms) || [];
  const filteredForms = allForms.filter(f => f.type === identifier);
  const condition = filteredForms.some(f =>
    ((options && options.statuses) || []).includes(f.status)
  );

  return condition;
};

const AlwaysReady = () => () => {
  return true;
};

export const WORKFLOW_RULES: Record<RuleKeys, (options?: any) => RuleFn<RuleContext>> = {
  AlwaysReady,
  FormSubmitted,
  FormWithStatus,
};
