import * as React from 'react';
import { IconProps } from './types';

export const BlockIcon = ({
  width = '11',
  height = '10',
  color = 'currentColor',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.33423 0.000610352C4.34532 0.000610352 3.37863 0.293855 2.55638 0.843262C1.73413 1.39267 1.09327 2.17356 0.714833 3.08719C0.336395 4.00082 0.237378 5.00616 0.430304 5.97606C0.62323 6.94597 1.09943 7.83688 1.7987 8.53614C2.49796 9.23541 3.38887 9.71161 4.35878 9.90454C5.32868 10.0975 6.33402 9.99845 7.24765 9.62001C8.16128 9.24157 8.94217 8.60071 9.49158 7.77846C10.041 6.95622 10.3342 5.98952 10.3342 5.00061C10.3342 4.344 10.2049 3.69382 9.95363 3.08719C9.70236 2.48057 9.33406 1.92937 8.86976 1.46508C8.40547 1.00078 7.85428 0.632486 7.24765 0.381213C6.64102 0.129939 5.99084 0.000610352 5.33423 0.000610352ZM5.33423 9.00061C4.27337 9.00061 3.25595 8.57918 2.5058 7.82904C1.75566 7.07889 1.33423 6.06148 1.33423 5.00061C1.33313 4.11228 1.63074 3.24938 2.17923 2.55061L7.78423 8.15561C7.08546 8.7041 6.22256 9.00171 5.33423 9.00061ZM8.48923 7.45061L2.88423 1.84561C3.583 1.29712 4.4459 0.999506 5.33423 1.00061C6.3951 1.00061 7.41251 1.42204 8.16266 2.17218C8.9128 2.92233 9.33423 3.93974 9.33423 5.00061C9.33533 5.88894 9.03772 6.75184 8.48923 7.45061Z"
      fill={color}
    />
  </svg>
);
