import * as React from 'react';
import { IconProps } from './types';

export const MegaphoneIcon = ({
  width = '13px',
  height = '14px',
  color = 'currentColor',
  style = {},
}: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    viewBox="0 0 13 14"
  >
    <path
      d="M12.328.335A.666.666 0 0 0 11.66 1v.425a6.025 6.025 0 0 1-4.667 2.242h-4a2.002 2.002 0 0 0-2 2v1.333a2.002 2.002 0 0 0 2 2h.323L1.715 12.74a.666.666 0 0 0 .612.929h2.667a.665.665 0 0 0 .613-.404l1.816-4.237a6.019 6.019 0 0 1 4.238 2.216v.425a.666.666 0 1 0 1.333 0V1.001a.665.665 0 0 0-.666-.666ZM2.994 7.668a.667.667 0 0 1-.667-.667V5.668a.667.667 0 0 1 .667-.667h.667v2.667h-.667Zm1.56 4.667H3.34L4.767 9h1.216l-1.428 3.334Zm7.107-2.986a7.368 7.368 0 0 0-4.667-1.681h-2V5.001h2a7.369 7.369 0 0 0 4.667-1.681v6.03Z"
      fill={color}
    />
  </svg>
);
