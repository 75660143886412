import React, { useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { DayValue } from 'react-modern-calendar-datepicker';
import { isArray } from 'lodash';

// mutations
import { CREATE_FORM } from 'api/graphql/compliance/mutations';

// context
import { AlertContext } from '../../../../notifications/context/Alert/Alert';
import { FormsContext } from '../../../../compliance/context/FormsContext';

// Helpers
import { buildPSTForm, PST_VALUE_INFERENCE_MAP } from './PSTFormHelpers';
import { mapFormData } from '../../../../utils/helpers/formDataMappers';

// Hooks
import useStateModal from '../../../../utils/hooks/useStateModal';
import { UseMultiStepForm, useMultiStepForm } from '../../../../utils/hooks/useMultiStepForm';

// Validations
import { validationSchema } from './PSTFormValidations';

export type PSTForm = {
  alreadyInvestedEntity: boolean;
  description: string;
  discloseDate: DayValue;
  entityName: string;
  hasInteractedWithOtherIndividual: boolean;
  interactionWithIndividualDetails: string;
  investmentAmount: number;
  isIssuerPubliclyTradedCompany: boolean;
  isSigned: boolean;
  isUserRelatedWithEntity: boolean;
  otherParticipation: string;
  otherSellingCompensations: string;
  participation: { label: string; value: string } | null;
  previousInvestments: string;
  relationshipWithEntityDetails: string;
  securityType: string;
  sellingCompensations: { label: string; value: string }[];
  symbolAndExchange: string;
  transactionAmount: number;
  transactionDate: DayValue;
  willReceiveSellingCompensation: boolean;
  files: File[];
  noteComment: string;
};

const steps = [
  {
    entityName: '',
    securityType: '',
    transactionAmount: 0,
    transactionDate: null,
  },
  {
    description: '',
    otherParticipation: '',
    participation: null,
  },
  {
    isIssuerPubliclyTradedCompany: undefined,
    symbolAndExchange: '',
    previousInvestments: '',
  },
  {
    isUserRelatedWithEntity: undefined,
    relationshipWithEntityDetails: '',
    hasInteractedWithOtherIndividual: undefined,
    interactionWithIndividualDetails: '',
  },
  {
    willReceiveSellingCompensation: undefined,
    sellingCompensations: [],
    otherSellingCompensations: '',
    alreadyInvestedEntity: undefined,
    investmentAmount: 0,
    discloseDate: null,
  },
  {
    files: [],
  },
  {
    noteComment: '',
  },
  {
    isSigned: undefined,
  },
];

export interface UsePSTFormHandler {
  form: UseMultiStepForm<PSTForm>;
  onSubmitPSTForm: () => Promise<void>;
  submitting: boolean;
  modal: ReturnType<typeof useStateModal>;
  isEdit: boolean;
}

export const PSTFormHandlerContext = React.createContext<UsePSTFormHandler>(
  {} as UsePSTFormHandler
);
export const PSTFormHandlerProvider = PSTFormHandlerContext.Provider;

export const usePSTFormHandler = (): UsePSTFormHandler => {
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);
  const modal = useStateModal('PSTFormDrawer');
  const {
    hide,
    modalState: { props, show },
  } = modal;
  const {
    regulatoryProfile,
    regulatoryFormsCount,
    getFormQuery,
    updateFormMutation,
    attestationFormsQuery,
  } = useContext(FormsContext);
  const { refetch: refetchPSTForms } = attestationFormsQuery;
  const { refetch } = regulatoryProfile.query;
  const { refetchPSTCount } = regulatoryFormsCount;
  const [getForm, { data: formData }] = getFormQuery;
  const [updateForm] = updateFormMutation;

  const isEdit = props && props.isEdit;
  const formId = props && props.formId;

  const form = useMultiStepForm<PSTForm>({
    steps,
    validationSchema,
  });

  const getFormData = async () => {
    if (formId) {
      await getForm({ variables: { id: formId } });
    }
  };

  useEffect(() => {
    if (show && isEdit) {
      getFormData();
    }
  }, [show]);

  useEffect(() => {
    if (formData && formData.form) {
      const mappedValues = mapFormData<PSTForm>(
        formData.form.content,
        steps.reduce((prev, curr) => ({ ...prev, ...curr }), {}) as PSTForm,
        PST_VALUE_INFERENCE_MAP,
        {
          sellingCompensations: value => {
            let sellingCompensations;
            let otherSellingCompensations;

            if (isArray(value)) {
              sellingCompensations = [];
              otherSellingCompensations = '';
            } else {
              sellingCompensations = (value && value.sellingCompensations) || [];
              otherSellingCompensations = (value && value.otherSellingCompensations) || '';
            }

            return {
              sellingCompensations,
              otherSellingCompensations,
            };
          },
        }
      );
      form.setValues(mappedValues);
    }
  }, [formData]);

  const resetAndCleanUp = () => {
    hide();
    form.resetForm();
    refetch && refetch();
    refetchPSTCount && refetchPSTCount();
    refetchPSTForms && refetchPSTForms();
  };

  const [createFormMutation, { loading: loadingForm }] = useMutation(CREATE_FORM, {
    onCompleted() {
      showSuccessToast({ title: 'Success!', description: 'Form was created successfully' });
      resetAndCleanUp();
    },
    onError() {
      showErrorToast({ title: 'Error', description: 'An error occurred while saving the form' });
    },
  });

  const submitting = loadingForm;

  const onSubmitPSTForm = async (): Promise<void> => {
    const { values } = form;
    if (isEdit) {
      await updateForm({
        variables: {
          id: formId,
          content: buildPSTForm(values).content,
        },
      });
      showSuccessToast({ title: 'Success!', description: 'Form was updated successfully' });
      resetAndCleanUp();
    } else {
      createFormMutation({
        variables: buildPSTForm(values),
      });
    }
  };

  return { form, onSubmitPSTForm, submitting, modal, isEdit };
};
