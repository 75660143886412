import React, { useState } from 'react';
import get from 'lodash/get';

import { AlertDialog } from '../AlertDialog/AlertDialog';
import { HStack } from '../Stack/HStack';
import { Button } from '../Button/Button';
import { InfoIcon } from 'app/common/icons';

const defaultConfig = {
  title: (
    <HStack spacing={16} alignItems="center">
      <InfoIcon />
      <span>Dismissing your report?</span>
    </HStack>
  ),
  description:
    'You have not submitted the report yet, all the data you entered will be lost. Are you sure you want to dismiss the report?',
  confirmLabel: 'Dismiss',
  declineLabel: 'No',
};

type AlertUIConfig = {
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  confirmLabel?: string | React.ReactNode;
  declineLabel?: string | React.ReactNode;
};

interface ModalClosePromptProps {
  onAbort: () => void;
  onConfirm: () => void;
  visible: boolean;
  uiConfig?: (config: AlertUIConfig) => AlertUIConfig;
}

const ModalClosePrompt: React.FunctionComponent<ModalClosePromptProps> = (
  props: ModalClosePromptProps
) => {
  const { onAbort, onConfirm, visible, uiConfig } = props;

  const config = (uiConfig && uiConfig(defaultConfig)) || defaultConfig;

  if (!visible) {
    return null;
  }

  return (
    <AlertDialog
      onClose={onAbort}
      show
      label={config.title}
      description={config.description}
      actionButtons={({ cancelRef, onClose }) => (
        <HStack justifyContent="flex-end" spacing={16}>
          <Button ref={cancelRef as any} className="tertiary" onClick={onClose}>
            {config.declineLabel}
          </Button>
          <Button onClick={onConfirm}>{config.confirmLabel}</Button>
        </HStack>
      )}
    />
  );
};

export default ModalClosePrompt;

export interface ModalPromptRenderProps {
  closeWithPrompt: () => void;
}

export interface ModalPromptOptions {
  promptOnClose?: boolean;
  promptConfig?: {
    onConfirm?: () => void;
    onAbort?: () => void;
    uiConfig?: (config: AlertUIConfig) => AlertUIConfig;
  };
  children: React.ReactNode | ((renderProps: ModalPromptRenderProps) => React.ReactNode);
}

export const withClosePrompt = <P extends ModalPromptOptions>(
  ModalComponent: React.ComponentType<P>
) => (props: P) => {
  const [isCancelling, setIsCancelling] = useState(false);
  const modalClose = get(props, 'onClose', () => {});
  const onClose = () => {
    if (props.promptOnClose) {
      setIsCancelling(true);
    } else {
      setIsCancelling(false);
      modalClose();
    }
  };

  const onAbort = () => {
    setIsCancelling(false);
    if (props.promptConfig && props.promptConfig.onAbort) {
      props.promptConfig.onAbort();
    }
  };

  const onConfirm = () => {
    setIsCancelling(false);
    modalClose();
    if (props.promptConfig && props.promptConfig.onConfirm) {
      props.promptConfig.onConfirm();
    }
  };

  const uiConfig = (props.promptConfig && props.promptConfig.uiConfig) || undefined;

  const renderChildren =
    typeof props.children === 'function'
      ? props.children({ closeWithPrompt: onClose })
      : props.children;

  return (
    <>
      <ModalComponent {...{ ...props, onClose, children: renderChildren }} />
      {props.promptOnClose && (
        <ModalClosePrompt
          visible={isCancelling}
          onAbort={onAbort}
          onConfirm={onConfirm}
          uiConfig={uiConfig}
        />
      )}
    </>
  );
};
