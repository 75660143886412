// Vendors
import React, { useState } from 'react';

// Components
import ResetButton from '../resetButton/ResetButton';

// Styled components
import { DealTypeSubHeader, DealTypeBody } from './StyledCustomCheckboxFilter';
import {
  Checkbox,
  CheckboxItemWrapper,
  CheckboxLabel,
} from '../../../sections/marketplaceBrowser/StyledMarketplaceBrowser';
import { getDealStatusOption } from '../../../helpers/index';
import { getDealTypeLabel } from '../../../../utils/helpers/deal';
import ViewMoreButton from '../viewMoreButton/ViewMoreButton';

type typeValue = {
  filterParent: string | null;
  count: number;
  filterElement: string;
  checked: boolean;
};

type typeFilters = {
  filterName: string;
  values: typeValue[];
};

type IProps = {
  filterName: string;
  filters: typeFilters;
  filtersSelected: string[];
  onChangeFilter: (filters: string[]) => void;
  marketplaceAccessEnabled?: boolean;
};

const CustomCheckboxFilter = ({
  filters,
  filtersSelected,
  filterName,
  onChangeFilter,
  marketplaceAccessEnabled,
}: IProps) => {
  const [viewMore, setViewMore] = useState(false);

  const handleCheckFilter = (filter: string) => {
    if (filtersSelected.includes(filter)) {
      onChangeFilter([...filtersSelected.filter((x: string) => x !== filter)]);
    } else {
      onChangeFilter([...filtersSelected, filter]);
    }
  };

  const handleSelectAllOptions = () => {
    onChangeFilter(filters.values.map((item: typeValue) => item.filterElement));
  };

  const handleResetAllOptions = () => {
    onChangeFilter([]);
  };

  const isChecked = (filterElement: string) => filtersSelected.includes(filterElement);

  const buildItemName = (filterElement: string) => {
    if (filterName === 'TYPE') return getDealTypeLabel(filterElement);
    if (filterName === 'STATUS') return getDealStatusOption(filterElement);
    return '';
  };

  const renderItems = (elements: typeValue[]) => {
    return elements.map((item: typeValue) => (
      <CheckboxItemWrapper>
        <Checkbox
          type="checkbox"
          name="checkboxName1"
          onChange={() => handleCheckFilter(item.filterElement)}
          checked={isChecked(item.filterElement)}
          disabled={item.count === 0}
        />
        <CheckboxLabel className={item.count === 0 ? 'label-disabled' : undefined}>
          {buildItemName(item.filterElement)}
        </CheckboxLabel>
        <div className="item-count">{item.count}</div>
      </CheckboxItemWrapper>
    ));
  };

  const renderMoreThanEight = () => {
    const firstFilters = filters.values.slice(0, 8);
    return (
      <>
        {viewMore ? renderItems(filters.values) : renderItems(firstFilters)}
        <ViewMoreButton display={viewMore} handleOnClick={() => setViewMore(true)} />
      </>
    );
  };

  return (
    <>
      {marketplaceAccessEnabled && (
        <DealTypeSubHeader>
          <div onClick={() => handleSelectAllOptions()} style={{ cursor: 'pointer' }}>
            <p>Select all</p>
          </div>
          <div>
            <div className="middle">.</div>
          </div>
          <ResetButton handleOnClick={handleResetAllOptions} />
        </DealTypeSubHeader>
      )}
      <DealTypeBody>
        {filters && filters.values.length > 8 ? renderMoreThanEight() : renderItems(filters.values)}
      </DealTypeBody>
    </>
  );
};

export default CustomCheckboxFilter;
