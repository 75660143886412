export type IProps = {
  show: boolean;
  props: {
    dealSelected?: any;
    refetch: () => void;
    type?: string;
  };
};

export enum CollaborateTypeEnum {
  EXECUTION = 'EXECUTION',
  RESEARCH = 'RESEARCH',
  OTHER = 'OTHER',
}
