import React from 'react';
import dayjs from 'dayjs';
import {
  EventMessageAuthorLabel,
  EventMessageBody,
  EventMessageBodyContainer,
  EventMessageBubble,
  EventMessageContainer,
  EventMessageIconContainer,
  EventMessageLeftSection,
  EventMessageRightSection,
  TimeStamp,
} from './MessageStyled';
import { PenIcon } from '../../icons/PenIcon';

interface EventMessageProps {
  message: {
    author: string;
    isAuthor?: boolean;
    body: string | (() => JSX.Element);
    createdAt: string;
  };
  icon?: JSX.Element;
}

export const EventMessage = ({ message, icon }: EventMessageProps): JSX.Element => {
  const renderIcon = icon || <PenIcon />;
  const body =
    typeof message.body === 'function' ? (message.body as () => JSX.Element)() : message.body;
  return (
    <EventMessageContainer>
      <EventMessageBubble>
        <EventMessageLeftSection>
          <EventMessageIconContainer>{renderIcon}</EventMessageIconContainer>
        </EventMessageLeftSection>
        <EventMessageRightSection>
          <EventMessageBodyContainer>
            <EventMessageAuthorLabel>{message.author}</EventMessageAuthorLabel>
            <EventMessageBody>&nbsp;{body}</EventMessageBody>
          </EventMessageBodyContainer>
          <TimeStamp className="noPadding">
            {dayjs(message.createdAt).format('HH:mm · MMM DD, YYYY')}
          </TimeStamp>
        </EventMessageRightSection>
      </EventMessageBubble>
    </EventMessageContainer>
  );
};
