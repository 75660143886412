import * as React from 'react';
import { IconProps } from './types';

export const LinkArrow = ({
  color = 'currentColor',
  width = '6',
  height = '6',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 6 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M5.96 3.31034C5.9362 3.24896 5.90052 3.19289 5.855 3.14534L3.355 0.645337C3.30838 0.598718 3.25304 0.561737 3.19212 0.536507C3.13121 0.511277 3.06593 0.498291 3 0.498291C2.86685 0.498291 2.73915 0.551185 2.645 0.645337C2.59838 0.691956 2.5614 0.747301 2.53617 0.808212C2.51094 0.869123 2.49795 0.934407 2.49795 1.00034C2.49795 1.13349 2.55085 1.26119 2.645 1.35534L4.295 3.00034H0.5C0.367392 3.00034 0.240215 3.05302 0.146447 3.14678C0.0526785 3.24055 0 3.36773 0 3.50034C0 3.63295 0.0526785 3.76012 0.146447 3.85389C0.240215 3.94766 0.367392 4.00034 0.5 4.00034H4.295L2.645 5.64534C2.59814 5.69182 2.56094 5.74712 2.53555 5.80805C2.51017 5.86898 2.4971 5.93433 2.4971 6.00034C2.4971 6.06634 2.51017 6.1317 2.53555 6.19263C2.56094 6.25355 2.59814 6.30885 2.645 6.35534C2.69148 6.4022 2.74678 6.4394 2.80771 6.46478C2.86864 6.49017 2.93399 6.50324 3 6.50324C3.06601 6.50324 3.13136 6.49017 3.19229 6.46478C3.25322 6.4394 3.30852 6.4022 3.355 6.35534L5.855 3.85534C5.90052 3.80779 5.9362 3.75171 5.96 3.69034C6.01001 3.56861 6.01001 3.43207 5.96 3.31034Z"
      fill={color}
    />
  </svg>
);
