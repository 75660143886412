import * as React from 'react';
import { IconProps } from './types';

export const UnmarkedStar = ({
  width = '14px',
  height = '13px',
  color = '#47455F',
}: IconProps): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 14 13" fill="none">
    <path
      d="M13.6667 4.94664C13.6246 4.8247 13.548 4.71759 13.4462 4.63831C13.3445 4.55903 13.2219 4.51097 13.0933 4.49997L9.3 3.94664L7.6 0.499975C7.54541 0.387261 7.46018 0.292203 7.35406 0.22569C7.24795 0.159178 7.12524 0.123901 7 0.123901C6.87477 0.123901 6.75206 0.159178 6.64595 0.22569C6.53983 0.292203 6.4546 0.387261 6.4 0.499975L4.70001 3.93997L0.906672 4.49997C0.783287 4.51751 0.667288 4.56928 0.571839 4.64941C0.47639 4.72954 0.405312 4.83482 0.366672 4.95331C0.331302 5.06909 0.328128 5.19232 0.35749 5.30977C0.386853 5.42722 0.447644 5.53446 0.533339 5.61998L3.28667 8.28664L2.62001 12.0733C2.59621 12.1983 2.60867 12.3275 2.65592 12.4456C2.70317 12.5637 2.78324 12.6659 2.88667 12.74C2.98748 12.812 3.10639 12.8546 3.23004 12.8628C3.35369 12.8711 3.47719 12.8447 3.58667 12.7866L7 11.0066L10.4 12.7933C10.4936 12.8461 10.5992 12.8737 10.7067 12.8733C10.8479 12.8738 10.9856 12.8295 11.1 12.7466C11.2034 12.6725 11.2835 12.5704 11.3308 12.4523C11.378 12.3341 11.3905 12.205 11.3667 12.08L10.7 8.29331L13.4533 5.62664C13.5496 5.54509 13.6207 5.43791 13.6585 5.31754C13.6963 5.19717 13.6991 5.06856 13.6667 4.94664ZM9.56667 7.61331C9.48849 7.68893 9.42999 7.78254 9.39629 7.88596C9.3626 7.98938 9.35471 8.09947 9.37334 8.20664L9.85334 11L7.34667 9.66664C7.25022 9.61528 7.14261 9.58842 7.03334 9.58842C6.92406 9.58842 6.81646 9.61528 6.72 9.66664L4.21334 11L4.69334 8.20664C4.71196 8.09947 4.70408 7.98938 4.67038 7.88596C4.63668 7.78254 4.57819 7.68893 4.50001 7.61331L2.50001 5.61331L5.30667 5.20664C5.41467 5.19162 5.51734 5.15034 5.60567 5.08641C5.69401 5.02248 5.76531 4.93787 5.81334 4.83997L7 2.29997L8.25334 4.84664C8.30137 4.94453 8.37267 5.02915 8.46101 5.09308C8.54934 5.157 8.65201 5.19829 8.76 5.21331L11.5667 5.61998L9.56667 7.61331Z"
      fill={color}
    />
  </svg>
);
