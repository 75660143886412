import uiActionTypes from './uiActionTypes';

export const showModal = (modalId: string, props?: any) => ({
  type: uiActionTypes.SHOW_MODAL,
  payload: {
    modalId,
    props,
  },
});

export const hideModal = (modalId: string) => ({
  type: uiActionTypes.HIDE_MODAL,
  payload: {
    modalId,
  },
});

export const setRedirecting = (redirecting: boolean) => ({
  type: uiActionTypes.SET_REDIRECTING,
  payload: {
    redirecting,
  },
});
