import * as React from 'react';
import { IconProps } from './types';

interface ChevronIconProps extends IconProps {
  size?: number;
}

export const ChevronIcon = ({
  color = 'currentColor',
  width,
  height,
  style = {},
  size = 17,
}: ChevronIconProps): JSX.Element => (
  <svg
    width={style.width || width || size}
    height={style.height || height || size}
    viewBox="0 0 17 16"
    style={style}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 6L8.5 10L12.5 6"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
