import { DealSide, Role } from 'app/core-tools/due-diligence/types/types';

export type Invitation = {
  email: string;
  invitationId: string | null;
  role: Role;
  expired: boolean | null;
  expiresAt: any | null;
};

export type IAction =
  | { type: 'add' }
  | { type: 'reset' }
  | { type: 'remove'; invitationIndex: number }
  | { type: 'modify'; invitationIndex: number; invitation: Partial<Invitation> };

export enum EOptionDescription {
  USER = 'Cannot submit answers and documents to the buyers/investors or invite new members to the group.',
  ADMIN = 'Can submit answers and documents to the buyers/investors and invite new members to the group.',
}

export type IProps = {
  dealId: string;
  show: boolean;
  setShow: (value: boolean) => void;
  side: DealSide;
  organizationId: string;
  organizationName: string;
  refetchInvitations?: Function;
};
