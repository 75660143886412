import { PUBLIC_ROUTES } from 'app/core-tools/due-diligence/types/types';

// we shouldn't be using this, only on exceptional cases because it gets bound to some specifics
export const getStyledComponentStyles = (
  StyledComponent: any,
  index: number = 0
): CSSStyleDeclaration | undefined => {
  const componentClass = StyledComponent().type.styledComponentId;
  
  const components = document.getElementsByClassName(componentClass);
  if (components[index]) return window.getComputedStyle(components[index]);
  return undefined;
};

export const isPublicRoute = (active_route?: string) =>
  active_route && PUBLIC_ROUTES.reduce((acc, route) => acc || active_route.includes(route), false);

export const handleBottomReached = (evt: any, callback: any) => {
  if (evt.target) {
    const { scrollTop, scrollHeight, clientHeight } = evt.target;
    if (scrollTop + clientHeight + 10 >= scrollHeight) {
      callback();
    }
  }
};
