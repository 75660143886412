import * as React from 'react';
import { IconProps } from './types';

export const CheckCircleOutlineIcon = ({
  width = '20',
  height = '20',
  color = 'currentColor',
  style,
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.72 6.79L8.43 11.09L6.78 9.44C6.69035 9.33532 6.58003 9.2503 6.45597 9.19027C6.3319 9.13025 6.19677 9.09652 6.05905 9.0912C5.92133 9.08588 5.784 9.10909 5.65567 9.15936C5.52735 9.20964 5.4108 9.28589 5.31335 9.38335C5.21589 9.4808 5.13963 9.59735 5.08936 9.72568C5.03908 9.854 5.01588 9.99133 5.0212 10.1291C5.02652 10.2668 5.06025 10.4019 5.12027 10.526C5.18029 10.65 5.26532 10.7604 5.37 10.85L7.72 13.21C7.81344 13.3027 7.92425 13.376 8.04609 13.4258C8.16793 13.4755 8.29839 13.5008 8.43 13.5C8.69234 13.4989 8.94374 13.3947 9.13 13.21L14.13 8.21C14.2237 8.11704 14.2981 8.00644 14.3489 7.88458C14.3997 7.76272 14.4258 7.63201 14.4258 7.5C14.4258 7.36799 14.3997 7.23728 14.3489 7.11542C14.2981 6.99356 14.2237 6.88296 14.13 6.79C13.9426 6.60375 13.6892 6.49921 13.425 6.49921C13.1608 6.49921 12.9074 6.60375 12.72 6.79ZM10 0C8.02218 0 6.08879 0.58649 4.44429 1.6853C2.7998 2.78412 1.51808 4.3459 0.761202 6.17317C0.00432521 8.00043 -0.193708 10.0111 0.192144 11.9509C0.577996 13.8907 1.5304 15.6725 2.92893 17.0711C4.32745 18.4696 6.10928 19.422 8.04909 19.8079C9.9889 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9996 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87103 17.5308 5.55543 16.6518C4.23984 15.7727 3.21446 14.5233 2.60896 13.0615C2.00346 11.5997 1.84503 9.99113 2.15371 8.43928C2.4624 6.88743 3.22432 5.46197 4.34314 4.34315C5.46196 3.22433 6.88743 2.4624 8.43927 2.15372C9.99112 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18Z"
      fill={color}
    />
  </svg>
);
