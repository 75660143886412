/* this networkInterface is copypasted from `redux-query` repo,
but modified so we can intercept requests with 401 status */
import superagent from 'superagent';
import { httpMethods } from 'redux-query';

// import { AppStorage } from '@dealsyte/poki';
// import { AUTH_TOKEN_KEY } from 'constants/appStorage';

const createRequest = (url, method, body) => {
  switch (method) {
    case httpMethods.HEAD:
      return superagent.head(url, body);
    case httpMethods.GET:
      return superagent.get(url, body);
    case httpMethods.POST:
      return superagent.post(url, body);
    case httpMethods.PUT:
      return superagent.put(url, body);
    case httpMethods.PATCH:
      return superagent.patch(url, body);
    case httpMethods.DELETE:
      return superagent.delete(url, body);
    default:
      throw new Error(`Unsupported HTTP method: ${method}`);
  }
};

const superagentNetworkInterface = (url, method, { body, headers, credentials } = {}) => {
  const request = createRequest(url, method, body);

  if (headers) {
    request.set(headers);
  }

  if (credentials === 'include') {
    request.withCredentials();
  }

  const execute = cb =>
    request.end((err, response) => {
      const resStatus = (response && response.status) || 0;
      const resBody = (response && response.body) || undefined;
      const resText = (response && response.text) || undefined;
      const resHeaders = (response && response.header) || undefined;

      if (resStatus !== 401) {
        cb(err, resStatus, resBody, resText, resHeaders);
      }
      // else {
      //   AppStorage.removeKey(AUTH_TOKEN_KEY);
      //   window.location = process.env.REACT_APP_LOGIN_URL;
      // }
    });

  const abort = () => request.abort();

  return {
    abort,
    execute,
    instance: request,
  };
};

export default superagentNetworkInterface;
