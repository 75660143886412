import * as React from 'react';
import { IconProps } from './types';

export const CloseCircleIcon = ({ width = 14, height = 14, color = 'currentColor' }: IconProps) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 .332c-3.666 0-6.666 3-6.666 6.667 0 3.666 3 6.666 6.667 6.666 3.666 0 6.666-3 6.666-6.666 0-3.667-3-6.667-6.666-6.667Zm2.467 8.2a.644.644 0 0 1 0 .933.644.644 0 0 1-.933 0L7.001 7.932 5.467 9.465a.644.644 0 0 1-.933 0 .644.644 0 0 1 0-.933l1.533-1.533-1.533-1.534a.644.644 0 0 1 0-.933.644.644 0 0 1 .933 0l1.534 1.533 1.533-1.533a.644.644 0 0 1 .933 0 .644.644 0 0 1 0 .933L7.934 7l1.533 1.533Z"
      fill={color}
    />
  </svg>
);
