import * as yup from 'yup';
import {
  basicRequiredInputValidation,
  REQUIRED_MESSAGE,
} from '../../../../utils/helpers/formValidators';

export const validationSchema = yup.object().shape({
  clientName: basicRequiredInputValidation(),
  clientEmail: basicRequiredInputValidation(),
  clientEntityName: basicRequiredInputValidation(),
  clientTitle: basicRequiredInputValidation(),
  transactionStructure: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .required(REQUIRED_MESSAGE),
  counterpartDeal: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .required(REQUIRED_MESSAGE),
  counterpartEntityName: basicRequiredInputValidation(),
  counterpartEntityLegalName: basicRequiredInputValidation(),
  contactName: basicRequiredInputValidation(),
  contactLastName: basicRequiredInputValidation(),
  contactEmail: basicRequiredInputValidation(),
  contactTitle: basicRequiredInputValidation(),
  note: basicRequiredInputValidation(),
});
