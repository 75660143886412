import * as React from 'react';
import { IconProps } from './types';

export const BigLockIcon = ({
  width = '12px',
  height = '14px',
  color = 'currentColor',
  style,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    style={style}
    viewBox="0 0 12 14"
    fill="none"
  >
    <path
      d="M5.99984 7.66829C5.79687 7.66592 5.59805 7.72579 5.43013 7.83983C5.26222 7.95387 5.13327 8.11662 5.06065 8.30617C4.98802 8.49571 4.97521 8.70295 5.02394 8.9C5.07266 9.09705 5.18058 9.27444 5.33317 9.40829V10.335C5.33317 10.5118 5.40341 10.6813 5.52843 10.8064C5.65346 10.9314 5.82303 11.0016 5.99984 11.0016C6.17665 11.0016 6.34622 10.9314 6.47124 10.8064C6.59627 10.6813 6.6665 10.5118 6.6665 10.335V9.40829C6.81909 9.27444 6.92701 9.09705 6.97574 8.9C7.02446 8.70295 7.01165 8.49571 6.93903 8.30617C6.86641 8.11662 6.73746 7.95387 6.56954 7.83983C6.40163 7.72579 6.2028 7.66592 5.99984 7.66829ZM9.33317 5.00163V3.66829C9.33317 2.78424 8.98198 1.93639 8.35686 1.31127C7.73174 0.68615 6.88389 0.334961 5.99984 0.334961C5.11578 0.334961 4.26794 0.68615 3.64281 1.31127C3.01769 1.93639 2.6665 2.78424 2.6665 3.66829V5.00163C2.13607 5.00163 1.62736 5.21234 1.25229 5.58741C0.877218 5.96249 0.666504 6.47119 0.666504 7.00163V11.6683C0.666504 12.1987 0.877218 12.7074 1.25229 13.0825C1.62736 13.4576 2.13607 13.6683 2.6665 13.6683H9.33317C9.8636 13.6683 10.3723 13.4576 10.7474 13.0825C11.1225 12.7074 11.3332 12.1987 11.3332 11.6683V7.00163C11.3332 6.47119 11.1225 5.96249 10.7474 5.58741C10.3723 5.21234 9.8636 5.00163 9.33317 5.00163ZM3.99984 3.66829C3.99984 3.13786 4.21055 2.62915 4.58562 2.25408C4.9607 1.87901 5.4694 1.66829 5.99984 1.66829C6.53027 1.66829 7.03898 1.87901 7.41405 2.25408C7.78912 2.62915 7.99984 3.13786 7.99984 3.66829V5.00163H3.99984V3.66829ZM9.99984 11.6683C9.99984 11.8451 9.9296 12.0147 9.80457 12.1397C9.67955 12.2647 9.50998 12.335 9.33317 12.335H2.6665C2.48969 12.335 2.32012 12.2647 2.1951 12.1397C2.07008 12.0147 1.99984 11.8451 1.99984 11.6683V7.00163C1.99984 6.82482 2.07008 6.65525 2.1951 6.53022C2.32012 6.4052 2.48969 6.33496 2.6665 6.33496H9.33317C9.50998 6.33496 9.67955 6.4052 9.80457 6.53022C9.9296 6.65525 9.99984 6.82482 9.99984 7.00163V11.6683Z"
      fill={color}
    />
  </svg>
);
