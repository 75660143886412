import { createStore, applyMiddleware, compose } from 'redux';
import { queryMiddlewareAdvanced } from 'redux-query';
import thunk from 'redux-thunk';

// import * as environment from 'constants/environments';
import networkInterface from 'app/core-tools/due-diligence/qna/redux/queries/network-interface';
import rootReducer from 'utils/redux/index.ts';

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialState = {};
const middleware = [
  queryMiddlewareAdvanced(networkInterface)(state => state.queries, state => state.entities),
  thunk,
  // ...(process.env.NODE_ENV === environment.DEVELOPMENT ? [logger] : []),
];

const configureStore = () => {
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  // if (process.env.NODE_ENV !== environment.PRODUCTION) {
  //   if (module.hot) {
  //     module.hot.accept('./redux', () => {
  //       store.replaceReducer(rootReducer);
  //     });
  //   }
  // }

  return store;
};

export default configureStore();
