import { EFormType } from 'app/compliance/types/formsTypes';

export const DR_FORMS_LIST = {
  BUTTON_BACK: 'Back to deal regulation',
  BUTTON_SUBMIT: 'Submit new',
  SUBTITLE: 'Follow progress on your submissions for ',
  BUTTON_STATUS: 'Status',
  TEXT_SORTING: 'Sort by:',
  TABLE_CODE: 'Code',
  TABLE_REPORT: 'Report',
  TABLE_REVIEWER: 'Reviewer',
  TABLE_SUBMITTED: 'Submitted on',
  TABLE_STATUS: 'Status',
  TABLE_VIEW: 'View',
};

export const sortingOptions = [
  [
    {
      label: 'Submission date',
      value: 'createdAt',
    },
  ],
  [
    {
      label: 'Ascending',
      value: 'ASC',
    },
    {
      label: 'Descending',
      value: 'DESC',
    },
  ],
];

export const initialSorting = [
  [
    {
      label: 'Submission date',
      value: 'createdAt',
    },
  ],
  [
    {
      label: 'Descending',
      value: 'DESC',
    },
  ],
];

export const getFormTitle = (formKey: string) => {
  switch (formKey) {
    case EFormType.EL:
      return 'Client engagement';
    case EFormType.MMAT:
      return 'Marketing materials';
    case EFormType.DRA:
      return 'Data room set up';
    case EFormType.KYC:
      return 'KYC / AML';
    case EFormType.CDOC:
      return 'Closing documents';
    case 'CA':
    case EFormType.FSAext:
    case EFormType.FSAint:
      return 'Collaboration agreements';
    // case EFormType.INV
    default:
      return 'Invoice(s)';
  }
};

export const getModalKey = (formKey: string) => {
  switch (formKey) {
    case EFormType.EL:
      return 'engagementLetterForm';
    case EFormType.MMAT:
      return 'MarketingMaterialsFormDrawer';
    case EFormType.DRA:
      return 'dataRoomForm';
    case EFormType.KYC:
      return 'DRKYCForm';
    case EFormType.CDOC:
      return 'closingDocumentsForm';
    case EFormType.AMNel:
      return 'amendmentForm';
    case 'CA':
    case EFormType.FSAext:
    case EFormType.FSAint:
      return 'collaborationAgreementsForm';
    // case EFormType.INV
    default:
      return 'invoiceForm';
  }
};

export const validDealFormTypes = [
  EFormType.EL,
  EFormType.MMAT,
  EFormType.DRA,
  EFormType.KYC,
  EFormType.KYCcl,
  EFormType.KYCco,
  EFormType.CDOC,
  EFormType.INV,
  'CA',
  EFormType.FSAint,
  EFormType.FSAext,
  EFormType.AMNel
];
