import React, { useEffect, useState } from 'react';
import OKCircleConfirmationIcon from './OKCircleConfirmationIcon';
import { MainContainer, IconContainer, Title, Text } from './StyledFsoSignatureCompletedPage';
import { ERoutePatterns } from 'app/core-tools/due-diligence/types/types';

function FsoSignatureCompletedPage() {
  const [time, setTime] = useState<number>(5);

  const closeWindow = () => {
    window.location.href = ERoutePatterns.HOME;
  };

  const reduceTime = () => {
    setTime(state => state - 1);
  };

  useEffect(() => {
    const timeout = setTimeout(closeWindow, 5000);
    const interval = setInterval(reduceTime, 1000);
    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, []);

  return (
    <MainContainer>
      <IconContainer>
        <OKCircleConfirmationIcon width={32} height={32} fill="#4DD8B6" />
      </IconContainer>

      <div style={{ maxWidth: 500 }}>
        <Title>This document has been signed in a secure environment</Title>
        <Title>{`You will be redirected to Finalis in ${time} seconds.`}</Title>

        <Text>
          Finalis uses bank-level encryption and public key infrastructure supported by DocuSign.
        </Text>
      </div>
    </MainContainer>
  );
}

export default FsoSignatureCompletedPage;
