import React from 'react';

// components
import { SideDrawer, Button, HStack } from '../../../../../common/components';
import { EditDealForm } from './EditDealForm';

// handler
import { useDealInformationSettingsHandler } from '../../../../../navigation/GlobalSidebar/components/SettingsModal/components/DealInformationSettings/DealInformationSettingsHandler';

// hook
import useStateModal from '../../../../../utils/hooks/useStateModal';

export const EditDealDrawer = () => {
  const { loadingUpdateDeal, updateDeal, ...editHandler } = useDealInformationSettingsHandler(true);
  const { modalState, hide } = useStateModal('editDealForm');

  const handleUpdate = () => {
    updateDeal().then(() => hide());
  };
  return (
    <SideDrawer
      title="Edit deal"
      show={modalState.show}
      promptOnClose={editHandler.form.dirty}
      footerContent={
        <HStack justifyContent="flex-end">
          <Button disabled={loadingUpdateDeal} onClick={handleUpdate}>
            Save
          </Button>
        </HStack>
      }
      closeWhenClickOutside={false}
      onClose={hide}
    >
      <EditDealForm editHandler={editHandler} />
    </SideDrawer>
  );
};
