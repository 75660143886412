import React from 'react';
import DataRoomFormDrawer from 'app/compliance/components/forms/DataRoomForm/DataRoomDrawer';
import MarketingMaterialFormDrawer from 'app/compliance/components/forms/MarketingMaterialForm/MarketingMaterialFormDrawer';
import KYCFormDrawer from 'app/compliance/components/forms/KYCForm/KYCFormDrawer';
import ClosingDocumentsDrawer from 'app/compliance/components/forms/ClosingDocumentsForm/ClosingDocumentsFormDrawer';
import InvoiceFormModal from 'app/compliance/components/forms/InvoiceForm/InvoiceFormModal';
import { EditDealDrawer } from 'app/compliance/sections/Attestation/Deals/Edit/EditDealDrawer';
import EngagementLetterDrawer from '../EngagementLetter/EngagementLetterDrawer';
import OBAFormDrawer from '../OBAForm/OBAFormDrawer';
import PSTFormDrawer from '../PSTForm/PSTFormDrawer';
import OBAcctFormDrawer from '../OBAcctForm/OBAcctFormDrawer';
import NewDealFormDrawer from '../NewDealForm/NewDealFormDrawer';
import MarketingMaterialsFormDrawer from '../../../../compliance/sections/DealRegulation/DealRegulationForms/DealRegulationMarketingMaterials/MarketingMaterialsFormDrawer';
import NewKYCFormDrawer from '../../../../compliance/sections/DealRegulation/DealRegulationForms/DealRegulationKYC/KYCFormDrawer';
import AmendmentFormDrawer from '../AmendmentForm/AmendmentDrawer';
import CollaborationAgreementsFormDrawer from '../CollaborationAgreementsForm/CAFormDrawer';

const FormsContainer = () => (
  <>
    <NewDealFormDrawer />
    <DataRoomFormDrawer />
    <MarketingMaterialFormDrawer />
    <KYCFormDrawer />
    <ClosingDocumentsDrawer />
    <InvoiceFormModal />
    <EngagementLetterDrawer />
    <OBAFormDrawer />
    <PSTFormDrawer />
    <OBAcctFormDrawer />
    <EditDealDrawer />
    <NewKYCFormDrawer />
    <MarketingMaterialsFormDrawer />
    <CollaborationAgreementsFormDrawer />
    <AmendmentFormDrawer />
  </>
);

export default FormsContainer;
