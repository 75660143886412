import React from 'react';
import styled from 'styled-components';

interface IRadioButton {
  name: string;
  value: string;
  style?: any;
  checked?: boolean;
  onClick: () => void;
}

const RadioButton = (props: IRadioButton) => (
  <StyledRadioButton
    type="radio"
    name={props.name}
    value={props.value}
    style={props.style}
    checked={props.checked}
    onClick={props.onClick}
  />
);

const StyledRadioButton = styled.input`
  appearance: none;
  background-color: #fff;
  width: 18px;
  height: 18px;
  border: 2px solid #807da1;
  border-radius: 50%;
  display: grid;
  place-content: center;
  margin: 0;

  ::before {
    content: '';
    width: 5px;
    height: 5.4px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em white;
    border: 6px solid #47455f;
  }

  :checked::before {
    transform: scale(1);
  }
`;

export default RadioButton;
