import { EFormType } from 'app/utils/types/types';

import { CAExternalFormType } from './CAExternalFormHandler';
import { CAInternalFormType } from './CAInternalFormHandler';
import { CA_EXTERNAL_FORM_LABELS, CA_INTERNAL_FORM_LABELS } from './CAConstants';

export const buildCAExternalForm = (
  { files, firmName, signer, emailAddress, CRD, note }: CAExternalFormType,
  dealId: string
) => {
  const variables = {
    type: EFormType.FSAext,
    dealId,
    content: [
      {
        question: CA_EXTERNAL_FORM_LABELS.FIRM_NAME,
        input: firmName.trim(),
        key: 'firmName',
        value: firmName.trim(),
      },
      {
        question: CA_EXTERNAL_FORM_LABELS.SIGNER,
        input: signer.trim(),
        key: 'signer',
        value: signer.trim(),
      },
      {
        question: CA_EXTERNAL_FORM_LABELS.EMAIL_ADDRESS,
        input: emailAddress.trim(),
        key: 'emailAddress',
        value: emailAddress.trim(),
      },
      {
        question: CA_EXTERNAL_FORM_LABELS.CRD,
        input: CRD.trim(),
        key: 'CRD',
        value: CRD.trim(),
      },
    ],
    note,
    files,
  };

  return variables;
};

export const buildCAInternalForm = (
  { files, firmName, fullName, emailAddress, note }: CAInternalFormType,
  dealId: string
) => {
  const variables = {
    type: EFormType.FSAint,
    dealId,
    content: [
      {
        question: CA_INTERNAL_FORM_LABELS.FIRM_NAME,
        input: firmName.trim(),
        key: 'firmName',
        value: firmName.trim(),
      },
      {
        question: CA_INTERNAL_FORM_LABELS.NAME,
        input: fullName.trim(),
        key: 'fullName',
        value: fullName.trim(),
      },
      {
        question: CA_INTERNAL_FORM_LABELS.EMAIL_ADDRESS,
        input: emailAddress.trim(),
        key: 'emailAddress',
        value: emailAddress.trim(),
      },
    ],
    note,
    files,
  };

  return variables;
};
