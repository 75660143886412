import styled from 'styled-components';

export const SpinnerContainer = styled.div`
  ${({ theme }) => {
    const { colors, spacing, typography, radii, shadows } = theme.foundations;
    return `
      background-color: ${colors.palette.white};
      z-index: 1;
      border-radius: ${radii.md};
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      box-shadow: ${shadows['02']};
      padding: ${spacing['04']};
      p {
        display: inline-block;
        margin-left: ${spacing['03']};
        font-family: ${typography.fonts.body};
        color: ${colors.palette.neutral['500']};
        font-size: ${typography.sizes['03']};
        font-weight: ${typography.weights.regular};
      }
    `;
  }}
`;

type LoaderContainerProps = { maxScreen?: boolean; blurBackground?: boolean };

export const LoaderContainer = styled.div<LoaderContainerProps>`
  ${({ theme, maxScreen, blurBackground }) => {
    const { colors, radii } = theme.foundations;
    return `
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0; 
    z-index: 10;
    position: ${maxScreen ? 'fixed' : 'absolute'};
    background: ${blurBackground ? 'rgba(216, 215, 234, 0.5)' : colors.palette.transparent};
    backdrop-filter: ${blurBackground && 'blur(2px)'};
    border-radius: 0px ${radii.lg} ${radii.lg} 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `;
  }}
`;
