import React from 'react';

export const accountTypes = [
  {
    label: 'Non-qualified brokerage (individual account)',
    value: 'Non-qualified brokerage (individual account)',
  },
  {
    label: 'IRA',
    value: 'IRA',
  },
  {
    label: 'Rollover IRA',
    value: 'Rollover IRA',
  },
  {
    label: 'Roth IRA',
    value: 'Roth IRA',
  },
  {
    label: '529 Plan',
    value: '529 Plan',
  },
  {
    label: 'SEP IRA',
    value: 'SEP IRA',
  },
  {
    label: '401K',
    value: '401K',
  },
  {
    label: 'Crypto',
    value: 'Crypto',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const OBAcctLabels = {
  OBACCT_INFO:
    'Do you have outside brokerage accounts that allow for direct trading (i.e. the purchase of individual securities)? If so, please list them below.',
  OBACCT_SIDENOTE: (
    <>
      Note: <strong>This requirement shall not apply</strong> to transactions in{' '}
      <strong>unit investment trusts, municipal fund securities</strong> as defined under MSRB Rule
      D-12, <strong>qualified tuition programs</strong> pursuant to Section 529 of the Internal
      Revenue Code and{' '}
      <strong>
        variable contracts or redeemable securities of companies registered under the Investment
        Company Act
      </strong>
      , as amended, or to accounts that are{' '}
      <strong>limited to transactions in such securities</strong>, or to{' '}
      <strong>Monthly Investment Plan type accounts</strong>.
    </>
  ),
  ACC_OWNER: 'Account owner',
  ACC_OWNER_PLACEHOLDER: 'Enter account owner',
  ACC_NUMBER: 'Account number',
  ACC_NUMBER_PLACEHOLDER: 'Enter account number',
  ACC_TYPE: 'Account type',
  ACC_TYPE_PLACEHOLDER: 'Select account type',
  INSTITUTION_NAME: 'Financial institution name',
  INSTITUTION_NAME_PLACEHOLDER: 'Enter financial institution name',
  NOTES: 'Do you want to add any comments / notes',
  FILES:
    "Upload your current quarterly statement cover page, showing your financial institution's account number.",
};
