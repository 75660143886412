import React, { useState, useContext } from 'react';
import _isEmpty from 'lodash/isEmpty';

// Components
import { SingleOptionRadioButtons, TextArea, FormGroup, Input } from 'app/common/components';

import { isValidUrl } from 'app/utils/helpers/urlValidator';

import { DRLabels } from './DataRoomFormConstants';

import { DataRoomForm as DataRoomFormType, DataRoomHandlerContext } from './DataRoomFormHandler';

import { formControlChangeHandlers } from '../../FormControlChangeHandlers/FormControlChangeHandlers';

const DataRoomForm = () => {
  const { form, isEdit } = useContext(DataRoomHandlerContext);
  const { values, touched, setFieldValue, errors } = form;
  const [webSiteError, setWebSiteError] = useState<boolean>(false);

  const { handleYesNoChange, handleFieldChange } = formControlChangeHandlers<DataRoomFormType>(
    setFieldValue
  );

  return (
    <>
      <FormGroup required label={DRLabels.DATA_ROOM_ACCESS}>
        <SingleOptionRadioButtons
          strictValueOnChange
          value={values.dataRoomAccess}
          name="dataRoomAccess"
          onChange={handleYesNoChange('dataRoomAccess')}
        />
      </FormGroup>
      {values.dataRoomAccess && (
        <FormGroup required label={DRLabels.FINALIS_VDR}>
          <SingleOptionRadioButtons
            strictValueOnChange
            value={values.finalisVDR}
            name="finalisVDR"
            onChange={handleYesNoChange('finalisVDR')}
          />
        </FormGroup>
      )}
      {values.dataRoomAccess && !values.finalisVDR && touched.finalisVDR && (
        <FormGroup
          optional
          label={DRLabels.SEND_LINK}
          error={touched.dataRoomLink && errors.dataRoomLink}
        >
          <Input
            placeholder="Paste link"
            value={values.dataRoomLink}
            onChange={handleFieldChange('dataRoomLink')}
          />
        </FormGroup>
      )}
      {!isEdit && (
        <FormGroup label="Do you want to add any comments / notes:">
          <TextArea
            placeholder="Comments..."
            value={values.note}
            onChange={handleFieldChange('note')}
          />
        </FormGroup>
      )}
    </>
  );
};

export default DataRoomForm;
