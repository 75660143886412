import AppStorage, { TOKEN_KEY } from 'app/appStorage';

import { isPublicRoute } from '../../../utils/helpers';
import { ERoutePatterns } from '../../core-tools/due-diligence/types/types';

export default function() {
  const token = AppStorage.getItem(TOKEN_KEY);

  if (!token && !isPublicRoute(window.location.href)) {
    // TODO: kick the user out if expiration or no token

    window.clearAppStorage();

    window.location.href = `${ERoutePatterns.LOGIN}/?from=${window.location.pathname}` || '/';
  }

  return token ? `Bearer ${token}` : '';
}
