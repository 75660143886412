import { transparentize } from 'polished';
import styled from 'styled-components';

export const Backdrop = styled.div`
  ${({ theme }) => {
    const { colors } = theme.foundations;
    return `
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background-color: ${transparentize(0.4)(colors.palette.neutral['700'])};
      transition: opacity 150ms ease-out;

      &.drawer-state {
        &-enter {
          opacity: 0;
          #drawer-container {
            transform: translateX(100%);
          }
        }
        &-enter-active {
          opacity: 1;
          #drawer-container {
            transform: translateX(0);
          }
        }
        &-enter-done {
          opacity: 1;
          #drawer-container {
            transform: translateX(0);
          }
        }
        &-exit {
          opacity: 1;
          #drawer-container {
            transform: translateX(0);
          }
        }
        &-exit-active {
          opacity: 0;
          #drawer-container {
            transform: translateX(100%);
          }
        }
      }
    `;
  }}
`;

export const SideDrawerContainer = styled.div`
  ${({ theme }) => {
    const { colors } = theme.foundations;
    return `
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 0;
      width: 576px;
      height: 100%;
      background-color: ${colors.palette.white};
      transition: transform 300ms ease-out;
    `;
  }}
`;

export const SideDrawerProgressBar = styled.div<{ percentage: number }>`
  ${({ theme, percentage = 0 }) => {
    const { colors } = theme.foundations;
    return `
    width 100%;
    height: 8px;
    background-color: ${colors.palette.neutral[100]};
    
    &::before {
      content: '';
      height: 8px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: ${colors.palette.neutral[600]};
      width: ${percentage}%;
      transition: width 300ms ease-out;
    }
  `;
  }}
`;

export const SideDrawerHeader = styled.div`
  ${({ theme }) => {
    const { colors, spacing } = theme.foundations;
    return `
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      padding: ${spacing['06']};
      width: 100%;
      height: 92px;
      border-bottom: 1px solid ${colors.palette.neutral[200]};
    `;
  }}
`;

export const SideDrawerTitle = styled.div`
  ${({ theme }) => {
    const { colors, typography } = theme.foundations;
    return `
      display: inline-block;
      color: ${colors.palette.neutral[600]};
      font-family: ${typography.fonts.heading};
      font-size: 22px;
      font-weight: ${typography.weights.bold};
      line-height: 27.5px;
      letter-spacing: 0.25px;
    `;
  }}
`;

export const SideDrawerCloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

export const SideDrawerContent = styled.div`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return `
      flex: 1;
      overflow-y: auto;
      padding: ${spacing['06']} ${spacing['06']} ${spacing['04']};
    `;
  }}
`;

export const SideDrawerFooter = styled.div`
  ${({ theme }) => {
    const { colors, spacing } = theme.foundations;
    return `
      padding: ${spacing['06']};
      width: 100%;
      height: 112px;
      border-top: 1px solid ${colors.palette.neutral[200]};
    `;
  }}
`;
