import React, { useContext, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import useStateModal from 'app/utils/hooks/useStateModal';
import get from 'lodash/get';
import { DayValue } from 'react-modern-calendar-datepicker';

// mutations
import { CREATE_FORM } from 'api/graphql/compliance/mutations';

// Constants
import { DEAL_FORM_LABELS, DEAL_STATUS_LABELS, DEAL_TYPES_LABELS } from 'app/utils/helpers/deal';
import { EDealStatus } from 'app/utils/types/types';
import { EFeatureFlag } from 'app/FeatureFlag';

// context
import { UseMultiStepForm, useMultiStepForm } from 'app/utils/hooks/useMultiStepForm';
import { AlertContext } from 'app/notifications/context/Alert/Alert';

import { FormType } from '../../../../../types/graphql-types';

import { validationSchema } from './NewDealFormValidation';
import { UserContext } from 'app/users/context/UserContext';
import { DealsContext } from 'app/compliance/context/DealsContext';
import {
  DEAL_METADATA_FIELDS,
  DEAL_METADATA_FIELD_ACCESSORS,
  mapObjectToMetadata,
} from 'app/utils/helpers/dealMetadataMappers';

// Hooks
import { useFeatureFlags } from 'app/utils/hooks/hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { ERoutePatterns } from 'app/core-tools/due-diligence/types/types';

export type selectValue = {
  value: string;
  label: string;
};

export type NewDealForm = {
  // first step
  newDealName: string;
  transactionType: selectValue | undefined;
  companyName: string;
  headquarterAddressLine1: string;
  headquarterAddressLine2: string;
  country: selectValue | undefined;
  state: string;
  zipCode: string;
  engagementLetterProvided: boolean;
  private: boolean;

  // second step
  marketplaceDealType: string;
  marketplaceDescription: string;

  // third step
  dealStatus: selectValue;
  closingDate: DayValue | null;
  loiProvided: boolean;
  industryType: selectValue | undefined;
  industryTypeSubOptions: selectValue | undefined;
  monthlyRetainer: boolean;
  retainerAmount: number;
  expectedSize: number;
  minFee: number;
  maxFee: number;
  tailPeriodDuration: {
    value: number;
    label: string;
  };
  // fourth step
  files: File[];
  publiclyTradedCompany: boolean;
  ticker: string;
  isInitialDueDiligence: boolean;
  initialDueDiligenceNote: string;
  isAnyRedFlag: boolean;
  anyRedFlagNote: string;
  isPotentialConflicts: boolean;
  potentialConflictsNote: string;
  targetInvestors: selectValue;
  foreignSolicitation: boolean;
  foreignSolicitationIntitutionalClients: boolean;
  foreignSolicitationPreexistingRelationship: string;
  foreignSolicitationCountries: selectValue[];
  conclusions: string;
  note: string;

  // not in the form
  expectedCommissions: number;
  projectName: string;
};

export interface UseNewDealFormHandler {
  form: UseMultiStepForm<NewDealForm>;
  onSubmitNewDealForm: () => Promise<void>;
  onClose: () => void;
  submitting: boolean;
  modal: ReturnType<typeof useStateModal>;
  disableSubmitOnDealShareOptionUntouched: boolean;
  displayShareDealWithFirm: boolean;
  isStatusDDClosing: boolean;
}

export const NewDealHandlerContext = React.createContext<UseNewDealFormHandler>(
  {} as UseNewDealFormHandler
);
export const NewDealHandlerProvider = NewDealHandlerContext.Provider;

const sanitizeErrorMessage = (message = '') => {
  return message.replace('GraphQL error:', '');
};

export const useNewDealFormHandler = (): UseNewDealFormHandler => {
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);
  const { currentFirm, marketplaceAccessEnabled } = useContext(UserContext);
  const { createDealMutation, dealsQuery } = useContext(DealsContext);
  const modal = useStateModal('newDealDrawer');
  const { hide } = modal;
  const history = useHistory();
  const { pathname } = useLocation();

  const FFEnable = useFeatureFlags(EFeatureFlag.CAAS_NEW_DEAL_EL_STEP); // REMOVE TO ENABLE IN DEPLOY

  const MarketplaceStep = {
    marketplaceDealType: '',
    marketplaceDescription: '',
  };

  const displayShareDealWithFirm = currentFirm ? (currentFirm.firmMembersCount || 0) > 1 : false;

  const PrimaryInfoStep = {
    newDealName: '',
    transactionType: undefined,
    engagementLetterProvided: undefined,
    private: undefined,
    companyName: '',
    headquarterAddressLine1: '',
    headquarterAddressLine2: '',
    country: undefined,
    state: '',
    zipCode: '',
  };

  const AdditionalInfoStep = {
    dealStatus: {
      label: DEAL_STATUS_LABELS[EDealStatus.INITIAL_ENGAGEMENT],
      value: EDealStatus.INITIAL_ENGAGEMENT,
    },
    industryType: undefined,
    industryTypeSubOptions: undefined,
    monthlyRetainer: undefined,
    retainerAmount: undefined,
    expectedSize: 0,
    minFee: 0,
    maxFee: 0,
    tailPeriodDuration: {
      value: 6,
      label: '6 months',
    },
    projectName: '',
    expectedCommissions: 0,
    closingDate: '' as any,
    loiProvided: undefined,
  };

  const ELFormStep = {
    files: [],
    publiclyTradedCompany: undefined,
    ticker: '',
    isInitialDueDiligence: undefined,
    initialDueDiligenceNote: '',
    isAnyRedFlag: undefined,
    anyRedFlagNote: '',
    isPotentialConflicts: undefined,
    potentialConflictsNote: '',
    targetInvestors: undefined,
    foreignSolicitation: undefined,
    foreignSolicitationIntitutionalClients: undefined,
    foreignSolicitationPreexistingRelationship: undefined,
    foreignSolicitationCountries: undefined,
    conclusions: '',
    note: '',
  };

  const initialSteps = [PrimaryInfoStep, AdditionalInfoStep];

  const initialStepsWithMarketplace = [PrimaryInfoStep, MarketplaceStep, AdditionalInfoStep];

  const [formSteps, setFormSteps] = useState<Array<Partial<NewDealForm>>>(initialSteps);

  useEffect(() => {
    if (marketplaceAccessEnabled) {
      setFormSteps(initialStepsWithMarketplace);
    }
  }, [marketplaceAccessEnabled]);

  const form = useMultiStepForm<NewDealForm>({
    steps: formSteps,
    validationSchema: validationSchema(displayShareDealWithFirm),
  });

  useEffect(() => {
    if (FFEnable && form.touched.engagementLetterProvided) {
      if (form.values.engagementLetterProvided === true) {
        setFormSteps((prev: any) => [...prev, ELFormStep]);
      } else {
        if (formSteps.length > 3 || (formSteps.length > 2 && !marketplaceAccessEnabled))
          setFormSteps((prev: any) => prev.slice(0, prev.length - 1));
      }
    }
  }, [form.values.engagementLetterProvided]);

  const resetSteps = () => {
    if (marketplaceAccessEnabled) {
      setFormSteps(initialStepsWithMarketplace);
    } else {
      setFormSteps(initialSteps);
    }
  };

  const [createDeal, { loading: loadingDeal }] = createDealMutation;

  const creationSuccess = () => {
    showSuccessToast({ title: 'Success!', description: 'Deal was created successfully' });
    hide();
    resetSteps();
    form.resetForm();
  };

  const redirectToDataRegulation = (dealId: string) => {
    history.push(`${ERoutePatterns.DEAL_REGULATION}/${dealId}`, {
      from: pathname,
    });
  };

  const disableSubmitOnDealShareOptionUntouched = displayShareDealWithFirm
    ? !form.touched.private
    : false;

  const createNewDeal = () => {
    const { values } = form;
    if (values.marketplaceDescription)
      values.marketplaceDescription = values.marketplaceDescription.replace(/\n|\r|\t|_/g, ' ');
    return createDeal({
      variables: {
        name: values.newDealName,
        type: get(values, 'transactionType.value', ''),
        companyName: values.companyName,
        companyAddress: values.headquarterAddressLine1,
        companyAgent: '',
        private: !Boolean(values.private),
        projectName: values.projectName,
        dealStatus: values.dealStatus.value,
        expectedCommissions: values.expectedCommissions,
        expectedSize: values.expectedSize || 0,
        closingDate: values.closingDate,
        loiProvided: values.loiProvided,
        firmId: values.private ? get(currentFirm, 'id', '') : '',
        metadata: mapObjectToMetadata(values, {
          fields: DEAL_METADATA_FIELDS,
          accessors: DEAL_METADATA_FIELD_ACCESSORS,
        }),
      },
    });
  };

  const [createFormMutation, { loading: loadingForm }] = useMutation(CREATE_FORM, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      creationSuccess();
    },
    onError() {
      showErrorToast({ title: 'Error', description: 'An error occurred while saving the form' });
    },
  });

  const mapCountries = (countries: selectValue[]) => {
    if (countries.length > 1) {
      return countries.reduce((previousValue, currentValue, currentIndex) => {
        if (currentIndex === countries.length - 1) {
          return `${previousValue} and ${currentValue.value}`;
        }
        return `${previousValue} ${currentValue.value},`;
      }, '');
    }
    return countries[0].value;
  };

  const submitting = loadingDeal || loadingForm;

  const buildELForm = (props: NewDealForm, dealId: string) => {
    const { touched } = form;
    const mapYesNoToString = (value: boolean): string => (value ? 'Yes' : 'No');
    const {
      newDealName,
      transactionType,
      companyName,
      headquarterAddressLine1,
      headquarterAddressLine2,
      country,
      state,
      zipCode,
      industryType,
      industryTypeSubOptions,
      publiclyTradedCompany,
      ticker,
      isInitialDueDiligence,
      initialDueDiligenceNote,
      isAnyRedFlag,
      anyRedFlagNote,
      isPotentialConflicts,
      potentialConflictsNote,
      targetInvestors,
      foreignSolicitation,
      foreignSolicitationIntitutionalClients,
      foreignSolicitationPreexistingRelationship,
      foreignSolicitationCountries,
      note,
      conclusions,
      files,
    } = props;
    const variables = {
      type: FormType.EL,
      dealId,
      content: [
        {
          question: DEAL_FORM_LABELS.DEAL_NAME,
          input: newDealName,
        },
        {
          question: DEAL_FORM_LABELS.DEAL_TYPE,
          input: get(DEAL_TYPES_LABELS, get(transactionType, 'value', ''), ''),
        },
        {
          question: DEAL_FORM_LABELS.CLIENT_NAME,
          input: companyName,
        },
        {
          question: DEAL_FORM_LABELS.CLIENT_ADDRESS_LINE1,
          input: headquarterAddressLine1,
        },
        {
          question: DEAL_FORM_LABELS.CLIENT_ADDRESS_LINE2,
          input: headquarterAddressLine2,
        },
        {
          question: DEAL_FORM_LABELS.COUNTRY,
          input: get(country, 'value', ''),
        },
        {
          question: DEAL_FORM_LABELS.STATE,
          input: state,
        },
        {
          question: DEAL_FORM_LABELS.ZIP_CODE,
          input: zipCode,
        },
        {
          question: DEAL_FORM_LABELS.INDUSTRY_TYPE,
          input: get(industryType, 'value', ''),
        },
        {
          question: DEAL_FORM_LABELS.INDUSTRY_SUB_TYPE,
          input: get(industryTypeSubOptions, 'value', ''),
        },
        {
          question: DEAL_FORM_LABELS.PUBLICLY_TRADED_LABEL,
          input: touched.publiclyTradedCompany ? mapYesNoToString(publiclyTradedCompany) : '',
        },
        {
          question: DEAL_FORM_LABELS.DUE_DILIGENCE_LABEL,
          input: touched.isInitialDueDiligence ? mapYesNoToString(isInitialDueDiligence) : '',
        },
        {
          question: DEAL_FORM_LABELS.RED_FLAG_LABEL,
          input: touched.isAnyRedFlag ? mapYesNoToString(isAnyRedFlag) : '',
        },
        {
          question: DEAL_FORM_LABELS.POTENTIAL_CONFLICTS_LABEL,
          input: touched.isPotentialConflicts ? mapYesNoToString(isPotentialConflicts) : '',
        },
        {
          question: DEAL_FORM_LABELS.TARGET_INVESTORS,
          input: get(targetInvestors, 'value', ''),
        },
        {
          question: DEAL_FORM_LABELS.FOREIGN_SOLICITATION_LABEL,
          input: touched.foreignSolicitation ? mapYesNoToString(foreignSolicitation) : '',
        },
        {
          question: DEAL_FORM_LABELS.CONCLUSION_LABEL,
          input: conclusions,
        },
      ],
      note: note,
      files: files,
    };

    if (publiclyTradedCompany) {
      variables.content.push({
        question: DEAL_FORM_LABELS.TICKER_EXCHANGE_LABEL,
        input: ticker,
      });
    }

    if (isInitialDueDiligence) {
      variables.content.push({
        question: DEAL_FORM_LABELS.DUE_DILIGENCE_NOTE_LABEL,
        input: initialDueDiligenceNote,
      });
    }

    if (isAnyRedFlag) {
      variables.content.push({
        question: DEAL_FORM_LABELS.RED_FLAG_NOTE_LABEL,
        input: anyRedFlagNote,
      });
    }

    if (isPotentialConflicts) {
      variables.content.push({
        question: DEAL_FORM_LABELS.POTENTIAL_CONFLICTS_NOTE_LABEL,
        input: potentialConflictsNote,
      });
    }

    if (foreignSolicitation) {
      variables.content.push({
        question: DEAL_FORM_LABELS.FOREIGN_SOLICITATION_INSTITUTIONAL_CLIENTS,
        input: mapYesNoToString(foreignSolicitationIntitutionalClients),
      });
      variables.content.push({
        question: DEAL_FORM_LABELS.FOREIGN_SOLICITATION_PREEXISTING_RELATIONSHIP,
        input: foreignSolicitationPreexistingRelationship,
      });
      variables.content.push({
        question: DEAL_FORM_LABELS.FOREIGN_SOLICITATION_COUNTRIES_LABEL,
        input: mapCountries(foreignSolicitationCountries),
      });
    }

    return variables;
  };

  const onSubmitNewDealForm = async (): Promise<void> => {
    try {
      const { data } = await createNewDeal();
      const dealId = (data && data.createDeal.id) || '';
      const { values } = form;
      if (!values.engagementLetterProvided) {
        dealsQuery.refetch && dealsQuery.refetch();
        creationSuccess();
        return redirectToDataRegulation(dealId);
      }
      await createFormMutation({
        variables: buildELForm(values, dealId),
      });
      // TODO - find a way to do this only for attestations
      dealsQuery.refetch && dealsQuery.refetch();
      redirectToDataRegulation(dealId);
    } catch (e) {
      showErrorToast({ title: 'Error', description: sanitizeErrorMessage(e && get(e, 'message')) });
    }
  };

  const onClose = () => {
    form.resetForm();
    resetSteps();
    hide();
  };

  const isStatusDDClosing =
    get(form.values, 'dealStatus.value') === EDealStatus.DUE_DILIGENCE_CLOSING;
  return {
    form,
    onSubmitNewDealForm,
    onClose,
    submitting,
    modal,
    disableSubmitOnDealShareOptionUntouched,
    displayShareDealWithFirm,
    isStatusDDClosing,
  };
};
