import { gql } from '@apollo/client';
import { LogFragment } from 'api/graphql/logs/fragments';

import {
  FsoFragment,
  UploadTransactionBodyFragment,
  UploadFsoFragment,
  FsoFragmentRecentUploads,
  FsoFragmentRecursive,
} from './fragments';

export const GET_FILEROOMS = gql`
  query GET_FILEROOMS_QUERY($dealId: ID!, $navigateAs: ID) {
    fileRooms(dealId: $dealId, navigateAs: $navigateAs) {
      ...FsoBasicFragment
    }
  }
  ${FsoFragment}
`;

export const GET_FSO_NO_CHILDREN = gql`
  query GET_FSO_NO_CHILDREN_QUERY($fsoId: ID!) {
    fso(id: $fsoId) {
      ...FsoBasicFragment
    }
  }
  ${FsoFragment}
`;

export const GET_FSO = gql`
  query GET_FSO_QUERY($fsoId: ID!, $navigateAs: ID) {
    fso(id: $fsoId) {
      ...FsoBasicFragment
      children(navigateAs: $navigateAs) {
        ...FsoBasicFragment
        ...FsoFragmentRecursive
      }
    }
  }
  ${FsoFragment}
  ${FsoFragmentRecursive}
`;

export const GET_FILE_INFO = gql`
  query GET_FILE_INFO_QUERY($fsoId: ID! = "") {
    fso(id: $fsoId) {
      ...FsoBasicFragment
    }
  }
  ${FsoFragment}
`;

export const GET_FILE_WITH_LOGS = gql`
  query GET_FILE_WITH_LOGS_QUERY($fsoId: ID! = "") {
    fso(id: $fsoId) {
      ...FsoBasicFragment
      logs {
        ...LogBasicFragment
      }
    }
  }
  ${LogFragment}
  ${FsoFragment}
`;

export const GET_FILE_LOGS_GROUP = gql`
  query GET_FILE_LOGS_GROUP_QUERY($fsoId: ID! = "") {
    fso(id: $fsoId) {
      ...FsoBasicFragment
      logs {
        ...LogBasicFragment
      }
    }
  }
  ${LogFragment}
  ${FsoFragment}
`;

export const GET_ALL_FSO_TRANSACTIONS = gql`
  query GET_ALL_FSO_TRANSACTIONS_QUERY($status: String = "") {
    activeUploadFsosTransactions(status: $status) {
      ...UploadTransactionBodyFragment
      files {
        pending {
          ...UploadFsoFragment
        }
        complete {
          ...UploadFsoFragment
        }
      }
    }
  }
  ${UploadTransactionBodyFragment}
  ${UploadFsoFragment}
`;

export const GET_FSO_TRANSACTION_STATUS = gql`
  query GET_FSO_TRANSACTION_STATUS_QUERY($id: ID!) {
    uploadFsosTransationStatus(id: $id) {
      ...UploadTransactionBodyFragment
      files {
        pending {
          ...UploadFsoFragment
        }
        complete {
          ...UploadFsoFragment
        }
      }
    }
  }
  ${UploadTransactionBodyFragment}
  ${UploadFsoFragment}
`;

export const GET_DOWNLOAD_TOKEN = gql`
  query GET_DOWNLOAD_TOKEN_QUERY(
    $parentId: String
    $fsoIds: [String]!
    $type: DownloadFsosTransactionType!
  ) {
    downloadFsosKey(parentId: $parentId, fsoIds: $fsoIds, type: $type) {
      token
      filename
    }
  }
`;

export const GET_WATERMARK_CONFIGS = gql`
  query GET_WATERMARK_CONFIGS_QUERY($dealId: ID!, $context: String!) {
    configs(dealId: $dealId, context: $context) {
      key
      value
      type
    }
  }
`;

export const GET_RECENT_UPLOADS = gql`
  query GET_RECENT_UPLOADS_QUERY(
    $roomId: ID!
    $count: Int
    $orderBy: String
    $excludeUnpublished: Boolean
    $excludeFolders: Boolean
    $descendingOrder: Boolean
  ) {
    fsosByDataRooms(
      roomId: $roomId
      count: $count
      orderBy: $orderBy
      excludeUnpublished: $excludeUnpublished
      excludeFolders: $excludeFolders
      descendingOrder: $descendingOrder
    ) {
      ...FsoRecentUploads
    }
  }
  ${FsoFragmentRecentUploads}
`;

export const STARRED_FSOS_BY_PARENT = gql`
  query STARRED_FSOS_BY_PARENT(
    $fsoId: ID!
    $orderBy: String
    $excludeUnpublished: Boolean
    $count: Int
    $descendingOrder: Boolean
  ) {
    starredFsosByParent(
      fsoId: $fsoId
      orderBy: $orderBy
      excludeUnpublished: $excludeUnpublished
      count: $count
      descendingOrder: $descendingOrder
    ) {
      ...FsoRecentUploads
    }
  }
  ${FsoFragmentRecentUploads}
`;
