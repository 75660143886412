import * as React from 'react';
import { IconProps } from './types';

export const FinalisLogov2 = ({
  width = '22px',
  height = '12px',
  style = {},
  color = '#185ADC',
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 12"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.208.842v10.316h-2.914l-3.708-2.959 1.526-1.22 3.236 2.573-.008-7.106-10.916 8.712H5.368L18.294.842h2.914Zm-18.294 0L6.622 3.8 5.096 5.02 1.86 2.447l.008 7.106L12.783.842h3.057L2.914 11.158H0V.842h2.914Z"
      fill={color}
    />
  </svg>
);
