import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import _get from 'lodash/get';
import safeParse from '../helpers/safeParse';

interface UseStoredStateOptions<T> {
  initialValueMapper?: (initialValue: T) => T;
}

export function useStoredState<T>(
  key: string,
  initialValue: T | (() => T),
  options: UseStoredStateOptions<T>
): [T, Dispatch<SetStateAction<T>>, boolean] {
  const [value, setValue] = useState(initialValue);
  const [isDraft] = useState(!!localStorage.getItem(key));

  const storedValue = localStorage.getItem(key) || JSON.stringify(initialValue);
  const parsedValue = safeParse(storedValue);

  const persist = (newValue: any) => {
    const mapped = _get(options, 'initialValueMapper', (v: any) => v)(newValue);
    localStorage.setItem(key, JSON.stringify(mapped));
  };

  useEffect(() => {
    if (storedValue) {
      setValue(parsedValue);
    }
  }, [key]);

  useEffect(() => {
    persist(value);
  }, [value]);

  return [value, setValue, isDraft];
}
