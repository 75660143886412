import * as React from 'react';
import { IconProps } from './types';

export const ExclamationOctagonSolidIcon = ({
  width = '16px',
  height = '16px',
  color = 'currentColor',
  style,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="m14.466 5.068-3.533-3.534a.605.605 0 0 0-.467-.2H5.533c-.2 0-.333.067-.467.2L1.533 5.068a.605.605 0 0 0-.2.466v5c0 .2.067.334.2.467l3.533 3.533c.134.067.267.134.467.134h5c.2 0 .333-.067.467-.2l3.533-3.534a.605.605 0 0 0 .2-.466V5.534c-.067-.2-.133-.333-.267-.466ZM8 11.334c-.4 0-.667-.266-.667-.666S7.6 10 8 10s.666.267.666.667-.266.666-.666.666Zm.666-3.333c0 .4-.266.667-.666.667S7.333 8.4 7.333 8V5.334c0-.4.267-.666.667-.666s.666.266.666.666v2.667Z"
      fill={color}
    />
  </svg>
);
