import * as yup from 'yup';

import validateFileExtension, {
  basicRequiredInputValidation,
  REQUIRED_MESSAGE,
  validateFileName,
} from '../../../../utils/helpers/formValidators';

const validations = {
  entityName: basicRequiredInputValidation(),
  startDate: yup
    .object()
    .shape({
      year: yup.number(),
      month: yup.number(),
      day: yup.number(),
    })
    .required(REQUIRED_MESSAGE),
  isInvestmentRelated: yup.boolean().required(REQUIRED_MESSAGE),
  position: basicRequiredInputValidation(),
  streetAddress: basicRequiredInputValidation(),
  city: basicRequiredInputValidation(),
  state: basicRequiredInputValidation(),
  zipCode: basicRequiredInputValidation(),
  country: basicRequiredInputValidation(),
  natureOfBusiness: basicRequiredInputValidation(),
  websitesConnectedToBusiness: basicRequiredInputValidation(),
  dutyDescription: basicRequiredInputValidation(),
  approximateHours: basicRequiredInputValidation(),
  devotedHours: basicRequiredInputValidation(),
  hasReasonableCompensation: yup.boolean().required(),
  compensation: yup.array().when('hasReasonableCompensation', {
    is: hasReasonableCompensation => hasReasonableCompensation,
    then: yup.array().required('At least one compensation is required'),
  }),
  // othersDescription: yup.string().when('compensation', {
  //   is: compensation => compensation.includes(CompensationValue.others),
  //   then: basicRequiredInputValidation(),
  // }),
  isCapitalRaise: yup.boolean().required(),
  otherLicenses: basicRequiredInputValidation(),
  files: yup
    .array()
    .test('', 'Some file has an invalid name', validateFileName)
    .test('', 'Some file has an invalid extension', validateFileExtension),
};

export const validationSchema = yup.object().shape(validations);
