import React from 'react';
import { useClickOutside } from './useClickOutside';
import { Container } from './StyledClickOutside';

export interface ClickOutsideHandlerProps {
  children: React.ReactNode;
  onClickOutside: () => void;
  event?: string;
  triggerElement?: React.MutableRefObject<HTMLElement | undefined>;
}

export const ClickOutsideHandler = ({
  onClickOutside = () => {},
  children,
  event = 'mousedown',
  triggerElement,
}: ClickOutsideHandlerProps) => {
  const { containerRef } = useClickOutside(onClickOutside, event, triggerElement);
  return (
    <Container ref={containerRef as any} id="click-outside-container">
      {children}
    </Container>
  );
};
