import { TextProps } from 'app/utils/interfaces/interfaces';

export const textVariantControl = ({ variant }: TextProps) => {
  switch (variant) {
    case 'subheading':
      return `
        font-family: effra;
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
        color: #47455f;
      `;
    case 'title':
      return `
        font-family: effra;
        font-size: 18px;
        font-weight: 400;
        color: #47455f;
      `;
    case 'label':
      return `
        font-family: Sofia Pro;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        text-transform: uppercase;
        color: #807da1;
      `;
    case 'value':
      return `
        font-family: Effra;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0.25px;
        text-align: left;
        color: #47455f;
        `;
    case 'regular':
    default:
      return `
        font-family: effra;
        font-size: 16px;
        font-weight: 400;
        color: #47455f;
      `;
  }
};
