import React from 'react';

import RadioButton from '../RadioButton/RadioButton';
import { HStack } from '../Stack/HStack';
import { StackProps } from '../../../utils/interfaces/interfaces';
import { VStack } from '../Stack/VStack';

interface SingleOptionRadioButtonsProps {
  options?: { label: string | React.ReactNode; value: any }[];
  stackOptions?: StackProps;
  style?: React.CSSProperties;
  value?: any;
  name?: string;
  id?: string;
  onChange?: (value: any) => void | ((evt: React.SyntheticEvent<HTMLInputElement>) => void);
  strictValueOnChange?: boolean;
  stackOrientation?: 'vertical' | 'horizontal';
}

const defaultOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

const defaultHorizontalStackOptions = {
  spacing: 48,
};

const defaultVerticalStackOptions = {
  spacing: 20,
};

const defaultHorizontalStyle = { display: 'inline-flex', width: 'min-content', margin: '12px 0px' };

const defaultVerticalStyle = { margin: '12px 0px' };

export const SingleOptionRadioButtons = ({
  options = defaultOptions,
  stackOptions,
  style,
  value,
  id,
  name = 'defaultSingleOptionRadio',
  strictValueOnChange,
  onChange,
  stackOrientation = 'horizontal',
}: SingleOptionRadioButtonsProps) => {
  const handleOnChange = (optionValue: any) => (evt: React.SyntheticEvent<HTMLInputElement>) => {
    if (strictValueOnChange) {
      onChange && onChange(optionValue);
    } else {
      onChange && onChange(evt);
    }
  };

  const renderButtons = () =>
    options.map((option, i) => (
      <RadioButton
        key={`${id}_${i + 1}`}
        id={`${id}_${i + 1}`}
        name={name}
        onChange={handleOnChange(option.value)}
        checked={option.value === value}
        label={option.label}
        value={option.value}
        style={{ width: stackOrientation === 'vertical' ? '100%' : '' }}
      />
    ));

  return stackOrientation === 'vertical' ? (
    <VStack
      {...{ ...defaultVerticalStackOptions, ...stackOptions }}
      style={{ ...defaultVerticalStyle, ...style }}
    >
      {renderButtons()}
    </VStack>
  ) : (
    <HStack
      {...{ ...defaultHorizontalStackOptions, ...stackOptions }}
      style={{ ...defaultHorizontalStyle, ...style }}
    >
      {renderButtons()}
    </HStack>
  );
};
