import React, { createContext, ReactNode, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Button, Modal, EButtonType } from '@dealsyte/poki';

import { ETransactionType, Fso as IFso } from '../../utils/types';
import FeatureFlag, { EFeatureFlag } from 'app/FeatureFlag';

import {
  StatusBar,
  StatusContainer,
  StatusText,
  DetailedStatusText,
  TransactionsDetailsButton,
  TransactionsDetailsTitle,
  FsosInTransferProgressesContainer,
  FsosInTransferDetailsName,
  ButtonsContainer,
} from '../components/TransactionProgress/StyledTransactionProgress';

export type IFsoInTransfer = {
  fso?: IFso;
  file?: any;
  path?: string;
  size?: string;
  status?: string;
  name: string;
  uuid?: string;
  id?: string;
  error?: boolean | null;
};

const TransactionBarContext = createContext({
  getTransactionProgress: () => {},
  setTransactionProgress: () => {},
  setupTransaction: () => {},
} as {
  getTransactionProgress: (progressValue: number, uuid?: string) => void;
  setTransactionProgress: ({
    currentFsosInTransfer,
  }: {
    currentFsosInTransfer: IFsoInTransfer[];
  }) => void;
  setupTransaction: ({
    isOnTransaction,
    transactionType,
    cancelTransactionMethod,
    isTransactionCanceled,
    isTransactionFailed,
    hideCancelButton,
    autoCloseAfterFinish,
  }: {
    isOnTransaction: boolean;
    transactionType?: ETransactionType;
    cancelTransactionMethod?: () => void;
    isTransactionCanceled?: boolean;
    isTransactionFailed?: boolean;
    hideCancelButton?: boolean;
    autoCloseAfterFinish?: boolean;
  }) => void;
});

function TransactionBarContextProvider({ children }: { children: ReactNode }) {
  const [detailedProgresses, setDetailedProgresses] = useState<{ [key: string]: number }>({});
  const [totalProgress, setTotalProgress] = useState(0);
  const [fsosInTransfer, setFsosInTransfer] = useState<IFsoInTransfer[]>([]);
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);

  const [initialConfigs, setInitialConfigs] = useState<{
    isOnTransaction: boolean;
    transactionType?: ETransactionType;
    cancelTransactionMethod?: () => void;
    isTransactionCanceled?: boolean;
    isTransactionFailed?: boolean;
    hideCancelButton?: boolean;
    autoCloseAfterFinish?: boolean;
  }>({
    isOnTransaction: false,
    transactionType: undefined,
    cancelTransactionMethod: undefined,
    isTransactionCanceled: false,
    isTransactionFailed: false,
    hideCancelButton: undefined,
    autoCloseAfterFinish: undefined,
  });

  useEffect(() => {
    if (initialConfigs.isTransactionFailed && initialConfigs.isOnTransaction) {
      setInitialConfigs(prevInitialCnofigs => ({ ...prevInitialCnofigs, isOnTransaction: false }));
    }
  }, [initialConfigs]);

  function getTransactionProgress(progressValue: number, uuid?: string) {
    if (!uuid) {
      setTotalProgress(progressValue);
    } else {
      const newDetailedProgresses = { ...detailedProgresses, [uuid]: progressValue };

      const progressSum = Object.values(newDetailedProgresses).reduce(
        (acc: number, detailedProgress: any) => acc + detailedProgress,
        0
      );

      const numberOfFsosInTransfer = fsosInTransfer.length;

      const newTotalProgress = Math.round(progressSum / numberOfFsosInTransfer);

      setDetailedProgresses(newDetailedProgresses);
      setTotalProgress(newTotalProgress);

      if (
        initialConfigs.autoCloseAfterFinish &&
        Object.values(newDetailedProgresses).filter(progress => progress === 100).length ===
          fsosInTransfer.length
      ) {
        setTimeout(
          () =>
            setupTransaction({
              isOnTransaction: false,
              transactionType: ETransactionType.upload,
            }),
          1000
        );
      }
    }
  }

  function setTransactionProgress({
    currentFsosInTransfer,
  }: {
    currentFsosInTransfer: IFsoInTransfer[];
  }) {
    setFsosInTransfer(currentFsosInTransfer);
  }

  function setupTransaction({
    isOnTransaction,
    transactionType,
  }: {
    isOnTransaction: boolean;
    transactionType?: ETransactionType;
  }) {
    if (!isOnTransaction) {
      setInitialConfigs(prevInitialConfigs => ({
        ...prevInitialConfigs,
        isOnTransaction,
        totalProgress: 0,
        fsosInTransfer: [],
        detailedProgresses: {},
      }));
    } else {
      setInitialConfigs(prevInitialConfigs => ({
        ...prevInitialConfigs,
        isOnTransaction,
        transactionType,
      }));
    }
  }

  function handleModalDetailsClose() {
    if (!initialConfigs.isOnTransaction) {
      setFsosInTransfer([]);

      setDetailedProgresses({});
    }

    setShowTransactionDetails(false);
  }

  function handleTransactionCancel() {
    if (initialConfigs.cancelTransactionMethod) initialConfigs.cancelTransactionMethod();

    setInitialConfigs(prevInitialConfigs => ({
      ...prevInitialConfigs,
      isOnTransaction: false,
    }));
  }

  const fsosInTransferProgresses =
    Array.isArray(fsosInTransfer) &&
    fsosInTransfer.map(({ name, uuid }) => {
      if (uuid)
        return {
          name,
          progress: detailedProgresses[uuid] ? detailedProgresses[uuid] : 0,
          uuid,
        };

      return null;
    });

  const statusMessages =
    initialConfigs.transactionType === ETransactionType.upload
      ? ['Upload', 'Uploading', 'uploaded']
      : ['Download', 'Downloading', 'downloaded'];

  return (
    <TransactionBarContext.Provider
      value={{
        getTransactionProgress,
        setTransactionProgress,
        setupTransaction,
      }}
    >
      <>
        {children}

        {initialConfigs.isOnTransaction &&
          !showTransactionDetails &&
          !initialConfigs.isTransactionFailed &&
          createPortal(
            <StatusContainer
              totalProgress={totalProgress}
              data-testid="TransactionBarStatusContainer"
            >
              {totalProgress < 100 ? (
                <StatusText data-testid="TransactionBarInProgressStatusText">
                  {statusMessages[1]}{' '}
                  {fsosInTransferProgresses ? 'files' : fsosInTransfer[0].name || ''}
                  ... {totalProgress}%
                </StatusText>
              ) : (
                <StatusText data-testid="TransactionBarCompletedStatusText">
                  Successfully {statusMessages[2]}{' '}
                  {fsosInTransferProgresses ? 'all files' : fsosInTransfer[0].name}
                </StatusText>
              )}
              <FeatureFlag feature={EFeatureFlag.VDR_TRANSACTION_DETAILS_MODAL}>
                {fsosInTransferProgresses && (
                  <TransactionsDetailsButton
                    buttonType={EButtonType.secondary}
                    onClick={() => {
                      setShowTransactionDetails(true);
                    }}
                    data-testid="TransactionBarDetailsModalOpenButton"
                  >
                    show details
                  </TransactionsDetailsButton>
                )}
              </FeatureFlag>
              <StatusBar totalProgress={totalProgress} />
            </StatusContainer>,
            document.body
          )}

        <Modal
          show={showTransactionDetails}
          onHide={handleModalDetailsClose}
          style={{ width: '30vw' }}
        >
          <TransactionsDetailsTitle>{statusMessages[0]} details</TransactionsDetailsTitle>

          {initialConfigs.isOnTransaction && !initialConfigs.isTransactionCanceled && (
            <FsosInTransferProgressesContainer data-testid="TransactionBarModal">
              {fsosInTransferProgresses &&
                fsosInTransferProgresses.map(({ name, progress, uuid }: any) => (
                  <div key={uuid} style={{ margin: '5px 0' }}>
                    <FsosInTransferDetailsName>{name}</FsosInTransferDetailsName>
                    <StatusBar totalProgress={progress} style={{ height: '20px' }}>
                      <DetailedStatusText>{progress}%</DetailedStatusText>
                    </StatusBar>
                  </div>
                ))}
            </FsosInTransferProgressesContainer>
          )}
          {!initialConfigs.isOnTransaction &&
            !initialConfigs.isTransactionCanceled &&
            !initialConfigs.isTransactionFailed && (
              <p style={{ margin: '20px 0' }} data-testid="TransactionSucceededText">
                Successfully {statusMessages[2]} all files.
              </p>
            )}
          {initialConfigs.isTransactionCanceled && (
            <p style={{ margin: '20px 0' }} data-testid="TransactionCanceledText">
              {statusMessages[0]} canceled.
            </p>
          )}
          <ButtonsContainer>
            <Button
              buttonType={EButtonType.primary}
              onClick={handleModalDetailsClose}
              data-testid="TransactionBarDetailsModalCloseButton"
            >
              Close
            </Button>
            {!initialConfigs.hideCancelButton && (
              <Button
                buttonType={EButtonType.primary}
                onClick={handleTransactionCancel}
                disabled={initialConfigs.isTransactionCanceled || !initialConfigs.isOnTransaction}
                data-testid="TransactionBarCancelTransactionButton"
              >
                Cancel {statusMessages[0]}
              </Button>
            )}
          </ButtonsContainer>
        </Modal>
      </>
    </TransactionBarContext.Provider>
  );
}

export { TransactionBarContextProvider, TransactionBarContext };
