// Vendors
import * as yup from 'yup';

// Constants
import { MAX_DEAL_SIZE, MIN_DEAL_SIZE } from 'app/utils/constants/app/appConstants';

const REQUIRED_MESSAGE = 'This field is required';
const EMPTY_MESSAGE = 'This field cannot be empty';
const PROHIBITED_CHARS_MESSAGE = 'Some characters are not allowed';
const ALPHANUMERIC_REGEX = /^[\w\-\s]*$/;

export const validationSchema = yup.object().shape({
  transactionType: yup
    .object()
    .nullable()
    .test(
      '',
      'A Transaction Type should be selected',
      option => !!option && option.value.length > 0
    ),
  dealStatus: yup
    .object()
    .nullable()
    .test('', 'A Deal Status should be selected', option => !!option && option.value.length > 0),
  expectedSize: yup
    .number()
    .required()
    .max(MAX_DEAL_SIZE, 'Expected Deal Size must be lower than or equal to $999,999,999,999,999')
    .min(MIN_DEAL_SIZE, 'Expected Deal Size must be greater than or equal to 1'),
  country: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .trim(EMPTY_MESSAGE)
    .matches(ALPHANUMERIC_REGEX, PROHIBITED_CHARS_MESSAGE),
  state: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .trim(EMPTY_MESSAGE)
    .matches(ALPHANUMERIC_REGEX, PROHIBITED_CHARS_MESSAGE),
  description: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .trim(EMPTY_MESSAGE),
  industryType: yup
    .object()
    .nullable()
    .test('', 'An Industry Type should be selected', option => !!option && option.value.length > 0),
  minSuccessFee: yup
    .number()
    .required(`Please provide a valid min value between 0 and 99`)
    .min(0, `Min value should be greater or equal than 0`)
    .max(99, `Min value should be less than 99`),
  maxSuccessFee: yup
    .number()
    .required(`Please provide a valid max value between 1 and 100`)
    .min(1, `Max should be greater than 1`)
    .max(100, `Max should be equal or less than 100`)
    .min(yup.ref('minSuccessFee'), 'Max should be greater than Min'),
});
