import React from 'react';
import { Button, HStack, SideDrawer } from 'app/common/components';

import OBAForm from './OBAForm';
import { useOBAFormHandler, OBAFormHandlerProvider } from './OBAFormHandler';

const OBAFormDrawer = () => {
  const handler = useOBAFormHandler();
  const { onSubmitOBAForm, submitting, form, modal, isEdit } = handler;
  const { modalState, hide } = modal;

  const hideModal = () => {
    hide();
    form.resetForm();
  };

  return (
    <OBAFormHandlerProvider value={handler}>
      <SideDrawer
        show={modalState.show}
        onClose={hideModal}
        title="Add an outside business activity"
        footerContent={({ onClose: promptClose }) => (
          <HStack spacing={16} justifyContent="flex-end">
            <Button className="tertiary" onClick={promptClose}>
              Dismiss
            </Button>
            <Button disabled={submitting || !form.isValid} onClick={onSubmitOBAForm}>
              {isEdit ? 'Save' : 'Submit'}
            </Button>
          </HStack>
        )}
        closeWhenClickOutside={false}
        promptOnClose={form.dirty}
      >
        <OBAForm />
      </SideDrawer>
    </OBAFormHandlerProvider>
  );
};

export default OBAFormDrawer;
