import React, { useEffect, useState } from 'react';

import { SideDrawer } from 'app/common/components';

import { useKYCFormHandler, KYCHandlerProvider } from './KYCFormHandler';
import KYCForm from './KYCForm';
import { KYCLabels, STEPS } from './KYCFormConstants';
import KYCFormFooter from './KYCFormFooter';
import { KYCSubmitModal } from './KYCSubmitModal/KYCSubmitModal';

const KYCFormDrawer = () => {
  const handler = useKYCFormHandler();
  const {
    currentStep,
    setCurrentStep,
    setPreviousStep,
    counterpartyHandler,
    clientHandler,
    modal,
  } = handler;
  const { form: clientForm } = clientHandler;
  const { form: counterpartyForm } = counterpartyHandler;
  const { modalState, hide } = modal;
  const [title, setTitle] = useState(KYCLabels.DRAWER_TITLE);

  const getDrawerTitle = () => {
    if (currentStep === STEPS.OPTION_SELECTOR) return setTitle(KYCLabels.DRAWER_TITLE);

    const result: string =
      currentStep === STEPS.CLIENT
        ? `${KYCLabels.CLIENT} ${KYCLabels.DRAWER_TITLE}`
        : `${KYCLabels.COUNTERPARTY} ${KYCLabels.DRAWER_TITLE}`;

    return setTitle(result);
  };

  useEffect(() => {
    getDrawerTitle();
  }, [currentStep]);

  const hideDrawer = () => {
    hide();
    setPreviousStep(currentStep);
    if (currentStep === STEPS.CLIENT) clientForm.resetForm();
    if (currentStep === STEPS.COUNTERPARTY) counterpartyForm.resetForm();
    setCurrentStep(STEPS.OPTION_SELECTOR);
  };

  useEffect(() => {
    getDrawerTitle();
  }, [currentStep]);

  return (
    <KYCHandlerProvider value={handler}>
      <SideDrawer
        show={modalState.show}
        onClose={hideDrawer}
        title={title}
        footerContent={({ onClose: promptClose }) => (
          <KYCFormFooter onClose={promptClose} currentStep={currentStep} hideDrawer={hideDrawer} />
        )}
        closeWhenClickOutside={false}
        promptOnClose
      >
        <KYCForm
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setPreviousStep={setPreviousStep}
        />
      </SideDrawer>

      <KYCSubmitModal />
    </KYCHandlerProvider>
  );
};

export default KYCFormDrawer;
