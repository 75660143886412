import React from 'react';

// Components
import NewDealForm from './NewDealForm';
import NewDealFormFooter from './NewDealFormFooter';
import { SideDrawer } from '../../../../common/components';

import { useNewDealFormHandler, NewDealHandlerProvider } from './NewDealFormHandler';

const NewDealFormDrawer = () => {
  const handler = useNewDealFormHandler();
  const { form, modal, onClose } = handler;
  const {
    modalState: { show },
  } = modal;

  return (
    <NewDealHandlerProvider value={handler}>
      <SideDrawer
        show={show}
        onClose={onClose}
        title="Create a new deal"
        progress={form.percentage}
        footerContent={({ onClose: promptClose }) => <NewDealFormFooter onClose={promptClose} />}
        closeWhenClickOutside={false}
        promptOnClose={form.dirty}
      >
        <NewDealForm />
      </SideDrawer>
    </NewDealHandlerProvider>
  );
};

export default NewDealFormDrawer;
