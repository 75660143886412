// vendors
import React from 'react';
import _get from 'lodash/get';

// Components
import MarketplaceConfig from './MarketplaceConfig/MarketplaceConfig';

// types
import { ELabels, IMarketplaceSettingsProps } from './TypedMarketplaceSettings';

// styles
import { ContentContainer, MainContainer } from './StyledMarketplaceSettings';

// AppStorage
import AppStorage, { EMAIL_KEY } from 'app/appStorage';

const MarketplaceSettings = ({ mDeal }: IMarketplaceSettingsProps) => {
  const isEmailDefined = AppStorage.getItem(EMAIL_KEY);
  const email: string = isEmailDefined ? isEmailDefined.toString() : '';

  return (
    <MainContainer>
      <ContentContainer>
        <div className="title">{ELabels.MARKETPLACE_SETTINGS_TAB_TITLE}</div>
        <p className="paragraph">
          {`View Marketplace settings for ${_get(mDeal, 'name', '')} below.`}
        </p>
        {email && <MarketplaceConfig mDeal={mDeal} />}
      </ContentContainer>
    </MainContainer>
  );
};

export default MarketplaceSettings;
