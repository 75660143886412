import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';

import { UPDATE_WATERMARK_CONFIGS } from 'api/graphql/core-tools/vdr/mutations';
import { GET_WATERMARK_CONFIGS } from 'api/graphql/core-tools/vdr/queries';
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { UserContext } from 'app/users/context/UserContext';
import {
  GET_WATERMARK_CONFIGS_QUERY,
  GET_WATERMARK_CONFIGS_QUERYVariables,
} from 'api/graphql/core-tools/vdr/types/GET_WATERMARK_CONFIGS_QUERY';

type Props = {
  children: (
    mutateFunction: (key: string, value: string | boolean) => Promise<any>,
    updateConfigLoadingState: boolean,
    updateConfigErrorState: any
  ) => JSX.Element;
  currentConfigs: any;
};

function UpdateWatermarkContentMutation({ children, currentConfigs }: Props) {
  const { activeDeal } = useContext(UserContext);
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);

  const [
    updateWatermarkConfigsMutation,
    {
      loading: loadingUpdateWatermarkConfigsMutation,
      error: errorOnUpdateWatermarkConfigsMutation,
    },
  ] = useMutation(UPDATE_WATERMARK_CONFIGS);

  function updateConfigsMutation(key: string, value: string | boolean) {
    return updateWatermarkConfigsMutation({
      variables: { key, value },
      update: (cache, { data }) => {
        const updateWatermarkConfigsMutationResponse = get(data, 'updateConfig');

        if (updateWatermarkConfigsMutationResponse) {
          const configs = [...currentConfigs];

          const formattedConfig = [] as any[];

          configs.forEach(config => {
            formattedConfig.push({
              key: config.key,
              value:
                config.key === updateWatermarkConfigsMutationResponse.key
                  ? updateWatermarkConfigsMutationResponse.value
                  : config.value.toString(),
              type: config.key === 'TEXT_LABEL' ? 'string' : 'boolean',
              __typename: 'Config',
            });
          });

          cache.writeQuery<GET_WATERMARK_CONFIGS_QUERY, GET_WATERMARK_CONFIGS_QUERYVariables>({
            query: GET_WATERMARK_CONFIGS,
            variables: { dealId: get(activeDeal, 'id', ''), context: 'watermark' },
            data: { configs: formattedConfig },
          });
        }
      },
    })
      .then(() => {
        showSuccessToast({ title: 'Settings saved successfully' });
      })
      .catch(() => {
        showErrorToast({ title: 'Error saving settings' });
      });
  }

  return children(
    updateConfigsMutation,
    loadingUpdateWatermarkConfigsMutation,
    errorOnUpdateWatermarkConfigsMutation
  );
}

export default UpdateWatermarkContentMutation;
