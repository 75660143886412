import gql from 'graphql-tag';

export const LogFragment = gql`
  fragment LogBasicFragment on Log {
    id
    type
    time
    user {
      id
      firstName
      lastName
    }
  }
`;
