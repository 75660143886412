import React, { useState } from 'react';
// styles
import * as S from './ToggleStyled';

export interface ToggleOption {
  label: string;
  value: any;
  icon?: React.ReactElement;
}
export interface ToggleProps {
  value: any;
  onChange: (value: any) => void;
  options: [ToggleOption, ToggleOption];
}

export const Toggle = ({ value, onChange, options }: ToggleProps) => {
  const [left, right] = options;
  const [toggleClass, setToggleClass] = useState('');
  const hoverHandler = (className: string) => () => setToggleClass(className);
  const whoIsActive = value === left.value ? 'left' : 'right';
  const inferClass = whoIsActive === toggleClass ? 'activeHover' : 'inactiveHover';

  return (
    <S.ViewContainer className={inferClass}>
      <S.ButtonIcon
        className={left.value === value ? 'btn-active' : 'btn-inactive'}
        onMouseEnter={hoverHandler('left')}
        onClick={() => onChange(left.value)}
        data-dataTestid="view-control"
      >
        {left.icon}
        {left.label}
      </S.ButtonIcon>
      <S.ButtonIcon
        className={right.value === value ? 'btn-active' : 'btn-inactive'}
        onMouseEnter={hoverHandler('right')}
        onClick={() => onChange(right.value)}
        data-dataTestid="view-control"
      >
        {right.icon}
        {right.label}
      </S.ButtonIcon>
    </S.ViewContainer>
  );
};
