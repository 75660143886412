import { actionTypes } from './actions';

const reducer = (state = { notifications: {} }, action) => {
  switch (action.type) {
    case actionTypes.ADD_NOTIFICATION: {
      const id = action.payload.notification && action.payload.notification.id;

      return {
        ...state,
        notifications: {
          ...state.notifications,
          [id]: { ...action.payload.notification, id, read: false },
        },
      };
    }
    case actionTypes.MARK_NOTIFICATION_AS_SHOWN: {
      const { notificationId } = action.payload;

      return {
        ...state,
        notifications: {
          ...state.notifications,
          [notificationId]: { ...state.notifications[notificationId], shown: true },
        },
      };
    }
    case actionTypes.MARK_NOTIFICATION_AS_READ: {
      const { notificationId } = action.payload;

      return {
        ...state,
        notifications: {
          ...state.notifications,
          [notificationId]: { ...state.notifications[notificationId], read: true },
        },
      };
    }
    case actionTypes.CLEAR_NOTIFICATIONS: {
      return { notifications: {} };
    }
    default:
      return state;
  }
};

export default reducer;
