export type IProps = {
  show?: boolean;
  onHide: () => void;
};

export enum EUserSettingType {
  ACCOUNT = 'Account',
  PASSWORD = 'Password',
  BANK_ACCOUNT = 'Bank account',
  STATE_REGISTRATION = 'State registrations',
}

export enum ESettingsAccountLabels {
  NAME = 'First Name',
  LAST_NAME = 'Last name',
  EMAIL_TITLE = 'Email',
  EMAIL_BODY = 'Make sure to use your work email.',
  AVATAR_TITLE = 'Avatar',
  AVATAR_BUTTON = 'Upload image',
  INFO_TITLE = 'Info',
  MFA_TITLE = 'MFA (Multi-factor authentication)',
  MFA_ENABLE_TITLE = 'Enable MFA',
  MFA_DISABLE_TITLE = 'Disable MFA',
  MFA_ENABLE_ACTION = 'Click to enable MFA',
  MFA_DISABLE_ACTION = 'Click to Disable MFA',
  MFA_ENABLED = 'MFA is enabled for your account',
  MFA_DISABLED = 'MFA is disabled for your account',
  MFA_TOKEN = 'MFA Code (six-digit)',
  SUCCESS_ENABLE_MFA_MESSAGE = 'Multi-factor authentication enabled.',
  SUCCESS_DISABLE_MFA_MESSAGE = 'Multi-factor authentication disabled.',
  INFO_BODY = 'Member since ',
  UNLINK_FROM_DEAL_TITLE = 'Unlink account from current deal',
  UNLINK_FROM_DEAL_BODY = 'Your account will no longer have access to the current deal. All of the data you added inside this deal will be kept.',
  UNLINK_FROM_DEAL_BUTTON = 'Unlink from Deal',
  UNLINK_FROM_DEAL_ALERT_TITLE = 'Unlink account',
  UNLINK_FROM_DEAL_ALERT_BUTTON = 'Unlink',
  SUCCESS_UPDATE_PROFILE_MESSAGE = 'Your profile has been successfully updated',
}

export enum ESettingsPasswordLabels {
  CHANGE_PASSWORD = 'Change Password',
  SET_PASSWORD = 'Set Password',
  CURRENT_PASSWORD = 'Current Password',
  NEW_PASSWORD = 'New Password',
  VERIFY_PASSWORD = 'Verify Password',
  MORE_THAN_8_CHARS_PW_STRENGTH = 'Minimum 8 characters long',
  MORE_THAN_1_LOWER_AND_UPPERCASE_PW_STRENGTH = 'Include upper and lower case characters',
  MORE_THAN_1_SPECIAL_CHAR_PW_STRENGTH = 'At least one special character ONLY from these: @ $ ! % * ? &',
  SUCCESS_UPDATE_PASSWORD_MESSAGE = 'Your password has been successfully updated',
}
