import React, { useContext } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { UploadFileIcon, UploadIcon } from 'app/common/icons';
import * as S from './FormFilePickerStyled';
import { Button } from '../Button/Button';
import { ExtendedFile, FileStatus, FileUploaderFn } from './ExtendedFile';
import { invalidFormatError, maxAllowedFilesError, invalidNameFile } from './FormFileConstants';
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { SUPPORTED_FILENAME_VALIDATION } from 'app/utils/helpers/formValidators';

interface FilePickerDropzoneProps {
  options: DropzoneOptions;
  label?: string | React.ReactNode;
  buttonLabel?: string;
  fileStateSetter?: (file: ExtendedFile) => void;
  fileUploaderFn?: FileUploaderFn;
  autoUpload?: boolean;
  displayAs?: 'dropZone' | 'button';
  supportedFileFormats?: string | string[];
  maxFiles: number;
  storedFiles: ExtendedFile[];
  setInternalError?: (value: string) => void;
}

export const FilePickerDropzone = ({
  options,
  label,
  autoUpload,
  buttonLabel,
  fileStateSetter = () => {},
  fileUploaderFn,
  displayAs = 'dropZone',
  supportedFileFormats = '',
  maxFiles = 30,
  storedFiles = [],
  setInternalError = () => {},
}: FilePickerDropzoneProps) => {
  const { showToastV2 } = useContext(AlertContext);
  const { getRootProps, getInputProps, open } = useDropzone({
    ...options,
    onDrop: (files, rejectedFiles, event) => {
      if (storedFiles.length + files.length > maxFiles) {
        showToastV2({
          description: maxAllowedFilesError(maxFiles),
          variant: 'danger',
        });
        return;
      }
      const extendedFiles = files.map(
        file => new ExtendedFile(file, fileStateSetter, fileUploaderFn)
      ) as ExtendedFile[];

      if (autoUpload) {
        extendedFiles.forEach(file => file.upload());
      } else {
        extendedFiles.forEach(file => (file.status = FileStatus.Completed));
      }

      if (options.onDrop) {
        options.onDrop<any>(extendedFiles, rejectedFiles, event);
      }
      if (
        !extendedFiles.every(
          extendedFile =>
            extendedFile.file.type && supportedFileFormats.includes(extendedFile.file.type)
        )
      ) {
        setInternalError(invalidFormatError);
      }
      if (
        !extendedFiles.every(extendedFile =>
          SUPPORTED_FILENAME_VALIDATION.test(extendedFile.file.name)
        )
      ) {
        setInternalError(invalidNameFile);
      }
    },
  });
  return displayAs === 'button' ? (
    <S.ButtonContainerDropArea {...getRootProps()}>
      <input type="file" {...getInputProps()} />
      <Button type="button" className="secondary">
        <UploadFileIcon style={{ marginRight: '10px' }} />
        Upload file
      </Button>
    </S.ButtonContainerDropArea>
  ) : (
    <>
      <S.FilePickerDropArea {...getRootProps()}>
        <UploadIcon />
        <S.Label>{label || 'Drop a file or select one from your device'}</S.Label>
        <input type="file" {...getInputProps()} />
        <Button className="secondary" style={{ width: 176 }}>
          {buttonLabel || 'Select a file'}
        </Button>
      </S.FilePickerDropArea>
    </>
  );
};
