import * as React from 'react';

import { IconProps } from './types';

export const FilesIconVDR = ({
  width = '12px',
  height = '14px',
  color = '#807DA1',
}: IconProps): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 12 14" fill="none">
    <path
      d="M11.3334 4.9609C11.3264 4.89965 11.313 4.83932 11.2934 4.7809V4.7209C11.2613 4.65235 11.2185 4.58934 11.1667 4.53423L7.16669 0.534228C7.11158 0.482373 7.04857 0.439616 6.98002 0.407562H6.92002C6.8523 0.368723 6.7775 0.343791 6.70002 0.334229H2.66669C2.13625 0.334229 1.62755 0.544942 1.25247 0.920015C0.877401 1.29509 0.666687 1.8038 0.666687 2.33423V11.6676C0.666687 12.198 0.877401 12.7067 1.25247 13.0818C1.62755 13.4568 2.13625 13.6676 2.66669 13.6676H9.33335C9.86379 13.6676 10.3725 13.4568 10.7476 13.0818C11.1226 12.7067 11.3334 12.198 11.3334 11.6676V5.0009C11.3334 5.0009 11.3334 5.0009 11.3334 4.9609ZM7.33335 2.60756L9.06002 4.33423H8.00002C7.82321 4.33423 7.65364 4.26399 7.52862 4.13897C7.40359 4.01394 7.33335 3.84437 7.33335 3.66756V2.60756ZM10 11.6676C10 11.8444 9.92978 12.0139 9.80476 12.139C9.67973 12.264 9.51016 12.3342 9.33335 12.3342H2.66669C2.48988 12.3342 2.32031 12.264 2.19528 12.139C2.07026 12.0139 2.00002 11.8444 2.00002 11.6676V2.33423C2.00002 2.15742 2.07026 1.98785 2.19528 1.86282C2.32031 1.7378 2.48988 1.66756 2.66669 1.66756H6.00002V3.66756C6.00002 4.19799 6.21073 4.7067 6.58581 5.08178C6.96088 5.45685 7.46959 5.66756 8.00002 5.66756H10V11.6676Z"
      fill={color}
    />
  </svg>
);
