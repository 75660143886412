export enum ELabels {
  EMAIL = 'Email Address',
  PASSWORD = 'Password',
  RESET_PASSWORD = 'Reset your password',
  FORGOT_PASSWORD = 'Forgot password?',
  LOGIN_LOCAL = 'Log in',
  LOGIN_WITH_GOOGLE = 'Login with Google',
  LOGIN_MFA_CODE = 'Send Code',
  MFA_TOKEN = 'Multi-factor Authenticator (MFA) Code',
}

export enum ELoginErrors {
  EMPTY_EMAIL = 'Please provide an email address.',
  INVALID_EMAIL = 'Please provide a valid email address.',
  EMPTY_PASSWORD = 'Please provide a password.',
  EMPTY_MFA_CODE = 'Please provide a MFA code (six-digit)',
  INVALID_MFA_LENGTH = 'MFA Code must be six digit',
  EMPTY_EMAIL_AND_PASSWORD = 'The email and password you entered didn’t match our records, please double-check and try again.',
  THIRD_PARTY_GENERAL_TITLE = 'Login failed',
  THIRD_PARTY_GENERAL_DESCRIPTION = 'Cannot login with your third party account. Please try again later or contact our support team.',
  THIRD_PARTY_GOOGLE_DESCRIPTION = 'Cannot login with your Google account. Please try again later or contact our support team.',
  MFA_REQUIRED = 'TOTP_REQUIRED',
  TOTP_INVALID = 'TOTP_INVALID',
}
