import * as React from 'react';

import { IconProps } from './types';

export const DownArrow = ({
  width = '12px',
  height = '8px',
  color = 'currentColor',
  style,
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 8"
  >
    <path
      d="M11 1.205a1 1 0 0 0-1.41 0L6 4.745l-3.54-3.54a1 1 0 1 0-1.41 1.42l4.24 4.24a1 1 0 0 0 1.42 0L11 2.625a.998.998 0 0 0 0-1.42Z"
      fill={color}
    />
  </svg>
);
