import styled from 'styled-components';

export const SideDrawerContent = styled.div`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return `
      .DatePicker {
        z-index: 0;
      }

      .subheader {
        margin-bottom: ${spacing['05']};
        display: flex;

        .badge-container {
          margin-left: ${spacing['04']};
          margin-top: ${spacing['01']};
        }
      }

      .textarea-container {
        padding-top: ${spacing['06']};
      }
      `;
  }}
`;

export const SuccessContainer = styled.div`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return `
      display: flex;

      .min-input {
        display: flex;
        align-items: center;
        flex: 1;
      }

      .max-input {
        display: flex;
        align-items: center;
        flex: 1;
        padding-left: ${spacing['04']};
      }
    `;
  }}
`;

export const ButtonsContainer = styled.div`
  ${({ theme }) => {
    const { radii, typography, sizes, colors } = theme.foundations;
    return `
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-self: flex-start;
      .filter-button {
        width: 100%;
        height: ${sizes['09']};
        border-radius: ${radii.md};
        background-color: ${colors.palette.neutral['600']};
        justify-content: center;
        display: flex;
        align-items: center;
        cursor: pointer;

        p {
          color: ${colors.palette.neutral['50']};
          font-size: ${typography.sizes['04']};
          font-family: ${typography.fonts.body};
          letter-spacing: 0.5px;
        }
      }

      p {
        font-family: ${typography.fonts.body};
        font-weight: ${typography.weights.regular};
        font-size: ${typography.sizes['04']};
        line-height: 16px;
        letter-spacing: 0.5px;
        display: inline-block;
      }
  `;
  }}
`;

export const SelectCustomStyles = (errorStyle?: {} | undefined) => {
  return {
    // @ts-ignore
    placeholder: base => ({
      ...base,
      color: '#807da1',
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: 0.25,
    }),
    // @ts-ignore
    control: base => ({
      ...base,
      height: 35,
      backgroundColor: '#ffff',
      minHeight: 35,
      minWidth: 450,
      border: '1px solid #807da1',
      ...errorStyle,
    }),
    // @ts-ignore
    container: base => ({
      ...base,
      width: '100%',
      display: 'inline-block',
      minHeight: 30,
      height: 30,
      borderColor: '',
      fontSize: 12,
    }),
    // @ts-ignore
    valueContainer: base => ({
      ...base,
      minHeight: 30,
      fontSize: 12,
      fontWeight: 'normal',
      color: '#707f8f',
    }),
    // @ts-ignore
    dropdownIndicator: base => ({
      ...base,
      padding: 4,
    }),
    // @ts-ignore
    singleValue: base => ({
      ...base,
      fontSize: 12,
      fontWeight: 'normal',
      color: '#707f8f',
    }),
    // @ts-ignore
    menuList: base => ({
      ...base,
      height: 200,
    }),
    // @ts-ignore
    indicatorSeparator: base => ({
      ...base,
      display: 'none',
    }),
  };
};
