import styled from 'styled-components';

type IOptionCardContainer = {
  checked?: boolean;
  disabled?: boolean;
};

export const OptionCardContainer = styled.label<IOptionCardContainer>`
  ${({ theme, disabled, checked }) => {
    const { colors, spacing, radii } = theme.foundations;
    return `
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: ${spacing['05']};
      border: ${
        checked
          ? `2px solid ${colors.palette.neutral[700]}`
          : `1px solid ${colors.palette.neutral[200]}`
      };
      border-radius: ${radii.md};
      position: relative;
      ${!disabled &&
        `&:hover{
          cursor:pointer;
          background-color: ${colors.palette.neutral['100']};
      }`}
      &:focus{
        border: 1px solid ${colors.palette.neutral[200]};
      }
    `;
  }};
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 11px;
`;

export const CardTitle = styled.div`
  ${({ theme }) => {
    const { colors, typography } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.bold};
      font-size: ${typography.sizes['04']};
      letter-spacing: 0.5px;
      line-height: 24px;
      color: ${colors.palette.neutral['600']};
    `;
  }}
`;

export const CardSubtitle = styled.div`
  ${({ theme }) => {
    const { colors, typography } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['03']};
      letter-spacing: 0.25px;
      line-height: 21px;
      color: ${colors.palette.neutral['500']};
      max-width: 362px;
    `;
  }}
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
