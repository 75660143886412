// Libraries
import React from 'react';

import { MessageIcon } from 'app/common/icons';

// Styled components
import { MessageBoxWrapper } from './StyledMessageBox';

// Types
import { IProps } from './TypesMessageBox';

export const MessageBox = ({ align, children, icon = <MessageIcon /> }: IProps): JSX.Element => (
  <MessageBoxWrapper align={align}>
    {icon}
    <p>{children}</p>
  </MessageBoxWrapper>
);
