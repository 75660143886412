import React from 'react';
import { OrganizationBadge } from '@dealsyte/poki';

import { MainContainer, TextContainer, Name, Description } from './StyledGroupListItem';

export enum GroupItemType {
  INTERNAL,
  EXTERNAL,
}

type IProps = {
  id: string;
  idx: number;
  name: string;
  users: any[];
  groups: any[];
  active?: boolean;
  internal: boolean;
  setActive: (idx: number) => void;
};

/**
 * Renders a member or group list item (for any type of list).
 * @param props Group details to render in the row.
 */
function GroupListItem({ internal, name, users, groups, active, setActive, idx }: IProps) {
  const descriptionOccupants = users.length;
  let descriptionLabel = 'member';
  descriptionLabel += descriptionOccupants > 1 || descriptionOccupants === 0 ? 's' : '';

  return (
    <MainContainer active={active} onClick={() => setActive(idx)}>
      <OrganizationBadge name={name} />
      <TextContainer>
        <Name active={active}>{name}</Name>
        <Description active={active}>
          {descriptionOccupants} {descriptionLabel}
        </Description>
      </TextContainer>
    </MainContainer>
  );
}
GroupListItem.displayName = 'GroupListItem';

export default GroupListItem;
