import { PositionMap } from 'app/utils/helpers/formDataMappers';
import { FormikStub } from 'app/utils/hooks/useForm';
import { FormType } from 'types/graphql-types';
import { CDOCLabels } from './ClosingDocumentsFormConstants';
import { ClosingDocumentsForm } from './ClosingDocumentsFormHandler';

export const buildCDOCForm = (form: FormikStub<ClosingDocumentsForm>, dealId: string) => {
  const { files, conflicts, conflictDescription, closingDate, dealSize, note } = form.values;
  const variables = {
    type: FormType.CDOC,
    dealId,
    content: [
      {
        question: CDOCLabels.CONFLICTS,
        input: `${conflicts ? 'Yes' : 'No'}${
          conflictDescription ? `, ${conflictDescription}` : ''
        }`,
        key: 'conflicts',
        value: JSON.stringify({
          conflicts,
          conflictDescription,
        }),
      },
      {
        question: CDOCLabels.CLOSING_DATE,
        input: closingDate,
        key: 'closingDate',
        value: JSON.stringify(closingDate),
      },
      {
        question: CDOCLabels.DEAL_SIZE,
        input: dealSize,
        key: 'dealSize',
        value: JSON.stringify(dealSize),
      },
    ],
    note,
    files,
  };

  return variables;
};

export const CDOC_VALUE_INFERENCE_MAP: PositionMap = {
  closingDate: { match: CDOCLabels.CLOSING_DATE },
  dealSize: { match: CDOCLabels.DEAL_SIZE },
  conflicts: {
    match: CDOCLabels.CONFLICTS,
    value: ({ input }) => {
      const hasDescription = input.includes(',');
      return hasDescription ? input.split(',')[0] === 'Yes' : input === 'Yes';
    },
  },
  conflictDescription: {
    match: CDOCLabels.CONFLICTS,
    value: ({ input }) => {
      const hasDescription = input.includes(',');
      return hasDescription ? input.split(',')[1] : '';
    },
  },
};
