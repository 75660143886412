import { EFormStatus } from '../types/formsTypes';

export enum CompensationValue {
  cash = 'Cash',
  options = 'Options',
  others = 'Others',
  shares = 'Shares or membership interests',
  warrants = 'Warrants',
}

export const MAX_PST_TRANSACTION_SIZE = 2147483647; // $

export const ONBOARDING_DATE = '2022-10-17';

export const LAUNCH_USER_FREE_TEMPLATE = 'cfef20b6-f8f3-467b-aadd-3da08772fb40';

export const ACCEPTED_FILES =
  ' .jp2, .jps, .bmp, .exr, .json, .js, .ods, .svg, .html, .heif, .heic, .csv, .jpg, .jpeg, .png, text/plain, .doc, .docx, .xls, .xlsx, application/excel, application/vnd.ms-excel, application/x-excel, application/x-msexcel, application/doc, application/ms-doc, application/msword, application/pdf, application/ppt, application/pptx';

export const DISABLED_STATUS_VALUES = [
  EFormStatus.APPROVED,
  EFormStatus.APPROVED_WITH_CONDITIONS,
  EFormStatus.CLOSED_DUPLICATED,
  EFormStatus.CLOSED,
  EFormStatus.REJECTED,
];
