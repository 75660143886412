import React, { useState } from 'react';
import { EyeIcon, EButtonSizeType } from '@dealsyte/poki';

import { MainContainer, Input, ButtonContainer, EyeButton } from './StyledPasswordInput';

type IProps = {
  id: string;
  value: string | number | string[];
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
  name?: string;
  style?: React.CSSProperties;
  placeholder?: string;
};

/**
 * Renders a styled input component with show/hide password text init.
 * @param props Input props to be passed directly to the styled <input/>.
 */
function PasswordInput({ value, onChange, onBlur, id, style, name, placeholder }: IProps) {
  const [activeInputType, setActiveInputType] = useState<boolean>(false);

  return (
    <MainContainer style={style}>
      <Input
        type={activeInputType ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        id={id}
        name={name}
        placeholder={placeholder}
        data-testid="login-input"
      />
      <ButtonContainer
        onClick={() => setActiveInputType(prevActiveInputType => !prevActiveInputType)}
      >
        <EyeButton type="button" size={EButtonSizeType.small} data-testid="login-eye-password">
          <EyeIcon opened={activeInputType} />
        </EyeButton>
      </ButtonContainer>
    </MainContainer>
  );
}
PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;
