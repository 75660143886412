import { AnyAction } from 'redux';
import uiActionTypes from './uiActionTypes';

export interface UiState {
  modals: {
    [key: string]: {
      show: boolean;
      props?: any;
    };
  };
  redirecting: boolean;
}

const initialState: UiState = {
  modals: {},
  redirecting: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case uiActionTypes.SHOW_MODAL: {
      const { modalId, props } = action.payload;
      const modalState = state.modals[modalId] || {};
      return {
        ...state,
        modals: {
          ...state.modals,
          [modalId]: {
            ...modalState,
            show: true,
            props: props || modalState.props || {},
          },
        },
      };
    }
    case uiActionTypes.HIDE_MODAL: {
      const { modalId } = action.payload;
      const modalState = state.modals[modalId] || {};
      return {
        ...state,
        modals: {
          ...state.modals,
          [modalId]: {
            ...modalState,
            show: false,
            props: {},
          },
        },
      };
    }
    case uiActionTypes.SET_REDIRECTING: {
      const { redirecting } = action.payload;
      return {
        ...state,
        redirecting,
      };
    }
    default:
      return state;
  }
};
