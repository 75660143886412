import * as yup from 'yup';
import validateFileExtension, {
  basicRequiredInputValidation,
  REQUIRED_MESSAGE,
  validateFileName,
} from '../../../../utils/helpers/formValidators';

export const validationSchema = yup.object().shape({
  invoiceFiles: yup
    .array()
    .required(REQUIRED_MESSAGE)
    .test('', 'Some file has an invalid name', validateFileName)
    .test('', 'Some file has an invalid extension', validateFileExtension),
  breakdown: basicRequiredInputValidation(),
});
