import React, { useEffect, useRef, useContext, useState } from 'react';
import { useScroll } from 'app/utils/hooks/useScroll';

interface ScrollToErrorProps {
  children: React.ReactNode;
  field: string;
}

interface IScrollToErrorContext {
  activeError: string;
  setActiveErrorCounter: number;
  scrollToField: (value: any) => void;
}

const ScrollToErrorContext = React.createContext<IScrollToErrorContext>(
  {} as IScrollToErrorContext
);

export const ScrollToError = ({ children, field }: ScrollToErrorProps) => {
  const elementRef = useRef<any>();
  const { scrollToView } = useScroll(elementRef);
  const { activeError, setActiveErrorCounter } = useContext(ScrollToErrorContext);

  useEffect(() => {
    if (field === activeError) {
      scrollToView({ behavior: 'smooth', block: 'center' });
    }
  }, [activeError, setActiveErrorCounter]);

  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ref: elementRef } as any);
    }
    return child;
  });
  return <>{childrenWithProps}</>;
};

export const withScrollToError = <P extends any>(FormComponent: React.ComponentType<P>) => (
  props: P
) => {
  const [activeError, setActiveErrorState] = useState<string>('');
  const [setActiveErrorCounter, setErrorCounter] = useState(0);

  const setActiveError = (value: string) => {
    setActiveErrorState(value);
    setErrorCounter(counter => counter + 1);
  };

  const scrollToField = (errors: any) => {
    const errorKeys = Object.keys(errors); // errors from formik
    const activeError = errorKeys.length > 0 ? errorKeys[0] : '';
    setActiveError(activeError);
  };

  const scrollToErrorProps = {
    activeError,
    setActiveErrorCounter,
    scrollToField,
  };

  return (
    <ScrollToErrorContext.Provider value={scrollToErrorProps}>
      <FormComponent {...scrollToErrorProps} {...props} />
    </ScrollToErrorContext.Provider>
  );
};
