type ReviverFn = (key: string, value: any) => any;
type TransformFn = (input: string) => string;

export interface SafeParseOptions<T = any> {
  fallBackValue?: T;
  reviver?: ReviverFn;
  transformers?: TransformFn | TransformFn[];
}

const compose = (...functions: TransformFn[]) => (value: string) =>
  (functions || []).reduce((prev, curr) => curr(prev), value);

const preTransformInput = (transformers: TransformFn | TransformFn[], input: string) => {
  const isArray = Array.isArray(transformers);
  const isFunction = typeof transformers === 'function';
  if (isArray) {
    return compose(...(transformers as TransformFn[]))(input);
  }
  if (isFunction) {
    return (transformers as TransformFn)(input);
  }
  return input;
};

const safeParse = <T = any>(input: string, options?: SafeParseOptions<T>): T => {
  let _input = input;
  try {
    if (options && options.transformers) {
      _input = preTransformInput(options.transformers, _input);
    }
    return JSON.parse(_input, options && options.reviver);
  } catch (e) {
    if (options && options.fallBackValue !== undefined) {
      return options.fallBackValue;
    }
    return {} as T;
  }
};

export default safeParse;
