import gql from 'graphql-tag';

export const UserFragment = gql`
  fragment UserBasicFragment on User {
    id
    firstName
    lastName
    displayName
    organization {
      id
      name
    }
  }
`;

export const OrganizationFragment = gql`
  fragment OrganizationBasicFragment on Organization {
    id
    name
  }
`;

export const GroupFragment = gql`
  fragment GroupFragment on Group {
    id
    name
    type
    users {
      id
    }
  }
`;

export const GroupSettingsFragment = gql`
  fragment GroupSettingsFragment on Group {
    id
    name
    type
    owner {
      id
      displayName
    }
    users {
      id
      displayName
      email
      side
      role
    }
    relatedGroups {
      parents {
        id
        name
        type
        owner {
          id
          displayName
        }
        users {
          id
          displayName
          email
          side
          role
        }
        invites {
          invitationId
          email
          role
          expired
          expiresAt
        }
      }
      children {
        id
        name
        type
        owner {
          id
          displayName
        }
        users {
          id
          displayName
          email
          side
          role
        }
        invites {
          invitationId
          email
          role
          expired
          expiresAt
        }
      }
    }
    invites {
      invitationId
      email
      role
      expired
      expiresAt
    }
    createdAt
    updatedAt
  }
`;
