import * as React from 'react';
import { IconProps } from './types';

export const IntercomIcon = ({
  width = '228px',
  height = '228px',
  color = 'currentColor',
  style,
}: IconProps): JSX.Element => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={width}
    height={height}
    color={color}
    style={style}
    viewBox="0 0 228 228"
    xmlSpace="preserve"
  >
    <style type="text/css">.st0fill-rule:evenodd;clip-rule:evenodd;fill:#292839;</style>
    <g transform="translate(-35 -126)">
      <g>
        <path
          className="st0"
          d="M177.6,264.4c-19.2,10.3-37.9,10.3-57.2,0c-1.5-0.8-3.3-0.2-4.1,1.2c-0.8,1.5-0.2,3.3,1.2,4.1
        c10.4,5.6,20.9,8.3,31.4,8.3s21-2.8,31.4-8.3c1.5-0.8,2-2.6,1.2-4.1C180.9,264.1,179.1,263.6,177.6,264.4z"
        />
        <path
          className="st0"
          d="M149,126c-63,0-114,51-114,114s51,114,114,114s114-51,114-114S212,126,149,126z M200.1,305.5l-32-14.5H112
        c-7.7,0-14-6.3-14-14v-74c0-7.7,6.3-14,14-14h74c7.7,0,14,6.3,14,14v50.4l0.1-0.2V305.5z"
        />
      </g>
    </g>
  </svg>
);
