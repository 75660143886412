import React from 'react';
import { Button, HStack, SideDrawer } from 'app/common/components';

import { OBAcctForm } from './OBAcctForm';
import { useOBAcctFormHandler, OBAcctFormHandlerProvider } from './OBAcctFormHandler';

const OBAFormDrawer = () => {
  const handler = useOBAcctFormHandler();
  const { onSubmitOBAcctForm, submitting, form, modal, isEdit } = handler;
  const { modalState, hide } = modal;

  const hideModal = () => {
    hide();
    form.resetForm();
  };

  return (
    <OBAcctFormHandlerProvider value={handler}>
      <SideDrawer
        show={modalState.show}
        onClose={hideModal}
        title="Add an outside brokerage account"
        footerContent={({ onClose: promptClose }) => (
          <HStack spacing={16} justifyContent="flex-end">
            <Button className="tertiary" onClick={promptClose}>
              Dismiss
            </Button>
            <Button disabled={submitting || !form.isValid} onClick={onSubmitOBAcctForm}>
              {isEdit ? 'Save' : 'Submit'}
            </Button>
          </HStack>
        )}
        closeWhenClickOutside={false}
        promptOnClose={form.dirty}
      >
        <OBAcctForm />
      </SideDrawer>
    </OBAcctFormHandlerProvider>
  );
};

export default OBAFormDrawer;
