export const DRLabels = {
  DATA_ROOM_ACCESS: 'Does this transaction have a Data Room that you have access to?',
  FINALIS_VDR: "Do you use Finalis' VDR?",
  DATA_ROOM_INVITATION: 'Please send a Data Room invitation to compliance@finalis.com',
  OBSERVATIONS: 'Observations',
  DATA_ROOM_LINK: 'Link to the third-party data room',
  INVITATION_SEND: 'Invitations to third-party data rooms must be sent to compliance@finalis.com',
  SEND_LINK:
    'Please share the link to your current Data Room or send an invitation to compliance@finalis.com',
};
