import { DEAL_REGULATION_WORKFLOW_CONFIG } from './DealRegulationWorkflowConfig';
import { FormActionFn } from './DealRegulationWorkflowTypes';
import { customRulesExecutor, getStatus, segregatedForms } from './DealRegulationWorkflowHelpers';
import { IForm } from '../../../types/formsTypes';

const populateWorkflow = (forms: IForm[]) => {
  // Populate workflow with forms
  const populatedWorkflow = DEAL_REGULATION_WORKFLOW_CONFIG.map(config => {
    // Get all forms by the specified identifier
    const filteredForms = segregatedForms(forms, config.identifier);
    // Assing the forms found to the configuration metadata
    config.metadata.forms = filteredForms || [];
    // Compute submissions
    config.metadata.componentProps.submissions = config.metadata.forms.length;
    return config;
  });
  return populatedWorkflow;
};

export const getDealRegulation = (options: {
  viewDetailsAction: FormActionFn;
  completeAction: FormActionFn;
}) => (forms: IForm[]) => {
  // Populate workflow with forms
  const populatedWorkflow = populateWorkflow(forms);
  return populatedWorkflow.map(config => {
    // Infer status
    config.metadata.componentProps.stepStatus = getStatus(config, populatedWorkflow);

    // Execute Custom rules
    config.metadata.componentProps.customRules = customRulesExecutor(
      config.customRules || {},
      config,
      populatedWorkflow
    );

    // Set complete Action
    config.metadata.componentProps.completeAction = options.completeAction(config);

    // Set view Action
    config.metadata.componentProps.viewDetailsAction = options.viewDetailsAction(config);

    // Return deal regulation
    return config;
  });
};

export const getDealRegulationProps = (options: {
  viewDetailsAction: FormActionFn;
  completeAction: FormActionFn;
}) => (forms: IForm[]) => {
  const workflow = getDealRegulation(options)(forms);
  return workflow.map(wf => wf.metadata.componentProps);
};
