export const DATA_CHANGE_REQUEST_TYPES = {
  METADATA: 'METADATA',
};

export const DATA_CHANGE_REQUEST_IDENTIFIERS = {
  [DATA_CHANGE_REQUEST_TYPES.METADATA]: {
    BANK_ACCOUNT_INFORMATION: 'BANK_ACCOUNT_INFORMATION',
    STATE_REGISTRATION: 'STATE_REGISTRATION',
    DEAL_UPDATE: 'DEAL_UPDATE',
  },
};
