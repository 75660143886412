import get from 'lodash/get';

// types
import { FormType } from '../../../../../types/graphql-types';
import { OBAcctLabels } from './OBAcctFormConstants';

// helpers
import { PositionMap } from '../../../../utils/helpers/formDataMappers';

// handler
import { OBAcctForm } from './OBAcctFormHandler';

export function buildOBAcctForm({
  accountOwner,
  accountNumber,
  financialInstitutionName,
  accountType,
  files,
  noteComment,
}: OBAcctForm) {
  return {
    type: FormType.OBAcct,
    content: [
      {
        question: OBAcctLabels.ACC_OWNER,
        input: accountOwner.trim(),
        key: 'accountOwner',
        value: JSON.stringify(accountOwner.trim()),
      },
      {
        question: OBAcctLabels.ACC_NUMBER,
        input: accountNumber.trim(),
        key: 'accountNumber',
        value: JSON.stringify(accountNumber.trim()),
      },
      {
        question: OBAcctLabels.INSTITUTION_NAME,
        input: financialInstitutionName.trim(),
        key: 'financialInstitutionName',
        value: JSON.stringify(financialInstitutionName.trim()),
      },
      {
        question: OBAcctLabels.ACC_TYPE,
        input: get(accountType, 'value', ''),
        key: 'accountType',
        value: JSON.stringify(accountType),
      },
    ],
    files,
    note: noteComment,
  };
}

export const OBACCT_VALUE_INFERENCE_MAP: PositionMap = {
  accountOwner: { match: OBAcctLabels.ACC_OWNER },
  accountNumber: { match: OBAcctLabels.ACC_NUMBER },
  financialInstitutionName: { match: OBAcctLabels.INSTITUTION_NAME },
  accountType: {
    match: OBAcctLabels.ACC_TYPE,
    value: ({ input }) => ({ label: input, value: input }),
  },
};
