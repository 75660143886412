import React, { useContext } from 'react';
import { Button, HStack } from '../../../../../common/components';
import { AlertContext } from '../../../../../notifications/context/Alert/Alert';
import useStateModal from '../../../../../utils/hooks/useStateModal';
import { FormsContext } from '../../../../../compliance/context/FormsContext';
import { STEPS } from './KYCFormConstants';
import { KYCHandlerContext } from './KYCFormHandler';

interface KYCFormFooterProps {
  onClose: () => void;
  currentStep: string;
  hideDrawer: () => void;
}

const KYCFormFooter = ({ onClose, currentStep, hideDrawer }: KYCFormFooterProps) => {
  const { globalShow } = useStateModal();
  const { showSuccessToast, showErrorToast } = useContext(AlertContext);
  const { counterpartyHandler, clientHandler } = useContext(KYCHandlerContext);
  const {
    dealRegulation: { query },
  } = useContext(FormsContext);
  const {
    form: counterpartyForm,
    onSubmitKYCCounterpartyForm,
    creatingForm: creatingCounterpartyForm,
  } = counterpartyHandler;
  const {
    form: clientForm,
    onSubmitKYCClientForm,
    creatingForm: creatingClientForm,
  } = clientHandler;

  const handleSubmit = async (): Promise<void> => {
    try {
      currentStep === STEPS.CLIENT
        ? await onSubmitKYCClientForm()
        : await onSubmitKYCCounterpartyForm();
    } catch (error) {
      showErrorToast({ title: 'Error', description: 'An error occurred while saving the form' });
      return;
    }

    showSuccessToast({
      title: `
      ${currentStep === STEPS.CLIENT ? 'Client' : 'Counterparty'} KYC/AML submitted succesfully.`,
    });
    hideDrawer();
    setTimeout(() => {
      query.refetch();
      globalShow('successfullKYCSubmitModal');
    }, 2000);
  };

  return (
    <HStack justifyContent="flex-end" spacing={16}>
      <Button onClick={onClose} className="tertiary">
        Dismiss
      </Button>
      {currentStep !== STEPS.OPTION_SELECTOR && (
        <Button
          onClick={handleSubmit}
          disabled={
            currentStep === STEPS.CLIENT
              ? !clientForm.isValid || creatingClientForm
              : !counterpartyForm.isValid || creatingCounterpartyForm
          }
          style={{ width: 176 }}
        >
          Submit
        </Button>
      )}
    </HStack>
  );
};

export default KYCFormFooter;
