import React, { useContext, useEffect, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import useStateModal from 'app/utils/hooks/useStateModal';

// mutations
import { CREATE_FORM, CREATE_NOTE } from 'api/graphql/compliance/mutations';

// context
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { DealsContext } from 'app/compliance/context/DealsContext';
import { FormsContext } from 'app/compliance/context/FormsContext';

import { FormikStub, useForm } from 'app/utils/hooks/useForm';

// validation schema
import { validationSchema } from './ClosingDocumentsFormValidation';
import { buildCDOCForm, CDOC_VALUE_INFERENCE_MAP } from './ClosingDocumentsFormHelpers';
import { mapFormData } from 'app/utils/helpers/formDataMappers';

export type ClosingDocumentsForm = {
  files: File[];
  conflicts: boolean | null;
  conflictDescription: string;
  closingDate: string;
  dealSize: string;
  note: string;
};

export interface UseClosingDocumentsFormHandler {
  form: FormikStub<ClosingDocumentsForm>;
  onSubmitClosingDocumentsForm: () => Promise<void>;
  modal: ReturnType<typeof useStateModal>;
  submitting: boolean;
}

export const ClosingDocumentsHandlerContext = React.createContext<UseClosingDocumentsFormHandler>(
  {} as UseClosingDocumentsFormHandler
);
export const ClosingDocumentsHandlerProvider = ClosingDocumentsHandlerContext.Provider;

const initialValues = {
  files: [],
  conflicts: null,
  conflictDescription: '',
  closingDate: '',
  dealSize: '',
  note: '',
};

export const useClosingDocumentsFormHandler = (): UseClosingDocumentsFormHandler => {
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);
  const { selectedDeal: dealId } = useContext<any>(DealsContext);
  const { refetchAll, getFormQuery, updateFormMutation } = useContext(FormsContext);
  const modal = useStateModal('closingDocumentsForm');
  const {
    hide,
    modalState: { show, props },
  } = modal;

  const [
    getForm,
    { data: formData, loading: formDataLoading, refetch: getFormRefetch },
  ] = getFormQuery;
  const [updateForm, { loading: updatingForm }] = updateFormMutation;

  const [createNote, { loading: isNoteSubmitting }] = useMutation(CREATE_NOTE);

  const [createFormMutation, { loading: loadingForm }] = useMutation(CREATE_FORM, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      showSuccessToast({ title: 'Success!', description: 'Form was created successfully' });
      onSuccess();
    },
    onError() {
      showErrorToast({ title: 'Error', description: 'An error occurred while saving the form' });
    },
  });

  const onSuccess = () => {
    hide();
    form.resetForm();
    refetchAll(['complianceTracker', 'dealRegulation']);
    getFormRefetch && getFormRefetch();
  };

  const isEdit = props && props.isEdit;
  const formId = props && props.formId;

  const form = useForm<ClosingDocumentsForm>({
    initialValues,
    validationSchema,
  });

  const getFormData = useCallback(async () => {
    if (formId) {
      await getForm({ variables: { id: formId } });
    }
  }, [formId]);

  useEffect(() => {
    if (show && isEdit) {
      getFormData();
    }
  }, [show]);

  useEffect(() => {
    if (formData && formData.form) {
      const mappedValues = mapFormData<ClosingDocumentsForm>(
        formData.form.content,
        initialValues,
        CDOC_VALUE_INFERENCE_MAP,
        {
          conflicts: value => {
            const conflicts = (value && value.conflicts) || false;
            const conflictDescription = (value && value.conflictDescription) || '';
            return {
              conflicts,
              conflictDescription,
            };
          },
        }
      );
      form.setValues(mappedValues);
    }
  }, [formData]);

  const submitting = loadingForm || formDataLoading || updatingForm || isNoteSubmitting;

  const onSubmitClosingDocumentsForm = async (): Promise<void> => {
    if (isEdit) {
      const { note, files } = form.values;
      try {
        await updateForm({
          variables: {
            id: formId,
            content: buildCDOCForm(form, dealId).content,
          },
        });
        if (note || (files && files.length)) {
          await createNote({
            variables: {
              input: {
                type: 'NOTE',
                content: note,
                entity: 'FORM',
                entityId: formId,
                attachments: files,
                private: false,
              },
            },
          });
        }
        showSuccessToast({ title: 'Success!', description: 'Form was updated successfully' });
        onSuccess();
      } catch (e) {
        showErrorToast({
          title: 'Error',
          description: 'An error occurred while updating the form',
        });
      }
    } else {
      await createFormMutation({
        variables: buildCDOCForm(form, dealId),
      });
    }
  };

  return {
    form,
    onSubmitClosingDocumentsForm,
    modal,
    submitting,
  };
};
