// vendors
import React from 'react';

// poki
import { FormGroup } from '@dealsyte/poki';

// components
import {
  FormGroupWrapper,
  RadioButton,
  RadioInput,
  RadioLabel,
  RadioRow,
} from './StyledFormYesNoQuestion';

enum OptionValues {
  NO = 'NO',
  YES = 'YES',
}

interface IProps {
  checked: boolean;
  touched?: boolean;
  required?: boolean;
  id: string;
  label: React.ReactNode;
  onChange: (checked: boolean) => void;
  onTouch?: (touched: boolean) => void;
}

const FormYesNoQuestion: React.FunctionComponent<IProps> = (props: IProps) => {
  const { checked, touched = true, required, id, label, onChange, onTouch } = props;

  const handleSelectionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const isChecked = event.target.value === OptionValues.YES;
    onChange(isChecked);
    onTouch && onTouch(true);
  };

  const yesLabel = `${id}_yes`;
  const noLabel = `${id}_no`;

  return (
    <FormGroupWrapper>
      <FormGroup
        id={id}
        label={label}
        required={required}
        input={
          <RadioRow>
            <RadioButton>
              <RadioInput
                id={yesLabel}
                type="radio"
                value={OptionValues.YES}
                checked={checked && touched}
                onChange={handleSelectionChange}
              />
              <RadioLabel htmlFor={yesLabel}>Yes</RadioLabel>
            </RadioButton>
            <RadioButton>
              <RadioInput
                id={noLabel}
                type="radio"
                value={OptionValues.NO}
                checked={!checked && touched}
                onChange={handleSelectionChange}
              />
              <RadioLabel htmlFor={noLabel}>No</RadioLabel>
            </RadioButton>
          </RadioRow>
        }
      />
    </FormGroupWrapper>
  );
};

export default FormYesNoQuestion;
