import styled, { DefaultTheme } from 'styled-components';
import { darken } from 'polished';

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AppHeaderNotificationContainer = styled.span`
  display: inline-block;
  margin-right: 15px;
`;

export const BellContainer = styled.div<{ count: React.ReactNode }>`
  position: relative;
  display: inline-block;
  line-height: 0;
`;

export const NotificationCount = styled.div`
  border: 1px solid white;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: ${darken(0.05, '#fd6213')};
  color: white;
  padding: 7px;
  position: absolute;
  right: -4px;
  bottom: -4px;
  transform: translate(50%, 33%);
  display: flex;
  align-items: baseline;
  justify-content: center;

  font-size: ${({ theme }) => theme.fontSizes.tiny};
`;

export const TriggerContainer = styled.div`
  ${({ theme }: { theme: DefaultTheme }) => {
    const { colors, radii, spacing, typography } = theme.foundations;
    return `
      cursor: pointer;
      padding: ${spacing['02']} ${spacing['03']};
      margin-left: -5px;
      border-radius: ${radii.md};
      user-select: none;
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['03']};
      font-weight: ${typography.weights.regular};
      color: ${colors.text.default};

      &:focus {
        background-color: ${colors.palette.neutral['200']};
      }

      &:hover {
        background-color: ${({ theme }) => theme.palette.neutral['200']};
      }`;
  }}
`;

export const ContainerOption = styled.div`
  ${({ theme }: { theme: DefaultTheme }) => {
    const { colors, spacing, typography } = theme.foundations;
    return `
    display: flex;
    align-content: center;
    gap: ${spacing['02']};
    padding-top:${spacing['02']};
    padding-bottom:${spacing['02']};
    p{
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['03']};
      font-weight: ${typography.weights.regular};
      color: ${colors.palette.neutral['600']};
    }
    `;
  }}
`;

export const EmailOption = styled.div`
  ${({ theme }: { theme: DefaultTheme }) => {
    const { colors, typography, sizes, spacing } = theme.foundations;
    return `
    padding-top:${spacing['02']};
    padding-bottom:${spacing['02']};
    font-family: ${typography.fonts.body};
    font-size: ${typography.sizes['02']};
    font-weight: ${typography.weights.regular};
    color: ${colors.text.light};
    line-height: ${sizes['04']};
  `;
  }}
`;
