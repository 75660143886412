import _get from 'lodash/get';
import { getDateTimePickerFormattedDate } from 'app/navigation/GlobalSidebar/components/SettingsModal/components/MarketplaceSettings/HelpersMarketplaceSettings';

import { DEAL_STATUS_OPTIONS, DEAL_TYPES_OPTIONS } from 'app/utils/helpers/deal';
import { formatDateInput } from 'app/utils/helpers/helpers';

// Constants
import {
  DEAL_INDUSTRY_TYPES_OPTIONS,
  getIndustryTypeSubOptions,
} from 'app/utils/helpers/dealIndustryTypes';
import { COUNTRY_OPTIONS, getCountrySubOptions } from 'app/utils/constants/dealFormOptions';
import { MarketplaceDealType } from 'app/marketplace/types';

export const getStyles = (errors: any, fieldName: string): {} | undefined => {
  if (_get(errors, fieldName)) {
    return {
      borderColor: 'red !important',
      border: '1px solid red',
    };
  }
  return undefined;
};

const dealStatusToSelect = DEAL_STATUS_OPTIONS;

const getDealStatusOption = (statusValue: string) => {
  return dealStatusToSelect.filter(x => x.value === statusValue);
};

const getTransactionTypeOption = (transactionTypeValue: string) => {
  return DEAL_TYPES_OPTIONS.filter(x => x.value === transactionTypeValue);
};

const getIndustryTypeOption = (industryTypeValue: string) => {
  return DEAL_INDUSTRY_TYPES_OPTIONS.filter(x => x.value === industryTypeValue);
};

const getSubIndustryTypeOption = (industryTypeValue: string, subIndustryTypeValue: string) => {
  const subIndustryValues = getIndustryTypeSubOptions(industryTypeValue);
  if (!subIndustryTypeValue) return [];
  return subIndustryValues.filter(x => x.value === subIndustryTypeValue[0]);
};

const getCountry = (country: string) =>
  COUNTRY_OPTIONS.find((value: any) => value.value === country);

const getState = (country: string, state: string) => {
  const states = getCountrySubOptions(country);
  return states.find((value: any) => value.value === state);
};

const isRetainer = (retainerAmount?: number) => {
  if (typeof retainerAmount === 'number') {
    if (retainerAmount > 0) {
      return true;
    }
    return false;
  }
  return null;
};

export const COUNTRIES_WITH_STATES = ['USA'];

export const buildInitialValues = (dealSelected?: any) => {
  if (!dealSelected) {
    return {
      dealName: '',
      country: null,
      state: null,
      transactionType: null,
      marketplaceDealType: MarketplaceDealType.NonCollaborative,
      dealStage: null,
      primaryIndustry: null,
      secondaryIndustry: null,
      isRetainer: null,
      retainerAmount: null,
      expectedSize: null,
      description: null,
      minGrossFeeEarned: 0,
      maxGrossFeeEarned: 0,
      engagementLetterSignedDate: null,
      tailPeriodDuration: null,
    };
  }

  return {
    dealName: dealSelected.name || '',
    country: getCountry(dealSelected.country),
    state: getState(dealSelected.country, dealSelected.state),
    transactionType: getTransactionTypeOption(dealSelected.type)[0],
    marketplaceDealType: dealSelected.marketplaceDealType,
    dealStage: getDealStatusOption(dealSelected.status)[0],
    primaryIndustry: getIndustryTypeOption(dealSelected.primaryIndustry)[0],
    secondaryIndustry: getSubIndustryTypeOption(
      dealSelected.primaryIndustry,
      dealSelected.secondaryIndustries
    )[0],
    isRetainer: isRetainer(dealSelected.retainerAmount),
    retainerAmount: dealSelected.retainerAmount,
    expectedSize: dealSelected.expectedSize,
    description: dealSelected.description,
    minGrossFeeEarned: dealSelected.calculatedPercentRate
      ? dealSelected.calculatedPercentRate.min
      : null,
    maxGrossFeeEarned: dealSelected.calculatedPercentRate
      ? dealSelected.calculatedPercentRate.max
      : null,
    engagementLetterSignedDate: getDateTimePickerFormattedDate(
      dealSelected.engagementLetterSignedDate
    ),
    tailPeriodDuration: dealSelected.tailPeriodDuration,
  };
};

export const buildPayload = (values: any): any => {
  return {
    name: values.dealName,
    type: values.transactionType.value,
    status: values.dealStage.value,
    country: values.country.value,
    state: values.state ? values.state.value : null,
    marketplaceDealType: values.marketplaceDealType,
    primaryIndustry: values.primaryIndustry.value,
    secondaryIndustries: values.secondaryIndustry ? [values.secondaryIndustry.value] : [],
    retainerAmount:
      values.retainerAmount !== null ? Number.parseFloat(values.retainerAmount) : null,
    expectedSize: values.expectedSize ? Number.parseFloat(values.expectedSize) : null,
    description: values.description,
    calculatedPercentRate: {
      max: Number.parseFloat(values.maxGrossFeeEarned),
      min: Number.parseFloat(values.minGrossFeeEarned),
    },
    engagementLetterSignedDate: values.engagementLetterSignedDate
      ? formatDateInput(values.engagementLetterSignedDate)
      : null,
    tailPeriodDuration: values.tailPeriodDuration,
  };
};
