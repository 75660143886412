import React from 'react';
import { SideDrawerBase } from '..';
import { SideDrawerProps } from './TypesSideDrawer';
import { CloseIcon } from '../../icons';
import * as S from './StyledSideDrawer';

export const SideDrawer = ({
  title,
  footerContent,
  onClose,
  children,
  ...props
}: SideDrawerProps) => {
  return (
    <SideDrawerBase {...props} onClose={onClose}>
      {({ closeWithPrompt }) => (
        <>
          <S.SideDrawerHeader>
            <S.SideDrawerTitle>{title}</S.SideDrawerTitle>
            <S.SideDrawerCloseButton onClick={closeWithPrompt}>
              <CloseIcon />
            </S.SideDrawerCloseButton>
          </S.SideDrawerHeader>
          <S.SideDrawerContent>{children}</S.SideDrawerContent>
          <S.SideDrawerFooter>
            {typeof footerContent === 'function'
              ? footerContent({ onClose: closeWithPrompt })
              : footerContent}
          </S.SideDrawerFooter>
        </>
      )}
    </SideDrawerBase>
  );
};
