import React, { ReactNode } from 'react';
import _get from 'lodash/get';

import { Pill } from 'app/common/components';
import { EFormStatus, IForm, EFormType } from '../../types/formsTypes';

export function getFormStatusPill(status: EFormStatus): ReactNode {
  switch (status) {
    case EFormStatus.APPROVED:
      return <Pill label="APPROVED" className="green" />;
    case EFormStatus.APPROVED_WITH_CONDITIONS:
      return <Pill label="APPROVED WITH CONDITIONS" className="teal" />;

    case EFormStatus.REQUEST_FOR_INFORMATION:
      return <Pill label="REQUEST FOR INFORMATION" className="orange" />;

    case EFormStatus.REJECTED:
      return <Pill label="DISAPPROVED" className="red" />;

    case EFormStatus.CLOSED_DUPLICATED:
      return <Pill label="DUPLICATED" className="purple" />;

    case EFormStatus.CLOSED:
      return <Pill label="CLOSED" className="purple" />;

    case EFormStatus.IN_REVIEW:
    case EFormStatus.PASS_TO_LEGAL_TEAM:
    case EFormStatus.REVIEWED_BY_LEGAL:
      return <Pill label="PENDING APPROVAL" className="lightblue" />;

    case EFormStatus.REVIEWED_BY_CO:
      return <Pill label="PROCESSED BY COMPLIANCE OPS" className="green" />;
    default:
      return null;
  }
}

/**
 * Renders the `form`'s title.
 * @param form the form to be rendered.
 * @returns the `form`'s title.
 */
export function getFormTitle(form: IForm) {
  switch (_get(form, 'type', '')) {
    case EFormType.OBA:
      return 'Outside business activities';

    case EFormType.OBAcct:
      return 'Outside brokerage accounts';

    case EFormType.PST:
      return 'Private securities transactions';

    case EFormType.MMAT:
    case EFormType.MMR:
      return 'Marketing materials';

    case EFormType.AOQ:
      return 'Agreement onboarding questionnaire';

    case EFormType.SE:
      return 'Speaking engagement';

    case EFormType.DCR:
      return 'Change request';

    case EFormType.NDR:
      return 'New deal request';

    case EFormType.EL:
      return 'Engagement letter';

    case EFormType.DRA:
      return 'Data room set up';

    case EFormType.KYC:
      return 'KYC/AML';

    case EFormType.KYCcl:
      return 'Client KYC / AML';

    case EFormType.KYCco:
      return 'Counterparty KYC / AML';

    case EFormType.CDOC:
      return 'Closing documents';

    case EFormType.INV:
      return 'Invoice(s)';

    case EFormType.U4:
      return 'U4';

    case EFormType.AMNel:
      return 'Client engagement - Amendment';

    case EFormType.FSAext:
      return 'FSA - External';

    case EFormType.FSAint:
      return 'FSA - Internal';

    default:
      return '-';
  }
}

export function getReportType(type: EFormType) {
  switch (type) {
    case EFormType.OBAcct:
      return 'OBA (Accounts)';

    case EFormType.MMAT:
      return 'Marketing material';

    case EFormType.OBA:
      return 'OBA (Activities)';

    default:
      return type;
  }
}
