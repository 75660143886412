import {
  ESortingOrders,
  DealSide as EDealSide,
  IUser,
  Role as ERole,
} from 'app/core-tools/due-diligence/types/types';

export type IPath = {
  file: any;
  uuid: string;
  path: string;
};

export type IUploadTreeChildren = {
  name: string;
  children: IUploadTreeChildren[] | null;
  correlation: string;
  type: string;
};

export enum ActivePublishUnpublishTypes {
  ALL = 'all',
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
}

export type ChildrenFso = {
  name: string;
  type: string;
  correlation: string;
  children: [ChildrenFso];
};
export interface JWT extends IUser {
  exp: number;
  iat: number;
  dealId: string;
  originRole: string;
  platformRole: string;
}

export type IAuth = {
  authenticated: string;
  token: string;
  role: ERole;
  side: EDealSide;
  id: string;
};

export type EditPermissionsGroupInfo = {
  name: string;
  id: string;
  allFsoDownloadChecked: boolean;
  permissions: {
    bulkDisabled: boolean;
    displayName: string;
    id: string;
    icon: React.ReactNode;
    allChecked: boolean;
    isFsoChecked: (fsoId: string) => boolean;
    headerOnChange: (event: React.ChangeEvent<HTMLInputElement>, isDownload?: boolean) => void;
    rowOnChange: (fsoId: string, remove: boolean, isDownload?: boolean) => void;
    name: string;
    isDisabled: (fsoId: string) => boolean;
  }[];
}[];

export type FsoPermissions = {
  upload: boolean;
  download_original: boolean;
  download_watermark: boolean;
  rename: boolean;
  move: boolean;
  delete: boolean;
  publish: boolean;
  unpublish: boolean;
};

export enum ConversionStatus {
  CONVERTED = 'converted',
  PENDING = 'pending',
  UNFIT = 'unfit',
  FAILED = 'failed',
}

export type Fso = {
  id: string;
  size: number;
  displaySize: string;
  parentId: string;
  createdAt: string;
  updatedAt: string;
  path: string;
  index: string;
  extension: EFileExtension;
  status: string;
  name: string;
  type: FsoTypes;
  childrenAmount: Number;
  children: Fso[] | [];
  owner?: User;
  group?: Group;
  logs?: Log[];
  trash: boolean;
  permissions: FsoPermissions;
  [key: string]: any;
  conversionStatus: ConversionStatus;
  Signatures: IFsoSignature[];
};

export enum EFileExtension {
  pdf = '.pdf',
  doc = '.doc',
  docx = '.docx',
  xls = '.xls',
  xlsx = '.xlsx',
  ppt = '.ppt',
  pptx = '.pptx',
  jpg = '.jpg',
  jpeg = '.jpeg',
  gif = '.gif',
  tif = '.tif',
  tiff = '.tiff',
  png = '.png',
  key = '.key',
}

export enum ESupportedFileExtension {
  pdf = '.pdf',
  doc = '.doc',
  docx = '.docx',
  xls = '.xls',
  xlsx = '.xlsx',
  ppt = '.ppt',
  pptx = '.pptx',
  jpg = '.jpg',
  jpeg = '.jpeg',
  gif = '.gif',
  tif = '.tif',
  tiff = '.tiff',
  key = '.key',
  png = '.png',
}

export enum EFsoPublishStatus {
  published = 'published',
  unpublished = 'unpublished',
}

export type IFsoSignature = {
  id: string;
  requesterGroupId: string;
  signerGroupId: string;
  status: EFsoSignatureStatus;
  url?: string;
};

export enum EFsoSignatureStatus {
  pending = 'pending',
  signed = 'signed',
}

export type FsoToUpload = {
  fso: any;
  file: any;
  path: string;
  size: string;
  status: string;
  name: string;
  uuid: string;
  id: string;
  error: boolean | null;
};

export enum ETransactionType {
  upload = 'upload',
  download = 'download',
  watermarkDownload = 'download_watermark',
  originalDownload = 'download_original',
  dndUpload = 'dnd_upload',
  inputUpload = 'input_upload',
  attachment = 'attachment_message',
}

export type FsoTransaction = {
  id: string;
  status: string;
  index?: number;
  files: {
    pending: FsoToUpload[];
    complete: FsoToUpload[];
  };
  error: any;
};

export type IProgress = {
  [key: string]: number;
};

export enum FsoTypes {
  file = 'file',
  folder = 'folder',
  fileRoom = 'room',
}

export type Group = {
  id: string;
  userIds: [string];
  users: [User];
  organizationIds: [string];
  organizations: [Organization];
};

export type Log = {
  id: string;
  type: LogType;
  time: string;
  fileId: string;
  file: Fso;
  userId: string;
  user: User;
};

export enum LogType {
  download = 'download',
  upload = 'upload',
  edit = 'edit',
  changeVisibility = 'changeVisibility',
}

export type Organization = {
  id: string;
  name: string;
};

export type Firm = {
  id: string;
  name: string;
  logo: string;
  website: string;
  status: string;
};

export enum SortingCriterias {
  index = 'index',
  name = 'name',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  access = 'access',
  linked = 'linked',
}

export type SortingState = {
  activated: boolean;
  activeType: SortingCriterias;
  order: ESortingOrders;
};

export enum ETransactionActions {
  done = 'done',
  cancel = 'cancel',
}

export enum ETransactionStatus {
  pending = 'pending',
  canceled = 'canceled',
  finished = 'finished',
  completed = 'completed',
  success = 'success',
  error = 'error',
  inTransfer = 'inTransfer',
}

export enum TransactionsDialogTypes {
  toast = 'toast',
  dialog = 'dialog',
}

export type ITransactionProgress = {
  [key: string]: number;
};

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  organization?: Organization;
};

export enum UserContextRole {
  user = 'user',
  admin = 'admin',
}

export enum UserContextSide {
  buy = 'buy',
  sell = 'sell',
}
