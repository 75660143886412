import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
`;

export const StyledCheckboxButton = styled.div`
  ${({ theme }) => {
    const { colors, sizes } = theme.foundations;
    return `
      display: inline-flex;
      width: ${sizes['05']};
      height: ${sizes['05']};
      border-width: 2px;
      border-style: solid;
      border-color: ${colors.palette.neutral['600']};
      border-radius: ${sizes['01']};
      background: transparent;
      transition: all 150ms ease;
      align-items: center;
      justify-content: center;
      &:hover {
        border-width: 1px;
      }
      svg {
        height: 11px;
        width: auto;
        opacity: 0;
        margin-top: 1px;
        color: ${colors.text.buttons};
        visibility: hidden;
        transition: all 150ms ease;
      }
  `;
  }}
`;

export const CheckboxLabel = styled.label`
  ${({ theme }) => {
    const { colors, spacing, typography } = theme.foundations;
    return `
      margin-left: ${spacing['02']};
      color: ${colors.palette.neutral['900']};
      font-size: ${typography.sizes['04']};
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      letter-spacing: 0.5px;
    `;
  }}
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  ${({ theme }) => {
    const { colors } = theme.foundations;
    return `
      cursor: pointer;
      position: absolute;
      opacity: 0;
      top: -4px;
      left: -4px;
      width: 22px;
      height: 22px;

      &:checked ~ ${StyledCheckboxButton} {
        background-color: ${colors.palette.neutral['600']};
        border: 0;
        svg {
          opacity: 1;
          visibility: visible;
        }
      }
      &:focus ~ ${StyledCheckboxButton} {
        box-shadow: 0px 0px 0px 4px ${colors.palette.neutral['300']};
      }
      &:disabled ~ ${StyledCheckboxButton} {
        background-color: ${colors.palette.neutral['200']};
        border-color: ${colors.palette.neutral['300']};
        &:hover {
          border-width: 2px;
        }
      }
      &:disabled ~ ${CheckboxLabel} {
        color: ${colors.text.light};
      }
    `;
  }}
`;
