import styled from 'styled-components';
import { Input as PokiInput } from '@dealsyte/poki';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 56px 0;
  overflow-y: scroll;
  height: 550px;
`;

export const ContentContainer = styled.div`
  padding: 0px 70px;
  line-height: 1.3;
  text-align: left;
  color: ${({ theme }) => theme.palette.gray.darker};

  label {
    font-size: ${({ theme }) => theme.fontSizes.small};
    color: #707f8f;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;

export const DealInformationContainer = styled.div`
  padding-top: 32px;
`;

export const Subtitle = styled.p`
  margin: 20px 0 15px 0;
  font-weight: ${({ theme }) => theme.fontWeights.bolder};
  font-size: ${({ theme }) => theme.fontSizes.regular};
  color: #424d57;
  line-height: 24px;

  &:first-child {
    margin-top: 0;
  }
`;

export const Text = styled.p`
  max-width: 400px;
  color: #6f8091;
  margin-bottom: 20px;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const ErrorMessageText = styled.p`
  max-width: 400px;
  margin: 0 0 35px 70px;
  color: ${({ theme }) => theme.palette.red.default};
`;

export const Input = styled(PokiInput)`
  width: 75%;
  height: 41px;
  background-color: transparent;
  border-radius: 2px;
  padding-left: 16.5px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: #707f8f;
`;

export const SubmitButtonsBotomFixedContainer = styled.div`
  display: flex;
  flex-direction: 'column';
  align-items: center;
  width: 100%;
  padding-right: 64px;
  box-shadow: 5px 4px 8px 4px rgba(0, 0, 0, 0.1);
  height: 120px;
`;

export const SubmitButtonContainer = styled.div`
  flex: 1;
  justify-content: flex-end;
  display: flex;
`;

export const ErrorLabelContainer = styled.div`
  align-items: center;
  justify-content: center;
`;

export const selectDealCustomStyles = {
  //@ts-ignore
  control: base => ({
    ...base,
    height: '41px',
    backgroundColor: 'white',
    minHeight: '41px',
    border: '1px solid #eeeeee',
    color: '#707F8F',
    fontSize: '12px',
  }),
  //@ts-ignore
  container: base => ({
    ...base,
    display: 'inline-block',
    width: '75%',
    minHeight: '41px',
    height: '41px',
    borderColor: '',
    color: '#707F8F',
    fontSize: '12px',
  }),
  //@ts-ignore
  valueContainer: base => ({
    ...base,
    minHeight: '30px',
    color: '#707F8F',
    fontSize: '12px',
    paddingTop: '17px',
    paddingLeft: '16.5px',
  }),
  //@ts-ignore
  dropdownIndicator: base => ({
    ...base,
    padding: '0 12px',
    border: 0,
  }),
  //@ts-ignore
  indicatorSeparator: base => ({
    ...base,
    width: 0,
  }),
  //@ts-ignore
  singleValue: base => ({
    ...base,
    color: '#707F8F',
  }),
};
