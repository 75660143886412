import React, { useContext } from 'react';
import get from 'lodash/get';

// Components
import {
  Alert,
  FormGroup,
  Input,
  Select,
  SingleOptionRadioButtons,
} from '../../../../../../common/components';

// Constants
import {
  CONNECTION_OPTIONS,
  KYCCounterpartiesLabels,
  TypesCounterpartyOptions,
} from '../KYCFormConstants';

// Handler
import { KYCHandlerContext } from '../KYCFormHandler';
import { KYCCounterpartyForm } from './KYCCounterpartyHandler';
import { formControlChangeHandlers } from '../../../../../../compliance/components/FormControlChangeHandlers/FormControlChangeHandlers';

// Style
import * as S from '../KYCFormStyled';

const KYCCounterparties = () => {
  const { counterpartyHandler } = useContext(KYCHandlerContext);
  const { form, isCapitalRaiseDeal } = counterpartyHandler;
  const { values, setFieldValue, errors, touched } = form;
  const { handleYesNoChange, handleFieldChange, handleSelectChange } = formControlChangeHandlers<
    KYCCounterpartyForm
  >(setFieldValue);

  return (
    <>
      <S.Text>{KYCCounterpartiesLabels.SUBTITLE}</S.Text>
      <FormGroup
        label={KYCCounterpartiesLabels.COUNTERPARTY_TYPES}
        error={touched.typesCounterparty && errors.typesCounterparty}
        required
      >
        <SingleOptionRadioButtons
          strictValueOnChange
          options={TypesCounterpartyOptions}
          id="typesCounterparty"
          name="typesCounterparty"
          value={values.typesCounterparty}
          onChange={handleYesNoChange('typesCounterparty')}
        />
      </FormGroup>
      {values.typesCounterparty && (
        <FormGroup
          label={KYCCounterpartiesLabels.ENTITY_NAME}
          error={touched.entityName && errors.entityName}
          required
        >
          <Input
            id="entityName"
            name="entityName"
            placeholder="Enter full legal name"
            value={values.entityName}
            onChange={handleFieldChange('entityName')}
          />
        </FormGroup>
      )}
      {isCapitalRaiseDeal && (
        <FormGroup
          label={KYCCounterpartiesLabels.CONNECTION}
          error={touched.connection && errors.connection}
          required
        >
          <Select
            inputId="connection"
            name="connection"
            value={values.connection}
            options={CONNECTION_OPTIONS}
            onChange={handleSelectChange('connection')}
            placeholder="Select answer"
            isSearchable={false}
          />
        </FormGroup>
      )}
      {isCapitalRaiseDeal && get(values, 'connection.value', '') === 'PRE_EXISTING_RELATIONSHIP' && (
        <>
          <FormGroup
            label={KYCCounterpartiesLabels.YEARS_KNWOLEDGE}
            error={touched.yearsKnowledge && errors.yearsKnowledge}
            required
          >
            <Input
              id="yearsKnowledge"
              name="yearsKnowledge"
              placeholder="Enter number of years"
              value={values.yearsKnowledge}
              onChange={handleFieldChange('yearsKnowledge')}
            />
          </FormGroup>
          <FormGroup
            label={KYCCounterpartiesLabels.RELATIONSHIP_NATURE}
            error={touched.relationshipNature && errors.relationshipNature}
            required
          >
            <Input
              id="relationshipNature"
              name="relationshipNature"
              placeholder="Enter nature of the relationship"
              value={values.relationshipNature}
              onChange={handleFieldChange('relationshipNature')}
            />
          </FormGroup>
        </>
      )}
      {isCapitalRaiseDeal && get(values, 'connection.value', '') === 'OTHER' && (
        <FormGroup
          label={KYCCounterpartiesLabels.CONNECTION_AMPLIFY}
          error={touched.connectionAmplify && errors.connectionAmplify}
          required
        >
          <Input
            id="connectionAmplify"
            name="connectionAmplify"
            placeholder="Enter your answer"
            value={values.connectionAmplify}
            onChange={handleFieldChange('connectionAmplify')}
          />
        </FormGroup>
      )}
      <S.DrawerTitle>Point of contact</S.DrawerTitle>
      <FormGroup
        label={KYCCounterpartiesLabels.FULL_NAME}
        error={touched.fullName && errors.fullName}
        required
      >
        <Input
          id="fullName"
          name="fullName"
          placeholder="Enter full name"
          value={values.fullName}
          onChange={handleFieldChange('fullName')}
        />
      </FormGroup>
      <FormGroup
        label={KYCCounterpartiesLabels.EMAIL_ADDRESS}
        error={touched.emailAddress && errors.emailAddress}
        required
      >
        <Input
          id="emailAddress"
          name="emailAddress"
          placeholder="Enter email address"
          value={values.emailAddress}
          onChange={handleFieldChange('emailAddress')}
        />
      </FormGroup>
      <FormGroup
        label={KYCCounterpartiesLabels.TITLE}
        error={touched.title && errors.title}
        required
      >
        <Input
          id="title"
          name="title"
          placeholder="Enter title"
          value={values.title}
          onChange={handleFieldChange('title')}
        />
      </FormGroup>
      <Alert text={KYCCounterpartiesLabels.ALERT} />
    </>
  );
};

export default KYCCounterparties;
