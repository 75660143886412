import { useContext } from 'react';
import { StylesConfig } from 'react-select';
import get from 'lodash/get';

// styles
import styled, { ThemeContext } from 'styled-components';

export const useDefaultSelectStyles = (isDescriptionSelect: boolean) => {
  const { foundations } = useContext(ThemeContext);
  const { sizes, colors, radii, typography, spacing, shadows } = foundations;

  // Select Styles
  const selectorStyles: StylesConfig = {
    control: (base, state) => ({
      ...base,
      backgroundColor: state.isDisabled ? colors.background.secondary : colors.background.primary,
      minHeight: sizes['09'],
      border: `1px solid ${state.isDisabled ? colors.outline.light : colors.outline.default}`,
      borderRadius: radii.sm,
      fontFamily: typography.fonts.body,
      fontSize: typography.sizes['04'],
      color: state.isDisabled ? colors.text.disabled : colors.text.default,
      padding: spacing['03'],
      outline: 'none',
    }),
    container: base => ({
      ...base,
      display: 'inline-block',
      width: '100%',
      outline: 'none',
    }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none',
    }),
    indicatorsContainer: (base, state) => {
      const multiSelectStyles =
        get(state, 'isMulti') && get(state, 'hasValue')
          ? {
              alignItems: 'flex-start',
              marginTop: '6px',
            }
          : {};
      return {
        ...base,
        ...multiSelectStyles,
      };
    },
    valueContainer: base => ({
      ...base,
      padding: 0,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      padding: 0,
      color: state.isDisabled ? colors.text.disabled : colors.text.default,
    }),
    placeholder: (base, state) => ({
      ...base,
      color: state.isDisabled ? colors.text.disabled : colors.text.light,
    }),
    multiValue: base => ({
      ...base,
      borderRadius: radii.md,
      backgroundColor: colors.palette.neutral['200'],
      '&:hover': {
        background: colors.palette.red['200'],
      },
      padding: `${spacing['01']} ${spacing['02']}`,
    }),
    multiValueRemove: base => ({
      ...base,
      '&:hover': {
        background: 'none',
        color: colors.text.default,
      },
    }),
    option: (base, state) => {
      const justifyStyled = () => {
        if (get(state, 'data.value') === get(state, 'selectProps.value.value')) {
          return 'space-between';
        }
        return 'flex-start';
      };
      const colorStyled = () => {
        if (get(state, 'isFocused')) {
          return colors.primary.dark;
        } else if (get(state, 'data') === get(state, 'selectProps.value')) {
          return colors.text.dark;
        }
        return colors.text.default;
      };
      const backgroundColorStyled = () => {
        if (get(state, 'isFocused')) {
          return colors.background.tertiary;
        } else if (get(state, 'data') === get(state, 'selectProps.value')) {
          return colors.background.secondary;
        }
        return colors.background.primary;
      };
      return {
        ...base,
        display: 'flex',
        justifyContent: justifyStyled(),
        alignItems: 'center',
        padding: spacing['04'],
        borderRadius: radii.md,
        fontFamily: typography.fonts.body,
        fontSize: typography.sizes['04'],
        fontWeight: isDescriptionSelect ? typography.weights.medium : typography.weights.regular,
        color: colorStyled(),
        backgroundColor: backgroundColorStyled(),
        '& .iconContainer': {
          display: 'block',
          height: sizes['06'],
          minWidth: sizes['06'],
          color: colors.primary.light,
          marginLeft: spacing['03'],
        },
        '& .descriptionContainer': {
          display: 'flex',
          flexDirection: 'column',
        },
        '& .description': {
          fontFamily: typography.fonts.body,
          fontSize: typography.sizes['03'],
          fontWeight: typography.weights.regular,
          lineHeight: '21px',
          letterSpacing: '0.25px',
          color: colors.text.default,
          marginTop: spacing['01'],
        },
        '& .checkboxContainer': {
          marginRight: spacing['04'],
        },
      };
    },
    menu: base => ({
      ...base,
      border: 0,
      boxShadow: shadows['01'],
      padding: spacing['02'],
    }),
    menuList: base => ({
      ...base,
      '::-webkit-scrollbar': {
        width: '10px',
      },
      '::-webkit-scrollbar-track': {
        background: '#FEFEFE',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#D8D7EA',
        borderRadius: '20px',
      },
    }),
    clearIndicator: base => ({
      ...base,
      cursor: 'pointer',
    }),
  };
  return selectorStyles;
};

export const CSSWrapper = styled.div`
  ${({ theme }) => {
    const { colors, shadows } = theme.foundations;
    return `
      .fns-select__control {
        &:hover {
          border: 1px solid ${colors.outline.dark};
        }
      }
      .fns-select__control--is-focused {
        box-shadow: ${shadows['01']};
      }
      .fns-select__control--menu-is-open {
        .fns-select__dropdown-indicator {
          transform: rotate(180deg);
        }
      }
    `;
  }}
`;
