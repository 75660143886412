import React, { useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import useStateModal from 'app/utils/hooks/useStateModal';

// mutations
import { CREATE_FORM } from 'api/graphql/compliance/mutations';

// context
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { DealsContext } from 'app/compliance/context/DealsContext';
import { FormsContext } from 'app/compliance/context/FormsContext';

import { validationSchema } from './DataRoomFormValidations';
import { FormikStub, useForm } from 'app/utils/hooks/useForm';
import { mapFormData } from 'app/utils/helpers/formDataMappers';
import { buildDRForm, DRA_VALUE_INFERENCE_MAP } from './DataRoomFormHelpers';

export type DataRoomForm = {
  dataRoomAccess: boolean | undefined;
  finalisVDR: boolean | undefined;
  dataRoomLink: string;
  note: string;
};

export interface UseDataRoomFormHandler {
  form: FormikStub<DataRoomForm>;
  onSubmitDataRoomForm: () => Promise<void>;
  submitting: boolean;
  isEdit: boolean;
}

export const DataRoomHandlerContext = React.createContext<UseDataRoomFormHandler>(
  {} as UseDataRoomFormHandler
);
export const DataRoomHandlerProvider = DataRoomHandlerContext.Provider;

const initialValues = {
  dataRoomAccess: undefined,
  finalisVDR: undefined,
  dataRoomLink: '',
  note: '',
};

export const useDataRoomFormHandler = (): UseDataRoomFormHandler => {
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);
  const { selectedDeal: dealId } = useContext<any>(DealsContext);
  const { refetchAll, updateFormMutation, getFormQuery } = useContext(FormsContext);
  const { hide, modalState } = useStateModal('dataRoomForm');
  const { props, show } = modalState;

  const [getForm, { data: formData, loading: formDataLoading }] = getFormQuery;
  const [updateForm] = updateFormMutation;

  const formCleanup = () => {
    hide();
    refetchAll(['complianceTracker', 'dealRegulation']);
    setTimeout(() => {
      form.resetForm();
    }, 500);
  };

  const [createFormMutation, { loading: loadingForm }] = useMutation(CREATE_FORM, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      showSuccessToast({ title: 'Success!', description: 'Form was created successfully' });
      formCleanup();
    },
    onError() {
      showErrorToast({ title: 'Error', description: 'An error occurred while saving the form' });
    },
  });

  const isEdit = props && props.isEdit;
  const formId = props && props.formId;

  const getFormData = async () => {
    if (formId) {
      await getForm({ variables: { id: formId } });
    }
  };

  const form = useForm<DataRoomForm>({
    initialValues,
    validationSchema,
  });

  useEffect(() => {
    if (show && isEdit) {
      getFormData();
    }
  }, [show]);

  useEffect(() => {
    if (formData && formData.form) {
      const mappedValues = mapFormData<DataRoomForm>(
        formData.form.content,
        initialValues,
        DRA_VALUE_INFERENCE_MAP
      );
      form.setValues(mappedValues);
      form.setTouched({
        dataRoomAccess: true,
        finalisVDR: true,
        dataRoomLink: true,
        note: true,
      });
    }
  }, [formData]);

  const submitting = loadingForm || formDataLoading;

  const onSubmitDataRoomForm = async (): Promise<void> => {
    const { values } = form;
    if (isEdit) {
      try {
        await updateForm({
          variables: {
            id: formId,
            content: buildDRForm(values, dealId).content,
          },
        });
        showSuccessToast({ title: 'Success!', description: 'Form was updated successfully' });
        formCleanup();
      } catch (e) {
        showErrorToast({
          title: 'Error',
          description: 'An error occurred while updating the form',
        });
      }
    } else {
      await createFormMutation({
        variables: buildDRForm(values, dealId),
      });
    }
  };

  return { form, onSubmitDataRoomForm, submitting, isEdit };
};
