/* eslint-disable camelcase */
// Libraries
import React, { useContext, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import _get from 'lodash/get';

// Poki
import { Checkbox, Button, EButtonType, Input, LoadingCircle } from '@dealsyte/poki';

// Context
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { FormsContext } from 'app/compliance/context/FormsContext';
import { UserContext } from 'app/users/context/UserContext';

// Hooks
import { useForm } from 'app/utils/hooks/useForm';
import { useTouchedForm } from 'app/utils/hooks/useTouchedForm';

// Mutations & Query
import { GET_USER_PROFILE_QUERY } from 'api/graphql/users/types/GET_USER_PROFILE_QUERY';
import { GET_USER_PROFILE } from 'api/graphql/users/queries';
import { UPDATE_PROFILE } from 'api/graphql/users/mutations';

// Types
import { EButtonMessages } from 'app/core-tools/due-diligence/types/types';
import {
  UPDATE_PROFILE_MUTATION,
  UPDATE_PROFILE_MUTATIONVariables,
} from 'api/graphql/users/types/UPDATE_PROFILE_MUTATION';
import { ESettingsAccountLabels } from '../../TypedUsersSettingsModal';
import { IProps } from './TypedAccountBankSettings';

// Helpers & Utils
import { getError } from '../../../../../utils/helpers/helpers';
import { accountBankSettingsSchema } from './HelpersAccountBankSettings';

// Styled components
import {
  BodySectionContainer,
  SubmitButtonsBotomFixedContainer,
  Text,
  TextContainer,
  TextDescriptionContainer,
  CheckboxAccountBankingContainer,
  CheckboxAcountBankingLabel,
  InputBankSectionContainer,
  FormAccountBankSection,
  LoaderContainer,
  MessageContainer,
  MessageBodyContainer,
  ParagraphMessageContainer,
  DotOption,
  FooterMessageContainer,
  FooterMessageLabel,
  RequiredMarker,
  ErrorMessage,
} from '../../StyledUsersSettingsModal';

function AccountBankSettings({ bankInfo }: IProps) {
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);
  const { isImpersonated } = useContext(UserContext);
  const { regulatoryProfile } = useContext(FormsContext);
  const { refetch } = regulatoryProfile.query;
  const { setIsTouched } = useTouchedForm();

  const formInitialValues = () => ({
    bankName: _get(bankInfo, 'bankName'),
    bankAccountName: _get(bankInfo, 'bankAccountName'),
    bankAccountNumber: _get(bankInfo, 'bankAccountNumber'),
    abaNumber: _get(bankInfo, 'abaNumber'),
    attention: _get(bankInfo, 'attention'),
    swiftCode: _get(bankInfo, 'swiftCode'),
    furtherBankAccountName: _get(bankInfo, 'furtherBankAccountName'),
    furtherBankAccountNumber: _get(bankInfo, 'furtherBankAccountNumber'),
    acceptBankAccountDetailsInformation: !!_get(bankInfo, 'bankName'),
  });

  const { values, isValid, dirty, errors, touched, setFieldValue, setValues } = useForm({
    initialValues: formInitialValues(),
    validationSchema: accountBankSettingsSchema,
  });

  const changeField = (key: string) => (e: any) => setFieldValue(key, e.target.value);
  const changeCheckBox = (key: string) => (e: any) => setFieldValue(key, e.target.checked);

  useEffect(() => {
    if (bankInfo) {
      setValues(formInitialValues());
    }
  }, [bankInfo]);

  useEffect(() => {
    if (dirty) {
      setIsTouched(true);
    }
  }, [dirty]);

  const [getUserProfile, { updateQuery, loading: profileLoading }] = useLazyQuery<
    GET_USER_PROFILE_QUERY
  >(GET_USER_PROFILE, {
    fetchPolicy: 'no-cache',
    onCompleted({ profile }) {
      updateQuery(() => ({
        profile,
      }));
    },
  });

  const [updateProfile, { loading: sendingForm }] = useMutation<
    UPDATE_PROFILE_MUTATION,
    UPDATE_PROFILE_MUTATIONVariables
  >(UPDATE_PROFILE, {
    onCompleted: () => {
      showSuccessToast({
        title: ESettingsAccountLabels.SUCCESS_UPDATE_PROFILE_MESSAGE,
      });
      getUserProfile();
      refetch();
    },
    onError(error) {
      showErrorToast({ title: getError(error) });
    },
  });

  const screenLoading = profileLoading;

  const handleSubmitForm = () => {
    const { acceptBankAccountDetailsInformation, ...rest } = values;
    updateProfile({
      variables: {
        bankInfo: rest,
      },
    });
    setIsTouched(false);
  };

  if (screenLoading) {
    return (
      <LoaderContainer>
        <LoadingCircle height={30} style={{ color: '#4485eb' }} />
      </LoaderContainer>
    );
  }

  return !isImpersonated ? (
    <>
      <TextContainer>
        <Text isSubTitle>Bank account</Text>
      </TextContainer>
      <FormAccountBankSection>
        <MessageContainer style={{ marginLeft: 0 }}>
          <MessageBodyContainer>
            Please note that we can only include bank account information either:
            <ParagraphMessageContainer>
              <DotOption />
              In the name of the R-R or
            </ParagraphMessageContainer>
            <ParagraphMessageContainer>
              <DotOption />
              {`In the name of the R-R's sole-proprietor's business (created using the R-R's SSN).`}
            </ParagraphMessageContainer>
            <FooterMessageContainer>
              <FooterMessageLabel style={{ fontWeight: 'bold' }}>
                We cannot make payments to the bank accounts of distinct legal entities (e.g.,
                trusts, LLCs or corporations).
              </FooterMessageLabel>
            </FooterMessageContainer>
          </MessageBodyContainer>
        </MessageContainer>
        <BodySectionContainer isMain={false}>
          <InputBankSectionContainer>
            <Text isSubTitle={false} style={{ color: '#000' }}>
              Bank name
              <RequiredMarker>*</RequiredMarker>
            </Text>
            <Input
              id="bankName"
              name="bankName"
              onChange={changeField('bankName')}
              value={values.bankName}
              placeholder="Enter your details here"
            />
            <ErrorMessage>
              {errors.bankName && touched.bankName && <span> {errors.bankName}</span>}
            </ErrorMessage>
          </InputBankSectionContainer>
        </BodySectionContainer>
        <BodySectionContainer isMain={false}>
          <InputBankSectionContainer>
            <Text isSubTitle={false} style={{ color: '#000' }}>
              Personal bank account holder&apos;s name
              <RequiredMarker>*</RequiredMarker>
            </Text>
            <Input
              id="bankAccountName"
              name="bankAccountName"
              onChange={changeField('bankAccountName')}
              value={values.bankAccountName}
              placeholder="Enter your details here"
            />
            <ErrorMessage>
              {errors.bankAccountName && touched.bankAccountName && (
                <span> {errors.bankAccountName}</span>
              )}
            </ErrorMessage>
          </InputBankSectionContainer>
        </BodySectionContainer>
        <BodySectionContainer>
          <InputBankSectionContainer>
            <Text isSubTitle={false} style={{ color: '#000' }}>
              Personal bank account number
              <RequiredMarker>*</RequiredMarker>
            </Text>
            <Input
              id="bankAccountNumber"
              name="bankAccountNumber"
              onChange={changeField('bankAccountNumber')}
              value={values.bankAccountNumber}
              placeholder="Enter your details here"
            />
            <ErrorMessage>
              {errors.bankAccountNumber && touched.bankAccountNumber && (
                <span> {errors.bankAccountNumber}</span>
              )}
            </ErrorMessage>
          </InputBankSectionContainer>
        </BodySectionContainer>
        <BodySectionContainer>
          <InputBankSectionContainer>
            <Text isSubTitle={false} style={{ color: '#000' }}>
              ABA number
              <RequiredMarker>*</RequiredMarker>
            </Text>
            <Input
              id="abaNumber"
              name="abaNumber"
              onChange={changeField('abaNumber')}
              value={values.abaNumber}
              placeholder="Enter your details here"
            />
            <ErrorMessage>
              {errors.abaNumber && touched.abaNumber && <span> {errors.abaNumber}</span>}
            </ErrorMessage>
          </InputBankSectionContainer>
        </BodySectionContainer>
        <TextDescriptionContainer>
          <Text isSubTitle={false} style={{ color: '#000' }}>
            If applicable, please provide below bank information
          </Text>
        </TextDescriptionContainer>
        <BodySectionContainer isMain={false}>
          <InputBankSectionContainer>
            <Text isSubTitle={false} style={{ color: '#000' }}>
              Attention
            </Text>
            <Input
              id="attention"
              name="attention"
              onChange={changeField('attention')}
              value={values.attention}
              placeholder="Enter your details here"
            />
          </InputBankSectionContainer>
        </BodySectionContainer>
        <BodySectionContainer isMain={false}>
          <InputBankSectionContainer>
            <Text isSubTitle={false} style={{ color: '#000' }}>
              SWIFT code
            </Text>
            <Input
              id="swiftCode"
              name="swiftCode"
              onChange={changeField('swiftCode')}
              value={values.swiftCode}
              placeholder="Enter your details here"
            />
            <ErrorMessage>
              {errors.swiftCode && touched.swiftCode && <span> {errors.swiftCode}</span>}
            </ErrorMessage>
          </InputBankSectionContainer>
        </BodySectionContainer>
        <BodySectionContainer>
          <InputBankSectionContainer>
            <Text isSubTitle={false} style={{ color: '#000' }}>
              Further personal bank account name
            </Text>
            <Input
              id="furtherBankAccountName"
              name="furtherBankAccountName"
              onChange={changeField('furtherBankAccountName')}
              value={values.furtherBankAccountName}
              placeholder="Enter your details here"
            />
            <ErrorMessage>
              {errors.furtherBankAccountName && touched.furtherBankAccountName && (
                <span> {errors.furtherBankAccountName}</span>
              )}
            </ErrorMessage>
          </InputBankSectionContainer>
        </BodySectionContainer>
        <BodySectionContainer>
          <InputBankSectionContainer>
            <Text isSubTitle={false} style={{ color: '#000' }}>
              Further personal bank account number
            </Text>
            <Input
              id="furtherBankAccountNumber"
              name="furtherBankAccountNumber"
              onChange={changeField('furtherBankAccountNumber')}
              value={values.furtherBankAccountNumber}
              placeholder="Enter your details here"
            />
            <ErrorMessage>
              {errors.furtherBankAccountNumber && touched.furtherBankAccountNumber && (
                <span> {errors.furtherBankAccountNumber}</span>
              )}
            </ErrorMessage>
          </InputBankSectionContainer>
        </BodySectionContainer>
        <CheckboxAccountBankingContainer>
          <BodySectionContainer>
            <Checkbox
              id="acceptBankAccountDetailsInformation"
              checked={values.acceptBankAccountDetailsInformation}
              onChange={changeCheckBox('acceptBankAccountDetailsInformation')}
            />{' '}
            <CheckboxAcountBankingLabel>
              {
                'I hereby (a) attest to the fact that the above account is my personal and private bank account, and (b) acknowledge and agree that I must provide prior written notice of any changes to my personal and private bank account details to Compliance.'
              }
              <RequiredMarker>*</RequiredMarker>
            </CheckboxAcountBankingLabel>
          </BodySectionContainer>
        </CheckboxAccountBankingContainer>
      </FormAccountBankSection>
      <SubmitButtonsBotomFixedContainer>
        <Button
          buttonType={EButtonType.primary}
          disabled={sendingForm || !isValid || !values.acceptBankAccountDetailsInformation}
          onClick={handleSubmitForm}
        >
          {EButtonMessages.SAVE_ACTION}
        </Button>
      </SubmitButtonsBotomFixedContainer>
    </>
  ) : (
    <TextContainer>
      <Text isSubTitle>You are not allowed to see this information</Text>
    </TextContainer>
  );
}

export default AccountBankSettings;
