import * as React from 'react';
import { IconProps } from './types';

export const ListIconV2 = ({
  color = 'currentColor',
  style = {},
  height = '13',
  width = '21',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M6.00015 3H20.0001C20.6001 3 21.0001 2.6 21.0001 2C21.0001 1.4 20.6001 1 20.0001 1H6.00015C5.40015 1 5.00015 1.4 5.00015 2C5.00015 2.6 5.40015 3 6.00015 3ZM20.0001 6H6.00015C5.40015 6 5.00015 6.4 5.00015 7C5.00015 7.6 5.40015 8 6.00015 8H20.0001C20.6001 8 21.0001 7.6 21.0001 7C21.0001 6.4 20.6001 6 20.0001 6ZM20.0001 11H6.00015C5.40015 11 5.00015 11.4 5.00015 12C5.00015 12.6 5.40015 13 6.00015 13H20.0001C20.6001 13 21.0001 12.6 21.0001 12C21.0001 11.4 20.6001 11 20.0001 11ZM2.70015 1.3C2.60015 1.2 2.50015 1.1 2.40015 1.1C2.00015 0.900001 1.60015 1 1.30015 1.3C1.20015 1.4 1.10015 1.5 1.10015 1.6C1.00015 1.9 1.00015 2.1 1.10015 2.4C1.20015 2.5 1.20015 2.6 1.30015 2.7C1.40015 2.8 1.50015 2.9 1.60015 2.9C1.70015 3 1.90015 3 2.00015 3C2.30015 3 2.50015 2.9 2.70015 2.7C2.80015 2.6 2.90015 2.5 2.90015 2.4C3.00015 2.1 3.00015 1.9 2.90015 1.6C2.90015 1.5 2.80015 1.4 2.70015 1.3ZM2.70015 6.3C2.40015 6 2.00015 5.9 1.60015 6.1C1.50015 6.2 1.40015 6.2 1.30015 6.3C1.20015 6.4 1.10015 6.5 1.10015 6.6C1.00015 6.8 1.00015 7.1 1.10015 7.4C1.20015 7.5 1.20015 7.6 1.30015 7.7C1.40015 7.8 1.50015 7.9 1.60015 7.9C1.70015 8 1.90015 8 2.00015 8C2.10015 8 2.30015 8 2.40015 7.9C2.50015 7.8 2.60015 7.8 2.70015 7.7C2.80015 7.6 2.90015 7.5 2.90015 7.4C3.00015 7.2 3.00015 6.9 2.90015 6.6C2.90015 6.5 2.80015 6.4 2.70015 6.3ZM2.70015 11.3C2.60015 11.2 2.50015 11.1 2.40015 11.1C2.20015 11 1.90015 11 1.60015 11.1C1.50015 11.1 1.40015 11.2 1.30015 11.3C1.20015 11.4 1.10015 11.5 1.10015 11.6C0.900146 12 1.00015 12.4 1.30015 12.7C1.40015 12.8 1.50015 12.9 1.60015 12.9C1.70015 13 1.90015 13 2.00015 13C2.10015 13 2.30015 13 2.40015 12.9C2.50015 12.8 2.60015 12.8 2.70015 12.7C3.00015 12.4 3.10015 12 2.90015 11.6C2.90015 11.5 2.80015 11.4 2.70015 11.3Z"
      fill={color}
    />
  </svg>
);
