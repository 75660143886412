import { GET_DEAL_QUERY_deal_metadata } from 'api/graphql/deals/types/GET_DEAL_QUERY';
import { GET_USER_PROFILE_QUERY_profile_metadata } from 'api/graphql/users/types/GET_USER_PROFILE_QUERY';
import safeParse, { SafeParseOptions } from './safeParse';

type MetadataType = (
  | GET_DEAL_QUERY_deal_metadata
  | GET_USER_PROFILE_QUERY_profile_metadata
  | null)[];

export type ParserOptions = <T = any>(key: string, value: string) => SafeParseOptions<T>;

export const mapMetadata = (
  metadata: MetadataType = [],
  parserOptions?: ParserOptions
): Record<string, any> => {
  const metadataArray = metadata || [];
  return metadataArray.reduce(
    (prev, curr) => ({
      ...prev,
      ...(curr
        ? {
            [curr.key]: safeParse(
              curr.value as string,
              parserOptions && parserOptions(curr.key, curr.value as string)
            ),
          }
        : {}),
    }),
    {}
  );
};
