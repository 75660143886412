import React, { CSSProperties } from 'react';
import { HStack } from '../Stack/HStack';

// style
import * as S from './RadioButtonStyled';

interface RadioButtonProps extends React.InputHTMLAttributes<any> {
  label?: string | React.ReactNode;
  style?: CSSProperties;
}

const RadioButton = ({ type, label, id, style, ...props }: RadioButtonProps) => (
  <HStack
    spacing={8}
    alignItems="center"
    style={{ display: 'inline-flex', width: 'min-content', ...style }}
  >
    <S.HiddenRadio id={id} {...props} />
    <S.RadioOption />
    {label && <S.RadioLabel htmlFor={id}>{label}</S.RadioLabel>}
  </HStack>
);

export default RadioButton;
