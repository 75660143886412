import * as React from 'react';
import { IconProps } from './types';

export const PieChartIcon = ({
  width = '20px',
  height = '20px',
  color = '#EB6A19',
}: IconProps): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
      fill="#FFF1E0"
    />
    <path
      d="M10 3.33333C6.33333 3.33333 3.33333 6.33333 3.33333 10C3.33333 13.6667 6.33333 16.6667 10 16.6667C13.6667 16.6667 16.6667 13.6667 16.6667 10C16.6667 6.33333 13.6667 3.33333 10 3.33333ZM10 4.66666C12.6667 4.66666 14.9333 6.66666 15.2667 9.33333H10V4.66666ZM12.6667 14.6L10.4 10.6667H15.3333C15.0667 12.3333 14.1333 13.8 12.6667 14.6Z"
      fill={color}
    />
  </svg>
);
