// Vendors
import { useEffect } from 'react';
import ReactGA4 from 'react-ga4';
// AppStorage
import AppStorage, { EMAIL_KEY } from 'app/appStorage';

const useGoogleAnalytics4 = () => {
  const email: string = AppStorage.getItem(EMAIL_KEY).toString();
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4 || '');
  }, []);

  const trackSingleEvent = (
    category: string,
    action: string,
    label?: string,
    data?: undefined | any
  ) => {
    ReactGA4.event({
      category,
      action,
      label: label || email.replace('@', '#'),
      value: 1, // optional, must be a number
      nonInteraction: true,
      transport: 'xhr',
      ...data,
    });
  };

  const trackMultipleEvents = (category: string, action: string, labels: string[]) => {
    labels.forEach((singleLabel: string) => {
      trackSingleEvent(category, action, singleLabel);
    });
  };

  return {
    trackSingleEvent,
    trackMultipleEvents,
  };
};

export default useGoogleAnalytics4;
