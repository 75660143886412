import * as yup from 'yup';
import validateFileExtension, {
  basicRequiredInputValidation,
  REQUIRED_MESSAGE,
  validateFileName,
} from '../../../../utils/helpers/formValidators';

export const validationSchema = yup.object().shape({
  files: yup
    .array()
    .required('At least one file is required')
    .test(
      '',
      'Some file has an invalid name',
      value => value && value.length && validateFileName(value)
    )
    .test(
      '',
      'Some file has an invalid extension',
      value => value && value.length && validateFileExtension(value)
    ),
  conflicts: yup.boolean().required(REQUIRED_MESSAGE),
  conflictDescription: yup.string().when('conflicts', {
    is: true,
    then: basicRequiredInputValidation(),
  }),
  closingDate: basicRequiredInputValidation(),
  dealSize: basicRequiredInputValidation(),
});
