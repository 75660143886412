import React from 'react';
import { LoadingRectangle } from '@dealsyte/poki';

import { HeaderContainer } from '../../StyledSettingsModal';

import { Container, Column, GroupList, SpaceBetweenRow, MemberList } from './StyledGroupsSettings';

/**
 * Renders groups settings loader skeleton
 * @param internal Flag that indicates if selected settings tab es internal or external.
 */
function GroupsSettingsLoader({ internal }: { internal: boolean }) {
  return (
    <>
      <GroupList>
        <div style={{ borderBottom: '1px solid rgba(222, 224, 226, 0.3)' }}>
          <LoadingRectangle mainStyle={{ margin: '15px auto' }} height={15} width={120} />
        </div>
        <Column id="SidebarGroupList" style={{ overflowY: 'auto', flex: 1, margin: '15px 15px' }}>
          {[...new Array(4)].map((_, index) => (
            <div key={index}>
              <LoadingRectangle height={25} width={25} />
              <LoadingRectangle
                mainStyle={{ left: '35px', bottom: '25px' }}
                height={15}
                width={130}
              />
              <LoadingRectangle
                mainStyle={{ left: '35px', bottom: '20px' }}
                height={5}
                width={70}
              />
            </div>
          ))}
        </Column>
      </GroupList>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <HeaderContainer
          style={{
            position: 'relative',
            justifyContent: 'left',
            borderBottom: '1px solid #f4f4f4',
            padding: '35px 30px',
          }}
        >
          <LoadingRectangle height={25} width={25} />
          <LoadingRectangle mainStyle={{ left: '15px' }} height={15} width={200} />
          <LoadingRectangle mainStyle={{ right: '225px', top: '30px' }} height={5} width={240} />
        </HeaderContainer>
        <div style={{ display: 'flex', overflowY: 'auto', minHeight: '300px' }}>
          <Container>
            {internal ? (
              <Column>
                <LoadingRectangle mainStyle={{ top: '30px' }} height={20} width={80} />
                <SpaceBetweenRow style={{ marginTop: '50px' }}>
                  <span>
                    <LoadingRectangle height={15} width={40} />
                  </span>
                  <span>
                    <LoadingRectangle height={15} width={80} />
                  </span>
                </SpaceBetweenRow>
                <MemberList>
                  {[...new Array(3)].map((_, index) => (
                    <div key={index}>
                      <LoadingRectangle
                        mainStyle={{ top: '20px' }}
                        rectangleStyle={{ borderRadius: '100%' }}
                        height={20}
                        width={20}
                      />
                      <LoadingRectangle mainStyle={{ left: '30px' }} height={10} width={40} />
                      <LoadingRectangle
                        mainStyle={{ left: '30px', top: '5px' }}
                        height={5}
                        width={80}
                      />
                      <LoadingRectangle
                        mainStyle={{ left: '90%', bottom: '15px' }}
                        height={5}
                        width={50}
                      />
                    </div>
                  ))}
                </MemberList>
                <LoadingRectangle mainStyle={{ top: '30px' }} height={40} width={150} />
                <LoadingRectangle
                  mainStyle={{ top: '60px', marginBottom: '20px' }}
                  height={20}
                  width={80}
                />
                <div style={{ marginTop: '60px' }}>
                  {[...new Array(2)].map((_, index) => (
                    <SpaceBetweenRow style={{ marginTop: '10px' }} key={index}>
                      <span>
                        <LoadingRectangle height={10} width={180} />
                      </span>
                      <span>
                        <LoadingRectangle height={10} width={100} />
                      </span>
                    </SpaceBetweenRow>
                  ))}
                </div>
              </Column>
            ) : (
              <Column>
                <SpaceBetweenRow style={{ marginTop: '50px' }}>
                  <span>
                    <LoadingRectangle height={15} width={40} />
                  </span>
                  <span>
                    <LoadingRectangle height={15} width={80} />
                  </span>
                </SpaceBetweenRow>
                <MemberList>
                  {[...new Array(5)].map((_, index) => (
                    <div
                      key={index}
                      style={{ borderBottom: '1px solid #f4f4f4', paddingBottom: '5px' }}
                    >
                      <LoadingRectangle mainStyle={{ top: '10px' }} height={20} width={20} />
                      <LoadingRectangle
                        mainStyle={{ left: '30px', bottom: '5px' }}
                        height={10}
                        width={40}
                      />
                      <LoadingRectangle
                        mainStyle={{ left: '90%', bottom: '15px' }}
                        height={5}
                        width={50}
                      />
                    </div>
                  ))}
                </MemberList>
              </Column>
            )}
          </Container>
        </div>
      </div>
    </>
  );
}
GroupsSettingsLoader.displayName = 'GroupsSettingsLoader';

export default GroupsSettingsLoader;
