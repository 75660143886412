import React, { useState, useRef, useContext, ReactNode, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import {
  keyboardContants,
  Popup,
  Input,
  ContextMenu,
  EButtonSizeType,
  EllipsisIcon,
  Alert,
} from '@dealsyte/poki';

import { UserContext } from 'app/users/context/UserContext';
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { RENAME_FILEROOM, DELETE_FILEROOM } from 'api/graphql/core-tools/vdr/mutations';
import {
  RENAME_FILEROOM_MUTATION,
  RENAME_FILEROOM_MUTATIONVariables,
} from 'api/graphql/core-tools/vdr/types/RENAME_FILEROOM_MUTATION';
import {
  DELETE_FILEROOM_MUTATIONVariables,
  DELETE_FILEROOM_MUTATION,
} from 'api/graphql/core-tools/vdr/types/DELETE_FILEROOM_MUTATION';
import { GET_FILEROOMS } from 'api/graphql/core-tools/vdr/queries';
import { Fso as IFso } from 'app/core-tools/due-diligence/vdr/utils/types';
import { Role as ERole, DealSide as EDealSide } from 'app/core-tools/due-diligence/types/types';
import { isFsoNameValid } from 'app/core-tools/due-diligence/vdr/utils/helpers';
import { MAX_FSO_NAME_DEFINITION_CONSTRAINT } from 'app/core-tools/due-diligence/vdr/utils/constants/various';

import { Link } from '../../StyledDealContentSidebar';
import { ContextMenuContainer, ActionsButton } from './StyledFileroom';
import { getError } from 'app/utils/helpers/helpers';

/**
 * Renders a fileroom to be displayed in the global sidebar.
 * @param fileRoomData Object containing all the fileroom data.
 */
function Fileroom({ fileRoomData }: { fileRoomData: IFso }): JSX.Element {
  const user = useContext(UserContext);
  const { showSuccessToast, showErrorToast } = useContext(AlertContext);

  const [currentEditingValue, setCurrentEditingValue] = useState('');
  const [isEditingName, setIsEditingName] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const renameInputRef = useRef<HTMLInputElement>(null);

  const routeMatch = !!useRouteMatch(`/vdr/directories/${fileRoomData.id}`);
  const history = useHistory();

  const handleDeleteRedirect = (): void => {
    if (routeMatch) {
      history.push('/vdr/directories');
    }
  };

  const [renameFileRoomMutation, { loading: renameMutationLoading }] = useMutation<
    RENAME_FILEROOM_MUTATION,
    RENAME_FILEROOM_MUTATIONVariables
  >(RENAME_FILEROOM, {
    onCompleted(res) {
      showSuccessToast({
        title: `Successfully changed data room's name to ${get(res, 'renameFileRoom.name')}.`,
      });
    },
    onError(error) {
      showErrorToast({ title: getError(error) });
    },
  });

  const [deleteFileRoomMutation, { loading: deleteMutationLoading }] = useMutation<
    DELETE_FILEROOM_MUTATION,
    DELETE_FILEROOM_MUTATIONVariables
  >(DELETE_FILEROOM, {
    onCompleted() {
      showSuccessToast({
        title: 'Data room successfully deleted.',
      });

      handleDeleteRedirect();
    },
    onError(error) {
      showErrorToast({ title: getError(error) });
    },
    update(cache) {
      const fileRoomsCacheData = cache.readQuery({
        query: GET_FILEROOMS,
        variables: { dealId: get(user, 'activeDeal.id', '') },
      });

      if (get(fileRoomsCacheData, 'fileRooms'))
        cache.writeQuery({
          query: GET_FILEROOMS,
          variables: { dealId: get(user, 'activeDeal.id', '') },
          data: {
            fileRooms: get(fileRoomsCacheData, 'fileRooms').filter(
              ({ id }: { id: string }) => id !== fileRoomData.id
            ),
          },
        });
    },
  });

  const isLoading = deleteMutationLoading || renameMutationLoading;

  function renameFileroom(name: string): void {
    const trimmedName = name.trim();

    if (isFsoNameValid(trimmedName, MAX_FSO_NAME_DEFINITION_CONSTRAINT)) {
      renameFileRoomMutation({
        variables: {
          fileRoomId: fileRoomData.id,
          name: trimmedName,
        },
      });
    } else {
      showErrorToast({
        title: 'Invalid data room name',
        description: `${name} is not a valid name. Please try with another one.`,
      });
    }

    stopEditingRenameInput();
  }

  function deleteFileroom(): void {
    deleteFileRoomMutation({ variables: { fileRoomId: fileRoomData.id } });
  }

  function startEditingRenameInput(): void {
    setIsEditingName(true);

    setCurrentEditingValue(fileRoomData.name);

    document.addEventListener('mousedown', handleClickOutsideInput);
  }

  function stopEditingRenameInput(): void {
    setIsEditingName(false);

    document.removeEventListener('mousedown', handleClickOutsideInput);
  }

  function handleRenameInputKeyDown(event: React.KeyboardEvent): void {
    if (event.keyCode === keyboardContants.ENTER_KEY) {
      renameFileroom(currentEditingValue);

      stopEditingRenameInput();
    }

    if (event.keyCode === keyboardContants.ESC_KEY) {
      stopEditingRenameInput();
    }
  }

  function handleClickOutsideInput(event: MouseEvent): void {
    const menuNode = renameInputRef && renameInputRef.current;

    if (menuNode && !menuNode.contains(event.target as Node)) {
      renameFileroom(currentEditingValue);

      document.removeEventListener('mousedown', handleClickOutsideInput);
    }
  }

  function getActions(): {
    disabled: boolean;
    isLoading: boolean;
    label: ReactNode | string;
    onClick: () => void;
    show: boolean;
  }[] {
    const actions = [
      {
        disabled: isLoading,
        isLoading,
        label: 'Rename data room',
        onClick: startEditingRenameInput,
        show: fileRoomData.permissions.rename,
      },
      {
        disabled: isLoading,
        isLoading,
        label: 'Delete data room',
        onClick() {
          setShowDeleteAlert(true);
        },
        show: fileRoomData.permissions.delete,
      },
    ];

    return actions.filter(action => action.show);
  }

  useEffect(() => {
    if (isEditingName) {
      //@ts-ignore
      renameInputRef.current.focus();

      //@ts-ignore
      renameInputRef.current.setSelectionRange(0, renameInputRef.current.value.length);
    }
  }, [isEditingName]);

  return (
    <>
      <Popup
        show={isEditingName}
        placement="on-top"
        modifiers={{
          preventOverflow: { enabled: true, priority: ['left'] },
        }}
        popupStyle={{ backgroundColor: 'transparent', boxShadow: 'none', left: 40, top: 6 }}
        popup={
          <Input
            style={{ width: 152 }}
            value={currentEditingValue}
            onChange={event => setCurrentEditingValue((event.target as HTMLInputElement).value)}
            onKeyDown={handleRenameInputKeyDown}
            ref={renameInputRef}
            data-testid="RenameFileRoomInput"
          />
        }
        reference={({ ref }) => (
          <ContextMenuContainer ref={ref}>
            <ContextMenu
              actions={getActions()}
              align="left"
              renderTriggerButton={({ ref, onClick, isMenuOpen }) => (
                <>
                  <Link
                    to={`/vdr/directories/${fileRoomData.id}`}
                    style={{ marginLeft: 42, color: routeMatch ? '#4585EB' : '#3E4751' }}
                  >
                    {fileRoomData.name}
                  </Link>

                  {user.role === ERole.ADMIN &&
                    (user.side === EDealSide.SELL && (
                      <ActionsButton
                        size={EButtonSizeType.small}
                        isMenuOpen={isMenuOpen}
                        ref={ref}
                        onClick={onClick}
                      >
                        <EllipsisIcon height={11} />
                      </ActionsButton>
                    ))}
                </>
              )}
            />
          </ContextMenuContainer>
        )}
      />

      <Alert
        onHide={() => {
          setShowDeleteAlert(false);
        }}
        disableActionButtons={isLoading}
        show={showDeleteAlert}
        content={{
          title: 'Delete data room',
          description: `Are you sure you want to delete data room ${fileRoomData.name}? This action is permanent, and after you delete it cannot be restored.`,
        }}
        confirmAction={{
          label: 'Delete',
          action: deleteFileroom,
        }}
        declineAction={{
          label: 'Cancel',
          action() {
            setShowDeleteAlert(false);
          },
        }}
        data-testid="DeleteFileRoomAlert"
      />
    </>
  );
}

export default Fileroom;
