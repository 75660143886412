export const DEAL_INDUSTRY_TYPES = {
  TECHNOLOGY: 'Technology',
  CONSUMER: 'Consumer and Retail',
  FINANCIAL: 'Financial Services - Traditional',
  FINTECH: 'FinTech',
  HEALTHCARE: 'Healthcare',
  LIFE_SCIENCE: 'Life Sciences',
  CONSULTING: 'Consulting, Marketing and Communications',
  ENERGY: 'Energy',
  REAL_ESTATE: 'Real Estate, Lodging and Leisure',
  MATERIALS: 'Materials',
  OTHERS: 'Industrials and Other Services',
};

export const DEAL_INDUSTRY_TYPES_OPTIONS = [
  {
    value: DEAL_INDUSTRY_TYPES.TECHNOLOGY,
    label: DEAL_INDUSTRY_TYPES.TECHNOLOGY,
  },
  {
    value: DEAL_INDUSTRY_TYPES.CONSUMER,
    label: DEAL_INDUSTRY_TYPES.CONSUMER,
  },
  {
    value: DEAL_INDUSTRY_TYPES.FINANCIAL,
    label: DEAL_INDUSTRY_TYPES.FINANCIAL,
  },
  {
    value: DEAL_INDUSTRY_TYPES.FINTECH,
    label: DEAL_INDUSTRY_TYPES.FINTECH,
  },
  {
    value: DEAL_INDUSTRY_TYPES.HEALTHCARE,
    label: DEAL_INDUSTRY_TYPES.HEALTHCARE,
  },
  {
    value: DEAL_INDUSTRY_TYPES.LIFE_SCIENCE,
    label: DEAL_INDUSTRY_TYPES.LIFE_SCIENCE,
  },
  {
    value: DEAL_INDUSTRY_TYPES.CONSULTING,
    label: DEAL_INDUSTRY_TYPES.CONSULTING,
  },
  {
    value: DEAL_INDUSTRY_TYPES.ENERGY,
    label: DEAL_INDUSTRY_TYPES.ENERGY,
  },
  {
    value: DEAL_INDUSTRY_TYPES.REAL_ESTATE,
    label: DEAL_INDUSTRY_TYPES.REAL_ESTATE,
  },
  {
    value: DEAL_INDUSTRY_TYPES.MATERIALS,
    label: DEAL_INDUSTRY_TYPES.MATERIALS,
  },
  {
    value: DEAL_INDUSTRY_TYPES.OTHERS,
    label: DEAL_INDUSTRY_TYPES.OTHERS,
  },
];

export const INDUSTRY_TYPE_OPTIONS_TECHNOLOGY = [
  {
    value: 'SaaS',
    label: 'SaaS',
  },
  {
    value: 'eCommerce',
    label: 'eCommerce',
  },
  {
    value: 'Cybersecurity',
    label: 'Cybersecurity',
  },
  {
    value: 'ID Verification',
    label: 'ID Verification',
  },
  {
    value: 'Blockchain',
    label: 'Blockchain',
  },
  {
    value: 'AgTech (Agricultural Technology)',
    label: 'AgTech (Agricultural Technology)',
  },
  {
    value: 'eSport/Gaming',
    label: 'eSport/Gaming',
  },
  {
    value: 'IoT',
    label: 'IoT',
  },
  {
    value: 'Artificial Intelligence',
    label: 'Artificial Intelligence',
  },
  {
    value: 'Compliance/Legal',
    label: 'Compliance/Legal',
  },
  {
    value: 'Telematics',
    label: 'Telematics',
  },
  {
    value: 'Biometrics',
    label: 'Biometrics',
  },
  {
    value: 'Supply Chain/Inventory',
    label: 'Supply Chain/Inventory',
  },
  {
    value: 'Data Analytics',
    label: 'Data Analytics',
  },
  {
    value: 'Animation',
    label: 'Animation',
  },
  {
    value: 'Entertainment Software',
    label: 'Entertainment Software',
  },
  {
    value: 'edTech',
    label: 'edTech',
  },
  {
    value: 'Food Delivery',
    label: 'Food Delivery',
  },
];

export const INDUSTRY_TYPE_OPTIONS_CONSUMER = [
  {
    value: 'Retail',
    label: 'Retail',
  },
  {
    value: 'Brands',
    label: 'Brands',
  },
  {
    value: 'Luxury Products',
    label: 'Luxury Products',
  },
  {
    value: 'Entertainment',
    label: 'Entertainment',
  },
  {
    value: 'Wellness and Fitness',
    label: 'Wellness and Fitness',
  },
  {
    value: 'Food',
    label: 'Food',
  },
  {
    value: 'Pets Retail',
    label: 'Pets Retail',
  },
  {
    value: 'Home and Office Products',
    label: 'Home and Office Products',
  },
  {
    value: 'Apparel and Textile (Products)',
    label: 'Apparel and Textile (Products)',
  },
  {
    value: 'Beverages',
    label: 'Beverages',
  },
  {
    value: 'Beauty Products',
    label: 'Beauty Products',
  },
  {
    value: 'Farming/Agricultural',
    label: 'Farming/Agricultural',
  },
  {
    value: 'Cannabis',
    label: 'Cannabis',
  },
  {
    value: 'Education',
    label: 'Education',
  },
  {
    value: 'Travel, Lodging and Dining',
    label: 'Travel, Lodging and Dining',
  },
  {
    value: 'Fast Food',
    label: 'Fast Food',
  },
  {
    value: 'Green Consumer Goods',
    label: 'Green Consumer Goods',
  },
  {
    value: 'Healthcare Supplies',
    label: 'Healthcare Supplies',
  },
  {
    value: 'Sports',
    label: 'Sports',
  },
];

export const INDUSTRY_TYPE_OPTIONS_FINANCIAL = [
  {
    value: 'Insurance',
    label: 'Insurance',
  },
  {
    value: 'Traditional Broker Dealers',
    label: 'Traditional Broker Dealers',
  },
  {
    value: 'Mortgage',
    label: 'Mortgage',
  },
  {
    value: 'Banking',
    label: 'Banking',
  },
  {
    value: 'Assets Management',
    label: 'Assets Management',
  },
  {
    value: 'Private Equity',
    label: 'Private Equity',
  },
  {
    value: 'Venture Capital',
    label: 'Venture Capital',
  },
  {
    value: 'Family Office',
    label: 'Family Office',
  },
  {
    value: 'Lender',
    label: 'Lender',
  },
];

export const INDUSTRY_TYPE_OPTIONS_FINTECH = [
  {
    value: 'Crypto',
    label: 'Crypto',
  },
  {
    value: 'NFT (Non-Fungible Tokens)',
    label: 'NFT (Non-Fungible Tokens)',
  },
  {
    value: 'Digital Assets',
    label: 'Digital Assets',
  },
  {
    value: 'InsurTech',
    label: 'InsurTech',
  },
  {
    value: 'WealthTech',
    label: 'WealthTech',
  },
  {
    value: 'Blockchain',
    label: 'Blockchain',
  },
  {
    value: 'Buy Now Pay Later',
    label: 'Buy Now Pay Later',
  },
  {
    value: 'PropTech',
    label: 'PropTech',
  },
  {
    value: 'Special Lending',
    label: 'Special Lending',
  },
  {
    value: 'Robo Advisors',
    label: 'Robo Advisors',
  },
  {
    value: 'Payments',
    label: 'Payments',
  },
];

export const INDUSTRY_TYPE_OPTIONS_HEALTHCARE = [
  {
    value: 'IoT Hospital',
    label: 'IoT Hospital',
  },
  {
    value: 'Medical (Equipment/Devices)',
    label: 'Medical (Equipment/Devices)',
  },
  {
    value: 'TeleHealth',
    label: 'TeleHealth',
  },
  {
    value: 'Hair Loss',
    label: 'Hair Loss',
  },
  {
    value: 'Healthcare Facilities/Services',
    label: 'Healthcare Facilities/Services',
  },
  {
    value: 'Elderly Services',
    label: 'Elderly Services',
  },
  {
    value: 'HealthTech',
    label: 'HealthTech',
  },
];

export const INDUSTRY_TYPE_OPTIONS_LIFESCIENCE = [
  {
    value: 'BioTech',
    label: 'BioTech',
  },
  {
    value: 'Pharma',
    label: 'Pharma',
  },
  {
    value: 'Gene Therapy',
    label: 'Gene Therapy',
  },
  {
    value: 'Drug Development',
    label: 'Drug Development',
  },
  {
    value: 'CRO (Contract Research Organization/Optimization)',
    label: 'CRO (Contract Research Organization/Optimization)',
  },
];

export const INDUSTRY_TYPE_OPTIONS_COMMUNICATIONS = [
  {
    value: 'TMT',
    label: 'TMT',
  },
  {
    value: 'Media Content',
    label: 'Media Content',
  },
  {
    value: 'Marketing',
    label: 'Marketing',
  },
  {
    value: 'Consulting',
    label: 'Consulting',
  },
  {
    value: 'Advertising',
    label: 'Advertising',
  },
  {
    value: 'Creative Agency',
    label: 'Creative Agency',
  },
];

export const INDUSTRY_TYPE_OPTIONS_ENERGY = [
  {
    value: 'Oil, Gas and Coal',
    label: 'Oil, Gas and Coal',
  },
  {
    value: 'Renewable Energy',
    label: 'Renewable Energy',
  },
];

export const INDUSTRY_TYPE_OPTIONS_REALESTATE = [
  {
    value: 'Housing Market',
    label: 'Housing Market',
  },
  {
    value: 'Commercial Real Estate',
    label: 'Commercial Real Estate',
  },
  {
    value: 'Residential Real Estate',
    label: 'Residential Real Estate',
  },
  {
    value: 'Hotel',
    label: 'Hotel',
  },
  {
    value: 'Investments',
    label: 'Investments',
  },
  {
    value: 'B2C Investments',
    label: 'B2C Investments',
  },
  {
    value: 'Marine',
    label: 'Marine',
  },
];

export const INDUSTRY_TYPE_OPTIONS_MATERIALS = [
  {
    value: 'Chemicals',
    label: 'Chemicals',
  },
  {
    value: 'Construction Materials',
    label: 'Construction Materials',
  },
  {
    value: 'Containers and Packaging',
    label: 'Containers and Packaging',
  },
  {
    value: 'Forest and Paper Products',
    label: 'Forest and Paper Products',
  },
  {
    value: 'Iron and Steel',
    label: 'Iron and Steel',
  },
  {
    value: 'Metals and Mining',
    label: 'Metals and Mining',
  },
];

export const INDUSTRY_TYPE_OPTIONS_INDUSTRIAL = [
  {
    value: 'Aerospace and Defense',
    label: 'Aerospace and Defense',
  },
  {
    value: 'Electrical Equipment',
    label: 'Electrical Equipment',
  },
  {
    value: 'Engineering and Construction Services',
    label: 'Engineering and Construction Services',
  },
  {
    value: 'Industrial Distribution',
    label: 'Industrial Distribution',
  },
  {
    value: 'Machinery',
    label: 'Machinery',
  },
  {
    value: 'Manufactured Goods',
    label: 'Manufactured Goods',
  },
  {
    value: 'Transportation and Logistics',
    label: 'Transportation and Logistics',
  },
  {
    value: 'Transportation Equipment',
    label: 'Transportation Equipment',
  },
  {
    value: 'Waste and Environmental Services',
    label: 'Waste and Environmental Services',
  },
  {
    value: 'Staff Services',
    label: 'Staff Services',
  },
  {
    value: 'Battery',
    label: 'Battery',
  },
  {
    value: 'Utilities',
    label: 'Utilities',
  },
  {
    value: 'Automotive',
    label: 'Automotive',
  },
  {
    value: 'Printing and Labeling Products',
    label: 'Printing and Labeling Products',
  },
  {
    value: 'Building Maintenance',
    label: 'Building Maintenance',
  },
  {
    value: 'HVAC',
    label: 'HVAC',
  },
];

export const getIndustryTypeSubOptions = (industryType: string) => {
  switch (industryType) {
    case DEAL_INDUSTRY_TYPES.TECHNOLOGY:
      return INDUSTRY_TYPE_OPTIONS_TECHNOLOGY;
    case DEAL_INDUSTRY_TYPES.CONSUMER:
      return INDUSTRY_TYPE_OPTIONS_CONSUMER;
    case DEAL_INDUSTRY_TYPES.FINANCIAL:
      return INDUSTRY_TYPE_OPTIONS_FINANCIAL;
    case DEAL_INDUSTRY_TYPES.FINTECH:
      return INDUSTRY_TYPE_OPTIONS_FINTECH;
    case DEAL_INDUSTRY_TYPES.HEALTHCARE:
      return INDUSTRY_TYPE_OPTIONS_HEALTHCARE;
    case DEAL_INDUSTRY_TYPES.LIFE_SCIENCE:
      return INDUSTRY_TYPE_OPTIONS_LIFESCIENCE;
    case DEAL_INDUSTRY_TYPES.CONSULTING:
      return INDUSTRY_TYPE_OPTIONS_COMMUNICATIONS;
    case DEAL_INDUSTRY_TYPES.ENERGY:
      return INDUSTRY_TYPE_OPTIONS_ENERGY;
    case DEAL_INDUSTRY_TYPES.REAL_ESTATE:
      return INDUSTRY_TYPE_OPTIONS_REALESTATE;
    case DEAL_INDUSTRY_TYPES.MATERIALS:
      return INDUSTRY_TYPE_OPTIONS_MATERIALS;
    case DEAL_INDUSTRY_TYPES.OTHERS:
      return INDUSTRY_TYPE_OPTIONS_INDUSTRIAL;
    default:
      return [{ value: '', label: '' }];
  }
};
