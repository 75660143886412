import React, { HTMLProps, useEffect, useMemo, useRef, useState } from 'react';
import * as S from './StyledInput';

export interface InputProps extends HTMLProps<HTMLInputElement> {
  prefix?: string;
  suffix?: string;
  error?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  onValueSet?: (value: string | number | string[] | undefined) => void;
  inputStyles?: React.CSSProperties;
  style?: React.CSSProperties;
}

const elementHasChildren = (element?: HTMLDivElement) => {
  if (!element) return false;
  return element.childNodes.length > 0;
};

const getElementWidth = (element: HTMLDivElement) => {
  return element.clientWidth;
};

export const Input = React.forwardRef(
  (refProps: InputProps, ref: React.LegacyRef<HTMLInputElement>) => {
    const {
      prefix,
      suffix,
      leftIcon,
      rightIcon,
      type,
      disabled,
      error,
      inputStyles,
      style,
      value,
      onValueSet,
      onClick,
      ...props
    } = refProps;

    const [inputPadding, setInputPadding] = useState({ leftPadding: 0, rightPadding: 0 });

    const rightOverlayRef = useRef<HTMLDivElement>();
    const leftOverlayRef = useRef<HTMLDivElement>();

    useEffect(() => {
      if (onValueSet) {
        onValueSet(value);
      }
    }, [value]);

    useEffect(() => {
      const leftElement = leftOverlayRef.current;
      const rightElement = rightOverlayRef.current;

      if (leftElement && elementHasChildren(leftElement)) {
        const width = getElementWidth(leftElement);
        setInputPadding(state => ({
          ...state,
          leftPadding: width + 8,
        }));
      }

      if (rightElement && elementHasChildren(rightElement)) {
        const width = getElementWidth(rightElement);
        setInputPadding(state => ({
          ...state,
          rightPadding: width + 8,
        }));
      }
    }, [leftOverlayRef.current, rightOverlayRef.current]);

    const resolveInputStyles = useMemo(() => {
      return {
        ...inputStyles,
        ...(inputPadding.leftPadding && { paddingLeft: inputPadding.leftPadding }),
        ...(inputPadding.rightPadding && { paddingRight: inputPadding.rightPadding }),
      };
    }, [inputPadding]);

    const resolveInputType = useMemo(
      () => (['text', 'number', 'password'].includes(type as string) ? type : 'text'),
      [type]
    );
    const resolveClassName = (error && 'error') || '';
    return (
      <S.InputContainer
        onClick={onClick}
        className={resolveClassName}
        disabled={disabled}
        style={style}
      >
        <S.Overlays className="left-overlay" ref={leftOverlayRef as any}>
          {prefix && <S.Prefix>{prefix}</S.Prefix>}
          {leftIcon && <S.IconContainer>{leftIcon}</S.IconContainer>}
        </S.Overlays>
        <input
          style={resolveInputStyles}
          ref={ref}
          value={value}
          {...props}
          type={resolveInputType}
          disabled={disabled}
        />
        <S.Overlays className="right-overlay" ref={rightOverlayRef as any}>
          {rightIcon && <S.IconContainer>{rightIcon}</S.IconContainer>}
          {suffix && <S.Sufix>{suffix}</S.Sufix>}
        </S.Overlays>
      </S.InputContainer>
    );
  }
);
