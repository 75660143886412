import * as React from 'react';
import { IconProps } from './types';

export const ArrowRightIcon = ({
  width = '9',
  height = '10',
  color = 'currentColor',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 9 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.94667 4.74671C7.91494 4.66487 7.86736 4.59011 7.80667 4.52671L4.47333 1.19338C4.41117 1.13122 4.33738 1.08191 4.25617 1.04827C4.17495 1.01463 4.08791 0.997314 4 0.997314C3.82247 0.997314 3.6522 1.06784 3.52667 1.19338C3.46451 1.25553 3.4152 1.32933 3.38156 1.41054C3.34792 1.49176 3.33061 1.5788 3.33061 1.66671C3.33061 1.84424 3.40113 2.01451 3.52667 2.14004L5.72667 4.33338H0.666667C0.489856 4.33338 0.320287 4.40361 0.195262 4.52864C0.070238 4.65366 0 4.82323 0 5.00004C0 5.17685 0.070238 5.34642 0.195262 5.47145C0.320287 5.59647 0.489856 5.66671 0.666667 5.66671H5.72667L3.52667 7.86004C3.46418 7.92202 3.41459 7.99575 3.38074 8.07699C3.34689 8.15823 3.32947 8.24537 3.32947 8.33337C3.32947 8.42138 3.34689 8.50852 3.38074 8.58976C3.41459 8.671 3.46418 8.74473 3.52667 8.80671C3.58864 8.86919 3.66238 8.91879 3.74362 8.95264C3.82486 8.98648 3.91199 9.00391 4 9.00391C4.08801 9.00391 4.17515 8.98648 4.25638 8.95264C4.33762 8.91879 4.41136 8.86919 4.47333 8.80671L7.80667 5.47338C7.86736 5.40997 7.91494 5.33521 7.94667 5.25338C8.01335 5.09107 8.01335 4.90902 7.94667 4.74671Z"
      fill={color}
    />
  </svg>
);
