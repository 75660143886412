import styled from 'styled-components';

export const ViewMore = styled.button`
  ${({ theme, display }: { theme: any; display: boolean }) => {
    const { colors, spacing, typography, radii } = theme.foundations;
    return `
      display: ${display ? 'none' : ''};
      margin-left: ${spacing['01']};
      margin-bottom: ${spacing['02']};
      color: ${colors.palette.blue[700]};
      font-size: ${typography.sizes['03']};
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.bold};
      background: transparent;
      cursor: pointer;
      padding: ${spacing['01']};

      :focus {
        outline: ${spacing['01']} solid ${colors.palette.neutral[400]};
        border-radius: ${radii.sm};
      }
    `;
  }}
`;
