import { useContext } from 'react';
import { useMutation } from '@apollo/client';

import { CREATE_FORM } from 'api/graphql/compliance/mutations';
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { UserContext } from 'app/users/context/UserContext';
import { FormikStub, useForm } from 'app/utils/hooks/useForm';

import { buildCAExternalForm } from './CAFormHelper';
import { CAExternallValidationSchema } from './CAFormValidation';

export type CAExternalFormType = {
  files: File[];
  firmName: string;
  signer: string;
  emailAddress: string;
  CRD: string;
  note?: string;
};

export interface IUseCAExternalHandler {
  form: FormikStub<CAExternalFormType>;
  onSubmitCAExternalForm: () => Promise<void>;
  creatingForm: boolean;
}
export const useCAExternalHandler = (): IUseCAExternalHandler => {
  const { activeDeal } = useContext(UserContext);
  const { showErrorToast } = useContext(AlertContext);

  const initialValues = {
    files: [],
    firmName: '',
    signer: '',
    emailAddress: '',
    CRD: '',
    note: '',
  };

  const form = useForm<CAExternalFormType>({
    initialValues,
    validationSchema: CAExternallValidationSchema,
  });

  const onSuccess = () => {
    form.resetForm();
  };

  const [createFormMutation, { loading: creatingForm }] = useMutation(CREATE_FORM, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      onSuccess();
    },
    onError() {
      showErrorToast({ title: 'Error', description: 'An error occurred while saving the form' });
    },
  });

  const onSubmitCAExternalForm = async (): Promise<void> => {
    const { values } = form;
    const dealId = activeDeal ? activeDeal.id : '';

    await createFormMutation({
      variables: buildCAExternalForm(values, dealId),
    });
  };

  return {
    form,
    onSubmitCAExternalForm,
    creatingForm,
  };
};
