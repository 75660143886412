import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const UserBadgerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const DisplayName = styled.p`
  font-size: 15px;
  margin-left: 10px;
`;

export const SubmitButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
`;

export const PasswordSettingStrengthContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 20px 7px 20px;
  background-color: #f8f8f8;
  margin-top: 10px;
  width: 65%;
  height: 100px;
`;

export const PasswordSettingStrengthText = styled.p`
  display: flex;
  font-size: 11px;
  margin-bottom: 13px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.palette.red.default};
  margin-top: 15px;
`;

export const WarningIcon = styled.span`
  border: 1px solid;
  width: 15px;
  height: 15px;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
  padding: 1px 1px 0 0;
  margin-right: 5px;
`;

export const BodyContainer = styled.div<{ isAccountBankBody?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: ${({ isAccountBankBody }) => (isAccountBankBody ? '40px 0px 0px 0px' : '40px')}
  height: 100%;
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 10px;
`;

export const BodySectionContainer = styled.div<{ isMain?: boolean }>`
  display: flex;
  width: 100%;
  margin-bottom: 10px;

  border-top: 1px solid
    ${({ theme, isMain }) => (isMain ? theme.colors.borders.rowSeparator : 'none')};
`;

export const Text = styled.p<{ isSubTitle: boolean; isErrorMessage?: boolean }>`
  margin: ${({ isSubTitle }) => (isSubTitle ? '15px 0 8px 0' : ' 0 0 10px 0')};
  font-size: ${({ theme, isSubTitle }) =>
    isSubTitle ? theme.fontSizes.regular : theme.fontSizes.regularSmall};
  font-weight: ${({ isSubTitle }) => (isSubTitle ? 600 : 400)};
  color: ${({ theme, isSubTitle, isErrorMessage }) => {
    if (isSubTitle) return '#000';
    if (isErrorMessage) return theme.palette.red.default;
    return theme.palette.gray.darker;
  }};
  letter-spacing: 0.25px;
  line-height: 24px;
`;

export const TextContainer = styled.div`
  margin-bottom: 5px;
  padding-left: 40px;
`;

export const TextDescriptionContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  width: 230px;
  min-height: 100%;
  padding: 30px;
  user-select: none;
  background-color: #e8e9ea;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.section};
`;

export const SectionContainer = styled.button<{ isActive: boolean }>`
  padding: 8px 30px;
  margin: 0 -30px;
  background-color: transparent;
  font-size: ${({ theme }) => theme.fontSizes.regular};
  text-align: left;

  ${({ isActive }) => {
    if (isActive) {
      return `
        background-color: #4485eb;
        color: #fff;
      `;
    }
  }};
  &:hover {
    background-color: ${({ isActive, theme }) => (!isActive ? theme.palette.gray.light : 'auto')};
  }
`;

export const CheckboxAccountBankingContainer = styled.div`
  padding-top: 20px;
`;

export const CheckboxAcountBankingLabel = styled.div`
  line-height: 20px;
  margin-left: 8px;
  font-size: 13px;
  font-weight: bold;
`;

export const InputBankSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 80%;
`;

export const SubmitButtonsBotomFixedContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-right: 64px;
  box-shadow: 5px 4px 8px 4px rgba(0, 0, 0, 0.1);
  height: 120px;
`;

export const FormAccountBankSection = styled.div`
  height: 400px;
  overflow-y: auto;
  padding: 0px 16px 0 40px;
  width: 99%;
`;

export const FormStateRegistrationSection = styled.div`
  padding: 0px 16px 0 40px;
  width: 99%;
  margin-bottom: 19px;
`;

export const LoaderContainer = styled.div`
  height: 400px;
  width: 700px;
  margin: 40px 40px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const MessageContainer = styled.div`
  margin: 14px 32px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: #e4f2ff;
  width: 581px;
`;

export const MessageTitle = styled.span`
  color: #525a7a;
  letter-spacing: 0.25px;
  line-height: 24px;
  font-weight: bold;
  font-size: 14px;
`;

export const MessageBodyContainer = styled.div`
  padding-top: 16px;
  font-size: 12px;
  color: #525a7a;
`;

export const ParagraphMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 14px;
`;

export const DotOption = styled.div`
  width: 8px;
  height: 8px;
  background-color: #2c72e8;
  border-radius: 50%;
  display: inline-block;
  margin-right: 9px;
`;

export const FooterMessageContainer = styled.div`
  padding-top: 24px;
`;

export const FooterMessageLabel = styled.p`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

export const RequiredMarker = styled.span`
  color: ${({ theme }) => theme.palette.red.default};
  font-weight: 600;
  padding-left: 5px;
`;
