import React from 'react';
import useStateModal from 'app/utils/hooks/useStateModal';
import {
  MarketingMaterialHandlerProvider,
  useMarketingMaterialFormHandler,
} from './MarketingMaterialsFormHandler';

// Components
import { Button, HStack, SideDrawer } from '../../../../../common/components';
import MarketingMaterialForm from './MarketingMaterialsForm';

const MarketingMaterialsFormDrawer = () => {
  const handler = useMarketingMaterialFormHandler();
  const { onSubmitMarketingMaterialForm, submitting, form } = handler;
  const { hide, modalState } = useStateModal('MarketingMaterialsFormDrawer');

  const hideModal = () => {
    hide();
    form.resetForm();
  };

  return (
    <MarketingMaterialHandlerProvider value={handler}>
      <SideDrawer
        show={modalState.show}
        onClose={() => hideModal()}
        title="Marketing materials"
        closeWhenClickOutside={false}
        promptOnClose={form.dirty}
        footerContent={({ onClose: closeWithPrompt }) => (
          <HStack justifyContent="flex-end" spacing={16}>
            <Button onClick={closeWithPrompt} className="tertiary">
              Dismiss
            </Button>
            <Button
              onClick={onSubmitMarketingMaterialForm}
              disabled={submitting || !form.isValid}
              style={{ width: 176 }}
            >
              Submit
            </Button>
          </HStack>
        )}
      >
        <MarketingMaterialForm />
      </SideDrawer>
    </MarketingMaterialHandlerProvider>
  );
};

export default MarketingMaterialsFormDrawer;
