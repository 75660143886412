import { AnyAction } from 'redux';
import { IState } from 'app/core-tools/due-diligence/types/diligence';

import AppStorage from 'app/appStorage';

export const HYDRATION_KEY = 'hydration';

const hydration = (action: AnyAction, root: IState) => {
  if (action.type !== '@@INIT') AppStorage.setItem(HYDRATION_KEY, root);
};

export default hydration;
