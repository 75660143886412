import React, { useContext, useEffect, useCallback } from 'react';
import get from 'lodash/get';

import { useMutation } from '@apollo/client';
import useStateModal from 'app/utils/hooks/useStateModal';

// mutations
import { CREATE_FORM } from 'api/graphql/compliance/mutations';

// context
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { DealsContext } from 'app/compliance/context/DealsContext';
import { FormsContext } from 'app/compliance/context/FormsContext';
import { FormikStub, useForm } from 'app/utils/hooks/useForm';

import { buildKYCForm, KYC_VALUE_INFERENCE_MAP } from './KYCFormHelper';
import { mapFormData } from 'app/utils/helpers/formDataMappers';

// Validations
import { validationSchema } from './KYCFormValidation';

export type KYCForm = {
  clientName: string;
  clientEmail: string;
  clientEntityName: string;
  clientTitle: string;
  transactionStructure: {
    label: string;
    value: string;
  } | null;
  otherStructure: string;
  counterpartDeal: {
    label: string;
    value: string;
  } | null;
  counterpartEntityName: string;
  counterpartEntityLegalName: string;
  contactName: string;
  contactLastName: string;
  contactEmail: string;
  contactTitle: string;
  note: string;
};

export interface UseKYCFormHandler {
  form: FormikStub<KYCForm>;
  onSubmitKYCForm: () => Promise<void>;
  submitting: boolean;
}

const initialValues = {
  clientName: '',
  clientEmail: '',
  clientEntityName: '',
  clientTitle: '',
  transactionStructure: null,
  otherStructure: '',
  counterpartDeal: null,
  counterpartEntityName: '',
  counterpartEntityLegalName: '',
  contactName: '',
  contactLastName: '',
  contactEmail: '',
  contactTitle: '',
  note: '',
};

export const KYCHandlerContext = React.createContext<UseKYCFormHandler>({} as UseKYCFormHandler);
export const KYCHandlerProvider = KYCHandlerContext.Provider;

export const useKYCFormHandler = (): UseKYCFormHandler => {
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);
  const modal = useStateModal('KYCForm');
  const {
    hide,
    modalState: { show, props },
  } = modal;
  const { selectedDeal: dealId } = useContext<any>(DealsContext);
  const { refetchAll, getFormQuery, updateFormMutation } = useContext(FormsContext);

  const [getForm, { data: formData, loading: loadingFormData }] = getFormQuery;

  const [updateForm, { loading: updatingForm }] = updateFormMutation;

  const isEdit = get<boolean>(props, 'isEdit', false);
  const formId = get<string>(props, 'formId', '');

  const form = useForm<KYCForm>({
    initialValues,
    validationSchema,
  });

  const getFormData = useCallback(async () => {
    if (formId) {
      await getForm({ variables: { id: formId } });
    }
  }, [formId]);

  useEffect(() => {
    if (show && isEdit) {
      getFormData();
    }
  }, [show]);

  useEffect(() => {
    if (formData && formData.form) {
      const mappedValues = mapFormData<KYCForm>(
        formData.form.content,
        initialValues,
        KYC_VALUE_INFERENCE_MAP,
        {
          transactionStructure: value => {
            const transactionStructure = (value && value.transactionStructure) || [];
            const otherStructure = (value && value.otherStructure) || '';
            return {
              transactionStructure,
              otherStructure,
            };
          },
        }
      );
      form.setValues(mappedValues);
    }
  }, [formData]);

  const onSuccess = () => {
    hide();
    form.resetForm();
    refetchAll(['complianceTracker', 'dealRegulation']);
  };

  const [createFormMutation, { loading: creatingForm }] = useMutation(CREATE_FORM, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      showSuccessToast({ title: 'Success!', description: 'Form was created successfully' });
      onSuccess();
    },
    onError() {
      showErrorToast({ title: 'Error', description: 'An error occurred while saving the form' });
    },
  });

  const submitting = creatingForm || loadingFormData || updatingForm;

  const onSubmitKYCForm = async (): Promise<void> => {
    const { values } = form;
    if (isEdit) {
      try {
        await updateForm({
          variables: {
            id: formId,
            content: buildKYCForm(values, dealId).content,
          },
        });
        showSuccessToast({ title: 'Success!', description: 'Form was updated successfully' });
        onSuccess();
      } catch (e) {
        showErrorToast({
          title: 'Error',
          description: 'An error occurred while updating the form',
        });
      }
    } else {
      await createFormMutation({
        variables: buildKYCForm(values, dealId),
      });
    }
  };

  return { form, onSubmitKYCForm, submitting };
};
