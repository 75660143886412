import * as React from 'react';
import { IconProps } from './types';

export const CubeIcon = ({
  width = '10px',
  height = '10px',
  color = 'currentColor',
  style,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    style={style}
    viewBox="0 0 10 10"
    fill="none"
  >
    <path
      d="M9.23507 2.68502C9.23507 2.68502 9.23507 2.68502 9.23507 2.64502L9.20507 2.57002C9.19463 2.55411 9.18293 2.53906 9.17007 2.52502C9.15669 2.50386 9.14165 2.48379 9.12507 2.46502L9.08007 2.43002L9.00007 2.39002L5.25007 0.0750247C5.17061 0.0253583 5.07878 -0.000976562 4.98507 -0.000976562C4.89136 -0.000976563 4.79954 0.0253583 4.72007 0.0750247L1.00007 2.39002L0.955073 2.43002L0.910072 2.46502C0.893498 2.48379 0.87845 2.50386 0.865073 2.52502C0.852218 2.53906 0.840514 2.55411 0.830073 2.57002L0.800072 2.64502C0.800072 2.64502 0.800072 2.64502 0.800072 2.68502C0.795158 2.72822 0.795158 2.77183 0.800072 2.81502V7.18503C0.799902 7.27 0.821391 7.35361 0.862508 7.42797C0.903626 7.50233 0.963016 7.56499 1.03507 7.61002L4.78507 9.92502C4.80815 9.93928 4.83353 9.94943 4.86007 9.95502C4.86007 9.95502 4.88507 9.95502 4.90007 9.95502C4.98466 9.98185 5.07548 9.98185 5.16007 9.95502C5.16007 9.95502 5.18507 9.95502 5.20007 9.95502C5.22662 9.94943 5.25199 9.93928 5.27507 9.92502L9.00007 7.61002C9.07213 7.56499 9.13152 7.50233 9.17264 7.42797C9.21375 7.35361 9.23524 7.27 9.23507 7.18503V2.81502C9.23999 2.77183 9.23999 2.72822 9.23507 2.68502ZM4.50007 8.60502L1.75007 6.90503V3.71502L4.50007 5.41002V8.60502ZM5.00007 4.54502L2.20007 2.81502L5.00007 1.09002L7.80007 2.81502L5.00007 4.54502ZM8.25007 6.90503L5.50007 8.60502V5.41002L8.25007 3.71502V6.90503Z"
      fill={color}
    />
  </svg>
);
