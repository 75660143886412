import React, { useState, useContext } from 'react';
import get from 'lodash/get';
import { ThemeContext } from 'styled-components';
import { EButtonType, UserBadge, OrganizationBadge } from '@dealsyte/poki';

import { UserContext } from 'app/users/context/UserContext';
import { MemberContainer, SpaceBetweenRow, Column, Row, MemberList } from '../StyledGroupsSettings';
import NewDealMembersModal from '../NewDealMembersModal/NewDealMembersModal';
import {
  DealSide as EDealSide,
  Role as ERole,
  EGroupRightLabel,
} from 'app/core-tools/due-diligence/types/types';
import ChangeUserRoleSelector from './ChangeUserRoleSelector';
import { Pill } from '../../../../../../../common/components';
import {
  AddNewMemberButton,
  EmailContainer,
  NameContainer,
  UserRole,
} from './StyledGroupMembersTable';

type IGroupMember = {
  id?: string;
  name: string;
  role?: ERole;
  email?: string;
  membersLabel?: string;
  onClick?: () => void;
};

type IProps = {
  members: IGroupMember[];
  leftLabel: string;
  rightLabel: EGroupRightLabel;
  groupName: string;
  groupId: string;
  side: EDealSide;
  refetchInvitations?: Function;
};

/**
 * Renders the members of a group in table format.
 * @param members Array of members to display.
 * @param leftLabel Left side column title of the table (e.g. -User-).
 * @param rightLabel Right side column title of the table (e.g. -Access level-).
 * @param groupName Name of the current group being rendered.
 * @param groupId ID of the current group being rendered.
 * @param side Deal side from which the table is being rendered.
 */
function GroupMembersTable({
  members,
  leftLabel,
  rightLabel,
  groupName,
  groupId,
  side,
  refetchInvitations,
}: IProps) {
  const [show, setShow] = useState<boolean>(false);

  const { id: userId, role: userRole, side: userSide, activeDeal: userActiveDeal } = useContext(
    UserContext
  );

  const theme = useContext(ThemeContext);

  const decorateUserRole = (role?: ERole): string => {
    switch (role) {
      case ERole.ADMIN:
        return 'Admin';
      case ERole.USER:
        return 'Member';
      case undefined:
        return '';
      default:
        return '';
    }
  };

  const showNewMember = userRole === ERole.ADMIN && rightLabel !== EGroupRightLabel.MEMBERS;

  return (
    <>
      {members.length === 0 ? (
        <p style={{ color: theme.palette.gray.dark }}>No members yet.</p>
      ) : (
        <>
          <SpaceBetweenRow
            style={{
              color: theme.palette.gray.dark,
              fontSize: '11px',
            }}
          >
            <span>{leftLabel}</span>
            <span>{rightLabel}</span>
          </SpaceBetweenRow>
          <MemberList>
            {members.map((member, index) => {
              const isDealOwner =
                userActiveDeal &&
                userActiveDeal.DealOwner &&
                userActiveDeal.DealOwner.id === member.id;

              return (
                <MemberContainer
                  key={`${index}-memberListItem`}
                  role={member.onClick ? 'button' : undefined}
                  tabIndex={member.onClick ? 0 : undefined}
                  onClick={member.onClick}
                >
                  <SpaceBetweenRow>
                    <Row>
                      {member.onClick ? (
                        <OrganizationBadge name={member.name} />
                      ) : (
                        <UserBadge name={member.name} />
                      )}

                      <Column
                        style={{
                          marginLeft: 10,
                          fontSize: 12,
                          justifyContent: 'center',
                        }}
                      >
                        <NameContainer>
                          {member.name}
                          {isDealOwner && <Pill label="OWNER" style={{ padding: '0 5px' }} />}
                        </NameContainer>

                        {<EmailContainer>{get(member, 'email', '')}</EmailContainer>}
                      </Column>
                    </Row>
                  </SpaceBetweenRow>

                  {get(member, 'membersLabel') ? (
                    get(member, 'membersLabel')
                  ) : get(member, 'id') &&
                    userSide === EDealSide.SELL &&
                    userRole === ERole.ADMIN &&
                    !isDealOwner &&
                    get(member, 'id') !== userId &&
                    refetchInvitations ? (
                    <ChangeUserRoleSelector
                      initialRole={get(member, 'role')}
                      userId={get(member, 'id', '')}
                      dealId={get(userActiveDeal, 'id')}
                      side={side}
                      organizationId={groupId}
                      refetchInvitations={refetchInvitations}
                    />
                  ) : (
                    <UserRole>{decorateUserRole(get(member, 'role'))}</UserRole>
                  )}
                </MemberContainer>
              );
            })}
          </MemberList>
        </>
      )}

      {showNewMember && (
        <AddNewMemberButton buttonType={EButtonType.primary} onClick={() => setShow(true)}>
          Add new members
        </AddNewMemberButton>
      )}

      <NewDealMembersModal
        organizationName={groupName}
        organizationId={groupId}
        side={side}
        show={show}
        setShow={setShow}
        dealId={get(userActiveDeal, 'id', '')}
        refetchInvitations={refetchInvitations}
      />
    </>
  );
}
GroupMembersTable.displayName = 'GroupMembersTable';

export default GroupMembersTable;
