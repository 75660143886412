import React, { Component, useContext } from 'react';
import { Query } from '@apollo/client/react/components';
import { Alert, Button, Checkbox, Input, EButtonType } from '@dealsyte/poki';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

import { GET_WATERMARK_CONFIGS } from 'api/graphql/core-tools/vdr/queries';
import {
  GET_WATERMARK_CONFIGS_QUERYVariables,
  GET_WATERMARK_CONFIGS_QUERY,
} from 'api/graphql/core-tools/vdr/types/GET_WATERMARK_CONFIGS_QUERY';
import { UserContext } from 'app/users/context/UserContext';
import { errorHandlingConstants } from 'app/core-tools/due-diligence/vdr/utils/constants';
import UpdateWatermarkContentMutation from './UpdateWatermarkContentMutation';

import {
  AllowedConfigKeys as IAllowedConfigKeys,
  Props as IProps,
  PropConfigs as IPropConfigs,
  State as IState,
} from './TypedWatermarkSettings';
import {
  SettingsDescription,
  SideSection,
  TableContainer,
  TableRow,
  TitleRow,
} from './StyledWatermarkSettings';

import { getError } from 'app/utils/helpers/helpers';

const SECTION_DESCRIPTION =
  'Define the content of the watermark that each of the files that are downloaded with this option will have.';
const CONFIG_OPTIONS = [
  {
    title: 'Watermark Title',
    description: 'Set the title that you want watermarks to have.',
  },
  {
    title: 'Body Content',
    description: 'Select which content do you want to include in the watermark body content.',
  },
];
const BODY_CONTENT_PARAMS_DIC = {
  ENABLE_USER_NAME: 'User name',
  ENABLE_USER_EMAIL: 'User email',
  ENABLE_USER_GROUP: 'User group',
  ENABLE_TIMESTAMP: 'Timestamp',
  ENABLE_DOCUMENT_NAME: 'Document name',
  ENABLE_DOCUMENT_INDEX: 'Document index',
} as { [x: string]: string };

const { UPDATE_WATERMARK_CONFIGS_ERROR_ALERT_MESSAGES } = errorHandlingConstants;

class WatermarkSettings extends Component<IProps, IState> {
  state = {
    originalTitleTextValue: '',
    currentTitleTextValue: '',
    updateConfigsErrorMessage: '',
    updateConfigsFailed: false,
  };

  componentDidMount() {
    const { configs } = this.props;
    const incomingTitleTextValue = configs.find(config => config.key === 'TEXT_LABEL');

    if (incomingTitleTextValue) {
      const { value } = incomingTitleTextValue;

      this.setState({ originalTitleTextValue: value, currentTitleTextValue: value });
    }
  }

  componentDidUpdate() {
    const { updateConfigErrorState } = this.props;

    if (updateConfigErrorState) {
      const newUpdateConfigsErrorMessage = getError(updateConfigErrorState);

      this.setState({
        updateConfigsErrorMessage: newUpdateConfigsErrorMessage,
        updateConfigsFailed: true,
      });
    }
  }

  handleTitleTextInputOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      currentTitleTextValue: (event.target as HTMLInputElement).value,
    });
  };

  setConfigState = async (key: IAllowedConfigKeys, value: string | boolean) => {
    const { updateWatermarkConfigsMutation } = this.props;
    const { currentTitleTextValue } = this.state;
    await updateWatermarkConfigsMutation(key, value);

    this.setState({ originalTitleTextValue: currentTitleTextValue });
  };

  render() {
    const [HEADER_CONFIGS, BODY_CONFIGS] = CONFIG_OPTIONS;

    const { configs, updateConfigLoadingState } = this.props;
    const {
      originalTitleTextValue,
      currentTitleTextValue,
      updateConfigsErrorMessage,
      updateConfigsFailed,
    } = this.state;
    const isChangingTitleText = originalTitleTextValue !== currentTitleTextValue;

    return (
      <>
        <SettingsDescription>{SECTION_DESCRIPTION}</SettingsDescription>

        <SideSection>
          <h5 style={{ marginBottom: 10 }}>{HEADER_CONFIGS.title}</h5>
          <SettingsDescription style={{ paddingBottom: 20 }}>
            {HEADER_CONFIGS.description}
          </SettingsDescription>
          <Input
            style={{ width: '50%' }}
            value={currentTitleTextValue}
            onChange={this.handleTitleTextInputOnChange}
          />

          <Button
            buttonType={EButtonType.primary}
            style={{ marginLeft: '10px', height: '25px' }}
            disabled={updateConfigLoadingState && isChangingTitleText}
            onClick={() => this.setConfigState('TEXT_LABEL', currentTitleTextValue)}
          >
            Save Header
          </Button>
        </SideSection>
        <SideSection>
          <h5 style={{ marginBottom: 10 }}>{BODY_CONFIGS.title}</h5>
          <SettingsDescription style={{ paddingBottom: 20 }}>
            {BODY_CONFIGS.description}
          </SettingsDescription>
          <TableContainer>
            <TitleRow>
              <div>Content type</div>
              <div>Included</div>
            </TitleRow>
            {configs.map(({ text, value, key }) =>
              typeof value === 'boolean' ? (
                <TableRow key={key}>
                  <div>{text}</div>
                  <div>
                    <Checkbox
                      type="switch"
                      onChange={() => this.setConfigState(key, !value)}
                      checked={value}
                    />
                  </div>
                </TableRow>
              ) : null
            )}
          </TableContainer>
        </SideSection>
        <Alert
          onHide={() => {
            this.setState({ updateConfigsFailed: false });
          }}
          disableActionButtons={false}
          show={updateConfigsFailed}
          content={{
            title: UPDATE_WATERMARK_CONFIGS_ERROR_ALERT_MESSAGES.title,
            description: <p>{updateConfigsErrorMessage}</p>,
          }}
          confirmAction={{
            label: UPDATE_WATERMARK_CONFIGS_ERROR_ALERT_MESSAGES.confirmAction,
            action: () => this.setState({ updateConfigsFailed: false }),
          }}
        />
      </>
    );
  }
}

const ConnectedWatermarkSettings = () => {
  const { activeDeal } = useContext(UserContext);

  return (
    <Query<GET_WATERMARK_CONFIGS_QUERY, GET_WATERMARK_CONFIGS_QUERYVariables>
      query={GET_WATERMARK_CONFIGS}
      variables={{ dealId: get(activeDeal, 'id', ''), context: 'watermark' }}
    >
      {({ loading: getConfigsLoadingState, error: getConfigsErrorState, data: configsData }) => {
        if (getConfigsLoadingState)
          return <SettingsDescription>Loading Watermark Configurations...</SettingsDescription>;

        if (getConfigsErrorState) {
          const getWatermarkConfigsErrorMessage = getError(getConfigsErrorState);

          return (
            <SettingsDescription>Error! `${getWatermarkConfigsErrorMessage}`</SettingsDescription>
          );
        }

        const configs: IPropConfigs[] =
          configsData && configsData.configs
            ? configsData.configs.map(({ key, value, type }: any) => {
                return {
                  text: BODY_CONTENT_PARAMS_DIC[key],
                  value: type === 'boolean' ? value === 'true' : value,
                  key,
                };
              })
            : [];

        return (
          <UpdateWatermarkContentMutation currentConfigs={configs}>
            {(updateConfigsMutation, updateConfigsLoadingState, updateConfigsErrorState) => {
              const sortedConfigs = sortBy(configs, '[text]');

              return (
                <WatermarkSettings
                  configs={sortedConfigs}
                  updateConfigLoadingState={updateConfigsLoadingState}
                  updateConfigErrorState={updateConfigsErrorState}
                  updateWatermarkConfigsMutation={updateConfigsMutation}
                />
              );
            }}
          </UpdateWatermarkContentMutation>
        );
      }}
    </Query>
  );
};

export default ConnectedWatermarkSettings;
