import styled from 'styled-components';

export const FormDescription = styled.div`
  ${({ theme }) => {
    const { colors, spacing, typography } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-style: normal;
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['04']};
      line-height: 150%;
      letter-spacing: 0.25px;
      color:${colors.palette.neutral['600']};
      margin-bottom: ${spacing['04']};
  `;
  }}
`;

export const FormTitle = styled.div`
  ${({ theme }) => {
    const { colors, spacing, typography } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-style: normal;
      font-weight: ${typography.weights.bold};
      font-size: ${typography.sizes['05']};
      line-height: 125%;
      letter-spacing: 0.25px;
      color:${colors.palette.neutral['600']};
      margin-bottom: ${spacing['02']};
  `;
  }}
`;

export const FormTitleDescription = styled.div`
  ${({ theme }) => {
    const { colors, spacing, typography } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-style: normal;
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['04']};
      line-height: 150%;
      letter-spacing: 0.25px;
      color:${colors.palette.neutral['600']};
      margin-bottom: ${spacing['03']};
  `;
  }}
`;

export const FormSubtitle = styled.div`
  ${({ theme }) => {
    const { colors, spacing, typography } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-style: normal;
      font-weight: ${typography.weights.bold};
      font-size: ${typography.sizes['03']};
      line-height: 150%;
      letter-spacing: 0.25px;
      color:${colors.palette.neutral['600']};
      margin-bottom: ${spacing['03']};
  `;
  }}
`;
