// vendor
import React, { useContext } from 'react';
import { Tooltip } from '@dealsyte/poki';

// Components
import {
  Checkbox,
  FormFilePicker,
  FormGroup,
  Input,
  SingleOptionRadioButtons,
  TextArea,
} from 'app/common/components';
import DatePicker from 'app/common/components/DatePicker/DatePickerPortal';
import { RoundedInfoIcon } from 'app/common/icons';

// helpers
import { formatDateInput } from 'app/utils/helpers/helpers';

// constants
import { CompensationValue } from 'app/compliance/constants/constants';

// Context
import { OBAForm as OBAFormType, OBAFormHandlerContext } from './OBAFormHandler';
import { formControlChangeHandlers } from '../../../components/FormControlChangeHandlers/FormControlChangeHandlers';
import { OBA_FORM_MESSAGES } from './OBAFormConstants';

const OBAForm = () => {
  const { form, isEdit } = useContext(OBAFormHandlerContext);
  const { values, touched, errors, setFieldValue } = form;

  const isInCompensation = (value: any) => values.compensation.includes(value);

  const compensationSetter = (field: string, value: any) => {
    const newValue = isInCompensation(value)
      ? values.compensation.filter(val => val !== value)
      : [...values.compensation, value];
    setFieldValue(field, newValue);
  };

  const { handleFieldChange: handleCompensationChange } = formControlChangeHandlers<
    Pick<OBAFormType, 'compensation'>
  >(compensationSetter);

  const checkValue = (value: CompensationValue): boolean => isInCompensation(value);

  const {
    handleFieldChange,
    handleYesNoChange,
    handleDateChange,
    handleFilesChange,
  } = formControlChangeHandlers<OBAFormType>(setFieldValue);

  return (
    <>
      <FormGroup
        id="entityName"
        label={OBA_FORM_MESSAGES.ENTITY}
        error={touched.entityName && errors.entityName}
        required
      >
        <Input
          id="entityName"
          type="text"
          name="entityName"
          placeholder={OBA_FORM_MESSAGES.ENTITY}
          onChange={handleFieldChange('entityName')}
          value={values.entityName}
        />
      </FormGroup>
      <FormGroup
        id="startDate"
        label={OBA_FORM_MESSAGES.START_DATE_LABEL}
        error={touched.startDate && errors.startDate}
        required
      >
        <DatePicker
          value={values.startDate}
          calendarPopperPosition="bottom"
          onChange={handleDateChange('startDate')}
          formatInputText={() => formatDateInput(values.startDate)}
          inputPlaceholder={OBA_FORM_MESSAGES.START_DATE_PLACEHOLDER}
          portalTarget="#drawer-portal"
        />
      </FormGroup>

      <FormGroup
        id="investmentRelated"
        label={
          <span>
            {OBA_FORM_MESSAGES.INVESTMENT_LABEL}
            <Tooltip
              message={OBA_FORM_MESSAGES.INVESTMENT_TOOLTIP}
              style={{ padding: 20, lineHeight: 1.5 }}
            >
              <RoundedInfoIcon style={{ marginLeft: 5, color: '#6A678E' }} width={11} height={11} />
            </Tooltip>
          </span>
        }
        required
      >
        <SingleOptionRadioButtons
          id="investmentRelated"
          name="investmentRelated"
          value={values.isInvestmentRelated}
          onChange={handleYesNoChange('isInvestmentRelated')}
          strictValueOnChange
        />
      </FormGroup>

      <FormGroup
        id="position"
        label={OBA_FORM_MESSAGES.POSITION_LABEL}
        error={touched.position && errors.position}
        required
      >
        <Input
          id="position"
          type="text"
          name="position"
          placeholder={OBA_FORM_MESSAGES.POSITION_PLACEHOLDER}
          onChange={handleFieldChange('position')}
          value={values.position}
        />
      </FormGroup>
      <FormGroup
        id="street"
        label={OBA_FORM_MESSAGES.ADDRESS}
        error={touched.streetAddress && errors.streetAddress}
        required
      >
        <Input
          id="street"
          type="text"
          name="street"
          placeholder={OBA_FORM_MESSAGES.ADDRESS}
          onChange={handleFieldChange('streetAddress')}
          value={values.streetAddress}
        />
      </FormGroup>
      <FormGroup
        id="city"
        label={OBA_FORM_MESSAGES.CITY}
        error={touched.city && errors.city}
        required
      >
        <Input
          id="city"
          type="text"
          name="city"
          placeholder={OBA_FORM_MESSAGES.CITY}
          onChange={handleFieldChange('city')}
          value={values.city}
        />
      </FormGroup>
      <FormGroup
        id="stateProvince"
        label={OBA_FORM_MESSAGES.STATE_PROVINCE}
        error={touched.state && errors.state}
        required
      >
        <Input
          id="stateProvince"
          type="text"
          name="stateProvince"
          placeholder={OBA_FORM_MESSAGES.STATE_PROVINCE}
          onChange={handleFieldChange('state')}
          value={values.state}
        />
      </FormGroup>
      <FormGroup
        id="postalCode"
        label={OBA_FORM_MESSAGES.POSTAL_ZIP}
        error={touched.zipCode && errors.zipCode}
        required
      >
        <Input
          id="postalCode"
          type="text"
          name="postalCode"
          placeholder={OBA_FORM_MESSAGES.POSTAL_ZIP}
          onChange={handleFieldChange('zipCode')}
          value={values.zipCode}
          maxLength={10}
        />
      </FormGroup>
      <FormGroup
        id="countryName"
        label={OBA_FORM_MESSAGES.COUNTRY}
        error={touched.country && errors.country}
        required
      >
        <Input
          id="country"
          type="text"
          name="country"
          placeholder={OBA_FORM_MESSAGES.COUNTRY}
          onChange={handleFieldChange('country')}
          value={values.country}
        />
      </FormGroup>
      <FormGroup
        id="natureOfBusiness"
        label={OBA_FORM_MESSAGES.NATURE_OF_BUSINESS}
        error={touched.natureOfBusiness && errors.natureOfBusiness}
        required
      >
        <TextArea
          id="OBABatureOfBusiness"
          name="OBABatureOfBusiness"
          placeholder={`${OBA_FORM_MESSAGES.NATURE_OF_BUSINESS}...`}
          onChange={handleFieldChange('natureOfBusiness')}
          value={values.natureOfBusiness}
        />
      </FormGroup>
      <FormGroup
        id="oba-websitesConnectedToBusiness"
        label={OBA_FORM_MESSAGES.WEBSITES_CONNECTED_LABEL}
        error={touched.websitesConnectedToBusiness && errors.websitesConnectedToBusiness}
        required
      >
        <Input
          id="oba-websitesConnectedToBusiness"
          type="text"
          name="oba-websitesConnectedToBusiness"
          placeholder={OBA_FORM_MESSAGES.WEBSITES_CONNECTED_PLACEHOLDER}
          onChange={handleFieldChange('websitesConnectedToBusiness')}
          value={values.websitesConnectedToBusiness}
        />
      </FormGroup>
      <FormGroup
        id="dutyDescription"
        label={OBA_FORM_MESSAGES.DUTY_LABEL}
        error={touched.dutyDescription && errors.dutyDescription}
        required
      >
        <TextArea
          id="dutyDescription"
          name="dutyDescription"
          placeholder={OBA_FORM_MESSAGES.DUTY_PLACEHOLDER}
          onChange={handleFieldChange('dutyDescription')}
          value={values.dutyDescription}
        />
      </FormGroup>
      <FormGroup
        id="approximateHours"
        label={OBA_FORM_MESSAGES.HOURS_AMOUNT_LABEL}
        error={touched.approximateHours && errors.approximateHours}
        required
      >
        <Input
          id="approximateHours"
          type="text"
          name="approximateHours"
          placeholder={OBA_FORM_MESSAGES.HOURS_AMOUNT_PLACEHOLDER}
          onChange={handleFieldChange('approximateHours')}
          value={values.approximateHours}
        />
      </FormGroup>
      <FormGroup
        id="devotedHours"
        label={OBA_FORM_MESSAGES.HOURS_DEVOTED_LABEL}
        error={touched.devotedHours && errors.devotedHours}
        required
      >
        <Input
          id="devotedHours"
          type="text"
          name="devotedHours"
          placeholder={OBA_FORM_MESSAGES.HOURS_DEVOTED_PLACEHOLDER}
          onChange={handleFieldChange('devotedHours')}
          value={values.devotedHours}
        />
      </FormGroup>

      <FormGroup
        id="compensatedGroup"
        label={OBA_FORM_MESSAGES.REASONABLE_COMPENSATION_LABEL}
        required
      >
        <SingleOptionRadioButtons
          id="compensatedGroup"
          name="compensatedGroup"
          value={values.hasReasonableCompensation}
          onChange={handleYesNoChange('hasReasonableCompensation')}
          strictValueOnChange
        />
      </FormGroup>

      <div
        style={{
          display: values.hasReasonableCompensation ? 'block' : 'none',
        }}
      >
        <FormGroup
          id="street"
          style={{ marginBottom: '10px' }}
          label={OBA_FORM_MESSAGES.COMPENSATION_LABEL}
          required
        >
          <Checkbox
            style={{ marginBottom: 10 }}
            id="compensationCash"
            name="compensation"
            value={CompensationValue.cash}
            onChange={handleCompensationChange('compensation')}
            checked={checkValue(CompensationValue.cash)}
            label="Cash"
          />

          <Checkbox
            style={{ marginBottom: 10 }}
            id="compensationShares"
            name="compensation"
            value={CompensationValue.shares}
            onChange={handleCompensationChange('compensation')}
            checked={checkValue(CompensationValue.shares)}
            label="Shares or membership interests"
          />

          <Checkbox
            style={{ marginBottom: 10 }}
            id="compensationWarrants"
            name="compensation"
            value={CompensationValue.warrants}
            onChange={handleCompensationChange('compensation')}
            checked={checkValue(CompensationValue.warrants)}
            label="Warrants"
          />

          <Checkbox
            style={{ marginBottom: 10 }}
            id="compensationOptions"
            name="compensation"
            value={CompensationValue.options}
            onChange={handleCompensationChange('compensation')}
            checked={checkValue(CompensationValue.options)}
            label="Options"
          />

          <Checkbox
            style={{ marginBottom: 10 }}
            id="compensationOthers"
            name="compensation"
            value={CompensationValue.others}
            onChange={handleCompensationChange('compensation')}
            checked={checkValue(CompensationValue.others)}
            label="Others"
          />
        </FormGroup>

        {isInCompensation(CompensationValue.others) && (
          <FormGroup id="othersDesc" error={touched.othersDescription && errors.othersDescription}>
            <Input
              id="othersDesc"
              type="text"
              name="othersDesc"
              placeholder={OBA_FORM_MESSAGES.OTHERS_COMPENSATION}
              onChange={handleFieldChange('othersDescription')}
              value={values.othersDescription}
            />
          </FormGroup>
        )}
      </div>

      <FormGroup id="capitalRaise" label={OBA_FORM_MESSAGES.CAPITAL_RAISE} required>
        <SingleOptionRadioButtons
          id="capitalRaise"
          name="capitalRaise"
          value={values.isCapitalRaise}
          onChange={handleYesNoChange('isCapitalRaise')}
          strictValueOnChange
        />
      </FormGroup>
      <FormGroup
        id="oba-otherLicenses"
        label={OBA_FORM_MESSAGES.OTHER_LICENSES_LABEL}
        error={touched.otherLicenses && errors.otherLicenses}
        required
      >
        <Input
          id="oba-otherLicenses"
          type="text"
          name="oba-otherLicenses"
          placeholder={OBA_FORM_MESSAGES.OTHER_LICENSES_PLACEHOLDER}
          onChange={handleFieldChange('otherLicenses')}
          value={values.otherLicenses}
        />
      </FormGroup>
      <FormGroup
        id="oba-otherInfo"
        label={OBA_FORM_MESSAGES.OTHER_INFO_LABEL}
        error={touched.otherInfo && errors.otherInfo}
      >
        <Input
          id="oba-otherInfo"
          type="text"
          name="oba-otherInfo"
          placeholder={OBA_FORM_MESSAGES.OTHER_INFO_PLACEHOLDER}
          onChange={handleFieldChange('otherInfo')}
          value={values.otherInfo}
        />
      </FormGroup>
      {!isEdit && (
        <>
          <FormGroup id="notes" label={OBA_FORM_MESSAGES.NOTES_LABEL}>
            <TextArea
              id="noteComment"
              name="noteComment"
              placeholder={OBA_FORM_MESSAGES.NOTES_PLACEHOLDER}
              onChange={handleFieldChange('noteComment')}
              value={values.noteComment}
            />
          </FormGroup>
          <FormFilePicker
            error={touched.files && errors.files}
            files={values.files}
            setFiles={handleFilesChange('files')}
            acceptedFiles=".jpg, .jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .png, image/jpeg, image/png, text/plain, .doc, .docx, .xls, .xlsx, .ppt, .pptx, application/excel, application/vnd.ms-excel, application/x-excel, application/x-msexcel, application/doc, application/ms-doc, application/msword, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
            description="DOC, DOCX, JPG, JPEG, PDF, PNG, PPT, PPTX, XLS, XLSX and plain text are supported."
          />
        </>
      )}
    </>
  );
};

export default OBAForm;
