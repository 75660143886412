import * as React from 'react';

import { IconProps } from './types';

export const FinalisIcon = ({ width = '620px', height = '120px' }: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 620 120"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M214.475 11v98h-27.683l-35.228-28.103 14.498-11.587 30.747 24.436-.083-67.505L93.031 109H64l122.792-98h27.683ZM40.684 11 75.91 39.102 61.412 50.69 30.667 26.253l.082 67.506L134.443 11h29.033L40.684 109H13V11h27.684Z"
      fill="#185ADC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M424.631 40.22c9.744 0 15.588 5.002 18.967 10.135h.519v-8.376h16.888v64.723h-16.888v-8.108h-.519c-4.027 5.539-10.134 10.269-20.267 10.269-12.081 0-28.323-10.134-28.323-34.591 0-20.808 13.772-34.052 29.623-34.052ZM282.727 11.036l.625.002v17.43h-7.05c-3.782 0-6.46 3.279-6.46 6.71v6.802h13.51v15.403h-13.51v49.32h-17.67v-49.32h-8.834V41.98h8.834V28.466c0-9.289 7.077-17.466 16.697-17.466l2.14.002c3.919.007 9.805.026 11.718.032Zm28.437 30.944v64.723h-17.538V41.979h17.538Zm50.628-2.026c14.03 0 23.774 9.727 23.774 29.589v37.16h-17.539V70.759c0-9.728-4.415-14.729-11.691-14.729-7.666 0-13.253 5.676-13.253 16.486v34.186h-17.538V41.979h17.28v8.107h.519c4.287-7.162 11.302-10.133 18.448-10.133Zm131.264-28.916v95.665h-17.539V11.037h17.539ZM428.918 55.76c-10.914 0-16.11 9.323-16.11 18.648 0 9.456 5.066 18.644 15.98 18.644 8.315 0 15.588-7.564 15.588-18.51 0-11.351-6.754-18.782-15.458-18.782ZM311.164 11.037v17.43h-17.538v-17.43h17.538Zm248.449 28.78c13.77 0 21.434 8.242 22.734 19.591h-15.458c-.391-4.592-3.248-7.296-7.148-7.296-4.157 0-6.365 2.838-6.365 5.675 0 3.65 1.95 5.945 12.472 9.728 13.122 4.46 17.927 11.351 17.927 20.674 0 11.891-8.704 20.811-24.034 20.811-15.847 0-23.382-8.648-24.034-21.216h15.72c.39 5.135 2.99 8.784 8.705 8.784 4.418 0 7.144-3.109 7.144-6.893 0-4.458-2.987-7.161-12.34-10.402-11.694-3.919-17.799-10-17.799-20.134 0-11.62 9.094-19.322 22.476-19.322Zm-34.705 2.162v64.723H507.37V41.979h17.538Zm0-30.942v17.43H507.37v-17.43h17.538Z"
      fill="#151D3C"
    />
    <path
      d="M595.283 11c-6.359 0-11.381 5.126-11.381 11.413a11.333 11.333 0 0 0 11.381 11.382 11.362 11.362 0 0 0 11.414-11.382A11.389 11.389 0 0 0 595.283 11Zm0 20.828a9.376 9.376 0 0 1-9.424-9.415c0-5.223 4.174-9.479 9.424-9.479 5.283 0 9.457 4.256 9.457 9.48 0 5.223-4.174 9.414-9.457 9.414Zm4.272-11.317c0-1.87-1.174-3.546-3.685-3.546h-4.37v11.187h2.087V24.22h1.174l2.838 3.933h2.348l-2.903-4.094c1.761-.42 2.511-1.548 2.511-3.547Zm-5.968-1.612h2.185c1.24 0 1.761.935 1.761 1.741s-.521 1.741-1.761 1.741h-2.185V18.9Z"
      fill="#151D3C"
    />
  </svg>
);
