import styled from 'styled-components';

type GridProps = {
  templateColumns: string;
  gap?: number;
  autoRows?: number;
};

export const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${({ templateColumns }) => templateColumns};
  grid-auto-rows: ${({ autoRows }) => (autoRows ? `${autoRows}px` : 'auto')};
  grid-gap: ${({ gap }) => (gap ? `${gap}px` : null)};
`;
