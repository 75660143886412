// vendors
import React from 'react';

// styles
import * as S from './CheckboxStyled';

// icons
import { CheckIcon } from '../../icons';

// type
interface CheckboxOptionProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  className?: string;
  label?: string | React.ReactNode;
}

export const Checkbox = ({ className, label, id, style, ...props }: CheckboxOptionProps) => (
  <S.CheckboxContainer className={className} style={style}>
    <S.HiddenCheckbox id={id} {...props} />
    <S.StyledCheckboxButton>
      <CheckIcon />
    </S.StyledCheckboxButton>
    {!!label && <S.CheckboxLabel htmlFor={id}>{label}</S.CheckboxLabel>}
  </S.CheckboxContainer>
);
