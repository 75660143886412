import React from 'react';

import { ResetButtonContainer } from './StyledResetButton';

interface IResetButtonProps {
  handleOnClick: () => void;
}

const ResetButton = ({ handleOnClick }: IResetButtonProps) => (
  <ResetButtonContainer onClick={handleOnClick}>
    <p>Reset to default</p>
  </ResetButtonContainer>
);

export default ResetButton;
