import React, { useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import useStateModal from 'app/utils/hooks/useStateModal';
import _get from 'lodash/get';

// mutations
import { CREATE_FORM } from 'api/graphql/compliance/mutations';

// Constants
import { DEAL_TYPES_LABELS } from 'app/utils/helpers/deal';

// context
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { UserContext } from 'app/users/context/UserContext';
import { FormsContext } from '../../../../compliance/context/FormsContext';

import { FormikStub, useForm } from 'app/utils/hooks/useForm';

// validation schema
import { validationSchema } from './EngagementLetterValidations';
import { IDeal } from 'app/utils/types/types';
import { valueToSelectInput } from 'app/utils/helpers/selectInputHelpers';
import { buildELForm } from './EngagementLetterHelper';

export type SelectValue = {
  value: string;
  label: string;
};

export type EngagementLetterForm = {
  projectName: string;
  name: string;
  type: SelectValue;
  companyName: string;
  headquarterAddress: string;
  country: string;
  state: string;
  zipCode: string;
  industryType: SelectValue;
  industryTypeSubOptions: SelectValue;
  files: File[];
  publiclyTradedCompany?: boolean;
  ticker: string;
  isInitialDueDiligence?: boolean;
  initialDueDiligenceNote: string;
  isAnyRedFlag?: boolean;
  anyRedFlagNote: string;
  isPotentialConflicts?: boolean;
  potentialConflictsNote: string;
  targetInvestors?: SelectValue;
  foreignSolicitation?: boolean;
  foreignSolicitationIntitutionalClients?: boolean;
  foreignSolicitationPreexistingRelationship: string;
  foreignSolicitationCountries?: SelectValue[];
  conclusions: string;
  note: string;
};

export interface UseEngagementLetterFormHandler {
  form: FormikStub<EngagementLetterForm>;
  onSubmitEngagementLetterForm: () => Promise<void>;
  submitting: boolean;
  modal: ReturnType<typeof useStateModal>;
}

export const EngagementLetterHandlerContext = React.createContext<UseEngagementLetterFormHandler>(
  {} as UseEngagementLetterFormHandler
);

export const EngagementLetterHandlerProvider = EngagementLetterHandlerContext.Provider;

const sanitizeErrorMessage = (message = '') => {
  return message.replace('GraphQL error:', '');
};

export const useEngagementLetterHandler = (): UseEngagementLetterFormHandler => {
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);
  const { activeDeal, refetchActiveDeal } = useContext(UserContext);
  const { refetchAll } = useContext(FormsContext);
  const modal = useStateModal('engagementLetterForm');
  const { hide, modalState } = modal;
  const initialValues: EngagementLetterForm = {
    projectName: '',
    name: '',
    type: {
      value: '',
      label: '',
    },
    companyName: '',
    headquarterAddress: '',
    country: '',
    state: '',
    zipCode: '',
    industryType: {
      value: '',
      label: '',
    },
    industryTypeSubOptions: {
      value: '',
      label: '',
    },
    files: [],
    publiclyTradedCompany: undefined,
    ticker: '',
    isInitialDueDiligence: undefined,
    initialDueDiligenceNote: '',
    isAnyRedFlag: undefined,
    anyRedFlagNote: '',
    isPotentialConflicts: undefined,
    potentialConflictsNote: '',
    targetInvestors: undefined,
    foreignSolicitation: undefined,
    foreignSolicitationIntitutionalClients: undefined,
    foreignSolicitationPreexistingRelationship: '',
    foreignSolicitationCountries: undefined,
    conclusions: '',
    note: '',
  };

  const form = useForm({
    initialValues,
    validationSchema: validationSchema(),
  });

  const setDealFormValues = () => {
    function fieldGetter<T = any>(source: T) {
      return (field: keyof T, fallback?: any) => _get(source, field, fallback);
    }
    const getField = fieldGetter<IDeal>(activeDeal as IDeal);
    const getMetadataField = fieldGetter(getField('metadata'));

    form.setValues({
      ...form.values,
      projectName: getField('projectName', ''),
      name: getField('name', ''),
      type: {
        value: getField('type'),
        label: DEAL_TYPES_LABELS[getField('type') as keyof typeof DEAL_TYPES_LABELS],
      },
      companyName: getField('companyName'),
      headquarterAddress: getField('companyAddress'),
      country: getMetadataField('country'),
      state: getMetadataField('state'),
      zipCode: getMetadataField('zipCode'),
      industryType: valueToSelectInput(getMetadataField('industryType')),
      industryTypeSubOptions: valueToSelectInput(getMetadataField('industryTypeSubOptions')),
    });
  };

  useEffect(() => {
    if (activeDeal) {
      setDealFormValues();
    }
  }, [activeDeal, modalState.show]);

  const creationSuccess = () => {
    showSuccessToast({ title: 'Success!', description: 'Form was created successfully' });
    form.resetForm();
    hide();
    refetchAll(['complianceTracker', 'dealRegulation']);
    refetchActiveDeal && refetchActiveDeal();
  };

  const [createFormMutation, { loading: loadingForm }] = useMutation(CREATE_FORM, {
    onCompleted() {
      creationSuccess();
    },
    onError() {
      showErrorToast({ title: 'Error', description: 'An error occurred while saving the form' });
    },
  });

  const onSubmitEngagementLetterForm = async (): Promise<void> => {
    const dealId = _get(activeDeal, 'id', '');
    try {
      await createFormMutation({
        variables: buildELForm(form, dealId),
      });
    } catch (e) {
      showErrorToast({
        title: 'Error',
        description: sanitizeErrorMessage(e && _get(e, 'message')),
      });
    }
  };

  return {
    form,
    onSubmitEngagementLetterForm,
    submitting: loadingForm,
    modal,
  };
};
