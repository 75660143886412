import * as React from 'react';
import { IconProps } from './types';

export const MenuIcon = ({
  color = 'currentColor',
  width = '4px',
  height = '12px',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 4 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.99996 2.66667C2.26367 2.66667 2.52145 2.58847 2.74072 2.44196C2.95999 2.29545 3.13088 2.08721 3.2318 1.84358C3.33272 1.59994 3.35912 1.33185 3.30767 1.07321C3.25623 0.814572 3.12924 0.576995 2.94277 0.390525C2.7563 0.204055 2.51872 0.0770672 2.26008 0.0256202C2.00144 -0.0258267 1.73335 0.000577708 1.48972 0.101495C1.24608 0.202411 1.03784 0.373308 0.891334 0.592574C0.744825 0.811839 0.666627 1.06963 0.666627 1.33333C0.666627 1.68696 0.807103 2.02609 1.05715 2.27614C1.3072 2.52619 1.64634 2.66667 1.99996 2.66667ZM1.99996 9.33333C1.73625 9.33333 1.47847 9.41153 1.2592 9.55804C1.03993 9.70455 0.869037 9.91279 0.768121 10.1564C0.667204 10.4001 0.640799 10.6681 0.692246 10.9268C0.743693 11.1854 0.870681 11.423 1.05715 11.6095C1.24362 11.7959 1.4812 11.9229 1.73984 11.9744C1.99848 12.0258 2.26657 11.9994 2.5102 11.8985C2.75384 11.7976 2.96208 11.6267 3.10859 11.4074C3.25509 11.1882 3.33329 10.9304 3.33329 10.6667C3.33329 10.313 3.19282 9.97391 2.94277 9.72386C2.69272 9.47381 2.35358 9.33333 1.99996 9.33333ZM1.99996 4.66667C1.73625 4.66667 1.47847 4.74487 1.2592 4.89137C1.03993 5.03788 0.869037 5.24612 0.768121 5.48976C0.667204 5.73339 0.640799 6.00148 0.692246 6.26012C0.743693 6.51876 0.870681 6.75634 1.05715 6.94281C1.24362 7.12928 1.4812 7.25627 1.73984 7.30771C1.99848 7.35916 2.26657 7.33276 2.5102 7.23184C2.75384 7.13092 2.96208 6.96003 3.10859 6.74076C3.25509 6.52149 3.33329 6.26371 3.33329 6C3.33329 5.64638 3.19282 5.30724 2.94277 5.05719C2.69272 4.80714 2.35358 4.66667 1.99996 4.66667Z"
      fill={color}
    />
  </svg>
);
