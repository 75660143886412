import styled from 'styled-components';

export const ResetButtonContainer = styled.div`
  ${({ theme }) => {
    const { colors, spacing, typography } = theme.foundations;
    return `
      cursor: pointer;
      display: inline-block;
      font-size: ${typography.sizes['04']};
      font-family: ${typography.fonts.body};
      color: ${colors.palette.blue['700']};
      line-height: ${spacing['04']};
      letter-spacing: 0.25px;

      &.middle {
        margin-left: 5px;
        margin-right: 5px;
        vertical-align: text-top;
      }
  `;
  }}
`;
