import styled, { DefaultTheme } from 'styled-components';

export const PaginationWrapper = styled.div`
  ${({ theme }: { theme: DefaultTheme; selected?: boolean }) => {
    const { spacing } = theme.foundations;
    return `
    margin-bottom: ${spacing['04']};
    display: flex;
    align-items: center;
    justify-content: center;`;
  }}
`;

export const Page = styled.span`
  ${({ theme, selected }: { theme: DefaultTheme; selected?: boolean }) => {
    const { spacing, typography, colors, radii } = theme.foundations;
    return `
      padding: ${spacing['02']};
			background:${selected ? '#ededf7' : '#fff'};
      border-radius: ${radii.md};
			cursor: pointer;
      color: ${colors.palette.neutral['800']};
      font-size: ${typography.sizes['04']};
      font-style: normal;
      font-weight: ${typography.weights.regular};
  `;
  }}
`;

export const ChevronWrapper = styled.div`
  ${({ disabled }: { disabled?: boolean }) => `
      cursor:${!disabled && 'pointer'};
`}
`;
