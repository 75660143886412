import React, { useEffect, useRef, useState } from 'react';
import { ExclamationIcon } from 'app/common/icons';

// Style
import * as S from './FormGroupStyled';

interface FormGroupAdditionalProps {
  onValueSet: (value: string | number | string[] | undefined) => void;
}

interface FormGroupProps {
  id?: string;
  children?:
    | React.ReactNode
    | ((
        ref: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement | undefined>,
        additionalProps: FormGroupAdditionalProps
      ) => React.ReactNode);
  label?: string | React.ReactNode;
  helperText?: string | React.ReactNode;
  error?: string;
  required?: boolean;
  optional?: boolean;
  width?: string;
  style?: React.CSSProperties;
  showCharCount?: boolean;
}

export const FormGroup = ({
  id,
  label,
  error,
  helperText,
  required,
  optional,
  width,
  style,
  showCharCount,
  children,
}: FormGroupProps) => {
  const inputRef = useRef<HTMLTextAreaElement | HTMLInputElement>();
  const [inputState, setInputState] = useState({ charLength: 0, maxLength: 0 });

  useEffect(() => {
    const listener = (evt: any) => {
      setInputState({
        charLength: evt.target && evt.target.value.length,
        maxLength: evt.target && evt.target.maxLength,
      });
    };

    if (inputRef.current) {
      const input = inputRef.current;
      input.addEventListener('keyup', listener);
      input.addEventListener('change', listener);
    }
    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('keyup', listener);
        inputRef.current.removeEventListener('change', listener);
      }
    };
  }, [inputRef.current, setInputState]);

  const onValueSet = (value: string | number | string[] | undefined) => {
    if (inputRef.current) {
      setInputState({
        charLength: value ? String(value).length : 0,
        maxLength: inputRef.current.maxLength,
      });
    }
  };

  return (
    <S.FormGroupContainer className={(error && 'error') || ''} style={style} width={width} id={id}>
      {!!label && (
        <S.FormGroupLabel htmlFor={id}>
          {label}
          {required && <S.RequiredLabel>(required)</S.RequiredLabel>}
          {optional && <S.RequiredLabel>(optional)</S.RequiredLabel>}
        </S.FormGroupLabel>
      )}
      {typeof children === 'function' ? children(inputRef, { onValueSet }) : children}
      <S.FormGroupFooter>
        <S.FormGroupFooterLeftSection>
          {error && (
            <S.ErrorTextContainer>
              <ExclamationIcon style={{ marginRight: 8 }} />
              {error}
            </S.ErrorTextContainer>
          )}
          {helperText && !error && <S.HelperTextContainer>{helperText}</S.HelperTextContainer>}
        </S.FormGroupFooterLeftSection>
        <S.FormGroupFooterRightSection>
          {showCharCount && (
            <S.HelperTextContainer>
              {inputState.charLength} {inputState.maxLength > 0 && `/ ${inputState.maxLength}`}
            </S.HelperTextContainer>
          )}
        </S.FormGroupFooterRightSection>
      </S.FormGroupFooter>
    </S.FormGroupContainer>
  );
};
