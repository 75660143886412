import { useContext, useState } from 'react';
import { QueryResult, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';

import { DealSide, ERoutePatterns } from 'app/core-tools/due-diligence/types/types';
import useStateModal from '../../../utils/hooks/useStateModal';
import { IForm } from '../../../compliance/types/formsTypes';
import { getComplianceTrackerReports } from './ComplianceTrackerConstants';
import { GET_PAGINATED_FORMS_BY_DEAL } from '../../../../api/graphql/compliance/queries';
import {
  IFilterQuery,
  IPageQuery,
  IPaginationInfo,
} from '../../../compliance/context/TypedFilterQuery';
import { UserContext } from '../../../users/context/UserContext';

const DEAL_FORMS = ['EL', 'MMAT', 'DRA', 'KYC', 'CDOC', 'INV'];
const DEAL_FORM_FILTERS = DEAL_FORMS.map(type => ({ field: 'type', value: type }));

export const useComplianceTrackerHandler = () => {
  const { side } = useContext(UserContext);
  const history = useHistory();

  const [dealId, setDealId] = useState<string | undefined>();
  const [filters, setFilterQuery] = useState<IFilterQuery>({});
  const [{ page, pageSize }, setPageQuery] = useState<IPageQuery>({ page: 1, pageSize: 10000 });

  const complianceTrackerQuery = useQuery<{ form: IForm[] }>(GET_PAGINATED_FORMS_BY_DEAL, {
    fetchPolicy: 'no-cache',
    skip: !dealId || side !== DealSide.SELL,
    variables: {
      dealId,
      ...filters,
      sort: filters.sortParams,
      page,
      pageSize,
    },
    notifyOnNetworkStatusChange: true,
  });

  const { globalShow } = useStateModal();

  const getReports = getComplianceTrackerReports({
    viewDetailsAction: form => () => {
      history.push(`${ERoutePatterns.COMPLIANCE_TRACKER}/form/${form.id}`, {
        from: ERoutePatterns.COMPLIANCE_TRACKER,
      });
    },
    completeAction: (_, modalProps) => () => {
      globalShow(modalProps.key, modalProps.props);
    },
    editAction: (form, modalProps) => () => {
      globalShow(modalProps.key, { ...modalProps.props, isEdit: true, formId: form.id });
    },
  });

  const paginationInfo = (query: QueryResult<{ form: IForm[] }>): IPaginationInfo => ({
    current: page,
    size: pageSize,
    totalPages: _get(query, 'data.getPaginatedFormsByDeal.totalPages'),
    totalRecord: _get(query, 'data.getPaginatedFormsByDeal.totalRecords'),
  });

  return {
    query: {
      ...complianceTrackerQuery,
      getReports,
      setDealId,
    },
    pagination: {
      ...paginationInfo(complianceTrackerQuery),
      setPageQuery,
    },
    filters: {
      ...filters,
      DEAL_FORM_FILTERS,
      setFilterQuery,
    },
  };
};
