import { IForm } from '../../../types/formsTypes';
import { FormStepStatus } from '../DealRegulationScreen/DealRegulationFormCard';
import { WORKFLOW_RULES } from './DealRegulationWorkflowRules';
import { MADealWorkflowConfig, RuleConfig, WorkflowStatuses } from './DealRegulationWorkflowTypes';

export const checkFormType = (key: string | string[]) => (form: IForm) => {
  const isArray = Array.isArray(key);
  if (isArray) {
    return key.includes(form.type);
  }
  return key === form.type;
};

export const segregatedForms = (forms: IForm[], identifier: string | string[]) => {
  return forms.filter(checkFormType(identifier));
};

export const executeRules = (
  rulesConfig: RuleConfig[],
  context: MADealWorkflowConfig,
  workflow: MADealWorkflowConfig[]
) => {
  if (!rulesConfig.length) return false;
  return rulesConfig.every(rule => {
    const ruleFn = WORKFLOW_RULES[rule.rule];
    return ruleFn(rule.options as any)(context, workflow);
  });
};

export const extractRulesResult = (
  context: MADealWorkflowConfig,
  workflow: MADealWorkflowConfig[]
): [WorkflowStatuses, boolean][] => {
  const rules = context.rules || {};
  const statuses = (Object.keys(rules) || []) as WorkflowStatuses[];
  return statuses.map(status => {
    const rulesArray = rules[status];
    const result = executeRules(rulesArray, context, workflow);
    return [status, result];
  });
};

export const customRulesExecutor = (
  rules: Record<string, RuleConfig[]>,
  context: MADealWorkflowConfig,
  workflow: MADealWorkflowConfig[]
): Record<string, boolean> => {
  const ruleKeys = Object.keys(rules);
  return ruleKeys.reduce((prev, curr) => {
    const ruleConfig = rules[curr];
    const ruleResult = executeRules(ruleConfig, context, workflow);
    return {
      ...prev,
      [curr]: ruleResult,
    };
  }, {});
};

export const inferStatus = (
  rulesResult: ReturnType<typeof extractRulesResult>
): WorkflowStatuses => {
  const index = rulesResult.findIndex(([_, result]) => !!result);
  if (index < 0) {
    return FormStepStatus.BLOCKED;
  }
  return rulesResult[index][0];
};

export const getStatus = (context: MADealWorkflowConfig, workflow: MADealWorkflowConfig[]) => {
  const results = extractRulesResult(context, workflow);
  return inferStatus(results);
};
