// vendor
import React, { useContext } from 'react';
import get from 'lodash/get';

// components
import { Input, FormGroup, Select, TextArea } from 'app/common/components';

import { Text, Label } from './KYCFormStyled';
import {
  KYCLabels,
  dealCounterpart,
  transactionStructures,
  placeholders,
} from './KYCFormConstants';
import { KYCHandlerContext, KYCForm as KYCtype } from './KYCFormHandler';

// Change Handler
import { formControlChangeHandlers } from '../../FormControlChangeHandlers/FormControlChangeHandlers';

const KYCForm = () => {
  const { form } = useContext(KYCHandlerContext);
  const { values, setFieldValue, touched, errors } = form;

  const { handleFieldChange, handleSelectChange } = formControlChangeHandlers<KYCtype>(
    setFieldValue
  );

  const renderKYCIntro = () => (
    <>
      <Text>
        <ins>Completing this form requires 3 steps:</ins>
      </Text>
      <Text>
        Step 1: Fill out questions 1-4 in <b>Section A</b>—information about <b>you</b> and{' '}
        <b>your firm</b>.
        <br />
        Step 2: Fill out question 5 in <b>Section B</b>—information about the <b>deal type</b>.
        <br />
        Step 3: Fill out questions 6-9 in <b>Section C</b>—information about your{' '}
        <b>deal counterparts</b> (We will send a questionnaire to each of the counterparts.)
      </Text>
      <Text>
        <b>IMPORTANT:</b> This form needs to be filled out{' '}
        <ins>for ALL counterparts involved in the deal transaction</ins>—e.g., issuer/target,
        acquirer/investor, and any other parties. (
        <b>Repeat entries of this form for each counterpart.</b>)
      </Text>
    </>
  );

  return (
    <>
      {renderKYCIntro()}
      <Text>{KYCLabels.SECTION_A}</Text>
      <FormGroup
        id="clientName"
        required
        label={KYCLabels.NAME}
        error={touched.clientName && errors.clientName}
      >
        <Input
          id="clientName"
          name="clienName"
          value={values.clientName}
          onChange={handleFieldChange('clientName')}
          placeholder={placeholders.NAME}
        />
      </FormGroup>

      <FormGroup
        id="clientEmail"
        required
        label={KYCLabels.EMAIL}
        error={touched.clientEmail && errors.clientEmail}
      >
        <Input
          id="clientEmail"
          name="clientEmail"
          value={values.clientEmail}
          onChange={handleFieldChange('clientEmail')}
          placeholder={placeholders.EMAIL}
        />
      </FormGroup>

      <FormGroup
        id="clientEntityName"
        required
        label={KYCLabels.ENTITY_NAME}
        error={touched.clientEntityName && errors.clientEntityName}
      >
        <Input
          id="clientEntityName"
          name="clientEntityName"
          value={values.clientEntityName}
          onChange={handleFieldChange('clientEntityName')}
          placeholder={placeholders.ENTITY_NAME}
        />
      </FormGroup>

      <FormGroup
        id="clientTitle"
        required
        label={KYCLabels.TITLE}
        error={touched.clientTitle && errors.clientTitle}
      >
        <Input
          id="clientTitle"
          name="clientTitle"
          value={values.clientTitle}
          onChange={handleFieldChange('clientTitle')}
          placeholder={placeholders.TITLE}
        />
      </FormGroup>

      <Text>{KYCLabels.SECTION_B}</Text>

      <FormGroup
        label={KYCLabels.TRANSACTION_STRUCTURE}
        id="transactionStructure"
        required
        error={touched.transactionStructure && errors.transactionStructure}
      >
        <Select
          options={transactionStructures}
          inputId="transactionStructure"
          name="transactionStructure"
          onChange={handleSelectChange('transactionStructure')}
          value={values.transactionStructure}
          maxMenuHeight={200}
          isSearchable={false}
        />
      </FormGroup>

      {get(values, 'transactionStructure.value', '') === 'Other' && (
        <FormGroup id="otherStructure">
          <Input
            id="otherStructure"
            name="otherStructure"
            placeholder={placeholders.OTHER_STRUCTURE}
            onChange={handleFieldChange('otherStructure')}
            value={values.otherStructure}
          />
        </FormGroup>
      )}
      <Text>{KYCLabels.SECTION_C}</Text>
      <FormGroup
        label={KYCLabels.COUNTERPART_DEAL}
        id="counterpartDeal"
        required
        error={touched.counterpartDeal && errors.counterpartDeal}
      >
        <Select
          options={dealCounterpart}
          inputId="counterpartDeal"
          name="counterpartDeal"
          onChange={handleSelectChange('counterpartDeal')}
          value={values.counterpartDeal}
          maxMenuHeight={200}
          isSearchable={false}
        />
      </FormGroup>
      <FormGroup
        id="counterpartEntityName"
        required
        label={KYCLabels.COUNTERPART_ENTITY_NAME}
        error={touched.counterpartEntityName && errors.counterpartEntityName}
      >
        <Input
          id="counterpartEntityName"
          name="counterpartEntityName"
          value={values.counterpartEntityName}
          onChange={handleFieldChange('counterpartEntityName')}
          placeholder={placeholders.COUNTERPART_ENTITY_NAME}
        />
      </FormGroup>
      <FormGroup
        id="counterpartEntityLegalName"
        required
        label={KYCLabels.COUNTERPART_ENTITY_LEGAL_NAME}
        error={touched.counterpartEntityLegalName && errors.counterpartEntityLegalName}
      >
        <Input
          id="counterpartEntityLegalName"
          name="counterpartEntityLegalName"
          value={values.counterpartEntityLegalName}
          onChange={handleFieldChange('counterpartEntityLegalName')}
          placeholder={placeholders.COUNTERPART_ENTITY_LEGAL_NAME}
        />
      </FormGroup>
      <Label>{KYCLabels.COUNTERPART_CONTACT_INFO}</Label>
      <FormGroup
        id="contactName"
        required
        label={KYCLabels.FIRST_NAME}
        error={touched.contactName && errors.contactName}
      >
        <Input
          id="contactName"
          name="contactName"
          value={values.contactName}
          onChange={handleFieldChange('contactName')}
          placeholder={placeholders.FIRST_NAME}
        />
      </FormGroup>
      <FormGroup
        id="contactLastName"
        required
        label={KYCLabels.LAST_NAME}
        error={touched.contactLastName && errors.contactLastName}
      >
        <Input
          id="contactLastName"
          name="contactLastName"
          value={values.contactLastName}
          onChange={handleFieldChange('contactLastName')}
          placeholder={placeholders.LAST_NAME}
        />
      </FormGroup>
      <FormGroup
        id="contactEmail"
        required
        label={KYCLabels.CONTACT_EMAIL}
        error={touched.contactEmail && errors.contactEmail}
      >
        <Input
          id="contactEmail"
          name="contactEmail"
          value={values.contactEmail}
          onChange={handleFieldChange('contactEmail')}
          placeholder={placeholders.CONTACT_EMAIL}
        />
      </FormGroup>
      <FormGroup
        id="contactTitle"
        required
        label={KYCLabels.CONTACT_TITLE}
        error={touched.contactTitle && errors.contactTitle}
      >
        <Input
          id="contactTitle"
          name="contactTitle"
          value={values.contactTitle}
          onChange={handleFieldChange('contactTitle')}
          placeholder={placeholders.CONTACT_TITLE}
        />
      </FormGroup>
      <FormGroup label={KYCLabels.COMMENTS} required error={touched.note && errors.note}>
        <TextArea
          placeholder="Comments..."
          value={values.note}
          onChange={handleFieldChange('note')}
        />
      </FormGroup>
    </>
  );
};

export default KYCForm;
