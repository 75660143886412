import React, { useContext } from 'react';

// Constants
import { countries } from 'app/utils/helpers/countries';
import {
  DEAL_FORM_LABELS,
  DEAL_TARGET_INVESTOR_OPTIONS,
  FOREIGN_SOLICITATION_PREEXISTING_RELATIONSHIP_OPTIONS,
} from 'app/utils/helpers/deal';
import { EngagementLetterInfoText } from '../NewDealForm/NewDealFormTexts';

// Components
import {
  Alert,
  FormFilePicker,
  FormGroup,
  SingleOptionRadioButtons,
  Input,
  TextArea,
  Select,
} from '../../../../common/components';

import * as S from '../NewDealForm/NewDealFormStyled';

// Context
import { EngagementLetterHandlerContext, EngagementLetterForm } from './EngagementLetterHandler';

// Helpers
import { formControlChangeHandlers } from '../../FormControlChangeHandlers/FormControlChangeHandlers';

const EngagementLetter = () => {
  const { form } = useContext(EngagementLetterHandlerContext);
  const { values, touched, errors, setFieldValue } = form;

  const {
    handleYesNoChange,
    handleFilesChange,
    handleSelectChange,
    handleFieldChange,
  } = formControlChangeHandlers<EngagementLetterForm>(setFieldValue);

  const handlePubliclyTradedCompany = (isPubliclyTraded: boolean) => {
    handleYesNoChange('publiclyTradedCompany')(isPubliclyTraded);
    if (!isPubliclyTraded) {
      setFieldValue('ticker', '');
    }
  };

  return (
    <>
      <S.NewDealSubtitle>
      Upload an Engagement Letter, Finder's Fee, or Placement Agreement below. Our team will review it, suggest edits where necessary, and return it for your signature.
      </S.NewDealSubtitle>
      <Alert
        text={
          <S.AlertText>
            You must submit <strong>unsigned</strong> Word documents at this stage. We also
            recommend that you submit the draft document to Finalis before sending it to your
            client.
          </S.AlertText>
        }
      />
      <FormGroup label="Upload the document" required>
        <FormFilePicker
          setFiles={handleFilesChange('files')}
          files={values.files}
          acceptedFiles=".jpg, .jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .png, image/jpeg, image/png, text/plain, .doc, .docx, .xls, .xlsx, .ppt, .pptx, application/excel, application/vnd.ms-excel, application/x-excel, application/x-msexcel, application/doc, application/ms-doc, application/msword, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
          description="DOC, DOCX, JPG, JPEG, PDF, PNG, PPT, PPTX, XLS, XLSX and plain text are supported."
        />
      </FormGroup>
      <S.NewDealTitle>{EngagementLetterInfoText.ADDITIONAL_QUESTIONS_TITLE}</S.NewDealTitle>
      <S.NewDealSubtitle>
        {EngagementLetterInfoText.ADDITIONAL_QUESTIONS_SUBTITLE}
      </S.NewDealSubtitle>
      <S.SpaceBetweenContainer>
        <div>
          <FormGroup label={DEAL_FORM_LABELS.PUBLICLY_TRADED_LABEL} required>
            <SingleOptionRadioButtons
              strictValueOnChange
              value={values.publiclyTradedCompany}
              id="publiclyTradedCompany"
              name="publiclyTradedCompany"
              onChange={handlePubliclyTradedCompany}
            />
          </FormGroup>
        </div>
        <div>
          <FormGroup
            label={DEAL_FORM_LABELS.TICKER_EXCHANGE_LABEL}
            required={values.publiclyTradedCompany}
            error={touched.ticker && errors.ticker}
          >
            <Input
              disabled={!values.publiclyTradedCompany}
              id="tickerExchange"
              name="tickerExchange"
              placeholder={EngagementLetterInfoText.TICKER_EXCHANGE_PLACEHOLDER}
              value={values.ticker}
              onChange={handleFieldChange('ticker')}
            />
          </FormGroup>
        </div>
      </S.SpaceBetweenContainer>
      <FormGroup label={DEAL_FORM_LABELS.DUE_DILIGENCE_LABEL} required>
        <SingleOptionRadioButtons
          strictValueOnChange
          value={values.isInitialDueDiligence}
          id="isInitialDueDiligence"
          name="isInitialDueDiligence"
          onChange={handleYesNoChange('isInitialDueDiligence')}
        />
      </FormGroup>
      {values.isInitialDueDiligence && (
        <FormGroup
          label={DEAL_FORM_LABELS.DUE_DILIGENCE_NOTE_LABEL}
          helperText={EngagementLetterInfoText.DUE_DILIGENCE_NOTE_HELPER}
          required
          error={touched.initialDueDiligenceNote && errors.initialDueDiligenceNote}
        >
          <TextArea
            rows={2}
            name="initialDueDiligenceNote"
            onChange={handleFieldChange('initialDueDiligenceNote')}
            value={values.initialDueDiligenceNote}
            placeholder={EngagementLetterInfoText.ANSWER_PLACEHOLDER}
            style={{ minHeight: 0 }}
          />
        </FormGroup>
      )}
      <FormGroup label={DEAL_FORM_LABELS.RED_FLAG_LABEL} required>
        <SingleOptionRadioButtons
          strictValueOnChange
          value={values.isAnyRedFlag}
          id="isAnyRedFlag"
          name="isAnyRedFlag"
          onChange={handleYesNoChange('isAnyRedFlag')}
        />
      </FormGroup>
      {values.isAnyRedFlag && (
        <FormGroup
          label={DEAL_FORM_LABELS.RED_FLAG_NOTE_LABEL}
          helperText={EngagementLetterInfoText.RED_FLAG_NOTE_HELPER}
          required
          error={touched.anyRedFlagNote && errors.anyRedFlagNote}
        >
          <TextArea
            rows={2}
            name="anyRedFlagNote"
            onChange={handleFieldChange('anyRedFlagNote')}
            value={values.anyRedFlagNote}
            placeholder={EngagementLetterInfoText.ANSWER_PLACEHOLDER}
            style={{ minHeight: 0 }}
          />
        </FormGroup>
      )}
      <FormGroup label={DEAL_FORM_LABELS.POTENTIAL_CONFLICTS_LABEL} required>
        <SingleOptionRadioButtons
          strictValueOnChange
          value={values.isPotentialConflicts}
          id="isPotentialConflicts"
          name="isPotentialConflicts"
          onChange={handleYesNoChange('isPotentialConflicts')}
        />
      </FormGroup>
      {values.isPotentialConflicts && (
        <FormGroup
          label={DEAL_FORM_LABELS.POTENTIAL_CONFLICTS_NOTE_LABEL}
          helperText={EngagementLetterInfoText.POTENTIAL_CONFLICTS_NOTE_HELPER}
          required
          error={touched.potentialConflictsNote && errors.potentialConflictsNote}
        >
          <TextArea
            rows={2}
            name="potentialConflictsNote"
            onChange={handleFieldChange('potentialConflictsNote')}
            value={values.potentialConflictsNote}
            placeholder={EngagementLetterInfoText.ANSWER_PLACEHOLDER}
            style={{ minHeight: 0 }}
          />
        </FormGroup>
      )}
      <FormGroup label={DEAL_FORM_LABELS.TARGET_INVESTOR_LABEL} required>
        <Select
          options={DEAL_TARGET_INVESTOR_OPTIONS}
          inputId="targetInvestors"
          name="targetInvestors"
          placeholder={EngagementLetterInfoText.TARGET_INVESTOR_PLACEHOLDER}
          value={values.targetInvestors}
          onChange={handleSelectChange('targetInvestors')}
          maxMenuHeight={200}
          isSearchable={false}
        />
      </FormGroup>
      <FormGroup label={DEAL_FORM_LABELS.FOREIGN_SOLICITATION_LABEL} required>
        <SingleOptionRadioButtons
          strictValueOnChange
          value={values.foreignSolicitation}
          id="foreignSolicitation"
          name="foreignSolicitation"
          onChange={handleYesNoChange('foreignSolicitation')}
        />
      </FormGroup>
      {values.foreignSolicitation && (
        <>
          <FormGroup label={DEAL_FORM_LABELS.FOREIGN_SOLICITATION_INSTITUTIONAL_CLIENTS} required>
            <SingleOptionRadioButtons
              strictValueOnChange
              value={values.foreignSolicitationIntitutionalClients}
              id="foreignSolicitationIntitutionalClients"
              name="foreignSolicitationIntitutionalClients"
              onChange={handleYesNoChange('foreignSolicitationIntitutionalClients')}
            />
          </FormGroup>
          <FormGroup
            label={DEAL_FORM_LABELS.FOREIGN_SOLICITATION_PREEXISTING_RELATIONSHIP}
            required
          >
            <SingleOptionRadioButtons
              strictValueOnChange
              value={values.foreignSolicitationPreexistingRelationship}
              id="foreignSolicitationPreexistingRelationship"
              name="foreignSolicitationPreexistingRelationship"
              onChange={handleSelectChange('foreignSolicitationPreexistingRelationship')}
              stackOrientation="vertical"
              options={FOREIGN_SOLICITATION_PREEXISTING_RELATIONSHIP_OPTIONS}
            />
          </FormGroup>
          <FormGroup label={DEAL_FORM_LABELS.FOREIGN_SOLICITATION_COUNTRIES_LABEL} required>
            <Select
              // TODO: Replace countries for query from users
              options={countries}
              inputId="foreignSolicitationCountries"
              name="foreignSolicitationCountries"
              placeholder={EngagementLetterInfoText.FOREIGN_SOLICITATION_COUNTRIES_PLACEHOLDER}
              value={values.foreignSolicitationCountries}
              onChange={handleSelectChange('foreignSolicitationCountries')}
              maxMenuHeight={200}
              isSearchable={false}
              isMulti
            />
          </FormGroup>
        </>
      )}
      <FormGroup label={DEAL_FORM_LABELS.CONCLUSION_LABEL} required>
        <TextArea
          rows={2}
          name="conclusionDescription"
          onChange={handleFieldChange('conclusions')}
          value={values.conclusions}
          placeholder={EngagementLetterInfoText.ANSWER_PLACEHOLDER}
          style={{ minHeight: 0 }}
        />
      </FormGroup>
      <FormGroup label={DEAL_FORM_LABELS.ADDITIONAL_NOTES_LABEL} optional>
        <TextArea
          rows={2}
          name="note"
          onChange={handleFieldChange('note')}
          value={values.note}
          placeholder={EngagementLetterInfoText.NOTES_PLACEHOLDER}
          style={{ minHeight: 0 }}
        />
      </FormGroup>
    </>
  );
};

export default EngagementLetter;
