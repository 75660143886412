// vendor
import React, { useContext } from 'react';

// Context
import { OBAcctFormHandlerContext, OBAcctForm as OBAcctFormType } from './OBAcctFormHandler';
import { formControlChangeHandlers } from '../../../components/FormControlChangeHandlers/FormControlChangeHandlers';

// Components
import { FormFilePicker, FormGroup, Input, Select, TextArea } from '../../../../common/components';

// Constants
import { accountTypes, OBAcctLabels } from './OBAcctFormConstants';

// Style
import { Description } from '../FormsStyled';

export const OBAcctForm = () => {
  const { form, isEdit } = useContext(OBAcctFormHandlerContext);
  const { values, setFieldValue, errors, touched } = form;
  const { handleFieldChange, handleSelectChange, handleFilesChange } = formControlChangeHandlers<
    OBAcctFormType
  >(setFieldValue);

  return (
    <>
      <Description>
        {OBAcctLabels.OBACCT_INFO}
        <br />
        <br />
        {OBAcctLabels.OBACCT_SIDENOTE}
      </Description>
      <FormGroup
        id="accountOwner"
        label={OBAcctLabels.ACC_OWNER}
        error={touched.accountOwner && errors.accountOwner}
        required
      >
        <Input
          id="accountOwner"
          name="accountOwner"
          placeholder={OBAcctLabels.ACC_OWNER_PLACEHOLDER}
          onChange={handleFieldChange('accountOwner')}
          value={values.accountOwner}
        />
      </FormGroup>
      <FormGroup
        id="accountNumber"
        label={OBAcctLabels.ACC_NUMBER}
        error={touched.accountNumber && errors.accountNumber}
        required
      >
        <Input
          id="accountNumber"
          name="accountNumber"
          placeholder={OBAcctLabels.ACC_NUMBER_PLACEHOLDER}
          onChange={handleFieldChange('accountNumber')}
          value={values.accountNumber}
        />
      </FormGroup>
      <FormGroup
        id="financialInstitutionName"
        label={OBAcctLabels.INSTITUTION_NAME}
        error={touched.financialInstitutionName && errors.financialInstitutionName}
        required
      >
        <Input
          id="financialInstitutionName"
          name="financialInstitutionName"
          placeholder={OBAcctLabels.INSTITUTION_NAME_PLACEHOLDER}
          onChange={handleFieldChange('financialInstitutionName')}
          value={values.financialInstitutionName}
        />
      </FormGroup>
      <FormGroup id="accountType" label={OBAcctLabels.ACC_TYPE} required>
        <Select
          options={accountTypes}
          inputId="accountType"
          name="accountType"
          onChange={handleSelectChange('accountType')}
          value={values.accountType}
          maxMenuHeight={200}
          placeholder={OBAcctLabels.ACC_TYPE_PLACEHOLDER}
          isSearchable={false}
        />
      </FormGroup>
      {!isEdit && (
        <>
          <FormGroup id="notes" label={OBAcctLabels.NOTES} optional>
            {ref => (
              <TextArea
                ref={ref as any}
                id="noteComment"
                name="noteComment"
                placeholder="Comments..."
                onChange={handleFieldChange('noteComment')}
                maxLength={250}
                value={values.noteComment}
              />
            )}
          </FormGroup>
          <FormFilePicker
            files={values.files}
            setFiles={handleFilesChange('files')}
            label={OBAcctLabels.FILES}
            error={errors.files}
            acceptedFiles=".jpg, .jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .png, image/jpeg, image/png, text/plain, .doc, .docx, .xls, .xlsx, .ppt, .pptx, application/excel, application/vnd.ms-excel, application/x-excel, application/x-msexcel, application/doc, application/ms-doc, application/msword, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
            description="DOC, DOCX, JPG, JPEG, PDF, PNG, PPT, PPTX, XLS, XLSX and plain text are supported."
          />
        </>
      )}
    </>
  );
};
