import * as React from 'react';

import { IconProps } from './types';

export const TrashIcon = ({
  width = '18px',
  height = '21px',
  color = 'currentColor',
  style = {},
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 16.5015C7.26522 16.5015 7.51957 16.3961 7.70711 16.2086C7.89464 16.021 8 15.7667 8 15.5015V9.50146C8 9.23625 7.89464 8.98189 7.70711 8.79436C7.51957 8.60682 7.26522 8.50146 7 8.50146C6.73478 8.50146 6.48043 8.60682 6.29289 8.79436C6.10536 8.98189 6 9.23625 6 9.50146V15.5015C6 15.7667 6.10536 16.021 6.29289 16.2086C6.48043 16.3961 6.73478 16.5015 7 16.5015ZM17 4.50146H13V3.50146C13 2.70582 12.6839 1.94275 12.1213 1.38014C11.5587 0.817535 10.7956 0.501465 10 0.501465H8C7.20435 0.501465 6.44129 0.817535 5.87868 1.38014C5.31607 1.94275 5 2.70582 5 3.50146V4.50146H1C0.734784 4.50146 0.48043 4.60682 0.292893 4.79436C0.105357 4.98189 0 5.23625 0 5.50146C0 5.76668 0.105357 6.02104 0.292893 6.20857C0.48043 6.39611 0.734784 6.50146 1 6.50146H2V17.5015C2 18.2971 2.31607 19.0602 2.87868 19.6228C3.44129 20.1854 4.20435 20.5015 5 20.5015H13C13.7956 20.5015 14.5587 20.1854 15.1213 19.6228C15.6839 19.0602 16 18.2971 16 17.5015V6.50146H17C17.2652 6.50146 17.5196 6.39611 17.7071 6.20857C17.8946 6.02104 18 5.76668 18 5.50146C18 5.23625 17.8946 4.98189 17.7071 4.79436C17.5196 4.60682 17.2652 4.50146 17 4.50146ZM7 3.50146C7 3.23625 7.10536 2.98189 7.29289 2.79436C7.48043 2.60682 7.73478 2.50146 8 2.50146H10C10.2652 2.50146 10.5196 2.60682 10.7071 2.79436C10.8946 2.98189 11 3.23625 11 3.50146V4.50146H7V3.50146ZM14 17.5015C14 17.7667 13.8946 18.021 13.7071 18.2086C13.5196 18.3961 13.2652 18.5015 13 18.5015H5C4.73478 18.5015 4.48043 18.3961 4.29289 18.2086C4.10536 18.021 4 17.7667 4 17.5015V6.50146H14V17.5015ZM11 16.5015C11.2652 16.5015 11.5196 16.3961 11.7071 16.2086C11.8946 16.021 12 15.7667 12 15.5015V9.50146C12 9.23625 11.8946 8.98189 11.7071 8.79436C11.5196 8.60682 11.2652 8.50146 11 8.50146C10.7348 8.50146 10.4804 8.60682 10.2929 8.79436C10.1054 8.98189 10 9.23625 10 9.50146V15.5015C10 15.7667 10.1054 16.021 10.2929 16.2086C10.4804 16.3961 10.7348 16.5015 11 16.5015Z"
      fill={color}
    />
  </svg>
);
