import React, { useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import useStateModal from 'app/utils/hooks/useStateModal';

// mutations
import { CREATE_FORM } from 'api/graphql/compliance/mutations';

// context
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { FormsContext } from '../../../context/FormsContext';

// Helpers
import { buildOBAcctForm, OBACCT_VALUE_INFERENCE_MAP } from './OBAcctFormHelpers';
import { mapFormData } from '../../../../utils/helpers/formDataMappers';

// Hooks
import { useForm, FormikStub } from '../../../../utils/hooks/useForm';

// Validations
import { validationSchema } from './OBAcctFormValidations';

export type SelectValue = {
  value: string;
  label: string;
};

export type OBAcctForm = {
  accountOwner: string;
  accountNumber: string;
  financialInstitutionName: string;
  accountType: SelectValue | null;
  files: File[];
  noteComment: string;
};

const initialValues = {
  accountOwner: '',
  accountNumber: '',
  financialInstitutionName: '',
  accountType: null,
  noteComment: '',
  files: [],
};

export interface UseOBAcctFormHandler {
  form: FormikStub<OBAcctForm>;
  onSubmitOBAcctForm: () => Promise<void>;
  submitting: boolean;
  modal: ReturnType<typeof useStateModal>;
  isEdit: boolean;
}

export const OBAcctFormHandlerContext = React.createContext<UseOBAcctFormHandler>(
  {} as UseOBAcctFormHandler
);
export const OBAcctFormHandlerProvider = OBAcctFormHandlerContext.Provider;

export const useOBAcctFormHandler = (): UseOBAcctFormHandler => {
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);
  const modal = useStateModal('OBAcctFormDrawer');
  const {
    hide,
    modalState: { props, show },
  } = modal;
  const {
    regulatoryProfile,
    regulatoryFormsCount,
    getFormQuery,
    updateFormMutation,
    attestationFormsQuery,
  } = useContext(FormsContext);
  const { refetch: refetchOBAcctForms } = attestationFormsQuery;
  const { refetch } = regulatoryProfile.query;
  const { refetchOBAccCount } = regulatoryFormsCount;
  const [getForm, { data: formData }] = getFormQuery;
  const [updateForm] = updateFormMutation;

  const isEdit = props && props.isEdit;
  const formId = props && props.formId;

  const form = useForm<OBAcctForm>({
    initialValues,
    validationSchema,
  });

  const getFormData = async () => {
    if (formId) {
      await getForm({ variables: { id: formId } });
    }
  };

  useEffect(() => {
    if (show && isEdit) {
      getFormData();
    }
  }, [show]);

  useEffect(() => {
    if (formData && formData.form) {
      const mappedValues = mapFormData<OBAcctForm>(
        formData.form.content,
        initialValues,
        OBACCT_VALUE_INFERENCE_MAP
      );
      form.setValues(mappedValues);
    }
  }, [formData]);

  const resetAndCleanUp = () => {
    hide();
    form.resetForm();
    refetch && refetch();
    refetchOBAccCount && refetchOBAccCount();
    refetchOBAcctForms && refetchOBAcctForms();
  };

  const [createFormMutation, { loading: loadingForm }] = useMutation(CREATE_FORM, {
    onCompleted() {
      showSuccessToast({ title: 'Success!', description: 'Form was created successfully' });
      resetAndCleanUp();
    },
    onError() {
      showErrorToast({ title: 'Error', description: 'An error occurred while saving the form' });
    },
  });

  const submitting = loadingForm;

  const onSubmitOBAcctForm = async (): Promise<void> => {
    const { values } = form;
    if (isEdit) {
      await updateForm({
        variables: {
          id: formId,
          content: buildOBAcctForm(values).content,
        },
      });
      showSuccessToast({ title: 'Success!', description: 'Form was updated successfully' });
      resetAndCleanUp();
    } else {
      await createFormMutation({ variables: buildOBAcctForm(values) });
    }
  };

  return { form, onSubmitOBAcctForm, submitting, modal, isEdit };
};
