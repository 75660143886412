import React, { useContext } from 'react';

// Constants
import { countries } from '../../../../utils/helpers/countries';
import {
  DEAL_FORM_LABELS,
  DEAL_TARGET_INVESTOR_OPTIONS,
  FOREIGN_SOLICITATION_PREEXISTING_RELATIONSHIP_OPTIONS,
} from '../../../../utils/helpers/deal';
import { EngagementLetterInfoText } from '../NewDealForm/NewDealFormTexts';

// Components
import {
  Alert,
  FormFilePicker,
  FormGroup,
  SingleOptionRadioButtons,
  Input,
  TextArea,
  Select,
} from '../../../../common/components';

// Context
import { AmendmentHandlerContext, AmendmentForm } from './AmendmentHandler';

// Helpers
import { formControlChangeHandlers } from '../../FormControlChangeHandlers/FormControlChangeHandlers';

// Styles
import * as S from '../NewDealForm/NewDealFormStyled';

const Amendment = () => {
  const { form } = useContext(AmendmentHandlerContext);
  const { values, touched, errors, setFieldValue } = form;

  const {
    handleFilesChange,
    handleFieldChange,
  } = formControlChangeHandlers<AmendmentForm>(setFieldValue);

  return (
    <>
      <S.NewDealSubtitle>
        Upload the engagement document below. Our team will review it, suggest edits where necessary,
        and return it for your signature.
      </S.NewDealSubtitle>
      <Alert
        variant="warning"
        text="
            An approved Engagement Letter already exists. This submission will be considered an
            amendment of it."
      />
      <FormGroup label="Upload the document" required>
        <FormFilePicker
          setFiles={handleFilesChange('files')}
          files={values.files}
          acceptedFiles=".jpg, .jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .png, image/jpeg, image/png, text/plain, .doc, .docx, .xls, .xlsx, .ppt, .pptx, application/excel, application/vnd.ms-excel, application/x-excel, application/x-msexcel, application/doc, application/ms-doc, application/msword, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
          description="DOC, DOCX, JPG, JPEG, PDF, PNG, PPT, PPTX, XLS, XLSX and plain text are supported."
        />
      </FormGroup>
      <FormGroup
        label='Briefly explain the purpose of this amendment' 
        required
        error={touched.explanation && errors.explanation}
      >
        <TextArea
          rows={2}
          name="explanation"
          onChange={handleFieldChange('explanation')}
          value={values.explanation}
          placeholder='Write your notes'
          maxLength={300}
        />
      </FormGroup>
    </>
  );
};

export default Amendment;
