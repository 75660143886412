// Libraries
import React, { useState, useEffect, CSSProperties } from 'react';

// Icons
import { SearchIcon } from '../../../common/icons';

// Style
import { SearchBarWrapper } from './StyledSearchBar';

export interface searchProps {
  onChange: (val: string) => void;
  style?: CSSProperties;
}

export const SearchBar = ({ onChange, style }: searchProps) => {
  const [input, setInput] = useState('');

  useEffect(() => {
    onChange(input);
  }, [input]);

  return (
    <SearchBarWrapper style={style}>
      <input type="text" value={input} onChange={evt => setInput(evt.target.value)} />
      <SearchIcon />
    </SearchBarWrapper>
  );
};
