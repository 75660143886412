import React, { useContext } from 'react';

// components
import { FormFilePicker, FormGroup, Input, TextArea } from 'app/common/components';

// form handlers
import { formControlChangeHandlers } from '../../FormControlChangeHandlers/FormControlChangeHandlers';
import { CAInternalFormType } from './CAInternalFormHandler';
import { CollaborationAgreementsHandlerContext } from './CAFormHandler';

// constants
import { ACCEPTED_FILES, CA_INTERNAL_FORM_LABELS, INTERNAL_STEP } from './CAConstants';

// styles
import * as S from './CAFormStyled';

export const CAInternalForm = () => {
  const { internallHandler } = useContext(CollaborationAgreementsHandlerContext);
  const { values, setFieldValue, touched, errors } = internallHandler.form;

  const { handleFilesChange, handleFieldChange } = formControlChangeHandlers<CAInternalFormType>(
    setFieldValue
  );
  return (
    <>
      <S.FormDescription>{INTERNAL_STEP.description}</S.FormDescription>
      <FormGroup label={CA_INTERNAL_FORM_LABELS.FILES} required>
        <FormFilePicker
          setFiles={handleFilesChange('files')}
          files={values.files}
          acceptedFiles={ACCEPTED_FILES}
          multiple={false}
          description="DOC, DOCX, JPG, JPEG, PDF, PNG, PPT, PPTX, XLS, XLSX and plain text are supported."
        />
      </FormGroup>
      <S.FormTitle>{INTERNAL_STEP.title}</S.FormTitle>
      <S.FormTitleDescription>{INTERNAL_STEP.titleDescription}</S.FormTitleDescription>
      <FormGroup
        required
        label={CA_INTERNAL_FORM_LABELS.FIRM_NAME}
        error={touched.firmName && errors.firmName}
      >
        <Input
          id="firmName"
          name="firmName"
          value={values.firmName}
          onChange={handleFieldChange('firmName')}
          placeholder="Enter name"
        />
      </FormGroup>
      <S.FormSubtitle> {INTERNAL_STEP.subtitle}</S.FormSubtitle>
      <FormGroup
        required
        label={CA_INTERNAL_FORM_LABELS.NAME}
        error={touched.fullName && errors.fullName}
      >
        <Input
          id="fullName"
          name="fullName"
          value={values.fullName}
          onChange={handleFieldChange('fullName')}
          placeholder="Enter full name"
        />
      </FormGroup>
      <FormGroup
        required
        label={CA_INTERNAL_FORM_LABELS.EMAIL_ADDRESS}
        error={touched.emailAddress && errors.emailAddress}
      >
        <Input
          id="emailAddress"
          name="emailAddress"
          value={values.emailAddress}
          onChange={handleFieldChange('emailAddress')}
          placeholder="Enter email address"
        />
      </FormGroup>
      <FormGroup
        label={CA_INTERNAL_FORM_LABELS.NOTE}
        optional
        helperText="Enter necessary details to complete the form."
        error={touched.note && errors.note}
        showCharCount
      >
        {ref => (
          <TextArea
            ref={ref as any}
            rows={2}
            name="note"
            onChange={handleFieldChange('note')}
            value={values.note}
            placeholder="Write your notes"
            maxLength={300}
          />
        )}
      </FormGroup>
    </>
  );
};
