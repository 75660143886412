import styled, { DefaultTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from '@dealsyte/poki';

export const ErrorContainer = styled.div`
  ${({ theme }: { theme: DefaultTheme }) => {
    const { colors, radii, spacing } = theme.foundations;
    return `
    align-items: flex-start;
    background-color: ${colors.palette.red['100']};
    border-radius: ${radii.lg};
    display: flex;
    flex-direction: row;
    gap: ${spacing['02']};
    height: 74px;
    padding: ${spacing['04']}; 
    width: 100%;`;
  }}
`;

export const ErrorElementsContainer = styled.div`
  ${({ theme }: { theme: DefaultTheme }) => {
    const { spacing } = theme.foundations;
    return `
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: ${spacing['04']};
    height: 42px;`;
  }}
`;
export const ErrorIconContainer = styled.div`
  ${({ theme }: { theme: DefaultTheme }) => {
    const { sizes, spacing } = theme.foundations;
    return `
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: ${spacing['02']};
    height: ${sizes['06']};
    padding: 0px;
    width: ${sizes['06']}`;
  }}
`;
export const ErrorMessageContainer = styled.div`
  ${({ theme }: { theme: DefaultTheme }) => {
    const { colors, typography } = theme.foundations;
    return `
    align-items: center;
    color: ${colors.palette.red['900']};
    font-family: ${typography.fonts.body};
    font-size: ${typography.sizes['03']};
    font-style: normal;
    font-weigth: ${typography.weights.regular}
    letter-spacing: 0.25px;
    line-height: 150%;
    `;
  }}
`;

export const Submit = styled.input`
  width: 100%;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const LocalLink = styled(Link)`
  margin-left: auto;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary.default};
  font-size: ${({ theme }) => theme.fontSizes.small};

  &:hover {
    text-decoration: underline;
  }
`;

export const LinkLoginError = styled(Link)`
  ${({ theme }: { theme: DefaultTheme }) => {
    const { colors, typography } = theme.foundations;
    return `
    color: ${colors.palette.red['900']};
    font-family: ${typography.fonts.body}
    font-size: ${typography.sizes['03']}
    `;
  }}
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-width: 380px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10vh 15vw;
  width: 100%;
`;

export const BackButton = styled(Button)`
  border-radius: 24px;
  height: 48px;
  width: 266px;
  background-color: white;
  font-weight: bold;
  font-size: 14px;
  color: white;
  border: none;
  line-height: 48px;

  box-sizing: border-box;
  border: 1px solid #dee0e2;
  color: #1f2f47;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }

  & + & {
    margin-top: 5px;
  }
`;

export const LogoContainer = styled.a`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return ` 
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 70px 0;
  flex-direction: column;
  text-decoration: none;
  margin: ${spacing['03']} 0 ${spacing['05']};

  @media (max-width: 600px) {
    padding: 50px 0;
  }
  `;
  }}
`;

export const Title = styled.h4`
  display: block;
  margin-bottom: 32px;
  color: #596573;
`;

export const Paragraph = styled.p`
  color: #8c98a6;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 24px;
  margin-bottom: 24px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;
`;

// TODO: Add this button style on poki button secondary type
export const AuthProviderButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid #6f7e90;
  background-image: none;
  height: 40px;
  color: #596573;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.regular};
  margin-bottom: 12px;
`;

export const AuthProvidersSeparator = styled.div`
  display: flex;
  margin: 25px 0;
  color: #707f8f;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-transform: uppercase;

  hr {
    width: 45%;
    border: none;
    border-top: 1px solid ${({ theme }) => theme.palette.gray.lighter};
  }
`;

export const PasswordSettingStrengthContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 20px 7px 20px;
  background-color: #f8f8f8;
  margin-top: 10px;
  margin-bottom: 30px;
  height: 100px;
`;

export const PasswordSettingStrengthText = styled.p`
  display: flex;
  font-size: 11px;
  margin-bottom: 13px;
`;
