import {
  Fso as IFso,
  Organization as IOrganization,
  User as IUser,
  Group as IGroup,
  Log as ILog,
  EFileExtension,
  FsoTypes as EFsoTypes,
  LogType as ELogType,
  ConversionStatus as EConversionStatus,
  IPath,
  IUploadTreeChildren,
  EFsoPublishStatus,
  IFsoSignature,
} from '../types';

export const FSO_DEF_NAMES = {
  validName: 'Lexicon Capital Co.',
  emptyName: '',
  nSpacedName: ' '.repeat(Math.random() * 10),
  longName: 'Lexicon Capital Co. deal starting at february 19th of 2019',
};

export const ORGANIZATION = {
  id: 'c89e31b0-e203-11e8-833f-d90e569315e6',
  name: 'ECorp',
} as IOrganization;

export const USER = {
  id: 'c89e31b0-e203-11e8-833f-d90e569315e6',
  firstName: 'John',
  lastName: 'Doe',
  organization: ORGANIZATION,
  email: 'johndoe@company.com',
} as IUser;

export const FSO_PERMISSIONS = {
  upload: true,
  download_original: true,
  download_watermark: true,
  rename: true,
  move: true,
  delete: true,
  publish: true,
  unpublish: true,
};

export const FSO_SIGNATURES = [
  {
    id: 'id1',
    requesterGroupId: '0ab8d7b0-ef57-11e9-8f4c-ed5e15fe956f', // Initech Group ID
    signerGroupId: '092f2a70-ef57-11e9-8f4c-ed5e15fe956f', // Lexington Capital ID
    status: 'pending',
    url: '/file1-url',
  },
  {
    id: 'id2',
    requesterGroupId: '0ab8d7b0-ef57-11e9-8f4c-ed5e15fe956f', // Initech Group ID
    signerGroupId: '0a4380a1-ef57-11e9-8f4c-ed5e15fe956f', // Horizon Partners ID
    status: 'signed',
    url: '/file2-url',
  },
] as IFsoSignature[];

export const FSO = {
  id: '0ed9dc60-66c5-11e9-9d85-3523b4b29ec7',
  size: 3,
  displaySize: '3 KB',
  folderId: '2013-09-16 - Certificate of Incorporation',
  createdAt: '2018-11-20T14:01:50.652Z',
  updatedAt: '2018-11-20T14:01:50.673Z',
  index: '1.0.1',
  extension: EFileExtension.pdf,
  status: EFsoPublishStatus.published,
  name: '2013-09-16 - Certificate of Incorporation.pdf',
  type: EFsoTypes.file,
  childrenAmount: 0,
  owner: USER,
  trash: false,
  path: '/vdr/directories/0ed9dc60-66c5-11e9-9d85-3523b4b29ec7',
  parentId: 'parentId',
  permissions: FSO_PERMISSIONS,
  conversionStatus: EConversionStatus.CONVERTED,
  Signatures: FSO_SIGNATURES,
  children: [],
} as IFso;

export const GROUP = {
  id: 'c89e31b0-e203-11e8-833f-d90e569315e6',
  users: [USER],
  organizations: [ORGANIZATION],
} as IGroup;

export const log = {
  id: 'c89e31b0-e203-11e8-833f-d90e569315e6',
  type: ELogType.download,
  time: '2018-11-20T14:01:50.673Z',
  fileId: '2013-09-16 - Certificate of Incorporation.pdf',
  file: FSO,
  userId: 'c89e31b0-e203-11e8-833f-d90e569315e6',
  user: USER,
} as ILog;

export const FOLDER_WITH_CHILDREN = {
  ...FSO,
  childrenAmount: 3,
  type: EFsoTypes.folder,
  children: [
    { ...FSO, id: '1', name: 'children1' },
    { ...FSO, id: '2', name: 'children2' },
    { ...FSO, id: '3', name: 'children3' },
  ],
};

export const FSOS_FOR_SORTING = {
  unsortedFsos: [
    {
      ...FSO,
      index: '1.4',
      name: 'Liam Neeson',
      updatedAt: '2017-03-21T01:50:00.4Z',
      type: 'folder',
    },
    {
      ...FSO,
      index: '1.3',
      name: 'Robert De Niro',
      updatedAt: '2019-01-22T01:50:10.424Z',
      type: 'file',
    },
    {
      ...FSO,
      index: '1.1',
      name: 'Al Pacino',
      updatedAt: '2018-05-23T21:01:33.384Z',
      type: 'folder',
    },
    {
      ...FSO,
      index: '1.2',
      name: 'Clint Eastwood',
      updatedAt: '2019-05-09T00:08:47.689Z',
      type: 'file',
    },
  ],
  indexAscendingSortedFsos: [
    {
      ...FSO,
      index: '1.1',
      name: 'Al Pacino',
      updatedAt: '2018-05-23T21:01:33.384Z',
      type: 'folder',
    },
    {
      ...FSO,
      index: '1.2',
      name: 'Clint Eastwood',
      updatedAt: '2019-05-09T00:08:47.689Z',
      type: 'file',
    },
    {
      ...FSO,
      index: '1.3',
      name: 'Robert De Niro',
      updatedAt: '2019-01-22T01:50:10.424Z',
      type: 'file',
    },
    {
      ...FSO,
      index: '1.4',
      name: 'Liam Neeson',
      updatedAt: '2017-03-21T01:50:00.4Z',
      type: 'folder',
    },
  ],
  indexDescendingSortedFsos: [
    {
      ...FSO,
      index: '1.4',
      name: 'Liam Neeson',
      updatedAt: '2017-03-21T01:50:00.4Z',
      type: 'folder',
    },
    {
      ...FSO,
      index: '1.3',
      name: 'Robert De Niro',
      updatedAt: '2019-01-22T01:50:10.424Z',
      type: 'file',
    },
    {
      ...FSO,
      index: '1.2',
      name: 'Clint Eastwood',
      updatedAt: '2019-05-09T00:08:47.689Z',
      type: 'file',
    },
    {
      ...FSO,
      index: '1.1',
      name: 'Al Pacino',
      updatedAt: '2018-05-23T21:01:33.384Z',
      type: 'folder',
    },
  ],
  nameAscendingSortedFsos: [
    {
      ...FSO,
      index: '1.1',
      name: 'Al Pacino',
      updatedAt: '2018-05-23T21:01:33.384Z',
      type: 'folder',
    },
    {
      ...FSO,
      index: '1.4',
      name: 'Liam Neeson',
      updatedAt: '2017-03-21T01:50:00.4Z',
      type: 'folder',
    },
    {
      ...FSO,
      index: '1.2',
      name: 'Clint Eastwood',
      updatedAt: '2019-05-09T00:08:47.689Z',
      type: 'file',
    },
    {
      ...FSO,
      index: '1.3',
      name: 'Robert De Niro',
      updatedAt: '2019-01-22T01:50:10.424Z',
      type: 'file',
    },
  ],
  nameDescendingSortedFsos: [
    {
      ...FSO,
      index: '1.4',
      name: 'Liam Neeson',
      updatedAt: '2017-03-21T01:50:00.4Z',
      type: 'folder',
    },
    {
      ...FSO,
      index: '1.1',
      name: 'Al Pacino',
      updatedAt: '2018-05-23T21:01:33.384Z',
      type: 'folder',
    },
    {
      ...FSO,
      index: '1.3',
      name: 'Robert De Niro',
      updatedAt: '2019-01-22T01:50:10.424Z',
      type: 'file',
    },
    {
      ...FSO,
      index: '1.2',
      name: 'Clint Eastwood',
      updatedAt: '2019-05-09T00:08:47.689Z',
      type: 'file',
    },
  ],
  updatedAtAscendingSortedFsos: [
    {
      ...FSO,
      index: '1.4',
      name: 'Liam Neeson',
      updatedAt: '2017-03-21T01:50:00.4Z',
      type: 'folder',
    },
    {
      ...FSO,
      index: '1.1',
      name: 'Al Pacino',
      updatedAt: '2018-05-23T21:01:33.384Z',
      type: 'folder',
    },
    {
      ...FSO,
      index: '1.3',
      name: 'Robert De Niro',
      updatedAt: '2019-01-22T01:50:10.424Z',
      type: 'file',
    },
    {
      ...FSO,
      index: '1.2',
      name: 'Clint Eastwood',
      updatedAt: '2019-05-09T00:08:47.689Z',
      type: 'file',
    },
  ],
  updatedAtDescendingSortedFsos: [
    {
      ...FSO,
      index: '1.1',
      name: 'Al Pacino',
      updatedAt: '2018-05-23T21:01:33.384Z',
      type: 'folder',
    },
    {
      ...FSO,
      index: '1.4',
      name: 'Liam Neeson',
      updatedAt: '2017-03-21T01:50:00.4Z',
      type: 'folder',
    },
    {
      ...FSO,
      index: '1.2',
      name: 'Clint Eastwood',
      updatedAt: '2019-05-09T00:08:47.689Z',
      type: 'file',
    },
    {
      ...FSO,
      index: '1.3',
      name: 'Robert De Niro',
      updatedAt: '2019-01-22T01:50:10.424Z',
      type: 'file',
    },
  ],
  notSeparatedByTypeSortedFsos: [
    {
      ...FSO,
      index: '1.4',
      name: 'Liam Neeson',
      updatedAt: '2017-03-21T01:50:00.4Z',
      type: 'folder',
    },
    {
      ...FSO,
      index: '1.3',
      name: 'Robert De Niro',
      updatedAt: '2019-01-22T01:50:10.424Z',
      type: 'file',
    },
    {
      ...FSO,
      index: '1.2',
      name: 'Clint Eastwood',
      updatedAt: '2019-05-09T00:08:47.689Z',
      type: 'file',
    },
    {
      ...FSO,
      index: '1.1',
      name: 'Al Pacino',
      updatedAt: '2018-05-23T21:01:33.384Z',
      type: 'folder',
    },
  ],
};

export const UPLOAD_FSOS_IO: {
  inputs: { [key: string]: IPath[] };
  outputs: { [key: string]: IUploadTreeChildren[] };
} = {
  inputs: {
    emptyPaths: [],
    branchedPaths: [
      {
        path: 'r/a_0/a_00/b_000/x_000',
        uuid: '1',
        file: {},
      },
      {
        path: 'r/a_0/a_00/b_000/y_000',
        uuid: '2',
        file: {},
      },
      {
        path: 'r/a_0/a_00/b_001/x_001',
        uuid: '3',
        file: {},
      },
      {
        path: 'r/a_0/a_00/b_001/y_001',
        uuid: '4',
        file: {},
      },
      {
        path: 'r/a_0/a_00/x_00',
        uuid: '5',
        file: {},
      },
      {
        path: 'r/a_0/a_01/b_010/x_010',
        uuid: '6',
        file: {},
      },
      {
        path: 'r/a_0/a_01/b_010/y_010',
        uuid: '7',
        file: {},
      },
      {
        path: 'r/a_0/a_01/b_011/x_011',
        uuid: '8',
        file: {},
      },
      {
        path: 'r/a_0/a_01/b_011/y_011',
        uuid: '9',
        file: {},
      },
      {
        path: 'r/a_0/a_01/x_01',
        uuid: '10',
        file: {},
      },
      {
        path: 'r/a_0/x_0',
        uuid: '11',
        file: {},
      },
      {
        path: 'r/a_1/a_10/b_100/x_100',
        uuid: '12',
        file: {},
      },
      {
        path: 'r/a_1/a_10/b_100/y_100',
        uuid: '13',
        file: {},
      },
      {
        path: 'r/a_1/a_10/b_101/x_101',
        uuid: '14',
        file: {},
      },
      {
        path: 'r/a_1/a_10/b_101/y_101',
        uuid: '15',
        file: {},
      },
      {
        path: 'r/a_1/a_10/x_10',
        uuid: '16',
        file: {},
      },
      {
        path: 'r/a_1/a_11/b_110/x_110',
        uuid: '17',
        file: {},
      },
      {
        path: 'r/a_1/a_11/b_110/y_110',
        uuid: '18',
        file: {},
      },
      {
        path: 'r/a_1/a_11/b_111/x_111',
        uuid: '19',
        file: {},
      },
      {
        path: 'r/a_1/a_11/b_111/y_111',
        uuid: '20',
        file: {},
      },
      {
        path: 'r/a_1/a_11/x_11',
        uuid: '21',
        file: {},
      },
      {
        path: 'r/a_1/x_1',
        uuid: '22',
        file: {},
      },
      {
        path: 'r/x',
        uuid: '23',
        file: {},
      },
    ],
    onlyFilesPaths: [
      {
        path: 'x',
        uuid: '1',
        file: {},
      },
      {
        path: 'y',
        uuid: '2',
        file: {},
      },
      {
        path: 'z',
        uuid: '3',
        file: {},
      },
    ],
    moreThan10LevelsPaths: [
      {
        path: 'r/a_0/b_00/x_00',
        uuid: '1',
        file: {},
      },
      {
        path: 'r/a_0/b_00/y_00',
        uuid: '2',
        file: {},
      },
      {
        path: 'r/a_0/b_00/z_00',
        uuid: '3',
        file: {},
      },
      {
        path: 'r/a_0/b_00/w_00',
        uuid: '4',
        file: {},
      },

      {
        path: 'r/a_0/x_0',
        uuid: '5',
        file: {},
      },
      {
        path: 'r/x',
        uuid: '6',
        file: {},
      },
      {
        path: 'r/y',
        uuid: '7',
        file: {},
      },
      {
        path:
          'r/a_1/b_11/c_110/d_1100/e_11000/f_110000/g_1100000/h_11000000/i_110000000/x_110000000',
        uuid: '8',
        file: {},
      },
      {
        path: 'r/a_1/b_11/c_110/d_1100/e_11000/f_110000/g_1100000/x_1100000',
        uuid: '9',
        file: {},
      },
    ],
    repeatedPaths: [
      {
        path: 'r/a_0/x_0',
        uuid: '1',
        file: {},
      },
      {
        path: 'r/a_0/x_0',
        uuid: '2',
        file: {},
      },
      {
        path: 'r/a_0/x_0',
        uuid: '3',
        file: {},
      },
      {
        path: 'r/a_0/x_0',
        uuid: '4',
        file: {},
      },
      {
        path: 'r/a_0/x_0',
        uuid: '5',
        file: {},
      },
      {
        path: 'r/a_1/x_1',
        uuid: '6',
        file: {},
      },
      {
        path: 'r/a_1/y_1',
        uuid: '7',
        file: {},
      },
      {
        path: 'r/a_1/z_1',
        uuid: '8',
        file: {},
      },
      {
        path: 'r/a_1/z_1',
        uuid: '9',
        file: {},
      },
    ],
  },
  outputs: {
    branchedPathsTree: [
      {
        children: [
          {
            children: [
              {
                children: [
                  {
                    children: [
                      {
                        name: 'x_000',
                        children: null,
                        correlation: '1',
                        type: 'file',
                      },
                      {
                        name: 'y_000',
                        children: null,
                        correlation: '2',
                        type: 'file',
                      },
                    ],
                    name: 'b_000',
                    type: 'folder',
                    correlation: 'xxx',
                  },
                  {
                    children: [
                      {
                        name: 'x_001',
                        children: null,
                        type: 'file',
                        correlation: '3',
                      },
                      {
                        name: 'y_001',
                        children: null,
                        type: 'file',
                        correlation: '4',
                      },
                    ],
                    name: 'b_001',
                    type: 'folder',
                    correlation: 'xxx',
                  },
                  {
                    children: null,
                    name: 'x_00',
                    type: 'file',
                    correlation: '5',
                  },
                ],
                name: 'a_00',
                type: 'folder',
                correlation: 'xxx',
              },
              {
                children: [
                  {
                    children: [
                      {
                        name: 'x_010',
                        children: null,
                        type: 'file',
                        correlation: '6',
                      },
                      {
                        name: 'y_010',
                        children: null,
                        type: 'file',
                        correlation: '7',
                      },
                    ],
                    name: 'b_010',
                    type: 'folder',
                    correlation: 'xxx',
                  },
                  {
                    children: [
                      {
                        name: 'x_011',
                        children: null,
                        type: 'file',
                        correlation: '8',
                      },
                      {
                        name: 'y_011',
                        children: null,
                        type: 'file',
                        correlation: '9',
                      },
                    ],
                    name: 'b_011',
                    type: 'folder',
                    correlation: 'xxx',
                  },
                  {
                    children: null,
                    name: 'x_01',
                    type: 'file',
                    correlation: '10',
                  },
                ],
                name: 'a_01',
                type: 'folder',
                correlation: 'xxx',
              },
              {
                children: null,
                name: 'x_0',
                type: 'file',
                correlation: '11',
              },
            ],
            name: 'a_0',
            type: 'folder',
            correlation: 'xxx',
          },
          {
            children: [
              {
                children: [
                  {
                    children: [
                      {
                        name: 'x_100',
                        children: null,
                        type: 'file',
                        correlation: '12',
                      },
                      {
                        name: 'y_100',
                        children: null,
                        type: 'file',
                        correlation: '13',
                      },
                    ],
                    name: 'b_100',
                    type: 'folder',
                    correlation: 'xxx',
                  },
                  {
                    children: [
                      {
                        name: 'x_101',
                        children: null,
                        type: 'file',
                        correlation: '14',
                      },
                      {
                        name: 'y_101',
                        children: null,
                        type: 'file',
                        correlation: '15',
                      },
                    ],
                    name: 'b_101',
                    type: 'folder',
                    correlation: 'xxx',
                  },
                  {
                    children: null,
                    name: 'x_10',
                    type: 'file',
                    correlation: '16',
                  },
                ],
                name: 'a_10',
                type: 'folder',
                correlation: 'xxx',
              },
              {
                children: [
                  {
                    children: [
                      {
                        name: 'x_110',
                        children: null,
                        type: 'file',
                        correlation: '17',
                      },
                      {
                        name: 'y_110',
                        children: null,
                        type: 'file',
                        correlation: '18',
                      },
                    ],
                    name: 'b_110',
                    type: 'folder',
                    correlation: 'xxx',
                  },
                  {
                    children: [
                      {
                        name: 'x_111',
                        children: null,
                        type: 'file',
                        correlation: '19',
                      },
                      {
                        name: 'y_111',
                        children: null,
                        type: 'file',
                        correlation: '20',
                      },
                    ],
                    name: 'b_111',
                    type: 'folder',
                    correlation: 'xxx',
                  },
                  {
                    children: null,
                    name: 'x_11',
                    type: 'file',
                    correlation: '21',
                  },
                ],
                name: 'a_11',
                type: 'folder',
                correlation: 'xxx',
              },
              {
                children: null,
                name: 'x_1',
                type: 'file',
                correlation: '22',
              },
            ],
            name: 'a_1',
            type: 'folder',
            correlation: 'xxx',
          },
          {
            children: null,
            name: 'x',
            type: 'file',
            correlation: '23',
          },
        ],
        name: 'r',
        type: 'folder',
        correlation: 'xxx',
      },
    ],
    onlyFilesTree: [
      {
        children: null,
        name: 'x',
        type: 'file',
        correlation: '1',
      },
      {
        children: null,
        name: 'y',
        type: 'file',
        correlation: '2',
      },
      {
        children: null,
        name: 'z',
        type: 'file',
        correlation: '3',
      },
    ],
    moreThan10LevelsPathsTree: [
      {
        children: [
          {
            children: [
              {
                children: [
                  {
                    children: null,
                    name: 'x_00',
                    correlation: '1',
                    type: 'file',
                  },
                  {
                    children: null,
                    name: 'y_00',
                    correlation: '2',
                    type: 'file',
                  },
                  {
                    children: null,
                    name: 'z_00',
                    correlation: '3',
                    type: 'file',
                  },
                  {
                    children: null,
                    name: 'w_00',
                    correlation: '4',
                    type: 'file',
                  },
                ],
                name: 'b_00',
                correlation: 'xxx',
                type: 'folder',
              },
              {
                children: null,
                name: 'x_0',
                correlation: '5',
                type: 'file',
              },
            ],
            name: 'a_0',
            correlation: 'xxx',
            type: 'folder',
          },
          {
            children: null,
            name: 'x',
            correlation: '6',
            type: 'file',
          },
          {
            children: null,
            name: 'y',
            correlation: '7',
            type: 'file',
          },
          {
            children: [
              {
                children: [
                  {
                    children: [
                      {
                        children: [
                          {
                            children: [
                              {
                                children: [
                                  {
                                    children: [
                                      {
                                        children: [
                                          {
                                            children: [
                                              {
                                                children: null,
                                                name: 'x_110000000',
                                                correlation: '8',
                                                type: 'file',
                                              },
                                            ],
                                            name: 'i_110000000',
                                            correlation: 'xxx',
                                            type: 'folder',
                                          },
                                        ],
                                        name: 'h_11000000',
                                        correlation: 'xxx',
                                        type: 'folder',
                                      },
                                      {
                                        children: null,
                                        name: 'x_1100000',
                                        correlation: '9',
                                        type: 'file',
                                      },
                                    ],
                                    name: 'g_1100000',
                                    correlation: 'xxx',
                                    type: 'folder',
                                  },
                                ],
                                name: 'f_110000',
                                correlation: 'xxx',
                                type: 'folder',
                              },
                            ],
                            name: 'e_11000',
                            correlation: 'xxx',
                            type: 'folder',
                          },
                        ],
                        name: 'd_1100',
                        correlation: 'xxx',
                        type: 'folder',
                      },
                    ],
                    name: 'c_110',
                    correlation: 'xxx',
                    type: 'folder',
                  },
                ],
                name: 'b_11',
                correlation: 'xxx',
                type: 'folder',
              },
            ],
            name: 'a_1',
            correlation: 'xxx',
            type: 'folder',
          },
        ],
        name: 'r',
        correlation: 'xxx',
        type: 'folder',
      },
    ],
    repeatedPathsTree: [
      {
        children: [
          {
            children: [
              {
                children: null,
                name: 'x_0',
                correlation: '1',
                type: 'file',
              },
            ],
            name: 'a_0',
            correlation: 'xxx',
            type: 'folder',
          },
          {
            children: [
              {
                children: null,
                name: 'x_1',
                correlation: '6',
                type: 'file',
              },
              {
                children: null,
                name: 'y_1',
                correlation: '7',
                type: 'file',
              },
              {
                children: null,
                name: 'z_1',
                correlation: '8',
                type: 'file',
              },
            ],
            name: 'a_1',
            correlation: 'xxx',
            type: 'folder',
          },
        ],
        name: 'r',
        correlation: 'xxx',
        type: 'folder',
      },
    ],
  },
};

export const FSOS_IN_TRANSFER = [
  {
    extension: null,
    id: '1',
    name: 'x_0',
    size: '',
    status: 'pending',
    uuid: '01',
  },
  {
    extension: null,
    id: '2',
    name: 'y_0',
    size: '',
    status: 'pending',
    uuid: '02',
  },
  {
    extension: null,
    id: '3',
    name: 'z_0',
    size: '',
    status: 'pending',
    uuid: '03',
  },
  {
    extension: null,
    id: '4',
    name: 'x_1',
    size: '',
    status: 'pending',
    uuid: '04',
  },
  {
    extension: null,
    id: '5',
    name: 'y_1',
    size: '',
    status: 'pending',
    uuid: '05',
  },
  {
    extension: null,
    id: '6',
    name: 'z_1',
    size: '',
    status: 'pending',
    uuid: '06',
  },
];
