import * as React from 'react';
import { IconProps } from './types';

export const UserIconV2 = ({
  color = 'currentColor',
  width = '14px',
  height = '14px',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    viewBox="0 0 14 14"
  >
    <path
      d="M9.473 7.472a4 4 0 1 0-4.946 0A6.667 6.667 0 0 0 .38 12.925a.67.67 0 1 0 1.333.147 5.333 5.333 0 0 1 10.6 0 .667.667 0 0 0 .667.593h.073a.666.666 0 0 0 .587-.733 6.667 6.667 0 0 0-4.167-5.46ZM7 6.998a2.667 2.667 0 1 1 0-5.333 2.667 2.667 0 0 1 0 5.333Z"
      fill={color}
    />
  </svg>
);
