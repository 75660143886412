import React, { useContext, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import get from 'lodash/get';

import { UserContext } from 'app/users/context/UserContext';
import useStateModal from 'app/utils/hooks/useStateModal';
import { AlertDialog, Button, HStack } from 'app/common/components';
import { InfoIcon } from 'app/common/icons';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { SKIP_ATTESTATION_ALERT } from 'api/graphql/compliance/mutations';
import { ERoutePatterns } from 'app/core-tools/due-diligence/types/types';

export const AttestationAlertDialog = () => {
  const { attestation, user, refetchLatestAttestation } = useContext(UserContext);
  const { hide, show, modalState } = useStateModal('attestationAlertDialog');
  const { location, push: goToRoute } = useHistory();

  const excludedRoutes = ['/attestation', '/connect', '/onboarding'];
  const isInExcludedRoute = excludedRoutes.some(
    route => location && location.pathname && location.pathname.includes(route)
  );
  const hasAttested = get(attestation, 'attested', true);
  const alertSeverity = get(attestation, 'dueDateStatus', '') as string;
  const hasSkippedAlert = alertSeverity !== 'high' ? !!get(attestation, 'skipAlertAt') : false;

  const displayAlert = !hasAttested && !hasSkippedAlert && !isInExcludedRoute;

  const dueDateUnformatted = dayjs(get(attestation, 'dueDate', '').replace('Z', ''));
  const dueDate = dueDateUnformatted.format('MMMM DD, YYYY');

  useEffect(() => {
    if (displayAlert) {
      show();
    }
  }, [attestation, location.pathname]);

  const [skipAlert] = useMutation(SKIP_ATTESTATION_ALERT, {
    onCompleted: () => {
      hide();
      refetchLatestAttestation &&
        refetchLatestAttestation({
          userId: user.id,
        });
    },
    onError: () => hide(),
  });

  const cancelHandler = () => {
    if (hasSkippedAlert) {
      hide();
    } else if (alertSeverity === 'high') {
      hide();
    } else {
      skipAlert({
        variables: {
          attestInput: {
            userId: user.id,
            attestation: JSON.stringify(null),
          },
        },
      });
    }
  };

  const goToAttestationHandler = () => {
    goToRoute(ERoutePatterns.ATTESTATION);
    hide();
  };

  const dialogProps = useMemo(() => {
    const propMap = {
      low: {
        label: (
          <HStack spacing={16} alignItems="center">
            <InfoIcon />
            <span>Attestation required</span>
          </HStack>
        ),
        description: `Attestation must be completed and verified by ${dueDate}.`,
      },
      mid: {
        label: (
          <HStack spacing={16} alignItems="center">
            <InfoIcon />
            <span>Attestation not completed</span>
          </HStack>
        ),
        description: `You need to complete this requirement by ${dueDate}.`,
      },
      high: {
        label: (
          <HStack spacing={16} alignItems="center">
            <InfoIcon />
            <span>Attestation: NOT COMPLETE</span>
          </HStack>
        ),
        description: (
          <span>
            Complete by {dueDate} —{' '}
            <i>
              any further service requests will not be fulfilled until completion of attestation.
            </i>
          </span>
        ),
      },
    };
    return get(propMap, alertSeverity, propMap.low);
  }, [attestation]);

  return (
    <AlertDialog
      show={modalState.show}
      onClose={cancelHandler}
      label={dialogProps.label}
      description={dialogProps.description}
      actionButtons={({ cancelRef, onClose }) => (
        <HStack spacing={16} justifyContent="flex-end">
          <Button ref={cancelRef as any} className="tertiary small" onClick={onClose}>
            Remind me later
          </Button>
          <Button onClick={goToAttestationHandler} className="primary small">
            Go to attestation
          </Button>
        </HStack>
      )}
    />
  );
};
