import { UserContext } from 'app/users/context/UserContext';
import React, { useContext } from 'react';
import * as S from './ImpersonationOverlayStyled';

export const ImpersonationOverlay = (): JSX.Element | null => {
  const { isImpersonated, displayName } = useContext(UserContext);
  return isImpersonated ? (
    <S.Overlay>
      <S.NotchContainer>
        <S.Notch>
          <S.NotchText>
            Proxy mode for <em>[{displayName}]</em>
            <a href="/logout" style={{ marginLeft: 16 }}>
              Log out
            </a>
          </S.NotchText>
        </S.Notch>
      </S.NotchContainer>
    </S.Overlay>
  ) : null;
};
