import safeParse from './utils/helpers/safeParse';

export const APP_STORAGE_KEY = 'Finalis_data';
export const TOKEN_KEY = 'authToken';
export const EMAIL_KEY = 'email';

// AppStorage is responsible for implementing reads, writes, and deletes to localStorage.
// It also provides a faked localStorage when the app is executed in a node env (testing).

export type StoredData = number | string | Object;

declare global {
  interface Window {
    clearAppStorage: () => void;
  }
}

// The actual app data is stored as a stringified JSON object under a single key.
// When we get, set, and delete from appStorage, the AppStorage class will internally
// manage JSON parsing and stringifcation.
class AppStorage {
  getAppStorageJSON() {
    const appStorageString = localStorage.getItem(APP_STORAGE_KEY) || '{}';
    return safeParse(appStorageString);
  }

  setAppStorageJSON(storageData: Object): void {
    const storageString = JSON.stringify(storageData);
    localStorage.setItem(APP_STORAGE_KEY, storageString);
  }

  setItem(key: string, value: StoredData): void {
    const storageJSON = this.getAppStorageJSON();
    storageJSON[key] = value;
    this.setAppStorageJSON(storageJSON);
  }

  getItem(key: string): StoredData {
    return this.getAppStorageJSON()[key];
  }

  removeKey(key: string): void {
    const storageJSON = this.getAppStorageJSON();
    delete storageJSON[key];
    this.setAppStorageJSON(storageJSON);
  }
}

// Export the appStorage singleton.
const appStorage = new AppStorage();

// Add a globally accessable method for clearing app storage for dev and debugging purposes.
window.clearAppStorage = () => appStorage.setAppStorageJSON({});

export default appStorage;
