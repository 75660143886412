import { useCallback, useContext, useState } from 'react';
import _get from 'lodash/get';
import { QueryResult, useQuery } from '@apollo/client';

import { GET_PAGINATED_FORMS } from '../../../../api/graphql/compliance/queries';
import { EFormStatus, IForm } from '../../../compliance/types/formsTypes';
import {
  IFilterQuery,
  IPageQuery,
  IPaginationInfo,
} from '../../../compliance/context/TypedFilterQuery';
import { FormType } from '../../../../types/graphql-types';
import { UserContext } from '../../../../app/users/context/UserContext';

export const useRegulatoryProfileHandler = () => {
  const { user } = useContext(UserContext);

  const [queryCount, setQueryCount] = useState(0);
  const [filters, setFilterQuery] = useState<IFilterQuery>({});
  const [{ page, pageSize }, setPageQuery] = useState<IPageQuery>({ page: 1, pageSize: 10 });

  const { operator, searchQuery, sortParams } = filters;

  const triggerQuery = useCallback(() => {
    setQueryCount(value => value + 1);
  }, [setQueryCount]);

  const checkDefaultTypeFilter = () => {
    const generalFilter = _get(searchQuery, 'generalFilter', []);
    const hasTypeFilter = generalFilter.some(filter => filter.field === 'type');
    if (generalFilter.length < 1 || !hasTypeFilter) {
      return [
        { field: 'type', value: FormType.OBA },
        { field: 'type', value: FormType.PST },
        { field: 'type', value: FormType.OBAcct },
        { field: 'type', value: FormType.DCR },
        { field: 'type', value: FormType.MMR },
        {
          field: 'type',
          value: FormType.U4,
        },
        {
          field: 'type',
          value: FormType.EL,
        },
        {
          field: 'type',
          value: FormType.MMAT,
        },
        {
          field: 'type',
          value: FormType.MMF,
        },
        {
          field: 'type',
          value: FormType.FSAext,
        },
        {
          field: 'type',
          value: FormType.FSAint,
        },
        {
          field: 'type',
          value: FormType.AMNel,
        },
        {
          field: 'type',
          value: FormType.INV,
        },
        {
          field: 'type',
          value: FormType.KYCcl,
        },
        {
          field: 'type',
          value: FormType.KYCco,
        },
        {
          field: 'type',
          value: FormType.KYC,
        },
        {
          field: 'type',
          value: FormType.CDOC,
        },
        {
          field: 'type',
          value: FormType.DRA,
        },
      ];
    }
    return [];
  };

  const addLegalFilters = () => {
    const generalFilter = _get(searchQuery, 'generalFilter') || [];
    const isPendingApprovalSelected = generalFilter.find(
      (filter: any) => filter.field === 'status' && filter.value === EFormStatus.IN_REVIEW
    );
    if (isPendingApprovalSelected) {
      return generalFilter.concat([
        { field: 'status', value: EFormStatus.PASS_TO_LEGAL_TEAM },
        { field: 'status', value: EFormStatus.REVIEWED_BY_LEGAL },
      ]);
    }
    return generalFilter;
  };

  const regulatoryProfileFormsQuery = useQuery<{ form: IForm[] }>(GET_PAGINATED_FORMS, {
    skip: !queryCount,
    variables: {
      operator,
      sort: sortParams,
      page,
      pageSize,
      searchQuery: {
        ...searchQuery,
        isActive: 'all',
        userId: user.id,
        generalFilter: [
          ..._get(searchQuery, 'generalFilter', []),
          ...checkDefaultTypeFilter(),
          ...addLegalFilters(),
        ],
      },
    },
  });

  const paginationInfo = (query: QueryResult<{ form: IForm[] }>): IPaginationInfo => ({
    current: page,
    size: pageSize,
    totalPages: _get(query, 'data.getPaginatedFormsByUser.totalPages'),
    totalRecord: _get(query, 'data.getPaginatedFormsByUser.totalRecords'),
  });

  return {
    query: {
      ...regulatoryProfileFormsQuery,
      triggerQuery,
    },
    pagination: {
      ...paginationInfo(regulatoryProfileFormsQuery),
      setPageQuery,
    },
    filters: {
      ...filters,
      setFilterQuery,
    },
  };
};
