import gql from 'graphql-tag';

export const PUBLISH_MARKETPLACE_DEAL = gql`
  mutation PUBLISH_MARKETPLACE_DEAL_MUTATION($dealId: ID!, $input: PublishMarketplaceDealInput!) {
    publishMarketplaceDeal(dealId: $dealId, input: $input) {
      id
      name
      type
      mode
      ownerId
      projectName
      companyName
      companyAddress
      companyAgent
      marketplaceDealId
      status
      loiProvided
      expectedSize
      expectedCommissions
      companyProfile
      description
      retainerAmount
      grossFeedEarned {
        min
        max
      }
      calculatedPercentRate {
        min
        max
      }
      createdAt
      updatedAt
      tailPeriodDuration
      publishedAt
      engagementLetterSignedDate
      loiSignedDate
    }
  }
`;

export const UNPUBLISH_MARKETPLACE_DEAL = gql`
  mutation UNPUBLISH_MARKETPLACE_DEAL_MUTATION($dealId: ID!) {
    unpublishMarketplaceDeal(dealId: $dealId) {
      id
    }
  }
`;

export const REQUEST_COLLABORATE_IN_OFFERING = gql`
  mutation REQUEST_COLLABORATE_IN_OFFERING_MUTATION(
    $offeringId: ID!
    $input: CollaborateInput!
    $offeringType: CollaborativeOfferingsEnum!
  ) {
    requestCollaborateInOffering(
      offeringId: $offeringId
      input: $input
      offeringType: $offeringType
    ) {
      offeringId
      offeringType
      requesterId
      collaborationType
      status
      requestedAt
    }
  }
`;

export const UPDATE_MARKETPLACE_DEAL = gql`
  mutation UPSERT_MARKETPLACE_DEAL($marketplaceDeal: MarketplaceDealInput!) {
    marketplaceUpsertDeal(marketplaceDeal: $marketplaceDeal) {
      id
      name
      type
      mode
    }
  }
`;

export const UPSERT_NON_COMPLIANCE_MARKETPLACE_DEAL = gql`
  mutation UPSERT_NON_COMPLIANCE_MARKETPLACE_DEAL(
    $nonComplianceMarketplaceDeal: NonComplianceMarketplaceDealInput!
  ) {
    marketplaceUpsertNonComplianceDeal(
      nonComplianceMarketplaceDeal: $nonComplianceMarketplaceDeal
    ) {
      id
      name
      type
      mode
    }
  }
`;

export const DELETE_NON_COMPLIANCE_MARKETPLACE_DEAL = gql`
  mutation DELETE_NON_COMPLIANCE_MARKETPLACE_DEAL_MUTATION($dealId: String!) {
    marketplaceDeleteNonComplianceDeal(dealId: $dealId)
  }
`;

export const SET_MARKETPLACE_ACCESS_STATUS = gql`
  mutation SET_MARKETPLACE_ACCESS_STATUS($userId: ID!, $marketplaceUserAccessEnabled: Boolean!) {
    setMarketplaceAccessEnabled(
      userId: $userId
      marketplaceUserAccessEnabled: $marketplaceUserAccessEnabled
    ) {
      id
      marketplaceAccessEnabled
    }
  }
`;

export const SET_SKIP_MARKETPLACE_TOUR_STATUS = gql`
  mutation SET_SKIP_MARKETPLACE_TOUR_STATUS($skipMarketplaceTour: Boolean!, $userId: ID!) {
    setSkipMarketplaceTour(skipMarketplaceTour: $skipMarketplaceTour, userId: $userId) {
      firstName
      skipMarketplaceTour
    }
  }
`;

export const SEND_CONVERSATION_MESSAGE = gql`
  mutation SendMessage($collaborationId: ID!, $message: String!, $files: [UploadFile!]!) {
    sendMessage(collaborationId: $collaborationId, message: $message, files: $files) {
      userName
    }
  }
`;

export const UPDATE_LAST_INTERACTION_IN_COLABORATION = gql`
  mutation UpdateLastUserInteractionInCollaboration($collaborationId: String!) {
    updateLastUserInteractionInCollaboration(collaborationId: $collaborationId)
  }
`;
