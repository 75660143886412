import * as React from 'react';
import { IconProps } from './types';

export const ChatInfoIconV2 = ({
  width = '32px',
  height = '32px',
  color = 'currentColor',
  style,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    style={style}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <path
      d="M16 12a1.334 1.334 0 0 0-1.334 1.334v4a1.333 1.333 0 1 0 2.667 0v-4A1.334 1.334 0 0 0 15.999 12Zm9.333-9.333H6.666a4 4 0 0 0-4 4V20a4 4 0 0 0 4 4h15.453l4.934 4.947a1.335 1.335 0 0 0 .946.387 1.12 1.12 0 0 0 .507-.107A1.334 1.334 0 0 0 29.333 28V6.667a4 4 0 0 0-4-4Zm1.333 22.12-3.053-3.067a1.334 1.334 0 0 0-.947-.386h-16A1.333 1.333 0 0 1 5.333 20V6.667a1.333 1.333 0 0 1 1.333-1.333h18.667a1.333 1.333 0 0 1 1.333 1.333v18.12ZM15.999 8a1.333 1.333 0 1 0 0 2.667A1.333 1.333 0 0 0 16 8Z"
      fill={color}
    />
  </svg>
);
