import * as React from 'react';
import { IconProps } from './types';

export const BoxIcon = ({
  width = '13px',
  height = '14px',
  color = 'currentColor',
  style = {},
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    width={width}
    height={height}
    viewBox="0 0 14 13"
    fill="none"
  >
    <path
      d="M5.66732 7.83333H8.33398C8.5108 7.83333 8.68036 7.7631 8.80539 7.63807C8.93041 7.51305 9.00065 7.34348 9.00065 7.16667C9.00065 6.98986 8.93041 6.82029 8.80539 6.69526C8.68036 6.57024 8.5108 6.5 8.33398 6.5H5.66732C5.49051 6.5 5.32094 6.57024 5.19591 6.69526C5.07089 6.82029 5.00065 6.98986 5.00065 7.16667C5.00065 7.34348 5.07089 7.51305 5.19591 7.63807C5.32094 7.7631 5.49051 7.83333 5.66732 7.83333ZM11.6673 0.5H2.33398C1.80355 0.5 1.29484 0.710714 0.919771 1.08579C0.544698 1.46086 0.333984 1.96957 0.333984 2.5V4.5C0.333984 4.67681 0.404222 4.84638 0.529246 4.9714C0.654271 5.09643 0.82384 5.16667 1.00065 5.16667H1.66732V10.5C1.66732 11.0304 1.87803 11.5391 2.2531 11.9142C2.62818 12.2893 3.13688 12.5 3.66732 12.5H10.334C10.8644 12.5 11.3731 12.2893 11.7482 11.9142C12.1233 11.5391 12.334 11.0304 12.334 10.5V5.16667H13.0007C13.1775 5.16667 13.347 5.09643 13.4721 4.9714C13.5971 4.84638 13.6673 4.67681 13.6673 4.5V2.5C13.6673 1.96957 13.4566 1.46086 13.0815 1.08579C12.7065 0.710714 12.1978 0.5 11.6673 0.5ZM11.0007 10.5C11.0007 10.6768 10.9304 10.8464 10.8054 10.9714C10.6804 11.0964 10.5108 11.1667 10.334 11.1667H3.66732C3.49051 11.1667 3.32094 11.0964 3.19591 10.9714C3.07089 10.8464 3.00065 10.6768 3.00065 10.5V5.16667H11.0007V10.5ZM12.334 3.83333H1.66732V2.5C1.66732 2.32319 1.73756 2.15362 1.86258 2.0286C1.9876 1.90357 2.15717 1.83333 2.33398 1.83333H11.6673C11.8441 1.83333 12.0137 1.90357 12.1387 2.0286C12.2637 2.15362 12.334 2.32319 12.334 2.5V3.83333Z"
      fill={color}
    />
  </svg>
);
