import safeParse from './safeParse';

export type PositionMap = {
  [key: string]: { match: string; value?: (values: { input: string; question: string }) => any };
};

export type TransformationMap = {
  [key: string]: (resolved: any) => object;
};

const transformationResolver = (
  key: string,
  value: any,
  positionMap: PositionMap,
  transformationMap: TransformationMap,
  usePositionMap: boolean
) => {
  if (usePositionMap) {
    const params = positionMap[key];
    return {
      [key]: params && params.value ? params.value(value) : value.input,
    };
  }
  const currentTransformationMap = transformationMap[key];
  return currentTransformationMap
    ? currentTransformationMap(safeParse(value.value || value.input))
    : { [key]: safeParse(value.value || value.input) };
};

function mapFormDataFromContent<T = any>(
  formContent: any[],
  initialValues: T = {} as T,
  positionMap: PositionMap,
  transformationMap: TransformationMap
): T {
  const mappedValues = formContent.reduce(
    (prev, curr) => ({
      ...prev,
      ...transformationResolver(curr.key, curr, positionMap, transformationMap, !curr.value),
    }),
    {}
  );
  return {
    ...initialValues,
    ...mappedValues,
  };
}

function mapFormDataFromMatch<T = any>(
  formContent: any[],
  initialValues: T = {} as T,
  positionMap: PositionMap
): T {
  const mappedValues = Object.keys(positionMap).reduce((prev, curr) => {
    const params = positionMap[curr];
    const found = formContent.find(
      ({ question }) => question.toLocaleLowerCase() === params.match.toLocaleLowerCase()
    );
    if (found) {
      return {
        ...prev,
        [curr]: params && params.value ? params.value(found) : found.input,
      };
    }
    return prev;
  }, {});
  return {
    ...initialValues,
    ...mappedValues,
  };
}

export function mapFormData<T = any>(
  formContent: any[],
  initialValues: T,
  positionMap: PositionMap = {},
  transformationMap: TransformationMap = {}
) {
  const shouldInferByQuestion = !formContent.some(content => !!content.key);
  if (shouldInferByQuestion) {
    return mapFormDataFromMatch(formContent, initialValues, positionMap);
  }
  return mapFormDataFromContent(formContent, initialValues, positionMap, transformationMap);
}
