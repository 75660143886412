import React, { useEffect, useRef, useState } from 'react';
import { createPopper } from '@popperjs/core';

// Components
import { Button, ClickOutsideHandler } from 'app/common/components';
import * as S from './ContextMenuStyled';

interface ContextMenuProps {
  actions: Array<ActionType>;
  title: string;
  isDropdown: boolean;
  icon?: JSX.Element;
}

type ActionType = {
  icon: JSX.Element;
  label: string;
  onClick: () => void;
  style?: object;
  disabled?: boolean;
  tooltip?: string;
};

export const ContextMenu = ({ actions, title, isDropdown, icon }: ContextMenuProps) => {
  const dropdownButtonElement = useRef<HTMLDivElement>();
  const dropdownMenuElement = useRef<HTMLDivElement>();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (dropdownButtonElement.current && dropdownMenuElement.current && show) {
      createPopper(dropdownButtonElement.current, dropdownMenuElement.current, {
        placement: 'bottom-end',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
        ],
      });
    }
  }, [show]);

  const toggleShow = () => setShow(state => !state);

  const handleClickOutside = () => {
    toggleShow();
  };

  const handleClick = (action: any) => (event: any) => {
    action();
    toggleShow();
  };

  return (
    <>
      <S.ButtonContainer ref={dropdownButtonElement as any}>
        <Button
          className="secondary"
          displayAs={isDropdown ? 'dropdown' : 'button'}
          onClick={toggleShow}
        >
          {icon ? icon : null}
          {title}
        </Button>
      </S.ButtonContainer>
      {show && (
        <ClickOutsideHandler
          onClickOutside={handleClickOutside}
          triggerElement={dropdownButtonElement}
        >
          <S.SelectContainer ref={dropdownMenuElement as any}>
            {actions.map(element => (
              <S.SelectOption
                onClick={handleClick(element.onClick)}
                style={element.style}
                disabled={element.disabled}
                title={element.tooltip ? element.tooltip : ''}
              >
                {element.icon}
                <p> {element.label}</p>
              </S.SelectOption>
            ))}
          </S.SelectContainer>
        </ClickOutsideHandler>
      )}
    </>
  );
};
