import * as React from 'react';
import { IconProps } from './types';

export const PlusIcon = ({
  color = 'currentColor',
  width = '11',
  height = '12',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3335 5.33332H6.3335V1.33332C6.3335 1.15651 6.26326 0.986943 6.13823 0.861919C6.01321 0.736895 5.84364 0.666656 5.66683 0.666656C5.49002 0.666656 5.32045 0.736895 5.19542 0.861919C5.0704 0.986943 5.00016 1.15651 5.00016 1.33332V5.33332H1.00016C0.823352 5.33332 0.653783 5.40356 0.528758 5.52858C0.403734 5.65361 0.333496 5.82318 0.333496 5.99999C0.333496 6.1768 0.403734 6.34637 0.528758 6.47139C0.653783 6.59642 0.823352 6.66666 1.00016 6.66666H5.00016V10.6667C5.00016 10.8435 5.0704 11.013 5.19542 11.1381C5.32045 11.2631 5.49002 11.3333 5.66683 11.3333C5.84364 11.3333 6.01321 11.2631 6.13823 11.1381C6.26326 11.013 6.3335 10.8435 6.3335 10.6667V6.66666H10.3335C10.5103 6.66666 10.6799 6.59642 10.8049 6.47139C10.9299 6.34637 11.0002 6.1768 11.0002 5.99999C11.0002 5.82318 10.9299 5.65361 10.8049 5.52858C10.6799 5.40356 10.5103 5.33332 10.3335 5.33332Z"
      fill={color}
    />
  </svg>
);
