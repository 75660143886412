import React from 'react';
import { DataProxy, FetchResult, gql } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';

import { EDIT_PERMISSIONS } from 'api/graphql/users/mutations';

export type EditPermissionsArgs = {
  ownerId: string;
  fsoIds: string[];
  permissionEntityId: string;
  remove: boolean;
};

type Props = {
  children: (mutateFunction: (childrenProps: EditPermissionsArgs) => any) => JSX.Element | null;
};

const EditPermissionsMutation = ({ children }: Props) => (
  <Mutation mutation={EDIT_PERMISSIONS}>
    {mutatePermissions => {
      const editPermission = ({
        ownerId,
        fsoIds,
        permissionEntityId,
        remove,
      }: EditPermissionsArgs) => {
        if (fsoIds.length === 0) {
          return;
        }

        mutatePermissions({
          variables: {
            ownerIds: [ownerId],
            fsoIds,
            permissionEntityId,
            remove,
          },
          optimisticResponse: {
            setPermission: fsoIds.map(fsoId => ({
              __typename: 'Permission',
              id: `${fsoId}-${ownerId}-${permissionEntityId}`,
              entityId: fsoId,
              ownerId,
              active: !remove,
              permissionEntityId,
            })),
          },
          update: remove
            ? undefined
            : (
                client: DataProxy,
                { data: { setPermission } }: FetchResult<any, Record<string, any>>
              ) => {
                const fragment = gql`
                  fragment myEntityPermission on PermissionByEntity {
                    id
                    entityId
                    permissions {
                      id
                      entityId
                      ownerId
                      permissionEntityId
                      active
                    }
                  }
                `;

                setPermission.forEach((createdPermission: { id: string; entityId: string }) => {
                  try {
                    const permissionByEntity = client.readFragment({
                      id: `PermissionByEntity:${createdPermission.entityId}`,
                      fragment,
                    }) as { id: string; permissions: any[] };

                    if (permissionByEntity) {
                      const updatedPermissions = [
                        ...permissionByEntity.permissions.filter(
                          permission => permission.id !== createdPermission.id
                        ),
                        createdPermission,
                      ];

                      const updatedPermissionByEntity = {
                        ...permissionByEntity,
                        permissions: updatedPermissions,
                      };

                      client.writeFragment({
                        id: `PermissionByEntity:${createdPermission.entityId}`,
                        fragment,
                        data: updatedPermissionByEntity,
                      });
                    }
                  } catch (error) {
                    console.log(error);
                  }
                });
              },
        });
      };

      return children(editPermission);
    }}
  </Mutation>
);

export default EditPermissionsMutation;
