import * as React from 'react';
import { IconProps } from './types';

export const AttachmentIcon = ({
  color = 'currentColor',
  style = {},
  width = '18',
  height = '20',
}: IconProps): JSX.Element => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1593 6.78296C13.2996 6.66211 13.3841 6.48928 13.3906 6.30345C13.3984 6.11893 13.3282 5.93828 13.197 5.80705C13.0657 5.67581 12.8851 5.60562 12.7005 5.61342C12.5147 5.61992 12.3419 5.70439 12.221 5.84473L7.231 10.8348C6.84114 11.2246 6.62155 11.7522 6.62155 12.3019C6.62155 12.8529 6.84116 13.3805 7.231 13.769C8.05096 14.5539 9.34398 14.5539 10.1652 13.769L15.1553 8.77901C15.8206 8.12797 16.1988 7.23782 16.2039 6.30608C16.2091 5.37434 15.8414 4.48029 15.1826 3.82144C14.5237 3.16259 13.6297 2.79485 12.6979 2.80004C11.7662 2.80523 10.876 3.18338 10.225 3.84872L5.23495 8.83875C3.99782 10.0759 3.51442 11.8796 3.96665 13.5703C4.42018 15.2596 5.74044 16.5799 7.42973 17.0334C9.12036 17.4856 10.9241 17.0022 12.1613 15.7651L15.8206 12.1057C15.961 11.9849 16.0455 11.812 16.052 11.6262C16.0598 11.4417 15.9896 11.2611 15.8583 11.1298C15.7271 10.9986 15.5465 10.9284 15.3619 10.9362C15.1761 10.9427 15.0033 11.0272 14.8824 11.1675L11.2231 14.8269C9.8287 16.2212 7.56769 16.2212 6.17314 14.8269C4.77859 13.4325 4.77879 11.1715 6.17314 9.77694L11.1632 4.7869C12.0066 3.94352 13.3736 3.94352 14.217 4.7869C15.0604 5.63029 15.0604 6.99732 14.217 7.84071L9.22694 12.8307C8.93195 13.1062 8.47195 13.0984 8.18735 12.8126C7.90146 12.528 7.89366 12.0679 8.16916 11.773L13.1593 6.78296Z"
      fill={color}
    />
  </svg>
);
