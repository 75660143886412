import { useForm } from 'app/utils/hooks/useForm';
import get from 'lodash/get';
import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_FORM } from '../../../../../../../api/graphql/compliance/mutations';
import { AlertContext } from '../../../../../../notifications/context/Alert/Alert';
import { UserContext } from '../../../../../../users/context/UserContext';
import { DealsContext } from '../../../../../context/DealsContext';
import { CAPITAL_RAISE_DEALS } from '../KYCFormConstants';
import { buildKYCClientForm } from './KYCClientHelper';
import { validationSchema } from './KYCClientValidations';

export type selectValue = {
  value: string;
  label: string;
};

export type KYCClientForm = {
  entityName: string;
  transactionStructure: selectValue | undefined;
  fullName: string;
  emailAddress: string;
  title: string;
};

const initialValues = {
  entityName: '',
  transactionStructure: undefined,
  fullName: '',
  emailAddress: '',
  title: '',
};

export const useKYCClientHandler = () => {
  const { activeDeal } = useContext(UserContext);
  const { selectedDeal: dealId } = useContext<any>(DealsContext);
  const { showErrorToast } = useContext(AlertContext);
  const isCapitalRaiseDeal = CAPITAL_RAISE_DEALS.includes(get(activeDeal, 'type', ''));
  const validation = isCapitalRaiseDeal
    ? validationSchema.capitalRaiseSchema
    : validationSchema.noCapitalRaiseSchema;

  const form = useForm<KYCClientForm>({
    initialValues,
    validationSchema: validation,
  });

  const onSuccess = () => {
    form.resetForm();
  };

  const [createFormMutation, { loading: creatingForm }] = useMutation(CREATE_FORM, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      onSuccess();
    },
    onError() {
      showErrorToast({ title: 'Error', description: 'An error occurred while saving the form' });
    },
  });

  const onSubmitKYCClientForm = async (): Promise<void> => {
    const { values } = form;
    await createFormMutation({
      variables: buildKYCClientForm(
        values,
        dealId,
        isCapitalRaiseDeal,
        get(activeDeal, 'companyName', '')
      ),
    });
  };

  return {
    isCapitalRaiseDeal,
    form,
    onSubmitKYCClientForm,
    creatingForm,
    activeDeal,
  };
};
