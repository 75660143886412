import React, { useMemo } from 'react';
import {
  AttachmentIcon,
  CheckCircleIcon,
  CrossCircleIcon,
  ExclamationCircleFilledIcon,
  ExclamationIcon,
  TrashIcon,
} from 'app/common/icons';
import * as S from './FormFilePickerStyled';
import { ExtendedFile, FileStatus } from './ExtendedFile';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { Button } from '../Button/Button';
import { SUPPORTED_FILENAME_VALIDATION } from 'app/utils/helpers/formValidators';

interface FilePickerListProps {
  files: ExtendedFile[];
  onDelete: (id: string) => void;
  displayAs?: string;
  supportedFileFormats: string | string[];
}

export const FilePickerList = ({
  files = [],
  onDelete,
  supportedFileFormats,
  displayAs = 'dropZone' || 'button',
}: FilePickerListProps) => {
  const handleRemove = (id: string) => () => onDelete(id);

  const handleFileState = useMemo(
    () => (file: ExtendedFile) => {
      if (
        file.file.type &&
        supportedFileFormats.includes(file.file.type) &&
        SUPPORTED_FILENAME_VALIDATION.test(file.file.name)
      ) {
        switch (file.status) {
          case FileStatus.Uploading:
            return (
              <ProgressBar
                style={{ width: '100%' }}
                percentage={file.progress}
                showPercentage
                variant={['accent', 'round']}
              />
            );
          case FileStatus.Ready:
            return null;
          case FileStatus.Completed:
            return (
              <S.FileListStatusLabel className="completed">
                <CheckCircleIcon /> Completed
              </S.FileListStatusLabel>
            );
          case FileStatus.Failed:
            return (
              <S.FileListStatusLabel className="failed">
                <ExclamationIcon /> Failed
              </S.FileListStatusLabel>
            );
          default:
            return null;
        }
      } else if (!SUPPORTED_FILENAME_VALIDATION.test(file.file.name)) {
        return (
          <S.FileListStatusLabel className="invalid">
            <ExclamationCircleFilledIcon /> Invalid name
          </S.FileListStatusLabel>
        );
      } else {
        return (
          <S.FileListStatusLabel className="invalid">
            <ExclamationCircleFilledIcon /> Invalid format
          </S.FileListStatusLabel>
        );
      }
    },
    [files]
  );

  return files.length > 0 ? (
    <S.FileListContainer>
      {displayAs !== 'button' && <S.FileListHeading>Files</S.FileListHeading>}
      <S.FileListGrid>
        {files.map((file, index) => (
          <React.Fragment key={`file_${index + 1}`}>
            <S.FileListCell>
              <AttachmentIcon />
            </S.FileListCell>
            <S.FileListCell>
              <S.FileListItemLabel displayAs={displayAs}>{file.filename}</S.FileListItemLabel>
            </S.FileListCell>
            <S.FileListCell>
              {displayAs === 'dropZone' ? (
                <S.FileListItemInfo>
                  {file.extension} ({file.fileSize})
                </S.FileListItemInfo>
              ) : (
                <S.FileListItemInfo>{file.fileSize}</S.FileListItemInfo>
              )}
            </S.FileListCell>
            <S.FileListCell>{handleFileState(file)}</S.FileListCell>
            <S.FileListCell>
              <Button
                onClick={handleRemove(file.id)}
                style={{ background: 'transparent', boxShadow: 'none' }}
                className="small secondary"
              >
                {displayAs === 'dropZone' ? (
                  <CrossCircleIcon />
                ) : (
                  <TrashIcon width={12} height={13} />
                )}
              </Button>
            </S.FileListCell>
          </React.Fragment>
        ))}
      </S.FileListGrid>
    </S.FileListContainer>
  ) : null;
};
