export const FORMS_TITLES = {
  CLIENT_ENGAGEMENT: 'Client engagement',
  COLLABORATION_AGREEMENTS: 'Collaboration agreements',
  MARKETING_MATERIALS: 'Marketing materials',
  DATA_ROOM_SET_UP: 'Data room set up',
  KYC_AML: 'KYC / AML',
  CLOSING_DOCUMENTS: 'Closing documents',
  CLOSING_INVOICES: 'Invoice(s)',
};

export const FORMS_SUBTITLES = {
  CLIENT_ENGAGEMENT: 'To be built and signed, for Legal and Compliance review.',
  COLLABORATION_AGREEMENTS:
    'Upload Fee Sharing Agreements (either with another Finalis registered rep. or with an external party).',
  MARKETING_MATERIALS:
    'Submit promotional materials shared to other parties, for Compliance supervision.',
  DATA_ROOM_SET_UP:
    'Set up the Finalis Data Room or share the link with which you will exchange communication materials.',
  KYC_AML: 'Provide contact details, for due diligence on your client and counterparts.',
  CLOSING_DOCUMENTS:
    'Upload files that prove the deal is about to close, e.g. executed purchase agreement, or LOI.',
  CLOSING_INVOICES:
    'Upload the final invoice shared to all counterparties, and provide details on the fee splits.',
};

export const REMOVE_DEAL = {
  PUBLIC: 'Deals shared with your firm can’t be removed.',
  OWNER: 'Only the deal owner can remove the deal.',
};
