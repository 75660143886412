import { OptionsType } from 'react-select';
import {
  EPSTParticipationOptions,
  EPSTSellingCompensationOptions,
} from 'app/compliance/types/formsTypes';

export const PARTICIPATION_OPTIONS: OptionsType<{ label: string; value: string }> = Object.values(
  EPSTParticipationOptions
).map(value => ({
  label: value,
  value,
}));

export const SELLING_COMPENSATION_OPTIONS: OptionsType<{
  label: string;
  value: string;
}> = Object.values(EPSTSellingCompensationOptions).map(value => ({ label: value, value }));

const renderHasInteractedWithOtherIndividualLabel = (entityName: string = ''): string =>
  `Have you discussed, promoted, recommended, reviewed, or offered an opinion related to any materials in conjunction ${
    entityName ? `with ${entityName} ` : ''
  }with any other individual or have you solicited, introduced, referred, participated, or in any way facilitated the investment of any other individual or entity${
    entityName ? ` into ${entityName}` : ''
  }?`;

export const PST_QUESTIONS = {
  ISSUER_PUBLICLY_TRADED_COMPANY: 'Is the issuer a publicly-traded company?',
  ISUSE_RRELATED_WITH_ENTITY: 'Do you have a relationship with the entity/issuer?',
  WILL_RECEIVE_SELLING_COMPENSATION:
    'Will you receive selling compensation in connection with the transaction?',
  PREVIOUS_INVESTMENT_ENTITY:
    'Is this investment an addition to a previous investment in the same entity?',
  ENTITY_NAME: 'Name of the entity/issuer',
  SECURITY_TYPE: 'Type of security',
  TRANSACTION_DATE: 'Date of proposed transaction',
  TRANSACTION_AMOUNT: 'Amount of transaction (USD)',
  DESCRIPTION: 'Description of private securities transaction',
  PARTICIPATION:
    'Describe your participation in this transaction (investor, finder, employee, seller of securities, etc.)',
  PREVIOUS_INVESTMENTS: `If an investor AND this investment is an additional investment amount, please disclose the DATE and VALUE of previous investments`,
  RELATIONSHIP_WITH_ENTITY_DETAILS: 'Provide details of your relationship with the entity/issuer',
  INTERACTION_WITH_INDIVIDUAL_DETAILS: 'Please provide details',
  SELLING_COMPENSATIONS: 'Please provide the type of selling compensation you will receive',
  DISCLOSE_DATE: "Previous investment's date",
  INVESTMENT_AMOUNT: 'Previous Investment Amount (USD$)',
  WILL_RECEIVE_SELLING_COMPENSATION_DESCRIPTION:
    "Selling compensation shall mean any compensation paid directly or indirectly from whatever source in connection with or as a result of the purchase or sale of a security, including, though not limited to, commissions; finder's fees; securities or rights to acquire securities; rights of participation in profits, tax benefits, or dissolution proceeds, as a general partner or otherwise; or expense reimbursements.",
  renderHasInteractedWithOtherIndividualLabel,

  SYMBOL_AND_EXCHANGE: 'Provide the symbol and exchange where listed',
};

export const PST_TEXTS = {
  infoNotificationLabel:
    'Information notification - All registered and non-registered associated persons must provide advance written notice to, and receive written approval from, Finalis\' Compliance department prior to engaging in any private securities transaction. A private securities transaction is defined as "a securities transaction outside the regular course or scope of an associated person\'s employment with a member, including, but not limited to, new offerings of securities which are not registered with the SEC."',
  entityNameLabel: PST_QUESTIONS.ENTITY_NAME,
  entityNamePlaceholder: 'Name of the entity/issuer',
  entityNameErrorMessage: 'Please provide a valid entity/issuer name.',
  securityTypeLabel: PST_QUESTIONS.SECURITY_TYPE,
  securityTypePlaceholder: 'Type of security',
  securityTypeErrorMessage: 'Please provide a valid type of security.',
  transactionDateLabel: PST_QUESTIONS.TRANSACTION_DATE,
  transactionDatePlaceholder: 'Select a date',
  transactionDateErrorMessage: 'Please provide a valid date of proposed transaction.',
  transactionAmountLabel: PST_QUESTIONS.TRANSACTION_AMOUNT,
  transactionAmountPlaceholder: 'Amount (USD)',
  transactionAmountErrorMessage: 'Please provide a whole value between $0-$2,147,483,647.',
  descriptionLabel: 'Description of private securities transaction',
  descriptionPlaceholder: 'Description of private securities transaction',
  descriptionErrorMessage: 'Please provide a valid description.',
  participationLabel:
    'Describe your participation in this transaction (investor, finder, employee, seller of securities, etc.)',
  participationErrorMessage: 'Please provide a valid participation.',
  participationPlaceholder: 'Select...',
  otherParticipationPlaceholder: 'Describe your participation in this transaction',
  otherParticipationErrorMessage: 'Please provide a valid participation.',
  issuerPubliclyTradedCompanyLabel: PST_QUESTIONS.ISSUER_PUBLICLY_TRADED_COMPANY,
  symbolAndExchangeLabel: PST_QUESTIONS.SYMBOL_AND_EXCHANGE,
  symbolAndExchangePlaceholder: 'Symbol and exchange where listed',
  symbolAndExchangeErrorMessage: 'Please provide valid symbol and exchange.',
  previousInvestmentsLabel: PST_QUESTIONS.PREVIOUS_INVESTMENTS,
  previousInvestmentsPlaceholder: 'Dates and values of previous investments',
  previousInvestmentsErrorMessage: 'Please provide valid dates and values of previous investments.',
  isUserRelatedWithEntityLabel: PST_QUESTIONS.ISUSE_RRELATED_WITH_ENTITY,
  relationshipWithEntityDetailsLabel: PST_QUESTIONS.RELATIONSHIP_WITH_ENTITY_DETAILS,
  relationshipWithEntityDetailsPlaceholder: 'Details of your relationship with the entity/issuer',
  relationshipWithEntityDetailsErrorMessage:
    'Please provide valid details of your relationship with the entity/issuer.',
  renderHasInteractedWithOtherIndividualLabel,
  interactionWithIndividualDetailsLabel: PST_QUESTIONS.INTERACTION_WITH_INDIVIDUAL_DETAILS,
  interactionWithIndividualDetailsPlaceholder:
    'Details of your interaction with any other individual',
  interactionWithIndividualDetailsErrorMessage:
    'Please provide valid details of your interaction with any other individual.',
  willReceiveSellingCompensationLabel: PST_QUESTIONS.WILL_RECEIVE_SELLING_COMPENSATION,
  willReceiveSellingCompensationDescription:
    PST_QUESTIONS.WILL_RECEIVE_SELLING_COMPENSATION_DESCRIPTION,
  sellingCompensationsLabel: PST_QUESTIONS.SELLING_COMPENSATIONS,
  sellingCompensationsPlaceholder: 'Select...',
  sellingCompensationsErrorMessage: 'Please provide a valid type of selling compensation.',
  otherSellingCompensationsPlaceholder: 'Other selling compensations',
  otherSellingCompensationsErrorMessage: 'Please provide other valid selling compensations.',
  previousInvestmentEntityLabel: PST_QUESTIONS.PREVIOUS_INVESTMENT_ENTITY,
  discloseDateLabel: PST_QUESTIONS.DISCLOSE_DATE,
  discloseDatePlaceholder: 'Select a date',
  discloseDateErrorMessage: 'Please provide a valid disclose date.',
  investmentAmountLabel: PST_QUESTIONS.INVESTMENT_AMOUNT,
  investmentAmountPlaceholder: 'Amount (USD$)',
  investmentAmountErrorMessage: 'Please provide a whole value between $0-$2,147,483,647.',
  fileUploadLabelPart1:
    'Upload the offering document and completed subscription agreement (if currently available, if not please send to ',
  fileUploadLabelPart2: ' upon availability)',
  attestationLabel:
    'I affirm my involvement in the mentioned private securities transaction ("PST"). If a passive investment, I understand that I am prohibited from soliciting, recommending, or referring any other person, including family members and other persons affiliated with Finalis, to invest in this venture; and agree to provide prior written notice to Finalis of any further capital contributions and additional investments.  I agree to promptly notify and receive prior written approval from Finalis should my status in or any other material aspects of this PST change (i.e., compensation, role) from other than what is represented within. I further certify that the foregoing is true and accurate, and I agree to provide Finalis with complete copies of any other information requested related to or concerning this private transaction.',
  pstComments: 'Do you want to add any comments / notes:',
  pstCommentsPlaceholder: 'Comments...',
};
