import gql from 'graphql-tag';

export const CREATE_FORM = gql`
  mutation CREATE_FORM_MUTATION(
    $type: FormType!
    $status: String
    $content: [FormContentInput!]!
    $files: [Upload!]
    $note: String
    $dealId: ID
  ) {
    createForm(
      type: $type
      status: $status
      content: $content
      files: $files
      note: $note
      dealId: $dealId
    ) {
      id
      status
    }
  }
`;

export const UPDATE_FORM = gql`
  mutation UPDATE_FORM_MUTATION($id: String!, $content: [FormContentInput!]!) {
    updateFormContent(id: $id, content: $content) {
      id
      type
    }
  }
`;

export const SUBMIT_FORM = gql`
  mutation SUBMIT_FORM_MUTATION($input: UpdateFormInput!, $files: [Upload!]) {
    updateForm(input: $input, files: $files) {
      id
      status
    }
  }
`;

export const DEACTIVATE_OBA_FORM = gql`
  mutation DEACTIVATE_OBA($id: ID!) {
    deactivateOBA(id: $id) {
      id
      status
      isActive
    }
  }
`;

export const CREATE_GIFT_REPORT = gql`
  mutation createGiftReport($report: GiftReportInput!) {
    createGiftReport(report: $report) {
      id
      userId
      receivedGivenGifts
      receivedGivenGiftsDetail
      hostedEvents
      hostedEventsDetail
      charitableContributions
      charitableContributionsDetail
      createdAt
    }
  }
`;

export const DOWNLOAD_TEMPLATE = gql`
  mutation DOWNLOAD_TEMPLATE_MUTATION($documentId: String!) {
    downloadTemplate(documentId: $documentId) {
      pdf
    }
  }
`;

export const PROCESS_DOCU_SIGN_LINK = gql`
  mutation PROCESS_DOCU_SIGN_LINK_MUTATION($checkInRecordId: ID!) {
    processDocuSignLink(checkInRecordId: $checkInRecordId) {
      url
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation CREATE_NOTE_MUTATION($input: CreateNoteInput!) {
    createNote(input: $input) {
      id
      type
      content
      entity
      entityId
      attachments {
        filename
        link
      }
      authorId
      author {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_CHECK_IN_STEP = gql`
  mutation UPDATE_CHECK_IN_STEP_MUTATION($input: UpdateCheckInStepInput) {
    updateCheckInStep(input: $input) {
      checkInRecordId
      checkList {
        key
        label
        checked
      }
      canSign
      nextCheckInDate
    }
  }
`;

export const PREVIEW_TEMPLATE = gql`
  mutation PREVIEW_TEMPLATE_MUTATION($documentId: String!) {
    previewTemplate(documentId: $documentId) {
      url
    }
  }
`;

export const ATTEST = gql`
  mutation ATTEST_MUTATION($attestInput: AttestInput!) {
    attest(input: $attestInput) {
      id
      userId
      reviewerId
      attestation
      status
      attested
      createdAt
      updatedAt
      timestamp
      dueDate
      dueDateStatus
    }
  }
`;

export const SKIP_ATTESTATION_ALERT = gql`
  mutation SKIP_ATTESTATION_ALERT_MUTATION($attestInput: AttestInput!) {
    skipAlert(input: $attestInput) {
      id
      skipAlertAt
    }
  }
`;

export const DELETE_ATTACHMENT = gql`
  mutation DELETE_ATTACHMENT($input: DeleteAttachmentInput!) {
    deleteAttachment(input: $input) {
      filename
      link
      origin
      approved
      deleted
    }
  }
`;
