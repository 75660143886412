export type IUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  platformRole: EPlatformRole;
  active: boolean;
};

export enum EPlatformRole {
  ADMIN = 'admin',
  COMPLIANCE = 'compliance',
  USER = 'user',
}
