import * as yup from 'yup';
import validateFileExtension, {
  basicRequiredInputValidation,
  REQUIRED_MESSAGE,
  validateFileName,
} from '../../../../utils/helpers/formValidators';

export const validationSchema = yup.object().shape({
  accountOwner: basicRequiredInputValidation(),
  accountNumber: basicRequiredInputValidation(),
  financialInstitutionName: basicRequiredInputValidation(),
  accountType: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .required(REQUIRED_MESSAGE),
  files: yup
    .array()
    .required(REQUIRED_MESSAGE)
    .test('', 'Some file has an invalid name', validateFileName)
    .test('', 'Some file has an invalid extension', validateFileExtension),
});
