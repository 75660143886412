import React, { useContext } from 'react';

// Constants
import { NDMarketplaceTexts } from '../NewDealFormTexts';

// Components
import { FormGroup, TextArea, OptionCard, VStack, Alert } from '../../../../../common/components';

// Context
import { NewDealHandlerContext, NewDealForm } from '../NewDealFormHandler';

// Helper
import { formControlChangeHandlers } from '../../../FormControlChangeHandlers/FormControlChangeHandlers';

// Icons
import { ThunderIcon, OpenEyeIcon } from '../../../../../common/icons';

// Style
import * as S from '../NewDealFormStyled';

const NDMarketplaceInfo = () => {
  const { form } = useContext(NewDealHandlerContext);
  const { values, touched, errors, setFieldValue } = form;
  const { handleFieldChange } = formControlChangeHandlers<NewDealForm>(setFieldValue);

  const DATA_TEST_ID_BASE = "NDMarketplaceInfo__"

  return (
    <>
      <S.NewDealTitle>{NDMarketplaceTexts.TITLE}</S.NewDealTitle>
      <S.NewDealSubtitle>
        {NDMarketplaceTexts.SUBTITLE}
        <S.RequiredLabel>(required)</S.RequiredLabel>
      </S.NewDealSubtitle>
      <Alert
        variant="referral"
        text={
          <>
            Intending to refer? Complete the{' '}
            <a href="https://form.finalis.com/fs/deal-referral" target="_blank" rel="noreferrer">
              Referral Form
            </a>{' '}
            instead.
          </>
        }
      />
      <VStack
        spacing={16}
        style={{ marginTop: '16px' }}
        onChange={handleFieldChange('marketplaceDealType')}
      >
        <OptionCard
          title={NDMarketplaceTexts.NON_COLLABORATIVE_TITLE}
          subtitle={NDMarketplaceTexts.NON_COLLABORATIVE_SUBTITLE}
          icon={<OpenEyeIcon width="20px" height="16px" />}
          name="collaboration"
          data-testid={DATA_TEST_ID_BASE+"collaboration_non-collab"}
          value="non-collab"
          checked={values.marketplaceDealType === 'non-collab'}
        />
        <OptionCard
          title={NDMarketplaceTexts.COLLABORATIVE_TITLE}
          subtitle={NDMarketplaceTexts.COLLABORATIVE_SUBTITLE}
          icon={<ThunderIcon width="16px" height="20px" />}
          name="collaboration"
          data-testid={DATA_TEST_ID_BASE+"collaboration_collab"}
          value="collab"
          checked={values.marketplaceDealType === 'collab'}
        />
      </VStack>
      <FormGroup
        label={NDMarketplaceTexts.DESCRIPTION_TITLE}
        optional={values.marketplaceDealType !== 'collab'}
        required={values.marketplaceDealType === 'collab'}
        error={touched.marketplaceDescription && errors.marketplaceDescription}
        helperText={NDMarketplaceTexts.DESCRIPTION_HELPER}
      >
        <TextArea
          rows={6}
          name="marketplaceDescription"
          data-testid={DATA_TEST_ID_BASE+"marketplaceDescription"}
          onChange={handleFieldChange('marketplaceDescription')}
          value={values.marketplaceDescription}
          placeholder={NDMarketplaceTexts.DESCRIPTION_PLACEHOLDER}
        />
      </FormGroup>
    </>
  );
};

export default NDMarketplaceInfo;
