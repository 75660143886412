import styled from 'styled-components';

export const SideDrawerContent = styled.div`
  ${({ theme }) => {
    const { colors, spacing, typography, radii } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      .subheader {
        margin-bottom: ${spacing['02']};
        display: flex;

        .badge-container {
          margin-left: ${spacing['02']};
          margin-top: ${spacing['01']};
        }
      }
      
      .message-box {
        background-color: ${colors.palette.neutral['200']};
        border-radius: ${radii.lg};
        display: flex;
        margin-top: ${spacing['04']};
        padding: ${spacing['04']};
        
        .icon-container {
          display: flex;
          align-items: center;
        }
        
        .message-container {
          margin-left: ${spacing['04']};
          
          p {
            line-height: 24px;
            font-size: ${typography.sizes['04']};
            font-weight: ${typography.weights.regular};
            font-family: ${typography.fonts.body};
            letter-spacing: 0.25px;
          }
        }
      }
      
      .textarea-container {
        padding-top: ${spacing['06']};

        p {
          font-weight: ${typography.weights.regular};
          color: ${colors.palette.neutral['600']};
          font-size: ${typography.sizes['03']};
          margin-bottom: ${spacing['01']};
        }
      }

      .textarea-container {
        padding-top: ${spacing['07']};

        p {
          font-weight: ${typography.weights.regular};
          color: ${colors.palette.neutral['600']};
          font-size: ${typography.sizes['03']};
          margin-bottom: ${spacing['01']};
        }
      }

      h2 {
        font-size: ${typography.sizes['05']};
        line-height: 22.5px;
        letter-spacing: 0.25px;
        font-weight: ${typography.weights.medium};
        color: ${colors.palette.neutral['600']};
        display: inline-block;
      }

      p {
        color: ${colors.palette.neutral['600']};
        font-size: ${typography.sizes['04']}
        letter-spacing: 0.25px;
        font-weight: ${typography.weights.regular};
        line-height: 24px;
      }
    `;
  }}
`;

export const ButtonsContainer = styled.div`
  ${({ theme }) => {
    const { colors, spacing, typography, radii, sizes } = theme.foundations;
    return `
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-self: flex-start;
      .filter-button {
        width: 176px;
        height: ${sizes['09']};
        border-radius: ${radii.md};
        background-color: ${colors.palette.neutral['600']};
        justify-content: center;
        display: flex;
        align-items: center;
        cursor: pointer;

        p {
          color: ${colors.palette.neutral['50']};
          font-size: ${typography.sizes['04']};
          font-family: ${typography.fonts.body};
          letter-spacing: 0.5px;
        }
      }

      .cancel-button {
        background-color: ${colors.palette.transparent};
        color: ${colors.palette.neutral['600']};
        margin-right: ${spacing['10']};
        cursor: pointer;
      }

      p {
        font-family: ${typography.fonts.body};
        font-weight: ${typography.weights.regular};
        font-size: ${typography.sizes['04']};
        line-height: 16px;
        letter-spacing: 0.5px;
        display: inline-block;
      }
    `;
  }}
`;
