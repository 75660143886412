// Libraries
import React from 'react';
import { IconProps } from './types';

export const LogOut2Icon = ({
  width = '16px',
  height = '17px',
  color = 'currentColor',
  style = {},
}: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 16 17" fill="none">
    <path
      d="M12.6114 1.83325H7.27804C6.7476 1.83325 6.23889 2.04397 5.86382 2.41904C5.48875 2.79411 5.27804 3.30282 5.27804 3.83325V5.83325C5.27804 6.01006 5.34827 6.17963 5.4733 6.30466C5.59832 6.42968 5.76789 6.49992 5.9447 6.49992C6.12151 6.49992 6.29108 6.42968 6.41611 6.30466C6.54113 6.17963 6.61137 6.01006 6.61137 5.83325V3.83325C6.61137 3.65644 6.68161 3.48687 6.80663 3.36185C6.93166 3.23682 7.10122 3.16659 7.27804 3.16659H12.6114C12.7882 3.16659 12.9578 3.23682 13.0828 3.36185C13.2078 3.48687 13.278 3.65644 13.278 3.83325V13.1666C13.278 13.3434 13.2078 13.513 13.0828 13.638C12.9578 13.763 12.7882 13.8333 12.6114 13.8333H7.27804C7.10122 13.8333 6.93166 13.763 6.80663 13.638C6.68161 13.513 6.61137 13.3434 6.61137 13.1666V11.1666C6.61137 10.9898 6.54113 10.8202 6.41611 10.6952C6.29108 10.5702 6.12151 10.4999 5.9447 10.4999C5.76789 10.4999 5.59832 10.5702 5.4733 10.6952C5.34827 10.8202 5.27804 10.9898 5.27804 11.1666V13.1666C5.27804 13.697 5.48875 14.2057 5.86382 14.5808C6.23889 14.9559 6.7476 15.1666 7.27804 15.1666H12.6114C13.1418 15.1666 13.6505 14.9559 14.0256 14.5808C14.4007 14.2057 14.6114 13.697 14.6114 13.1666V3.83325C14.6114 3.30282 14.4007 2.79411 14.0256 2.41904C13.6505 2.04397 13.1418 1.83325 12.6114 1.83325Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.41622 6.97132C4.67657 6.71097 4.67657 6.28886 4.41622 6.02851C4.15587 5.76816 3.73376 5.76816 3.47341 6.02851L1.56769 7.93423C1.25527 8.24665 1.25527 8.75318 1.56769 9.0656L3.47341 10.9713C3.73376 11.2317 4.15587 11.2317 4.41622 10.9713C4.67657 10.711 4.67657 10.2889 4.41622 10.0285L3.55429 9.16659L9.9447 9.16659C10.3129 9.16659 10.6114 8.86811 10.6114 8.49992C10.6114 8.13173 10.3129 7.83325 9.9447 7.83325L3.55429 7.83325L4.41622 6.97132Z"
      fill={color}
    />
  </svg>
);
