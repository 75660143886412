import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import _get from 'lodash/get';
import { Calendar, CalendarProps, DayRange, DayValue } from 'react-modern-calendar-datepicker';
import { createPopper } from '@popperjs/core';

import DatePickerInput, { DatePickerInputProps } from './DatePicketInput';
import { getValueType, TYPE_SINGLE_DATE, TYPE_MUTLI_DATE, TYPE_RANGE } from './datePickerHelper';
import { Input } from '../Input/Input';
import { CalendarIcon } from 'app/common/icons';

interface DatePickerProps
  extends CalendarProps<DayValue | DayRange>,
    Omit<DatePickerInputProps, 'locale' | 'value'> {
  calendarPopperPosition?: string;
  portalTarget?: string;
}

export const DatePicker = ({
  value,
  onChange,
  formatInputText,
  inputPlaceholder,
  inputClassName,
  inputName,
  renderInput,
  wrapperClassName,
  portalTarget = '[data-reach-dialog-overlay]',
  ...calendarProps
}: DatePickerProps) => {
  const calendarContainerElement = useRef<HTMLDivElement>();
  const inputElement = useRef<HTMLInputElement>(null);
  const shouldPreventToggle = useRef(false);
  const [isCalendarOpen, setCalendarVisiblity] = useState(false);

  useEffect(() => {
    const handleBlur = () => {
      setCalendarVisiblity(false);
    };
    window.addEventListener('blur', handleBlur, false);
    return () => {
      window.removeEventListener('blur', handleBlur, false);
    };
  }, []);

  // handle input focus/blur
  useEffect(() => {
    const valueType = getValueType(value);
    const valueFrom = _get(value, 'from');
    const valueTo = _get(value, 'to');

    if (valueType === TYPE_MUTLI_DATE) return; // no need to close the calendar

    const shouldCloseCalendar =
      valueType === TYPE_SINGLE_DATE ? !isCalendarOpen : !isCalendarOpen && valueFrom && valueTo;

    if (shouldCloseCalendar && inputElement && inputElement.current) inputElement.current.blur();
  }, [value, isCalendarOpen]);

  const handleBlur: React.FocusEventHandler<HTMLDivElement> = e => {
    const relatedTarget = e.relatedTarget as HTMLInputElement;
    const calendarContainer: HTMLDivElement | undefined = _get(calendarContainerElement, 'current');
    const input: HTMLInputElement | null = _get(inputElement, 'current');
    e.persist();
    if (!isCalendarOpen) return;
    const isInnerElementFocused = calendarContainer && calendarContainer.contains(relatedTarget);
    if (shouldPreventToggle.current) {
      shouldPreventToggle.current = false;
      input && input.focus();
    } else if (isInnerElementFocused && relatedTarget) {
      relatedTarget.focus();
    } else {
      setCalendarVisiblity(false);
    }
  };

  const openCalendar = () => {
    if (!shouldPreventToggle.current) setCalendarVisiblity(true);
  };

  useEffect(() => {
    if (calendarContainerElement.current && inputElement.current && isCalendarOpen) {
      createPopper(inputElement.current, calendarContainerElement.current);
    }
  }, [isCalendarOpen]);

  const handleCalendarChange = (newValue: any) => {
    const valueType = getValueType(value);
    onChange && onChange(newValue);
    if (valueType === TYPE_SINGLE_DATE) setCalendarVisiblity(false);
    else if (valueType === TYPE_RANGE && newValue.from && newValue.to) setCalendarVisiblity(false);
  };

  const handleKeyUp = (prop: { key: string }) => {
    switch (prop.key) {
      case 'Enter':
        setCalendarVisiblity(true);
        break;
      case 'Escape':
        setCalendarVisiblity(false);
        shouldPreventToggle.current = true;
        break;
    }
  };

  useEffect(() => {
    const input = _get(inputElement, 'current') as HTMLInputElement;
    if (!isCalendarOpen && shouldPreventToggle.current) {
      shouldPreventToggle.current = false;
      if (input) {
        input.focus();
      }
    }
  }, [shouldPreventToggle, isCalendarOpen]);

  const appendTo = document.querySelector(portalTarget);

  const defaultInput = ({ ref, inputProps: { className, ...rest } }: any) => (
    <Input
      onClick={openCalendar}
      ref={ref}
      {...rest}
      inputStyles={{ cursor: 'default' }}
      rightIcon={<CalendarIcon />}
    />
  );

  return (
    <div
      onBlur={handleBlur}
      onKeyUp={handleKeyUp}
      className={`DatePicker ${wrapperClassName}`}
      role="presentation"
    >
      <DatePickerInput
        ref={inputElement as any}
        formatInputText={formatInputText}
        value={value}
        inputPlaceholder={inputPlaceholder}
        inputClassName={inputClassName}
        renderInput={renderInput || defaultInput}
        inputName={inputName}
        locale={calendarProps.locale}
      />
      {isCalendarOpen &&
        (!appendTo
          ? null
          : createPortal(
              <>
                <div
                  ref={calendarContainerElement as React.LegacyRef<HTMLDivElement>}
                  className="DatePicker__calendarContainer"
                  data-testid="calendar-container"
                  role="presentation"
                  style={{ zIndex: 2 }}
                  onMouseDown={() => {
                    shouldPreventToggle.current = true;
                  }}
                >
                  <Calendar
                    value={value as DayValue}
                    onChange={handleCalendarChange}
                    {...calendarProps}
                  />
                </div>
                <div className="DatePicker__calendarArrow" />
              </>,
              appendTo
            ))}
    </div>
  );
};

export default DatePicker;
