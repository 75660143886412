import React from 'react';
import { Card, CardDivider, CardHeader, HStack, Text } from 'app/common/components';
import { BigLockIcon, RightArrow } from 'app/common/icons';
import * as S from './DealRegulationScreenStyled';

export enum FormStepStatus {
  READY = 'READY',
  BLOCKED = 'BLOCKED',
  COMPLETED = 'COMPLETED',
}

export enum FormKeys {
  KYC = 'DRKYCForm',
  MMAT = 'MarketingMaterialsFormDrawer',
}
export interface DealRegulationFormCardProps {
  stepStatus?: FormStepStatus;
  title: string;
  subtitle: string;
  icon: JSX.Element;
  submissions: number;
  footerText: React.ReactNode | string;
  customRules?: Record<string, boolean>;
  completeAction: () => void;
  viewDetailsAction: () => void;
}

export const DealRegulationFormCard = ({
  stepStatus = FormStepStatus.BLOCKED,
  title,
  subtitle,
  icon,
  submissions,
  footerText,
  completeAction,
  viewDetailsAction,
}: DealRegulationFormCardProps) => {
  const cardVariant = stepStatus === FormStepStatus.BLOCKED ? 'outline' : 'default';

  return (
    <Card
      variant={cardVariant}
      style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <CardHeader style={{ padding: '24px 24px 8px 24px' }}>
        <S.CardActionPanel>
          <S.CardIconContainer className={cardVariant}>
            {stepStatus === FormStepStatus.BLOCKED ? (
              <BigLockIcon color="#47455F" width={13} height={17} />
            ) : (
              icon
            )}
          </S.CardIconContainer>
        </S.CardActionPanel>
      </CardHeader>
      <S.CardBodyStyled style={{ flex: 1 }}>
        <S.Title className={cardVariant}>{title}</S.Title>

        <S.Subtitle className={cardVariant}>{subtitle}</S.Subtitle>
      </S.CardBodyStyled>

      {stepStatus !== FormStepStatus.BLOCKED && <CardDivider />}

      <S.CardFooterStyled padding="16px 24px">
        {stepStatus !== FormStepStatus.BLOCKED ? (
          <>
            <HStack justifyContent="space-between" spacing={16} alignItems="center">
              <>
                <Text variant="value" style={{ color: '#6A678E' }}>
                  {submissions && submissions > 0
                    ? `${submissions} submission(s)`
                    : 'No submission'}
                </Text>
              </>
              {stepStatus === FormStepStatus.READY ? (
                <>
                  <S.CompleteButton className="complete" onClick={completeAction}>
                    Complete
                    <RightArrow color="#185ADC" style={{ marginLeft: '9px' }} />
                  </S.CompleteButton>
                </>
              ) : (
                <>
                  <S.CompleteButton className="view" onClick={viewDetailsAction}>
                    View
                    <RightArrow style={{ marginLeft: '9px' }} />
                  </S.CompleteButton>
                </>
              )}
            </HStack>
          </>
        ) : (
          <Text variant="value" style={{ color: '#6A678E', fontSize: '12px' }}>
            {footerText}
          </Text>
        )}
      </S.CardFooterStyled>
    </Card>
  );
};
