import { IMarketplaceDeal } from 'app/marketplace/types';

export enum ELabels {
  MARKETPLACE_SETTINGS_TAB_TITLE = 'Finalis Marketplace™',
  MARKETPLACE_PUBLISH_LABEL = 'Publish in the Mandate Marketplace',
  MARKETPLACE_SUBTITLE_SECTION = 'Additional information',
  MARKETPLACE_COMPANY_PROFILE_LABEL = 'Company Profile',
  MARKETPLACE_DESCRIPTION_LABEL = 'Notes',
  MARKETPLACE_RETAINER_LABEL = 'Retainer',
  MARKETPLACE_RETAINER_AMOUNT_LABEL = 'Retainer Amount',
  MARKETPLACE_TAIL_PERIOD_EXPIRATION_LABEL = 'Tail Period Expiration',
  MARKETPLACE_LOI_SIGNED_LABEL = 'Letter of Intent (LOI) signed',
  MARKETPLACE_EL_SIGNED_DATE_LABEL = 'Engagement Letter Signed Date (optional)',
  MARKETPLACE_GROSS_FEE_EARNED_LABEL = 'Gross Fee Earned',
  MARKETPLACE_CALCULATED_PERCENT_RATE = 'Gross Fee Earned Percent Rate',
  UPDATE_DEAL_ACTION = 'Save Changes',
  MARKETPLACE_SUCCESS_FEE_LABEL = 'Success fee',
}

export interface IMarketplaceSettingsProps {
  mDeal: IMarketplaceDeal | null;
}
