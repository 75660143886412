// Libraries
import React, { useContext, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import Select from 'react-select';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _xorWith from 'lodash/xorWith';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';

// Context
import { AlertContext } from 'app/notifications/context/Alert/Alert';

// Helpers & Utils
import { getError } from 'app/utils/helpers/helpers';

// Poki
import { Button, EButtonType, LoadingCircle } from '@dealsyte/poki';

// Mutations & Query
import { GET_USER_PROFILE_QUERY } from 'api/graphql/users/types/GET_USER_PROFILE_QUERY';
import { GET_USER_PROFILE } from 'api/graphql/users/queries';
import { UPDATE_PROFILE } from 'api/graphql/users/mutations';
import {
  UPDATE_PROFILE_MUTATION,
  UPDATE_PROFILE_MUTATIONVariables,
} from 'api/graphql/users/types/UPDATE_PROFILE_MUTATION';

// Types
import { EButtonMessages } from 'app/core-tools/due-diligence/types/types';

// hooks
import { useTouchedForm } from 'app/utils/hooks/useTouchedForm';
import { useForm } from '../../../../../../app/utils/hooks/useForm';

// Constants
import stateOptions from './states';

import { IOptionsProps } from './TypedStateRegistrationSettings';
import { ESettingsAccountLabels } from '../../TypedUsersSettingsModal';

// Styled components
import {
  BodySectionContainer,
  SubmitButtonsBotomFixedContainer,
  Text,
  TextContainer,
  FormStateRegistrationSection,
  LoaderContainer,
  MessageContainer,
  MessageBodyContainer,
  ParagraphMessageContainer,
  DotOption,
  FooterMessageContainer,
  FooterMessageLabel,
} from '../../StyledUsersSettingsModal';
import {
  SelectLabel,
  CustomMultiSelectStyles,
  SRContainer,
} from './StyledStateRegistrationSettings';

interface StateRegistrationForm {
  states: Array<{ label: string; value: string }>;
}

function StateRegistrationSettings({ stateRegInfo }: any) {
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);
  const [getUserProfile, { updateQuery, loading: profileLoading }] = useLazyQuery<
    GET_USER_PROFILE_QUERY
  >(GET_USER_PROFILE, {
    fetchPolicy: 'no-cache',
    onCompleted({ profile }) {
      updateQuery(() => ({
        profile,
      }));
    },
  });

  const [updateProfileMutation, { loading: loadingProfileMutation }] = useMutation<
    UPDATE_PROFILE_MUTATION,
    UPDATE_PROFILE_MUTATIONVariables
  >(UPDATE_PROFILE, {
    onError(error) {
      showErrorToast({ title: getError(error) });
    },
    onCompleted() {
      showSuccessToast({
        title: ESettingsAccountLabels.SUCCESS_UPDATE_PROFILE_MESSAGE,
      });
      getUserProfile();
    },
  });

  const { setIsTouched } = useTouchedForm();

  const { values, dirty, setFieldValue, setValues } = useForm<StateRegistrationForm>({
    initialValues: { states: [] },
  });

  const buildInitialStates = () => {
    if (stateRegInfo) {
      return stateRegInfo
        .map((stateValue: string) => _find(stateOptions, { value: stateValue }))
        .filter((option: any) => !!option);
    }
    return [];
  };

  useEffect(() => {
    setValues({ states: buildInitialStates() });
  }, [stateRegInfo]);

  useEffect(() => {
    if (dirty) {
      setIsTouched(true);
    }
  }, [dirty]);

  const handleSubmitForm = async () => {
    await updateProfileMutation({
      variables: {
        statesInfo: {
          states: values.states.map(state => state.value),
        },
      },
    });
    setIsTouched(false);
  };

  const isSameSelection = (states: IOptionsProps) => {
    const initialSelection = buildInitialStates();
    return _isEmpty(_xorWith(states, initialSelection, _isEqual));
  };

  if (profileLoading) {
    return (
      <LoaderContainer>
        <LoadingCircle height={30} style={{ color: '#4485eb' }} />
      </LoaderContainer>
    );
  }

  const currentYear = () => dayjs().year();

  const handleStateSelect = (selectedStates: any = []) => {
    if (!selectedStates) {
      setFieldValue('states', []);
      return;
    }
    const selectedAllStates = selectedStates.some((state: any) => state.value === 'ALL');
    if (selectedAllStates) {
      setFieldValue('states', stateOptions.filter(option => option.value !== 'ALL'));
      return;
    }
    setFieldValue('states', selectedStates);
  };

  return (
    <>
      <TextContainer>
        <Text isSubTitle>State Registrations</Text>
      </TextContainer>

      <SRContainer>
        <MessageContainer>
          <MessageBodyContainer>
            During the ONBOARDING process, follow this workflow:
            <ParagraphMessageContainer>
              <DotOption />
              If you are unlicensed, SKIP THIS STEP.
            </ParagraphMessageContainer>
            <ParagraphMessageContainer>
              <DotOption />
              If you are licensed, submit a registration in your HOME state ONLY.
            </ParagraphMessageContainer>
            <ParagraphMessageContainer>
              <DotOption />
              If you are uncertain, skip this step and send an email to
              customer-success@finalis.com.
            </ParagraphMessageContainer>
            <ParagraphMessageContainer>
              <br />
              After Finalis has submitted your U4 to FINRA, if you have any active deal(s), follow
              this workflow:
            </ParagraphMessageContainer>
            <ParagraphMessageContainer>
              <DotOption />
              If you have an executed engagement letter, register in the state where your client is
              located.
            </ParagraphMessageContainer>
            <ParagraphMessageContainer>
              <DotOption />
              Depending on the type of transaction:
            </ParagraphMessageContainer>
            <ParagraphMessageContainer>
              &emsp;&emsp;&emsp;{`(i) M&A: register where the investor (counterparty) is located`}
            </ParagraphMessageContainer>
            <ParagraphMessageContainer>
              &emsp;&emsp;&emsp;
              {`(ii) Capital Raise: register in EVERY state in which you solicit investors`}
            </ParagraphMessageContainer>
            <FooterMessageContainer>
              <FooterMessageLabel>
                Please discuss your state registration plans with your Managing Director, if
                applicable, as your firm will be invoiced for all states in which you are
                registered.
              </FooterMessageLabel>
            </FooterMessageContainer>
          </MessageBodyContainer>
        </MessageContainer>
        <FormStateRegistrationSection>
          <SelectLabel isBold>
            Select the states where you&apos;d like to renew registration:
          </SelectLabel>
          <BodySectionContainer isMain={false}>
            <Select
              options={stateOptions}
              placeholder={<span>Select the states...</span>}
              styles={CustomMultiSelectStyles}
              isMulti
              menuColor="red"
              menuPlacement="top"
              maxMenuHeight={200}
              name="states"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleStateSelect}
              value={values.states}
            />
          </BodySectionContainer>
          <SelectLabel>
            If applicable, select any additional states where you&apos;d like to register for{' '}
            {currentYear()}.
          </SelectLabel>
        </FormStateRegistrationSection>
      </SRContainer>
      <SubmitButtonsBotomFixedContainer>
        <Button
          buttonType={EButtonType.primary}
          onClick={handleSubmitForm}
          disabled={
            _get(values, 'states.value') === '' ||
            isSameSelection(values.states) ||
            loadingProfileMutation
          }
        >
          {EButtonMessages.SAVE_ACTION}
        </Button>
      </SubmitButtonsBotomFixedContainer>
    </>
  );
}

export default StateRegistrationSettings;
