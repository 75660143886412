import * as React from 'react';
import { IconProps } from './types';

export const PencilEditIcon = ({
  color = 'currentColor',
  style = {},
  height = 18,
  width = 21,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    style={style}
    fill="none"
    viewBox="0 0 18 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.667 4.828a.666.666 0 0 0-.194-.474l-2.826-2.826a.667.667 0 0 0-.474-.194.667.667 0 0 0-.473.194L8.813 3.414l-7.286 7.287a.667.667 0 0 0-.194.473v2.827a.667.667 0 0 0 .667.667h2.827a.666.666 0 0 0 .506-.193l7.247-7.287 1.893-1.854a.796.796 0 0 0 .147-.22.67.67 0 0 0 0-.16.47.47 0 0 0 0-.093l.047-.033ZM4.553 13.334H2.667v-1.886l6.62-6.62 1.886 1.886-6.62 6.62Zm7.56-7.56-1.886-1.886.946-.94 1.88 1.88-.94.946Z"
      fill={color}
    />
  </svg>
);
