import React from 'react';
import { ViewMore } from './StyledViewMoreButton';

interface ViewMoreProps {
  display: boolean;
  handleOnClick: () => void;
}

const ViewMoreButton = (props: ViewMoreProps) => {
  const { display, handleOnClick } = props;

  return (
    <ViewMore display={display} onClick={handleOnClick}>
      View more
    </ViewMore>
  );
};

export default ViewMoreButton;
