import * as React from 'react';
import { IconProps } from './types';

export const ClockCircleIcon = ({ width = 14, height = 14, color = 'currentColor' }: IconProps) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 .332c-3.666 0-6.666 3-6.666 6.667 0 3.666 3 6.666 6.667 6.666 3.666 0 6.666-3 6.666-6.666 0-3.667-3-6.667-6.666-6.667Zm2.334 8c-.2.333-.6.4-.933.267l-1.734-1a.7.7 0 0 1-.333-.6V3.665c0-.4.267-.666.667-.666s.666.266.666.666V6.6l1.4.8c.334.2.4.6.267.933Z"
      fill={color}
    />
  </svg>
);
