import React from 'react';

import { Button, HStack, SideDrawer } from 'app/common/components';
import KYCForm from 'app/compliance/components/forms/KYCForm/KYCForm';
import useStateModal from 'app/utils/hooks/useStateModal';

import { useKYCFormHandler, KYCHandlerProvider } from './KYCFormHandler';

const KYCFormDrawer = () => {
  const handler = useKYCFormHandler();
  const { modalState, hide } = useStateModal('KYCForm');
  const { onSubmitKYCForm, submitting, form } = handler;

  const hideModal = () => {
    hide();
    form.resetForm();
  };

  return (
    <KYCHandlerProvider value={handler}>
      <SideDrawer
        show={modalState.show}
        onClose={hideModal}
        title="DD KYC/AML Form"
        promptOnClose={form.dirty}
        footerContent={({ onClose }) => (
          <HStack justifyContent="flex-end" spacing={16}>
            <Button onClick={onClose} className="tertiary">
              Dismiss
            </Button>
            <Button
              onClick={onSubmitKYCForm}
              disabled={submitting || !form.isValid}
              style={{ width: 176 }}
            >
              Submit
            </Button>
          </HStack>
        )}
      >
        <KYCForm />
      </SideDrawer>
    </KYCHandlerProvider>
  );
};

export default KYCFormDrawer;
