import * as React from 'react';

import { IconProps } from './types';

export const UpperArrow = ({
  width = '12px',
  height = '8px',
  color = 'currentColor',
  style,
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 8"
  >
    <path
      d="M11 5.41 6.71 1.17a1 1 0 0 0-1.42 0L1.05 5.41a1 1 0 1 0 1.41 1.42L6 3.29l3.54 3.54a1 1 0 0 0 1.41 0A1 1 0 0 0 11 5.41Z"
      fill={color}
    />
  </svg>
);
