import {
  EPSTParticipationOptions,
  EPSTSellingCompensationOptions,
} from 'app/compliance/types/formsTypes';
import { buildInitialDatePickerValue, formatDateInput } from 'app/utils/helpers/helpers';
import { FormType } from 'types/graphql-types';
import { PositionMap } from 'app/utils/helpers/formDataMappers';
import each from 'lodash/each';

import { PST_QUESTIONS } from './PSTFormConstants';
import { PSTForm } from './PSTFormHandler';
import Currency from '../../../../utils/helpers/currency';

const mapYesNoAnswer = (value: boolean): string => {
  if (typeof value !== 'boolean') {
    return '';
  }
  return value ? 'Yes' : 'No';
};

const buildPSTParticipation = (
  participation: { label: string; value: string } | null,
  otherParticipation: string = ''
): string => {
  if (participation && participation.value) {
    return participation.value === EPSTParticipationOptions.OTHERS
      ? `Other (${otherParticipation})`
      : participation.value;
  }
  return '';
};

const buildPSTSellingCompensationsList = (
  sellingCompensations: { label: string; value: string }[],
  otherSellingCompensations: string = ''
): string => {
  const compensationsWithoutOther = sellingCompensations
    .map(option => option.value)
    .filter(value => value !== EPSTSellingCompensationOptions.OTHERS)
    .join(', ');
  const other = sellingCompensations
    .filter(option => option.value === EPSTSellingCompensationOptions.OTHERS)
    .map(option => `${option.value} (${otherSellingCompensations})`)
    .join('');

  return `${compensationsWithoutOther}${other ? `, ${other}` : ''}`;
};

const getSellingCompensationsFromString = (sellingCompensations: string) => {
  const splitted = sellingCompensations.split(',').map(option => option.trim());
  let otherSellingsString: string = '';
  const sellingCompensationsOptions: { label: string; value: string }[] = [];
  each(Object.values(EPSTSellingCompensationOptions), item => {
    if (sellingCompensations.includes(item))
      sellingCompensationsOptions.push({ label: item, value: item });
  });

  // eslint-disable-next-line consistent-return
  splitted.forEach(compensation => {
    if (compensation.includes('Other ')) {
      const otherSearch = compensation.match(/\((.*?)\)/);
      otherSellingsString = (otherSearch && otherSearch[1]) || '';

      return {
        label: EPSTSellingCompensationOptions.OTHERS,
        value: EPSTSellingCompensationOptions.OTHERS,
      };
    }
  });

  return {
    sellingCompensations: sellingCompensationsOptions,
    otherSellingsString,
  };
};

export const buildPSTForm = (values: PSTForm) => {
  const {
    transactionAmount,
    transactionDate,
    entityName,
    securityType,
    description,
    participation,
    otherParticipation,
    isIssuerPubliclyTradedCompany,
    symbolAndExchange,
    previousInvestments,
    isUserRelatedWithEntity,
    relationshipWithEntityDetails,
    hasInteractedWithOtherIndividual,
    interactionWithIndividualDetails,
    willReceiveSellingCompensation,
    sellingCompensations,
    otherSellingCompensations,
    alreadyInvestedEntity,
    investmentAmount,
    discloseDate,
    files,
    noteComment,
  } = values;

  const variables = {
    type: FormType.PST,
    content: [
      {
        question: PST_QUESTIONS.ENTITY_NAME,
        input: entityName,
        key: 'entityName',
        value: JSON.stringify(entityName),
      },
      {
        question: PST_QUESTIONS.SECURITY_TYPE,
        input: securityType,
        key: 'securityType',
        value: JSON.stringify(securityType),
      },
      {
        question: PST_QUESTIONS.TRANSACTION_DATE,
        input: formatDateInput(transactionDate),
        key: 'transactionDate',
        value: JSON.stringify(transactionDate),
      },
      {
        question: PST_QUESTIONS.TRANSACTION_AMOUNT,
        input: transactionAmount ? Currency.formatToCurrency(transactionAmount) : '',
        key: 'transactionAmount',
        value: JSON.stringify(
          transactionAmount ? Currency.formatToCurrency(transactionAmount) : ''
        ),
      },
      {
        question: PST_QUESTIONS.DESCRIPTION,
        input: description,
        key: 'description',
        value: JSON.stringify(description),
      },
      {
        question: PST_QUESTIONS.PARTICIPATION,
        input: buildPSTParticipation(participation, otherParticipation),
        key: 'participation',
        value: JSON.stringify(participation),
      },
      {
        question: PST_QUESTIONS.ISSUER_PUBLICLY_TRADED_COMPANY,
        input: mapYesNoAnswer(isIssuerPubliclyTradedCompany),
        key: 'isIssuerPubliclyTradedCompany',
        value: JSON.stringify(isIssuerPubliclyTradedCompany),
      },
    ],
    files,
    note: noteComment,
  };

  if (isIssuerPubliclyTradedCompany) {
    variables.content.push({
      question: PST_QUESTIONS.SYMBOL_AND_EXCHANGE,
      input: symbolAndExchange,
      key: 'symbolAndExchange',
      value: JSON.stringify(symbolAndExchange),
    });
  }

  variables.content.push({
    question: PST_QUESTIONS.PREVIOUS_INVESTMENTS,
    input: previousInvestments,
    key: 'previousInvestments',
    value: JSON.stringify(previousInvestments),
  });

  variables.content.push({
    question: PST_QUESTIONS.ISUSE_RRELATED_WITH_ENTITY,
    input: mapYesNoAnswer(isUserRelatedWithEntity),
    key: 'isUserRelatedWithEntity',
    value: JSON.stringify(isUserRelatedWithEntity),
  });

  if (isUserRelatedWithEntity) {
    variables.content.push({
      question: PST_QUESTIONS.RELATIONSHIP_WITH_ENTITY_DETAILS,
      input: relationshipWithEntityDetails,
      key: 'relationshipWithEntityDetails',
      value: JSON.stringify(relationshipWithEntityDetails),
    });
  }

  variables.content.push({
    question: PST_QUESTIONS.renderHasInteractedWithOtherIndividualLabel(entityName),
    input: mapYesNoAnswer(hasInteractedWithOtherIndividual),
    key: 'hasInteractedWithOtherIndividual',
    value: JSON.stringify(hasInteractedWithOtherIndividual),
  });

  if (hasInteractedWithOtherIndividual) {
    variables.content.push({
      question: PST_QUESTIONS.INTERACTION_WITH_INDIVIDUAL_DETAILS,
      input: interactionWithIndividualDetails,
      key: 'interactionWithIndividualDetails',
      value: JSON.stringify(interactionWithIndividualDetails),
    });
  }

  variables.content.push({
    question: `${PST_QUESTIONS.WILL_RECEIVE_SELLING_COMPENSATION} ${PST_QUESTIONS.WILL_RECEIVE_SELLING_COMPENSATION_DESCRIPTION}`,
    input: mapYesNoAnswer(willReceiveSellingCompensation),
    key: 'willReceiveSellingCompensation',
    value: JSON.stringify(willReceiveSellingCompensation),
  });

  if (willReceiveSellingCompensation) {
    variables.content.push({
      question: PST_QUESTIONS.SELLING_COMPENSATIONS,
      input: buildPSTSellingCompensationsList(sellingCompensations, otherSellingCompensations),
      key: 'sellingCompensations',
      value: JSON.stringify({ sellingCompensations, otherSellingCompensations }),
    });
  }

  variables.content.push({
    question: `${PST_QUESTIONS.PREVIOUS_INVESTMENT_ENTITY}`,
    input: mapYesNoAnswer(alreadyInvestedEntity),
    key: 'alreadyInvestedEntity',
    value: JSON.stringify(alreadyInvestedEntity),
  });

  if (alreadyInvestedEntity) {
    variables.content.push({
      question: PST_QUESTIONS.INVESTMENT_AMOUNT,
      input: investmentAmount ? Currency.formatToCurrency(investmentAmount) : '',
      key: 'investmentAmount',
      value: JSON.stringify(investmentAmount ? Currency.formatToCurrency(investmentAmount) : ''),
    });

    variables.content.push({
      question: PST_QUESTIONS.DISCLOSE_DATE,
      input: formatDateInput(discloseDate),
      key: 'discloseDate',
      value: JSON.stringify(discloseDate),
    });
  }

  return variables;
};

export const PST_VALUE_INFERENCE_MAP: PositionMap = {
  issuerPubliclyTradedCompany: {
    match: PST_QUESTIONS.ISSUER_PUBLICLY_TRADED_COMPANY,
    value: ({ input }) => input === 'Yes',
  },
  isUserRelatedWithEntity: {
    match: PST_QUESTIONS.ISUSE_RRELATED_WITH_ENTITY,
    value: ({ input }) => input === 'Yes',
  },
  willReceiveSellingCompensation: {
    match: `${PST_QUESTIONS.WILL_RECEIVE_SELLING_COMPENSATION} ${PST_QUESTIONS.WILL_RECEIVE_SELLING_COMPENSATION_DESCRIPTION}`,
    value: ({ input }) => input === 'Yes',
  },
  previousInvestmentEntity: {
    match: PST_QUESTIONS.PREVIOUS_INVESTMENT_ENTITY,
    value: ({ input }) => input === 'Yes',
  },
  entityName: { match: PST_QUESTIONS.ENTITY_NAME },
  securityType: { match: PST_QUESTIONS.SECURITY_TYPE },
  transactionDate: {
    match: PST_QUESTIONS.TRANSACTION_DATE,
    value: ({ input }) => buildInitialDatePickerValue(input),
  },
  transactionAmount: {
    match: PST_QUESTIONS.TRANSACTION_AMOUNT,
  },
  description: { match: PST_QUESTIONS.DESCRIPTION },
  participation: {
    match: PST_QUESTIONS.PARTICIPATION,
    value: ({ input }) => buildPSTParticipation({ label: '', value: input }),
  },
  previousInvestments: { match: PST_QUESTIONS.PREVIOUS_INVESTMENTS },
  relationshipWithEntityDetails: {
    match: PST_QUESTIONS.RELATIONSHIP_WITH_ENTITY_DETAILS,
  },
  interactionWithIndividualDetails: {
    match: PST_QUESTIONS.INTERACTION_WITH_INDIVIDUAL_DETAILS,
  },
  sellingCompensations: {
    match: PST_QUESTIONS.SELLING_COMPENSATIONS,
    value: ({ input }) => getSellingCompensationsFromString(input).sellingCompensations,
  },
  otherSellingCompensations: {
    match: PST_QUESTIONS.SELLING_COMPENSATIONS,
    value: ({ input }) => getSellingCompensationsFromString(input).otherSellingsString,
  },
  discloseDate: {
    match: PST_QUESTIONS.DISCLOSE_DATE,
    value: ({ input }) => buildInitialDatePickerValue(input),
  },
  investmentAmount: {
    match: PST_QUESTIONS.INVESTMENT_AMOUNT,
    value: ({ input }) => (input ? Currency.formatToCurrency(Number(input)) : ''),
  },
};
