import * as React from 'react';
import { IconProps } from './types';

export const BankIcon = ({
  width = '17px',
  height = '19px',
  color = 'currentColor',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8336 7.33334C15.943 7.33337 16.0514 7.31183 16.1525 7.26997C16.2536 7.2281 16.3455 7.16672 16.4229 7.08933C16.5003 7.01194 16.5617 6.92006 16.6035 6.81894C16.6454 6.71783 16.6669 6.60945 16.6669 6.50001V4.00001C16.667 3.82515 16.612 3.65472 16.5097 3.51288C16.4075 3.37104 16.2632 3.265 16.0973 3.20981L8.59725 0.709808C8.42608 0.652842 8.24107 0.652842 8.0699 0.709808L0.569902 3.20981C0.403989 3.265 0.259668 3.37104 0.157416 3.51288C0.0551635 3.65472 0.000172364 3.82515 0.000244211 4.00001V6.50001C0.000215745 6.60945 0.0217512 6.71783 0.06362 6.81894C0.105489 6.92006 0.166871 7.01194 0.244258 7.08933C0.321646 7.16672 0.413524 7.2281 0.514641 7.26997C0.615759 7.31183 0.724135 7.33337 0.833578 7.33334H1.66691V13.3202C1.18086 13.4914 0.759715 13.8089 0.4613 14.229C0.162885 14.6492 0.00182954 15.1514 0.000244211 15.6667V17.3333C0.000215745 17.4428 0.0217512 17.5512 0.06362 17.6523C0.105489 17.7534 0.166871 17.8453 0.244258 17.9227C0.321646 18 0.413524 18.0614 0.514641 18.1033C0.615759 18.1452 0.724135 18.1667 0.833578 18.1667H15.8336C15.943 18.1667 16.0514 18.1452 16.1525 18.1033C16.2536 18.0614 16.3455 18 16.4229 17.9227C16.5003 17.8453 16.5617 17.7534 16.6035 17.6523C16.6454 17.5512 16.6669 17.4428 16.6669 17.3333V15.6667C16.6653 15.1514 16.5043 14.6492 16.2059 14.229C15.9074 13.8089 15.4863 13.4914 15.0002 13.3202V7.33334H15.8336ZM15.0002 16.5H1.66691V15.6667C1.66713 15.4457 1.755 15.2339 1.91123 15.0777C2.06746 14.9214 2.2793 14.8336 2.50024 14.8333H14.1669C14.3879 14.8336 14.5997 14.9214 14.7559 15.0777C14.9122 15.2339 15 15.4457 15.0002 15.6667V16.5ZM3.33358 13.1667V7.33334H5.00024V13.1667H3.33358ZM6.66691 13.1667V7.33334H10.0002V13.1667H6.66691ZM11.6669 13.1667V7.33334H13.3336V13.1667H11.6669ZM1.66691 5.66668V4.60059L8.33358 2.37809L15.0002 4.60059V5.66668H1.66691Z"
      fill={color}
    />
  </svg>
);
