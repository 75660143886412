// vendors
import * as yup from 'yup';
import _get from 'lodash/get';

// Types
import { CREATE_FORM_MUTATIONVariables } from 'api/graphql/compliance/types/CREATE_FORM_MUTATION';
import { BankAccountInput, FormType } from '../../../../../../types/graphql-types';

// Constants
import {
  DATA_CHANGE_REQUEST_TYPES,
  DATA_CHANGE_REQUEST_IDENTIFIERS,
} from '../../../../../utils/constants/dcrConstants';

const LLC_REGEX = /(^|[\s'"¨`´.,;:~&*$#@%^+=|¡!¿?_()<>/[\]{}\\]|-)(l\.*l\.*c)([\s'"¨`´.,;:~&*$#@%^+=|¡!¿?_()<>/[\]{}\\]|-|$)/iu;
const REQUIRED_FIELD = 'This field is required';

function bankAccountNameTest(bankAccountName: string = ''): boolean {
  return !LLC_REGEX.test(bankAccountName);
}

export const accountBankSettingsSchema = yup.object().shape({
  bankName: yup
    .string()
    .trim()
    .required(REQUIRED_FIELD)
    .max(100, 'Should not be more than 100 characters'),
  bankAccountName: yup
    .string()
    .trim()
    .required(REQUIRED_FIELD)
    .max(100, 'Should not be more than 100 characters')
    .test(
      'only-personal-bank-accounts-are-allowed',
      'Only personal bank accounts are allowed.',
      bankAccountNameTest
    ),
  bankAccountNumber: yup
    .string()
    .trim()
    .required(REQUIRED_FIELD)
    .max(100, 'Should not be more than 100 characters'),
  abaNumber: yup
    .string()
    .trim()
    .required(REQUIRED_FIELD)
    .max(100, 'Should not be more than 100 characters'),
  swiftCode: yup
    .string()
    .trim()
    .max(100, 'Should not be more than 100 characters'),
  furtherBankAccountName: yup
    .string()
    .test(
      'only-further-personal-bank-accounts-are-allowed',
      'Only personal bank accounts are allowed.',
      bankAccountNameTest
    )
    .max(100, 'Should not be more than 100 characters'),
  furtherBankAccountNumber: yup
    .string()
    .trim()
    .max(100, 'Should not be more than 100 characters'),
});

export const buildDataRequestPayload = (
  values: BankAccountInput
): CREATE_FORM_MUTATIONVariables => ({
  type: FormType.DCR,
  content: [
    {
      question: 'Bank name',
      input: _get(values, 'bankName'),
    },
    {
      question: 'Bank account name',
      input: _get(values, 'bankAccountName'),
    },
    {
      question: 'Bank account number',
      input: _get(values, 'bankAccountNumber'),
    },
    {
      question: 'ABA number',
      input: _get(values, 'abaNumber'),
    },
    {
      question: 'Attention',
      input: _get(values, 'attention'),
    },
    {
      question: 'SWIFT code',
      input: _get(values, 'swiftCode'),
    },
    {
      question: 'Further personal bank account name',
      input: _get(values, 'furtherBankAccountName'),
    },
    {
      question: 'Further personal bank account number',
      input: _get(values, 'furtherBankAccountNumber'),
    },
    {
      question: 'payload',
      input: JSON.stringify({
        type: DATA_CHANGE_REQUEST_TYPES.METADATA,
        identifier: DATA_CHANGE_REQUEST_IDENTIFIERS.METADATA.BANK_ACCOUNT_INFORMATION,
        value: {
          name: DATA_CHANGE_REQUEST_IDENTIFIERS.METADATA.BANK_ACCOUNT_INFORMATION,
          value: {
            bankName: _get(values, 'bankName'),
            bankAccountName: _get(values, 'bankAccountName'),
            bankAccountNumber: _get(values, 'bankAccountNumber'),
            abaNumber: _get(values, 'abaNumber'),
            attention: _get(values, 'attention'),
            swiftCode: _get(values, 'swiftCode'),
            furtherBankAccountName: _get(values, 'furtherBankAccountName'),
            furtherBankAccountNumber: _get(values, 'furtherBankAccountNumber'),
          },
        },
      }),
    },
  ],
  files: [],
});
