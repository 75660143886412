import _get from 'lodash/get';
import { EFormType } from '../../../../utils/types/types';
import { FormikStub } from '../../../../utils/hooks/useForm';
import { AmendmentForm } from './AmendmentHandler';

export const buildAMNForm = (form: FormikStub<AmendmentForm>, dealId: string) => {
  const { values } = form;
  const { files, explanation } = values;

  const variables = {
    type: EFormType.AMNel,
    dealId,
    content: [
      {
        question: 'Briefly explain the purpose of this amendment',
        input: explanation,
        key: 'explanation',
        value: JSON.stringify(explanation),
      },
    ],
    files,
  };

  return variables;
};
