import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import get from 'lodash/get';

import { UserContext } from 'app/users/context/UserContext';
import {
  GET_FILEROOMS_QUERY,
  GET_FILEROOMS_QUERYVariables,
} from 'api/graphql/core-tools/vdr/types/GET_FILEROOMS_QUERY';
import { GET_FILEROOMS } from 'api/graphql/core-tools/vdr/queries';
import { Fso as IFso } from 'app/core-tools/due-diligence/vdr/utils/types';
import FileroomLoader from '../FileroomLoader/FileroomLoader';
import Fileroom from '../Fileroom/Fileroom';

import { sortFileRooms } from './HelpersVDRSubsection';
import { LinkContainer } from '../../StyledDealContentSidebar';

function VDRDealContentSidebarSubsection() {
  const user = useContext(UserContext);

  const { data: fileroomsData, loading: loadingFileroomsData } = useQuery<
    GET_FILEROOMS_QUERY,
    GET_FILEROOMS_QUERYVariables
  >(GET_FILEROOMS, {
    variables: { dealId: get(user, 'activeDeal.id', '') },
    skip: !get(user, 'activeDeal.id', false),
  });

  if (loadingFileroomsData)
    return (
      <>
        {[...new Array(3)].map((_, index) => (
          <FileroomLoader key={index} />
        ))}
      </>
    );

  const sortedFileRooms = sortFileRooms(get(fileroomsData, 'fileRooms', []) as IFso[]);

  return (
    <>
      {sortedFileRooms.map((fileRoom, index) => {
        return (
          <LinkContainer key={index} style={{ marginLeft: 22 }}>
            <Fileroom fileRoomData={fileRoom} />
          </LinkContainer>
        );
      })}
    </>
  );
}

export default VDRDealContentSidebarSubsection;
