// Libraries
import React from 'react';
import PropTypes from 'prop-types';

const TickIcon = ({ width, height, color, style }) => (
  <svg
    width={width}
    height={height}
    style={style}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.83984 11.0801C5.7085 11.0802 5.57843 11.0544 5.4571 11.0041C5.33577 10.9539 5.22557 10.8801 5.13284 10.7871L1.293 6.94727C1.19928 6.85462 1.1248 6.74435 1.07385 6.62281C1.02289 6.50128 0.996462 6.37086 0.996083 6.23908C0.995704 6.10729 1.02138 5.97673 1.07164 5.8549C1.12189 5.73307 1.19574 5.62238 1.28892 5.52919C1.38211 5.436 1.4928 5.36215 1.61462 5.31189C1.73645 5.26163 1.86701 5.23594 1.99879 5.23631C2.13058 5.23669 2.26099 5.26311 2.38253 5.31406C2.50407 5.36501 2.61434 5.43948 2.707 5.5332L5.83984 8.666L13.293 1.21289C13.4807 1.02602 13.7348 0.921226 13.9996 0.921509C14.2644 0.921792 14.5183 1.02713 14.7056 1.2144C14.8929 1.40167 14.9982 1.65558 14.9984 1.92041C14.9987 2.18524 14.8939 2.43936 14.707 2.627L6.54685 10.7872C6.45411 10.8802 6.34391 10.9539 6.22258 11.0042C6.10124 11.0544 5.97117 11.0802 5.83984 11.0801Z"
      fill={color}
    />
  </svg>
);

TickIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.shape({}),
};

TickIcon.defaultProps = {
  width: '15',
  height: '12',
  color: '#807DA1',
  style: {},
};

export default TickIcon;
