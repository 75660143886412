import React from 'react';

import { Button, HStack, SideDrawer } from 'app/common/components';
import MarketingMaterialForm from 'app/compliance/components/forms/MarketingMaterialForm/MarketingMaterialForm';
import useStateModal from 'app/utils/hooks/useStateModal';

import {
  useMarketingMaterialFormHandler,
  MarketingMaterialHandlerProvider,
} from './MarketingMaterialFormHandler';

const MarketingMaterialFormDrawer = () => {
  const handler = useMarketingMaterialFormHandler();
  const { modalState, hide } = useStateModal('marketingMaterialForm');
  const { onSubmitMarketingMaterialForm, submitting, form } = handler;

  const hideModal = () => {
    hide();
    form.resetForm();
  };

  return (
    <MarketingMaterialHandlerProvider value={handler}>
      <SideDrawer
        promptOnClose={form.dirty}
        title="Marketing materials"
        show={modalState.show}
        onClose={hideModal}
        footerContent={({ onClose }) => (
          <HStack justifyContent="flex-end" spacing={16}>
            <Button onClick={onClose} className="tertiary">
              Dismiss
            </Button>
            <Button onClick={onSubmitMarketingMaterialForm} disabled={submitting || !form.isValid}>
              Submit
            </Button>
          </HStack>
        )}
      >
        <MarketingMaterialForm />
      </SideDrawer>
    </MarketingMaterialHandlerProvider>
  );
};

export default MarketingMaterialFormDrawer;
