export const initialSorting = [
  [
    {
      label: 'Creation date',
      value: 'createdAt',
    },
  ],
  [
    {
      label: 'Descending',
      value: 'DESC',
    },
  ],
];

export const sortingOptions = [
  [
    {
      label: 'Creation date',
      value: 'createdAt',
    },
    {
      label: 'Deal name',
      value: 'name',
    },
  ],
  [
    {
      label: 'Ascending',
      value: 'ASC',
    },
    {
      label: 'Descending',
      value: 'DESC',
    },
  ],
];
