import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

type Route = string;
type Action = (ctx: {
  history: ReturnType<typeof useHistory>;
  match: ReturnType<typeof useRouteMatch>;
}) => void;

export const useRouteSubscription = (routes: Route[] = [], actions: Action[] = []) => {
  const history = useHistory();
  const match = useRouteMatch();

  if (routes.length !== actions.length) {
    try {
      throw new Error(
        'The actions array should correspond in length and position for every route in the routes array'
      );
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    routes.forEach((route, i) => {
      if (match && match.path === route) {
        actions[i]({ history, match });
      }
    });
  }, [history.location]);
};
