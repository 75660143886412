import React, { Component, createContext, ErrorInfo } from 'react';
import { EErrorCodes, ErrorPage } from '@dealsyte/poki';
import { withRouter } from 'react-router-dom';

import { IProps, IErrorPageProps, IState } from './TypedErrorBoundaryContext';
import { ERoutePatterns } from 'app/core-tools/due-diligence/types/types';

const ErrorBoundaryContext = createContext({
  showNotFoundErrorPage: ({ title, message, action }: IErrorPageProps) => {},
  showForbiddenErrorPage: ({ title, message, action }: IErrorPageProps) => {},
});

class ErrorBoundaryContextProvider extends Component<IProps, IState> {
  state = {
    errorActive: false,
    errorAction: { message: undefined, callback: () => {} },
    errorMessage: undefined,
    errorCode: EErrorCodes.NOT_FOUND,
    errorTitle: undefined,
  };

  static getDerivedStateFromError = () => {
    return {
      errorActive: true,
      errorAction: {
        callback: () => {},
        message: 'Go back',
      },
      errorCode: EErrorCodes.SERVER_ERROR,
    };
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      errorAction: {
        ...this.state.errorAction,
        callback: () => {
          this.cleanState();
          this.props.history.push(ERoutePatterns.LOGIN);
        },
      },
    });
  }

  cleanState = () => {
    this.setState({
      errorActive: false,
      errorAction: { message: undefined, callback: () => {} },
      errorMessage: undefined,
      errorCode: EErrorCodes.NOT_FOUND,
      errorTitle: undefined,
    });
  };

  showNotFoundErrorPage = ({ title, message, action }: IErrorPageProps) => {
    const errorData = {
      errorActive: true,
      errorAction: {
        message: action.message,
        callback: () => {
          this.cleanState();
          action.callback();
        },
      },
      errorMessage: message,
      errorCode: EErrorCodes.NOT_FOUND,
      errorTitle: title,
    };
    this.setState(errorData);
  };

  showForbiddenErrorPage = ({ title, message, action }: IErrorPageProps) => {
    const errorData = {
      errorActive: true,
      errorAction: {
        message: action.message,
        callback: () => {
          this.cleanState();
          action.callback();
        },
      },
      errorMessage: message,
      errorCode: EErrorCodes.FORBIDDEN,
      errorTitle: title,
    };
    this.setState(errorData);
  };

  render() {
    return (
      <ErrorBoundaryContext.Provider
        value={{
          showNotFoundErrorPage: this.showNotFoundErrorPage,
          showForbiddenErrorPage: this.showForbiddenErrorPage,
        }}
      >
        {this.state.errorActive ? (
          <ErrorPage
            action={this.state.errorAction}
            errorCode={this.state.errorCode}
            message={this.state.errorMessage}
            title={this.state.errorTitle}
          />
        ) : (
          this.props.children
        )}
      </ErrorBoundaryContext.Provider>
    );
  }
}

const WithRouterErrorBoundaryContextProvider = withRouter(ErrorBoundaryContextProvider);

export {
  ErrorBoundaryContext,
  WithRouterErrorBoundaryContextProvider as ErrorBoundaryContextProvider,
};
