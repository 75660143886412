// vendors
import get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import { DayValue } from 'react-modern-calendar-datepicker';

// helpers
import { valueToSelectInput } from 'app/utils/helpers/selectInputHelpers';

// types
import { EDealStatus, IDeal } from 'app/utils/types/types';
import { DealMode } from 'types/graphql-types';
import {
  DEAL_METADATA_FIELDS,
  DEAL_METADATA_FIELD_ACCESSORS,
  DEFAULT_METADATA_VALUES,
  mapObjectToMetadata,
} from 'app/utils/helpers/dealMetadataMappers';
import { UPDATE_DEAL_MUTATIONVariables } from 'api/graphql/deals/types/UPDATE_DEAL_MUTATION';
import { DEAL_STATUS_LABELS, DEAL_TYPES_LABELS } from 'app/utils/helpers/deal';
import { UpdateDealForm } from './TypedDealInformationSettings';
import { getTailPeriodLabel } from '../MarketplaceSettings/HelpersMarketplaceSettings';

export const dealUpdateFormInitialValues = {
  name: '',
  type: undefined,
  projectName: '',
  companyName: '',
  companyAddress: '',
  companyAgent: '',
  mode: '',
  private: true,
  // Deal Metadata part
  dealStatus: {
    label: DEAL_STATUS_LABELS[EDealStatus.INITIAL_ENGAGEMENT],
    value: EDealStatus.INITIAL_ENGAGEMENT,
  },
  expectedCommissions: 0,
  expectedSize: 0,
  closingDate: null,
  loiProvided: false,
  country: undefined,
  state: '',
  zipCode: '',
  industryType: undefined,
  industryTypeSubOptions: undefined,
  publiclyTradedCompany: false,
  ticker: '',
  initialDueDiligence: '',
  potentialConflicts: '',
  targetInvestors: undefined,
  foreignSolicitation: false,
  marketplaceDealType: '',
  marketplaceDescription: '',
  monthlyRetainer: false,
  retainerAmount: 0,
  minFee: 0,
  maxFee: 0,
  tailPeriodDuration: {
    label: '6 months',
    value: 6,
  },
};

const dateToDatePickerValue = (dateStr: null | string) => {
  if (!dateStr || typeof dateStr !== 'string') return null;
  const dateRaw = new Date(dateStr);
  return {
    year: dateRaw.getFullYear(),
    month: dateRaw.getMonth() + 1,
    day: dateRaw.getDate(),
  } as DayValue;
};

export const mapDealValuesToForm = (deal: IDeal | undefined) => {
  if (!deal) {
    return dealUpdateFormInitialValues;
  }
  const metadataSource =
    deal && deal.metadata && !_isEqual(deal.metadata, DEFAULT_METADATA_VALUES)
      ? 'metadata'
      : 'details';
  const fieldGetter = <T = any>(source: T) => (field: keyof T, fallback?: any) =>
    get(source, field as any, fallback);
  const getField = fieldGetter(deal);
  const getMetadataField = fieldGetter(getField(metadataSource));

  return {
    name: getField('name'),
    type: valueToSelectInput(getField('type'), {
      labelResolver: value => get(DEAL_TYPES_LABELS, value),
    }),
    projectName: getField('projectName'),
    companyName: getField('companyName'),
    companyAddress: getField('companyAddress'),
    companyAgent: getField('companyAgent'),
    mode: getField('mode'),
    private: !getField('private'),
    // Deal Metadata part
    dealStatus: valueToSelectInput(getMetadataField('dealStatus'), {
      labelResolver: value => get(DEAL_STATUS_LABELS, value),
    }),
    expectedCommissions: getMetadataField('expectedCommissions'),
    expectedSize: getMetadataField('expectedSize'),
    closingDate: dateToDatePickerValue(getMetadataField('closingDate')),
    loiProvided: getMetadataField('loiProvided'),
    country: valueToSelectInput(getMetadataField('country')),
    state: getMetadataField('state'),
    zipCode: getMetadataField('zipCode'),
    industryType: valueToSelectInput(getMetadataField('industryType')),
    industryTypeSubOptions: valueToSelectInput(getMetadataField('industryTypeSubOptions')),
    publiclyTradedCompany: getMetadataField('publiclyTradedCompany'),
    ticker: getMetadataField('ticker'),
    initialDueDiligence: getMetadataField('initialDueDiligence'),
    potentialConflicts: getMetadataField('potentialConflicts'),
    targetInvestors: valueToSelectInput(getMetadataField('targetInvestors')),
    foreignSolicitation: getMetadataField('foreignSolicitation'),
    marketplaceDealType: getMetadataField('marketplaceDealType'),
    marketplaceDescription: getMetadataField('marketplaceDescription'),
    monthlyRetainer: getMetadataField('monthlyRetainer'),
    retainerAmount: getMetadataField('retainerAmount'),
    minFee: getMetadataField('minFee'),
    maxFee: getMetadataField('maxFee'),
    tailPeriodDuration: valueToSelectInput(getMetadataField('tailPeriodDuration'), {
      labelResolver: value => getTailPeriodLabel(value).label,
      fallback: getTailPeriodLabel(6),
    }),
  };
};

export const mapFormValuesToDeal = (
  values: UpdateDealForm,
  extraFields = {} as any
): UPDATE_DEAL_MUTATIONVariables => {
  const { dealId, firmId, isStatusDDClosing } = extraFields;
  return {
    dealId,
    name: get(values, 'name', ''),
    type: get(values, 'type.value', ''),
    companyName: get(values, 'companyName', ''),
    companyAddress: get(values, 'companyAddress', ''),
    companyAgent: get(values, 'companyAgent', ''),
    projectName: get(values, 'projectName', ''),
    mode: get(values, 'mode', '') as DealMode,
    dealStatus: get(values, 'dealStatus.value', ''),
    expectedCommissions: get(values, 'expectedCommissions', 0),
    expectedSize: get(values, 'expectedSize', 0),
    closingDate: isStatusDDClosing && values.closingDate ? values.closingDate : '',
    loiProvided: get(values, 'loiProvided', false),
    private: !Boolean(values.private),
    firmId,
    metadata: mapObjectToMetadata(
      { ...values, closingDate: isStatusDDClosing && values.closingDate ? values.closingDate : '' },
      {
        fields: DEAL_METADATA_FIELDS,
        accessors: DEAL_METADATA_FIELD_ACCESSORS,
      }
    ),
  };
};
