import * as React from 'react';
import { IconProps } from './types';

export const JewelIcon = ({
  color = 'currentColor',
  width = '18px',
  height = '15px',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.15947 0.34319C4.31628 0.12757 4.5668 0 4.83342 0H13.1667C13.4334 0 13.6839 0.12757 13.8407 0.34319L17.174 4.92652C17.4011 5.23869 17.384 5.66592 17.1328 5.95899L9.6328 14.709C9.47448 14.8937 9.24335 15 9.00008 15C8.75681 15 8.52569 14.8937 8.36737 14.709L0.86737 5.95899C0.616165 5.66592 0.599103 5.23869 0.826137 4.92652L4.15947 0.34319ZM5.25777 1.66667L3.13656 4.58333H5.62596L7.48202 1.66667H5.25777ZM9.00008 2.38552L7.60148 4.58333H10.3987L9.00008 2.38552ZM10.7606 6.25H7.2396L9.00008 11.5314L10.7606 6.25ZM6.86423 10.3944L5.48278 6.25H3.31193L6.86423 10.3944ZM11.1359 10.3944L12.5174 6.25H14.6882L11.1359 10.3944ZM14.8636 4.58333H12.3742L10.5181 1.66667H12.7424L14.8636 4.58333Z"
      fill={color}
    />
  </svg>
);
