import { GET_FSO } from 'api/graphql/core-tools/vdr/queries';
import { Fso } from '../types';

export const createFolder = async (
  name: string,
  currentFolderId: string | null = null,
  createFolderMutation: Function
) => {
  try {
    const parentId = currentFolderId;
    const result = await createFolderMutation({
      variables: { name, parentId },
      update(store: any, { data: { createFolder } }: any) {
        updateFolderChildrenCache(store, parentId, [createFolder]);
      },
    });

    return result.data.createFolder.id;
  } catch (err) {
    return '';
  }
};

export const updateFolderChildrenCache = (
  store: any,
  parentId: string | null,
  newFsos: Fso[],
  updateCache: (data: any, newFsos: Fso[]) => any = (data, newFsos) => {
    if (data.fso) {
      data.fso.children = [
        ...data.fso.children,
        ...newFsos.filter(fso => !data.fso.children.find((FSO: any) => FSO.id === fso.id)),
      ];

      data.fso.childrenAmount = data.fso.children.length;
    }
    return data;
  }
) => {
  let data = store.readQuery({
    query: GET_FSO,
    variables: { fsoId: parentId },
  });
  data = updateCache(data, newFsos);
  if (data.fso) {
    store.writeQuery({
      query: GET_FSO,
      variables: { fsoId: parentId },
      data,
    });
  }
};
