export type IStateProps = {
  notifications: any[];
};

export type IProps = IStateProps;

export enum ELabels {
  USER_SETTINGS = 'My Account',
  LOG_OUT = 'Log out',
  FAQ = 'FAQ',
}
