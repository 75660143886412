import { gql } from '@apollo/client';
import { FormFragment } from './fragments';

export const GET_FORMS = gql`
  query GET_FORMS_QUERY($searchQuery: FormSearchQuery, $sort: SortParams, $dealId: ID) {
    forms(searchQuery: $searchQuery, sort: $sort, dealId: $dealId) {
      id
      code
      type
      files {
        name
        link
      }
      status
      author {
        id
        firstName
        lastName
      }
      measure {
        noteCount
        attachmentCount
      }
      content {
        question
        input
        key
        value
      }
      notes {
        id
        content
        type
        attachments {
          approved
          filename
          link
          deleted
        }
        author {
          id
          email
          firstName
          lastName
          platformRole
          active
        }
        createdAt
      }
      isActive
      entity {
        id
        name
      }
      createdAt
      assignedTo {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_FORM = gql`
  query GET_FORM_QUERY($id: ID!) {
    form(id: $id) {
      assignedTo {
        id
        firstName
        lastName
      }
      author {
        id
        firstName
        lastName
      }
      code
      content {
        question
        input
        key
        value
      }
      createdAt
      dealId
      entity {
        id
        name
      }
      files {
        name
        approved
        link
        deleted
        size
        createdAt
        uploadedBy
      }
      id
      isActive
      metrics {
        unattended {
          beforeTime
          afterTime
        }
        attended {
          beforeTime
          afterTime
        }
      }
      notes {
        id
        content
        type
        attachments {
          approved
          filename
          link
          deleted
          size
        }
        author {
          id
          email
          firstName
          lastName
          platformRole
          active
        }
        createdAt
      }
      status
      type
    }
  }
`;

export const GET_FORMS_COUNT = gql`
  query GET_FORMS_COUNT_QUERY($searchQuery: FormSearchQuery) {
    formsCount(searchQuery: $searchQuery)
  }
`;

export const GET_FORM_U4 = gql`
  query GET_FORM_U4_QUERY($searchQuery: FormSearchQuery) {
    forms(searchQuery: $searchQuery) {
      id
    }
  }
`;

export const GET_FORM_U4_CONTENT = gql`
  query GET_FORM_U4_CONTENT_QUERY($searchQuery: FormSearchQuery) {
    forms(searchQuery: $searchQuery) {
      id
      content {
        question
        input
      }
    }
  }
`;

export const GET_PAGINATED_FORMS = gql`
  query GET_PAGINATED_FORMS_QUERY(
    $searchQuery: FormSearchQuery
    $operator: String = "AND"
    $sort: SortParams
    $page: Int
    $pageSize: Int
    $dealId: ID
  ) {
    getPaginatedFormsByUser(
      searchQuery: $searchQuery
      operator: $operator
      sort: $sort
      page: $page
      pageSize: $pageSize
      dealId: $dealId
    ) {
      page
      totalPages
      pageSize
      totalRecords
      forms {
        ...FormBaseFragment
      }
    }
  }
  ${FormFragment}
`;

export const GET_PAGINATED_FORMS_BY_DEAL = gql`
  query GET_PAGINATED_FORMS_QUERY_BY_DEAL(
    $searchQuery: FormSearchQuery
    $operator: String = "AND"
    $sort: SortParams
    $page: Int
    $pageSize: Int
    $dealId: ID
  ) {
    getPaginatedFormsByDeal(
      searchQuery: $searchQuery
      operator: $operator
      sort: $sort
      page: $page
      pageSize: $pageSize
      dealId: $dealId
    ) {
      page
      totalPages
      pageSize
      totalRecords
      forms {
        ...FormBaseFragment
      }
    }
  }
  ${FormFragment}
`;

export const GET_CHECK_IN_LIST = gql`
  query GET_CHECK_IN_LIST_QUERY {
    checkInList {
      id
      code
      userId
      representative
      signed
      sentAt
      reminderSentAt
      signedAt
      previewUrl
    }
  }
`;

export const GET_DASHBOARD_FORMS = gql`
  query DASHBOARDFORMS {
    dashboardForms {
      forms {
        id
        type
        status
        entity {
          id
          name
        }
        createdAt
        updatedAt
        author {
          id
          firstName
          lastName
        }
      }
      moreFormsCount
    }
  }
`;

export const GET_CHECKLISTS_REPORTS = gql`
  query checkInCheckList {
    checkInCheckList {
      canSign
      signed
      checkList {
        key
        label
        checked
      }
      checkInRecordId
      nextCheckInDate
    }
  }
`;

export const GET_DOCUMENT_BANK = gql`
  query getDocumentBank {
    getDocumentBank {
      topic
      objects {
        name
        description
        downloadFormats {
          documentId
          filename
          extension
          mimetype
          size
        }
        lastUpdated
      }
    }
  }
`;

export const GET_DATA_CHANGE_REQUEST_STATUS = gql`
  query dataChangeRequestStatus($type: String, $identifier: String) {
    dataChangeRequestStatus(type: $type, identifier: $identifier) {
      canPost
    }
  }
`;

export const GET_LATEST_ATTESTATION = gql`
  query getLastAttestation($userId: ID!) {
    getLastAttestation(userId: $userId) {
      id
      userId
      reviewerId
      attestation
      status
      attested
      createdAt
      updatedAt
      timestamp
      skipAlertAt
      dueDate
      dueDateStatus
    }
  }
`;

export const GET_ALL_ATTESTATION = gql`
  query GetAllAttestations($userId: ID) {
    getAllAttestations(userId: $userId) {
      id
      attestation
      attested
      status
      year
      period
      quarter
    }
  }
`;
