export const NDPrimaryInfoTexts = {
  SUBTITLE:
    'Complete the following information to create a new deal and publish it in Finalis Marketplace.',
  ALERT_TEXT:
    "Neither the deal name nor the client's entity name will be shown to other Finalis users.",
  NAME: 'Deal name',
  NAME_DESCRIPTION: 'The deal name will be visible only to you',
  TYPE: 'Transaction type',
  STAGE: 'Stage',
  NAME_PLACEHOLDER: 'Enter deal name',
  TYPE_PLACEHOLDER: 'Select type',
  STAGE_PLACEHOLDER: 'Select the deal stage',
  LEGAL_NAME_LABEL: 'Client’s entity name – and DBA if applicable',
  LEGAL_NAME_PLACEHOLDER: "Enter your client's name",
  LEGA_NAME_DESCRIPTION: 'Entity name should match that in the Engagement Letter.',
  HEADQUARTER_PLACEHOLDER: 'Enter full address',
  HEADQUARTER_LABEL_LINE1: 'Client headquarters – line 1',
  HEADQUARTER_LABEL_LINE2: 'Client headquarters – line 2',
  COUNTRY_LABEL: 'Country',
  COUNTRY_PLACEHOLDER: 'Enter country',
  STATE_LABEL: 'State / province',
  STATE_PLACEHOLDER: 'Enter a state / province',
  ZIPCODE_LABEL: 'ZIP / postal code',
  ZIPCODE_PLACEHOLDER: 'Enter ZIP code / postcode',
};

export const ButtonNames = {
  PREVIOUS: 'Previous',
  CANCEL: 'Cancel',
  NEXT: 'Next',
  SUBMIT: 'Create deal',
};

export const NDMarketplaceTexts = {
  TITLE: 'Collaboration status',
  SUBTITLE:
    'Specify whether or not Marketplace users can collaborate on your deal. If you decide to collaborate, our Connect team may request additional details to improve matchmaking opportunities.',
  NON_COLLABORATIVE_TITLE: 'Non-collaborative',
  NON_COLLABORATIVE_SUBTITLE:
    "Deal information visible to all Marketplace's users. Collaboration NOT allowed.",
  COLLABORATIVE_TITLE: 'Collaborative',
  COLLABORATIVE_SUBTITLE: 'To be listed in the Marketplace and promoted by our Connect team. ',
  DESCRIPTION_TITLE: 'Marketplace description',
  DESCRIPTION_PLACEHOLDER: 'Write the description',
  DESCRIPTION_HELPER:
    'You may include EBITDA, project summary, current collaborators, fee share splitting, and any other information relevant to assessing your deal.',
};

export const AdditionalInfoTexts = {
  TITLE: 'Deal terms',
  SUBTITLE_EL:
    'Complete the following information according to the engagement letter items agreed with your client, you will have to attach the document in the next step.',
  SUBTITLE_W_EL: 'Complete the following data with tentative information on the deal.',
  DEAL_STAGE_LABEL: 'Deal  stage',
  DEAL_STAGE_PLACEHOLDER: 'Select deal stage',
  P_INDUSTRY_LABEL: 'Primary industry',
  P_INDUSTRY_PLACEHOLDER: 'Select primary industry',
  S_INDUSTRY_LABEL: 'Secondary industry',
  S_INDUSTRY_PLACEHOLDER: 'Select secondary industry',
  TARGET_INV_LABEL: 'Type of target investor',
  TARGET_INV_PLACEHOLDER: 'Select type of target investor',
  CONFLICT_QUESTION: 'Are there any potential conflicts of interest?',
  CONFLICT_DETAILS:
    '(e.g., board seat, advisor, equity interest, family connection, connection to the deal via an OBA/PST)',
  FOREIGN_SOLICITATION_QUESTION: 'Do you anticipate conducting any foreign solicitation?',
  RETAINER_QUESTION: 'Will the deal have a monthly retainer?',
  DEAL_SIZE_LABEL: 'Expected deal size (USD)',
  DEAL_SIZE_PLACEHOLDER: 'Enter deal size',
  SUCCESS_FEE_LABEL: 'Success fee',
  SUCCESS_FEE_PLACEHOLDER: 'Enter value',
  TAIL_PERIOD_LABEL: 'Tail period expiration',
  TAIL_PERIOD_PLACEHOLDER: 'Select an option',
  DESCRIPTION_LABEL: 'Description',
  DESCRIPTION_PLACEHOLDER: 'Write a description of your deal',
  COMISSION_LABEL: 'Expected Commission Earnings (USD)',
  COMISSION_PLACEHOLDER: 'Enter comission earnings',
  ENGAGEMENT_LETTER_TITLE:
    'Attach the engagement letter, which supports all of the information you filled out.',
};

export const EngagementLetterInfoText = {
  TITLE: 'Client engagement',
  SUBTITLE:
    'Attach an Engagement Letter, Finder’s Fee, or Placement Agreement—all of which support the information filled out in the previous step—and complete the additional questions below.',
  ADDITIONAL_QUESTIONS_TITLE: 'Additional questions',
  ADDITIONAL_QUESTIONS_SUBTITLE:
    'These give our Legal and Compliance teams key details on the deal and its participants.',
  TICKER_EXCHANGE_PLACEHOLDER: 'Enter value',
  DUE_DILIGENCE_NOTE_HELPER: 'e.g., financial stress test, review of management background, etc.',
  RED_FLAG_NOTE_HELPER:
    'Provide an explanation of how you resolved the area(s) of concern prior to sale of the offering.',
  POTENTIAL_CONFLICTS_NOTE_HELPER:
    'e.g., board seat, advisor, equity interest, family connection, connection to the deal via an OBA/PST. ',
  TARGET_INVESTOR_PLACEHOLDER: 'Select target investor',
  FOREIGN_SOLICITATION_COUNTRIES_PLACEHOLDER: 'For which countries?',
  NOTES_PLACEHOLDER: 'Write your notes',
  ANSWER_PLACEHOLDER: 'Write your answer',
};
