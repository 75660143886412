import React from 'react';

import { OptionCard } from 'app/common/components';

import { options } from './CAConstants';

import * as S from './OptionFormStyled';

export interface IOptionForm {
  optionSelected: string;
  setOptionSelected: (value: string) => void;
}

export const OptionForm = ({ optionSelected, setOptionSelected }: IOptionForm) => (
  <>
    <S.OptionFormSubtitle>
      Select the type of Fee Sharing Agreement (FSA) you want to submit:
    </S.OptionFormSubtitle>
    <S.OptionFormContainer>
      {options.map(({ icon, title, subtitle, step, id, disabled }) => (
        <OptionCard
          key={id}
          icon={icon}
          title={title}
          subtitle={subtitle}
          checked={optionSelected === step}
          onClick={() => setOptionSelected(step)}
          disabled={disabled}
        />
      ))}
    </S.OptionFormContainer>
  </>
);
