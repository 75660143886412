// Libraries
import React from 'react';
import { IconProps } from './types';

export const PencilIcon = ({
  width = '14px',
  height = '14px',
  color = 'currentColor',
  style,
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6656 3.82733C13.6661 3.73959 13.6492 3.65262 13.6161 3.57139C13.5829 3.49017 13.534 3.41629 13.4722 3.354L10.6456 0.527329C10.5833 0.465541 10.5094 0.416658 10.4282 0.383481C10.3469 0.350305 10.26 0.333488 10.1722 0.333996C10.0845 0.333488 9.99751 0.350305 9.91629 0.383481C9.83506 0.416658 9.76118 0.465541 9.69889 0.527329L7.81223 2.414L0.525559 9.70066C0.463771 9.76295 0.414888 9.83683 0.381711 9.91806C0.348535 9.99928 0.331718 10.0863 0.332225 10.174V13.0007C0.332225 13.1775 0.402463 13.347 0.527488 13.4721C0.652512 13.5971 0.822081 13.6673 0.998892 13.6673H3.82556C3.91884 13.6724 4.01215 13.6578 4.09944 13.6245C4.18672 13.5912 4.26603 13.5399 4.33223 13.474L11.5789 6.18733L13.4722 4.334C13.5331 4.26938 13.5827 4.19502 13.6189 4.114C13.6253 4.06086 13.6253 4.00714 13.6189 3.954C13.622 3.92296 13.622 3.89169 13.6189 3.86066L13.6656 3.82733ZM3.55223 12.334H1.66556V10.4473L8.28556 3.82733L10.1722 5.714L3.55223 12.334ZM11.1122 4.77399L9.22556 2.88733L10.1722 1.94733L12.0522 3.82733L11.1122 4.77399Z"
      fill={color}
    />
  </svg>
);
