import styled from 'styled-components';

const fontFamily = 'Effra';

export const ViewContainer = styled.div`
  width: 128px;
  height: 40px;
  background: #ededf7;
  border-radius: 6px;
  border: 1px solid #ededf7;
  display: flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
  &.activeHover {
    &:hover {
      border: 1px solid #d8d7ea;
    }
  }
  &.inactiveHover {
    &:hover {
      border: 1px solid #d8d7ea;
      background-color: #d8d7ea;
    }
  }
`;

export const ButtonIcon = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  height: 38px;
  width: 65px;
  border-radius: 6px;
  padding: 0 12px;
  font-family: ${fontFamily};
  font-size: 12px;
  letter-spacing: 0.25px;
  color: #807da1;

  &.btn-active {
    background-color: #fefefe;
    color: #47455f;
    box-shadow: 0px 4px 16px 4px rgba(65, 78, 129, 0.1);
    &:hover {
      color: #292839;
    }
  }
  &.btn-inactive {
    &:hover {
      color: #292839;
    }
  }
`;
