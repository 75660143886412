import React from 'react';
import * as S from './Styled';

interface TabProps {
  active: boolean;
  icon?: () => JSX.Element;
  onClick?: () => void;
  label: string;
  count?: number;
}

export const TabV2 = ({ active, label, icon: Icon, onClick, count = 0 }: TabProps): JSX.Element => {
  return (
    <S.TabContainerV2 className={active ? 'active' : ''} onClick={onClick} disabled={count === 0}>
      {Icon && (
        <S.TabIconContainer>
          <Icon />
        </S.TabIconContainer>
      )}
      <S.TabTextContainerV2 isActive={active}>{label}</S.TabTextContainerV2>
      <div className={`counter-container ${active ? 'active' : ''}`}>{count}</div>
    </S.TabContainerV2>
  );
};
