import React, { useContext, useEffect, useMemo, useState } from 'react';
import { QueryResult, useQuery } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import { History } from 'history';

// context
import { DealsContext } from '../../context/DealsContext';
import { FormsContext } from '../../context/FormsContext';
import { UserContext } from '../../../users/context/UserContext';

// constants
import {
  initialSorting,
  validDealFormTypes,
} from './DealRegulationFormsList/DealRegulationFormListConstants';

// Types
import { EFormType } from '../../../utils/types/types';
import { DealSide, ERoutePatterns } from '../../../core-tools/due-diligence/types/types';
import { IForm } from '../../types/formsTypes';
import { IFilterQuery, IPageQuery, IPaginationInfo } from '../../context/TypedFilterQuery';

// Helpers
import {
  getDealRegulationProps,
  getDealRegulation,
  MADealWorkflowConfig,
} from './DealRegulationWorkflow';

// Hooks
import { useRouteSubscription } from '../../../utils/hooks/useRouteSubscription';
import useStateModal from '../../../utils/hooks/useStateModal';

// Queries
import { GET_PAGINATED_FORMS_BY_DEAL } from '../../../../api/graphql/compliance/queries';
import { MultiSelectDropdownState } from '../../../common/components';

const DEAL_FORMS = ['EL', 'MMAT', 'DRA', 'KYC', 'CDOC', 'INV'];
const DEAL_FORM_FILTERS = DEAL_FORMS.map(type => ({ field: 'type', value: type }));

const useDealRegulationRouteCheck = (setDealId: React.Dispatch<React.SetStateAction<string>>) => {
  const { setActiveDeal } = useContext(DealsContext);
  const { activeDeal } = useContext(UserContext);
  const { dealId } = useParams<{ dealId: string }>();

  useEffect(() => {
    setDealId(dealId);
  }, [dealId, setDealId]);

  useRouteSubscription(
    [
      `${ERoutePatterns.DEAL_REGULATION}/:dealId`,
      `${ERoutePatterns.DEAL_REGULATION}/:dealId/:formType`,
    ],
    [
      () => {
        logInToDeal();
      },
      ({ match, history }) => {
        const formType = match && match.params && match.params.formType;
        checkFormTypeAndLogIn(formType || '', history);
      },
    ]
  );

  const logInToDeal = () => {
    if (!activeDeal || (activeDeal && activeDeal.id !== dealId)) {
      setActiveDeal(dealId);
    }
  };

  const checkFormTypeAndLogIn = (formType: string, history: History<unknown>) => {
    if (!validDealFormTypes.includes(formType as EFormType)) {
      history.push(`${ERoutePatterns.DEAL_REGULATION}/${dealId}`);
      return;
    }
    logInToDeal();
  };
};

export const useDealRegulationHandler = () => {
  const routerHistory = useHistory();
  const { loginToDealMutation } = useContext(DealsContext);
  const { side } = useContext(UserContext);
  const { loading: loadingLoginToDeal } = loginToDealMutation[1];
  const [dealId, setDealId] = useState('');
  const [sortFilter, setSortFilter] = useState<MultiSelectDropdownState>(initialSorting);
  const [filters, setFilterQuery] = useState<IFilterQuery>({});
  const [{ page, pageSize }, setPageQuery] = useState<IPageQuery>({ page: 1, pageSize: 10000 });

  const complianceTrackerQuery = useQuery<{ form: IForm[] }>(GET_PAGINATED_FORMS_BY_DEAL, {
    fetchPolicy: 'no-cache',
    skip: !dealId || side !== DealSide.SELL,
    variables: {
      dealId,
      ...filters,
      sort: filters.sortParams,
      page,
      pageSize,
    },
    notifyOnNetworkStatusChange: true,
  });

  const { globalShow } = useStateModal();

  const reportPropsConfig = useMemo(() => {
    return {
      viewDetailsAction: (context: any) => () => {
        const isArrayIdentifier = Array.isArray(context.identifier);
        routerHistory.push(
          `/deal-regulation/${dealId}/${
            isArrayIdentifier ? context.identifier[0] : context.identifier
          }`
        );
      },
      completeAction: (context: any) => () => {
        globalShow(context.metadata.drawerProps.key, context.metadata.drawerProps.props);
      },
    };
  }, [routerHistory, globalShow]);

  const getReports = getDealRegulationProps(reportPropsConfig);

  const getWorkflowContext = (identifier: string): MADealWorkflowConfig | undefined => {
    const workflow = getDealRegulation(reportPropsConfig)(
      _get(complianceTrackerQuery, 'data.getPaginatedFormsByDeal.forms', [])
    );
    return workflow.find(wf => {
      const isArray = Array.isArray(wf.identifier);
      if (isArray) {
        return wf.identifier.includes(identifier);
      } else {
        return wf.identifier === identifier;
      }
    });
  };

  const paginationInfo = (query: QueryResult<{ form: IForm[] }>): IPaginationInfo => ({
    current: page,
    size: pageSize,
    totalPages: _get(query, 'data.getPaginatedFormsByDeal.totalPages'),
    totalRecord: _get(query, 'data.getPaginatedFormsByDeal.totalRecords'),
  });

  return {
    loadingLoginToDeal,
    setDealId,
    dealId,
    query: {
      ...complianceTrackerQuery,
      getReports,
    },
    pagination: {
      ...paginationInfo(complianceTrackerQuery),
      setPageQuery,
    },
    filters: {
      ...filters,
      DEAL_FORM_FILTERS,
      setFilterQuery,
    },
    sortFilter,
    setSortFilter,
    getWorkflowContext,
  };
};

type DealRegulationHandlerType = ReturnType<typeof useDealRegulationHandler>;
export const DealRegulationContext = React.createContext<DealRegulationHandlerType>(
  {} as DealRegulationHandlerType
);
export const DealRegulationProvider = ({ children }: { children: React.ReactNode }) => {
  const { dealRegulation } = useContext(FormsContext);
  useDealRegulationRouteCheck(dealRegulation.setDealId);
  return (
    <DealRegulationContext.Provider value={dealRegulation}>
      {children}
    </DealRegulationContext.Provider>
  );
};
