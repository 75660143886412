import styled from 'styled-components';
import { Input } from '@dealsyte/poki';

export const SpinnerContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const MarketplaceBrowserContainer = styled.div`
  ${({ theme }) => {
    const { colors, spacing, typography, radii, shadows } = theme.foundations;
    return `
      overflow-y: scroll;
      background-color: ${colors.palette.neutral['100']};
      height: 100%;
      position: relative;
      .header {
        // TODO: Move color to the design system
        background-color: ${colors.palette.neutral['100']};
        height: 90px;
        width: 100%;
        padding: ${spacing['07']} ${spacing['11']};
        padding-bottom: 0;
        display: flex;

        .title {
          font-size: ${typography.sizes['08']};
          font-family: ${typography.fonts.heading};
          font-weight: ${typography.weights.bold};
          line-height: 40px;
          color: ${colors.palette.neutral['600']};
        }
      }

      .footer-container {
        position: sticky;
        margin-bottom: 30px;
        display: flex;
        flex: 1;
        justify-content: center;
        height: 50px;
      
        .button-show-more-container {
          display: flex;
          flex: 1;
          justify-content: center;
      
          .button-show-more {
            display: flex;
            padding: ${spacing['04']} ${spacing['05']};
            border: 1px solid ${colors.palette.neutral['600']};
            border-radius: ${radii.md};
            cursor: pointer;
            width: 205px;
            height: 48px;
            justify-content: center;
            align-items: center;

            &.isLoading {
              background-color: ${colors.palette.neutral['200']};
              border: none;

              p {
                margin-right: 4px;
                color: ${colors.palette.neutral['700']};
              }
            }
      
            p {
              margin-right: 4px;
              display: inline-block;
              font-family: ${typography.fonts.body};
              font-size: ${typography.sizes['03']};
              letter-spacing: 0.25px;
              font-weight: ${typography.weights.regular};
            }
          }
        }

        .scroll-top-container {
          width: 48px;
        }
      
        .button-scroll-top {
          width: 48px;
          height: 48px;
          background-color: ${colors.palette.neutral['50']};
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: ${shadows['01']};
          cursor: pointer;
          border-radius: ${radii.md};
          margin-right: 10px;
          margin-top: -58px;
        }
      }

      .container {
        flex: 1;
        padding: ${spacing['05']} ${spacing['12']} 0;

        .button-container {
          display: flex;
          flex-direction: row

          .newDeal-button {
            margin-left: ${spacing['02']};
            }
          
          .button {
            width: 180px;
            height: 40px;
            border-radius: ${radii.md};
            background-color: ${colors.palette.neutral['600']};
            display: flex;
            align-items: center;
            padding-left: ${spacing['04']};
            cursor: pointer;
            
          }
        }
        
          .filter-row {
            display: flex;

            .arrow-container{
              min-width: 8px;
            }
      
            .count-pill{
              width: 14px;
              height: 16px;
              justify-content: center;
              margin-left: ${spacing['02']};
              margin-right: ${spacing['04']};
              border-radius: ${radii.sm};
              font-size: ${typography.sizes['02']};
              color: ${colors.palette.neutral['600']};
              font-family: ${typography.fonts.body};
              font-weight: ${typography.weights.medium};
              line-height: ${spacing['04']};
              background-color: ${colors.palette.neutral['200']};
            }
          }
          
          .filter-row-2 {
            margin-top: 35px;
            padding-left: 5px;
            display: flex;
            align-items: center;
            
            p {
              color: ${colors.palette.neutral['600']};
              font-family: ${typography.fonts.body};
              font-size: ${typography.sizes['03']};
              letter-spacing: 0.75px;
              
              &.label-checkbox {
                color: ${colors.palette.neutral['900']};
              }
            }
            
            .separator {
              width: 1px;
              border-right: 1px solid ${colors.palette.neutral['300']};
              height: 24px;
              margin-left: ${spacing['02']};
              margin-right: ${spacing['02']};
              margin-top: -5px;
            }
            
            .button-fee-guide-run {
              background-color: transparent;
              border-radius: ${radii.md};
              width: 147px;
              height: 34px;
              display: flex;
              justify-content: center;
              cursor: pointer;
              margin-right: 20px;
              padding: ${spacing['02']};
              
              p {
                color: ${colors.palette.neutral['600']};
                font-size: ${typography.sizes['03']};
                font-family: ${typography.fonts.body};
                font-weight: ${typography.weights.regular};
                display: inline-block;
                margin-left: ${spacing['02']};
                text-align: center;
              }
              
              :hover {
                background-color: ${colors.palette.neutral['200']};
              }
            }
            
            .checkbox-container {
              display: flex;
              cursor: pointer;
              padding: ${spacing['01']};
              :focus-within {
                outline: ${spacing['01']} solid ${colors.palette.neutral[400]};
                border-radius: ${radii.sm};
              }
              label {
                font-family: ${typography.fonts.body};
                cursor: pointer;
              }
              input {
                height: auto;
                margin-top: 1px;
                margin-right: 10px;
              }
            }
            
            .sort-container {
              display: flex;
              flex: 1;
              .sort-align {
                display: flex;
                flex: 1;
                justify-content: flex-end;
              }
            }
          }

          .button-filter {
            display: flex;
            align-items: center;
            min-width: 100px;
            justify-content: space-between;
            border-radius: ${radii.md};
            padding: 11px ${spacing['03']};
            background-color: ${colors.palette.neutral['50']};
            cursor: pointer;
            padding-bottom: 10px;
            outline: 1px solid ${colors.palette.neutral['200']};
            height: ${spacing['07']};
            :focus {
              outline: 4px solid ${colors.palette.neutral['400']} !important;
              :hover {
                outline: 4px solid ${colors.palette.neutral['400']};
              }
            }
            
            &.link {
              justify-content: center;
              background-color: transparent;
              border: none;
              color: ${colors.palette.blue['700']};
              font-size: ${typography.sizes['03']};
              font-family: ${typography.fonts.body};
              font-weight: ${typography.weights.regular};
              line-height: 24.5px;
              letter-spacing: 0.25px;
              cursor: pointer;
              outline: none;
            }
            
            .count-filter {
              margin-left: ${spacing['02']};
              height: 16px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              background-color: ${colors.palette.neutral['200']};
              border-radius: ${radii.sm};
              padding: ${spacing['01']};
              font-family: ${typography.fonts.body};
              font-size: ${typography.sizes['02']};
              font-weight: ${typography.weights.regular};
              line-height: 16px;
            }
            
            &.my-deal {
              justify-content: center;
            }
            
            &.advanced-filter {
              width: 165px;
              justify-content: center;
              margin-left: auto;
              
              .equalizer-icon-container {
                display: inline-block;
                margin-right: ${spacing['02']};
              }
            }

            &.active {
              outline: 1px solid ${colors.palette.neutral['500']};
              :focus {
                outline: 4px solid ${colors.palette.neutral['400']};
              }
            }
            
            :hover {
              outline: 1px solid ${colors.palette.neutral['500']};
            }
            
            &.middle {
              margin-right: ${spacing['04']};
            }
            
            p {
              display: inline-block;
              font-family: ${typography.fonts.body};
              font-size: ${typography.sizes['03']};
              line-height: 17.5px;
              letter-spacing: 0.25px;
              font-weight: ${typography.weights.regular};
            }
          }
          
          .deals-container {
            padding: ${spacing['07']} 0 0;

            .empty-state-container {
              margin-top: 100px;
            }
          }
      }
    `;
  }}
`;

export const Checkbox = styled(Input)`
  ${({ theme }) => {
    const { colors } = theme.foundations;
    return `
      accent-color: ${colors.palette.neutral[600]};
      cursor: pointer;
      :focus,
      :active {
        border: none;
        box-shadow: none;
        outline: none;
      }`;
  }}
`;

export const CheckboxLabel = styled.p`
  ${({ theme }) => {
    const { colors, typography, spacing } = theme.foundations;
    return `
    display: 'inline-block';
    padding: ${spacing['02']};
    color: ${colors.palette.neutral['900']};
    font-size: 14px ${typography.sizes['03']};
    font-family: ${typography.fonts.body};

    &.industry {
      font-weight: ${typography.weights.bold};
    }

    &.location {
      font-weight: ${typography.weights.bold};
    }

    &.label-disabled {
      color: ${colors.palette.neutral['500']};
      font-family: ${typography.fonts.body};
      letter-spacing: 0.5px;
    }
  `;
  }}
`;

export const CheckboxItemWrapper = styled.label`
  ${({ theme }) => {
    const { colors, radii, spacing } = theme.foundations;
    return `
      display: flex;
      flex-direction: 'column';
      align-items: center;
      flex: 1;
      cursor: pointer;
      padding-right: ${spacing['02']};
      border: ${spacing['01']} solid transparent;

      input {
        height: ${spacing['04']};
      }

      :focus-within {
        border: ${spacing['01']} solid ${colors.palette.neutral[400]};
        border-radius: ${radii.md};
      }`;
  }}
`;
