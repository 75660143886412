import React, { ChangeEvent, useContext, useEffect } from 'react';
import { Query } from '@apollo/client/react/components';
import { Collapsable, Tooltip } from '@dealsyte/poki';
import get from 'lodash/get';

import { GET_FILEROOMS } from 'api/graphql/core-tools/vdr/queries';
import { GET_GROUPS, GET_PERMISSION_ENTITIES, GET_PERMISSIONS } from 'api/graphql/users/queries';
import EditPermissionsMutation from 'api/graphql/components/EditPermissionMutation';
import { Fso as IFso, ETransactionType } from 'app/core-tools/due-diligence/vdr/utils/types';
import ExternalTeamNotation from 'app/core-tools/due-diligence/components/ExternalTeamNotation';
import {
  AppContext as EAppContext,
  DealSide as EDealSide,
  Sort,
  IPermissionEntity,
  IGroup,
  EGroupType,
  IPermissionByEntity,
} from 'app/core-tools/due-diligence/types/types';
import { UserContext } from 'app/users/context/UserContext';

import { IProps } from './TypedPermissionsSettings';
import {
  SettingsDescription,
  PermissionRow,
  TitleRow,
  GroupName,
  Checkbox,
  CheckboxColumn,
  FileRoomPermissionsHeader,
  PermissionTableContainer,
  FileRoomTag,
  SideSection,
  IsCollapsedTag,
  EmptyTableMessage,
} from './StyledPermissionSettings';

const sides = {
  [EDealSide.SELL]: {
    name: 'Your Internal Groups',
    subtitle: 'Choose the level of security you want to give to your groups',
  },
  [EDealSide.BUY]: {
    name: <ExternalTeamNotation />,
    subtitle: (
      <>
        Choose the level of security you want to give to your potential{' '}
        <ExternalTeamNotation capitalize={false} />{' '}
      </>
    ),
  },
};

const PermissionsSettings = ({
  loading,
  error,
  fileRooms,
  groups,
  refetchGroupsData,
  permissionEntities,
  editPermission,
  permissionByEntities,
}: IProps) => {
  const accessPermissionEntity = permissionEntities.find(
    (permissionEntity) => permissionEntity.name === ETransactionType.originalDownload
  ) || { id: '' };
  const accessPermissionEntityId = accessPermissionEntity.id;

  useEffect(() => {
    refetchGroupsData();
  }, []);

  return (
    <div>
      <SettingsDescription>
        Grant access to groups and choose which actions they can perform on each Data Room.
      </SettingsDescription>
      {Object.entries(sides).map(([key, value]) => {
        const filteredGroups = groups.filter((group) => group.type === key);

        const filteredPermissionEntities = permissionEntities.filter((permission) =>
          permission.restrictions.sides.includes(key)
        );

        if (filteredPermissionEntities.length === 0) {
          return null;
        }

        const sortedPermissionsEntities = filteredPermissionEntities.sort((a, b) => {
          if (a.name === ETransactionType.originalDownload) return Sort.A_GOES_FIRST;
          if (b.name === ETransactionType.originalDownload) return Sort.B_GOES_FIRST;
          return Sort.EQUAL;
        });

        return (
          <SideSection key={key}>
            <h5 style={{ marginBottom: 10 }}>{value.name}</h5>
            <SettingsDescription style={{ paddingBottom: 20 }}>
              {value.subtitle}
            </SettingsDescription>
            <div>
              {fileRooms.map((fileRoom, index, array) => {
                const permissionByEntity = permissionByEntities.find(
                  (permissionByEntity) => permissionByEntity.entityId === fileRoom.id
                );
                const fileRoomPermissions = permissionByEntity
                  ? permissionByEntity.permissions
                  : [];

                return (
                  <Collapsable
                    key={fileRoom.id}
                    initClosed
                    header={({ isOpen }) => (
                      <FileRoomPermissionsHeader>
                        {fileRoom.name}
                        <FileRoomTag>Data Room</FileRoomTag>
                        <IsCollapsedTag>{isOpen ? 'hide' : 'show'}</IsCollapsedTag>
                      </FileRoomPermissionsHeader>
                    )}
                    body={
                      <PermissionTableContainer
                        style={{
                          transition: '0.1s',
                          opacity: loading ? 0.6 : 1,
                          paddingBottom: index === array.length - 1 ? 0 : 30,
                        }}
                      >
                        <TitleRow>
                          <GroupName>Group name</GroupName>
                          {sortedPermissionsEntities.map((permissionEntity) => (
                            <CheckboxColumn key={permissionEntity.id}>
                              <Tooltip message={permissionEntity.description}>
                                {permissionEntity.displayName}
                              </Tooltip>
                            </CheckboxColumn>
                          ))}
                        </TitleRow>
                        {filteredGroups.map((group) => {
                          const groupPermissions = fileRoomPermissions.filter(
                            (permission) => permission.ownerId === group.id
                          );

                          const hasAccess = groupPermissions.some(
                            (groupPermission) =>
                              groupPermission.permissionEntityId === accessPermissionEntityId &&
                              groupPermission.active &&
                              groupPermission.entityId === fileRoom.id
                          );
                          const disableEditPermissions =
                            group.type === EGroupType.SELL ? false : !hasAccess;
                          const removePermissions = () => {
                            groupPermissions.map(({ permissionEntityId, id, ownerId }) =>
                              editPermission({
                                permissionId: id,
                                ownerId,
                                fileRoomId: fileRoom.id,
                                permissionEntityId: permissionEntityId,
                                remove: true,
                              })
                            );
                          };
                          return (
                            <PermissionRow key={group.id}>
                              <GroupName>{group.name}</GroupName>
                              {sortedPermissionsEntities.map((permissionEntity) => {
                                const permission = groupPermissions.find(
                                  (permission) =>
                                    permission.permissionEntityId === permissionEntity.id &&
                                    permission.entityId === fileRoom.id
                                );
                                const getCheckbox = (PermissionName = '') => {
                                  switch (PermissionName) {
                                    case ETransactionType.watermarkDownload:
                                      return (
                                        <Tooltip
                                          message="Enable watermark to protect your documents"
                                          delay={300}
                                          hideOnClick={false}
                                        >
                                          <Checkbox
                                            onChange={onChange}
                                            checked={hasPermission}
                                            disabled={disableEditPermissions}
                                          />
                                        </Tooltip>
                                      );
                                    case ETransactionType.originalDownload:
                                      return (
                                        <Tooltip
                                          message="Enable download for this groups"
                                          delay={300}
                                          hideOnClick={false}
                                        >
                                          <Checkbox
                                            type="switch"
                                            onChange={onChange}
                                            checked={hasPermission}
                                          />
                                        </Tooltip>
                                      );
                                    case ETransactionType.upload:
                                      return (
                                        <Checkbox
                                          type="switch"
                                          onChange={onChange}
                                          checked={hasPermission}
                                        />
                                      );
                                    default:
                                      return <></>;
                                  }
                                };
                                const onChange = (event: ChangeEvent<HTMLInputElement>) => {
                                  const remove = !event.target.checked;
                                  if (
                                    remove &&
                                    permissionEntity.name === ETransactionType.originalDownload
                                  ) {
                                    removePermissions();
                                  } else {
                                    editPermission({
                                      permissionId: permission ? permission.id : '',
                                      ownerId: group.id,
                                      fileRoomId: fileRoom.id,
                                      permissionEntityId: permissionEntity.id,
                                      remove,
                                    });
                                  }
                                };

                                const hasPermission = !!(permission && permission.active);
                                const permissionEntityName = permissionEntity
                                  ? permissionEntity.name
                                  : '';
                                return (
                                  <CheckboxColumn key={permissionEntity.id}>
                                    {getCheckbox(permissionEntityName)}
                                  </CheckboxColumn>
                                );
                              })}
                            </PermissionRow>
                          );
                        })}
                        {filteredGroups.length === 0 && (
                          <EmptyTableMessage>There are no groups</EmptyTableMessage>
                        )}
                      </PermissionTableContainer>
                    }
                  />
                );
              })}
              {fileRooms.length === 0 && (
                <EmptyTableMessage>There are no Data Rooms</EmptyTableMessage>
              )}
            </div>
          </SideSection>
        );
      })}
    </div>
  );
};

const ConnectedPermissionsSettings = () => {
  const { activeDeal } = useContext(UserContext);

  return (
    <EditPermissionsMutation>
      {(mutatePermissions) => {
        const editPermission = ({
          permissionId,
          ownerId,
          fileRoomId,
          permissionEntityId,
          remove,
        }: {
          permissionId: string;
          ownerId: string;
          fileRoomId: string;
          permissionEntityId: string;
          remove: boolean;
        }) =>
          mutatePermissions({
            ownerId,
            fsoIds: [fileRoomId],
            permissionEntityId,
            remove,
          });

        return (
          <Query<{ permissionEntities: IPermissionEntity[] }>
            query={GET_PERMISSION_ENTITIES}
            // TODO: Handle data filter by dealside using $side var. With that,
            // we can remove extra validations made in PermissionsSettings component
            variables={{ dealId: get(activeDeal, 'id', ''), context: EAppContext.VDR }}
          >
            {({
              loading: loadingPermissionEntities,
              error: onErrorPermissionEntities,
              data: permissionEntitiesData,
            }) => {
              const permissionEntities =
                loadingPermissionEntities || !permissionEntitiesData
                  ? []
                  : permissionEntitiesData.permissionEntities;

              return (
                <Query<{ groups: IGroup[] }>
                  query={GET_GROUPS}
                  variables={{ dealId: get(activeDeal, 'id', ''), onlyActiveGroups: true }}
                >
                  {({ loading: loadingGroups, data: groupsData, refetch: refetchGroupsData }) => {
                    const groups = loadingGroups || !groupsData ? [] : groupsData.groups;

                    return (
                      <Query<{ fileRooms: IFso[] }>
                        query={GET_FILEROOMS}
                        variables={{ dealId: get(activeDeal, 'id', '') }}
                      >
                        {({
                          loading: loadingFileRooms,
                          error: onErrorFileRooms,
                          data: fileRoomsData,
                        }) => {
                          if (onErrorFileRooms)
                            return <SettingsDescription>Error!</SettingsDescription>;

                          if (loadingGroups || loadingFileRooms || loadingPermissionEntities) {
                            return (
                              <SettingsDescription>
                                Loading Permissions Configurations...
                              </SettingsDescription>
                            );
                          }

                          const fileRooms =
                            loadingFileRooms || !fileRoomsData ? [] : fileRoomsData.fileRooms;

                          const entityIds = fileRooms
                            .map((fileRoom: { id: string }) => fileRoom.id)
                            .sort();

                          return (
                            <Query<{ permissions: IPermissionByEntity[] }>
                              query={GET_PERMISSIONS}
                              fetchPolicy="cache-first"
                              variables={{ entityIds }}
                            >
                              {({
                                loading: loadingPermissions,
                                error: onErrorPermissions,
                                data: permissionsData,
                              }) => {
                                const permissions =
                                  loadingPermissions || !permissionsData
                                    ? []
                                    : permissionsData.permissions;

                                return (
                                  <PermissionsSettings
                                    loading={loadingPermissions}
                                    fileRooms={fileRooms}
                                    groups={groups}
                                    refetchGroupsData={refetchGroupsData}
                                    editPermission={editPermission}
                                    permissionEntities={permissionEntities}
                                    permissionByEntities={permissions}
                                    error={onErrorPermissions || onErrorPermissionEntities}
                                  />
                                );
                              }}
                            </Query>
                          );
                        }}
                      </Query>
                    );
                  }}
                </Query>
              );
            }}
          </Query>
        );
      }}
    </EditPermissionsMutation>
  );
};

export default ConnectedPermissionsSettings;
