import * as React from 'react';
import { IconProps } from './types';

export const CheckIcon = ({
  width = '10px',
  height = '9px',
  color = 'currentColor',
  style = {},
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 10 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.46659 0.799998C9.19992 0.533332 8.79992 0.533332 8.53325 0.799998L3.53325 5.8L1.46659 3.73333C1.19992 3.46667 0.799919 3.46667 0.533252 3.73333C0.266585 4 0.266585 4.4 0.533252 4.66667L3.06659 7.2C3.19992 7.33333 3.33325 7.4 3.53325 7.4C3.73325 7.4 3.86659 7.33333 3.99992 7.2L9.46659 1.73333C9.73325 1.46667 9.73325 1.06667 9.46659 0.799998Z"
      fill={color}
    />
  </svg>
);
