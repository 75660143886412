import styled from 'styled-components';

export const MainContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
`;

export const IconContainer = styled.div`
  margin-right: 20px;
`;

export const Title = styled.h4`
  font-size: 16px;
  font-family: Helvetica;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #707f8f;
  font-weight: bold;
  padding-bottom: 8px;
`;

export const Text = styled.p`
  font-size: 14px;
  color: #8c98a6;
  line-height: 24px;
  font-family: Helvetica;
  letter-spacing: 0.25px;
`;
