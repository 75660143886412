import { LoadingCircle } from '@dealsyte/poki';
import React from 'react';
import * as S from './LoaderStyled';

export interface LoaderProps {
  /**
   * @param {boolean} maxScreen - boolean to set the Loader to fill the entire screen
   */
  maxScreen?: boolean;

  /** @param {string} label - string to change the component label */
  label?: string;

  /** @param {boolean} blurBackground - boolean to blur the background */
  blurBackground?: boolean;
}

export const Loader = ({ maxScreen, label, blurBackground }: LoaderProps) => (
  <S.LoaderContainer maxScreen={maxScreen} blurBackground={blurBackground}>
    <S.SpinnerContainer>
      <LoadingCircle height={15} />
      <p>{label || 'Loading'}</p>
    </S.SpinnerContainer>
  </S.LoaderContainer>
);
