import React, { useContext } from 'react';
import get from 'lodash/get';
import { UserContext } from 'app/users/context/UserContext';
import { IProps } from './TypedExternalTeamNotation';
import { getExternalTeamNotation } from 'app/utils/helpers/helpers';
import { DealType } from 'types/graphql-types';

/**
 * Renders the proper external team notation (Eg buyers, investors, etc).
 * @param props The component props.
 * @param props.plural Indicates whether you want the plural or singular notation.
 * @param props.capitalize Indicates whether you want to capitalize the notation.
 */
function ExternalTeamNotation({ plural = true, capitalize = true }: IProps) {
  const { activeDeal } = useContext(UserContext);
  return (
    <>{getExternalTeamNotation(get(activeDeal, 'type') as DealType, { plural, capitalize })}</>
  );
}

export default ExternalTeamNotation;
