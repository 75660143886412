// Libraries
import React from 'react';
import PropTypes from 'prop-types';

const DealTypeIcon = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox={`0 0 ${props.width} ${props.height}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 9.00391H7V5.00391H9C9.26522 5.00391 9.51957 5.10926 9.70711 5.2968C9.89464 5.48434 10 5.73869 10 6.00391C10 6.26912 10.1054 6.52348 10.2929 6.71101C10.4804 6.89855 10.7348 7.00391 11 7.00391C11.2652 7.00391 11.5196 6.89855 11.7071 6.71101C11.8946 6.52348 12 6.26912 12 6.00391C12 5.20826 11.6839 4.4452 11.1213 3.88259C10.5587 3.31998 9.79565 3.00391 9 3.00391H7V1.00391C7 0.73869 6.89464 0.484336 6.70711 0.296799C6.51957 0.109263 6.26522 0.00390625 6 0.00390625C5.73478 0.00390625 5.48043 0.109263 5.29289 0.296799C5.10536 0.484336 5 0.73869 5 1.00391V3.00391H4C2.93913 3.00391 1.92172 3.42533 1.17157 4.17548C0.421427 4.92562 0 5.94304 0 7.00391C0 8.06477 0.421427 9.08219 1.17157 9.83233C1.92172 10.5825 2.93913 11.0039 4 11.0039H5V15.0039H3C2.73478 15.0039 2.48043 14.8986 2.29289 14.711C2.10536 14.5235 2 14.2691 2 14.0039C2 13.7387 1.89464 13.4843 1.70711 13.2968C1.51957 13.1093 1.26522 13.0039 1 13.0039C0.734784 13.0039 0.48043 13.1093 0.292893 13.2968C0.105357 13.4843 0 13.7387 0 14.0039C0 14.7996 0.316071 15.5626 0.87868 16.1252C1.44129 16.6878 2.20435 17.0039 3 17.0039H5V19.0039C5 19.2691 5.10536 19.5235 5.29289 19.711C5.48043 19.8986 5.73478 20.0039 6 20.0039C6.26522 20.0039 6.51957 19.8986 6.70711 19.711C6.89464 19.5235 7 19.2691 7 19.0039V17.0039H8C9.06087 17.0039 10.0783 16.5825 10.8284 15.8323C11.5786 15.0822 12 14.0648 12 13.0039C12 11.943 11.5786 10.9256 10.8284 10.1755C10.0783 9.42533 9.06087 9.00391 8 9.00391ZM5 9.00391H4C3.46957 9.00391 2.96086 8.79319 2.58579 8.41812C2.21071 8.04305 2 7.53434 2 7.00391C2 6.47347 2.21071 5.96477 2.58579 5.58969C2.96086 5.21462 3.46957 5.00391 4 5.00391H5V9.00391ZM8 15.0039H7V11.0039H8C8.53043 11.0039 9.03914 11.2146 9.41421 11.5897C9.78929 11.9648 10 12.4735 10 13.0039C10 13.5343 9.78929 14.043 9.41421 14.4181C9.03914 14.7932 8.53043 15.0039 8 15.0039Z"
      fill={props.color}
    />
  </svg>
);

DealTypeIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

DealTypeIcon.defaultProps = {
  width: '12px',
  height: '21px',
  color: '#807DA1',
};

export default DealTypeIcon;
