import React, { useContext } from 'react';
import Select from 'react-select';
import { getSelectUserRoleCustomStyles } from '../GroupMembersTable/HelpersGroupMembersTable';
import { ThemeContext } from 'styled-components';
import { ValueType, OptionsType } from 'react-select/src/types';

type IOption = {
  label: string | React.ReactNode;
  value: number;
};

type IProps = {
  options: IOption[];
  value: string | number;
  onChange: (idx: number) => void;
};
const roleOptions = [{ label: 'Member', value: 0 }, { label: 'Admin', value: 1 }] as OptionsType<{
  label: string;
  value: number;
}>;

const InviteUsersSelector: React.FC<IProps> = ({ options, value, onChange }) => {
  const currentIndex = options.findIndex(option => option.value === value);
  const theme = useContext(ThemeContext);
  const handleUserRoleInputChange = (selectedRole: ValueType<{ value: number; label: string }>) => {
    if (selectedRole) {
      // @ts-ignore
      return onChange(parseInt(selectedRole.value));
    }
  };

  return (
    <Select
      defaultValue={roleOptions[currentIndex]}
      onChange={handleUserRoleInputChange}
      options={roleOptions}
      name="color"
      styles={getSelectUserRoleCustomStyles(theme, true)}
      className="react-select"
      classNamePrefix="react-select"
      isSearchable={false}
    ></Select>
  );
};

export default InviteUsersSelector;
