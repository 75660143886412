import { useMutation, DataProxy } from '@apollo/client';
import { gql, FetchResult, ApolloError } from '@apollo/client';

import { EDIT_PERMISSIONS } from './mutations';
import {
  EDIT_PERMISSIONS_MUTATION,
  EDIT_PERMISSIONS_MUTATIONVariables,
} from './types/EDIT_PERMISSIONS_MUTATION';

export type IUseEditPermissionsMutationArgs = {
  onComplete?: (data: EDIT_PERMISSIONS_MUTATION) => void;
  onError?: (error: ApolloError) => void;
};

export type IUseEditPermissionsMutationVariables = {
  ownerId: string;
  fsoIds: string[];
  permissionEntityId: string;
  remove: boolean;
};

/**
 * [Hook] Provides all the logic for editting filerooms permissions (GQL mutation).
 * @param configs Mutation optional configs.
 * @returns The mutation triggering function and the loading/error states of it after its
 * execution.
 */
function useEditPermissionMutation(
  configs?: IUseEditPermissionsMutationArgs
): {
  editPermissionsMutation: (variables: IUseEditPermissionsMutationVariables) => void | undefined;
  loadingEditPermissionsMutation: boolean;
  errorOnEditPermissionsMutation: ApolloError | undefined;
} {
  const [
    editPermissionsBaseMutation,
    { loading: loadingEditPermissionsMutation, error: errorOnEditPermissionsMutation },
  ] = useMutation<EDIT_PERMISSIONS_MUTATION, EDIT_PERMISSIONS_MUTATIONVariables>(EDIT_PERMISSIONS, {
    onCompleted(data) {
      configs && configs.onComplete && configs.onComplete(data);
    },
    onError(error) {
      configs && configs.onError && configs.onError(error);
    },
  });

  /**
   * Function to trigger the edit permissions GQL mutation.
   * @param variables The GQL mutation variables.
   */
  function editPermissionsMutation(
    variables: IUseEditPermissionsMutationVariables
  ): void | undefined {
    if (variables.fsoIds.length === 0) {
      return undefined;
    }

    editPermissionsBaseMutation({
      variables: {
        ownerIds: [variables.ownerId],
        fsoIds: variables.fsoIds,
        permissionEntityId: variables.permissionEntityId,
        remove: variables.remove,
      },
      optimisticResponse: {
        setPermission: variables.fsoIds.map(fsoId => ({
          __typename: 'Permission',
          id: `${fsoId}-${variables.ownerId}-${variables.permissionEntityId}`,
          entityId: fsoId,
          ownerId: variables.ownerId,
          active: !variables.remove,
          permissionEntityId: variables.permissionEntityId,
        })),
      },
      update: variables.remove
        ? undefined
        : (
            client: DataProxy,
            { data: { setPermission } }: FetchResult<any, Record<string, any>>
          ) => {
            const fragment = gql`
              fragment myEntityPermission on PermissionByEntity {
                id
                entityId
                permissions {
                  id
                  entityId
                  ownerId
                  permissionEntityId
                  active
                }
              }
            `;

            setPermission.forEach((createdPermission: { id: string; entityId: string }) => {
              try {
                const permissionByEntity = client.readFragment({
                  id: `PermissionByEntity:${createdPermission.entityId}`,
                  fragment,
                }) as { id: string; permissions: any[] };

                if (permissionByEntity) {
                  permissionByEntity.permissions = [
                    ...permissionByEntity.permissions.filter(
                      permission => permission.id !== createdPermission.id
                    ),
                    createdPermission,
                  ];
                }

                client.writeFragment({
                  id: `PermissionByEntity:${createdPermission.entityId}`,
                  fragment,
                  data: permissionByEntity,
                });
              } catch (error) {
                console.log(error);
              }
            });
          },
    });
  }

  return {
    editPermissionsMutation,
    loadingEditPermissionsMutation,
    errorOnEditPermissionsMutation,
  };
}

export { useEditPermissionMutation };
