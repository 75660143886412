import * as React from 'react';
import { IconProps } from './types';

export const DollarIcon = ({
  width = '12',
  height = '21',
  color = 'currentColor',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 12 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 9.00244H7V5.00244H9C9.26522 5.00244 9.51957 5.1078 9.70711 5.29533C9.89464 5.48287 10 5.73723 10 6.00244C10 6.26766 10.1054 6.52201 10.2929 6.70955C10.4804 6.89708 10.7348 7.00244 11 7.00244C11.2652 7.00244 11.5196 6.89708 11.7071 6.70955C11.8946 6.52201 12 6.26766 12 6.00244C12 5.20679 11.6839 4.44373 11.1213 3.88112C10.5587 3.31851 9.79565 3.00244 9 3.00244H7V1.00244C7 0.737225 6.89464 0.482871 6.70711 0.295335C6.51957 0.107798 6.26522 0.00244141 6 0.00244141C5.73478 0.00244141 5.48043 0.107798 5.29289 0.295335C5.10536 0.482871 5 0.737225 5 1.00244V3.00244H4C2.93913 3.00244 1.92172 3.42387 1.17157 4.17401C0.421427 4.92416 0 5.94158 0 7.00244C0 8.06331 0.421427 9.08072 1.17157 9.83087C1.92172 10.581 2.93913 11.0024 4 11.0024H5V15.0024H3C2.73478 15.0024 2.48043 14.8971 2.29289 14.7095C2.10536 14.522 2 14.2677 2 14.0024C2 13.7372 1.89464 13.4829 1.70711 13.2953C1.51957 13.1078 1.26522 13.0024 1 13.0024C0.734784 13.0024 0.48043 13.1078 0.292893 13.2953C0.105357 13.4829 0 13.7372 0 14.0024C0 14.7981 0.316071 15.5612 0.87868 16.1238C1.44129 16.6864 2.20435 17.0024 3 17.0024H5V19.0024C5 19.2677 5.10536 19.522 5.29289 19.7095C5.48043 19.8971 5.73478 20.0024 6 20.0024C6.26522 20.0024 6.51957 19.8971 6.70711 19.7095C6.89464 19.522 7 19.2677 7 19.0024V17.0024H8C9.06087 17.0024 10.0783 16.581 10.8284 15.8309C11.5786 15.0807 12 14.0633 12 13.0024C12 11.9416 11.5786 10.9242 10.8284 10.174C10.0783 9.42387 9.06087 9.00244 8 9.00244ZM5 9.00244H4C3.46957 9.00244 2.96086 8.79173 2.58579 8.41665C2.21071 8.04158 2 7.53287 2 7.00244C2 6.47201 2.21071 5.9633 2.58579 5.58823C2.96086 5.21315 3.46957 5.00244 4 5.00244H5V9.00244ZM8 15.0024H7V11.0024H8C8.53043 11.0024 9.03914 11.2132 9.41421 11.5882C9.78929 11.9633 10 12.472 10 13.0024C10 13.5329 9.78929 14.0416 9.41421 14.4167C9.03914 14.7917 8.53043 15.0024 8 15.0024Z"
      fill={color}
    />
  </svg>
);
