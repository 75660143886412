import { compare } from 'natural-orderby';

import { Fso as IFso } from 'app/core-tools/due-diligence/vdr/utils/types';
import { MAX_FSO_NAME_DEFINITION_CONSTRAINT } from 'app/core-tools/due-diligence/vdr/utils/constants/various';

export function sortFileRooms(fileRoomsArr: IFso[]): IFso[] {
  const criteria = 'createdAt';
  const order = 'desc';

  if (fileRoomsArr.length === 0) return fileRoomsArr;

  const _sortFileRooms = (fsosArr: IFso[]) => {
    const _fileRoomsArr = [...fsosArr];

    _fileRoomsArr.sort((fileRoom_i, fileRoom_j) => {
      return compare({ order })(fileRoom_i[criteria], fileRoom_j[criteria]);
    });

    return _fileRoomsArr;
  };

  return _sortFileRooms(fileRoomsArr);
}

export function isFsoNameValid(name: string): boolean {
  const nameIsEmpty = name.length === 0,
    nameExceedsMaxLength = name.length > MAX_FSO_NAME_DEFINITION_CONSTRAINT;

  return !nameExceedsMaxLength && !nameIsEmpty;
}
