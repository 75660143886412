import React from 'react';
import { ChevronIcon } from 'app/common/icons';
import StyledButton from './StyledButton';

export interface ButtonProps extends React.ComponentProps<'button'> {
  isLoading?: boolean;
  displayAs?: 'button' | 'dropdown';
  dataTestid?: string;
}

export const Button = ({
  children,
  isLoading,
  className = 'primary',
  displayAs = 'button',
  dataTestid,
  ...props
}: ButtonProps): JSX.Element => {
  // TODO - Add a spinner for loading state
  return (
    <StyledButton
      displayAs={displayAs}
      {...(props as any)}
      className={className}
      data-testid={dataTestid}
    >
      <div id="button__content">{isLoading ? '...' : children}</div>
      {displayAs === 'dropdown' && (
        <div id="button__dropdown__icon">
          <ChevronIcon size={15} />
        </div>
      )}
    </StyledButton>
  );
};
