import styled from 'styled-components';

export const SRContainer = styled.div`
  height: 410px;
  overflow-y: auto;
  padding: 0px 16px 0 40px;
  width: 99%;
`;

export const SelectLabel = styled.p<{ isBold?: boolean }>`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #525a7a;
  padding-bottom: 8px;
  font-weight: ${({ isBold }) => (isBold ? 'bold' : '400')};
`;

export const CustomMultiSelectStyles = {
  control: (styles: any) => ({
    // none of react-select's styles are passed to <Control />
    ...styles,
    width: '450px',
    border: '1px solid #d5d9ea',
    padding: 2,
  }),
  option: (styles: any) => ({
    ...styles,
    fontSize: 12,
  }),
  multiValue: (styles: any) => {
    return {
      ...styles,
      backgroundColor: '#dbcbff',
      padding: 4,
      color: '#625e6e',
    };
  },
};
