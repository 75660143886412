import apolloRouterClient from 'api/apolloRouterConnection';
import { CREATE_EVENTS } from 'api/graphql/analytics/mutations';

export interface EventTargetInput {
  children?: EventTargetInput[];
  id?: string;
  object: string;
}

export interface IAnalyticEvent {
  action: string;
  browser?: string;
  device?: string;
  eventName: string;
  object?: string;
  page?: string;
  section?: string;
  timestamp: string;
  userId: string;
  target?: EventTargetInput[];
}

export const analyticsEvents = () => {
  const createEvent = async (events: IAnalyticEvent[]) => {
    try {
      await apolloRouterClient.mutate({
        mutation: CREATE_EVENTS,
        variables: {
          input: events,
        },
      });
    } catch (error) {
      console.log('Error creating event', error);
    }
  };

  return {
    createEvent,
  };
};
