import React, { useMemo } from 'react';
import { Locale } from 'react-modern-calendar-datepicker';

import utils from './localeUtils';
import { TYPE_SINGLE_DATE, TYPE_RANGE, TYPE_MUTLI_DATE, getValueType } from './datePickerHelper';
import getLanguageText from './localeLanguage';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}
export interface DatePickerInputProps {
  formatInputText?: (value: any) => string;
  inputPlaceholder?: string;
  inputClassName?: string;
  inputName?: string;
  renderInput?: (props: {
    ref: React.LegacyRef<HTMLInputElement | null>;
    inputProps: InputProps;
  }) => JSX.Element;
  wrapperClassName?: string;
  locale: string | Locale | undefined;
  value: any;
}

const DatePickerInput = React.forwardRef(
  (props: DatePickerInputProps, ref: React.LegacyRef<HTMLInputElement>) => {
    const {
      value,
      inputPlaceholder,
      inputClassName,
      inputName,
      formatInputText,
      renderInput,
      locale,
    } = props;
    const useLocaleUtils = (localeUtils: any) => useMemo(() => utils(localeUtils), [localeUtils]);
    const putZero = (number: number) => (number.toString().length === 1 ? `0${number}` : number);
    const useLocaleLanguage = (localeLanguage: string | Locale | undefined) =>
      useMemo(() => getLanguageText(localeLanguage), [localeLanguage]);
    const { getLanguageDigits } = useLocaleUtils(locale);
    const {
      from: fromWord,
      to: toWord,
      yearLetterSkip,
      digitSeparator,
      defaultPlaceholder,
      isRtl,
    } = useLocaleLanguage(locale);

    const getSingleDayValue = () => {
      if (!value) return '';
      const year = getLanguageDigits(value.year);
      const month = getLanguageDigits(putZero(value.month));
      const day = getLanguageDigits(putZero(value.day));
      return `${year}/${month}/${day}`;
    };

    const getDayRangeValue = () => {
      if (!value || !value.from || !value.to) return '';
      const { from, to } = value;
      const fromText = `${getLanguageDigits(putZero(from.year))
        .toString()
        .slice(yearLetterSkip)}/${getLanguageDigits(putZero(from.month))}/${getLanguageDigits(
        putZero(from.day)
      )}`;
      const toText = `${getLanguageDigits(putZero(to.year))
        .toString()
        .slice(yearLetterSkip)}/${getLanguageDigits(putZero(to.month))}/${getLanguageDigits(
        putZero(to.day)
      )}`;
      return `${fromWord} ${fromText} ${toWord} ${toText}`;
    };

    const getMultiDateValue = () =>
      value.map((date: any) => getLanguageDigits(date.day)).join(`${digitSeparator} `);

    const getValue = () => {
      if (formatInputText) return formatInputText(value);
      const valueType = value ? getValueType(value) : TYPE_SINGLE_DATE;
      switch (valueType) {
        case TYPE_SINGLE_DATE:
          return getSingleDayValue();
        case TYPE_RANGE:
          return getDayRangeValue();
        case TYPE_MUTLI_DATE:
          return getMultiDateValue();
        default:
          return '';
      }
    };

    const placeholderValue = inputPlaceholder || defaultPlaceholder;

    const inputProps = {
      'data-testid': 'datepicker-input',
      readOnly: true,
      value: getValue(),
      name: inputName,
      placeholder: placeholderValue,
      className: `DatePicker__input -${isRtl ? 'rtl' : 'ltr'} ${inputClassName}`,
      'aria-label': placeholderValue,
    };

    if (renderInput) {
      return renderInput({ ref, inputProps });
    }
    return <input ref={ref} {...inputProps} />;
  }
);

export default DatePickerInput;
