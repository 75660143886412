import styled from 'styled-components';

export const MainContainer = styled.div<{ active?: boolean }>`
  background-color: ${({ active, theme }) =>
    active ? theme.colors.primary.default : 'transparent'};
  padding: 6px 15px;
  display: flex;

  &:hover {
    background-color: ${({ active, theme }) => (active ? undefined : theme.palette.gray.lighter)};
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fontSizes.small};
  overflow: hidden;
  margin-left: 10px;
  user-select: none;
`;

export const Name = styled.span<{ active?: boolean }>`
  color: ${({ theme }) => theme.palette.gray.darker};
  line-height: 16px;
  ${({ active, theme }) =>
    active ? `color: ${theme.colors.background.default}; font-weight: bold;` : ''}
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Description = styled.div<{ active?: boolean }>`
  color: ${({ active, theme }) =>
    active ? theme.colors.background.default : theme.palette.gray.dark};
`;
