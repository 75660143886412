export const ENV_TIMEOUT = parseInt(process.env.REACT_APP_IDLE_TIMEOUT_MINUTES as string, 10);

const ONE_SECOND = 1000;

const ONE_MINUTE = ONE_SECOND * 60;

// how long before the idle warning appears
export const IDLE_TIMEOUT = ONE_MINUTE * ENV_TIMEOUT;

// how long after the idle warning the logout happens
export const LOGOUT_TIMEOUT = ONE_MINUTE * 2;

export const TIME_OUT_MESSAGE = `You have been inactive for ${ENV_TIMEOUT} minutes. You will be automatically logged out
if no further activity is detected.`;

export const KEEP_ME_ACTIVE_MESSAGE = 'Keep me active';
