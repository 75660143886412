import { PlatformRole } from '../../../core-tools/due-diligence/types/types';

export const FINALIS_URLS = {
  HOMEPAGE: 'https://www.finalis.com/',
};

export const MIN_DEAL_SIZE = 1; // $
export const MAX_DEAL_SIZE = 999999999999999; // $
export const MAX_DEAL_EARNINGS = 2147483647; // $

export const COUNTRY_DEFAULT_VALUE = 'United States';
export const COUNTRY_DEFAULT_VALUE_CODE = 'USA';

export const CAPITAL_MARKET_FINALIS_EMAIL = 'capital.markets@finalis.com';

export const DEAL_FLOW_ONLY_ROLES = [
  PlatformRole.MARKETPLACE_ONLY_USER,
  PlatformRole.DEAL_FLOW_LAUNCH,
];

export const initialDealTypeSheet = [
  [
    {
      label: 'All ',
      value: 0,
    },
  ],
];

export const initialDealTypeSheetOptions = [
  [
    {
      label: 'All ',
      value: 0,
    },
    {
      label: 'Capital raise - corporate',
      value: 1,
    },
    {
      label: 'Capital raise - fund',
      value: 2,
    },
    {
      label: 'M&A - sell side',
      value: 3,
    },
    {
      label: 'M&A - buy side',
      value: 4,
    },
    {
      label: 'Asset sale',
      value: 5,
    },
    {
      label: 'Other',
      value: 6,
    },
  ],
];

export const LINK_TO_DIRECTORY_SHEET =
  'https://docs.google.com/spreadsheets/d/1Oz4-AhbQglr7PjjFccRGeFkQn4kFu9H4wmmNWbNSdeo/edit#gid=';

export const LINK_TO_DIRECTORY_SHEET_FOR_LAUNCH_USER =
  'https://docs.google.com/spreadsheets/d/1J-x4qec0I0Ciep-qDXNSQVqjvWuvNvCFVXZnwwPG4lI/edit#gid=';
