import styled from 'styled-components';

// Poki
import { Button } from '@dealsyte/poki';

export const MainContainer = styled.div`
  width: 100%;
  padding: 24px 0 10px;
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.rowSeparator};
`;

export const DescriptionContainer = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.palette.gray.dark};
  margin-top: 15px;
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AvatarName = styled.span`
  color: ${({ theme }) => theme.palette.gray.darker};
  font-size: 15px;
  line-height: 30px;
  display: inline-block;
`;

export const BackButton = styled(Button)`
  border: 1px solid #dee0e2;
  height: 25px;
  width: 30px;
  margin-right: 10px;
`;

export const EmptyGroupsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 300px;
  width: 100%;
  color: ${({ theme }) => theme.palette.gray.dark};
`;

export const DealOptionsButton = styled(Button)`
  /* TODO: Once poki buttons designs get updated, remove this
  component and use the corresponding from poki */
  background-image: none;
  border: 1px solid #f3f5f7;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  cursor: pointer;
  transform: rotate(90deg);
`;

export const DropDownContainer = styled.div`
  /* The container <div> - needed to position the dropdown content */
  position: relative;
  display: inline-block;
  padding-left: 16px;

  /* Dropdown Content -- Hidden by Default */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    color: #8c98a6;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 12px;
    background-color: #ffffff;

    :last-child {
      color: #ff5d75;
    }
  }

  /* Color of dropdown links on hover */
  .dropdown-content a:hover {
    background-color: #f9fafb;
  }

  /* Show the dropdown menu on hover */
  :hover .dropdown-content {
    display: block;
  }

  /* Change the background color of the dropdown button when the dropdown content is shown */
  :hover .dropbtn {
    background-color: #f9fafb;
  }
`;

export const EditNameButton = styled.div`
  margin-left: 10px;
  padding-top: 9px;
  cursor: pointer;
`;

export const InputGroupNameContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DropdownButton = styled(Button)<{ color?: string }>`
  background-image: none;
  color: ${({ color }) => (color ? color : '#8c98a6')};
  cursor: pointer;
  min-width: 160px;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 12px;
  text-align: left;
  background-color: rgb(255, 255, 255);
  border-block: 0;
  &:hover {
    background-color: rgb(249, 250, 251);
  }
`;
