import styled, { DefaultTheme } from 'styled-components';

export const CheckboxCardContainer = styled.div`
  ${({ theme, checked }: { theme: DefaultTheme; checked: boolean }) => {
    const { spacing, colors, radii } = theme.foundations;
    return `
      display: flex;
      align-items: flex-start;
      padding: ${spacing['04']} ${spacing['05']};
      border: 1px solid ${checked ? colors.outline.dark : colors.outline.light};
      border-radius: ${radii.md};
  `;
  }}
`;

export const TextContainer = styled.div`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return `
      display: flex;
      flex-direction: column;
      margin-left: ${spacing['04']};
  `;
  }}
`;

export const Title = styled.span`
  ${({ theme }) => {
    const { typography, colors } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['03']};
      font-weight: ${typography.weights.bold};
      letter-spacing: 0.25px;
      line-height: 20px;
      color: ${colors.text.default};
    `;
  }}
`;

export const Subtitle = styled.span`
  ${({ theme }) => {
    const { typography, colors, spacing } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['02']};
      font-weight: ${typography.weights.regular};
      letter-spacing: 0.25px;
      line-height: 18px;
      color: ${colors.text.light};
      margin-top: ${spacing['01']};
    `;
  }}
`;
