import React from 'react';

import Header from '../navigation/Header/Header';
import SidebarContainer from '../navigation/Sidebar/SidebarContainer';
import IdleMonitor from 'app/screen/components/IdleMonitor/IdleMonitor';

import { MainContainer, ContentContainer } from '../StyledApp';
import FormsContainer from '../compliance/components/forms/FormsContainer/FormsContainer';
import SideDrawerContainer from '../../app/marketplace/components/sideDrawers/SideDrawerContainer';
import { AttestationAlertDialog } from '../compliance/components/AttestationAlertDialog/AttestationAlertDialog';
import { ImpersonationOverlay } from '../common/components';

interface LayoutProps {
  children?: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <MainContainer>
        <SidebarContainer />
        <ImpersonationOverlay />
        <AttestationAlertDialog />
        <ContentContainer>
          <Header />
          {children}
        </ContentContainer>
      </MainContainer>
      <FormsContainer />
      <SideDrawerContainer />
      <IdleMonitor />
    </>
  );
};
