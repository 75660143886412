export const TYPE_SINGLE_DATE = 'SINGLE_DATE';
export const TYPE_RANGE = 'RANGE';
export const TYPE_MUTLI_DATE = 'MUTLI_DATE';

export const hasProperty = (object: any, propertyName: any) =>
  Object.prototype.hasOwnProperty.call(object || {}, propertyName);

export const getValueType = (valueToKnow: any) => {
  if (Array.isArray(valueToKnow)) return TYPE_MUTLI_DATE;
  if (hasProperty(valueToKnow, 'from') && hasProperty(valueToKnow, 'to')) return TYPE_RANGE;
  if (
    !valueToKnow ||
    (hasProperty(valueToKnow, 'year') &&
      hasProperty(valueToKnow, 'month') &&
      hasProperty(valueToKnow, 'day'))
  ) {
    return TYPE_SINGLE_DATE;
  }
  throw new TypeError(
    `The passed value is malformed! Please make sure you're using one of the valid value types for date picker.`
  );
};

export const putZero = (number: any) => (number.toString().length === 1 ? `0${number}` : number);
