// Libraries
import React from 'react';
import { IconProps } from './types';

export const PersonIcon = ({
  width = '13px',
  height = '13px',
  color = 'currentColor',
  style = {},
}: IconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 13 13" fill="none">
    <path
      d="M9.47431 7.74132C10.1279 7.22709 10.605 6.52193 10.8391 5.72395C11.0733 4.92596 11.0529 4.07483 10.7808 3.28897C10.5087 2.5031 9.99848 1.82158 9.32102 1.33922C8.64357 0.856866 7.83261 0.597656 7.00098 0.597656C6.16934 0.597656 5.35838 0.856866 4.68093 1.33922C4.00347 1.82158 3.4932 2.5031 3.22112 3.28897C2.94903 4.07483 2.92865 4.92596 3.16282 5.72395C3.39698 6.52193 3.87405 7.22709 4.52764 7.74132C3.40769 8.19002 2.4305 8.93422 1.70024 9.8946C0.969973 10.855 0.514016 11.9955 0.380974 13.1947C0.371344 13.2822 0.379052 13.3708 0.403658 13.4554C0.428264 13.5399 0.469286 13.6188 0.524382 13.6875C0.635654 13.8263 0.797497 13.9152 0.974308 13.9347C1.15112 13.9541 1.32841 13.9025 1.46719 13.7912C1.60597 13.68 1.69486 13.5181 1.71431 13.3413C1.8607 12.0381 2.4821 10.8345 3.45979 9.96052C4.43748 9.0865 5.70291 8.60334 7.01431 8.60334C8.32571 8.60334 9.59114 9.0865 10.5688 9.96052C11.5465 10.8345 12.1679 12.0381 12.3143 13.3413C12.3324 13.5051 12.4106 13.6564 12.5337 13.766C12.6568 13.8756 12.8162 13.9357 12.981 13.9347H13.0543C13.2291 13.9145 13.3888 13.8262 13.4987 13.6888C13.6086 13.5515 13.6597 13.3762 13.641 13.2013C13.5073 11.9988 13.0489 10.8553 12.3149 9.89346C11.5809 8.9316 10.5989 8.18764 9.47431 7.74132ZM7.00098 7.26799C6.47356 7.26799 5.95799 7.11159 5.51945 6.81857C5.08092 6.52556 4.73913 6.10908 4.5373 5.62181C4.33546 5.13454 4.28265 4.59836 4.38555 4.08108C4.48844 3.5638 4.74242 3.08864 5.11536 2.7157C5.4883 2.34276 5.96345 2.08879 6.48073 1.98589C6.99802 1.883 7.5342 1.93581 8.02146 2.13764C8.50873 2.33948 8.92521 2.68127 9.21823 3.1198C9.51124 3.55833 9.66764 4.0739 9.66764 4.60132C9.66764 5.30856 9.38669 5.98684 8.88659 6.48694C8.3865 6.98704 7.70822 7.26799 7.00098 7.26799Z"
      fill={color}
    />
  </svg>
);
