// vendors
import _get from 'lodash/get';

// Helpers
import { formatDateInput } from 'app/utils/helpers/helpers';
import { DEAL_STATUS_OPTIONS, DEAL_TYPES_OPTIONS } from 'app/utils/helpers/deal';
import { getDateTimePickerFormattedDate } from 'app/navigation/GlobalSidebar/components/SettingsModal/components/MarketplaceSettings/HelpersMarketplaceSettings';

// Constants
import {
  DEAL_INDUSTRY_TYPES_OPTIONS,
  getIndustryTypeSubOptions,
} from 'app/utils/helpers/dealIndustryTypes';

// Types
import { IPayload } from './TypesCreateMMDealSideDrawer';

const dealStatusToSelect = DEAL_STATUS_OPTIONS;

const getDealStatusOption = (statusValue: string) => {
  return dealStatusToSelect.filter(x => x.value === statusValue);
};

const getTransactionTypeOption = (transactionTypeValue: string) => {
  return DEAL_TYPES_OPTIONS.filter(x => x.value === transactionTypeValue);
};

const getIndustryTypeOption = (industryTypeValue: string) => {
  return DEAL_INDUSTRY_TYPES_OPTIONS.filter(x => x.value === industryTypeValue);
};

const getSubIndustryTypeOption = (industryTypeValue: string, subIndustryTypeValue: string) => {
  const subIndustryValues = getIndustryTypeSubOptions(industryTypeValue);
  return subIndustryValues.filter(x => x.value === subIndustryTypeValue[0]);
};

export const buildInitialValues = (dealSelected?: any) => {
  if (!dealSelected) {
    return {
      transactionType: null,
      dealStatus: null,
      country: '',
      state: '',
      industryType: null,
      industryTypeSubOptions: null,
      description: '',
      isRetainer: false,
      retainerAmount: null,
      tailPeriodDuration: 6,
      expectedSize: 0,
      minSuccessFee: 0,
      maxSuccessFee: 0,
      loiSignedDate: null,
      engagementLetterSignedDate: null,
    };
  }

  return {
    transactionType: getTransactionTypeOption(dealSelected.type)[0],
    dealStatus: getDealStatusOption(dealSelected.status)[0],
    country: dealSelected.country,
    state: dealSelected.state,
    industryType: getIndustryTypeOption(dealSelected.primaryIndustry)[0],
    industryTypeSubOptions: getSubIndustryTypeOption(
      dealSelected.primaryIndustry,
      dealSelected.secondaryIndustries
    )[0],
    description: dealSelected.description,
    isRetainer: dealSelected.retainerAmount && dealSelected.retainerAmount > 0,
    retainerAmount: dealSelected.retainerAmount,
    tailPeriodDuration: dealSelected.tailPeriodDuration,
    expectedSize: dealSelected.expectedSize,
    minSuccessFee: dealSelected.calculatedPercentRate.min,
    maxSuccessFee: dealSelected.calculatedPercentRate.max,
    loiSignedDate: getDateTimePickerFormattedDate(dealSelected.loiSignedDate),
    engagementLetterSignedDate: getDateTimePickerFormattedDate(
      dealSelected.engagementLetterSignedDate
    ),
  };
};

export const buildPayload = (values: any): IPayload => {
  return {
    primaryIndustry: values.industryType.value,
    secondaryIndustries: values.industryTypeSubOptions ? [values.industryTypeSubOptions.value] : [],
    country: values.country,
    state: values.state,
    type: values.transactionType.value,
    expectedSize: Number.parseFloat(values.expectedSize),
    status: values.dealStatus.value,
    description: values.description,
    calculatedPercentRate: {
      max: Number.parseFloat(values.maxSuccessFee),
      min: Number.parseFloat(values.minSuccessFee),
    },
    engagementLetterSignedDate: values.engagementLetterSignedDate
      ? formatDateInput(values.engagementLetterSignedDate)
      : null,
    retainerAmount: Number.parseFloat(values.retainerAmount),
    tailPeriodDuration: values.tailPeriodDuration,
  };
};

export const getStyles = (errors: any, fieldName: string): {} | undefined => {
  if (_get(errors, fieldName)) {
    return {
      border: '1px solid red',
    };
  }
  return undefined;
};
