const palette = {
  transparent: 'transparent',
  black: '#000000',
  white: '#FFFFFF',
  accent: '#2BD4DB',
  neutral: {
    50: '#FEFEFE',
    100: '#F8F8FC',
    200: '#EDEDF7',
    300: '#D8D7EA',
    400: '#B9B6D2',
    500: '#6A678E',
    600: '#47455F',
    700: '#353347',
    800: '#292839',
    900: '#1D1B2C',
  },
  purple: {
    50: '#F2F0FF',
    100: '#E7E3FD',
    200: '#DCD6FF',
    300: '#C5BBF7',
    400: '#A291F7',
    500: '#8F75FF',
    600: '#7358E9',
    700: '#6043DF',
    800: '#442CC9',
    900: '#2B13B4',
  },
  blue: {
    50: '#F1F5FE',
    100: '#E1EAFE',
    200: '#C5D7FC',
    300: '#B0CAFC',
    400: '#78A1F2',
    500: '#4983F8',
    600: '#2C6EF2',
    700: '#185ADC',
    800: '#144BB8',
    900: '#092B9A',
  },
  lightBlue: {
    50: '#F1F9FE',
    100: '#DDF1FD',
    200: '#C7EAFF',
    300: '#A6D9F7',
    400: '#82C6ED',
    500: '#42A4E0',
    600: '#308EC5',
    700: '#177BB5',
    800: '#0A689E',
    900: '#0E537B',
  },
  teal: {
    50: '#F1FEFA',
    100: '#E0FFF8',
    200: '#C7FFF2',
    300: '#9BF8ED',
    400: '#64EDE4',
    500: '#29D5DB',
    600: '#1EBDD2',
    700: '#0AACC2',
    800: '#1C8297',
    900: '#06737F',
  },
  green: {
    50: '#F5FFF5',
    100: '#DDFDDD',
    200: '#C2FFC2',
    300: '#A8FAA8',
    400: '#87ED87',
    500: '#66DB66',
    600: '#52CB52',
    700: '#32A932',
    800: '#1C8D1C',
    900: '#186818',
  },
  yellow: {
    50: '#FFFBF0',
    100: '#FEF6DC',
    200: '#FAEBC2',
    300: '#FCDC97',
    400: '#FFCD70',
    500: '#FFBE4D',
    600: '#F2A71C',
    700: '#D08B0B',
    800: '#A26607',
    900: '#7F560A',
  },
  orange: {
    50: '#FFF8F0',
    100: '#FFF1E0',
    200: '#FFE1C2',
    300: '#FACDA3',
    400: '#FFBA7A',
    500: '#FF9E42',
    600: '#FF852E',
    700: '#EB6A19',
    800: '#D15310',
    900: '#A33C00',
  },
  red: {
    50: '#FFF1F0',
    100: '#FEE0DC',
    200: '#FFCDC7',
    300: '#FFA599',
    400: '#FF8170',
    500: '#EA5F4D',
    600: '#E14733',
    700: '#CA3521',
    800: '#AC1F0C',
    900: '#7F0F01',
  },
  danger: {
    100: '#FEE0DC',
    400: '#7F0F01',
  },
  warning: {
    100: '#FFF1E0',
    300: '#A33C00',
  },
  success: {
    100: '#DDFDDD',
    300: '#186818',
  },
};

const elements = {
  light: {
    primary: {
      xLight: palette.neutral['300'],
      light: palette.neutral['500'],
      default: palette.neutral['600'],
      dark: palette.neutral['700'],
    },
    text: {
      default: palette.neutral['700'],
      light: palette.neutral['500'],
      dark: palette.neutral['800'],
      disabled: palette.neutral['400'],
      buttons: palette.neutral['50'],
      link: palette.blue['700'],
    },
    background: {
      primary: palette.neutral['50'],
      secondary: palette.neutral['100'],
      tertiary: palette.neutral['200'],
      other: palette.neutral['300'],
    },
    outline: {
      xLight: palette.neutral['200'],
      light: palette.neutral['300'],
      default: palette.neutral['500'],
      dark: palette.neutral['800'],
    },
    states: {
      error: palette.red['700'],
    },
  },
  dark: {
    primary: {
      xLight: palette.neutral['300'],
      light: palette.neutral['300'],
      default: palette.neutral['200'],
      dark: palette.neutral['100'],
    },
    text: {
      default: palette.neutral['200'],
      light: palette.neutral['400'],
      dark: palette.neutral['100'],
      disabled: palette.neutral['500'],
      buttons: palette.neutral['900'],
      link: palette.blue['700'],
    },
    background: {
      primary: palette.neutral['700'],
      secondary: palette.neutral['800'],
      tertiary: palette.neutral['900'],
      other: palette.neutral['600'],
    },
    outline: {
      xLight: palette.neutral['700'],
      light: palette.neutral['600'],
      default: palette.neutral['500'],
      dark: palette.neutral['300'],
    },
    states: {
      error: palette.red['700'],
    },
  },
};

export default function(mode: 'light' | 'dark' = 'light') {
  return {
    palette,
    ...elements[mode],
  };
}
