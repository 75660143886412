import { QnAEvent } from './types';
import { IState, IReduxNotification } from 'app/core-tools/due-diligence/types/diligence';

const root = (state: IState): { notifications: { [key: string]: IReduxNotification } } =>
  state.socket || { notifications: {} };

export const selectNotifications = (state: IState) => root(state).notifications;

export const sortNotifications = (
  state: IState,
  selector: (state: IState) => { [key: string]: IReduxNotification }
) => Object.values(selector(state)).sort((a, b) => a.timestamp - b.timestamp);

export const selectAllNotifications = (state: IState): IReduxNotification[] =>
  Object.values(selectNotifications(state));

export const selectUnshownNotifications = (state: IState) =>
  selectAllNotifications(state).filter(notification => !notification.shown);

export const selectUnreadNotifications = (state: IState) =>
  selectAllNotifications(state).filter(
    // @ts-ignore
    notification => !notification.read && notification.event !== 'download_success'
  );

export const selectDecoratedNotifications = (
  state: IState,
  notificationsSelector: (state: IState) => QnAEvent[]
) => {
  if (typeof notificationsSelector !== 'function') return [];
  const notifications = notificationsSelector(state);

  const decorated =
    Array.isArray(notifications) &&
    notifications
      .map(notification => {
        const decoratedNotification = {
          ...notification,
          // organization: selectOrganizationByUserId(state, notification.userId),
          // question: selectQuestionById(state, notification.threadId),
        };

        return decoratedNotification;
      })
      .filter(notification => notification);

  return decorated;
};
