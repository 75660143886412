import React, { useMemo } from 'react';
import get from 'lodash/get';

import { STEPS } from './CAConstants';
import { OptionForm } from './OptionForm';
import { CAExternalForm } from './CAExternalForm';
import { CAInternalForm } from './CAInternalForm';

interface CollaborationAgreementsFormParams {
  currentStep: string;
  setOptionSelected: (option: string) => void;
  optionSelected: string;
}

const CollaborationAgreementsForm = ({
  currentStep,
  setOptionSelected,
  optionSelected,
}: CollaborationAgreementsFormParams) => {
  const renderStep = useMemo(() => {
    const isOption = currentStep === STEPS.OPTION_SELECTOR ? 0 : 1;
    const steps = [
      <OptionForm optionSelected={optionSelected} setOptionSelected={setOptionSelected} />,
      ...(currentStep === STEPS.INTERNAL ? [<CAInternalForm />] : [<CAExternalForm />]),
    ];

    return get(steps, `[${isOption}]`, null);
  }, [currentStep, optionSelected]);

  return renderStep;
};

export default CollaborationAgreementsForm;
