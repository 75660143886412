import styled from 'styled-components';
import { transparentize } from 'polished';
import {
  AlertDialogOverlay as ReachAlertDialogOverlay,
  AlertDialogContent as ReachAlertDialogContent,
  AlertDialogLabel as ReachAlertDialogLabel,
  AlertDialogDescription as ReachAlertDialogDescription,
} from '@reach/alert-dialog';

export const AlertDialogOverlay = styled(ReachAlertDialogOverlay)`
  ${({ theme }) => {
    const { colors } = theme.foundations;
    return `
      background: ${transparentize(0.4)(colors.palette.neutral['700'])} !important;
    `;
  }}
`;

export const AlertDialogContent = styled(ReachAlertDialogContent)`
  ${({ theme }) => {
    const { colors, radii, shadows } = theme.foundations;
    return `
      display: flex;
      flex-direction: column;
      background: ${colors.background.primary} !important;
      border-radius: ${radii.lg};
      box-shadow: ${shadows['02']};
      padding: 0px !important;
      max-width: 650px;
    `;
  }}
`;

export const AlertDialogCloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const AlertDialogCloseButton = styled.button`
  ${({ theme }) => {
    const { sizes, colors } = theme.foundations;
    return `
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${sizes['06']};
      height: ${sizes['06']};
      background: ${colors.palette.transparent};
      outline: 0;
      border: 0;
      cursor: pointer;
    `;
  }}
`;

export const AlertDialogBody = styled.div`
  ${({ theme }) => {
    const { colors, spacing } = theme.foundations;
    return `
      flex: 1;
      padding: ${spacing['05']} ${spacing['06']};
      border-bottom: 1px solid ${colors.outline.light};
    `;
  }}
`;

export const AlertDialogFooter = styled.div`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return `
      flex: 0 1 82px;
      padding: ${spacing['05']} ${spacing['06']};
    `;
  }}
`;

export const AlertDialogLabel = styled(ReachAlertDialogLabel)`
  ${({ theme }) => {
    const { colors, typography, spacing } = theme.foundations;
    return `
      & * {
        display: flex;
        align-items: center;
        font-family: ${typography.fonts.body};
        font-size: 22px; // Size not in the design system
        font-weight: ${typography.weights.bold};
        line-height: 125%;
        color: ${colors.text.default};
      }
      margin-bottom: ${spacing['05']};
    `;
  }}
`;

export const AlertDialogDescription = styled(ReachAlertDialogDescription)`
  ${({ theme }) => {
    const { colors, typography, spacing } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['04']};
      font-weight: ${typography.weights.regular};
      line-height: 150%;
      color: ${colors.text.default};
      margin-bottom: ${spacing['02']};
      & .boldText {
        display: inline;
        font-weight: ${typography.weights.medium};
      }
    `;
  }}
`;
