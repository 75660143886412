import React, { CSSProperties } from 'react';

// Icon
import { CloseIcon } from '../../icons';

// Style
import * as S from './PillStyled';

export interface PillProps {
  label: string;
  uppercase?: boolean;
  removeIcon?: boolean;
  className?: string;
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  icon?: React.ReactNode;
  dataTestid?: string;
}
export const Pill = ({
  label,
  uppercase = false,
  removeIcon = false,
  className = 'gray',
  style,
  labelStyle,
  icon = null,
  dataTestid,
}: PillProps) => (
  <S.PillContainer className={className} style={style} data-testId={dataTestid}>
    <S.PillLabel uppercase={uppercase} style={labelStyle}>
      {label}
    </S.PillLabel>
    {removeIcon && (
      <S.IconContainer>
        <CloseIcon width="8" height="8" />
      </S.IconContainer>
    )}
    {icon && <S.IconContainer>{icon}</S.IconContainer>}
  </S.PillContainer>
);
