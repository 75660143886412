import * as React from 'react';
import { IconProps } from './types';

export const ListIcon = ({
  color = 'currentColor',
  width = '10px',
  height = '10px',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 8.75V1.25C0 1.11739 0.0526784 0.990214 0.146447 0.896446C0.240215 0.802678 0.367392 0.75 0.5 0.75H9.5C9.63261 0.75 9.75979 0.802678 9.85355 0.896446C9.94732 0.990214 10 1.11739 10 1.25V8.75C10 8.88261 9.94732 9.00979 9.85355 9.10355C9.75979 9.19732 9.63261 9.25 9.5 9.25H3.5H2.5H0.5C0.367392 9.25 0.240215 9.19732 0.146447 9.10355C0.0526784 9.00979 0 8.88261 0 8.75ZM3.5 8.25H9V6.75H3.5V8.25ZM2.5 6.75V8.25H1V6.75H2.5ZM3.5 5.75H9V4.25H3.5V5.75ZM2.5 4.25V5.75H1V4.25H2.5ZM3.5 3.25H9V1.75H3.5V3.25ZM2.5 1.75V3.25H1V1.75H2.5Z"
      fill={color}
    />
  </svg>
);
