export const COUNTRY_OPTIONS = [
  {
    value: 'USA',
    label: 'USA',
  },
  {
    value: 'Australia',
    label: 'Australia',
  },
  {
    value: 'Brazil',
    label: 'Brazil',
  },
  {
    value: 'Canada',
    label: 'Canada',
  },
  {
    value: 'Chile',
    label: 'Chile',
  },
  {
    value: 'China',
    label: 'China',
  },
  {
    value: 'France',
    label: 'France',
  },
  {
    value: 'Hong Kong',
    label: 'Hong Kong',
  },
  {
    value: 'India',
    label: 'India',
  },
  {
    value: 'Israel',
    label: 'Israel',
  },
  {
    value: 'Italy',
    label: 'Italy',
  },
  {
    value: 'Japan',
    label: 'Japan',
  },
  {
    value: 'Mexico',
    label: 'Mexico',
  },
  {
    value: 'Portugal',
    label: 'Portugal',
  },
  {
    value: 'Singapore',
    label: 'Singapore',
  },
  {
    value: 'Spain',
    label: 'Spain',
  },
  {
    value: 'South Korea',
    label: 'South Korea',
  },
  {
    value: 'Sweden',
    label: 'Sweden',
  },
  {
    value: 'Switzerland',
    label: 'Switzerland',
  },
  {
    value: 'United Kingdom',
    label: 'United Kingdom',
  },
];

const STATE_OPTIONS_USA = [
  {
    value: 'Alabama',
    label: 'Alabama',
  },
  {
    value: 'Alaska',
    label: 'Alaska',
  },
  {
    value: 'Arizona',
    label: 'Arizona',
  },
  {
    value: 'Arkansas',
    label: 'Arkansas',
  },
  {
    value: 'California',
    label: 'California',
  },
  {
    value: 'Colorado',
    label: 'Colorado',
  },
  {
    value: 'Connecticut',
    label: 'Connecticut',
  },
  {
    value: 'D.C.',
    label: 'D.C.',
  },
  {
    value: 'Delaware',
    label: 'Delaware',
  },
  {
    value: 'Florida',
    label: 'Florida',
  },
  {
    value: 'Georgia',
    label: 'Georgia',
  },
  {
    value: 'Hawaii',
    label: 'Hawaii',
  },
  {
    value: 'Idaho',
    label: 'Idaho',
  },
  {
    value: 'Illinois',
    label: 'Illinois',
  },
  {
    value: 'Indiana',
    label: 'Indiana',
  },
  {
    value: 'Iowa',
    label: 'Iowa',
  },
  {
    value: 'Kansas',
    label: 'Kansas',
  },
  {
    value: 'Kentucky',
    label: 'Kentucky',
  },
  {
    value: 'Louisiana',
    label: 'Louisiana',
  },
  {
    value: 'Maine',
    label: 'Maine',
  },
  {
    value: 'Maryland',
    label: 'Maryland',
  },
  {
    value: 'Massachusetts',
    label: 'Massachusetts',
  },
  {
    value: 'Michigan',
    label: 'Michigan',
  },
  {
    value: 'Minnesota',
    label: 'Minnesota',
  },
  {
    value: 'Mississippi',
    label: 'Mississippi',
  },
  {
    value: 'Missouri',
    label: 'Missouri',
  },
  {
    value: 'Montana',
    label: 'Montana',
  },
  {
    value: 'Nebraska',
    label: 'Nebraska',
  },
  {
    value: 'Nevada',
    label: 'Nevada',
  },
  {
    value: 'New Hampshire',
    label: 'New Hampshire',
  },
  {
    value: 'New Jersey',
    label: 'New Jersey',
  },
  {
    value: 'New Mexico',
    label: 'New Mexico',
  },
  {
    value: 'New York',
    label: 'New York',
  },
  {
    value: 'North Carolina',
    label: 'North Carolina',
  },
  {
    value: 'North Dakota',
    label: 'North Dakota',
  },
  {
    value: 'Ohio',
    label: 'Ohio',
  },
  {
    value: 'Oklahoma',
    label: 'Oklahoma',
  },
  {
    value: 'Oregon',
    label: 'Oregon',
  },
  {
    value: 'Pennsylvania',
    label: 'Pennsylvania',
  },
  {
    value: 'Rhode Island',
    label: 'Rhode Island',
  },
  {
    value: 'South Carolina',
    label: 'South Carolina',
  },
  {
    value: 'South Dakota',
    label: 'South Dakota',
  },
  {
    value: 'Tennessee',
    label: 'Tennessee',
  },
  {
    value: 'Texas',
    label: 'Texas',
  },
  {
    value: 'Utah',
    label: 'Utah',
  },
  {
    value: 'Vermont',
    label: 'Vermont',
  },
  {
    value: 'Virginia',
    label: 'Virginia',
  },
  {
    value: 'Washington',
    label: 'Washington',
  },
  {
    value: 'West Virginia',
    label: 'West Virginia',
  },
  {
    value: 'Wisconsin',
    label: 'Wisconsin',
  },
  {
    value: 'Wyoming',
    label: 'Wyoming',
  },
];

export const getCountrySubOptions = (country: any) => {
  switch (country) {
    case 'USA':
      return STATE_OPTIONS_USA;
    default:
      return [{ value: '', label: '' }];
  }
};
