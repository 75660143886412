import styled from 'styled-components';
import { Button } from '@dealsyte/poki';

const MainContainer = styled.header`
  ${({ theme }) => {
    const { colors } = theme.foundations;
    return `
    display: flex;
    align-items: center;
    position: relative;
    min-height: 48px;
    border-bottom: 1px solid ${colors.palette.neutral['300']};
    z-index: 1;
    background-color: ${colors.palette.neutral['100']};
  
    @media (max-width: 600px) {
      margin-left: 0;
    }
    `;
  }}
`;

const HeaderSessionToolsContainer = styled.div`
  padding-right: 15px;
  display: flex;
  flex-direction: row-reverse;
  flex: 1;
  flex-basis: 200;
`;

const NavButton = styled(Button)`
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const ToggleSidebarContainer = styled.div`
  display: flex;
  flex: 1;
  flex-basis: 200px;
  align-items: center;
  padding-left: 10px;

  & > ${NavButton} {
    display: none;
  }

  @media (max-width: 600px) {
    & > ${NavButton} {
      display: flex;
    }
  }
`;

export { MainContainer, HeaderSessionToolsContainer, ToggleSidebarContainer, NavButton };
