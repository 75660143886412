import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';

// Hook
import { useForm } from 'app/utils/hooks/useForm';

// Validations
import { validationSchema } from './KYCCounterpartyValidations';

// Context
import { UserContext } from '../../../../../../users/context/UserContext';

// Constants
import { CAPITAL_RAISE_DEALS } from '../KYCFormConstants';
import { CREATE_FORM } from '../../../../../../../api/graphql/compliance/mutations';
import { buildKYCCounterpartytForm } from './KYCCounterpartyHelper';
import { DealsContext } from '../../../../../context/DealsContext';
import { AlertContext } from '../../../../../../notifications/context/Alert/Alert';

export type selectValue = {
  value: string;
  label: string;
};

export type KYCCounterpartyForm = {
  typesCounterparty: boolean | null; // true: Entity/Institution, false: Individual
  entityName: string;
  connection: selectValue | undefined;
  yearsKnowledge: string;
  relationshipNature: string;
  connectionAmplify: string;
  fullName: string;
  emailAddress: string;
  title: string;
};

const initialValues = {
  typesCounterparty: null,
  entityName: '',
  connection: undefined,
  yearsKnowledge: '',
  relationshipNature: '',
  connectionAmplify: '',
  fullName: '',
  emailAddress: '',
  title: '',
};

export const useKYCCounterpartyHandler = () => {
  const { activeDeal } = useContext(UserContext);
  const { selectedDeal: dealId } = useContext<any>(DealsContext);
  const isCapitalRaiseDeal = CAPITAL_RAISE_DEALS.includes(get(activeDeal, 'type', ''));
  const { showErrorToast } = useContext(AlertContext);

  const validation = isCapitalRaiseDeal
    ? validationSchema.capitalRaiseSchema
    : validationSchema.noCapitalRaiseSchema;
  const form = useForm<KYCCounterpartyForm>({
    initialValues,
    validationSchema: validation,
  });

  const onSuccess = () => {
    form.resetForm();
  };

  const [createFormMutation, { loading: creatingForm }] = useMutation(CREATE_FORM, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      onSuccess();
    },
    onError() {
      showErrorToast({ title: 'Error', description: 'An error occurred while saving the form' });
    },
  });

  const onSubmitKYCCounterpartyForm = async (): Promise<void> => {
    const { values } = form;
    await createFormMutation({
      variables: buildKYCCounterpartytForm(values, dealId, isCapitalRaiseDeal),
    });
  };

  return {
    form,
    isCapitalRaiseDeal,
    onSubmitKYCCounterpartyForm,
    creatingForm,
  };
};
