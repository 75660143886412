import React, { useContext } from 'react';

// Components
import { Button, HStack } from '../../../../common/components';

// Icons
import { ArrowLeftIcon, ArrowRightIcon } from '../../../../common/icons';

// Style
import * as S from './NewDealFormStyled';
import { ButtonNames } from './NewDealFormTexts';

// Context
import { NewDealHandlerContext } from './NewDealFormHandler';

const NewDealFormFooter = ({ onClose }: { onClose: () => void }) => {
  const { form, onSubmitNewDealForm, submitting } = useContext(NewDealHandlerContext);

  const isFirstStep = form.currentStep === 1;
  const onConfirm = form.isLastStep ? onSubmitNewDealForm : form.nextStep;

  return (
    <S.ButtonContainer>
      <div>
        {!isFirstStep && (
          <Button className="secondary" onClick={form.prevStep}>
            <ArrowLeftIcon style={{ marginRight: '12px' }} />
            {ButtonNames.PREVIOUS}
          </Button>
        )}
      </div>
      <HStack spacing={16} style={{ width: 'auto' }}>
        <Button className="cancel" onClick={onClose}>
          {ButtonNames.CANCEL}
        </Button>
        <Button
          onClick={onConfirm}
          disabled={submitting || !form.isFormValidOnStep(form.currentStep)}
        >
          {form.isLastStep ? ButtonNames.SUBMIT : ButtonNames.NEXT}
          {!form.isLastStep && <ArrowRightIcon style={{ marginLeft: '12px' }} />}
        </Button>
      </HStack>
    </S.ButtonContainer>
  );
};

export default NewDealFormFooter;
