import * as React from 'react';

import { IconProps } from './types';

export const MarkedStar = ({
  width = '14px',
  height = '13px',
  color = '#47455F',
}: IconProps): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 14 13" fill="none">
    <path
      d="M11.7817 4.32801C11.8732 4.39927 11.942 4.49554 11.9799 4.60513C12.009 4.71471 12.0065 4.83031 11.9725 4.93849C11.9386 5.04668 11.8746 5.14301 11.7881 5.2163L9.3135 7.61304L9.91269 11.0164C9.93408 11.1287 9.92288 11.2449 9.88041 11.351C9.83794 11.4572 9.76597 11.549 9.67301 11.6156C9.5702 11.69 9.44642 11.7299 9.31949 11.7294C9.22294 11.7298 9.12796 11.705 9.04387 11.6575L5.98803 10.0517L2.9202 11.6515C2.8218 11.7037 2.7108 11.7274 2.59967 11.72C2.48854 11.7126 2.38167 11.6744 2.29106 11.6096C2.1981 11.543 2.12613 11.4512 2.08366 11.345C2.0412 11.2389 2.02999 11.1228 2.05139 11.0104L2.65057 7.60705L0.175938 5.21031C0.0989178 5.13345 0.0442806 5.03707 0.0178902 4.93151C-0.00850021 4.82594 -0.00564746 4.71519 0.0261423 4.61113C0.060871 4.50464 0.124754 4.41001 0.210541 4.33799C0.296329 4.26597 0.400586 4.21944 0.511482 4.20368L3.92084 3.70037L5.44876 0.608575C5.49783 0.50727 5.57443 0.421834 5.66981 0.362054C5.76518 0.302275 5.87547 0.270569 5.98803 0.270569C6.10059 0.270569 6.21087 0.302275 6.30625 0.362054C6.40162 0.421834 6.47823 0.50727 6.52729 0.608575L8.05521 3.70636L11.4646 4.20368C11.5801 4.21357 11.6903 4.25676 11.7817 4.32801Z"
      fill={color}
    />
  </svg>
);
