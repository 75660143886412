// vendor
import React, { useContext } from 'react';
import _get from 'lodash/get';

// Constants
import { EFeatureFlag } from 'app/FeatureFlag';

// Hook
import { useFeatureFlags } from 'app/utils/hooks/hooks';

// Context
import { UserContext } from 'app/users/context/UserContext';
import { NewDealHandlerContext } from './NewDealFormHandler';

// Components
import NDPrimaryInfo from './steps/NDPrimaryInfo';
import NDMarketplaceInfo from './steps/NDMarketplaceInfo';
import NDAdditionalInfo from './steps/NDAdditionalInfo';
import NDEngagementLetterInfo from './steps/NDEngagementLetterInfo';

const NewDealForm = () => {
  const { form } = useContext(NewDealHandlerContext);
  const { marketplaceAccessEnabled } = useContext(UserContext);
  const { currentStep } = form;
  const FFEnable = useFeatureFlags(EFeatureFlag.CAAS_NEW_DEAL_EL_STEP); // REMOVE TO ENABLE IN DEPLOY

  const renderStep = (step: number) => {
    const steps = [
      <NDPrimaryInfo />,
      ...(marketplaceAccessEnabled ? [<NDMarketplaceInfo />] : []),
      <NDAdditionalInfo />,
      ...(FFEnable ? [<NDEngagementLetterInfo />] : []),
    ];

    return _get(steps, `[${step - 1}]`, null);
  };

  return renderStep(currentStep);
};

export default NewDealForm;
