import * as React from 'react';
import { IconProps } from './types';

export const ChatIcon = ({
  color = 'currentColor',
  width = '20px',
  height = '20px',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.44518 8.83874C7.44518 9.76777 6.05164 9.76777 6.05164 8.83874C6.05164 7.90971 7.44518 7.90971 7.44518 8.83874Z"
      fill={color}
    />
    <path
      d="M13.9485 8.83874C13.9485 9.76777 12.5549 9.76777 12.5549 8.83874C12.5549 7.90971 13.9485 7.90971 13.9485 8.83874Z"
      fill={color}
    />
    <path
      d="M10.6969 8.83874C10.6969 9.76777 9.30334 9.76777 9.30334 8.83874C9.30334 7.90971 10.6969 7.90971 10.6969 8.83874Z"
      fill={color}
    />
    <path
      d="M14.6452 2.79999H5.35489C3.94411 2.79999 2.80005 3.94404 2.80005 5.35483V12.0903C2.80005 13.5011 3.94411 14.6451 5.35489 14.6451H7.03151L9.12183 16.8171C9.35318 17.0576 9.67073 17.1955 10.0046 17.1982C10.3376 17.2018 10.6587 17.0712 10.8946 16.8353L13.0748 14.6451H14.6452C16.056 14.6451 17.2 13.5011 17.2 12.0903V5.35483C17.2 3.94404 16.056 2.79999 14.6452 2.79999ZM15.8065 12.0903C15.8065 12.3979 15.684 12.6936 15.4663 12.9114C15.2486 13.1291 14.9528 13.2516 14.6452 13.2516H12.4995L9.99998 15.7393L7.62653 13.2516H5.35477C4.71334 13.2516 4.19348 12.7317 4.19348 12.0903V5.35483C4.19348 4.7134 4.71334 4.19354 5.35477 4.19354H14.6451C14.9526 4.19354 15.2484 4.31601 15.4662 4.53375C15.6839 4.75148 15.8064 5.04725 15.8064 5.35483L15.8065 12.0903Z"
      fill={color}
    />
  </svg>
);
