// Libraries
import React from 'react';
import PropTypes from 'prop-types';

const LensIcon = props => (
  <svg width={props.width} height={props.height} fill="none">
    <path
      d="M20.7111 19.2904L17.0011 15.6104C18.4412 13.8148 19.1386 11.5357 18.9499 9.24169C18.7612 6.94769 17.7008 4.81318 15.9866 3.27704C14.2725 1.7409 12.0349 0.919903 9.73402 0.982863C7.43313 1.04582 5.24382 1.98795 3.61624 3.61553C1.98866 5.24311 1.04653 7.43243 0.983565 9.73332C0.920605 12.0342 1.7416 14.2718 3.27774 15.9859C4.81388 17.7001 6.9484 18.7605 9.24239 18.9492C11.5364 19.1379 13.8155 18.4405 15.6111 17.0004L19.2911 20.6804C19.3841 20.7741 19.4947 20.8485 19.6165 20.8993C19.7384 20.9501 19.8691 20.9762 20.0011 20.9762C20.1331 20.9762 20.2638 20.9501 20.3857 20.8993C20.5075 20.8485 20.6181 20.7741 20.7111 20.6804C20.8913 20.4939 20.9921 20.2447 20.9921 19.9854C20.9921 19.7261 20.8913 19.4769 20.7111 19.2904V19.2904ZM10.0011 17.0004C8.61663 17.0004 7.26325 16.5899 6.1121 15.8207C4.96096 15.0515 4.06375 13.9583 3.53394 12.6792C3.00412 11.4001 2.8655 9.99263 3.1356 8.63476C3.40569 7.27689 4.07238 6.02961 5.05135 5.05065C6.03031 4.07168 7.2776 3.40499 8.63546 3.1349C9.99333 2.8648 11.4008 3.00342 12.6799 3.53324C13.959 4.06305 15.0522 4.96026 15.8214 6.1114C16.5906 7.26255 17.0011 8.61592 17.0011 10.0004C17.0011 11.8569 16.2636 13.6374 14.9508 14.9501C13.6381 16.2629 11.8576 17.0004 10.0011 17.0004V17.0004Z"
      fill={props.color}
    />
  </svg>
);

LensIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

LensIcon.defaultProps = {
  width: '21px',
  height: '21px',
  color: '#807DA1',
};

export default LensIcon;
