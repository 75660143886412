// libraries
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { DayValue } from 'react-modern-calendar-datepicker';
import dayjs, { Dayjs } from 'dayjs';
import { MarketplaceDealType, MMTypeCard } from '../types/index';

// types
import {
  EDealStatus,
  DealFilterEnum,
  IOptionPayload,
  IOption,
  IMarketplaceDeal,
  mmFilterResult,
  MarketplaceType,
} from '../types/index';
import { DEAL_TYPES, DEAL_TYPES_LABELS, getDealTypeLabel } from 'app/utils/helpers/deal';

// helpers

export const getPublishedDaysAgo = (publishedAt: string): string => {
  const dayDiff = dayjs()
    .startOf('day')
    .diff((publishedAt as unknown) as Dayjs, 'day');

  if (dayDiff === 0) return 'Today';

  return `${dayDiff.toString()} days ago`;
};

export const getDate = (dateTimezone: string, format = 'MMM D, YYYY'): string => {
  if (_isEmpty(dateTimezone)) return '-';
  const [date] = dateTimezone.toLowerCase().split('t');
  return dayjs(date).format(format);
};

export const getDealStatusName = (status: EDealStatus): string => {
  switch (status) {
    case EDealStatus.INITIAL_ENGAGEMENT:
      return 'Initial Engagement';
    case EDealStatus.ENGAGEMENT_LETTER:
      return 'Engagement Letter';
    case EDealStatus.PRE_MARKETING:
      return 'Pre-Marketing';
    case EDealStatus.DUE_DILIGENCE_CLOSING:
      return 'DD / Closing';
    case EDealStatus.ON_HOLD:
      return 'On-hold';
    case EDealStatus.CLOSED:
      return 'Closed';
    case EDealStatus.EXPIRED:
      return 'Expired/Terminated';
    default:
      return '';
  }
};

/**
 * Extracts error message from apollo error object
 * @param error
 */
export const getError = (error: any, fallback: string | undefined) =>
  _get(error, 'graphQLErrors[0].message', fallback);

export const getDealTypeOption = (key: string) => {
  return getDealTypeLabel(key);
};

export const buildDealOptions = (marketPlaceDealsFilters: any[], DealFilter: DealFilterEnum) => {
  const options: any[] = [];
  const dealTypeOptions = marketPlaceDealsFilters.find(
    (marketplaceDealfilter: any) => marketplaceDealfilter.filterName === DealFilter
  );
  if (dealTypeOptions) {
    dealTypeOptions.values.forEach((value: string) => {
      options.push({
        value,
        label: DealFilter === 'TYPE' ? getDealTypeOption(value) : value,
      });
    });
  }
  return options;
};

export const buildStringOptions = (marketPlaceDealsFilters: any[], DealFilter: DealFilterEnum) => {
  const options: any[] = [];
  const dealTypeOptions = marketPlaceDealsFilters.find(
    (marketplaceDealfilter: any) => marketplaceDealfilter.filterName === DealFilter
  );
  if (dealTypeOptions) {
    dealTypeOptions.values.forEach((value: string) => {
      options.push({
        value,
        label: `${value}`,
      });
    });
  }
  return options;
};

export const getFilters = () => [
  DealFilterEnum.TYPE,
  DealFilterEnum.PRIMARY_INDUSTRY,
  DealFilterEnum.LOCATION,
  DealFilterEnum.EXPECTED_SIZE,
  DealFilterEnum.STATUS,
];

type filtersOption = {
  filterName: string;
  filterType: string;
  values: any[];
};

export const buildFilters = (
  dealType: IOption | null,
  primaryIndustry: IOption | null,
  dealSize: IOption | null,
  location: IOption | null,
  stage: IOption | null,
  date: DayValue | null,
  isRetainer: boolean,
  isInterestedShowed: boolean
) => {
  const filters: IOptionPayload[] = [];
  if (dealType) {
    filters.push({
      filterName: DealFilterEnum.TYPE,
      values: [dealType.value],
    });
  }
  if (primaryIndustry) {
    filters.push({
      filterName: DealFilterEnum.PRIMARY_INDUSTRY,
      values: [primaryIndustry.value],
    });
  }

  if (dealSize) {
    filters.push({
      filterName: DealFilterEnum.EXPECTED_SIZE,
      values: [dealSize.value],
    });
  }

  if (location) {
    filters.push({
      filterName: DealFilterEnum.LOCATION,
      values: [location.value],
    });
  }

  if (stage) {
    filters.push({
      filterName: DealFilterEnum.STATUS,
      values: [stage.value],
    });
  }

  if (date) {
    filters.push({
      filterName: DealFilterEnum.PUBLISHED_AT,
      filterType: 'greaterOrEqualThan',
      value: `${date.year}-${date.month
        .toString()
        .toString()
        .padStart(2, '0')}-${date.day.toString().padStart(2, '0')}`,
    });
  }

  if (isRetainer) {
    filters.push({
      filterName: DealFilterEnum.IS_RETAINER,
      filterType: 'notEqual',
      value: 'null',
    });
  }

  if (isInterestedShowed) {
    filters.push({
      filterName: DealFilterEnum.SHOWN_INTEREST,
      filterType: 'equal',
      value: isInterestedShowed.toString(),
    });
  }

  return filters;
};

export const getDealStatusOption = (key: string) => {
  if (key === 'PRE_MARKETING') return 'Pre-Marketing';
  if (key === 'INITIAL_ENGAGEMENT') return 'Initial Engagement';
  if (key === 'ENGAGEMENT_LETTER') return 'Engagement Letter';
  if (key === 'DUE_DILIGENCE_CLOSING') return 'DD / Closing';
  if (key === 'ON_HOLD') return 'On-hold';
  if (key === 'CLOSED') return 'Closed';
  if (key === 'EXPIRED') return 'Expired/Terminated';
  return '';
};

export const buildDealStatusOptions = (marketPlaceDealsFilters: any[]) => {
  const options: any[] = [];
  const dealTypeOptions = marketPlaceDealsFilters.find(
    (marketplaceDealfilter: any) => marketplaceDealfilter.filterName === DealFilterEnum.STATUS
  );
  if (dealTypeOptions) {
    dealTypeOptions.values.forEach((value: string) => {
      options.push({
        value,
        label: getDealStatusOption(value),
      });
    });
  }
  return options;
};

// TODO: Move to a shared folder because we are using in MM & CAAS
export const dealType = (type: string): string => {
  return _get(DEAL_TYPES_LABELS, type, DEAL_TYPES.OTHER);
};

const SI_SYMBOL = ['', 'k', 'M', 'Bn', 'T', 'P', 'E'];
/**
 * Return money amount formatted
 * @param amount number
 * @returns number value formatted
 */
export const kFormatter = (number: number) => {
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier === 0) return number;

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = number / scale;

  // format number and add suffix
  // this condition avoid shows numbers like 1.000B
  if (scaled >= 999.95) {
    return 1 + SI_SYMBOL[tier + 1];
  }
  return scaled.toFixed(1) + suffix;
};

/**
 * Return the amount of days published in the market
 * @param publishedDate string
 * @returns number value
 */
export const buildDaysInTheMarket = (engagementLetterSignedDate: string | null) => {
  if (!engagementLetterSignedDate) {
    return '-';
  }
  const dateELSD = dayjs(engagementLetterSignedDate);
  return dayjs()
    .diff(dateELSD, 'day')
    .toFixed(0);
};

export const buildLocation = (country: string, state?: string) => {
  if (!state) {
    return `${country}`;
  }
  return `${state}, ${country}`;
};

/**
 * Return the amount of the bigger deal size of deals
 * @param marketplaceDealFiltersCount array of filters
 * @returns max deal size
 */
export const calculateNewMaxExpectedDealSize = (marketplaceDealFiltersCount: mmFilterResult[]) => {
  const accumZero = 0;
  const expectedSizeMaxValueBe = marketplaceDealFiltersCount
    .filter((mFilter: mmFilterResult) => mFilter.filterName === DealFilterEnum.EXPECTED_SIZE)
    .reduce(
      (accum: number, mFilter) =>
        accum +
        (mFilter.values || []).reduce(
          (valuesAccum: number, currVal: any) => valuesAccum + parseInt(currVal.filterElement, 10),
          accumZero
        ),
      accumZero
    );
  return expectedSizeMaxValueBe;
};

export const getDealType = (userId: string, deal: IMarketplaceDeal) => {
  const ownDeal = userId === _get(deal, 'ownerId', '');
  if (_get(deal, 'marketplaceDealType') === MarketplaceDealType.Private) {
    return MMTypeCard.MM_PRIVATE_DEALS;
  }
  if (ownDeal) {
    return MMTypeCard.MM_MY_DEALS;
  }
  if (deal.collaborateInDeal) {
    return MMTypeCard.MM_COLLABORATE_DEALS;
  }
  return MMTypeCard.MM_ACTIVE_DEAL;
};

export const showNewTag = (publishedDate: string) => {
  const datePD = dayjs(publishedDate);
  return (
    Number(
      dayjs()
        .diff(datePD, 'day')
        .toFixed(0)
    ) <= 2
  );
};

export const checkIdType = (marketplaceDealId: string) => {
  const idArray = marketplaceDealId.split('');
  if (marketplaceDealId === 'conversations') {
    return marketplaceDealId;
  }
  if (/[0-9]/.test(idArray[3])) {
    return MarketplaceType.DEAL;
  }
  return MarketplaceType.FUND; // In a future if we have more deal types, we will check here the first letter
};

export function countEachFiltersApplied(filtersApplied: any) {
  const count: any = {};
  count.total = 0;
  if (filtersApplied) {
    filtersApplied.forEach((element: { filterName: DealFilterEnum; values: string | any[] }) => {
      if (element.filterName === DealFilterEnum.TYPE) {
        count.type = element.values.length;
      }
      if (element.filterName === DealFilterEnum.PRIMARY_INDUSTRY) {
        count.industryP = element.values.length;
      }
      if (element.filterName === DealFilterEnum.SECONDARY_INDUSTRIES) {
        count.industryS = element.values.length;
      }
      if (element.filterName === DealFilterEnum.STATUS) {
        count.stage = element.values.length;
      }
      if (
        element.filterName !== DealFilterEnum.OWNER_ID &&
        element.filterName !== DealFilterEnum.EXPECTED_SIZE &&
        element.filterName !== DealFilterEnum.RETAINER_AMOUNT &&
        element.filterName !== DealFilterEnum.CALCULATED_PERCENT_RATE &&
        element.filterName !== DealFilterEnum.SHOWN_INTEREST
      ) {
        count.total += element.values.length;
      } else {
        count.total += 1;
      }
    });
    if (count.industryS > 0) {
      count.total -= count.industryP;
      count.industryP = 0;
    }
  }
  return count;
}

export function NameInitials(value: string) {
  let initials: string = '';
  const splitString = value.split(' ');
  splitString.forEach(element => {
    if (initials.length < 2) initials = initials.concat(element.charAt(0));
  });
  return initials.toUpperCase();
}
