import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 56px 0;
  overflow-y: scroll;
  height: 550px;
`;

export const ContentContainer = styled.div`
  ${({ theme }) => {
    const { colors, spacing, typography, radii } = theme.foundations;
    return `
      padding: 0px 70px;
      line-height: 1.3;
      text-align: left;
      font-family: ${typography.fonts.body};

      .title {
        margin: 20px 0 15px 0;
        font-weight: ${typography.weights.bold};
        font-size: ${typography.sizes['05']};
        // TODO: Move the new color to the design system
        color: ${colors.palette.neutral['600']};
        line-height: 24px;
      }
      
      .paragraph {
        font-weight: ${typography.weights.regular};
        font-size: ${typography.sizes['04']};
        color: ${colors.palette.neutral['600']};
        display: inline-block;
      }

      .join-marketplace {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: ${spacing['04']};
        background-color: ${colors.palette.orange['100']};
        color: ${colors.palette.orange['900']};
        border-radius: ${radii.lg};
        line-height: 24px;

        .external-icon-container {
          svg {
            margin-left: ${spacing['01']};
          }
        }

        a {
          color: ${colors.palette.orange['900']};
        }

        svg {
          margin-right: ${spacing['04']};
          margin-bottom: ${spacing['01']};
        }
      }

      .subtitle {
        margin: 20px 0 15px 0;
        font-weight: ${typography.weights.bold};
        font-size: ${typography.sizes['03']};
        // TODO: Move the new color to the design system
        color: ${colors.palette.neutral['600']};
        line-height: 24px;
      }

      label {
        font-size: ${typography.sizes['01']};
        color: #707f8f;
        font-weight: ${typography.weights.bold};
      }

      .input {
        width: 450px;
        // TODO: Move the new color to the design system
        border: 1px solid #b4bde4;
        border-radius: 4px;
        // TODO: Move the new color to the design system
        font-size: 12px;
        background-color: #ffffff;
        height: 40px;
      }

      .textArea {
        // TODO: Move the new color to the design system
        border: 1px solid #b4bde4;
        width: 450px;
        // TODO: Move the new color to the design system
        background-color: #ffffff;
        font-size: 12px;
        color: #6b79b3;
        min-height: 102px;
      }
      .datePickerContainer {
        position: relative;
      }
      .datePickerContainerOne {
        z-index: 11;
      }
      .datePickerContainerTwo {
        z-index: 10;
      }
      .datePickerContainerThree {
        z-index: 9;
      }
      .datePickerFooter {
        display: flex;
        justify-content: center;
        padding: 16px 16px;
      }
    `;
  }}
`;
