import * as React from 'react';
import { IconProps } from './types';

export const ExclamationCircleFilledIcon = ({
  width = '14',
  height = '14',
  color = 'currentColor',
}: IconProps): JSX.Element => (
  <svg width={width} height={height} fill={color} viewBox="0 0 16 16">
    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
  </svg>
);
