import React from 'react';
import { EFormStatus } from 'app/compliance/types/formsTypes';
import {
  DoubleArrowIcon,
  FileIcon,
  OpenFolderIcon,
  ShareIcon,
  SquaredItemsIcon,
  UserCheckIcon,
} from '../../../../common/icons';
import {
  FORMS_SUBTITLES,
  FORMS_TITLES,
} from '../DealRegulationScreen/DealRegulationScreenConstants';
import { MADealWorkflowConfig } from './DealRegulationWorkflowTypes';

export const DEAL_REGULATION_WORKFLOW_CONFIG: MADealWorkflowConfig[] = [
  {
    identifier: ['EL', 'AMN_EL'],
    metadata: {
      drawerProps: {
        key: 'engagementLetterForm',
        props: {},
      },
      componentProps: {
        icon: <FileIcon color="#47455F" width={11} height={13} />,
        title: FORMS_TITLES.CLIENT_ENGAGEMENT,
        subtitle: FORMS_SUBTITLES.CLIENT_ENGAGEMENT,
        submissions: 0,
        stepStatus: undefined,
        footerText: '',
        completeAction: () => {},
        viewDetailsAction: () => {},
      },
    },
    customRules: {
      canCreate: [
        {
          rule: 'AlwaysReady',
        },
      ],
    },
    rules: {
      COMPLETED: [
        {
          rule: 'FormSubmitted',
        },
      ],
      READY: [
        {
          rule: 'AlwaysReady',
        },
      ],
      BLOCKED: [],
    },
  },
  {
    identifier: ['CA', 'FSA_INT', 'FSA_EXT'],
    metadata: {
      drawerProps: {
        key: 'collaborationAgreementsForm',
        props: {},
      },
      componentProps: {
        icon: <DoubleArrowIcon color="#47455F" width={14} height={10} />,
        title: FORMS_TITLES.COLLABORATION_AGREEMENTS,
        subtitle: FORMS_SUBTITLES.COLLABORATION_AGREEMENTS,
        submissions: 0,
        stepStatus: undefined,
        footerText: '',
        completeAction: () => {},
        viewDetailsAction: () => {},
      },
    },
    customRules: {
      canCreate: [
        {
          rule: 'AlwaysReady',
        },
      ],
    },
    rules: {
      COMPLETED: [
        {
          rule: 'FormSubmitted',
        },
      ],
      READY: [
        {
          rule: 'AlwaysReady',
        },
      ],
      BLOCKED: [],
    },
  },
  {
    identifier: 'MMAT',
    metadata: {
      drawerProps: {
        key: 'MarketingMaterialsFormDrawer',
        props: {
          isRegulatory: false,
        },
      },
      componentProps: {
        icon: <SquaredItemsIcon color="#47455F" width={13} height={13} />,
        title: FORMS_TITLES.MARKETING_MATERIALS,
        subtitle: FORMS_SUBTITLES.MARKETING_MATERIALS,
        submissions: 0,
        stepStatus: undefined,
        footerText: 'Approval of the client engagement is required.',
        completeAction: () => {},
        viewDetailsAction: () => {},
      },
    },
    customRules: {
      canCreate: [
        {
          rule: 'FormWithStatus',
          options: {
            identifier: 'EL',
            statuses: [EFormStatus.APPROVED, EFormStatus.APPROVED_WITH_CONDITIONS],
          },
        },
      ],
    },
    rules: {
      COMPLETED: [
        {
          rule: 'FormSubmitted',
        },
      ],
      READY: [
        {
          rule: 'FormWithStatus',
          options: {
            identifier: 'EL',
            statuses: [EFormStatus.APPROVED, EFormStatus.APPROVED_WITH_CONDITIONS],
          },
        },
      ],
      BLOCKED: [],
    },
  },
  {
    identifier: 'DRA',
    metadata: {
      drawerProps: {
        key: 'dataRoomForm',
        props: {},
      },
      componentProps: {
        icon: <ShareIcon color="#47455F" width={13} height={13} />,
        title: FORMS_TITLES.DATA_ROOM_SET_UP,
        subtitle: FORMS_SUBTITLES.DATA_ROOM_SET_UP,
        submissions: 0,
        stepStatus: undefined,
        footerText: 'Approval of the client engagement is required.',
        completeAction: () => {},
        viewDetailsAction: () => {},
      },
    },
    customRules: {
      canCreate: [
        {
          rule: 'FormWithStatus',
          options: {
            identifier: 'EL',
            statuses: [EFormStatus.APPROVED, EFormStatus.APPROVED_WITH_CONDITIONS],
          },
        },
      ],
    },
    rules: {
      COMPLETED: [
        {
          rule: 'FormSubmitted',
        },
      ],
      READY: [
        {
          rule: 'FormWithStatus',
          options: {
            identifier: 'EL',
            statuses: [EFormStatus.APPROVED, EFormStatus.APPROVED_WITH_CONDITIONS],
          },
        },
      ],
      BLOCKED: [],
    },
  },
  {
    identifier: ['KYC', 'KYC_CL', 'KYC_CO'],
    metadata: {
      drawerProps: {
        key: 'DRKYCForm',
        props: {},
      },
      componentProps: {
        icon: <UserCheckIcon color="#47455F" width={13} height={11} />,
        title: FORMS_TITLES.KYC_AML,
        subtitle: FORMS_SUBTITLES.KYC_AML,
        submissions: 0,
        stepStatus: undefined,
        footerText: 'Approval of the client engagement is required.',
        completeAction: () => {},
        viewDetailsAction: () => {},
      },
    },
    customRules: {
      canCreate: [
        {
          rule: 'FormWithStatus',
          options: {
            identifier: 'EL',
            statuses: [EFormStatus.APPROVED, EFormStatus.APPROVED_WITH_CONDITIONS],
          },
        },
      ],
    },
    rules: {
      COMPLETED: [
        {
          rule: 'FormSubmitted',
        },
      ],
      READY: [
        {
          rule: 'FormWithStatus',
          options: {
            identifier: 'EL',
            statuses: [EFormStatus.APPROVED, EFormStatus.APPROVED_WITH_CONDITIONS],
          },
        },
      ],
      BLOCKED: [],
    },
  },
  {
    identifier: 'CDOC',
    metadata: {
      drawerProps: {
        key: 'closingDocumentsForm',
        props: {},
      },
      componentProps: {
        icon: <OpenFolderIcon color="#47455F" width={15} height={13} />,
        title: FORMS_TITLES.CLOSING_DOCUMENTS,
        subtitle: FORMS_SUBTITLES.CLOSING_DOCUMENTS,
        submissions: 0,
        stepStatus: undefined,
        footerText: 'Approval of the client engagement is required.',
        completeAction: () => {},
        viewDetailsAction: () => {},
      },
    },
    customRules: {
      canCreate: [
        {
          rule: 'FormWithStatus',
          options: {
            identifier: 'EL',
            statuses: [EFormStatus.APPROVED, EFormStatus.APPROVED_WITH_CONDITIONS],
          },
        },
      ],
    },
    rules: {
      COMPLETED: [
        {
          rule: 'FormSubmitted',
        },
      ],
      READY: [
        {
          rule: 'FormWithStatus',
          options: {
            identifier: 'EL',
            statuses: [EFormStatus.APPROVED, EFormStatus.APPROVED_WITH_CONDITIONS],
          },
        },
      ],
      BLOCKED: [],
    },
  },
  {
    identifier: 'INV',
    metadata: {
      drawerProps: {
        key: 'invoiceForm',
        props: {},
      },
      componentProps: {
        icon: <FileIcon color="#47455F" width={11} height={13} />,
        title: FORMS_TITLES.CLOSING_INVOICES,
        subtitle: FORMS_SUBTITLES.CLOSING_INVOICES,
        submissions: 0,
        stepStatus: undefined,
        footerText: 'Completion of closing documents is required.',
        completeAction: () => {},
        viewDetailsAction: () => {},
      },
    },
    customRules: {
      canCreate: [
        {
          rule: 'FormWithStatus',
          options: {
            identifier: 'EL',
            statuses: [EFormStatus.APPROVED, EFormStatus.APPROVED_WITH_CONDITIONS],
          },
        },
        {
          rule: 'FormSubmitted',
          options: {
            identifier: 'CDOC',
          },
        },
      ],
    },
    rules: {
      COMPLETED: [
        {
          rule: 'FormSubmitted',
        },
      ],
      READY: [
        {
          rule: 'FormWithStatus',
          options: {
            identifier: 'EL',
            statuses: [EFormStatus.APPROVED, EFormStatus.APPROVED_WITH_CONDITIONS],
          },
        },
        {
          rule: 'FormSubmitted',
          options: {
            identifier: 'CDOC',
          },
        },
      ],
      BLOCKED: [],
    },
  },
];
