import React from 'react';

const OKCircleConfirmationIcon: React.FC<{ width: number; height: number; fill: string }> = ({
  width,
  height,
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Customer-Home" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="eSignature" transform="translate(-760.000000, -232.000000)" fill={fill}>
        <g id="Group-2" transform="translate(720.000000, 72.000000)">
          <g id="Group-3" transform="translate(40.000000, 160.000000)">
            <path
              d="M16,0 C11.7558743,0 7.68750338,1.68555242 4.68745416,4.68745376 C1.68574984,7.6876212 0,11.756268 0,16 C0,20.2437316 1.68555281,24.3124966 4.68745416,27.3125458 C7.6876216,30.3142502 11.7562684,32 16,32 C20.243732,32 24.312497,30.3144472 27.3125462,27.3125458 C30.3142506,24.3123784 32,20.2437316 32,16 C32,11.756268 30.3144476,7.68750298 27.3125462,4.68745376 C24.3123788,1.68574945 20.243732,0 16,0 Z M16.0000002,29.6336137 L16.0000002,29.6351506 C12.3840995,29.6351506 8.91581724,28.1974284 6.35909593,25.6404706 C3.80225639,23.0836311 2.36441591,19.6155458 2.36441591,15.9995663 C2.36441591,12.3835869 3.80213817,8.91538337 6.35909593,6.35866206 C8.91593546,3.80182252 12.3840207,2.36398204 16.0000002,2.36398204 C19.6159797,2.36398204 23.0841832,3.8017043 25.6409045,6.35866206 C28.197744,8.91550159 29.6355845,12.3835869 29.6355845,15.9995663 C29.6355845,19.6155458 28.1978622,23.0837493 25.6409045,25.6404706 C23.0840649,28.1973101 19.6159797,29.6351506 16.0000002,29.6351506 L16.0000002,29.6336137 Z M21.5169697,11.1523963 L14.1080737,18.1672231 L10.7184476,14.6990984 L10.7199884,14.6990984 C10.2627893,14.2311411 9.51315135,14.2219199 9.04519412,14.6790875 C8.57723688,15.1362867 8.56801566,15.8859246 9.02517934,16.3538819 L13.2413302,20.6500249 C13.4506795,20.8840035 13.7539355,21.0148463 14.0695062,21.0040724 C14.3727503,20.9963763 14.6636798,20.8855443 14.8961452,20.6885097 L23.1329808,12.8871207 C23.6055486,12.4484034 23.6409754,11.7125973 23.2114872,11.2308082 C22.7589381,10.7536297 22.0107976,10.7182029 21.5166742,11.1523017 L21.5169697,11.1523963 Z"
              id="circle-check-icon"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default OKCircleConfirmationIcon;
