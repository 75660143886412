import * as yup from 'yup';
import validateFileExtension, {
  basicRequiredInputValidation,
  validateFileName,
} from '../../../../utils/helpers/formValidators';

export const validationSchema = () =>
  yup.object().shape({
    files: yup
      .array()
      .required('At least one file is required')
      .test(
        '',
        'Some file has an invalid name',
        (array: File[]) => !!array && validateFileName(array)
      )
      .test(
        '',
        'Some file has an invalid extension',
        (array: File[]) => !!array && validateFileExtension(array)
      ),
    explanation: basicRequiredInputValidation(),
  });
