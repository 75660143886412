// vendors
import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import _get from 'lodash/get';

// context
import { UserContext } from 'app/users/context/UserContext';

// api
import { GET_MARKETPLACE_DEALS_V2 } from 'api/graphql/marketplace/queries';

// Components
import MarketplaceSettings from './MarketplaceSettings';
import GroupsSettingsLoader from '../GroupsSettings/GroupSettingsLoader';

function MarketplaceSettingsContainer() {
  const { activeDeal } = useContext(UserContext);

  const {
    called: marketplaceDealsCalled,
    loading: marketplaceDealsLoading,
    data: marketplaceDealsData,
  } = useQuery(GET_MARKETPLACE_DEALS_V2, {
    fetchPolicy: 'no-cache',
    variables: {
      filters: [
        {
          filterName: 'ID',
          filterType: 'equal',
          value: _get(activeDeal, 'id', ''),
        },
      ],
    },
  });

  // TODO: we will create a Generic loader
  if (marketplaceDealsCalled && marketplaceDealsLoading) return <GroupsSettingsLoader internal />;

  return (
    <MarketplaceSettings
      mDeal={
        marketplaceDealsData.marketplaceDeals && marketplaceDealsData.marketplaceDeals.length > 0
          ? marketplaceDealsData.marketplaceDeals[0]
          : null
      }
    />
  );
}

export default MarketplaceSettingsContainer;
