import * as yup from 'yup';

import validateFileExtension, {
  basicRequiredInputValidation,
  validateFileName,
} from 'app/utils/helpers/formValidators';
import { ONLY_ALPHABETS_REGEX } from './CAConstants';

export const CAInternalValidationSchema = yup.object().shape({
  files: yup
    .array()
    .required('At least one file is required')
    .test('', 'Some file has an invalid name', array => !!array && validateFileName(array))
    .test(
      '',
      'Some file has an invalid extension',
      array => !!array && validateFileExtension(array)
    ),
  firmName: basicRequiredInputValidation(),
  fullName: basicRequiredInputValidation().matches(
    ONLY_ALPHABETS_REGEX,
    'Only alphabets are allowed'
  ),
  emailAddress: yup
    .string()
    .email('Email address must be a valid email')
    .required('This field is required'),
  note: yup
    .string()
    .test(
      'max_limit',
      'You have reached the 300-character limit',
      value => String(value).length <= 300
    ),
});

export const CAExternallValidationSchema = yup.object().shape({
  files: yup
    .array()
    .required('At least one file is required')
    .test('', 'Some file has an invalid name', array => !!array && validateFileName(array))
    .test(
      '',
      'Some file has an invalid extension',
      array => !!array && validateFileExtension(array)
    ),
  firmName: basicRequiredInputValidation(),
  signer: basicRequiredInputValidation().matches(
    ONLY_ALPHABETS_REGEX,
    'Only alphabets are allowed'
  ),
  emailAddress: yup
    .string()
    .email('Email address must be a valid email')
    .required('This field is required'),
  CRD: yup
    .string()
    .required('This field is required')
    .test('special_chars', 'Only numbers are allowed', value =>
      new RegExp(/^\d+$/).test(String(value))
    )
    .test('max_limit', 'You have reached the 8-character limit', value => String(value).length < 9),
  note: yup
    .string()
    .test(
      'max_limit',
      'You have reached the 300-character limit',
      value => String(value).length <= 300
    ),
});
