import * as yup from 'yup';
import validateFileExtension, {
  REQUIRED_MESSAGE,
  validateFileName,
} from '../../../../utils/helpers/formValidators';

const EMPTY_MESSAGE = 'This field cannot be empty';

export const validationSchema = () =>
  yup.object().shape({
    files: yup
      .array()
      .required('At least one file is required')
      .test('', 'Some file has an invalid name', array => !!array && validateFileName(array))
      .test(
        '',
        'Some file has an invalid extension',
        array => !!array && validateFileExtension(array)
      ),
    publiclyTradedCompany: yup.boolean().required(REQUIRED_MESSAGE),
    ticker: yup
      .string()
      .nullable()
      .when('publiclyTradedCompany', {
        is: publiclyTradedCompany => publiclyTradedCompany,
        then: yup.string().required(REQUIRED_MESSAGE),
      }),
    isInitialDueDiligence: yup.boolean().required(REQUIRED_MESSAGE),
    initialDueDiligenceNote: yup.string().when('isInitialDueDiligence', {
      is: isInitialDueDiligence => isInitialDueDiligence,
      then: yup
        .string()
        .required(REQUIRED_MESSAGE)
        .trim(EMPTY_MESSAGE),
    }),
    isAnyRedFlag: yup.boolean().required(REQUIRED_MESSAGE),
    anyRedFlagNote: yup.string().when('isAnyRedFlag', {
      is: isAnyRedFlag => isAnyRedFlag,
      then: yup
        .string()
        .required(REQUIRED_MESSAGE)
        .trim(EMPTY_MESSAGE),
    }),
    isPotentialConflicts: yup.boolean().required(REQUIRED_MESSAGE),
    potentialConflictsNote: yup.string().when('isPotentialConflicts', {
      is: isPotentialConflicts => isPotentialConflicts,
      then: yup
        .string()
        .required(REQUIRED_MESSAGE)
        .trim(EMPTY_MESSAGE),
    }),
    targetInvestors: yup.object().test('TARGET-TEST', REQUIRED_MESSAGE, option => !!option),
    foreignSolicitation: yup.boolean().required(REQUIRED_MESSAGE),
    foreignSolicitationIntitutionalClients: yup.boolean().when('foreignSolicitation', {
      is: foreignSolicitation => foreignSolicitation,
      then: yup.boolean().required(REQUIRED_MESSAGE),
    }),
    foreignSolicitationCountries: yup.array().when('foreignSolicitation', {
      is: foreignSolicitation => foreignSolicitation,
      then: yup.array().required(REQUIRED_MESSAGE),
    }),
    foreignSolicitationPreexistingRelationship: yup.string().when('foreignSolicitation', {
      is: foreignSolicitation => foreignSolicitation,
      then: yup.string().required(REQUIRED_MESSAGE),
    }),
    conclusions: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .trim(EMPTY_MESSAGE),
  });
