import React from 'react';

import { FinalisLogov2, DoubleArrowIcon } from 'app/common/icons';

export const STEPS = {
  OPTION_SELECTOR: 'option',
  EXTERNAL: 'external',
  INTERNAL: 'internal',
};

export const ONLY_ALPHABETS_REGEX = /^[a-zA-Zs\s]*$/;

export const options = [
  {
    title: 'External party (FSA)',
    subtitle: 'The collaborator has an external broker-dealer.',
    icon: <DoubleArrowIcon color="#47455F" width="20px" height="14px" />,
    step: STEPS.EXTERNAL,
    id: 'external-option',
    disabled: false,
  },
  {
    title: 'Internal party (FSA)',
    subtitle: 'The collaborator is a Finalis registered representative.',
    icon: <FinalisLogov2 color="#47455F" width="20px" height="10px" />,
    step: STEPS.INTERNAL,
    id: 'internal-option',
    disabled: false,
  },
];

export const CA_EXTERNAL_FORM_LABELS = {
  FILES: 'Upload the document',
  FIRM_NAME: 'External broker-dealer’s name',
  SIGNER: 'Full name',
  EMAIL_ADDRESS: 'Email address',
  CRD: 'External BD CRD#',
  NOTE: 'Additional note',
};

export const CA_INTERNAL_FORM_LABELS = {
  FILES: 'Upload the document',
  FIRM_NAME: 'Finalis registered rep’s firm name',
  NAME: 'Full name',
  EMAIL_ADDRESS: 'Email address',
  NOTE: 'Additional note',
};

export const EXTERNAL_STEP = {
  description:
    'Upload your Fee Sharing Agreement with an external party. Our team will review it, suggest edits where necessary, and return it for your signature.',
  title: 'Point of contact',
  titleDescription: 'Provide the appropriate details about the external broker-dealer.',
  subtitle: 'Signatory on behalf of the external broker-dealer',
};

export const INTERNAL_STEP = {
  description:
    'Upload your Fee Sharing Agreement with a Finalis registered representative. Our team will review it, suggest edits where necessary, and return it for your signature.',
  title: 'Point of contact',
  titleDescription: 'Provide the appropriate details about the Finalis registered rep.',
  subtitle: 'Signatory on behalf of the firm',
};

export const ACCEPTED_FILES =
  '.jpg, .jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .png, image/jpeg, image/png, text/plain, .doc, .docx, .xls, .xlsx, .ppt, .pptx, application/excel, application/vnd.ms-excel, application/x-excel, application/x-msexcel, application/doc, application/ms-doc, application/msword, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation';
