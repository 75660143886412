// Libraries
import React from 'react';
import { IconProps } from './types';

export const ThunderIcon = ({
  width = '11px',
  height = '14px',
  color = 'currentColor',
  style = {},
}: IconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 11 14" fill="none">
    <path
      d="M10.7467 4.73633C10.697 4.62214 10.6163 4.52415 10.5138 4.45345C10.4113 4.38275 10.2911 4.34218 10.1667 4.33633H7.11333L7.96 1.17633C7.98696 1.07745 7.99077 0.973676 7.97111 0.873084C7.95146 0.772493 7.90887 0.677788 7.84667 0.596329C7.78456 0.515652 7.7048 0.450272 7.61351 0.405203C7.52222 0.360134 7.42181 0.336573 7.32 0.336328H2.65333C2.50282 0.331173 2.355 0.377128 2.23395 0.46671C2.11289 0.556291 2.02573 0.684221 1.98667 0.829662L0.2 7.49633C0.173034 7.59521 0.169232 7.69898 0.188888 7.79957C0.208544 7.90016 0.251128 7.99487 0.313333 8.07633C0.376123 8.1579 0.45695 8.22382 0.54948 8.26893C0.64201 8.31404 0.743729 8.33711 0.846666 8.33633H3.42667L2.22 12.8297C2.18046 12.9746 2.19101 13.1285 2.24993 13.2667C2.30886 13.4048 2.4127 13.5191 2.54464 13.5908C2.67658 13.6626 2.82888 13.6877 2.97687 13.662C3.12486 13.6364 3.25986 13.5616 3.36 13.4497L10.6267 5.44966C10.7132 5.35492 10.7705 5.23722 10.7918 5.11068C10.8131 4.98415 10.7974 4.85417 10.7467 4.73633ZM4.22 10.523L4.93333 7.85633C4.9603 7.75744 4.9641 7.65368 4.94445 7.55308C4.92479 7.45249 4.8822 7.35779 4.82 7.27633C4.75789 7.19565 4.67814 7.13027 4.58684 7.0852C4.49555 7.04013 4.39514 7.01657 4.29333 7.01633H1.73333L3.16 1.66966H6.44667L5.6 4.82966C5.57282 4.93037 5.56964 5.03604 5.59074 5.1382C5.61183 5.24035 5.6566 5.33613 5.72144 5.41783C5.78628 5.49954 5.86939 5.56488 5.96409 5.60862C6.05878 5.65236 6.16242 5.67327 6.26667 5.66966H8.64667L4.22 10.523Z"
      fill={color}
    />
  </svg>
);
