import { MarginProps } from 'app/utils/interfaces/interfaces';
import { StyleVal } from 'app/utils/types/types';

export const marginControl = ({ mb, mt, ml, mr, m }: MarginProps) => {
  const setVal = (val?: StyleVal): string => (typeof val === 'string' ? val : `${val}px`);
  return `
     ${mb ? `margin-bottom: ${setVal(mb)};` : ''}
     ${mt ? `margin-top: ${setVal(mt)};` : ''}
     ${ml ? `margin-left: ${setVal(ml)};` : ''}
     ${mr ? `margin-right: ${setVal(mr)};` : ''}
     ${m ? `margin: ${setVal(m)};` : ''}
    `;
};
