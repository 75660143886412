import React, { useState, useContext, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { Button, CheckIcon, EButtonType } from '@dealsyte/poki';

import { UPDATE_PASSWORD } from 'api/graphql/users/mutations';
import {
  UPDATE_PASSWORD_MUTATION,
  UPDATE_PASSWORD_MUTATIONVariables,
} from 'api/graphql/users/types/UPDATE_PASSWORD_MUTATION';
import { GET_USER_PROFILE_QUERY } from 'api/graphql/users/types/GET_USER_PROFILE_QUERY';
import { GET_USER_PROFILE } from 'api/graphql/users/queries';
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { UserContext } from 'app/users/context/UserContext';
import PasswordInput from 'app/auth/PasswordInput/PasswordInput';
import { EButtonMessages } from 'app/core-tools/due-diligence/types/types';
import { userPasswordSettingsValidationSchema } from 'app/auth/HelpersAuth';
import { usePasswordStrength } from 'app/utils/hooks/hooks';
import { useTouchedForm } from 'app/utils/hooks/useTouchedForm';
import { getError } from 'app/utils/helpers/helpers';

import { ESettingsPasswordLabels } from '../../TypedUsersSettingsModal';
import {
  BodySectionContainer,
  ErrorMessage,
  Form,
  InputContainer,
  PasswordSettingStrengthContainer,
  PasswordSettingStrengthText,
  SubmitButtonsContainer,
  Text,
  WarningIcon,
} from '../../StyledUsersSettingsModal';

function UserPasswordSettings() {
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);

  const { hasPassword } = useContext(UserContext);
  const { setIsTouched } = useTouchedForm();

  const [password, setPassword] = useState('');

  const [getUserProfile, { updateQuery }] = useLazyQuery<GET_USER_PROFILE_QUERY>(GET_USER_PROFILE, {
    fetchPolicy: 'no-cache',
    onCompleted({ profile }) {
      updateQuery(() => ({
        profile,
      }));
    },
  });

  const [updatePasswordMutation] = useMutation<
    UPDATE_PASSWORD_MUTATION,
    UPDATE_PASSWORD_MUTATIONVariables
  >(UPDATE_PASSWORD, {
    onError: error => {
      showErrorToast({ title: getError(error) });
    },
    onCompleted: ({ updatePassword }) => {
      if (updatePassword && updatePassword.status === 200) {
        showSuccessToast({
          title: updatePassword.statusText,
        });
        getUserProfile();
      }
    },
  });

  const currentPasswordStrength = usePasswordStrength(password);

  useEffect(() => {
    setPassword('');
  }, [password]);

  return (
    <Formik
      validationSchema={userPasswordSettingsValidationSchema}
      initialValues={{
        currentPassword: '',
        newPassword: '',
        verifiedNewPassword: '',
      }}
      onSubmit={async ({ currentPassword, newPassword }, { resetForm }) => {
        await updatePasswordMutation({ variables: { currentPassword, newPassword } });
        resetForm();
      }}
    >
      {({ handleChange: handle, handleBlur, values, handleSubmit, errors }) => {
        const handleChange = (e: React.ChangeEvent<any>) => {
          setIsTouched(true);
          handle(e);
        };
        return (
          <Form
            autoComplete="off"
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                handleSubmit();
                setIsTouched(false);
              }
            }}
            onSubmit={event => {
              event.preventDefault();
              event.stopPropagation();
              handleSubmit();
              setIsTouched(false);
            }}
          >
            <BodySectionContainer>
              <InputContainer>
                {hasPassword ? (
                  <>
                    <Text isSubTitle={true}>{ESettingsPasswordLabels.CHANGE_PASSWORD}</Text>
                    <Text isSubTitle={false} style={{ color: '#000', margin: '20px 0 10px 0' }}>
                      {ESettingsPasswordLabels.CURRENT_PASSWORD}
                    </Text>
                    <PasswordInput
                      id="currentPassword"
                      name="currentPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.currentPassword}
                      style={{ marginBottom: '20px' }}
                    />{' '}
                  </>
                ) : (
                  <Text isSubTitle={true}>{ESettingsPasswordLabels.SET_PASSWORD}</Text>
                )}
              </InputContainer>
            </BodySectionContainer>
            <BodySectionContainer>
              <InputContainer>
                <Text isSubTitle={false} style={{ color: '#000', margin: '0 0 10px 0' }}>
                  {ESettingsPasswordLabels.NEW_PASSWORD}
                </Text>
                <PasswordInput
                  id="newPassword"
                  name="newPassword"
                  onChange={(values: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setPassword(values.target.value);
                    handleChange(values);
                  }}
                  onBlur={handleBlur}
                  value={values.newPassword}
                  style={{ marginBottom: '20px' }}
                />
              </InputContainer>
            </BodySectionContainer>
            <BodySectionContainer>
              <InputContainer>
                <Text isSubTitle={false} style={{ color: '#000' }}>
                  {ESettingsPasswordLabels.VERIFY_PASSWORD}
                </Text>

                <PasswordInput
                  id="verifiedNewPassword"
                  name="verifiedNewPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.verifiedNewPassword}
                />

                <ErrorMessage>
                  {errors.verifiedNewPassword && (
                    <>
                      <WarningIcon>!</WarningIcon>
                      <span data-testid="UsersSettingsVerifiedNewPasswordErrorMessage">
                        {errors.verifiedNewPassword}
                      </span>
                    </>
                  )}
                </ErrorMessage>
              </InputContainer>
            </BodySectionContainer>

            <PasswordSettingStrengthContainer>
              <PasswordSettingStrengthText>
                <CheckIcon
                  height={11}
                  style={{ marginRight: 8 }}
                  color={currentPasswordStrength.moreThan8chars ? '#0fc54b' : '#e8e9ea'}
                />
                {ESettingsPasswordLabels.MORE_THAN_8_CHARS_PW_STRENGTH}
              </PasswordSettingStrengthText>
              <PasswordSettingStrengthText>
                <CheckIcon
                  height={11}
                  style={{ marginRight: 8 }}
                  color={currentPasswordStrength.atLeast1uppercase1lowercase ? '#0fc54b' : '#e8e9ea'}
                />
                {ESettingsPasswordLabels.MORE_THAN_1_LOWER_AND_UPPERCASE_PW_STRENGTH}
              </PasswordSettingStrengthText>
              <PasswordSettingStrengthText>
                <CheckIcon
                  height={11}
                  style={{ marginRight: 8 }}
                  color={currentPasswordStrength.atLeast1specialChar ? '#0fc54b' : '#e8e9ea'}
                />
                {ESettingsPasswordLabels.MORE_THAN_1_SPECIAL_CHAR_PW_STRENGTH}
              </PasswordSettingStrengthText>
            </PasswordSettingStrengthContainer>
            <SubmitButtonsContainer>
              <Button
                buttonType={EButtonType.primary}
                disabled={
                  Object.keys(errors).length !== 0 ||
                  values.newPassword === '' ||
                  values.verifiedNewPassword === ''
                }
                data-testid="UsersSettingsPasswordSaveChangesButton"
              >
                {EButtonMessages.SAVE_ACTION}{' '}
              </Button>
            </SubmitButtonsContainer>
          </Form>
        );
      }}
    </Formik>
  );
}

export default UserPasswordSettings;
