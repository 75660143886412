import * as React from 'react';
import { IconProps } from './types';

export const MinusIcon = ({
  width = '10',
  height = '10',
  color = 'currentColor',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 0.000610352C4.0111 0.000610352 3.0444 0.293855 2.22215 0.843262C1.39991 1.39267 0.759043 2.17356 0.380605 3.08719C0.00216642 4.00082 -0.0968503 5.00616 0.0960759 5.97606C0.289002 6.94597 0.765206 7.83688 1.46447 8.53614C2.16373 9.23541 3.05465 9.71161 4.02455 9.90454C4.99446 10.0975 5.99979 9.99845 6.91342 9.62001C7.82705 9.24157 8.60794 8.60071 9.15735 7.77846C9.70676 6.95622 10 5.98952 10 5.00061C10 4.344 9.87067 3.69382 9.6194 3.08719C9.36813 2.48057 8.99983 1.92937 8.53554 1.46508C8.07124 1.00078 7.52005 0.632486 6.91342 0.381213C6.30679 0.129939 5.65661 0.000610352 5 0.000610352ZM5 9.00061C4.20888 9.00061 3.43552 8.76601 2.77772 8.32649C2.11992 7.88696 1.60723 7.26225 1.30448 6.53134C1.00173 5.80044 0.92252 4.99617 1.07686 4.22025C1.2312 3.44433 1.61216 2.73159 2.17158 2.17218C2.73099 1.61277 3.44372 1.23181 4.21964 1.07747C4.99557 0.923128 5.79983 1.00234 6.53074 1.30509C7.26164 1.60784 7.88635 2.12053 8.32588 2.77833C8.76541 3.43613 9 4.20949 9 5.00061C9 6.06148 8.57858 7.07889 7.82843 7.82904C7.07828 8.57918 6.06087 9.00061 5 9.00061ZM7 4.50061H3C2.86739 4.50061 2.74022 4.55329 2.64645 4.64706C2.55268 4.74083 2.5 4.868 2.5 5.00061C2.5 5.13322 2.55268 5.2604 2.64645 5.35416C2.74022 5.44793 2.86739 5.50061 3 5.50061H7C7.13261 5.50061 7.25979 5.44793 7.35356 5.35416C7.44732 5.2604 7.5 5.13322 7.5 5.00061C7.5 4.868 7.44732 4.74083 7.35356 4.64706C7.25979 4.55329 7.13261 4.50061 7 4.50061Z"
      fill={color}
    />
  </svg>
);
