import styled, { DefaultTheme } from 'styled-components';

export const PillContainer = styled.div`
  ${({ theme }) => {
    const { spacing, radii, colors } = theme.foundations;
    return `
      display: flex;
      align-items: center;
      width: fit-content;
      color: ${colors.palette.neutral['800']};
      padding: ${spacing['01']} ${spacing['02']};
      border-radius: ${radii.md};
      margin-right: ${spacing['02']};
      &.gray {
        background: ${colors.palette.neutral['200']};
      }
      &.blue {
        background: ${colors.palette.blue['200']};
      }
      &.lightblue {
        background: ${colors.palette.lightBlue['200']};
      }
      &.teal {
        background: ${colors.palette.teal['200']};
      }
      &.green {
        background: ${colors.palette.green['200']};
      }
      &.yellow {
        background: ${colors.palette.yellow['200']};
      }
      &.orange {
        background: ${colors.palette.orange['200']};
      }
      &.red {
        background: ${colors.palette.red['200']};
      }
      &.purple {
        background: ${colors.palette.purple['200']};
      }
      &.white {
        background: ${colors.palette.neutral['50']};
      }
      &.gray-border {
        border: 1px solid ${colors.palette.neutral['300']};
      }
      &.lightgray {
        background-color: ${colors.palette.neutral['100']};
      }
    `;
  }}
`;

export const PillLabel = styled.label`
  ${({ theme, uppercase }: { theme: DefaultTheme; uppercase?: boolean }) => {
    const { typography } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-size: ${uppercase ? typography.sizes['02'] : typography.sizes['01']};
      text-transform: ${uppercase ? `uppercase` : `none`};
      letter-spacing: 1px;
      line-height: ${uppercase ? `18px` : `12px`};
    `;
  }}
`;

export const IconContainer = styled.div`
  ${({ theme }) => {
    const { spacing, colors } = theme.foundations;
    return `
      margin-left: ${spacing['01']};
      color: ${colors.text.default};
      display: flex;
    `;
  }}
`;
