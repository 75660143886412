import * as React from 'react';
import { IconProps } from './types';

export const ArrowLeftIcon = ({
  width = '9',
  height = '10',
  color = 'currentColor',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 9 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.83343 4.33338H2.77343L4.97343 2.14004C5.09896 2.01451 5.16949 1.84424 5.16949 1.66671C5.16949 1.48917 5.09896 1.31891 4.97343 1.19338C4.84789 1.06784 4.67763 0.997314 4.50009 0.997314C4.32256 0.997314 4.1523 1.06784 4.02676 1.19338L0.693427 4.52671C0.632733 4.59011 0.585157 4.66487 0.553427 4.74671C0.486748 4.90902 0.486748 5.09107 0.553427 5.25338C0.585157 5.33521 0.632733 5.40997 0.693427 5.47338L4.02676 8.80671C4.08874 8.86919 4.16247 8.91879 4.24371 8.95264C4.32495 8.98648 4.41209 9.00391 4.50009 9.00391C4.5881 9.00391 4.67524 8.98648 4.75648 8.95264C4.83772 8.91879 4.91145 8.86919 4.97343 8.80671C5.03591 8.74473 5.08551 8.671 5.11935 8.58976C5.1532 8.50852 5.17063 8.42138 5.17063 8.33337C5.17063 8.24537 5.1532 8.15823 5.11935 8.07699C5.08551 7.99575 5.03591 7.92202 4.97343 7.86004L2.77343 5.66671H7.83343C8.01024 5.66671 8.17981 5.59647 8.30483 5.47145C8.42986 5.34642 8.50009 5.17685 8.50009 5.00004C8.50009 4.82323 8.42986 4.65366 8.30483 4.52864C8.17981 4.40361 8.01024 4.33338 7.83343 4.33338Z"
      fill={color}
    />
  </svg>
);
