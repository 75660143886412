import _get from 'lodash/get';
// Types
import { EDealStatus } from 'app/utils/types/types';

export const DEAL_STATUS_LABELS = {
  [EDealStatus.INITIAL_ENGAGEMENT]: 'Initial Engagement',
  [EDealStatus.ENGAGEMENT_LETTER]: 'Engagement Letter',
  [EDealStatus.PRE_MARKETING]: 'Pre-Marketing',
  [EDealStatus.DUE_DILIGENCE_CLOSING]: 'DD / Closing',
  [EDealStatus.ON_HOLD]: 'On-hold',
  [EDealStatus.CLOSED]: 'Closed',
  [EDealStatus.EXPIRED]: 'Expired/Terminated',
};

export const DEAL_STATUS_OPTIONS = Object.values(EDealStatus).map(value => ({
  label: _get(DEAL_STATUS_LABELS, value),
  value,
}));

export const DEAL_TYPES = {
  ASSET_SALE: 'ASSET_SALE',
  CAPITAL_CORPORATE: 'CA_RA_COR',
  CAPITAL_FUND: 'CA_RA_FUN',
  CAPITAL_EB5: 'CA_RA_EB5',
  CONS_ENGAGEMENT: 'CONS_ENGAGEMENT',
  DIRECT_PARTICIPATION: 'D_P_P',
  MNA_BUY: 'M_N_A_B',
  MNA_SELL: 'M_N_A_S',
  OTHER: 'OTHER',
};

export const DEAL_TYPES_LABELS = {
  [DEAL_TYPES.ASSET_SALE]: 'Asset Sale',
  [DEAL_TYPES.CAPITAL_CORPORATE]: 'Capital Raise - Corporate',
  [DEAL_TYPES.CAPITAL_FUND]: 'Capital Raise - Fund',
  [DEAL_TYPES.CAPITAL_EB5]: 'Capital Raise - EB-5',
  [DEAL_TYPES.CONS_ENGAGEMENT]: 'Consulting Engagement',
  [DEAL_TYPES.DIRECT_PARTICIPATION]: 'Direct Participation Programs',
  [DEAL_TYPES.MNA_BUY]: 'M&A – Buy Side',
  [DEAL_TYPES.MNA_SELL]: 'M&A – Sell Side',
  [DEAL_TYPES.OTHER]: 'Other',
};

export const DEAL_TYPES_OPTIONS = Object.values(DEAL_TYPES).map(value => ({
  label: _get(DEAL_TYPES_LABELS, value),
  value,
}));

export const getDealTypeLabel = (type: string, fallback?: string) =>
  _get(DEAL_TYPES_LABELS, type, fallback);

export const DEAL_TARGET_INVESTOR_OPTIONS = [
  {
    value: 'Entities (+$5MM assets)',
    label: 'Entities (+$5MM assets)',
  },
  {
    value: 'Natural Persons (+$5MM assets)',
    label: 'Natural Persons (+$5MM assets)',
  },
  {
    value: 'Entities (+$5MM) and Natural Persons (+$5MM)',
    label: 'Entities (+$5MM) and Natural Persons (+$5MM)',
  },
];

export const DEAL_FORM_LABELS = {
  // Deal form fields
  PROJECT_NAME: 'Project Name (optional)',
  DEAL_NAME: 'Deal Name (Max 120 characters long)',
  DEAL_TYPE: 'Proposed Transaction Type',
  DEAL_STATUS: 'Deal Status',
  DEAL_EXPECTED_COMMISSION: 'Expected Commission Earnings (USD)',
  DEAL_EXPECTED_DEAL_SIZE: 'Expected Deal Size (USD)',
  DEAL_VISIBILITY: 'Do you want to share the deal with the firm?',
  CLIENT_NAME: 'Client Name (and DBA if applicable)',
  CLIENT_ADDRESS: 'Client Headquarters Office (No P.O. Boxes please)',
  CLIENT_ADDRESS_LINE1: 'Client headquarters – line 1',
  CLIENT_ADDRESS_LINE2: 'Client headquarters – line 2',
  CLIENT_RESPONSABLE_PERSON: 'Client Responsible Person',
  COUNTRY: 'Country',
  STATE: 'State',
  ZIP_CODE: 'Zip Code',
  INDUSTRY_TYPE: 'Industry Type',
  INDUSTRY_SUB_TYPE: 'Industry Sub-Type',
  ENGAGEMENT_LETTER_PROVIDED: 'Will you provide an Engagement Letter at this moment?',
  PUBLICITY_TRADED_COMPANY: 'Is the Client publicly traded?',
  TICKER_EXCHANGE: 'Ticker & Exchange',
  FILE_UPLOAD: 'Please attach the Engagement Letter for Compliance and Legal review',
  INITIAL_DUE_DILIGENCE:
    'Have you conducted initial due diligence on the Client? If so, what type (e.g., financial stress test, review of management background, etc.).',
  POTENTIAL_CONFLICTS:
    'Are there any potential conflicts of interest (e.g., board seat, advisor, equity interest, family connection, connection to the deal via an OBA/PST)?',
  TARGET_INVESTORS: 'Type of target investor',
  FOREIGN_SOLICITATION: 'Do you anticipate conducting any foreign solicitation?',
  // Miscellaneous
  DEAL_SETTINGS_TAB_TITLE: 'General',
  DEAL_SECTION_TITLE: 'Deal Information',
  CLIENT_SECTION_TITLE: 'Client Information',
  CLIENT_SECTION_DESCRIPTION: 'Details regarding the company that is fundraising or being sold.',
  DEAL_UPDATE_SUCCESS_MESSAGE: 'Successfully updated deal.',
  TARGET_INVESTORS_TOOLTIP:
    'Investors as defined in Rule 902 (K) under the Securities Act of 1993, as amended (the “Securities Act”) who are Qualified Purchasers (as defined in Section 2(a)(51) of the Investment Company Act of 1940, and certain rules thereunder) and Accredited Investors (as defined in Rule 230.501(a)(1)(2)(3)(7)(9)(12)(13)under the Securities Act)',
  UPDATE_DEAL_ACTION: 'Save changes',
  DEAL_UPDATE_VALIDATION_ERROR_MESSAGE: 'Please fill all required fields.',
  // New deal with Engagement Letter
  PUBLICLY_TRADED_LABEL: 'Is the client publicly traded?',
  TICKER_EXCHANGE_LABEL: 'Ticker & exchange',
  DUE_DILIGENCE_LABEL: 'Have you conducted initial due diligence on your client?',
  DUE_DILIGENCE_NOTE_LABEL: 'What type of due diligence have you conducted?',
  RED_FLAG_LABEL: 'Have you found any red flags?',
  RED_FLAG_NOTE_LABEL: 'Describe any red flags or concerns you may have had regarding your client.',
  POTENTIAL_CONFLICTS_LABEL: 'Are there any potential conflicts of interest?',
  POTENTIAL_CONFLICTS_NOTE_LABEL: 'Describe potential conflicts of interest',
  TARGET_INVESTOR_LABEL: 'Target investor',
  FOREIGN_SOLICITATION_LABEL: 'Do you anticipate conducting any foreign solicitation?',
  FOREIGN_SOLICITATION_INSTITUTIONAL_CLIENTS:
    'Are the foreign investors institutional clients per their country’s regulatory definition? NOTE: this varies from country to country.',
  FOREIGN_SOLICITATION_PREEXISTING_RELATIONSHIP:
    'Do you have a preexisting relationship with such investors or would you be soliciting new investors?',
  FOREIGN_SOLICITATION_COUNTRIES_LABEL: 'For which countries?',
  CONCLUSION_LABEL: 'Conclusion: why do you think this is a good investment?',
  ADDITIONAL_NOTES_LABEL: 'Additional notes',
};

export const FOREIGN_SOLICITATION_PREEXISTING_RELATIONSHIP_OPTIONS = [
  {
    label: 'I have a preexisting relationship with the investors.',
    value: 'I have a preexisting relationship with the investors.',
  },
  { label: 'New investors solicitation.', value: 'New investors solicitation.' },
];
