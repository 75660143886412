export const selectInputDefaultValue = undefined;

export const valueToSelectInput = <T = any>(
  value: T,
  options: { labelResolver?: (value: T) => string; fallback?: any } = {}
) => {
  const { labelResolver, fallback } = options;
  if (!value || !['string', 'number'].includes(typeof value)) {
    return fallback || selectInputDefaultValue;
  }
  return {
    label: labelResolver ? labelResolver(value) : String(value),
    value,
  };
};
