// vendors
import styled from 'styled-components';

// @poki
import { Modal, CloseIcon } from '@dealsyte/poki';

export const FinalisModalContainer = styled(Modal)`
  position: relative;
  width: auto !important;
  padding: 0 !important;
  border: none !important;
  display: flex;
  flex-direction: column;
  border-radius: 0.375rem;
  overflow: hidden;
`;

export const ModalHeader = styled.div`
  ${({ theme }) => {
    const { spacing, typography, colors } = theme.foundations;
    return `
      display: flex;
      align-items: center;
      height: 96px;
      padding: 0px ${spacing['06']};
      font-family: ${typography.fonts.body};
      font-size: 22px;
      color: ${colors.text.default};
      font-weight: ${typography.weights.bold};
      border-bottom: 1px solid ${colors.outline.xLight};
      background: ${colors.background.primary};
    `;
  }}
`;

export const ModalFooter = styled.div`
  ${({ theme }) => {
    const { spacing, typography, colors } = theme.foundations;
    return `
      display: flex;
      align-items: center;
      height: 112px;
      padding: 0px ${spacing['06']};
      font-family: ${typography.fonts.body};
      border-top: 1px solid ${colors.outline.xLight};
      background: ${colors.background.primary};
    `;
  }}
`;

export const ModalCloseButton = styled.button`
  ${({ theme }) => {
    const { sizes, colors } = theme.foundations;
    return `
      width: ${sizes['06']};
      height: ${sizes['06']};
      outline: 0;
      border: 0;
      background: ${colors.palette.transparent};
      color: ${colors.text.dark};
      cursor: pointer;
    `;
  }}
`;

export const FinalisModalCloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.875rem;
  border-bottom-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;

  background-color: #e4f2ff;
  border: none;
  outline: none;
  box-shadow: none;

  :focus,
  :active {
    border: none;
    outline: none;
    box-shadow: none;
  }
`;

export const FinalisModalCloseIcon = styled(CloseIcon)`
  height: 0.75rem;
  width: 0.75rem;
  color: ${({ theme }) => theme.palleteV2.gray.default};

  svg {
    fill: ${({ theme }) => theme.palleteV2.gray.default};
  }
`;
