import * as React from 'react';
import { IconProps } from './types';

export const GrowthGraphIcon = ({
  width = '16px',
  height = '16px',
  color = 'currentColor',
  style = {},
}: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" style={style}>
    <path
      fill={color}
      d="M4.667 10.667a1 1 0 0 0 1-1 .514.514 0 0 0 0-.1l1.86-1.86h.306L8.907 8.78v.054a1 1 0 1 0 2 0V8.78l2.426-2.446a1 1 0 1 0-1-1 .522.522 0 0 0 0 .1L9.927 7.84H9.82L8.667 6.667a1 1 0 0 0-2 0l-2 2a1 1 0 0 0 0 2Zm9 2.667H2.333V2A.667.667 0 1 0 1 2v12a.667.667 0 0 0 .667.667h12a.667.667 0 0 0 0-1.333Z"
    />
  </svg>
);
