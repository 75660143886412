import * as React from 'react';
import { IconProps } from './types';

export const CreditCard = ({ width = '180', height = '180' }: IconProps): JSX.Element => (
  <svg
    width="181"
    height="180"
    viewBox="0 0 181 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7284_34797)">
      <rect x="18.5" y="18" width="144" height="144" rx="14.4" fill="#EDEDF7" />
      <g filter="url(#filter0_d_7284_34797)">
        <rect
          x="31.5"
          y="72.5703"
          width="109.85"
          height="64.3949"
          rx="7.57587"
          transform="rotate(-9.7323 31.5 72.5703)"
          fill="#FEFEFE"
        />
        <rect
          x="33.2607"
          y="82.8359"
          width="109.85"
          height="14.2048"
          transform="rotate(-9.7323 33.2607 82.8359)"
          fill="#B9B6D2"
        />
        <rect
          x="48.6504"
          y="110.945"
          width="13.2578"
          height="13.2578"
          rx="3.78794"
          transform="rotate(-9.7323 48.6504 110.945)"
          fill="#807DA1"
        />
      </g>
      <circle cx="153.086" cy="21.4138" r="21.4138" fill="#B9B6D2" />
      <g filter="url(#filter1_d_7284_34797)">
        <path
          d="M162.285 29.0239L158.915 25.6533L161.564 23.0042L163.366 24.8062L162.285 29.0239ZM161.564 19.8252L158.915 17.1761L162.285 13.8055L163.366 18.0234L161.564 19.8252ZM156.478 11.1354L160.696 12.2158L157.325 15.5866L154.676 12.9375L156.478 11.1354ZM151.497 12.9376L148.848 15.5866L145.477 12.216L149.695 11.1354L151.497 12.9376ZM143.888 13.8052L147.259 17.1761L144.61 19.8252L142.807 18.0228L143.888 13.8052ZM144.61 23.004L147.259 25.6531L143.888 29.0239L142.807 24.8063L144.61 23.004ZM149.695 31.6939L145.477 30.6134L148.848 27.2426L151.497 29.8918L149.695 31.6939ZM155.736 25.6532L153.087 28.3023L150.437 25.6532L153.087 23.004L155.736 25.6532ZM157.325 18.7655L159.974 21.4146L157.325 24.0638L154.676 21.4146L157.325 18.7655ZM151.497 21.4146L148.848 24.0638L146.199 21.4146L148.848 18.7656L151.497 21.4146ZM150.438 17.1761L153.087 14.527L155.736 17.1761L153.087 19.8252L150.438 17.1761ZM154.676 29.8918L157.325 27.2428L160.696 30.6134L156.478 31.6938L154.676 29.8918ZM163.882 32.2097L165.935 24.1962L163.153 21.4147L165.935 18.633L163.882 10.6194L155.868 8.56641L153.087 11.348L150.305 8.56648L142.291 10.6196L140.238 18.6332L143.02 21.4146L140.238 24.1963L142.291 32.2099L150.305 34.263L153.087 31.4812L155.868 34.2628L163.882 32.2097Z"
          fill="white"
        />
      </g>
      <circle cx="114.914" cy="21.4138" r="21.4138" fill="#F8F8FC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.88 22.0715C129.88 19.8219 128.79 18.0469 126.707 18.0469C124.616 18.0469 123.351 19.8219 123.351 22.0539C123.351 24.6989 124.844 26.0345 126.989 26.0345C128.034 26.0345 128.825 25.7973 129.423 25.4633V23.7059C128.825 24.0047 128.14 24.1892 127.27 24.1892C126.417 24.1892 125.662 23.8904 125.565 22.8535H129.862C129.862 22.7393 129.88 22.2824 129.88 22.0715ZM125.539 21.2367C125.539 20.2437 126.145 19.8307 126.699 19.8307C127.235 19.8307 127.806 20.2437 127.806 21.2367H125.539Z"
        fill="#B9B6D2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.959 18.0469C119.098 18.0469 118.544 18.4511 118.237 18.7323L118.123 18.1875H116.189V28.4335L118.386 27.9677L118.395 25.4809C118.711 25.7094 119.177 26.0345 119.95 26.0345C121.523 26.0345 122.956 24.7692 122.956 21.9836C122.947 19.4353 121.497 18.0469 119.959 18.0469ZM119.432 24.1013C118.914 24.1013 118.606 23.9168 118.395 23.6883L118.386 20.4282C118.615 20.1734 118.931 19.9977 119.432 19.9977C120.232 19.9977 120.785 20.894 120.785 22.0451C120.785 23.2226 120.24 24.1013 119.432 24.1013Z"
        fill="#B9B6D2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.166 17.5279L115.372 17.0534V15.2695L113.166 15.7353V17.5279Z"
        fill="#B9B6D2"
      />
      <path d="M115.372 18.1953H113.166V25.8842H115.372V18.1953Z" fill="#B9B6D2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.802 18.8427L110.662 18.1924H108.764V25.8813H110.96V20.6704C111.479 19.9938 112.358 20.1168 112.63 20.2135V18.1924C112.349 18.0869 111.321 17.8936 110.802 18.8427Z"
        fill="#B9B6D2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.409 16.2891L104.265 16.746L104.256 23.7846C104.256 25.0851 105.231 26.043 106.532 26.043C107.252 26.043 107.78 25.9111 108.07 25.753V23.9692C107.788 24.0834 106.4 24.4876 106.4 23.1871V20.0676H108.07V18.1959H106.4L106.409 16.2891Z"
        fill="#B9B6D2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.468 20.4282C100.468 20.0855 100.749 19.9537 101.215 19.9537C101.883 19.9537 102.727 20.1558 103.394 20.5161V18.4511C102.665 18.1611 101.945 18.0469 101.215 18.0469C99.4314 18.0469 98.2451 18.9783 98.2451 20.5337C98.2451 22.959 101.584 22.5723 101.584 23.618C101.584 24.0222 101.233 24.154 100.741 24.154C100.011 24.154 99.0799 23.8553 98.3418 23.4511V25.5424C99.159 25.8939 99.985 26.0433 100.741 26.0433C102.568 26.0433 103.825 25.1382 103.825 23.5653C103.816 20.9467 100.468 21.4124 100.468 20.4282Z"
        fill="#B9B6D2"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_7284_34797"
        x="12.5603"
        y="38.8483"
        width="157.034"
        height="119.918"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="3.78794"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_7284_34797"
        />
        <feOffset dy="3.78794" />
        <feGaussianBlur stdDeviation="7.57587" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.254902 0 0 0 0 0.305882 0 0 0 0 0.505882 0 0 0 0.1 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7284_34797" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_7284_34797"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_7284_34797"
        x="94.2383"
        y="-31.3003"
        width="117.697"
        height="117.695"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="6.13333" />
        <feGaussianBlur stdDeviation="23" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.137255 0 0 0 0 0.121569 0 0 0 0 0.321569 0 0 0 0.15 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7284_34797" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_7284_34797"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_7284_34797">
        <rect width={width} height={height} fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
