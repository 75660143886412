import React from 'react';

// Components
import { Button, HStack, SideDrawer } from 'app/common/components';
import InvoiceForm from 'app/compliance/components/forms/InvoiceForm/InvoiceForm';

import { useInvoiceFormHandler, InvoiceHandlerProvider } from './InvoiceFormHandler';

const InvoiceFormModal = () => {
  const handler = useInvoiceFormHandler();
  const { onSubmitInvoiceForm, submitting, form, modal } = handler;
  const { hide, modalState } = modal;

  const hideModal = () => {
    hide();
    form.resetForm();
  };

  return (
    <InvoiceHandlerProvider value={handler}>
      <SideDrawer
        show={modalState.show}
        title="Invoice(s)"
        promptOnClose={form.dirty}
        onClose={hideModal}
        footerContent={({ onClose }) => (
          <HStack spacing={16} justifyContent="flex-end">
            <Button onClick={onClose} className="tertiary">
              Dismiss
            </Button>
            <Button
              onClick={onSubmitInvoiceForm}
              disabled={submitting || !form.isValid}
              style={{ width: 176 }}
            >
              Submit
            </Button>
          </HStack>
        )}
      >
        <InvoiceForm />
      </SideDrawer>
    </InvoiceHandlerProvider>
  );
};

export default InvoiceFormModal;
