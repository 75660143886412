import React, { ReactNode } from 'react';
import { ShowAlertArgs, PublicShowAlertArgs, ToastV2Props } from './TypedAlert';
import styled from 'styled-components';
import { toast, ToastContainer as MainToastContainer, cssTransition } from 'react-toastify';
import { getError } from 'app/utils/helpers/helpers';
import CopyLinkAlert from './copyLinkAlert/copyLinkAlert';
import { Alert } from '../../../common/components';

import 'react-toastify/dist/ReactToastify.css';
import './Alert.css';

export type AlertContextType = {
  showSuccessToast: (args: PublicShowAlertArgs) => number;
  showErrorToast: (args: PublicShowAlertArgs, error?: any) => number;
  showInfoToast: (args: PublicShowAlertArgs) => number;
  showWarningToast: (args: PublicShowAlertArgs) => number;
  dismissToast: (toastId?: number) => void;
  showCopyLinkToast: (message?: string) => number;
  showToastV2: (props: ToastV2Props) => void;
};

const AlertContext = React.createContext({
  showSuccessToast: () => 0,
  showErrorToast: () => 0,
  showInfoToast: () => 0,
  showWarningToast: () => 0,
  dismissToast: () => {},
  showCopyLinkToast: () => 0,
  showToastV2: () => {},
} as AlertContextType);

const AlertContextConsumer = AlertContext.Consumer;

const ToastWrapper = ({ className, ...props }: { className: string }) => (
  <MainToastContainer toastClassName={className} hideProgressBar {...props} closeButton={false} />
);

const ToastContainer = styled(ToastWrapper)<{ suppressClassNameWarning: boolean }>`
  border-radius: 3px !important;
`;

export default function AlertContextProvider({ children }: { children: ReactNode }) {
  const handleTitleDescription = (
    title: string,
    description: string | undefined,
    variant: 'danger' | 'warning' | 'success' | 'regular'
  ) => {
    return description ? (
      <>
        <label style={{ fontWeight: 700, fontSize: '100%' }}>
          {title}
          {variant === 'danger' ? '. ' : ' '}
        </label>
        {description}
      </>
    ) : (
      <>{title}</>
    );
  };

  function showToast({
    title,
    description,
    variant = 'regular',
    showCloseIcon,
    noIcon,
  }: ShowAlertArgs) {
    return toast(
      <Alert
        children={handleTitleDescription(title, description, variant)}
        style={{
          marginBottom: 0,
        }}
        type="floating"
        variant={variant as any}
        removable={showCloseIcon}
        noIcon={noIcon}
      />,
      {
        position: 'bottom-left',
        className: `alert-transparent ${variant}`,
      }
    );
  }

  function showSuccessToast(args: PublicShowAlertArgs) {
    return showToast({ ...args, variant: 'success' });
  }

  function showErrorToast(args: PublicShowAlertArgs, error?: any) {
    const alertArgs = {
      ...args,
      title: getError(error, args.title),
    };
    return showToast({ ...alertArgs, variant: 'danger' });
  }

  function showInfoToast(args: PublicShowAlertArgs) {
    return showToast({ ...args, variant: 'regular' });
  }

  function showWarningToast(args: PublicShowAlertArgs) {
    return showToast({ ...args, variant: 'warning' });
  }

  function dismissToast(toastId?: number) {
    toast.dismiss(toastId);
  }

  const Zoom = cssTransition({
    enter: 'zoomIn',
    exit: 'zoomOut',
  });

  function showCopyLinkToast(message?: string) {
    return toast(<CopyLinkAlert message={message} />, {
      position: 'bottom-center',
      className: 'alert-container',
      autoClose: 3000,
      transition: Zoom,
      toastId: 'copyLinkToast',
    });
  }

  function showToastV2({
    description,
    variant = 'regular',
    showCloseIcon = false,
    noIcon,
  }: ToastV2Props) {
    return toast(
      <Alert
        text={description}
        style={{
          marginBottom: 0,
        }}
        type="floating"
        variant={variant as any}
        removable={showCloseIcon}
        noIcon={noIcon}
      />,
      {
        position: 'bottom-left',
        className: 'alert-transparent',
      }
    );
  }

  return (
    <>
      <ToastContainer className="" suppressClassNameWarning />

      <AlertContext.Provider
        value={{
          showSuccessToast,
          showErrorToast,
          showInfoToast,
          showWarningToast,
          dismissToast,
          showCopyLinkToast,
          showToastV2,
        }}
      >
        {children}
      </AlertContext.Provider>
    </>
  );
}

export { AlertContext, AlertContextProvider, AlertContextConsumer };
