import React, { useContext } from 'react';
import { UserContext } from 'app/users/context/UserContext';
import { Redirect, Route, RouteProps } from 'react-router-dom';

const RedirectComponent = () => <Redirect to="/" />;

interface CustomerRouteProps extends RouteProps {
  condition?: boolean;
}

export const CustomerRoute = ({
  component,
  condition = true,
  ...props
}: CustomerRouteProps): React.ReactElement | null => {
  const { isCustomer, loadingDealData, loadingProfileData } = useContext(UserContext);
  const isLoading = loadingDealData || loadingProfileData;

  if (isLoading) return null;
  if (!condition) return <RedirectComponent />;

  return <Route {...props} component={isCustomer ? component : RedirectComponent} />;
};
