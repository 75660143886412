import React, { useContext, useEffect, useState } from 'react';

import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { Button, HStack, SideDrawer } from 'app/common/components';
import { ArrowLeftIcon, ArrowRightIcon } from 'app/common/icons';
import ModalClosePrompt from 'app/common/components/Modal/ModalClosePrompt';
import { FormsContext } from 'app/compliance/context/FormsContext';

import {
  CollaborationAgreementsHandlerProvider,
  useCollaborationAgreementsFormHandler,
} from './CAFormHandler';
import { STEPS } from './CAConstants';
import CollaborationAgreementsForm from './CAForm';

const CollaborationAgreementsFormDrawer = () => {
  const handler = useCollaborationAgreementsFormHandler();
  const [previousModal, setPreviousModal] = useState(false);
  const { showSuccessToast } = useContext(AlertContext);
  const {
    dealRegulation: { query },
  } = useContext(FormsContext);
  const {
    currentStep,
    setCurrentStep,
    setOptionSelected,
    optionSelected,
    modal,
    externalHandler,
    internallHandler,
  } = handler;

  const {
    form: internalForm,
    onSubmitCAInternalForm,
    creatingForm: creatingInternalForm,
  } = internallHandler;

  const {
    form: externalForm,
    onSubmitCAExternalForm,
    creatingForm: creatingExternalForm,
  } = externalHandler;

  const { modalState, hide } = modal;
  const [title, setTitle] = useState('Collaboration agreements');

  const getDrawerTitle = () => {
    if (currentStep === STEPS.OPTION_SELECTOR) return setTitle('Collaboration agreements');

    const result: string =
      currentStep === STEPS.INTERNAL
        ? `Fee Sharing Agreement - Internal`
        : `Fee Sharing Agreement - External`;

    return setTitle(result);
  };

  const hideDrawer = () => {
    hide();
    setOptionSelected('');
    setCurrentStep(STEPS.OPTION_SELECTOR);
    internalForm.resetForm();
    externalForm.resetForm();
  };

  const handlePreviousStep = () => {
    setCurrentStep(STEPS.OPTION_SELECTOR);
    internalForm.resetForm();
    externalForm.resetForm();
  };

  const getPromptValue = (step: string) => {
    switch (step) {
      case 'option':
        return false;
      case 'external':
        return externalForm.dirty;
      case 'internal':
        return internalForm.dirty;
      default:
        return false;
    }
  };

  const handleSubmit = async () => {
    try {
      STEPS.EXTERNAL === currentStep
        ? await onSubmitCAExternalForm()
        : await onSubmitCAInternalForm();
    } catch (error) {
      return;
    }
    showSuccessToast({
      title: 'Success!',
      description: 'Fee Sharing Agreement submitted successfully.',
    });
    setOptionSelected('');
    setCurrentStep(STEPS.OPTION_SELECTOR);
    hide();
    query.refetch();
  };

  useEffect(() => {
    getDrawerTitle();
  }, [currentStep]);

  return (
    <CollaborationAgreementsHandlerProvider value={handler}>
      <SideDrawer
        show={modalState.show}
        onClose={hideDrawer}
        title={title}
        footerContent={({ onClose }) => (
          <HStack>
            {STEPS.OPTION_SELECTOR !== currentStep && (
              <Button
                onClick={
                  externalForm.dirty || internalForm.dirty
                    ? () => setPreviousModal(true)
                    : handlePreviousStep
                }
                className="tertiary"
              >
                <ArrowLeftIcon style={{ marginRight: '12px' }} />
                Previous
              </Button>
            )}
            <HStack justifyContent="flex-end" spacing={16}>
              <Button onClick={onClose} className="tertiary">
                Cancel
              </Button>
              {STEPS.OPTION_SELECTOR === currentStep ? (
                <Button
                  disabled={!optionSelected}
                  onClick={() => {
                    setCurrentStep(optionSelected);
                    setOptionSelected('');
                  }}
                >
                  Next
                  <ArrowRightIcon style={{ marginLeft: '12px' }} />
                </Button>
              ) : (
                <Button
                  disabled={
                    STEPS.EXTERNAL === currentStep
                      ? !externalForm.isValid || creatingExternalForm
                      : !internalForm.isValid || creatingInternalForm
                  }
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              )}
            </HStack>
          </HStack>
        )}
        closeWhenClickOutside={false}
        promptOnClose={getPromptValue(currentStep)}
      >
        <CollaborationAgreementsForm
          currentStep={currentStep}
          setOptionSelected={setOptionSelected}
          optionSelected={optionSelected}
        />
      </SideDrawer>
      <ModalClosePrompt
        onAbort={() => setPreviousModal(false)}
        onConfirm={() => {
          handlePreviousStep();
          setPreviousModal(false);
        }}
        visible={previousModal}
      />
    </CollaborationAgreementsHandlerProvider>
  );
};

export default CollaborationAgreementsFormDrawer;
