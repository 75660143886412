import React, { ReactNode } from 'react';
import { UserContextProvider, useUserContextHandler } from './UserContext';

type UserContextProps = {
  children: ReactNode;
};

const UserContext = ({ children }: UserContextProps) => {
  const handler = useUserContextHandler();
  return <UserContextProvider value={handler}>{children}</UserContextProvider>;
};

export default UserContext;
