import React from 'react';

// Components
import { Button, HStack, SideDrawer } from 'app/common/components';

import ClosingDocumentsForm from './ClosingDocumentsForm';
import {
  ClosingDocumentsHandlerProvider,
  useClosingDocumentsFormHandler,
} from './ClosingDocumentsFormHandler';

const ClosingDocumentsFormDrawer = () => {
  const handler = useClosingDocumentsFormHandler();
  const { onSubmitClosingDocumentsForm, submitting, form, modal } = handler;
  const { modalState, hide } = modal;
  const { isValid } = form;

  const hideModal = () => {
    hide();
    form.resetForm();
  };

  return (
    <ClosingDocumentsHandlerProvider value={handler}>
      <SideDrawer
        title="Closing documents"
        show={modalState.show}
        onClose={hideModal}
        promptOnClose={form.dirty}
        footerContent={({ onClose }) => (
          <HStack justifyContent="flex-end" spacing={16}>
            <Button onClick={onClose} className="tertiary">
              Dismiss
            </Button>
            <Button
              disabled={!isValid || submitting}
              onClick={onSubmitClosingDocumentsForm}
              style={{ width: 176 }}
            >
              Submit
            </Button>
          </HStack>
        )}
      >
        <ClosingDocumentsForm />
      </SideDrawer>
    </ClosingDocumentsHandlerProvider>
  );
};

export default ClosingDocumentsFormDrawer;
