import React, { useContext } from 'react';
import get from 'lodash/get';
import { CaretIcon, ContextMenu, EDirections } from '@dealsyte/poki';
import { UserContext } from 'app/users/context/UserContext';
import { GET_USER_PROFILE_QUERY_profile_firms } from 'api/graphql/users/types/GET_USER_PROFILE_QUERY';
import { TriggerContainer } from './StyledHeaderSessionTools';

const HeaderFirmContextSwitcher = () => {
  const { firms, setCurrentFirm, getCurrentFirm } = useContext(UserContext);

  const firmsList = (firms || []) as GET_USER_PROFILE_QUERY_profile_firms[];
  const optionAction = (firm: any) => () => setCurrentFirm(firm);

  const firmOptions = firmsList.map(firm => ({
    label: firm.name,
    onClick: optionAction(firm),
  }));

  const firmName = get(getCurrentFirm(), 'name') || '';

  if (firmOptions.length < 2) {
    return null;
  }

  return (
    <ContextMenu
      actions={firmOptions}
      triggerButton={
        <TriggerContainer>
          <span>{firmName}</span> <CaretIcon direction={EDirections.down} />
        </TriggerContainer>
      }
    />
  );
};

export default HeaderFirmContextSwitcher;
