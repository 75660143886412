import React, { useEffect, useState } from 'react';
import setIn from 'lodash/set';
import getIn from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';
import { useSwappedStates, StateTypes, SwappedStatesConfig } from './useSwappedStates';

export interface FormConfig<T = any> {
  initialValues: T;
  validationSchema?: any;
  storeType?: StateTypes;
  stateConfig?: Omit<SwappedStatesConfig, 'initialValues'>;
}

export interface FormikStub<T = any> {
  values: T;
  errors: Record<keyof T, any>;
  isValid: boolean;
  touched: Record<keyof T, boolean | any>;
  dirty: boolean;
  setFieldValue: (key: string, value: any) => void;
  resetForm: () => void;
  setValues: (values: T) => void;
  setTouched: React.Dispatch<React.SetStateAction<Record<keyof T, boolean | any>>>;
  setErrors: React.Dispatch<React.SetStateAction<Record<keyof T, boolean | any>>>;
}

export function createDirtyFieldsMap<T>(fields: T): Record<keyof T, boolean> {
  const dirtyMap = Object.keys(fields).reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: false,
    }),
    {}
  );
  return dirtyMap as Record<keyof T, boolean>;
}

export function createErrorFieldsMap<T>(fields: T): Record<keyof T, any> {
  const errorMap = Object.keys(fields).reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: null,
    }),
    {}
  );
  return errorMap as Record<keyof T, any>;
}

/**
 * Transform Yup ValidationError to a more usable object
 */
export function yupToFormErrors(yupError: any): any {
  let errors = {};
  if (yupError.inner) {
    if (yupError.inner.length === 0) {
      return setIn(errors, yupError.path, yupError.message);
    }
    for (let err of yupError.inner) {
      if (!getIn(errors, err.path)) {
        errors = setIn(errors, err.path, err.message);
      }
    }
  }
  return errors;
}

/**
 * Validate a yup schema.
 */
export function validateYupSchema(
  values: any,
  schema: any,
  sync: boolean = false,
  context: any = {}
): Promise<Partial<any>> {
  const validateData = prepareDataForValidation(values);
  return schema[sync ? 'validateSync' : 'validate'](validateData, {
    abortEarly: false,
    context: context,
  });
}

/**
 * Recursively prepare values.
 */
export function prepareDataForValidation(values: any) {
  let data: any = Array.isArray(values) ? [] : {};
  for (let k in values) {
    if (Object.prototype.hasOwnProperty.call(values, k)) {
      const key = String(k);
      if (Array.isArray(values[key]) === true) {
        data[key] = values[key].map((value: any) => {
          if (Array.isArray(value) === true || isPlainObject(value)) {
            return prepareDataForValidation(value);
          } else {
            return value !== '' ? value : undefined;
          }
        });
      } else if (isPlainObject(values[key])) {
        data[key] = prepareDataForValidation(values[key]);
      } else {
        data[key] = values[key] !== '' ? values[key] : undefined;
      }
    }
  }
  return data;
}

const isFormDirty = (dirtyFields: any = {}) => Object.keys(dirtyFields).some(f => !!dirtyFields[f]);

export function useForm<T>(config: FormConfig<T>): FormikStub<T> {
  const initialValuesCopy = JSON.parse(JSON.stringify(config.initialValues));
  const [form, setForm] = useSwappedStates<T>(config.storeType || 'default', {
    initialValues: initialValuesCopy,
    ...(config.stateConfig || {}),
  });
  const [errors, setErrors] = useState<Record<keyof T, any>>(
    createErrorFieldsMap<T>(initialValuesCopy)
  );
  const [isValid, setIsValid] = useState(true);
  const [touched, setTouched] = useState<Record<keyof T, boolean | any>>(
    createDirtyFieldsMap(initialValuesCopy)
  );

  const formIsValid = async () => {
    const schema = config.validationSchema;
    if (schema) {
      const valid = await schema.isValid(form);
      setIsValid(valid);
    }
  };

  const setValues = (values: T) => {
    setForm(() => values);
  };

  const validate = async (): Promise<Record<keyof T, any>> => {
    const schema = config.validationSchema;
    if (schema) {
      const formikValidation = new Promise(resolve => {
        validateYupSchema(form, schema)
          .then(() => resolve({}))
          .catch(e => resolve(yupToFormErrors(e)));
      });
      const result = await formikValidation;
      await formIsValid();
      return result as Record<keyof T, any>;
    }
    return {} as Record<keyof T, any>;
  };

  useEffect(() => {
    formIsValid();
  }, []);

  useEffect(() => {
    validate().then(errors => {
      setErrors(errors);
    });
  }, [form]);

  const formikStub = {
    values: form,
    errors,
    isValid,
    dirty: isFormDirty(touched),
    touched,
    setFieldValue: (key: string, value: any) => {
      setForm(state => ({
        ...state,
        [key]: value,
      }));
      setTouched(fields => ({
        ...fields,
        [key]: true,
      }));
    },
    resetForm: () => {
      const initialValues = JSON.parse(JSON.stringify(config.initialValues));
      setTouched(createDirtyFieldsMap(initialValues));
      setErrors(createErrorFieldsMap(initialValues));
      setForm(initialValues);
    },
    setValues,
    setTouched,
    setErrors,
  };

  return formikStub;
}
