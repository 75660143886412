import styled from 'styled-components';

export const CreditCardInputWrapper = styled.div`
  ${({ theme }) => {
    const { colors, typography, radii } = theme.foundations;
    return `
    padding: 13.5px;
    border: 1px solid ${colors.palette.neutral['500']};
    border-radius: ${radii.sm};
    color: ${colors.palette.neutral['600']};
    font-weight: ${typography.weights.regular};
    font-family: ${typography.fonts.body};
    `;
  }}
`;
