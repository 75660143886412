import * as React from 'react';
import { IconProps } from './types';

export const DoubleArrowIcon = ({
  width = '14',
  height = '10',
  color = 'currentColor',
}: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 14 10"
    fill="none"
  >
    <path
      fill={color}
      d="M13.473 3.194 10.807.527a.67.67 0 0 0-.947.947l1.533 1.527H3.667a.667.667 0 0 0 0 1.333H13a.666.666 0 0 0 .613-.413.666.666 0 0 0-.14-.727Zm-3.14 2.473H1a.667.667 0 0 0-.613.414.667.667 0 0 0 .14.726l2.666 2.667a.667.667 0 0 0 1.093-.217.667.667 0 0 0-.146-.73L2.607 7.001h7.726a.667.667 0 1 0 0-1.334Z"
    />
  </svg>
);
