import React, { useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { DayValue } from 'react-modern-calendar-datepicker';
import useStateModal from 'app/utils/hooks/useStateModal';

// mutations
import { CREATE_FORM } from 'api/graphql/compliance/mutations';

// context
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { FormsContext } from 'app/compliance/context/FormsContext';
import { UseMultiStepForm, useMultiStepForm } from 'app/utils/hooks/useMultiStepForm';

// Helpers
import { buildOBAForm, OBA_VALUE_INFERENCE_MAP } from './OBAFormHelpers';
import { mapFormData } from 'app/utils/helpers/formDataMappers';

// Validations
import { validationSchema } from './OBAFormValidations';

export type OBAForm = {
  entityName: string;
  startDate: DayValue;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  position: string;
  natureOfBusiness: string;
  dutyDescription: string;
  approximateHours: string;
  devotedHours: string;
  hasReasonableCompensation: boolean;
  isCapitalRaise: boolean;
  isInvestmentRelated: boolean;
  compensation: string[];
  files: File[];
  othersDescription: string;
  otherInfo: string;
  otherLicenses: string;
  websitesConnectedToBusiness: string;
  noteComment: string;
};

const steps = [
  {
    entityName: '',
    position: '',
    startDate: null,
    isInvestmentRelated: undefined,
  },
  {
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
  },
  {
    natureOfBusiness: '',
    dutyDescription: '',
    approximateHours: '',
    devotedHours: '',
    websitesConnectedToBusiness: '',
  },
  {
    hasReasonableCompensation: undefined,
    compensation: [],
    othersDescription: '',
    isCapitalRaise: undefined,
  },
  {
    otherLicenses: '',
    otherInfo: '',
  },
  {
    files: [],
  },
  {
    noteComment: '',
  },
];

export interface UseOBAFormHandler {
  form: UseMultiStepForm<OBAForm>;
  onSubmitOBAForm: () => Promise<void>;
  submitting: boolean;
  modal: ReturnType<typeof useStateModal>;
  isEdit: boolean;
}

export const OBAFormHandlerContext = React.createContext<UseOBAFormHandler>(
  {} as UseOBAFormHandler
);
export const OBAFormHandlerProvider = OBAFormHandlerContext.Provider;

export const useOBAFormHandler = (): UseOBAFormHandler => {
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);
  const modal = useStateModal('OBAFormDrawer');
  const {
    hide,
    modalState: { props, show },
  } = modal;
  const { getFormQuery, updateFormMutation, refetchAll } = useContext(FormsContext);

  const [getForm, { data: formData }] = getFormQuery;
  const [updateForm] = updateFormMutation;

  const isEdit = props && props.isEdit;
  const formId = props && props.formId;

  const form = useMultiStepForm<OBAForm>({
    steps,
    validationSchema,
  });

  const getFormData = async () => {
    if (formId) {
      await getForm({ variables: { id: formId } });
    }
  };

  useEffect(() => {
    if (show && isEdit) {
      getFormData();
    }
  }, [show]);

  useEffect(() => {
    if (formData && formData.form) {
      const mappedValues = mapFormData<OBAForm>(
        formData.form.content,
        steps.reduce((prev, curr) => ({ ...prev, ...curr }), {}) as OBAForm,
        OBA_VALUE_INFERENCE_MAP,
        {
          compensation: value => {
            const compensation = (value && value.compensation) || [];
            const othersDescription = (value && value.othersDescription) || '';
            return {
              compensation,
              othersDescription,
            };
          },
        }
      );
      form.setValues(mappedValues);
    }
  }, [formData]);

  const resetAndCleanUp = () => {
    hide();
    form.resetForm();
    refetchAll(['regulatoryProfile', 'OBACount', 'attestationForms']);
  };

  const [createFormMutation, { loading: loadingForm }] = useMutation(CREATE_FORM, {
    onCompleted() {
      showSuccessToast({ title: 'Success!', description: 'Form was created successfully' });
      resetAndCleanUp();
    },
    onError() {
      showErrorToast({ title: 'Error', description: 'An error occurred while saving the form' });
    },
  });

  const submitting = loadingForm;

  const onSubmitOBAForm = async (): Promise<void> => {
    const { values } = form;
    if (isEdit) {
      await updateForm({
        variables: {
          id: formId,
          content: buildOBAForm(values).content,
        },
      });
      showSuccessToast({ title: 'Success!', description: 'Form was updated successfully' });
      resetAndCleanUp();
    } else {
      createFormMutation({
        variables: buildOBAForm(values),
      });
    }
  };

  return { form, onSubmitOBAForm, submitting, modal, isEdit };
};
