import React, { useState } from 'react';
import get from 'lodash/get';
import { FormConfig, FormikStub, useForm } from './useForm';

export interface MultiFormConfig<T = any> extends Omit<FormConfig<T>, 'initialValues'> {
  steps: Partial<T>[];
}

export interface UseMultiStepForm<T = any> extends FormikStub<T> {
  currentStep: number;
  isLastStep: boolean;
  percentage: number;
  prevStep: () => void;
  nextStep: () => void;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  noValidationNextStep: () => void;
  isFormValidOnStep: (step: number) => boolean;
  getStepKeys: (step: number) => string[];
}

type UseMultiStepFormFunction = <P>(config: MultiFormConfig<P>) => UseMultiStepForm<P>;

export const useMultiStepForm: UseMultiStepFormFunction = (config = { steps: [] }) => {
  const { validationSchema, steps: configSteps, ...restConfig } = config;

  // Utility functions
  const getInitialValuesFromSteps = (steps: any[]) =>
    steps.reduce((prev, curr) => ({ ...prev, ...curr }), {});

  const getStepKeys = (step: number): string[] => {
    const index = step - 1;
    const steps = get(config, `steps[${index}]`) || {};
    return Object.keys(steps);
  };

  // State
  const [currentStep, setCurrentStep] = useState(1);
  const form = useForm({
    initialValues: getInitialValuesFromSteps(configSteps),
    validationSchema: validationSchema || null,
    ...restConfig,
  });

  const isLastStep = currentStep === config.steps.length;
  const percentage = (currentStep / config.steps.length) * 100;

  // Hook methods
  const isFormValidOnStep = (step: number) => {
    const fields = getStepKeys(step);
    const { errors } = form;
    return !fields.some(key => errors[key]);
  };

  const reset = () => {
    form.resetForm();
    setCurrentStep(1);
  };

  const isStepOnRange = (step: number) => step >= 1 && step <= (config.steps.length || 1);
  const validateStep = (step: number) => isFormValidOnStep(step) && isStepOnRange(step);

  const nextStep = () => {
    if (validateStep(currentStep)) {
      setCurrentStep(value => value + 1);
    }
  };

  const noValidationNextStep = () => {
    setCurrentStep(value => value + 1);
  };

  const prevStep = () => {
    setCurrentStep(value => value - 1);
  };

  return {
    ...form,
    currentStep,
    isLastStep,
    percentage,
    resetForm: reset,
    prevStep,
    nextStep,
    setCurrentStep,
    noValidationNextStep,
    isFormValidOnStep,
    configSteps,
    getStepKeys,
  };
};
