/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AllowedKeys {
  ENABLE_DOCUMENT_INDEX = 'ENABLE_DOCUMENT_INDEX',
  ENABLE_DOCUMENT_NAME = 'ENABLE_DOCUMENT_NAME',
  ENABLE_TIMESTAMP = 'ENABLE_TIMESTAMP',
  ENABLE_USER_EMAIL = 'ENABLE_USER_EMAIL',
  ENABLE_USER_GROUP = 'ENABLE_USER_GROUP',
  ENABLE_USER_NAME = 'ENABLE_USER_NAME',
  TEXT_LABEL = 'TEXT_LABEL',
}

export enum AllowedTypes {
  boolean = 'boolean',
  string = 'string',
}

export enum CollaborateTypeEnum {
  EXECUTION = 'EXECUTION',
  OTHER = 'OTHER',
  RESEARCH = 'RESEARCH',
}

export enum CollaborativeOfferingsEnum {
  DEAL = 'DEAL',
  FUND = 'FUND',
}

export enum ContextValues {
  vdr = 'vdr',
  diligence = 'diligence',
}

export enum ConversionStatus {
  converted = 'converted',
  failed = 'failed',
  pending = 'pending',
  unfit = 'unfit',
}

export enum DealFilterEnum {
  CALCULATED_PERCENT_RATE = 'CALCULATED_PERCENT_RATE',
  COUNTRY = 'COUNTRY',
  EXPECTED_COMMISSIONS = 'EXPECTED_COMMISSIONS',
  EXPECTED_SIZE = 'EXPECTED_SIZE',
  ID = 'ID',
  IS_RETAINER = 'IS_RETAINER',
  LOCATION = 'LOCATION',
  LOI_PROVIDED = 'LOI_PROVIDED',
  MARKETPLACE_DEAL_ID = 'MARKETPLACE_DEAL_ID',
  MARKETPLACE_DEAL_TYPE = 'MARKETPLACE_DEAL_TYPE',
  MODE = 'MODE',
  NON_SHOWN_INTEREST_YET = 'NON_SHOWN_INTEREST_YET',
  OWNER_ID = 'OWNER_ID',
  PRIMARY_INDUSTRY = 'PRIMARY_INDUSTRY',
  PUBLISHED = 'PUBLISHED',
  PUBLISHED_AT = 'PUBLISHED_AT',
  RETAINER_AMOUNT = 'RETAINER_AMOUNT',
  SECONDARY_INDUSTRIES = 'SECONDARY_INDUSTRIES',
  SHOWN_INTEREST = 'SHOWN_INTEREST',
  STATE = 'STATE',
  STATUS = 'STATUS',
  TAIL_PERIOD_DURATION = 'TAIL_PERIOD_DURATION',
  TYPE = 'TYPE',
}

export enum DealMode {
  light = 'light',
  regular = 'regular',
}

export enum DealModeEnum {
  Light = 'Light',
  Regular = 'Regular',
}

export enum DealType {
  ASSET_SALE = 'ASSET_SALE',
  CA_RA_COR = 'CA_RA_COR',
  CA_RA_EB5 = 'CA_RA_EB5',
  CA_RA_FUN = 'CA_RA_FUN',
  CONS_ENGAGEMENT = 'CONS_ENGAGEMENT',
  D_P_P = 'D_P_P',
  M_N_A_B = 'M_N_A_B',
  M_N_A_S = 'M_N_A_S',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN',
}

export enum DealTypeEnum {
  ASSET_SALE = 'ASSET_SALE',
  CA_RA_COR = 'CA_RA_COR',
  CA_RA_EB5 = 'CA_RA_EB5',
  CA_RA_FUN = 'CA_RA_FUN',
  CONS_ENGAGEMENT = 'CONS_ENGAGEMENT',
  D_P_P = 'D_P_P',
  M_N_A_B = 'M_N_A_B',
  M_N_A_S = 'M_N_A_S',
  OTHER = 'OTHER',
}

export enum DownloadFsosTransactionType {
  original = 'original',
  watermark = 'watermark',
}

export enum ExternalProvider {
  GOOGLE = 'GOOGLE',
  LINKEDIN = 'LINKEDIN',
}

export enum FormType {
  AMNel = 'AMN_EL',
  AOQ = 'AOQ',
  CDOC = 'CDOC',
  COMMS = 'COMMS',
  DCR = 'DCR',
  DRA = 'DRA',
  EL = 'EL',
  FSAext = 'FSA_EXT',
  FSAint = 'FSA_INT',
  INV = 'INV',
  KYC = 'KYC',
  KYCcl = 'KYC_CL',
  KYCco = 'KYC_CO',
  MMAT = 'MMAT',
  MMF = 'MMF',
  MMR = 'MMR',
  NDR = 'NDR',
  OBA = 'OBA',
  OBAcct = 'OBAcct',
  PST = 'PST',
  SE = 'SE',
  U4 = 'U4',
}

export enum FundFilterEnum {
  CALCULATED_PERCENT_RATE = 'CALCULATED_PERCENT_RATE',
  COUNTRY = 'COUNTRY',
  EXPECTED_SIZE = 'EXPECTED_SIZE',
  ID = 'ID',
  LOCATION = 'LOCATION',
  MARKETPLACE_DEAL_ID = 'MARKETPLACE_DEAL_ID',
  NON_SHOWN_INTEREST_YET = 'NON_SHOWN_INTEREST_YET',
  OWNER_ID = 'OWNER_ID',
  PRIMARY_INDUSTRY = 'PRIMARY_INDUSTRY',
  PUBLISHED_AT = 'PUBLISHED_AT',
  SECONDARY_INDUSTRIES = 'SECONDARY_INDUSTRIES',
  SHOWN_INTEREST = 'SHOWN_INTEREST',
  STATE = 'STATE',
  TAIL_PERIOD_DURATION = 'TAIL_PERIOD_DURATION',
}

export enum GeneralFilterTypeEnum {
  between = 'between',
  equal = 'equal',
  greaterOrEqualThan = 'greaterOrEqualThan',
  greaterThan = 'greaterThan',
  in = 'in',
  like = 'like',
  lowerOrEqualThan = 'lowerOrEqualThan',
  lowerThan = 'lowerThan',
  notEqual = 'notEqual',
  notIn = 'notIn',
  rangeBetween = 'rangeBetween',
}

export enum GeneralSortTypeEnum {
  asc = 'asc',
  desc = 'desc',
}

export enum GroupType {
  buy = 'buy',
  buyOrganizations = 'buyOrganizations',
  deal = 'deal',
  organization = 'organization',
  sell = 'sell',
  sellOrganizations = 'sellOrganizations',
}

export enum LinkContext {
  vdr = 'vdr',
}

export enum LinkEntityTypes {
  fso = 'fso',
}

export enum MarketplaceDealType {
  Collaborative = 'Collaborative',
  NonCollaborative = 'NonCollaborative',
  Private = 'Private',
}

export enum MetricType {
  decimal = 'decimal',
  number = 'number',
  string = 'string',
}

export enum NoteEntity {
  FORM = 'FORM',
}

export enum NoteType {
  NOTE = 'NOTE',
  REVIEWED_COMPLIANCE_OPS = 'REVIEWED_COMPLIANCE_OPS',
  REVIEWED_COMPLIANCE_OPS_UNCHECKED = 'REVIEWED_COMPLIANCE_OPS_UNCHECKED',
  REVIEWER_ASSIGNED = 'REVIEWER_ASSIGNED',
  REVIEWER_UNASSIGNED = 'REVIEWER_UNASSIGNED',
  STATUS_CHANGE = 'STATUS_CHANGE',
}

export enum OrderType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum Sides {
  buy = 'buy',
  sell = 'sell',
}

export enum SignatureStatus {
  pending = 'pending',
  signed = 'signed',
}

export enum StatusCollaborationEnum {
  CLOSED = 'CLOSED',
  PENDING = 'PENDING',
}

export enum UploadFsosTransactionType {
  attachment = 'attachment',
  upload = 'upload',
}

export enum UserRoleInOfferingEnum {
  OFFERING_FIRM_MEMBER = 'OFFERING_FIRM_MEMBER',
  REQUESTER_FIRM_MEMBER = 'REQUESTER_FIRM_MEMBER',
}

export enum UserRoles {
  compliance = 'compliance',
  compliance_officer_approver = 'compliance_officer_approver',
  compliance_ops = 'compliance_ops',
  customer_success = 'customer_success',
  finance = 'finance',
  god = 'god',
  legal = 'legal',
  sales_growth = 'sales_growth',
  user = 'user',
}

export interface AdditionalDataInput {
  identifier?: string | null;
  data?: string | null;
}

export interface AttestInput {
  userId: string;
  attestation: string;
}

export interface BankAccountInput {
  bankName: string;
  bankAccountName: string;
  bankAccountNumber: string;
  abaNumber: string;
  attention?: string | null;
  swiftCode?: string | null;
  furtherBankAccountName?: string | null;
  furtherBankAccountNumber?: string | null;
}

export interface CollaborateInput {
  type: CollaborateTypeEnum;
  comment: string;
}

export interface CreateNoteInput {
  type: NoteType;
  content: string;
  entity: NoteEntity;
  entityId: string;
  private: boolean;
  attachments?: any[] | null;
  origin?: string | null;
}

export interface CreateUserInput {
  email: string;
  role: string;
  side: string;
  organizationId: string;
}

export interface DateRange {
  startDate: any;
  endDate: any;
}

export interface DealMetadataInput {
  dealId?: string | null;
  dealName: string;
  dealStatus: string;
  loiProvided?: boolean | null;
  closingDate: any;
  expectedCommissions: number;
  expectedSize: number;
  deletedAt?: any | null;
}

export interface DealMetadataV2Input {
  key: string;
  type: string;
  value?: string | null;
}

export interface DeleteAttachmentInput {
  id?: string | null;
  link?: string | null;
}

export interface EventInput {
  context: string;
  userId: string;
  dealId?: string | null;
  type: string;
  description: string;
  relatedModelId?: string | null;
  relatedModelType?: string | null;
  additionalData?: AdditionalDataInput | null;
}

export interface ExternalSignUp {
  provider: ExternalProvider;
  token: string;
}

export interface FilterInput {
  filterType?: GeneralFilterTypeEnum | null;
  values?: string[] | null;
  value?: string | null;
  from?: string | null;
  to?: string | null;
  filterName: DealFilterEnum;
}

export interface FormContentInput {
  question: string;
  input: any;
  key?: string | null;
  value?: string | null;
}

export interface FormSearchQuery {
  generalFilter?: (GeneralFilter | null)[] | null;
  dateRange?: DateRange | null;
  natureOfBusiness?: string | null;
  repName?: string | null;
  dealName?: string | null;
  entityName?: string | null;
  compensationType?: string | null;
  isActive?: any | null;
  assignedTo?: string | null;
  status?: (string | null)[] | null;
  type?: FormType | null;
  userId?: string | null;
}

export interface FundFilterInput {
  filterType?: GeneralFilterTypeEnum | null;
  values?: string[] | null;
  value?: string | null;
  from?: string | null;
  to?: string | null;
  filterName: FundFilterEnum;
}

export interface FundSortInput {
  sortType?: GeneralSortTypeEnum | null;
  sortName: FundFilterEnum;
}

export interface GeneralFilter {
  field?: string | null;
  value?: string | null;
}

export interface GiftReportInput {
  receivedGivenGifts: boolean;
  receivedGivenGiftsDetail?: string | null;
  hostedEvents: boolean;
  hostedEventsDetail?: string | null;
  charitableContributions: boolean;
  charitableContributionsDetail?: string | null;
}

export interface ImportedFiles {
  name: string;
  mimeType: string;
  url: string;
  type: string;
  id: string;
}

export interface LinkEntity {
  id: string;
  type: LinkEntityTypes;
}

export interface LinkInput {
  context: LinkContext;
  entities?: (LinkEntity | null)[] | null;
}

export interface LocalSignUp {
  firstName: string;
  lastName: string;
  password: string;
}

export interface MarketplaceCalculatedPercentRateInput {
  min?: number | null;
  max?: number | null;
}

export interface MarketplaceDealInput {
  id?: string | null;
  name?: string | null;
  type: DealTypeEnum;
  mode?: DealModeEnum | null;
  projectName?: string | null;
  companyName?: string | null;
  companyAddress?: string | null;
  companyAgent?: string | null;
  status: string;
  loiProvided?: boolean | null;
  published?: boolean | null;
  marketplaceDealType?: MarketplaceDealType | null;
  expectedCommissions?: number | null;
  expectedSize?: number | null;
  primaryIndustry?: string | null;
  secondaryIndustries?: string[] | null;
  companyProfile?: string | null;
  description?: string | null;
  retainerAmount?: number | null;
  tailPeriodDuration?: number | null;
  calculatedPercentRate?: MarketplaceCalculatedPercentRateInput | null;
  country: string;
  state?: string | null;
  engagementLetterSignedDate?: any | null;
}

export interface MarketplaceGrossFeeEarnedInput {
  min?: number | null;
  max?: number | null;
}

export interface NonComplianceMarketplaceDealInput {
  id?: string | null;
  name?: string | null;
  type: DealTypeEnum;
  mode?: DealModeEnum | null;
  projectName?: string | null;
  companyName?: string | null;
  companyAddress?: string | null;
  companyAgent?: string | null;
  status: string;
  loiProvided?: boolean | null;
  published?: boolean | null;
  marketplaceDealType?: MarketplaceDealType | null;
  expectedCommissions?: number | null;
  expectedSize?: number | null;
  primaryIndustry?: string | null;
  secondaryIndustries?: string[] | null;
  companyProfile?: string | null;
  description?: string | null;
  retainerAmount?: number | null;
  tailPeriodDuration?: number | null;
  calculatedPercentRate?: MarketplaceCalculatedPercentRateInput | null;
  country: string;
  state?: string | null;
  engagementLetterSignedDate?: any | null;
}

export interface PaginationInput {
  skip?: number | null;
  limit?: number | null;
}

export interface PublishMarketplaceDealInput {
  primaryIndustry?: string | null;
  secondaryIndustries?: string[] | null;
  companyProfile: string;
  description: string;
  retainerAmount?: number | null;
  tailPeriodExpiration?: any | null;
  tailPeriodDuration?: number | null;
  loiSignedDate?: any | null;
  engagementLetterSignedDate?: any | null;
  grossFeedEarned?: MarketplaceGrossFeeEarnedInput | null;
  calculatedPercentRate?: MarketplaceCalculatedPercentRateInput | null;
}

export interface SessionInput {
  context: string;
  userId: string;
  dealId?: string | null;
  type: string;
  description?: string | null;
  startedAt: string;
  finishedAt: string;
  additionalInfo?: string | null;
}

export interface SortInput {
  sortType?: GeneralSortTypeEnum | null;
  sortName: DealFilterEnum;
}

export interface SortParams {
  sortBy?: string | null;
  type?: OrderType | null;
}

export interface StatesInput {
  states: (string | null)[];
}

export interface UpdateCheckInStepInput {
  checkInRecordId: string;
  key: string;
  checked: boolean;
}

export interface UpdateFormInput {
  id: string;
  status: string;
  note?: string | null;
  isReview?: boolean | null;
  isReviewedByCO?: boolean | null;
}

export interface UpdateProfileInput {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
}

export interface UpdateUserRoleInput {
  dealId: string;
  userId: string;
  role: string;
}

export interface UploadAttachmentOptions {
  links: LinkInput;
  threadId: string;
}

export interface UploadFile {
  base64: string;
  fileName: string;
}

export interface UploadFsosNodes {
  name: string;
  type: string;
  correlation: string;
  children?: (UploadFsosNodes | null)[] | null;
}

export interface UploadFsosOptions {
  type?: UploadFsosTransactionType | null;
  attachment?: UploadAttachmentOptions | null;
}

export interface UserMetadataInput {
  value: UserMetadataValueInput;
  userId: string;
}

export interface UserMetadataValueInput {
  key: string;
  type: string;
  value: string;
}

/**
 * Only one startingAfter or endingBefore will be allowed
 */
export interface stripeCustomerInvoicesInput {
  customerId: string;
  limit?: number | null;
  startingAfter?: string | null;
  endingBefore?: string | null;
  expandSubscription?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
