import * as React from 'react';
import { IconProps } from './types';

export const InfoIcon = ({
  width = '20',
  height = '20',
  color = 'currentColor',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 7.00037C9.73478 7.00037 9.48043 7.10572 9.29289 7.29326C9.10536 7.4808 9 7.73515 9 8.00037V11.0004C9 11.2656 9.10536 11.5199 9.29289 11.7075C9.48043 11.895 9.73478 12.0004 10 12.0004C10.2652 12.0004 10.5196 11.895 10.7071 11.7075C10.8946 11.5199 11 11.2656 11 11.0004V8.00037C11 7.73515 10.8946 7.4808 10.7071 7.29326C10.5196 7.10572 10.2652 7.00037 10 7.00037ZM17 0.000366211H3C2.20435 0.000366211 1.44129 0.316437 0.87868 0.879046C0.316071 1.44166 0 2.20472 0 3.00037V13.0004C0 13.796 0.316071 14.5591 0.87868 15.1217C1.44129 15.6843 2.20435 16.0004 3 16.0004H14.59L18.29 19.7104C18.3834 19.803 18.4943 19.8764 18.6161 19.9261C18.7379 19.9759 18.8684 20.0011 19 20.0004C19.1312 20.0037 19.2613 19.9763 19.38 19.9204C19.5626 19.8453 19.7189 19.7179 19.8293 19.5542C19.9396 19.3905 19.999 19.1978 20 19.0004V3.00037C20 2.20472 19.6839 1.44166 19.1213 0.879046C18.5587 0.316437 17.7956 0.000366211 17 0.000366211ZM18 16.5904L15.71 14.2904C15.6166 14.1977 15.5057 14.1244 15.3839 14.0746C15.2621 14.0248 15.1316 13.9996 15 14.0004H3C2.73478 14.0004 2.48043 13.895 2.29289 13.7075C2.10536 13.5199 2 13.2656 2 13.0004V3.00037C2 2.73515 2.10536 2.4808 2.29289 2.29326C2.48043 2.10572 2.73478 2.00037 3 2.00037H17C17.2652 2.00037 17.5196 2.10572 17.7071 2.29326C17.8946 2.4808 18 2.73515 18 3.00037V16.5904ZM10 4.00037C9.80222 4.00037 9.60888 4.05902 9.44443 4.1689C9.27998 4.27878 9.15181 4.43496 9.07612 4.61768C9.00043 4.80041 8.98063 5.00148 9.01921 5.19546C9.0578 5.38944 9.15304 5.56762 9.29289 5.70747C9.43275 5.84733 9.61093 5.94257 9.80491 5.98115C9.99889 6.01974 10.2 5.99993 10.3827 5.92425C10.5654 5.84856 10.7216 5.72039 10.8315 5.55594C10.9414 5.39149 11 5.19815 11 5.00037C11 4.73515 10.8946 4.4808 10.7071 4.29326C10.5196 4.10572 10.2652 4.00037 10 4.00037Z"
      fill={color}
    />
  </svg>
);
