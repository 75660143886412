export const KYCLabels = {
  SECTION_A: 'SECTION A',
  NAME: '1. Your Name',
  EMAIL: '2. Your Email',
  ENTITY_NAME: '3. Your Company Name',
  TITLE: '4. Your Title',
  SECTION_B: 'SECTION B',
  TRANSACTION_STRUCTURE: '5. Likely Deal Type',
  SECTION_C: 'SECTION C',
  COUNTERPART_DEAL: '6. Deal Counterpart',
  COUNTERPART_ENTITY_NAME: '7. Counterpart Company Name',
  COUNTERPART_ENTITY_LEGAL_NAME: '8. Counterpart Company Legal Name',
  COUNTERPART_CONTACT_INFO: '9. Counterpart Contact Information',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  CONTACT_EMAIL: 'Email',
  CONTACT_TITLE: 'Title',
  COMMENTS: '10. Add any other comments:',
};

export const placeholders = {
  NAME: 'Name',
  EMAIL: 'Email',
  ENTITY_NAME: 'Company Name',
  TITLE: 'Title',
  OTHER_STRUCTURE: 'Please type another option here',
  COUNTERPART_ENTITY_NAME: 'Counterpart Company Name - e.g., DBA, Project Name, Subsidiary. etc.',
  COUNTERPART_ENTITY_LEGAL_NAME: 'Counterpart Company Legal Name',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  CONTACT_EMAIL: 'Email',
  CONTACT_TITLE: 'Title',
};

export const dealCounterpart = [
  {
    value: 'Issuer/Target',
    label: 'Issuer/Target',
  },
  {
    value: 'Acquirer/Investor',
    label: 'Acquirer/Investor',
  },
];

export const transactionStructures = [
  {
    value: 'Merger or Acquisition',
    label: 'Merger or Acquisition',
  },
  {
    value: 'Capital Raise or Private Placement [504 or 506(b)]',
    label: 'Capital Raise or Private Placement [504 or 506(b)]',
  },
  {
    value: 'Capital Raise or Private Placement [506(c)]',
    label: 'Capital Raise or Private Placement [506(c)]',
  },
  {
    value: 'Capital Raise or Private Placement [TBD]',
    label: 'Capital Raise or Private Placement [TBD]',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];
