import { gql } from '@apollo/client';

import { GroupFragment, GroupSettingsFragment } from './fragments';

export const GET_USER = gql`
  query GET_USER_QUERY($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query GET_USER_PROFILE_QUERY {
    profile {
      id
      firstName
      lastName
      displayName
      email
      dealId
      role
      side
      lastLoginInDealAt
      mfaEnabled
      hasPassword
      isCustomer
      crdNumber
      metadata {
        key
        type
        value
      }
      organization {
        id
        name
      }
      firms {
        id
        name
        logo
        website
        status
        firmMembersCount
      }
      permissions {
        vdr {
          createFileRoom
          setPermission
        }
      }
      bankInfo {
        bankName
        bankAccountName
        bankAccountNumber
        abaNumber
        attention
        swiftCode
        furtherBankAccountName
        furtherBankAccountNumber
      }
      stateRegInfo
      createdAt
      lastLoginAt
    }
  }
`;

export const GET_GROUPS = gql`
  query GET_GROUPS_QUERY(
    $dealId: ID!
    $onlyActiveGroups: Boolean
    $onlyGroupsWithMembers: Boolean
  ) {
    groups(
      dealId: $dealId
      onlyActiveGroups: $onlyActiveGroups
      onlyGroupsWithMembers: $onlyGroupsWithMembers
    ) {
      ...GroupFragment
    }
  }
  ${GroupFragment}
`;

export const GET_PERMISSION_ENTITIES = gql`
  query GET_PERMISSION_ENTITIES_QUERY($dealId: ID!, $side: String, $context: String) {
    permissionEntities(dealId: $dealId, context: $context, side: $side) {
      id
      name
      displayName
      description
      context
      restrictions {
        sides
      }
    }
  }
`;

export const GET_PERMISSIONS = gql`
  query GET_PERMISSIONS_QUERY($entityIds: [ID!]!) {
    permissions(entityIds: $entityIds) {
      id
      entityId
      permissions {
        entityId
        id
        ownerId
        permissionEntityId
        active
      }
    }
  }
`;

export const GET_INVITATION_GROUPS = gql`
  query GET_INVITATION_GROUPS_QUERY($dealId: ID!, $types: [GroupType], $onlyActiveGroups: Boolean) {
    groups(dealId: $dealId, types: $types, onlyActiveGroups: $onlyActiveGroups) {
      ...GroupSettingsFragment
    }
  }
  ${GroupSettingsFragment}
`;

export const CHECK_SIGNUP_CODE = gql`
  query CHECK_SIGNUP_CODE_QUERY($code: String!) {
    checkSignUpCode(code: $code) {
      status
      statusText
      email
    }
  }
`;

export const GET_STRIPE_CUSTOMER = gql`
  query GET_STRIPE_CUSTOMER($customerId: String!) {
    getStripeCustomer(customerId: $customerId) {
      id
      defaultSource
      invoiceSettings {
        defaultPaymentMethod
      }

      name
      subscriptions {
        id
        currentPeriodEnd
        currentPeriodStart
        items {
          id
          plan {
            amount
            currency
            id
            interval
            intervalCount
          }
        }
      }
      sources {
        __typename
        ... on BankAccount {
          bankName
          currency
          id
          last4
          holderName
        }
        ... on Card {
          id
          brand
          expMonth
          expYear
          last4
          holderName
        }
      }
    }
  }
`;

export const GET_CARD_PAYMENT_METHODS = gql`
  query GET_CARD_PAYMENT_METHODS($customerId: String!) {
    getCardPaymentMethods(customerId: $customerId) {
      id
      card {
        brand
        expMonth
        expYear
        last4
      }
    }
  }
`;

export const GET_USER_PAYMENT_CONFIG = gql`
  query GET_USER_PAYMENT_CONFIG($userId: ID) {
    getUserPaymentConfig(userId: $userId) {
      stripeCustomerId
    }
  }
`;

export const GET_STRIPE_CUSTOMER_INVOICES = gql`
  query GET_STRIPE_CUSTOMER_INVOICES($input: stripeCustomerInvoicesInput!) {
    getStripeCustomerInvoices(input: $input) {
      invoices {
        id
        currency
        total
        status
        dueDate
        number
        defaultSource
        defaultPaymentMethod
        subscription {
          __typename
          ... on StripeCustomerSubscription {
            id
            currentPeriodEnd
            currentPeriodStart
            items {
              id
              plan {
                amount
                currency
                id
                interval
                intervalCount
              }
            }
          }
        }
        invoicePdf
      }
      hasMore
    }
  }
`;

export const COUNT_STRIPE_CUSTOMER_INVOICES = gql`
  query COUNT_STRIPE_CUSTOMER_INVOICES($customerId: String!) {
    countStripeCustomerInvoices(customerId: $customerId) {
      invoiceIds
      totalCount
    }
  }
`;

export const GET_ADDITIONAL_FEE = gql`
  query FEES_FILE {
    getFeesFileUrl
  }
`;
