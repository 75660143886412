import React from 'react';

// @poki
import { Button, HStack, SideDrawer } from '../../../../common/components';

import { PSTForm } from './PSTForm';
import { usePSTFormHandler, PSTFormHandlerProvider } from './PSTFormHandler';

const PSTFormDrawer = () => {
  const handler = usePSTFormHandler();
  const { onSubmitPSTForm, submitting, form, modal, isEdit } = handler;
  const { modalState, hide } = modal;

  const hideModal = () => {
    hide();
    form.resetForm();
  };

  return (
    <PSTFormHandlerProvider value={handler}>
      <SideDrawer
        show={modalState.show}
        onClose={hideModal}
        title="Add a private security transaction"
        footerContent={({ onClose: promptClose }) => (
          <HStack spacing={16} justifyContent="flex-end">
            <Button className="tertiary" onClick={promptClose}>
              Dismiss
            </Button>
            <Button disabled={submitting || !form.isValid} onClick={onSubmitPSTForm}>
              {isEdit ? 'Save' : 'Submit'}
            </Button>
          </HStack>
        )}
        closeWhenClickOutside={false}
        promptOnClose={form.dirty}
      >
        <PSTForm />
      </SideDrawer>
    </PSTFormHandlerProvider>
  );
};

export default PSTFormDrawer;
