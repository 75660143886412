import styled from 'styled-components';
import { Tooltip as PokiTooltip } from '@dealsyte/poki';

export const Tooltip = styled(PokiTooltip)`
  ${({ theme }) => {
    const { colors, typography, spacing } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['02']};
      background-color: ${colors.palette.neutral[900]};
      color: ${colors.text.buttons};
      padding: ${spacing['02']};
      max-width: none;
    `;
  }}
`;
