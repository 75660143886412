import styled from 'styled-components';

export const Text = styled.div`
  ${({ theme }) => {
    const { colors, typography } = theme.foundations;
    return `
      color: ${colors.palette.neutral[600]};
      font-size: ${typography.sizes['04']};
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      text-align: justify;
      text-justify: inter-word;
      margin-bottom: 18px;    
      line-height: 150%;
      letter-spacing: 0.25px;
    `;
  }}
`;

export const Label = styled.div`
  ${({ theme }) => {
    const { colors, typography } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['03']};
      color: ${colors.text.default};
      margin-bottom: 24px;
      text-align: justify;
      text-justify: inter-word;
    `;
  }}
`;

export const OptionsContainer = styled.div`
  margin-top: '32px';
`;

export const OptionContainer = styled.div`
  ${({ theme }) => {
    const { colors } = theme.foundations;
    return `
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 24px 28px 24px 24px;
      width: 512px;
      height: 93px;
      margin-top: 16px;
      border: 1px solid ${colors.palette.neutral[200]};
      border-radius: 6px;
      flex: none;
      order: 4;
      align-self: stretch;
      flex-grow: 0;
      cursor: pointer;

      &.blocked {
        background: ${colors.palette.neutral[100]};
        cursor: default;

        .subtitle {
          color: ${colors.palette.neutral[400]}
        }
      }
    `;
  }}
`;

export const IconContainer = styled.div`
  margin-right: 13px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Title = styled.div`
  ${({ theme }) => {
    const { colors, typography } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.medium};
      font-size: ${typography.sizes['04']};
      line-height: 150%;
      letter-spacing: 0.25px;
      color: ${colors.palette.neutral[600]};
    `;
  }}
`;

export const Subtitle = styled.div`
  ${({ theme }) => {
    const { colors, typography } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['03']};
      line-height: 150%;
      color: ${colors.palette.neutral[500]};
      letter-spacing: 0.25px;
      flex: none;
      order: 1;
      flex-grow: 0;
    `;
  }}
`;

export const OptionLabel = styled.label`
  white-space: nowrap;
`;

export const DrawerTitle = styled.div`
  ${({ theme }) => {
    const { colors, typography, spacing } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.bold};
      font-size: ${typography.sizes['05']};
      line-height: 125%;
      letter-spacing: 0.25px;
      color: ${colors.palette.neutral[600]};
      margin: ${spacing['07']} 0 ${spacing['04']} 0;
  `;
  }}
`;

export const CustomOption = styled.div`
  ${({ theme }) => {
    const { colors, typography } = theme.foundations;
    return `  
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: ${colors.text.default};
      font-family: ${typography.fonts.body};
    `;
  }}
`;

export const TitleOption = styled.label`
  ${({ theme }) => {
    const { typography, spacing } = theme.foundations;
    return ` 
      font-weight: ${typography.weights.medium}};
      font-size: ${typography.sizes['04']};
      line-height: 24px;
      letter-spacing: 0.25px;
      margin-bottom: ${spacing['01']};
      text-transform: none;
    `;
  }}
`;

export const DescriptionOption = styled.p`
  ${({ theme }) => {
    const { typography } = theme.foundations;
    return `
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['03']};
      line-height: 21px;
      letter-spacing: 0.25px;
      width: 368px;
      text-transform: none;
    `;
  }}
`;

export const EntityLegalNameStyled = styled.p`
  ${({ theme }) => {
    const { typography, colors } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['03']};
      line-height: 150%;
      letter-spacing: 0.25px;
      color: ${colors.palette.neutral[500]};
      flex: none;
      order: 0;
      flex-grow: 0;
  `;
  }}
`;

export const EntityNameStyled = styled.p`
  ${({ theme }) => {
    const { typography, colors } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['04']};
      line-height: 150%;
      letter-spacing: 0.25px;
      color: ${colors.palette.neutral[800]};
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-top: 16px;
      margin-bottom: 28px
  `;
  }}
`;

export const EntityNameRequiredStyled = styled.label`
  ${({ theme }) => {
    const { typography, colors } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['02']};
      line-height: 150%;
      letter-spacing: 0.25px;
      color: ${colors.palette.neutral[500]};
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-top: 16px;
      padding-left: 8px
  `;
  }}
`;
