import * as React from 'react';

import { IconProps } from './types';

export const SearchIcon = ({ width = '14px', height = '14px', color = '#807DA1' }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
  >
    <path
      d="m13.803 12.82-2.595-2.577a6.305 6.305 0 0 0-.71-8.634A6.294 6.294 0 0 0 .002 6.129 6.304 6.304 0 0 0 5.78 12.58a6.293 6.293 0 0 0 4.455-1.364l2.575 2.576a.701.701 0 0 0 .993 0 .7.7 0 0 0 0-.973Zm-7.492-1.604a4.894 4.894 0 0 1-4.524-3.025A4.904 4.904 0 0 1 5.356 1.51a4.894 4.894 0 0 1 5.027 2.084 4.903 4.903 0 0 1-4.072 7.623Z"
      fill={color}
    />
  </svg>
);
