import { ChevronIcon } from 'app/common/icons';
import React, { useEffect, useRef, useState } from 'react';
import * as S from './CollapsibleStyled';

interface CollapsibleProps {
  label?: string | React.ReactNode;
  children?: React.ReactNode;
  updateMaxHeightOn?: any;
  initAs?: boolean;
  rightArrow?: boolean;
  withBorder?: boolean;
  isActive?: boolean;
  isTab?: boolean;
  style?: React.CSSProperties;
}

export const Collapsible = ({
  label,
  children,
  initAs = false,
  updateMaxHeightOn,
  rightArrow = false,
  withBorder = true,
  isActive = false,
  isTab = false,
  style,
}: CollapsibleProps) => {
  const contentRef = useRef<HTMLDivElement>();
  const [open, setOpen] = useState(initAs);
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    const element = contentRef.current;
    if (element) {
      setMaxHeight(element.clientHeight);
    }
  }, [setMaxHeight, updateMaxHeightOn]);

  const toggle = () => {
    setOpen(state => !state);
  };

  const CollapsibleContainer = (
    <S.CollapsibleIconContainer className={open ? 'open' : ''}>
      <ChevronIcon />
    </S.CollapsibleIconContainer>
  );

  return (
    <S.CollapsibleContainer withBorder={withBorder} style={style}>
      <S.CollapsibleButton onClick={toggle} isActive={isActive} className={isTab ? 'isTab' : ''}>
        {!rightArrow && CollapsibleContainer}
        {label}
        {rightArrow && CollapsibleContainer}
      </S.CollapsibleButton>
      <S.CollapsibleContent className={open ? 'open' : ''} maxContentHeight={maxHeight}>
        <div ref={contentRef as any}>{children}</div>
      </S.CollapsibleContent>
    </S.CollapsibleContainer>
  );
};
