import * as yup from 'yup';
import get from 'lodash/get';
import {
  REQUIRED_MESSAGE,
  basicRequiredInputValidation,
} from '../../../../../../utils/helpers/formValidators';

const commonSchema = {
  typesCounterparty: yup
    .boolean()
    .nullable()
    .required(REQUIRED_MESSAGE),
  entityName: yup.string().when('typesCounterparty', {
    is: true,
    then: basicRequiredInputValidation(),
  }),
  fullName: basicRequiredInputValidation(),
  emailAddress: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .email('Email address must be a valid email'),
  title: basicRequiredInputValidation(),
};

const noCapitalRaiseSchema = yup.object().shape({
  ...commonSchema,
});

const capitalRaiseSchema = yup.object().shape({
  ...commonSchema,
  connection: yup
    .object()
    .required(REQUIRED_MESSAGE)
    .test('', '', option => !!option && !!option.label && !!option.value),
  yearsKnowledge: yup.string().when('connection', {
    is: connection => get(connection, 'label') === 'Pre-existing relationship - rule 506(b)',
    then: basicRequiredInputValidation(),
  }),
  relationshipNature: yup.string().when('connection', {
    is: connection => get(connection, 'label') === 'Pre-existing relationship - rule 506(b)',
    then: basicRequiredInputValidation(),
  }),
  connectionAmplify: yup.string().when('connection', {
    is: connection => get(connection, 'label') === 'Other',
    then: basicRequiredInputValidation(),
  }),
});

export const validationSchema = {
  noCapitalRaiseSchema,
  capitalRaiseSchema,
};
