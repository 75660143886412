// Libraries
import React from 'react';
import PropTypes from 'prop-types';

const MinusIcon = props => (
  <svg width={props.width} height={props.height} fill="none">
    <path
      d="M10.6667 0.333984H1.33332C1.15651 0.333984 0.986943 0.404222 0.861919 0.529247C0.736895 0.654271 0.666656 0.82384 0.666656 1.00065C0.666656 1.17746 0.736895 1.34703 0.861919 1.47206C0.986943 1.59708 1.15651 1.66732 1.33332 1.66732H10.6667C10.8435 1.66732 11.013 1.59708 11.1381 1.47206C11.2631 1.34703 11.3333 1.17746 11.3333 1.00065C11.3333 0.82384 11.2631 0.654271 11.1381 0.529247C11.013 0.404222 10.8435 0.333984 10.6667 0.333984Z"
      fill={props.color}
    />
  </svg>
);

MinusIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

MinusIcon.defaultProps = {
  width: '12px',
  height: '12px',
  color: '#47455F',
};

export default MinusIcon;
