import styled from 'styled-components';

export const SeparatorLine = styled.div`
  ${({ theme }) => {
    const { colors, spacing } = theme.foundations;
    return `
      margin-bottom: ${spacing['06']};
      border: 1px solid ${colors.palette.neutral['200']};
  `;
  }}
`;

export const FormContent = styled.div`
  ${({ theme }) => {
    const { typography, colors, spacing, radii, sizes } = theme.foundations;
    return `
      .filter-box {
        font-family: ${typography.fonts.body};
        font-weight: ${typography.weights.regular};
        margin-bottom: ${spacing['06']};

        .filter-label {
          font-size: ${typography.sizes['05']};
          letter-spacing: 0.25px;
          color: ${colors.palette.neutral['600']};
          line-height: 24px;
          padding-bottom: ${spacing['05']};
        }

        .filter-retainer {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            font-size: ${typography.sizes['04']};
            line-height: 24px;
          }
        }
      }
      .button-container {
        bottom: 0;
        flex-grow: 1;
        flex-direction: row;
        justify-content: flex-end;
        align-self: flex-start;
        background-color: ${colors.palette.white};
        .filter-button {
          width: 512px;
          height: ${sizes['09']};
          border-radius: ${radii.md};
          background-color: ${colors.palette.neutral['600']};
          justify-content: center;
          display: flex;
          align-items: center;
          cursor: pointer;
          color: ${colors.palette.neutral['50']};
          :disabled {
            background-color: ${colors.palette.neutral['200']};
            color: ${colors.palette.neutral['500']};
          }
          p {
            font-size: ${typography.sizes['04']};
            font-family: ${typography.fonts.body};
            letter-spacing: 0.5px;
          }
        }
        .icon-button {
          display: inline-block;
          padding-right: ${spacing['02']};
          margin-top: -3px;
        }
        p {
          font-family: ${typography.fonts.body};
          font-weight: ${typography.weights.regular};
          font-size: ${typography.sizes['04']};
          line-height: 16px;
          letter-spacing: 0.5px;
          display: inline-block;
        }
      }
  `;
  }}
`;
