import React from 'react';

// components
import { Input, InputProps } from '../index';

// icons
import { SearchIcon } from '../../icons';

export const SearchInput = React.forwardRef<React.MutableRefObject<HTMLInputElement>, InputProps>(
  (props, ref) => {
    const { placeholder = 'Search', style, ...rest } = props;

    return (
      <Input
        ref={ref as any}
        name="searchBar"
        type="text"
        placeholder={placeholder}
        rightIcon={<SearchIcon />}
        style={{ backgroundColor: '#F8F8FC', color: '#6A678E', borderRadius: '6px', ...style }}
        {...rest}
      />
    );
  }
);
