import styled from 'styled-components';
import { NavLink as RRLink } from 'react-router-dom';
import { Button } from '@dealsyte/poki';

const MainContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  background-color: #eff0f2;
  height: 100%;
  width: 240px;
`;

const CollapsableSubsectionsContainer = styled.div<{ maxHeight: number | string }>`
  overflow: ${({ maxHeight }) => (maxHeight === 'auto' ? undefined : 'hidden')};
  transition: max-height ease-in-out 0.3s;
  max-height: ${({ maxHeight }) => (typeof maxHeight === 'string' ? maxHeight : `${maxHeight}px`)};
  height: auto;
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  color: #c9c9c9;
  margin-bottom: 16px;
  word-break: break-word;
`;

const SectionSeparatorHr = styled.hr`
  box-sizing: border-box;
  height: 1px;
  margin: 16px -24px;
  border: 1px solid #e2e3e5;
`;

const Link = styled(RRLink)`
  color: #304154;
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  letter-spacing: 0.27px;
  line-height: 16px;
  text-decoration: none;
  margin-left: 62px;
`;

const Anchor = styled.a`
  color: #304154;
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  letter-spacing: 0.27px;
  line-height: 16px;
  text-decoration: none;
  cursor: pointer;
`;

// @ts-ignore
const CreateFileroomButton = styled(Button)`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  background-image: none;
  border: none;
  color: #9299a0;
  font-size: 20px;
  cursor: pointer;
  border-radius: 2px;

  &:hover {
    background-color: #e3e3e3;
  }
`;

//@ts-ignore
const ExpandSectionButton = styled(Button)`
  opacity: 0;
  display: flex;
  align-items: center;
  margin-left: 8px;
  background-image: none;
  border: none;
  color: #9299a0;
  font-size: 20px;
  cursor: pointer;
  border-radius: 2px;
  transition: opacity 0.2s;

  ${LinkContainer}:hover & {
    opacity: 1;
  }

  &:hover {
    background-color: #e3e3e3;
  }
`;

export {
  MainContainer,
  CollapsableSubsectionsContainer,
  SectionSeparatorHr,
  LinkContainer,
  Link,
  Anchor,
  CreateFileroomButton,
  ExpandSectionButton,
};
