import React from 'react';
import { ThemeProvider as DesignSystemThemeProvider } from '@dealsyte/fns-design-system';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { DragDropContextProvider } from 'react-dnd';
import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import HTML5Backend from 'react-dnd-html5-backend';

import { AlertContextProvider } from 'app/notifications/context/Alert/Alert';
import { TransactionBarContextProvider } from 'app/core-tools/due-diligence/vdr/transactions/context/TransactionBarContext';
import { ErrorBoundaryContextProvider } from 'app/error-boundaries/context/ErrorBoundaryContext';
import Auth0ProviderWithHistory from 'app/auth/Auth0Provider';
import { ThemeProvider } from 'app/common/theme';
import { splitioConfig } from 'app/FeatureFlag';

import store from '../../store';
import ApolloWrapper from '../../api/graphql';
import GlobalCss from '../../global.styles.css';

interface AppProvidersProps {
  children?: React.ReactNode;
}

export const AppProviders = ({ children }: AppProvidersProps) => {
  return (
    <SplitFactoryProvider config={splitioConfig}>
      <Router>
        <DesignSystemThemeProvider>
          <ThemeProvider>
            <>
              <GlobalCss />
              <ErrorBoundaryContextProvider>
                <DragDropContextProvider backend={HTML5Backend}>
                  <AlertContextProvider>
                    <Auth0ProviderWithHistory>
                      <ApolloWrapper>
                        <ReduxProvider store={store as any}>
                          <TransactionBarContextProvider>{children}</TransactionBarContextProvider>
                        </ReduxProvider>
                      </ApolloWrapper>
                    </Auth0ProviderWithHistory>
                  </AlertContextProvider>
                </DragDropContextProvider>
              </ErrorBoundaryContextProvider>
            </>
          </ThemeProvider>
        </DesignSystemThemeProvider>
      </Router>
    </SplitFactoryProvider>
  );
};
