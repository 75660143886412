import * as React from 'react';
import { IconProps } from './types';

export const SendIcon = ({
  color = 'currentColor',
  width = '32px',
  height = '32px',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.2335 7.72845C23.7499 7.26352 23.1058 7.00353 22.4347 7.00002C22.122 6.99885 21.8117 7.05506 21.5177 7.16514L8.80064 11.4068C7.74548 11.7522 7.02406 12.7254 7.00065 13.8357C6.97723 14.9447 7.65647 15.9484 8.69523 16.3383L13.4111 18.1372C13.5142 18.1781 13.6079 18.2414 13.6864 18.3199C13.7695 18.4053 13.8339 18.5072 13.8726 18.6197L15.6714 23.3452V23.3686C16.0778 24.3605 17.0463 25.007 18.1178 24.9999H18.1413C19.2632 24.9953 20.254 24.2692 20.5971 23.2011L24.8939 10.4828C25.2312 9.51549 24.9735 8.44042 24.2334 7.73073L24.2335 7.72845ZM23.1936 9.89268L18.8908 22.6529C18.7854 22.9797 18.4797 23.2022 18.1354 23.201C17.7958 23.2022 17.4913 22.9949 17.3648 22.6799L15.566 17.9543C15.5426 17.8946 15.5145 17.8372 15.4876 17.781L18.454 14.8064L17.1833 13.5357L14.2063 16.5127C14.1548 16.4893 14.108 16.4647 14.0529 16.4436L9.32741 14.6448C9.00069 14.5253 8.78753 14.2103 8.79691 13.8625C8.79691 13.5146 9.02294 13.209 9.35437 13.1059L22.1137 8.86192C22.4135 8.73896 22.7567 8.80454 22.9897 9.02822C23.2228 9.2519 23.3024 9.59152 23.1935 9.896L23.1936 9.89268Z"
      fill={color}
    />
  </svg>
);
