import { EFormStatus, IForm } from '../../../compliance/types/formsTypes';
import { ComplianceTrackerFormCardProps, FormStepStatus } from './ComplianceTrackerFormCard';

export const FORM_ORDER: ReportsMapsKeys[] = ['EL', 'MMAT', 'DRA', 'KYC', 'CDOC', 'INV'];

export const FORM_MODAL_KEYS = {
  EL: 'engagementLetterForm',
  MMAT: 'marketingMaterialForm',
  DRA: 'dataRoomForm',
  KYC: 'KYCForm',
  CDOC: 'closingDocumentsForm',
  INV: 'invoiceForm',
};

export const FORM_MODAL_PROPS = {
  EL: {
    key: FORM_MODAL_KEYS.EL,
    props: {},
  },
  MMAT: {
    key: FORM_MODAL_KEYS.MMAT,
    props: {
      isRegulatory: false,
    },
  },
  DRA: {
    key: FORM_MODAL_KEYS.DRA,
    props: {},
  },
  KYC: {
    key: FORM_MODAL_KEYS.KYC,
    props: {},
  },
  CDOC: {
    key: FORM_MODAL_KEYS.CDOC,
    props: {},
  },
  INV: {
    key: FORM_MODAL_KEYS.INV,
    props: {},
  },
};

type ReportsMapsKeys = keyof typeof FORM_MODAL_PROPS;
type ReportsMap = Record<ReportsMapsKeys, ComplianceTrackerFormCardProps>;
type FormActionFn = (
  form: IForm,
  modalProps: typeof FORM_MODAL_PROPS[ReportsMapsKeys]
) => () => void;

export const REPORTS_MAP: ReportsMap = {
  EL: {
    step: '01',
    name: 'Engagement Letter',
    status: undefined,
    stepStatus: undefined,
    form: null,
    completeAction: () => {},
    viewDetailsAction: () => {},
    editAction: () => {},
  },
  MMAT: {
    step: '02',
    name: 'Marketing Material',
    status: undefined,
    stepStatus: undefined,
    form: null,
    completeAction: () => {},
    viewDetailsAction: () => {},
    editAction: () => {},
  },
  DRA: {
    step: '03',
    name: 'Data Room Access',
    status: undefined,
    stepStatus: undefined,
    form: null,
    completeAction: () => {},
    viewDetailsAction: () => {},
    editAction: () => {},
  },
  KYC: {
    step: '04',
    name: 'KYC / AML',
    status: undefined,
    stepStatus: undefined,
    form: null,
    completeAction: () => {},
    viewDetailsAction: () => {},
    editAction: () => {},
  },
  CDOC: {
    step: '05',
    name: 'Closing Documents',
    status: undefined,
    stepStatus: undefined,
    form: null,
    completeAction: () => {},
    viewDetailsAction: () => {},
    editAction: () => {},
  },
  INV: {
    step: '06',
    name: 'Invoice',
    status: undefined,
    stepStatus: undefined,
    form: null,
    completeAction: () => {},
    viewDetailsAction: () => {},
    editAction: () => {},
  },
};

const segregateForms = (forms: IForm[]) => {
  return FORM_ORDER.map(key => {
    return forms.filter(form => form.type === key)[0];
  });
};

const isFirstStepApproved = (reportsMap: ReportsMap) => {
  const firstStepForm = reportsMap[FORM_ORDER[0]].form;
  if (!firstStepForm) return false;
  return [EFormStatus.APPROVED, EFormStatus.APPROVED_WITH_CONDITIONS].includes(
    firstStepForm.status
  );
};

const isFormPresent = (reportMapItem: ReportsMap[ReportsMapsKeys]) => {
  return !!reportMapItem.form;
};

export const getComplianceTrackerReports = (options: {
  viewDetailsAction: FormActionFn;
  completeAction: FormActionFn;
  editAction: FormActionFn;
}) => (forms: IForm[]) => {
  const segregatedForms = segregateForms(forms || []);
  const reportsMap = REPORTS_MAP;

  FORM_ORDER.forEach((key, i) => {
    const form = segregatedForms[i];
    const modalProps = FORM_MODAL_PROPS[key as keyof typeof FORM_MODAL_KEYS];

    if (!form && i > 0) {
      reportsMap[key].stepStatus = FormStepStatus.BLOCKED;
    }
    if (i === 0 && !form) {
      reportsMap[key].stepStatus = FormStepStatus.READY;
    }

    if (!isFormPresent(reportsMap[key]) && isFirstStepApproved(reportsMap)) {
      reportsMap[key].stepStatus = FormStepStatus.READY;
    }

    if (form) {
      reportsMap[form.type as ReportsMapsKeys].status = form.status as any;
      reportsMap[key].stepStatus = FormStepStatus.COMPLETED;
      reportsMap[key].viewDetailsAction = options.viewDetailsAction(form, modalProps);
    }
    // Keeping this in case we need to revert or use both
    // if (i > 0 && !!reportsMap[FORM_ORDER[i - 1]].form && !form) {
    //   reportsMap[key].stepStatus = FormStepStatus.READY;
    // }

    reportsMap[key].form = form;
    reportsMap[key].completeAction = options.completeAction(form, modalProps);
    reportsMap[key].editAction = options.editAction(form, modalProps);
  });
  return FORM_ORDER.map(key => reportsMap[key]);
};
