import React from 'react';
import { tabColors, TabContainer, TabIconContainer, TabTextContainer } from './Styled';

interface TabProps {
  active: boolean;
  icon?: (props: { color: string }) => JSX.Element;
  onClick: () => void;
  label: string;
}

export const Tab = ({ active, label, icon: Icon, onClick }: TabProps): JSX.Element => {
  return (
    <TabContainer className={active ? 'active' : ''} onClick={onClick}>
      {Icon && (
        <TabIconContainer>
          <Icon color={active ? tabColors.active : tabColors.iconDefault} />
        </TabIconContainer>
      )}
      <TabTextContainer isActive={active}>{label}</TabTextContainer>
    </TabContainer>
  );
};
