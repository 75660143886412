// vendor
import React, { useContext } from 'react';
import NumberFormat from 'react-number-format';

// Utils
import { formatDateInput } from 'app/utils/helpers/helpers';
import utils from 'app/common/components/DatePicker/localeUtils';
import { PSTForm as PSTFormType, PSTFormHandlerContext } from './PSTFormHandler';
import { formControlChangeHandlers } from '../../../../compliance/components/FormControlChangeHandlers/FormControlChangeHandlers';

// Components
import {
  Checkbox,
  FormFilePicker,
  FormGroup,
  Input,
  SingleOptionRadioButtons,
  TextArea,
  Select,
  DatePicker,
  Alert,
} from '../../../../common/components';
import CommentField from '../../../../compliance/components/CommentField/CommentField';

// Context
import { UserContext } from '../../../../users/context/UserContext';

// Constants
import { PARTICIPATION_OPTIONS, PST_TEXTS, SELLING_COMPENSATION_OPTIONS } from './PSTFormConstants';
import {
  EPSTParticipationOptions,
  EPSTSellingCompensationOptions,
} from '../../../../compliance/types/formsTypes';
import { COMPLIANCE_EMAIL } from '../../../../compliance/constants/messages';

// Style
import * as S from './PSTFormStyled';
import * as FormsStyle from '../FormsStyled';

export const PSTForm = () => {
  const { form, isEdit } = useContext(PSTFormHandlerContext);
  const { values, errors, touched, setFieldValue } = form;
  const { firstName, lastName } = useContext(UserContext);

  const {
    handleFieldChange,
    handleDateChange,
    handleNumberInputChange,
    handleSelectChange,
    handleYesNoChange,
    handleFilesChange,
    handleCheckboxChange,
  } = formControlChangeHandlers<PSTFormType>(setFieldValue);

  function openEmail(evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
    const subject: string = encodeURIComponent(`PST - ${firstName} ${lastName}`);
    window.open(`mailto:${COMPLIANCE_EMAIL}?subject=${subject}`);
    evt.preventDefault();
  }

  const PREVIOUS_INVESTMENTS_LABEL = () => (
    <S.Text>
      If an investor <b>and</b> this investment is an additional investment amount, please disclose
      the <b>date</b> and <b>value</b> of previous investments
    </S.Text>
  );

  return (
    <>
      <Alert text={PST_TEXTS.infoNotificationLabel} style={{ marginBottom: '30px' }} />

      <FormGroup
        id="pst-entityName"
        label={PST_TEXTS.entityNameLabel}
        error={touched.entityName && errors.entityName}
        required
      >
        <Input
          id="pst-entityName"
          type="text"
          name="pst-entityName"
          placeholder={PST_TEXTS.entityNamePlaceholder}
          onChange={handleFieldChange('entityName')}
          value={values.entityName}
        />
      </FormGroup>
      <FormGroup
        id="pst-securityType"
        label={PST_TEXTS.securityTypeLabel}
        error={touched.securityType && errors.securityType}
        required
      >
        <Input
          id="pst-securityType"
          type="text"
          name="pst-securityType"
          placeholder={PST_TEXTS.securityTypePlaceholder}
          onChange={handleFieldChange('securityType')}
          value={values.securityType}
        />
      </FormGroup>
      <FormGroup
        id="pst-transactionDate"
        label={PST_TEXTS.transactionDateLabel}
        error={touched.transactionDate && errors.transactionDate}
        required
      >
        <DatePicker
          value={values.transactionDate}
          calendarPopperPosition="bottom"
          onChange={handleDateChange('transactionDate')}
          formatInputText={() => formatDateInput(values.transactionDate)}
          inputPlaceholder={PST_TEXTS.transactionDatePlaceholder}
          portalTarget="#drawer-portal"
        />
      </FormGroup>
      <FormGroup
        id="pst-transactionAmount"
        label={PST_TEXTS.transactionAmountLabel}
        error={touched.transactionAmount && errors.transactionAmount}
        required
      >
        <NumberFormat
          id="pst-transactionAmount"
          name="pst-transactionAmount"
          placeholder={PST_TEXTS.transactionAmountPlaceholder}
          onValueChange={handleNumberInputChange('transactionAmount')}
          value={values.transactionAmount}
          displayType="input"
          thousandSeparator
          allowNegative={false}
          decimalScale={2}
          prefix="$"
          customInput={Input}
        />
      </FormGroup>

      <FormGroup
        id="pst-description"
        label={PST_TEXTS.descriptionLabel}
        error={touched.description && errors.description}
        required
      >
        <TextArea
          id="pst-description"
          name="pst-description"
          placeholder={PST_TEXTS.descriptionPlaceholder}
          onChange={handleFieldChange('description')}
          value={values.description}
        />
      </FormGroup>

      <FormGroup
        id="pst-participation"
        label={PST_TEXTS.participationLabel}
        required
        error={touched.participation && errors.participation}
      >
        <Select
          id="pst-participation"
          options={PARTICIPATION_OPTIONS}
          value={values.participation}
          onChange={handleSelectChange('participation')}
          placeholder={PST_TEXTS.participationPlaceholder}
        />
      </FormGroup>

      {(values.participation && values.participation.value) === EPSTParticipationOptions.OTHERS && (
        <FormGroup
          id="pst-otherParticipation"
          error={touched.otherParticipation && errors.otherParticipation}
          required
        >
          <TextArea
            id="pst-otherParticipation"
            name="pst-otherParticipation"
            placeholder={PST_TEXTS.otherParticipationPlaceholder}
            onChange={handleFieldChange('otherParticipation')}
            value={values.otherParticipation}
          />
        </FormGroup>
      )}

      <FormGroup
        id="pst-isIssuerPubliclyTradedCompany"
        label={PST_TEXTS.issuerPubliclyTradedCompanyLabel}
        required
      >
        <SingleOptionRadioButtons
          id="pst-isIssuerPubliclyTradedCompany"
          name="pst-isIssuerPubliclyTradedCompany"
          value={values.isIssuerPubliclyTradedCompany}
          onChange={handleYesNoChange('isIssuerPubliclyTradedCompany')}
          strictValueOnChange
        />
      </FormGroup>

      {values.isIssuerPubliclyTradedCompany && (
        <FormGroup
          id="pst-symbolAndExchange"
          label={PST_TEXTS.symbolAndExchangeLabel}
          error={touched.symbolAndExchange && errors.symbolAndExchange}
          required
        >
          <Input
            id="pst-symbolAndExchange"
            type="text"
            name="pst-symbolAndExchange"
            placeholder={PST_TEXTS.symbolAndExchangePlaceholder}
            onChange={handleFieldChange('symbolAndExchange')}
            value={values.symbolAndExchange}
          />
        </FormGroup>
      )}

      <FormGroup
        id="pst-previousInvestments"
        label={PREVIOUS_INVESTMENTS_LABEL()}
        error={touched.previousInvestments && errors.previousInvestments}
        required
      >
        <Input
          id="pst-previousInvestments"
          type="text"
          name="pst-previousInvestments"
          placeholder={PST_TEXTS.previousInvestmentsPlaceholder}
          onChange={handleFieldChange('previousInvestments')}
          value={values.previousInvestments}
        />
      </FormGroup>

      <FormGroup
        id="pst-isUserRelatedWithEntity"
        label={PST_TEXTS.isUserRelatedWithEntityLabel}
        required
      >
        <SingleOptionRadioButtons
          id="pst-isUserRelatedWithEntity"
          name="pst-isUserRelatedWithEntity"
          value={values.isUserRelatedWithEntity}
          onChange={handleYesNoChange('isUserRelatedWithEntity')}
          strictValueOnChange
        />
      </FormGroup>

      {values.isUserRelatedWithEntity && (
        <FormGroup
          id="pst-relationshipWithEntityDetails"
          label={PST_TEXTS.relationshipWithEntityDetailsLabel}
          error={touched.relationshipWithEntityDetails && errors.relationshipWithEntityDetails}
          required
        >
          <TextArea
            id="pst-relationshipWithEntityDetails"
            name="pst-relationshipWithEntityDetails"
            error={touched.relationshipWithEntityDetails && errors.relationshipWithEntityDetails}
            placeholder={PST_TEXTS.relationshipWithEntityDetailsPlaceholder}
            onChange={handleFieldChange('relationshipWithEntityDetails')}
            value={values.relationshipWithEntityDetails}
          />
        </FormGroup>
      )}

      <FormGroup
        id="pst-hasInteractedWithOtherIndividual"
        label={PST_TEXTS.renderHasInteractedWithOtherIndividualLabel(values.entityName)}
        required
      >
        <SingleOptionRadioButtons
          id="pst-hasInteractedWithOtherIndividual"
          name="pst-hasInteractedWithOtherIndividual"
          value={values.hasInteractedWithOtherIndividual}
          onChange={handleYesNoChange('hasInteractedWithOtherIndividual')}
          strictValueOnChange
        />
      </FormGroup>

      {values.hasInteractedWithOtherIndividual && (
        <FormGroup
          id="pst-interactionWithIndividualDetails"
          label={PST_TEXTS.interactionWithIndividualDetailsLabel}
          error={
            touched.interactionWithIndividualDetails && errors.interactionWithIndividualDetails
          }
          required
        >
          <TextArea
            id="pst-interactionWithIndividualDetails"
            name="pst-interactionWithIndividualDetails"
            error={
              touched.interactionWithIndividualDetails && errors.interactionWithIndividualDetails
            }
            placeholder={PST_TEXTS.interactionWithIndividualDetailsPlaceholder}
            onChange={handleFieldChange('interactionWithIndividualDetails')}
            value={values.interactionWithIndividualDetails}
          />
        </FormGroup>
      )}

      <FormGroup
        id="pst-willReceiveSellingCompensation"
        label={PST_TEXTS.willReceiveSellingCompensationLabel}
        required
      >
        <SingleOptionRadioButtons
          id="pst-willReceiveSellingCompensation"
          name="pst-willReceiveSellingCompensation"
          value={values.willReceiveSellingCompensation}
          onChange={handleYesNoChange('willReceiveSellingCompensation')}
          strictValueOnChange
        />
      </FormGroup>

      {values.willReceiveSellingCompensation && (
        <>
          <FormGroup
            id="pst-sellingCompensations"
            label={PST_TEXTS.sellingCompensationsLabel}
            error={touched.sellingCompensations && errors.sellingCompensations}
            required
          >
            <Select
              id="pst-sellingCompensations"
              isMulti
              options={SELLING_COMPENSATION_OPTIONS}
              value={values.sellingCompensations}
              onChange={handleSelectChange('sellingCompensations', { defaultValue: [] })}
              placeholder={PST_TEXTS.sellingCompensationsPlaceholder}
            />
          </FormGroup>

          {values.sellingCompensations.some(
            option => option.value === EPSTSellingCompensationOptions.OTHERS
          ) && (
            <FormGroup
              id="pst-otherSellingCompensations"
              error={touched.otherSellingCompensations && errors.otherSellingCompensations}
              required
            >
              <Input
                id="pst-otherSellingCompensations"
                type="text"
                name="pst-otherSellingCompensations"
                placeholder={PST_TEXTS.otherSellingCompensationsPlaceholder}
                onChange={handleFieldChange('otherSellingCompensations')}
                value={values.otherSellingCompensations}
              />
            </FormGroup>
          )}
        </>
      )}

      <FormGroup
        id="pst-alreadyInvestedEntity"
        label={PST_TEXTS.previousInvestmentEntityLabel}
        required
      >
        <SingleOptionRadioButtons
          id="pst-alreadyInvestedEntity"
          name="pst-alreadyInvestedEntity"
          value={values.alreadyInvestedEntity}
          onChange={handleYesNoChange('alreadyInvestedEntity')}
          strictValueOnChange
        />
      </FormGroup>

      {values.alreadyInvestedEntity && (
        <>
          <FormGroup
            id="pst-discloseDate"
            label={PST_TEXTS.discloseDateLabel}
            error={touched.discloseDate && errors.discloseDate}
            required
          >
            <DatePicker
              value={values.discloseDate}
              onChange={handleDateChange('discloseDate')}
              formatInputText={formatDateInput}
              inputPlaceholder={PST_TEXTS.discloseDatePlaceholder}
              maximumDate={utils('en').getToday()}
              calendarPopperPosition="bottom"
              portalTarget="#drawer-portal"
            />
          </FormGroup>

          <FormGroup
            id="pst-investmentAmount"
            label={PST_TEXTS.investmentAmountLabel}
            error={touched.investmentAmount && errors.investmentAmount}
            required
          >
            <NumberFormat
              id="pst-investmentAmount"
              name="pst-investmentAmount"
              placeholder={PST_TEXTS.investmentAmountPlaceholder}
              onValueChange={handleNumberInputChange('investmentAmount')}
              value={values.investmentAmount}
              displayType="input"
              thousandSeparator
              allowNegative={false}
              decimalScale={2}
              prefix="$"
              customInput={Input}
              style={{ width: 166 }}
            />
          </FormGroup>
        </>
      )}
      {!isEdit && (
        <>
          <FormFilePicker
            files={values.files}
            label={
              <>
                {PST_TEXTS.fileUploadLabelPart1}
                <S.EmailLink to="#" onClick={openEmail}>
                  {COMPLIANCE_EMAIL}
                </S.EmailLink>
                {PST_TEXTS.fileUploadLabelPart2}
              </>
            }
            acceptedFiles=".jpg, .jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .png, image/jpeg, image/png, text/plain, .doc, .docx, .xls, .xlsx, .ppt, .pptx, application/excel, application/vnd.ms-excel, application/x-excel, application/x-msexcel, application/doc, application/ms-doc, application/msword, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
            description="DOC, DOCX, JPG, JPEG, PDF, PNG, PPT, PPTX, XLS, XLSX and plain text are supported."
            error={touched.files && errors.files}
            setFiles={handleFilesChange('files')}
          />

          <FormGroup id="pst-commentField" label={PST_TEXTS.pstComments}>
            <TextArea
              placeholder={PST_TEXTS.pstCommentsPlaceholder}
              onChange={handleFieldChange('noteComment')}
              value={values.noteComment}
            />
          </FormGroup>
        </>
      )}
      <FormsStyle.CheckboxTextContainer>
        <Checkbox
          id="pst-attestation-draw"
          name="pst-checkbox"
          checked={values.isSigned}
          onChange={handleCheckboxChange('isSigned')}
        />
        <FormsStyle.CheckboxTextLabel>{PST_TEXTS.attestationLabel}</FormsStyle.CheckboxTextLabel>
      </FormsStyle.CheckboxTextContainer>
    </>
  );
};
