import { useMutation } from '@apollo/client';
import { useContext } from 'react';

import { CREATE_FORM } from 'api/graphql/compliance/mutations';
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { FormikStub, useForm } from 'app/utils/hooks/useForm';
import { UserContext } from 'app/users/context/UserContext';

import { CAInternalValidationSchema } from './CAFormValidation';
import { buildCAInternalForm } from './CAFormHelper';

export type CAInternalFormType = {
  files: File[];
  firmName: string;
  fullName: string;
  emailAddress: string;
  note?: string;
};

export interface IUseAmendmentFormHandler {
  form: FormikStub<CAInternalFormType>;
  onSubmitCAInternalForm: () => Promise<void>;
  creatingForm: boolean;
}

export const useCAInternalHandler = (): IUseAmendmentFormHandler => {
  const { activeDeal } = useContext(UserContext);
  const { showErrorToast } = useContext(AlertContext);

  const initialValues = {
    files: [],
    firmName: '',
    fullName: '',
    emailAddress: '',
    note: '',
  };

  const form = useForm<CAInternalFormType>({
    initialValues,
    validationSchema: CAInternalValidationSchema,
  });

  const onSuccess = () => {
    form.resetForm();
  };

  const [createFormMutation, { loading: creatingForm }] = useMutation(CREATE_FORM, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      onSuccess();
    },
    onError() {
      showErrorToast({ title: 'Error', description: 'An error occurred while saving the form' });
    },
  });

  const onSubmitCAInternalForm = async (): Promise<void> => {
    const { values } = form;
    const dealId = activeDeal ? activeDeal.id : '';
    await createFormMutation({
      variables: buildCAInternalForm(values, dealId),
    });
  };

  return {
    form,
    onSubmitCAInternalForm,
    creatingForm,
  };
};
