// Vendors
import * as yup from 'yup';

// Constants
import { MAX_DEAL_SIZE } from 'app/utils/constants/app/appConstants';
import {
  REQUIRED_MESSAGE,
  BOTH_FIELDS_REQUIRED_MESSAGE,
  MAX_PERCENT_MESSAGE,
  MAX_LOWER_THAN_MIN_MESSAGE,
  MAX_DEAL_SIZE_REACHED_MESSAGE,
  MIN_VALUE_CANNOT_BE_MESSAGE,
} from 'app/common/texts/errorTexts';
import { COUNTRIES_WITH_STATES } from './EditDealSideDrawerHelper';
import { MarketplaceDealType } from 'app/marketplace/types';

const EMPTY_MESSAGE = 'This field cannot be empty';

export const validationSchema = yup.object().shape({
  dealName: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .trim(EMPTY_MESSAGE)
    .min(2, 'Should be at least 2 characters')
    .max(120, 'Should not be more than 120 characters'),
  transactionType: yup.object().test('TRANSACTION-TEST', REQUIRED_MESSAGE, option => !!option),
  country: yup.object().test('COUNTRY-TEST', REQUIRED_MESSAGE, option => !!option),
  state: yup
    .object()
    .nullable()
    .when('country', {
      is: country => country && COUNTRIES_WITH_STATES.includes(country.value),
      then: yup.object().test('STATE-TEST', REQUIRED_MESSAGE, option => !!option),
    }),
  dealStage: yup.object().required(REQUIRED_MESSAGE),
  primaryIndustry: yup.object().test('INDUSTRY-TEST', REQUIRED_MESSAGE, option => !!option),
  secondaryIndustry: yup
    .object()
    .nullable()
    .when('marketplaceDealType', {
      is: marketplaceDealType => marketplaceDealType === MarketplaceDealType.Collaborative,
      then: yup.object().required(REQUIRED_MESSAGE),
    }),
  isRetainer: yup
    .boolean()
    .nullable()
    .when('marketplaceDealType', {
      is: marketplaceDealType => marketplaceDealType === MarketplaceDealType.Collaborative,
      then: yup.boolean().required(REQUIRED_MESSAGE),
    }),
  retainerAmount: yup
    .number()
    .nullable()
    .when('isRetainer', {
      is: isRetainer => isRetainer,
      then: yup.number().required(REQUIRED_MESSAGE),
    }),
  expectedSize: yup
    .number()
    .nullable()
    .max(MAX_DEAL_SIZE, MAX_DEAL_SIZE_REACHED_MESSAGE)
    .when('marketplaceDealType', {
      is: marketplaceDealType => marketplaceDealType === MarketplaceDealType.Collaborative,
      then: yup.number().required(REQUIRED_MESSAGE),
    }),
  minGrossFeeEarned: yup
    .number()
    .nullable()
    .min(0.01, MIN_VALUE_CANNOT_BE_MESSAGE)
    .max(yup.ref('maxGrossFeeEarned'), MAX_LOWER_THAN_MIN_MESSAGE),
  maxGrossFeeEarned: yup
    .number()
    .nullable()
    .max(100, MAX_PERCENT_MESSAGE),
  grossFeeEarned: yup.boolean().when(['minGrossFeeEarned', 'maxGrossFeeEarned'], {
    is: (minGrossFeeEarned, maxGrossFeeEarned) =>
      (typeof minGrossFeeEarned === 'number' && typeof maxGrossFeeEarned !== 'number') ||
      (typeof minGrossFeeEarned !== 'number' && typeof maxGrossFeeEarned === 'number'),
    then: yup.boolean().required(BOTH_FIELDS_REQUIRED_MESSAGE),
  }),
});
