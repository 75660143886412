import gql from 'graphql-tag';

export const DealFragment = gql`
  fragment DealBasicFragment on Deal {
    id
    name
    createdAt
    updatedAt
    private
    status
    metrics {
      name
      type
      value
      measurementUnit
    }
    type
    details {
      dealStatus
      expectedCommissions
      expectedSize
      closingDate
      loiProvided
    }
    companyName
    companyAddress
    companyAgent
    projectName
    UserDeal {
      role
      side
    }
    DealOwner {
      id
      firstName
      lastName
    }
    mode
    metadata {
      key
      type
      value
    }
  }
`;
