import * as React from 'react';
import { IconProps } from './types';

export const MessageIcon = ({
  color = 'currentColor',
  style = {},
  width = '20',
  height = '20',
}: IconProps): JSX.Element => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 7.00085C9.73478 7.00085 9.48043 7.10621 9.29289 7.29375C9.10536 7.48128 9 7.73564 9 8.00085V11.0009C9 11.2661 9.10536 11.5204 9.29289 11.708C9.48043 11.8955 9.73478 12.0009 10 12.0009C10.2652 12.0009 10.5196 11.8955 10.7071 11.708C10.8946 11.5204 11 11.2661 11 11.0009V8.00085C11 7.73564 10.8946 7.48128 10.7071 7.29375C10.5196 7.10621 10.2652 7.00085 10 7.00085ZM17 0.000854492H3C2.20435 0.000854492 1.44129 0.316925 0.87868 0.879534C0.316071 1.44214 0 2.2052 0 3.00085V13.0009C0 13.7965 0.316071 14.5596 0.87868 15.1222C1.44129 15.6848 2.20435 16.0009 3 16.0009H14.59L18.29 19.7109C18.3834 19.8035 18.4943 19.8769 18.6161 19.9266C18.7379 19.9764 18.8684 20.0016 19 20.0009C19.1312 20.0042 19.2613 19.9768 19.38 19.9209C19.5626 19.8458 19.7189 19.7184 19.8293 19.5547C19.9396 19.391 19.999 19.1983 20 19.0009V3.00085C20 2.2052 19.6839 1.44214 19.1213 0.879534C18.5587 0.316925 17.7956 0.000854492 17 0.000854492ZM18 16.5909L15.71 14.2909C15.6166 14.1982 15.5057 14.1248 15.3839 14.0751C15.2621 14.0253 15.1316 14.0001 15 14.0009H3C2.73478 14.0009 2.48043 13.8955 2.29289 13.708C2.10536 13.5204 2 13.2661 2 13.0009V3.00085C2 2.73564 2.10536 2.48128 2.29289 2.29375C2.48043 2.10621 2.73478 2.00085 3 2.00085H17C17.2652 2.00085 17.5196 2.10621 17.7071 2.29375C17.8946 2.48128 18 2.73564 18 3.00085V16.5909ZM10 4.00085C9.80222 4.00085 9.60888 4.0595 9.44443 4.16938C9.27998 4.27927 9.15181 4.43545 9.07612 4.61817C9.00043 4.8009 8.98063 5.00196 9.01921 5.19594C9.0578 5.38993 9.15304 5.56811 9.29289 5.70796C9.43275 5.84781 9.61093 5.94305 9.80491 5.98164C9.99889 6.02023 10.2 6.00042 10.3827 5.92473C10.5654 5.84905 10.7216 5.72087 10.8315 5.55642C10.9414 5.39198 11 5.19864 11 5.00085C11 4.73564 10.8946 4.48128 10.7071 4.29375C10.5196 4.10621 10.2652 4.00085 10 4.00085Z"
      fill={color}
    />
  </svg>
);
