import _get from 'lodash/get';
import { EDealStatus } from '../types/types';
import { GET_DEAL_QUERY_deal_metadata } from 'api/graphql/deals/types/GET_DEAL_QUERY';
import { GET_USER_PROFILE_QUERY_profile_metadata } from 'api/graphql/users/types/GET_USER_PROFILE_QUERY';
import { mapMetadata } from './metadataMapper';
import { SafeParseOptions } from './safeParse';

export const DEAL_METADATA_FIELD_ACCESSORS = {
  industryType: 'value',
  industryTypeSubOptions: 'value',
  targetInvestors: 'value',
  dealStatus: 'value',
  country: 'value',
  closingDate: (value: any) => {
    if (!value) return value;
    const { day, month, year } = value;
    return `${month}/${day}/${year}`;
  },
  tailPeriodDuration: 'value',
};

export const DEFAULT_METADATA_VALUES = {
  headquarterAddressLine2: '',
  country: '',
  state: '',
  zipCode: '',
  industryType: '',
  industryTypeSubOptions: '',
  publiclyTradedCompany: false,
  ticker: '',
  initialDueDiligence: '',
  potentialConflicts: '',
  targetInvestors: '',
  foreignSolicitation: false,
  dealStatus: EDealStatus.INITIAL_ENGAGEMENT,
  expectedCommissions: 0,
  expectedSize: 0,
  closingDate: '',
  loiProvided: false,
  marketplaceDealType: '',
  marketplaceDescription: '',
  monthlyRetainer: false,
  retainerAmount: 0,
  minFee: 0,
  maxFee: 0,
  tailPeriodDuration: 6,
  status: true,
};

export const DEAL_METADATA_FIELDS = Object.keys(DEFAULT_METADATA_VALUES);
export type DealMetadataFields = keyof typeof DEFAULT_METADATA_VALUES;
type MetadataType = (
  | GET_DEAL_QUERY_deal_metadata
  | GET_USER_PROFILE_QUERY_profile_metadata
  | null)[];

export const mapDealMetadataToObject = (metadata: MetadataType = []): Record<string, any> => {
  const metadataArray = metadata || [];

  const parserOptions = (key: DealMetadataFields | string) => {
    let transformerFn: Function | undefined = undefined;
    if (key === 'marketplaceDescription') {
      transformerFn = (_input: string) => _input.replace('\n', ' ');
    }
    return {
      fallBackValue: DEFAULT_METADATA_VALUES[key as DealMetadataFields],
      transformers: transformerFn,
    } as SafeParseOptions<any>;
  };

  return {
    ...DEFAULT_METADATA_VALUES,
    ...mapMetadata(metadataArray, parserOptions),
  };
};

type MapObjectToMetadataOptions = {
  fields: string[];
  accessors: Record<string, string | ((value: any) => string)>;
};
export const mapObjectToMetadata = (values: any, options = {} as MapObjectToMetadataOptions) => {
  const { fields, accessors } = options;
  const keys =
    fields && fields.length
      ? Object.keys(values).filter(key => fields.includes(key))
      : Object.keys(values);
  return keys.map(key => {
    const accessor = _get(accessors, key);
    const valueFromAccessor =
      typeof accessor === 'function' ? accessor(values[key]) : _get(values, `${key}.${accessor}`);
    const value = accessor ? valueFromAccessor : values[key];
    return {
      key,
      type: typeof value,
      value: JSON.stringify(value),
    };
  });
};
