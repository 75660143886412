import styled from 'styled-components';
import { StackProps, MarginProps } from 'app/utils/interfaces/interfaces';
import { stackControl } from 'app/utils/helpers/stackControl';
import { marginControl } from 'app/utils/helpers/marginControl';

export const VStack = styled.div`
  display: flex;
  width: 100%;
  ${({ direction = 'column', ...rest }: StackProps) => stackControl({ direction, ...rest })}
  ${(props: MarginProps) => marginControl(props)}
`;
