// Vendors
import { useEffect, useContext } from 'react';
import ReactGA from 'react-ga';

// Context
import { UserContext } from 'app/users/context/UserContext';

const useGoogleAnalytics = () => {
  const { email } = useContext(UserContext);

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA || '');
  }, []);

  const trackSingleEvent = (
    category: string,
    action: string,
    label?: string,
    data?: undefined | any
  ) => {
    ReactGA.event({
      category,
      action,
      label,
      ...data,
    });
  };

  const trackMultipleEvents = (category: string, action: string, labels: string[]) => {
    labels.forEach((singleLabel: string) => {
      ReactGA.event({
        category,
        action,
        label: singleLabel,
        dimension1: email,
      });
    });
  };

  return {
    trackSingleEvent,
    trackMultipleEvents,
  };
};

export default useGoogleAnalytics;
