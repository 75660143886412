import React, { useCallback } from 'react';
import get from 'lodash/get';

// Components
import OptionSelector from './OptionSelector';
import KYCCounterparties from './counterparty/KYCCounterparties';
import KYCClient from './client/KYCClient';
import { STEPS } from './KYCFormConstants';

interface KYCFormParams {
  currentStep: string;
  setCurrentStep: (option: string) => void;
  setPreviousStep: (option: string) => void;
}

const KYCForm = ({ currentStep, setCurrentStep, setPreviousStep }: KYCFormParams) => {
  const renderStep = useCallback(() => {
    const isOption = currentStep === STEPS.OPTION_SELECTOR ? 0 : 1;
    const steps = [
      <OptionSelector setCurrentStep={setCurrentStep} setPreviousStep={setPreviousStep} />,
      ...(currentStep === STEPS.CLIENT ? [<KYCClient />] : [<KYCCounterparties />]),
    ];

    return get(steps, `[${isOption}]`, null);
  }, [currentStep]);

  return renderStep();
};

export default KYCForm;
