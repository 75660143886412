import React, { ChangeEventHandler } from 'react';

// Components
import { Checkbox, Pill, PillProps } from '../index';

// Style
import * as S from './CheckboxCardStyled';

interface CheckboxCardProps {
  checked: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  id?: string;
  pillProps?: PillProps;
  title: React.ReactElement | string;
  subtitle?: React.ReactElement | string;
  style?: React.CSSProperties;
}

export const CheckboxCard = ({
  checked,
  onChange,
  id,
  pillProps,
  title,
  subtitle,
  style,
}: CheckboxCardProps) => (
  <S.CheckboxCardContainer checked={checked} style={style}>
    <Checkbox checked={checked} onChange={onChange} id={id} />
    <S.TextContainer>
      {pillProps && <Pill {...pillProps} style={{ marginBottom: '4px' }} />}
      <S.Title>{title}</S.Title>
      {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
    </S.TextContainer>
  </S.CheckboxCardContainer>
);
