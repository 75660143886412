import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  min-width: 190px;
  min-height: 100%;
  padding: 56px 0;
  user-select: none;
  background-color: #f9fafb;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.section};
`;

export const Title = styled.p`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin: 0 0 16px 24px;
  color: #444444;
  line-height: 24px;
`;

export const SectionContainer = styled.button<{ isActive: boolean }>`
  padding: 8px 25px;
  background-color: transparent;
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizes.small};
  height: 32px;
  outline: none;
  color: #444444;
  ${({ isActive, theme }) => {
    if (isActive) {
      return `
        background-color: #4485EB;
        color: #fff;
        font-weight: ${theme.fontWeights.bold};
        outline: none;
      `;
    }
  }};
  &:hover {
    background-color: ${({ isActive, theme }) => (!isActive ? theme.palette.gray.light : 'auto')};
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 20px;
  height: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 10px;
`;

export const BodySectionContainer = styled.div<{ isMain: boolean }>`
  display: flex;
  width: 80%;
  margin-bottom: 10px;

  border-top: 1px solid
    ${({ theme, isMain }) => (isMain ? theme.colors.borders.rowSeparator : 'none')};
`;

export const Text = styled.p<{ isSubTitle: boolean }>`
  margin: ${({ isSubTitle }) => (isSubTitle ? '15px 0 8px 0' : ' 0 0 10px 0')};
  font-size: ${({ isSubTitle }) => (isSubTitle ? '12px' : '11px')};
  font-weight: ${({ isSubTitle }) => (isSubTitle ? 600 : 400)};
  color: ${({ theme, isSubTitle }) => (isSubTitle ? '#000' : theme.palette.gray.darker)};
`;
