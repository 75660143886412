import * as React from 'react';

export const EmptyInvoices = () => (
  <svg width={181} height={180} fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x={18.5} y={18} width={144} height={144} rx={14.4} fill="#EDEDF7" />
    <g filter="url(#a)">
      <path
        d="M53.5 57.023C53.5 50.935 58.435 46 64.523 46H93.92v15.616h15.616v52.361c0 6.088-4.935 11.023-11.023 11.023H64.523c-6.088 0-11.023-4.935-11.023-11.023V57.023Z"
        fill="#FEFEFE"
      />
      <path d="m93.918 46 7.349 7.808 8.267 7.808H93.918V46Z" fill="#D8D7EA" />
      <rect x={63.5} y={81} width={36} height={6} rx={3} fill="#EDEDF7" />
      <rect x={63.5} y={105} width={36} height={6} rx={3} fill="#EDEDF7" />
      <rect x={63.5} y={69} width={22} height={6} rx={3} fill="#EDEDF7" />
      <rect x={63.5} y={93} width={22} height={6} rx={3} fill="#EDEDF7" />
    </g>
    <g filter="url(#b)">
      <path
        d="M71.5 67.023C71.5 60.935 76.435 56 82.523 56h29.396v15.616h15.616v52.361c0 6.088-4.935 11.023-11.023 11.023H82.523c-6.088 0-11.023-4.935-11.023-11.023V67.023Z"
        fill="#FEFEFE"
      />
      <path d="m111.918 56 7.349 7.808 8.267 7.808h-15.616V56Z" fill="#D8D7EA" />
      <rect x={81.5} y={91} width={36} height={6} rx={3} fill="#EDEDF7" />
      <rect x={81.5} y={115} width={36} height={6} rx={3} fill="#EDEDF7" />
      <rect x={81.5} y={79} width={22} height={6} rx={3} fill="#EDEDF7" />
      <rect x={81.5} y={103} width={22} height={6} rx={3} fill="#EDEDF7" />
    </g>
    <defs>
      <filter
        id="a"
        x={33.5}
        y={30}
        width={96.035}
        height={119}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={4}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_9940_70722"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={8} />
        <feColorMatrix values="0 0 0 0 0.254902 0 0 0 0 0.305882 0 0 0 0 0.505882 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9940_70722" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_9940_70722" result="shape" />
      </filter>
      <filter
        id="b"
        x={51.5}
        y={40}
        width={96.035}
        height={119}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={4}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_9940_70722"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={8} />
        <feColorMatrix values="0 0 0 0 0.254902 0 0 0 0 0.305882 0 0 0 0 0.505882 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9940_70722" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_9940_70722" result="shape" />
      </filter>
    </defs>
  </svg>
);
