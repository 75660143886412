import * as React from 'react';
import { IconProps } from './types';

export const LocationMarkIcon = ({
  width = '11',
  height = '14',
  color = 'currentColor',
  style = {},
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    width={width}
    height={height}
    viewBox="0 0 11 14"
    fill="none"
  >
    <path
      d="M5.99935 0.601562C4.58486 0.601563 3.22831 1.16347 2.22811 2.16366C1.22792 3.16385 0.666016 4.52041 0.666016 5.9349C0.666016 9.5349 5.36602 13.6016 5.56602 13.7749C5.68677 13.8782 5.84045 13.9349 5.99935 13.9349C6.15825 13.9349 6.31193 13.8782 6.43268 13.7749C6.66602 13.6016 11.3327 9.5349 11.3327 5.9349C11.3327 4.52041 10.7708 3.16385 9.77059 2.16366C8.77039 1.16347 7.41384 0.601563 5.99935 0.601562ZM5.99935 12.3682C4.57935 11.0349 1.99935 8.16156 1.99935 5.9349C1.99935 4.87403 2.42078 3.85661 3.17092 3.10647C3.92107 2.35632 4.93848 1.9349 5.99935 1.9349C7.06021 1.9349 8.07763 2.35632 8.82778 3.10647C9.57792 3.85661 9.99935 4.87403 9.99935 5.9349C9.99935 8.16156 7.41935 11.0416 5.99935 12.3682ZM5.99935 3.26823C5.47193 3.26823 4.95636 3.42463 4.51783 3.71764C4.0793 4.01066 3.7375 4.42714 3.53567 4.91441C3.33384 5.40168 3.28103 5.93785 3.38392 6.45514C3.48681 6.97242 3.74079 7.44757 4.11373 7.82051C4.48667 8.19345 4.96183 8.44743 5.47911 8.55032C5.99639 8.65322 6.53257 8.60041 7.01984 8.39857C7.50711 8.19674 7.92358 7.85495 8.2166 7.41642C8.50962 6.97789 8.66602 6.46231 8.66602 5.9349C8.66602 5.22765 8.38506 4.54937 7.88497 4.04928C7.38487 3.54918 6.70659 3.26823 5.99935 3.26823ZM5.99935 7.26823C5.73564 7.26823 5.47785 7.19003 5.25859 7.04352C5.03932 6.89701 4.86843 6.68878 4.76751 6.44514C4.66659 6.20151 4.64019 5.93342 4.69163 5.67478C4.74308 5.41613 4.87007 5.17856 5.05654 4.99209C5.24301 4.80562 5.48059 4.67863 5.73923 4.62718C5.99787 4.57574 6.26596 4.60214 6.50959 4.70306C6.75323 4.80397 6.96147 4.97487 7.10797 5.19414C7.25448 5.4134 7.33268 5.67119 7.33268 5.9349C7.33268 6.28852 7.19221 6.62766 6.94216 6.8777C6.69211 7.12775 6.35297 7.26823 5.99935 7.26823Z"
      fill={color}
    />
  </svg>
);
