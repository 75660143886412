import { FormType } from 'types/graphql-types';
import { PositionMap } from 'app/utils/helpers/formDataMappers';
import { INVLabels } from './InvoiceFormConstants';
import { InvoiceForm } from './InvoiceFormHandler';

export const buildINVForm = (props: InvoiceForm, dealId: string) => {
  const { invoiceFiles, breakdown, note } = props;
  const variables = {
    type: FormType.INV,
    dealId,
    content: [
      {
        question: INVLabels.BREAKDOWN_QUESTION,
        input: breakdown,
        key: 'breakdown',
        value: JSON.stringify(breakdown),
      },
    ],
    note,
    files: invoiceFiles,
  };

  return variables;
};

export const INVOICE_VALUE_INFERENCE_MAP: PositionMap = {
  breakdown: { match: INVLabels.BREAKDOWN_QUESTION },
};
