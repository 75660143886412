import { REQUIRED_MESSAGE, INVALID_URL_MESSAGE } from 'app/utils/helpers/formValidators';
import { isValidUrl } from 'app/utils/helpers/helpers';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  dataRoomAccess: yup.boolean().required(REQUIRED_MESSAGE),
  finalisVDR: yup.boolean().when('dataRoomAccess', {
    is: true,
    then: yup.boolean().required(REQUIRED_MESSAGE),
  }),
  dataRoomLink: yup.string().when('finalisVDR', {
    is: false,
    then: yup.string().test('is-valid-url', INVALID_URL_MESSAGE, value => isValidUrl(value)),
  }),
});
