import React from 'react';
import AdvancedFilterSideDrawer from './AdvancedFilterSideDrawer/AdvancedFilterSideDrawer';
import CollaborateSideDrawer from './CollaborateSideDrawer/SideDrawer';
import CreateMMDealSideDrawer from './CreateMMDealSideDrawer/CreateMMDealSideDrawer';
import EditDealSideDrawer from './EditDealSideDrawer/EditDealSideDrawer';

const SideDrawerContainer = () => {
  return (
    <>
      <CollaborateSideDrawer />
      <AdvancedFilterSideDrawer />
      <CreateMMDealSideDrawer />
      <EditDealSideDrawer />
    </>
  );
};

export default SideDrawerContainer;
