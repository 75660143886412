import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import { ValueType } from 'react-select/src/types';
import { ThemeContext } from 'styled-components';
import { Alert, EButtonType } from '@dealsyte/poki';

// Mutations
import { UPDATE_USER_ROLE } from 'api/graphql/users/mutations';
import {
  UPDATE_USER_ROLE_MUTATION,
  UPDATE_USER_ROLE_MUTATIONVariables,
} from 'api/graphql/users/types/UPDATE_USER_ROLE_MUTATION';
import { REMOVE_USER_FROM_ORGANIZATION } from 'api/graphql/users/mutations';
import {
  REMOVE_USER_FROM_ORGANIZATION_MUTATION,
  REMOVE_USER_FROM_ORGANIZATION_MUTATIONVariables,
} from 'api/graphql/users/types/REMOVE_USER_FROM_ORGANIZATION_MUTATION';

import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { Role as ERole, EGroupType } from 'app/core-tools/due-diligence/types/types';

import { getSelectUserRoleCustomStyles } from './HelpersGroupMembersTable';
import { getError } from 'app/utils/helpers/helpers';

import { SelectStyled } from './StyledGroupMembersTable';

export type IProps = {
  userId: string;
  initialRole: ERole | undefined;
  dealId: string | undefined;
  organizationId: string;
  side: string;
  refetchInvitations: Function;
};

type IOption = {
  label: string;
  value: ERole | string;
};

let roleOptions = [
  { label: 'Admin', value: ERole.ADMIN },
  { label: 'Member', value: ERole.USER },
] as IOption[];

/**
 * Renders a selector UI which updates users role in current deal (triggers GQL mutation).
 * @param userId ID of the user which the selector is pointing.
 * @param initialRole Initial role of the user (before being switched).
 * @param dealId Current deal ID in which diligence settings are being rendered.
 */
function ChangeUserRoleSelector({
  userId,
  initialRole,
  dealId,
  organizationId,
  side,
  refetchInvitations,
}: IProps) {
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);
  const [showAlertModal, setShowAlertModal] = useState<boolean>(false);
  if (
    (EGroupType.SELL === side || EGroupType.BUY === side) &&
    findIndex(roleOptions, x => x.value === 'remove') < 0
  ) {
    roleOptions.push({
      label: 'Remove from group',
      value: 'remove',
    });
  } else if (EGroupType.SELL !== side && EGroupType.BUY !== side) {
    roleOptions = roleOptions.filter(x => x.value !== 'remove');
  }
  const initialValue = roleOptions.filter(roleOption => roleOption.value === initialRole)[0];

  const theme = useContext(ThemeContext);

  const [updateUserRoleMutation] = useMutation<
    UPDATE_USER_ROLE_MUTATION,
    UPDATE_USER_ROLE_MUTATIONVariables
  >(UPDATE_USER_ROLE, {
    onCompleted({ updateUserRole }) {
      refetchInvitations();
      showSuccessToast({
        title: `Successfully updated the role for ${get(updateUserRole, 'displayName', 'user')}.`,
      });
    },
    onError(error) {
      showErrorToast({ title: getError(error, 'Error trying to change user role.') });
    },
  });

  const [removeUser] = useMutation<
    REMOVE_USER_FROM_ORGANIZATION_MUTATION,
    REMOVE_USER_FROM_ORGANIZATION_MUTATIONVariables
  >(REMOVE_USER_FROM_ORGANIZATION, {
    onCompleted() {
      showSuccessToast({
        title: `The user has been successfully removed from the organization.`,
      });
    },
    onError(error) {
      showErrorToast({ title: getError(error, 'Error trying to remove user from organization.') });
    },
  });

  const handleUserRoleInputChange = (selectedRole: ValueType<{ value: ERole; label: string }>) => {
    if (get(selectedRole, 'value') === ERole.REMOVE_USER) {
      setShowAlertModal(true);
      return;
    }

    if (dealId && userId && get(selectedRole, 'value'))
      updateUserRoleMutation({
        variables: {
          newUserRole: {
            dealId,
            userId,
            //@ts-ignore
            role: selectedRole.value,
          },
        },
      });
  };

  if (!initialRole || !dealId) return null;

  return (
    <>
      <SelectStyled
        onChange={handleUserRoleInputChange}
        options={roleOptions}
        name="color"
        styles={getSelectUserRoleCustomStyles(theme, false)}
        className="react-select"
        classNamePrefix="react-select"
        isSearchable={false}
        value={initialValue}
      />
      <Alert
        onHide={() => setShowAlertModal(false)}
        disableActionButtons={false}
        show={showAlertModal}
        content={{
          title: `Remove user from Group`,
          description: `Are you sure you want to remove this user from the group? The user will lose access to all the data related to the deal.`,
        }}
        confirmAction={{
          label: 'Remove',
          action() {
            removeUser({
              variables: {
                groupId: organizationId,
                userId,
                dealId,
              },
            });
          },
          type: EButtonType.danger,
        }}
        declineAction={{
          label: 'Cancel',
          action() {
            setShowAlertModal(false);
          },
        }}
      />
    </>
  );
}
ChangeUserRoleSelector.displayName = 'ChangeUserRoleSelector';

export default ChangeUserRoleSelector;
