import React, { useEffect } from 'react';
import jwtDecode from 'jwt-decode';

import AppStorage, { TOKEN_KEY } from 'app/appStorage';

import Routes from './routes/Routes';
import { JWT } from 'app/core-tools/due-diligence/vdr/utils/types';
import { ERoutePatterns } from 'app/core-tools/due-diligence/types/types';
import { isPublicRoute } from '../utils/helpers';
import getUserToken from './utils/helpers/getUserToken';

// this interval is being created in the HTML to animate the logo
// @ts-ignore
clearInterval(window.interval);

const isTimestampExpired = (timestamp: any) => {
  const date = new Date();
  const currentTimestamp = date.getTime() / 1000;

  return currentTimestamp >= timestamp;
};

declare global {
  interface Window {
    Intercom: any;
  }
}

function App() {
  // TODO: handle the redirects to the internal login page

  useEffect(() => {
    if (process.env.REACT_APP_INTERCOM_APP_ID && window.Intercom) {
      window.Intercom('boot', {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        hide_default_launcher: true,
      });
    }
  });

  if (!isPublicRoute(window.location.href)) {
    const authToken = getUserToken();

    if (!authToken) {
      AppStorage.removeKey(TOKEN_KEY);

      window.location.href =
        `${ERoutePatterns.LOGIN}/?from=${window.location.pathname}${window.location.search}` || '/';

      return null;
    }

    const decodedToken = jwtDecode(authToken as string) as JWT;

    if (isTimestampExpired(decodedToken.exp)) {
      AppStorage.removeKey(TOKEN_KEY);

      window.location.href = `${ERoutePatterns.LOGOUT}` || '/';

      return null;
    }
  }

  return <Routes />;
}

export default App;
