import * as React from 'react';
import { IconProps } from './types';

export const EnvelopeOpenIcon = ({
  width = '12px',
  height = '14px',
  color = 'currentColor',
  style = {},
}: IconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 12 14" fill="none">
    <path
      d="M11.4733 4.87806L7.33333 0.851397C6.98264 0.517966 6.51724 0.332031 6.03333 0.332031C5.54943 0.332031 5.08403 0.517966 4.73333 0.851397L0.593333 4.8514C0.409387 5.01197 0.261443 5.2096 0.159193 5.43134C0.0569428 5.65307 0.00269376 5.89391 0 6.13806V11.8581C0.00702698 12.3431 0.206029 12.8057 0.553412 13.1443C0.900795 13.4829 1.36824 13.6701 1.85333 13.6647H10.1467C10.6318 13.6701 11.0992 13.4829 11.4466 13.1443C11.794 12.8057 11.993 12.3431 12 11.8581V6.13806C11.9995 5.90371 11.9527 5.67177 11.8623 5.45555C11.7719 5.23932 11.6397 5.04308 11.4733 4.87806ZM5.62667 1.8114C5.72865 1.71817 5.86183 1.66648 6 1.66648C6.13817 1.66648 6.27135 1.71817 6.37333 1.8114L10 5.3314L6.35333 8.8514C6.25135 8.94462 6.11817 8.99632 5.98 8.99632C5.84183 8.99632 5.70865 8.94462 5.60667 8.8514L2 5.3314L5.62667 1.8114ZM10.6667 11.8581C10.6581 11.9889 10.5991 12.1113 10.5021 12.1996C10.4052 12.2879 10.2777 12.3351 10.1467 12.3314H1.85333C1.72225 12.3351 1.59483 12.2879 1.49786 12.1996C1.40088 12.1113 1.34191 11.9889 1.33333 11.8581V6.56473L4.03333 9.16473L2.92667 10.2314C2.8025 10.3563 2.73281 10.5253 2.73281 10.7014C2.73281 10.8775 2.8025 11.0465 2.92667 11.1714C2.98863 11.2364 3.06309 11.2882 3.14557 11.3237C3.22806 11.3592 3.31686 11.3777 3.40667 11.3781C3.57831 11.3774 3.74308 11.3105 3.86667 11.1914L5.04667 10.0581C5.33974 10.2372 5.67654 10.3319 6.02 10.3319C6.36346 10.3319 6.70026 10.2372 6.99333 10.0581L8.17333 11.1914C8.29692 11.3105 8.46169 11.3774 8.63333 11.3781C8.72314 11.3777 8.81194 11.3592 8.89443 11.3237C8.97691 11.2882 9.05137 11.2364 9.11333 11.1714C9.2375 11.0465 9.3072 10.8775 9.3072 10.7014C9.3072 10.5253 9.2375 10.3563 9.11333 10.2314L8 9.16473L10.6667 6.56473V11.8581Z"
      fill={color}
    />
  </svg>
);
