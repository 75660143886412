import { Input } from '@dealsyte/poki';
import styled from 'styled-components';

export const SubHeader = styled.div`
  ${({ theme }) => {
    const { colors, spacing, typography } = theme.foundations;
    return `
      flex-direction: row;
      margin-top: ${spacing['03']};
      div {
        display: inline-block;
      }

      div {
        font-family: ${typography.fonts.body};
        font-size: ${typography.sizes['04']};
        color: ${colors.palette.blue['700']};
        line-height: ${spacing['04']};
        letter-spacing: 0.25px;

        &.middle {
          margin-left: 5px;
          margin-right: 5px;
          vertical-align: text-top;
        }
    }`;
  }}
`;

export const Body = styled.div`
  ${({ theme }) => {
    const { colors, spacing, typography } = theme.foundations;
    return `
      flex-direction: row;
      margin-top: 14px;

      .lens-icon-container {
        display: inline-block;
        position: fixed;
        margin-top: 10px;
        margin-left: -35px;
      }

      .body-list-container {
        flex: 1;
        max-height: 335px;
        overflow-y: auto;
        padding-right: 15px;

        .primary-item {
          display: flex;
          flex-direction: row;
          align-items: center;

          .expandable-icon {
            cursor: pointer;
            margin-left: ${spacing['01']};
            padding-inline: 2px;
            background-color: transparent;
            :focus {
              outline: ${spacing['01']} solid ${colors.palette.neutral[400]};
              border-radius: 2px;
            }
          }
        }
      }

      .subValue-list-container {
        padding-left: ${spacing['07']};
        flex: 1;
      }

      .item-count {
        flex: 1;
        text-align: end;
        color: ${colors.palette.neutral[400]};
        font-size: ${typography.sizes['03']};
        font-family: ${typography.fonts.body};
        letter-spacing: 0.5px;
      }

      .body-list-container .item-count {
        display: none;
      }

      .subValue-list-container .item-count {
        display: block;
      }`;
  }};
`;

export const SearchInput = styled(Input)`
  ${({ theme }) => {
    const { colors, spacing, typography, radii } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      color: ${colors.palette.neutral['500']};
      font-size: ${typography.sizes['04']};
      width: 430px;
      flex: 1;
      height: ${spacing['07']};
      border-radius: ${radii.md};
      padding: ${spacing['02']} ${spacing['03']};
      border: 1px solid ${colors.palette.neutral['500']};
      background-color: ${colors.palette.neutral['100']};
      margin-bottom: ${spacing['04']};
  `;
  }};
`;
