import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { MarginProps } from 'app/utils/interfaces/interfaces';
import { marginControl } from 'app/utils/helpers/marginControl';

export const ActivityContainer = styled.div`
  position: relative;
  &::before {
    content: '';
    width: 6px;
    height: 6px;
    background: #ca3521;
    border-radius: 10px;
    position: absolute;
    right: -2px;
    top: 1px;
  }
`;

export const SpinnerContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const MainContainer = styled.div`
  background: #f8f8fc;
  height: 100vh;
  overflow-y: auto;
  padding: 40px 72px;
`;

export const RoundIconContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: 1px solid #d8d7ea;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props: MarginProps) => marginControl(props)}
`;

export const Heading = styled.h1`
  color: #47455f;
  font-weight: bold;
  font-size: 32px;
  font-family: effra;
  word-wrap: break-word;
  ${({ mb = 8, ...rest }: MarginProps) => marginControl({ mb, ...rest })}
`;

export const GridItem = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
`;
