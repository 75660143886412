import * as React from 'react';

import { IconProps } from './types';

export const CircleIcon = ({
  color = 'currentColor',
  width = '13',
  height = '13',
  style = {},
}: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13 13"
    style={style}
  >
    <circle cx={6.5} cy={6.5} r={6.5} fill={color} />
  </svg>
);
