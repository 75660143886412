// Libraries
import React from 'react';
import PropTypes from 'prop-types';

const CheckCircleIcon = props => (
  <svg width={props.width} height={props.height} fill="none">
    <path
      d="M6.99778 0.333984C3.33112 0.333984 0.331116 3.33398 0.331116 7.00065C0.331116 10.6673 3.33112 13.6673 6.99778 13.6673C10.6644 13.6673 13.6644 10.6673 13.6644 7.00065C13.6644 3.33398 10.6644 0.333984 6.99778 0.333984ZM9.79778 5.86732L6.59778 9.06732C6.33112 9.33398 5.93112 9.33398 5.66445 9.06732L4.19778 7.60065C3.93112 7.33398 3.93112 6.93398 4.19778 6.66732C4.46445 6.40065 4.86445 6.40065 5.13112 6.66732L6.13112 7.66732L8.86445 4.93398C9.13112 4.66732 9.53112 4.66732 9.79778 4.93398C10.0644 5.20065 10.0644 5.60065 9.79778 5.86732Z"
      fill={props.color}
    />
  </svg>
);

CheckCircleIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

CheckCircleIcon.defaultProps = {
  width: '14px',
  height: '14px',
  color: '#87ED87',
};

export default CheckCircleIcon;
