import styled from 'styled-components';
import { TableCell } from '../../../../common/components';

export const Container = styled.div`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return `
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      padding: ${spacing['07']} ${spacing['11']};
  `;
  }}
`;

export const SectionHeader = styled.div`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return `
      display: flex;
      justify-content: space-between;
      margin-bottom: ${spacing['07']};
  `;
  }}
`;

export const TextContainer = styled.div`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return `
      display: flex;
      flex-direction: column;
      margin-right: ${spacing[10]};
    `;
  }}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const ButtonText = styled.label`
  ${({ theme }) => {
    const { typography } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['04']};
      letter-spacing: 0.25px;
      line-height: 16px;
    `;
  }}
`;

export const DealTableCell = styled(TableCell)`
  ${({ theme }) => {
    const { typography } = theme.foundations;
    return `   
      font-size: ${typography.sizes['03']};
      letter-spacing: 0.25px;
    `;
  }}
`;

export const OptionLabel = styled.label`
  ${({ theme }) => {
    const { typography, colors, spacing } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.medium};
      font-size: ${typography.sizes['02']};
      color: ${colors.palette.neutral['600']};
      letter-spacing: 0.25px;
      line-height: 24.5px;
      margin-left: ${spacing['02']};
  `;
  }}
`;

export const Text = styled.div`
  ${({ theme }) => {
    const { typography, colors, spacing } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['02']};
      color: ${colors.palette.neutral['600']};
      letter-spacing: 0.25px;
      line-height: 18px;
      margin-bottom: ${spacing['02']};
  `;
  }}
`;

export const SuccessFeeContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputLabelContainer = styled.div`
  ${({ theme }) => {
    const { colors, typography } = theme.foundations;
    return `
      display: flex;
      flex-direction: column;
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['02']};
      letter-spacing: 0.25px;
      line-height: 18px;
      color: ${colors.palette.neutral['500']};
    `;
  }}
`;

export const DealsButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -20px;
`;
