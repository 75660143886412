import React, { useEffect, useRef, Fragment, useState } from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { createPopper } from '@popperjs/core';

// icons
import TickIcon from '../../../assets/svg/tickIcon';

// styles
import * as S from './MultiSelectDropdownStyled';

// components
import { ClickOutsideHandler } from '../ClickOutsideHandler';
import { Button } from '../Button/Button';

export interface ApplyButtonOption {
  label: string;
  action?: (value: MultiSelectDropdownState | undefined) => void;
}

export type MultiSelectDropdownOption = {
  label: string;
  value: any;
};

export type MultiSelectDropdownState = Array<Array<MultiSelectDropdownOption>>;

export type MultiSelectDropdownOptions = MultiSelectDropdownState;

export interface DropdownProps {
  icon?: React.ReactElement | null;
  label: string;
  singleOptionMode?: boolean;
  changeOnSelection?: boolean;
  value?: MultiSelectDropdownState;
  applyButtonOptions?: ApplyButtonOption;
  options: MultiSelectDropdownOptions;
  onChange?: (value: MultiSelectDropdownState) => void;
  style?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  closeAfterClick?: boolean;
  isDisable?: boolean;
}

export const MultiSelectDropdown = ({
  icon = null,
  label,
  applyButtonOptions,
  options,
  value,
  onChange,
  singleOptionMode,
  changeOnSelection,
  style,
  buttonStyle,
  closeAfterClick,
  isDisable,
}: DropdownProps) => {
  const dropdownButtonElement = useRef<HTMLDivElement>();
  const dropdownMenuElement = useRef<HTMLDivElement>();
  const [internalState, setInternalState] = useState<MultiSelectDropdownState>();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (dropdownButtonElement.current && dropdownMenuElement.current && show) {
      createPopper(dropdownButtonElement.current, dropdownMenuElement.current, {
        placement: 'bottom-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
        ],
      });
    }
  }, [show]);

  useEffect(() => {
    setInternalState(value);
  }, [value]);

  const toggleShow = () => setShow(state => !state);

  const buildCleanOptionsState = () => Array.from(new Array(options.length)).map(_ => []);

  const isOptionInGroup = (source: MultiSelectDropdownState | undefined) => (
    option: MultiSelectDropdownOption,
    groupIndex: number
  ) => {
    const group = get(source, `[${groupIndex}]`, []) as MultiSelectDropdownOption[];
    return group.findIndex(item => item.value === option.value);
  };

  const isOptionSelected = (option: MultiSelectDropdownOption, groupIndex: number) =>
    isOptionInGroup(internalState)(option, groupIndex) >= 0;

  const handleOptionSelection = (option: MultiSelectDropdownOption, groupIndex: number) => () => {
    let newState = internalState ? [...internalState] : buildCleanOptionsState();
    const optionIndex = isOptionInGroup(newState)(option, groupIndex);
    if (singleOptionMode && options[groupIndex].length > 1) {
      newState = singleSelectionHandler(newState, groupIndex, option);
    } else {
      newState = multiSelectionHandler(newState, option, groupIndex, optionIndex);
    }
    if (changeOnSelection && onChange) {
      onChange(newState);
      if (closeAfterClick) {
        toggleShow();
      }
    } else {
      setInternalState(newState);
    }
  };

  const multiSelectionHandler = (
    source: MultiSelectDropdownState,
    option: MultiSelectDropdownOption,
    groupIndex: number,
    optionIndex: number
  ) => {
    if (optionIndex >= 0) {
      source[groupIndex].splice(optionIndex, 1);
      return source;
    }
    source[groupIndex].push(option);
    return source;
  };

  const singleSelectionHandler = (
    source: MultiSelectDropdownState,
    groupIndex: number,
    option: MultiSelectDropdownOption
  ) => {
    source[groupIndex] = [option];
    return source;
  };

  const handleApplyChanges = () => {
    const sideEffect = get(applyButtonOptions, 'action', () => {});
    if (onChange) {
      onChange(internalState as MultiSelectDropdownState);
      sideEffect(internalState);
    }
    setShow(false);
  };

  const handleClickOutside = () => {
    if (!changeOnSelection) {
      setInternalState(value);
    }
    toggleShow();
  };

  return (
    <>
      <S.DropdownContainer ref={dropdownButtonElement as any} style={style}>
        <Button
          displayAs="dropdown"
          className="secondary small alignLeft"
          style={{ ...buttonStyle, height: 40 }}
          onClick={toggleShow}
          disabled={isDisable}
          dataTestid="sort_by-control"
        >
          {icon && (
            <S.IconContainer
              data-testid={
                !!value && value[1][0].value === 'ASC'
                  ? 'sort_by-ascending_arrow '
                  : 'sort_by-descending_arrow'
              }
            >
              {icon}
            </S.IconContainer>
          )}
          {label}
        </Button>
      </S.DropdownContainer>
      {show && (
        <ClickOutsideHandler
          onClickOutside={handleClickOutside}
          triggerElement={dropdownButtonElement}
        >
          <S.DropdownModal ref={dropdownMenuElement as any}>
            {options.map((group, i) => (
              <Fragment key={`option_group_${i + 1}`}>
                <S.OptionsContainer>
                  {group.map((option, j) => (
                    <S.DropdownOption
                      key={`option_item_${j + 1}`}
                      onClick={handleOptionSelection(option, i)}
                      data-testid="sort_by-option"
                    >
                      <S.OptionIconContainer
                        data-testid={
                          isOptionSelected(option, i)
                            ? 'sort_by-option_marked'
                            : 'sort_by-option_non_marked'
                        }
                      >
                        {isOptionSelected(option, i) && <TickIcon />}
                      </S.OptionIconContainer>
                      <S.OptionLabelContainer data-testid="sort_by-option_label">
                        {option.label}
                      </S.OptionLabelContainer>
                    </S.DropdownOption>
                  ))}
                </S.OptionsContainer>
                <S.DropdownDivider />
              </Fragment>
            ))}
            {applyButtonOptions && !changeOnSelection && (
              <S.ButtonContainer>
                <Button
                  style={{ width: '100%' }}
                  className="primary small"
                  onClick={handleApplyChanges}
                  disabled={isEqual(value, internalState)}
                  dataTestid="sort_by-apply_button"
                >
                  {get(applyButtonOptions, 'label')}
                </Button>
              </S.ButtonContainer>
            )}
          </S.DropdownModal>
        </ClickOutsideHandler>
      )}
    </>
  );
};
