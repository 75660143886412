import React, { useCallback, useState } from 'react';

// Styles
import * as S from './AlertStyled';

// Icon
import {
  CheckCircleOutlineIcon,
  CloseIcon,
  ExclamationIcon,
  ExclamationMarkIcon,
  InfoIcon,
} from '../../icons';
import DealReferralIcon from 'app/common/icons/DealReferralIcon';

interface AlertProps extends React.ComponentProps<'div'> {
  icon?: React.ReactElement | null;
  type?: '' | 'floating';
  variant?: 'danger' | 'warning' | 'success' | 'regular' | 'referral';
  removable?: boolean;
  noIcon?: boolean;
  text?: React.ReactElement | string;
}

export const Alert = ({
  icon,
  noIcon,
  text,
  variant = 'regular',
  type = '',
  removable = false,
  children,
  ...props
}: AlertProps) => {
  const [open, setOpen] = useState(true);
  const closeAlert = () => setOpen(false);

  const resolveIcon = useCallback(() => {
    if (icon) return icon;
    switch (variant) {
      case 'regular':
        return <InfoIcon width="20" height="20" />;
      case 'danger':
        return <ExclamationIcon width="20" height="20" />;
      case 'warning':
        return <ExclamationMarkIcon width="20" height="20" />;
      case 'success':
        return <CheckCircleOutlineIcon width="20" height="20" />;
      case 'referral':
        return <DealReferralIcon width="20" height="20" />;
      default:
        return <InfoIcon width="20" height="20" />;
    }
  }, [icon, variant]);

  const resolveClassName = () => {
    const classNames = [variant, type].filter(name => !!name);
    return classNames.join(' ');
  };

  return open ? (
    <S.Alert {...(props as any)} className={resolveClassName()}>
      {!noIcon && <S.IconContainer>{resolveIcon()}</S.IconContainer>}
      <S.AlertText>{children || text}</S.AlertText>
      {removable && (
        <S.CloseIconContainer onClick={closeAlert}>
          <CloseIcon width="12" height="12" />
        </S.CloseIconContainer>
      )}
    </S.Alert>
  ) : null;
};
