import styled from 'styled-components';
import { Modal as PokiModal, Button } from '@dealsyte/poki';

export const InviteUsersButton = styled.div`
  color: #4485eb;
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-top: 15px;
  cursor: pointer;
  margin-bottom: 30px;
`;

export const Modal = styled(PokiModal)`
  padding: 0;
  min-height: 423px;
  width: 440px;
  margin-top: 95px;
  display: flex;
  border: none;
`;

export const BottomButton = styled(Button)`
  flex: 1;
  border-radius: unset;
  height: 100%;
  font-size: 14px;
`;
