import React from 'react';
import styled from 'styled-components';

const getHeight = (style: React.CSSProperties | undefined, fallback: string) => {
  if (!style || !style.height) return fallback;
  const heightValueType = typeof style.height;
  if (heightValueType === 'number') return `${style.height}px`;
  if (heightValueType === 'string') return style.height;
  return fallback;
};

const StyledButton = styled.button`
  ${({ theme, style, ...props }) => {
    const { colors, spacing, radii, sizes, typography } = theme.foundations;
    return `
      display: flex;
      align-items: center;
      border: 0;
      min-height: ${getHeight(style, sizes['09'])};
      color: ${colors.text.buttons};
      border-radius: ${radii.md};
      padding: 0px !important;
      cursor: pointer;
      outline: 0;

      & * {
        font-size: ${typography.sizes['04']};
        font-family: ${typography.fonts.body};
      }

      #button__content {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px ${(props as any).displayAs === 'dropdown' ? spacing['05'] : spacing['04']};
        height: ${getHeight(style, sizes['09'])};
      }

      #button__dropdown__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: ${sizes['09']}; 
        height: ${getHeight(style, sizes['09'])};
      }

      &.alignLeft {
        #button__content {
          justify-content: flex-start;
        }
      }

      &.small {
        min-height: 34px; // not existent size in theme
        #button__content {
          font-size: ${typography.sizes['03']};
          height: ${getHeight(style, '34px')};
          padding: 0px ${spacing['04']};
        }
        #button__dropdown__icon {
          min-width: 34px;
          height: ${getHeight(style, '34px')};
        }
      }
      &.primary {
        background: ${colors.primary.default};
        &:hover {
          background: ${colors.primary.dark};
        }
        &:focus {
          box-shadow: 0px 0px 0px 4px ${colors.palette.neutral['400']};
        }
        &:disabled {
          color: ${colors.palette.neutral['500']};
          background: ${colors.background.tertiary};
          cursor: not-allowed;
        }
        #button__dropdown__icon {
          border-left: 1px solid ${colors.outline.default};
        }

      }
      &.secondary {
        background: ${colors.palette.transparent};
        box-shadow: 0px 0px 0px 1px ${colors.primary.default} inset;
        color: ${colors.primary.default};
        &:hover {
          box-shadow: 0px 0px 0px 2px ${
            (props as any).displayAs === 'dropdown' ? colors.primary.light : colors.primary.dark
          } inset;
          color: ${colors.primary.dark};
        }
        &:focus {
          box-shadow: 0px 0px 0px 4px ${colors.palette.neutral['400']} inset;
          color: ${colors.primary.light}
        }
        &:disabled {
          color: ${colors.palette.neutral['400']};
          box-shadow: 0px 0px 0px 1px ${colors.outline.light} inset;
          cursor: not-allowed;
        }
      }
      &.tertiary {
        background: ${colors.palette.transparent};
        color: ${colors.primary.default};
        &:hover {
          box-shadow: 0px 0px 0px 2px ${
            (props as any).displayAs === 'dropdown' ? colors.primary.light : colors.primary.dark
          } inset;
          color: ${colors.primary.dark};
        }
        &:focus {
          box-shadow: 0px 0px 0px 4px ${colors.palette.neutral['400']} inset;
          color: ${colors.primary.light}
        }
        &:disabled {
          color: ${colors.palette.neutral['400']};
          cursor: not-allowed;
          box-shadow: none;
        }
      }
      &.dashboardHeader {
        padding: ${spacing['03']} ${spacing['04']};
        background: ${colors.palette.white};
      }
      &.link {
        background: ${colors.palette.transparent};
        color: ${colors.text.link};
        &:disabled {
          color: ${colors.text.disabled}
          cursor: default;
        }
      }
      &.white {
        background: ${colors.palette.white};
        color: ${colors.text.default};
        border: 1px solid ${colors.outline.xLight};
      }
    `;
  }}
`;

export default StyledButton;
