import React from 'react';
import { CardTabsContainer, CardTabsLeftPanel, CardTabsRightPanel } from './Styled';
import { Tab } from './Tab';

interface TabItem {
  component?: (props: any) => JSX.Element;
  props?: any;
}

interface CardTabsProps {
  options: {
    left: TabItem[];
    right?: TabItem[];
  };
  currentTab: number;
  onChange: (id: number) => void;
}

export const CardTabs = ({ options, currentTab, onChange }: CardTabsProps): JSX.Element => {
  const renderOptions = (items: TabItem[] = [], position = 'left') => {
    const isRightPanel = position !== 'left';
    return items.map(({ component: Item = Tab, props = {} }, index) => {
      const id = isRightPanel ? ((options.left && options.left.length) || 0) + (index + 1) : index;
      const key = `tab_${position}_${id}`;
      const defaultProps = {
        onClick: () => onChange(id),
        active: id === currentTab,
        ...props,
      };
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Item key={key} {...defaultProps} />;
    });
  };
  return (
    <CardTabsContainer>
      <CardTabsLeftPanel>{renderOptions(options && options.left)}</CardTabsLeftPanel>
      <CardTabsRightPanel>{renderOptions(options && options.right, 'right')}</CardTabsRightPanel>
    </CardTabsContainer>
  );
};
