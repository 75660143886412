import styled from 'styled-components';

export const SideDrawerContainer = styled.div`
  ${({ theme }) => {
    const { colors, spacing, typography } = theme.foundations;
    return `
      &.side-drawer {
        font-family: ${typography.fonts.body};
        height: 100%;
        background-color: ${colors.palette.white};
        position: fixed;
        top: 0;
        right: -15px;
        width: 600px;
        transform: translateX(100%);
        transition: transform 0.3s ease-out;
        overflow-y: scroll;
        .header {
          top: 0;
          position: sticky;
          background-color: ${colors.palette.white};
          .header-container {
            display: flex;
            flex: 1;
            padding: ${spacing['06']};
            align-items: baseline;
            flex-direction: row;
            .title-container {
              width: 100%;
              display: inline-block;
              p {
                color: #47455f;
                font-family: ${typography.fonts.body};
                font-size: ${typography.sizes['06']};
                font-weight: ${typography.weights.bold};
                line-height: 27.5px;
              }
            }
          }
        }
      }
      &.side-drawer.open {
        transform: translateX(0);
        display: flex;
        flex-direction: column;
        .close-button {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          cursor: pointer;
          flex: 1;
          margin-top: -10px;
        }
      }
      .DatePicker {
        input {
          text-align-last: start;
          font-size: ${typography.sizes['03']};
          color: ${colors.palette.neutral['600']};
          :focus {
            outline: none;
            border: 1px solid ${colors.palette.neutral['700']};
          }
          ::placeholder {
            color: ${colors.palette.neutral['500']};
            font-size: ${typography.sizes['03']};
            font-weight: ${typography.weights.regular};
          }
        }
      }
  `;
  }}
`;

export const ButtonContainer = styled.div`
  ${({ theme }) => {
    const { colors, sizes, typography, radii } = theme.foundations;
    return `
      display: flex;
      align-self: flex-start;
      background-color: ${colors.palette.white};
      justify-content: flex-end;
      .filter-button {
        width: 175px;
        height: ${sizes['09']};
        border-radius: ${radii.md};
        background-color: ${colors.palette.neutral['600']};
        justify-content: center;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: ${colors.palette.neutral['50']};
        :disabled {
          background-color: ${colors.palette.neutral['200']};
          color: ${colors.palette.neutral['500']};
        }
        p {
          font-size: ${typography.sizes['04']};
          font-family: ${typography.fonts.body};
          letter-spacing: 0.5px;
        }
      }
      .cancel {
        color: ${colors.palette.neutral['600']};
        background-color: ${colors.palette.white};
      }
  `;
  }}
`;

export const BodyContainer = styled.div`
  ${({ theme }) => {
    const { colors, spacing, typography } = theme.foundations;
    return `
      color: ${colors.palette.neutral['600']};
      letter-spacing: 0.25px;
      flex: 1;
      font-family: ${typography.fonts.body};
      .title {
        font-weight: ${typography.weights.bold};
        font-size: ${typography.sizes['05']};
        line-height: 24px;
        padding-bottom: ${spacing['02']};
      }
      .sub-title {
        font-size: ${typography.sizes['04']};
        line-height: 24px;
      }
  `;
  }}
`;

export const SelectTitle = styled.div`
  ${({ theme }) => {
    const { colors, spacing, typography } = theme.foundations;
    return `
      display: flex;
      align-items: center;
      padding-bottom: ${spacing['01']};
      .field-title {
        font-size: ${typography.sizes['03']};
        line-height: 24.5px;
      }
      .required-field {
        padding-left: ${spacing['02']};
        font-size: ${typography.sizes['02']};
        line-height: 18px;
        color: ${colors.palette.neutral['500']};
      }
  `;
  }}
`;

export const FieldContainer = styled.div`
  ${({ theme }) => {
    const { colors, spacing, shadows } = theme.foundations;
    return `
      display: flex;
      flex-direction: column;
      padding-top: ${spacing['04']};

      .deal-description {
        color: ${colors.palette.neutral['800']};
        resize: none;
        border: 1px solid ${colors.palette.neutral['500']};
        padding: ${spacing['03']};
        :focus {
          border: 1px solid ${colors.palette.neutral['700']};
          box-shadow: ${shadows['01']};
        }
      }
  `;
  }}
`;

export const SelectCustomStyles = (errorStyle?: {} | undefined) => {
  return {
    // @ts-ignore
    placeholder: base => ({
      ...base,
      color: '#807da1',
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: 0.25,
    }),
    // @ts-ignore
    control: (base, { isFocused }) => ({
      ...base,
      height: 35,
      minHeight: 35,
      minWidth: 450,
      border: isFocused ? '1px solid #353347 !important' : '1px solid #807da1 !important',
      boxShadow: isFocused
        ? '0px 4px 16px 4px rgba(65, 78, 129, 0.1) !important'
        : 'none !important',
      ...errorStyle,
    }),
    // @ts-ignore
    container: base => ({
      ...base,
      width: '100%',
      display: 'inline-block',
      minHeight: 30,
      borderColor: '',
      fontSize: 14,
    }),
    // @ts-ignore
    valueContainer: base => ({
      ...base,
      minHeight: 30,
      fontSize: 14,
      fontWeight: 'normal',
      color: '#707f8f',
    }),
    // @ts-ignore
    dropdownIndicator: base => ({
      ...base,
      padding: 4,
    }),
    // @ts-ignore
    singleValue: base => ({
      ...base,
      fontSize: 14,
      fontWeight: 'normal',
      color: '#47455F',
    }),
    // @ts-ignore
    menuList: base => ({
      ...base,
      height: 200,
    }),
    // @ts-ignore
    indicatorSeparator: base => ({
      ...base,
      display: 'none',
    }),
  };
};

export const SeparatorLine = styled.div`
  ${({ theme }) => {
    const { colors, spacing } = theme.foundations;
    return `
      margin-bottom: ${spacing['06']};
      border: 1px solid ${colors.palette.neutral['200']};
  `;
  }}
`;

export const YesNoRadioButtonContainer = styled.div`
  ${({ theme }) => {
    const { spacing, typography, sizes } = theme.foundations;
    return `
      display: flex;
      flex-direction: row;
      align-items: center;
      height: ${sizes['05']};
      p {
        height: 100%;
        font-size: ${typography.sizes['04']}
        line-height: 24px;
        margin-left: ${spacing['03']};
        margin-right: ${spacing['06']};
      }
  `;
  }}
`;

export const GrossFeeInputContainer = styled.div`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return `
      display: flex;
      align-items: center;

      .min-input {
        display: flex;
        align-items: center;
        flex: 1;
      }

      .max-input {
        display: flex;
        align-items: center;
        flex: 1;
        padding-left: ${spacing['04']};
      }

      p {
        padding-right: ${spacing['03']};;
      }
  `;
  }}
`;

export const DatePickerConteiner = styled.div`
  ${({ theme }) => {
    const { spacing, shadows, colors } = theme.foundations;
    return `
      display: flex;
      .datepicker-select {
        z-index: 0;
        display: flex;
        flex-direction: column;
        flex: 1;
        .DatePicker__input {
          border: 1px solid ${colors.palette.neutral['500']};
          :focus {
            border: 1px solid ${colors.palette.neutral['700']};
            box-shadow: ${shadows['01']};
          }
        }
      }
      .icon-container {
        display: flex;
        align-items: center;
        padding-left: ${spacing['03']};
      }
  `;
  }}
`;

export const ErrorFieldContainer = styled.div`
  ${({ theme }) => {
    const { spacing, colors } = theme.foundations;
    return `
      display: flex;
      padding-top: ${spacing['01']};
      align-items: center;
      color: ${colors.palette.red['800']};
      p {
        padding-left: ${spacing['02']};
      }
    `;
  }}
`;
