import React, { useState, useContext, useEffect } from 'react';
import _get from 'lodash/get';
import { Modal, Alert } from '@dealsyte/poki';

import { UserContext } from 'app/users/context/UserContext';
import { useTouchedForm, withTouchedComponent } from 'app/utils/hooks/useTouchedForm';

import UserAccountSettings from './components/UserAccountSettings/UserAccountSettings';
import UserPasswordSettings from './components/UserPasswordSettings/UserPasswordSettings';
import AccountBankSettings from './components/AccountBankSettings/AccountBankSettings';
import StateRegistrationSettings from './components/StateRegistrationSettings/StateRegistrationSettings';

import { IProps, EUserSettingType } from './TypedUsersSettingsModal';
import {
  BodyContainer,
  MainContainer,
  SidebarContainer,
  SectionContainer,
} from './StyledUsersSettingsModal';
import FeatureFlag, { EFeatureFlag } from '../../../FeatureFlag';
import useStateModal from '../../../utils/hooks/useStateModal';

/**
 * Modal that contains all the UI required for users to perform profile updates
 * @param show Flag that indicates if the modal is or not being rendered
 * @param onHide Function that turns off the -show- flag in order to unmount the modal
 */
function UsersSettingsModal({ show, onHide }: IProps) {
  const { modalState, hide } = useStateModal('userSettingsModal');
  const [activeSetting, setActiveSetting] = useState<EUserSettingType>(EUserSettingType.ACCOUNT);
  const [promptState, setPromptState] = useState<any>({
    show: false,
    onConfirm: () => {},
    onCancel: () => {},
  });

  const {
    displayName,
    firstName,
    lastName,
    email,
    createdAt,
    bankInfo,
    stateRegInfo,
    isCustomer,
  } = useContext(UserContext);

  const { isTouched, setIsTouched } = useTouchedForm();

  useEffect(() => {
    if (modalState.props && modalState.props.activeSetting) {
      setActiveSetting(_get(modalState.props, 'activeSetting', ''));
    }
  }, [modalState.show, modalState.props]);

  function getActiveSettingUI() {
    switch (activeSetting) {
      case EUserSettingType.ACCOUNT:
        return (
          <UserAccountSettings user={{ displayName, firstName, lastName, email, createdAt }} />
        );

      case EUserSettingType.PASSWORD:
        return <UserPasswordSettings />;

      case EUserSettingType.BANK_ACCOUNT:
        return <AccountBankSettings bankInfo={bankInfo} />;

      case EUserSettingType.STATE_REGISTRATION:
        return <StateRegistrationSettings stateRegInfo={stateRegInfo} />;

      default:
        return (
          <UserAccountSettings user={{ displayName, firstName, lastName, email, createdAt }} />
        );
    }
  }

  function closePrompt() {
    setPromptState((state: any) => ({ ...state, show: false }));
  }

  function onConfirm() {
    onHide();
    hide();
    setIsTouched(false);
    closePrompt();
  }

  function onAbort() {
    closePrompt();
  }

  function handleCancel() {
    if (isTouched) {
      setPromptState({ show: true, onConfirm, onCancel: onAbort });
    } else {
      hide();
      setIsTouched(false);
    }
  }

  function handleActiveSettingChange(newActiveSetting: EUserSettingType) {
    if (isTouched) {
      setPromptState({
        show: true,
        onConfirm: () => {
          if (newActiveSetting !== activeSetting) setActiveSetting(newActiveSetting);
          setIsTouched(false);
        },
        onCancel: onAbort,
      });
      return;
    }
    if (newActiveSetting !== activeSetting) setActiveSetting(newActiveSetting);
  }

  function cancellingModal() {
    return (
      <Alert
        onHide={onAbort}
        disableActionButtons={false}
        show
        content={{
          title: 'Dismissing your changes',
          description:
            'You have not saved your changes, all the data you entered will be lost. Are you sure you want to dismiss?',
        }}
        confirmAction={{
          label: 'Yes',
          action: promptState.onConfirm,
        }}
        declineAction={{
          label: 'No',
          action: promptState.onCancel,
        }}
      />
    );
  }

  return (
    <>
      <Modal
        show={show || modalState.show}
        onHide={handleCancel}
        style={{ padding: 0, height: '615px', minWidth: 1000 }}
      >
        <MainContainer data-testid="UsersSettingsMainContainer">
          <SidebarContainer>
            <h4 style={{ marginBottom: 25 }}>Settings</h4>

            <SectionContainer
              isActive={activeSetting === EUserSettingType.ACCOUNT}
              onClick={() => handleActiveSettingChange(EUserSettingType.ACCOUNT)}
            >
              {EUserSettingType.ACCOUNT}
            </SectionContainer>

            <SectionContainer
              isActive={activeSetting === EUserSettingType.PASSWORD}
              onClick={() => handleActiveSettingChange(EUserSettingType.PASSWORD)}
            >
              {EUserSettingType.PASSWORD}
            </SectionContainer>

            {isCustomer && (
              <FeatureFlag feature={EFeatureFlag.COMPLIANCE}>
                <SectionContainer
                  isActive={activeSetting === EUserSettingType.STATE_REGISTRATION}
                  onClick={() => handleActiveSettingChange(EUserSettingType.STATE_REGISTRATION)}
                >
                  {EUserSettingType.STATE_REGISTRATION}
                </SectionContainer>

                <SectionContainer
                  isActive={activeSetting === EUserSettingType.BANK_ACCOUNT}
                  onClick={() => handleActiveSettingChange(EUserSettingType.BANK_ACCOUNT)}
                >
                  {EUserSettingType.BANK_ACCOUNT}
                </SectionContainer>
              </FeatureFlag>
            )}
          </SidebarContainer>

          <BodyContainer
            isAccountBankBody={
              activeSetting === EUserSettingType.BANK_ACCOUNT ||
              activeSetting === EUserSettingType.STATE_REGISTRATION
            }
          >
            {getActiveSettingUI()}
          </BodyContainer>
        </MainContainer>
      </Modal>
      {promptState.show && cancellingModal()}
    </>
  );
}

export default withTouchedComponent(UsersSettingsModal);
