import * as React from 'react';
import { IconProps } from './types';

export const FilesIcon = ({
  color = 'currentColor',
  width = '16px',
  height = '18px',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 6.45001C15.4913 6.37345 15.4746 6.29803 15.45 6.22501V6.15001C15.4099 6.06432 15.3565 5.98556 15.2917 5.91667L10.2917 0.916672C10.2228 0.851852 10.144 0.798406 10.0583 0.758338C10.0335 0.754805 10.0082 0.754805 9.98333 0.758338C9.89868 0.709789 9.80519 0.678625 9.70833 0.666672H6.33333C5.67029 0.666672 5.03441 0.930064 4.56557 1.3989C4.09673 1.86775 3.83333 2.50363 3.83333 3.16667V4.00001H3C2.33696 4.00001 1.70107 4.2634 1.23223 4.73224C0.763392 5.20108 0.5 5.83696 0.5 6.50001V14.8333C0.5 15.4964 0.763392 16.1323 1.23223 16.6011C1.70107 17.0699 2.33696 17.3333 3 17.3333H9.66667C10.3297 17.3333 10.9656 17.0699 11.4344 16.6011C11.9033 16.1323 12.1667 15.4964 12.1667 14.8333V14H13C13.663 14 14.2989 13.7366 14.7678 13.2678C15.2366 12.7989 15.5 12.163 15.5 11.5V6.50001C15.5 6.50001 15.5 6.50001 15.5 6.45001ZM10.5 3.50834L12.6583 5.66667H11.3333C11.1123 5.66667 10.9004 5.57887 10.7441 5.42259C10.5878 5.26631 10.5 5.05435 10.5 4.83334V3.50834ZM10.5 14.8333C10.5 15.0544 10.4122 15.2663 10.2559 15.4226C10.0996 15.5789 9.88768 15.6667 9.66667 15.6667H3C2.77899 15.6667 2.56702 15.5789 2.41074 15.4226C2.25446 15.2663 2.16667 15.0544 2.16667 14.8333V6.50001C2.16667 6.27899 2.25446 6.06703 2.41074 5.91075C2.56702 5.75447 2.77899 5.66667 3 5.66667H3.83333V11.5C3.83333 12.163 4.09673 12.7989 4.56557 13.2678C5.03441 13.7366 5.67029 14 6.33333 14H10.5V14.8333ZM13.8333 11.5C13.8333 11.721 13.7455 11.933 13.5893 12.0893C13.433 12.2455 13.221 12.3333 13 12.3333H6.33333C6.11232 12.3333 5.90036 12.2455 5.74408 12.0893C5.5878 11.933 5.5 11.721 5.5 11.5V3.16667C5.5 2.94566 5.5878 2.7337 5.74408 2.57742C5.90036 2.42114 6.11232 2.33334 6.33333 2.33334H8.83333V4.83334C8.83333 5.49638 9.09672 6.13227 9.56557 6.60111C10.0344 7.06995 10.6703 7.33334 11.3333 7.33334H13.8333V11.5Z"
      fill={color}
    />
  </svg>
);
