import { useEffect, useMemo } from 'react';

export interface ElementModifierConfig {
  selector: string;
  callback: (element: any, callbackProps: any) => void;
  callbackProps?: any;
  onUnmount?: (element: any, callbackProps: any) => void;
}

export const useElementModifier = (subscription: any, config: ElementModifierConfig) => {
  useEffect(() => {
    const element = document.querySelector(config.selector);
    if (element) {
      config && config.callback && config.callback(element, config.callbackProps || {});
    }
    return () => {
      if (element) {
        config && config.onUnmount && config.onUnmount(element, config.callbackProps || {});
      }
    };
  }, [subscription]);
};

const intercomDefaultModifier = {
  selector: '.intercom-launcher, .intercom-launcher-frame',
  callback: (element: any, props: any) => {
    const show = props && props.show;
    const portal = document.getElementById('drawer-portal');
    element.style.transition = 'all 500ms cubic-bezier(0.72, -0.02, 0.29, 1.03)';
    element.style.right = 'unset';
    if (show) {
      element.style.left = '20px';
    } else if (portal && (!portal.hasChildNodes() || portal.childElementCount < 2)) {
      element.style.left = 'calc(100vw - 60px - 20px)';
    }
  },
  onUnmount: (element: any) => {
    const portal = document.getElementById('drawer-portal');
    if (portal && (!portal.hasChildNodes() || portal.childElementCount < 2)) {
      element.style.transition = 'unset';
      element.style.left = 'unset';
      element.style.right = '20px';
    }
  },
};

export const useIntercomModifier = (subscription: any, config?: ElementModifierConfig) => {
  const modifierObject = useMemo(() => intercomDefaultModifier, [subscription]);
  const defaultConfig = {
    ...modifierObject,
    callbackProps: {
      show: subscription,
    },
  };
  return useElementModifier(subscription, config || defaultConfig);
};
