import * as yup from 'yup';

import {
  BOTH_FIELDS_REQUIRED_MESSAGE,
  MAX_PERCENT_MESSAGE,
  MAX_LOWER_THAN_MIN_MESSAGE,
  MAX_DEAL_SIZE_REACHED_MESSAGE,
  MIN_DEAL_LENGTH_DESCRIPTION,
  MIN_DEAL_SIZE,
} from 'app/common/texts/errorTexts';
import { MAX_DEAL_SIZE } from 'app/utils/constants/app/appConstants';
import validateFileExtension, {
  REQUIRED_MESSAGE,
  validateFileName,
} from '../../../../utils/helpers/formValidators';

const EMPTY_MESSAGE = 'This field cannot be empty';
const PROHIBITED_CHARS_MESSAGE = 'Some characters are not allowed';
const ALPHANUMERIC_REGEX = /^[\w\-\s]*$/;

export const validationSchema = (displayShareDealWithFirm: boolean) =>
  yup.object().shape({
    newDealName: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .trim(EMPTY_MESSAGE)
      .min(2, 'Should be at least 2 characters')
      .max(120, 'Should not be more than 120 characters'),
    companyName: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .trim(EMPTY_MESSAGE),
    transactionType: yup
      .object()
      .required(REQUIRED_MESSAGE)
      .test(
        '',
        'A deal type should be selected',
        option => !!option && !!option.label && !!option.value
      ),
    headquarterAddressLine1: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .trim(EMPTY_MESSAGE),
    country: yup
      .object()
      .required(REQUIRED_MESSAGE)
      .test(
        '',
        'A country should be selected',
        option => !!option && !!option.label && !!option.value
      ),
    state: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .trim(EMPTY_MESSAGE)
      .matches(ALPHANUMERIC_REGEX, PROHIBITED_CHARS_MESSAGE),
    zipCode: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .trim(EMPTY_MESSAGE),
    engagementLetterProvided: yup.boolean().required(REQUIRED_MESSAGE),
    private: displayShareDealWithFirm ? yup.boolean().required(REQUIRED_MESSAGE) : yup.boolean(),

    marketplaceDealType: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .trim(EMPTY_MESSAGE),
    marketplaceDescription: yup.string().when('marketplaceDealType', {
      is: marketplaceDealType => marketplaceDealType === 'collab',
      then: yup
        .string()
        .required(REQUIRED_MESSAGE)
        .trim(EMPTY_MESSAGE)
        .min(100, MIN_DEAL_LENGTH_DESCRIPTION),
    }),

    dealStatus: yup.object().required(REQUIRED_MESSAGE),
    industryType: yup.object().test('INDUSTRY-TEST', REQUIRED_MESSAGE, option => !!option),
    industryTypeSubOptions: yup
      .object()
      .nullable()
      .when('marketplaceDealType', {
        is: marketplaceDealType => marketplaceDealType === 'collab',
        then: yup.object().required(REQUIRED_MESSAGE),
      }),
    monthlyRetainer: yup
      .boolean()
      .nullable()
      .when('marketplaceDealType', {
        is: marketplaceDealType => marketplaceDealType === 'collab',
        then: yup.boolean().required(REQUIRED_MESSAGE),
      }),
    retainerAmount: yup
      .number()
      .nullable()
      .when('monthlyRetainer', {
        is: monthlyRetainer => monthlyRetainer,
        then: yup.number().required(REQUIRED_MESSAGE),
      }),
    expectedSize: yup
      .number()
      .nullable()
      .max(MAX_DEAL_SIZE, MAX_DEAL_SIZE_REACHED_MESSAGE)
      .when('marketplaceDealType', {
        is: marketplaceDealType => marketplaceDealType === 'collab',
        then: yup
          .number()
          .required(REQUIRED_MESSAGE)
          .min(100000, MIN_DEAL_SIZE),
      }),
    minFee: yup
      .number()
      .max(yup.ref('maxFee'), MAX_LOWER_THAN_MIN_MESSAGE)
      .when('marketplaceDealType', {
        is: marketplaceDealType => marketplaceDealType === 'collab',
        then: yup.number().required(REQUIRED_MESSAGE),
      }),
    maxFee: yup
      .number()
      .max(100, MAX_PERCENT_MESSAGE)
      .when('marketplaceDealType', {
        is: marketplaceDealType => marketplaceDealType === 'collab',
        then: yup.number().required(REQUIRED_MESSAGE),
      }),
    grossFeeEarned: yup.boolean().when(['minFee', 'maxFee'], {
      is: (minFee, maxFee) =>
        (typeof minFee === 'number' && typeof maxFee !== 'number') ||
        (typeof minFee !== 'number' && typeof maxFee === 'number'),
      then: yup.boolean().required(BOTH_FIELDS_REQUIRED_MESSAGE),
    }),

    files: yup
      .array()
      .required('At least one file is required')
      .test('', 'Some file has an invalid name', array => !!array && validateFileName(array))
      .test(
        '',
        'Some file has an invalid extension',
        array => !!array && validateFileExtension(array)
      ),
    publiclyTradedCompany: yup.boolean().required(REQUIRED_MESSAGE),
    ticker: yup
      .string()
      .nullable()
      .trim(EMPTY_MESSAGE)
      .when('publiclyTradedCompany', {
        is: publiclyTradedCompany => publiclyTradedCompany,
        then: yup.string().required(REQUIRED_MESSAGE),
      }),
    isInitialDueDiligence: yup.boolean().required(REQUIRED_MESSAGE),
    initialDueDiligenceNote: yup.string().when('isInitialDueDiligence', {
      is: isInitialDueDiligence => isInitialDueDiligence,
      then: yup
        .string()
        .required(REQUIRED_MESSAGE)
        .trim(EMPTY_MESSAGE),
    }),
    isAnyRedFlag: yup.boolean().required(REQUIRED_MESSAGE),
    anyRedFlagNote: yup.string().when('isAnyRedFlag', {
      is: isAnyRedFlag => isAnyRedFlag,
      then: yup
        .string()
        .required(REQUIRED_MESSAGE)
        .trim(EMPTY_MESSAGE),
    }),
    isPotentialConflicts: yup.boolean().required(REQUIRED_MESSAGE),
    potentialConflictsNote: yup.string().when('isPotentialConflicts', {
      is: isPotentialConflicts => isPotentialConflicts,
      then: yup
        .string()
        .required(REQUIRED_MESSAGE)
        .trim(EMPTY_MESSAGE),
    }),
    targetInvestors: yup.object().test('TARGET-TEST', REQUIRED_MESSAGE, option => !!option),
    foreignSolicitation: yup.boolean().required(REQUIRED_MESSAGE),
    foreignSolicitationIntitutionalClients: yup.boolean().when('foreignSolicitation', {
      is: foreignSolicitation => foreignSolicitation,
      then: yup.boolean().required(REQUIRED_MESSAGE),
    }),
    foreignSolicitationCountries: yup.array().when('foreignSolicitation', {
      is: foreignSolicitation => foreignSolicitation,
      then: yup.array().required(REQUIRED_MESSAGE),
    }),
    foreignSolicitationPreexistingRelationship: yup.string().when('foreignSolicitation', {
      is: foreignSolicitation => foreignSolicitation,
      then: yup.string().required(REQUIRED_MESSAGE),
    }),
    conclusions: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .trim(EMPTY_MESSAGE),
  });
