import styled, { DefaultTheme } from 'styled-components';

export const MainContainer = styled.div``;

export const LabelContainer = styled.div`
  ${({ theme }) => {
    const { typography, colors, spacing } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['04']};
      color: ${colors.palette.neutral['700']};
      margin-bottom: ${spacing['02']};
    `;
  }}
`;

export const BarContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PercentageLabel = styled.div`
  ${({ theme }) => {
    const { typography, colors, spacing } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['02']};
      color: ${colors.palette.neutral['500']};
      margin-left: ${spacing['01']};
    `;
  }}
`;

export const Bar = styled.div`
  ${({ theme, percentage = 0 }: { theme: DefaultTheme; percentage: number }) => {
    const { colors, sizes, radii } = theme.foundations;
    return `
      width 100%;
      height: ${sizes['02']};
      background: ${colors.background.secondary};
      position: relative;
      overflow: hidden;
      flex: 1;
    
      &::before {
       content: '';
       height: ${sizes['02']};
       position: absolute;
       top: 0;
       left: 0;
       background: ${colors.primary.default};
       width: ${percentage}%;
       transition: width 300ms ease-out;
      }

      &.accent {
        &::before {
          background: ${colors.palette.accent};
        }
      }

      &.round {
        border-radius: ${radii.lg};
        &::before {
          border-radius: ${radii.lg};
        }
      }
    `;
  }}
`;
