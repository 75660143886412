// Libraries
import React from 'react';
import { IconProps } from './types';

export const ExternalLinkIcon = ({
  width = '10px',
  height = '10px',
  color = '#A33C00',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 4.41C7.86739 4.41 7.74021 4.46268 7.64645 4.55645C7.55268 4.65021 7.5 4.77739 7.5 4.91V8.5C7.5 8.63261 7.44732 8.75979 7.35355 8.85355C7.25979 8.94732 7.13261 9 7 9H1.5C1.36739 9 1.24021 8.94732 1.14645 8.85355C1.05268 8.75979 1 8.63261 1 8.5V3C1 2.86739 1.05268 2.74021 1.14645 2.64645C1.24021 2.55268 1.36739 2.5 1.5 2.5H5.09C5.22261 2.5 5.34979 2.44732 5.44355 2.35355C5.53732 2.25979 5.59 2.13261 5.59 2C5.59 1.86739 5.53732 1.74021 5.44355 1.64645C5.34979 1.55268 5.22261 1.5 5.09 1.5H1.5C1.10218 1.5 0.720644 1.65804 0.43934 1.93934C0.158035 2.22064 0 2.60218 0 3V8.5C0 8.89782 0.158035 9.27936 0.43934 9.56066C0.720644 9.84196 1.10218 10 1.5 10H7C7.39782 10 7.77936 9.84196 8.06066 9.56066C8.34196 9.27936 8.5 8.89782 8.5 8.5V4.91C8.5 4.77739 8.44732 4.65021 8.35355 4.55645C8.25979 4.46268 8.13261 4.41 8 4.41ZM9.96 0.31C9.90926 0.187825 9.81217 0.0907374 9.69 0.04C9.62989 0.0143795 9.56534 0.000789994 9.5 0H6.5C6.36739 0 6.24021 0.0526784 6.14645 0.146447C6.05268 0.240215 6 0.367392 6 0.5C6 0.632608 6.05268 0.759785 6.14645 0.853553C6.24021 0.947322 6.36739 1 6.5 1H8.295L3.145 6.145C3.09814 6.19148 3.06094 6.24678 3.03555 6.30771C3.01017 6.36864 2.9971 6.43399 2.9971 6.5C2.9971 6.56601 3.01017 6.63136 3.03555 6.69229C3.06094 6.75322 3.09814 6.80852 3.145 6.855C3.19148 6.90186 3.24678 6.93906 3.30771 6.96445C3.36864 6.98983 3.43399 7.0029 3.5 7.0029C3.56601 7.0029 3.63136 6.98983 3.69229 6.96445C3.75322 6.93906 3.80852 6.90186 3.855 6.855L9 1.705V3.5C9 3.63261 9.05268 3.75979 9.14645 3.85355C9.24021 3.94732 9.36739 4 9.5 4C9.63261 4 9.75979 3.94732 9.85355 3.85355C9.94732 3.75979 10 3.63261 10 3.5V0.5C9.99921 0.434661 9.98562 0.370111 9.96 0.31Z"
      fill={color}
    />
  </svg>
);
