import React, { useCallback, useContext } from 'react';
import { useSplitClient } from '@splitsoftware/splitio-react';

import { UserContext } from 'app/users/context/UserContext';

type IProps = {
  feature: EFeatureFlag;
  children?: React.ReactElement;
  fallback?: React.ReactElement;
};

export enum EFeatureFlag {
  COMPLIANCE = 'COMPLIANCE',
  VDR_TRANSACTION_DETAILS_MODAL = 'VDR_TRANSACTION_DETAILS_MODAL',
  VDR_IMPORT_FROM_GOOGLE_DRIVE = 'VDR_IMPORT_FROM_GOOGLE_DRIVE',
  COMPLIANCE_TRACKER_V2 = 'COMPLIANCE_TRACKER_V2',
  VDR_HOME_REDIRECTION = 'VDR_HOME_REDIRECTION',
  CJ_ONBOARDING = 'CJ_ONBOARDING',
  CAAS_DEALS_FILTERS = 'CAAS_DEALS_FILTERS',
  COMPLIANCE_FORM_EDIT = 'COMPLIANCE_FORM_EDIT',
  MY_ACCOUNT_SECTION = 'MY_ACCOUNT_SECTION',
  FM_FEE_GUIDE_EXPORT = 'FM_FEE_GUIDE_EXPORT',
  DEAL_COMPLIANCE_FORM_EDIT = 'DEAL_COMPLIANCE_FORM_EDIT',
  CAAS_NEW_DEAL_EL_STEP = 'CAAS_NEW_DEAL_EL_STEP',
  SS_HIDE_PAYMENTS_AND_SUBSCRIPTIONS = 'SS_HIDE_PAYMENTS_AND_SUBSCRIPTIONS',
  DEAL_REGULATION_SECTION = 'DEAL_REGULATION_SECTION',
  DF_ADDITIONAL_FEE = 'DF_ADDITIONAL_FEE',
  CN_DEAL_REFFERAL_BANNER = 'CN_DEAL_REFFERAL_BANNER',
  CN_DEAL_PREFERENCE_MODAL = 'CN_DEAL_PREFERENCE_MODAL',
  WF_PERSONALIZED_DASHBOARDS = 'WF_PERSONALIZED_DASHBOARDS',
  CN_DEAL_CREATE_AND_EDIT = 'CN_DEAL_CREATE_AND_EDIT',
}

const allFeatures: SplitIO.MockedFeaturesMap = Object.keys(EFeatureFlag).reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: { treatment: 'on', config: null },
  }),
  {}
);

export const splitioConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: process.env.REACT_APP_SPLITIO_API_KEY as string,
    key: '',
  },
  startup: {
    readyTimeout: 10,
    requestTimeoutBeforeReady: 10,
  },
  storage: {
    type: 'LOCALSTORAGE',
    prefix: 'FINALIS_CLIENT',
  },
  // By default in localhost mode all features are on
  features: {
    ...allFeatures,
    // all features that want to override value
  },
};

const ALL_FEATURE_FLAGS = Object.keys(EFeatureFlag);

/**
 * Filter a feature rendering according to a flag defined in splitIO.
 * @param feature Feature name to be filtered.
 * @param children Feature content.
 * @param fallback Content that will be rendered instead of the feature in case of be disabled.
 */
const FeatureFlag = ({ feature, children, fallback }: IProps): React.ReactElement | null => {
  const { email } = useContext(UserContext);
  const { client, isReady } = useSplitClient({ splitKey: email });

  const renderChildren = useCallback(() => {
    const treatments = (client && client.getTreatmentsWithConfig(ALL_FEATURE_FLAGS)) || {};
    if (treatments[feature] && treatments[feature].treatment === 'on') {
      return children || null;
    }
    if (treatments[feature] && treatments[feature].treatment === 'off') {
      return fallback || null;
    }
    return null;
  }, [client, feature, children, fallback]);

  if (process.env.NODE_ENV === 'test' || process.env.CI === 'true') return children || null;
  if (!isReady || !email || !children) return null;

  return renderChildren();
};

export default FeatureFlag;
