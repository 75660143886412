import styled from 'styled-components';

export const MessageBoxWrapper = styled.div<{ align?: string }>`
  ${({ theme, align = 'center' }) => {
    const { colors, sizes, typography } = theme.foundations;
    return `
      background: ${colors.palette.neutral['200']};
      border-radius:${sizes['02']};
      padding:${sizes['04']} ${sizes['06']};
      margin-bottom: ${sizes['04']};
      display: flex;
      align-items: ${align};
      p {
        font-size: ${typography.sizes['03']};
        line-height: 150%;
        font-family: ${typography.fonts.body};
        letter-spacing: 0.25px;
      }
      > svg {
        color: ${colors.palette.neutral['800']};
        margin-right: ${sizes['04']};
        flex-shrink: 0;
      }`;
  }}
`;
