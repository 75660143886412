import styled from 'styled-components';

interface CommonTableProps {
  minWidth?: number | string;
  width?: number | string;
  textAlign?: string;
  marginBottom?: number | string;
}

const setVal = (val?: number | string): string => (typeof val === 'string' ? val : `${val}px`);

export const Table = styled.table`
  display: block;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  font-family: 'Effra';
  border-collapse: collapse;
  margin-bottom: ${({ marginBottom = 50 }: CommonTableProps) => setVal(marginBottom)};
`;

export const HeaderRow = styled.tr`
  border-bottom: 1px solid #ededf7;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid #ededf7;
  &:hover {
    background: #f8f8fc;
  }
`;

export const TableHeader = styled.th`
  font-weight: 500;
  padding: 16px 10px;
  text-align: left;
  min-width: ${({ minWidth = 150 }: CommonTableProps) => setVal(minWidth)};
`;

export const TableCell = styled.td`
  ${({ minWidth = 150, width = '33%', textAlign = 'left' }: CommonTableProps) => `
    padding: 16px 10px;
    text-align: left;
    width: ${setVal(width)};
    min-width: ${setVal(minWidth)};
    text-align: ${textAlign};
  `}
`;
