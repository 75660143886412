import styled from 'styled-components';

interface CommonCardProps {
  padding?: number | string;
}

interface CardHeaderProps extends CommonCardProps {
  withSeparator?: boolean;
}

interface CardFooterProps extends CommonCardProps {
  withSeparator?: boolean;
}

interface CardProps {
  variant?: 'outline' | 'default' | 'marketplaceDealCard';
  borderRadius?: number | string;
  margin?: number | string;
}

type CardBodyProps = CommonCardProps;

const setVal = (val?: number | string): string => (typeof val === 'string' ? val : `${val}px`);

const cardVariants = ({ variant, borderRadius = 6 }: CardProps) => {
  switch (variant) {
    case 'outline':
      return `
        background: none;
        border-radius: ${setVal(borderRadius)};
        border: 1px solid #EDEDF7;
      `;
    case 'marketplaceDealCard':
      return `
        border-radius: ${setVal(borderRadius)};
        border: 1px solid #E6EAF9;
      `;
    case 'default':
    default:
      return `
        background: white;
        border-radius: ${setVal(borderRadius)};
      `;
  }
};

export const Card = styled.div`
  ${(props: CardProps) => cardVariants(props)}
  ${({ margin = 'none' }: CardProps) => `margin: ${setVal(margin)};`}
`;

export const CardDivider = styled.div`
  height: 1px;
  border-bottom: 1px solid #edf0fd;
`;

export const CardHeader = styled.div`
  padding: ${({ padding = 32 }: CardHeaderProps) => setVal(padding)};
  border-bottom: ${({ withSeparator }: CardHeaderProps) =>
    withSeparator ? '1px solid #EFEFEF' : 'none'};
`;

export const CardBody = styled.div`
  padding: ${({ padding = 32 }: CardBodyProps) => setVal(padding)};
`;

export const CardFooter = styled.div`
  padding: ${({ padding = 32 }: CardFooterProps) => setVal(padding)};
  border-top: ${({ withSeparator }: CardHeaderProps) =>
    withSeparator ? '1px solid #EFEFEF' : 'none'};
`;
