import { gql } from '@apollo/client';

import { DealFragment } from './fragments';

export const GET_DEAL = gql`
  query GET_DEAL_QUERY($id: ID!) {
    deal(id: $id) {
      id
      name
      createdAt
      updatedAt
      type
      companyName
      companyAddress
      companyAgent
      projectName
      mode
      private
      status
      details {
        dealStatus
        expectedCommissions
        expectedSize
        loiProvided
        closingDate
      }
      UserDeal {
        role
        side
      }
      DealOwner {
        id
      }
      metadata {
        key
        type
        value
      }
    }
  }
`;

export const GET_DEALS = gql`
  query GET_DEALS_QUERY {
    deals {
      ...DealBasicFragment
    }
  }
  ${DealFragment}
`;

export const GET_FIRM_DEALS = gql`
  query GET_FIRM_DEALS_QUERY($firmId: ID!) {
    firmDeals(firmId: $firmId) {
      ...DealBasicFragment
    }
  }
  ${DealFragment}
`;

export const GET_DEALS_TO_SELECT = gql`
  query GET_DEALS_TO_SELECT_QUERY {
    deals {
      id
      name
    }
  }
`;

export const GET_DEALS_METADATA = gql`
  query GET_DEALS_METADATA_QUERY {
    dealMetadata {
      dealId
      dealName
      dealStatus
      expectedCommissions
      expectedSize
      loiProvided
      closingDate
    }
  }
`;

export const GET_FILTERED_DEALS = gql`
  query GET_FILTERED_DEALS_QUERY(
    $searchValue: String
    $page: Int
    $pageSize: Int
    $userId: String
    $firmId: String
    $filter: String
    $sort: String
    $sortField: String
  ) {
    dealsPaginated(
      searchValue: $searchValue
      page: $page
      pageSize: $pageSize
      userId: $userId
      firmId: $firmId
      filter: $filter
      sort: $sort
      sortField: $sortField
    ) {
      page
      totalPages
      pageSize
      totalRecords
      deals {
        ...DealBasicFragment
      }
    }
  }
  ${DealFragment}
`;

export const GET_FILTERED_DEALS_NAMES = gql`
  query GET_FILTERED_DEALS_NAMES_QUERY(
    $searchValue: String
    $page: Int
    $pageSize: Int
    $userId: String
    $firmId: String
    $filter: String
    $sort: String
  ) {
    dealsPaginated(
      searchValue: $searchValue
      page: $page
      pageSize: $pageSize
      userId: $userId
      firmId: $firmId
      filter: $filter
      sort: $sort
    ) {
      page
      totalPages
      pageSize
      totalRecords
      deals {
        name
      }
    }
  }
`;
