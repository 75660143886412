export const DEFAULT_STEP_ERR_MSG = 'Please fill out the fields before continuing.';

export const OBA_FORM_MESSAGES = {
  ENTITY_INPUT_ERR: 'Please provide a valid entity name',
  START_DATE_ERR: 'Please provide a valid start date',
  ADDRESS_ERR: 'Please provide a valid street address',
  CITY_ERR: 'Please provide a valid city',
  STATE_PROVINCE_ERR: 'Please provide a valid state / province',
  POSTAL_ZIP_ERR: 'Please provide a valid Postal / Zip Code',
  COUNTRY_ERR: 'Please provide a valid Country',
  POSITION_ERR: 'Please provide a valid position',
  NATURE_OF_BUSINESS_ERR: 'Please provide a valid nature of business',
  DUTY_ERR: 'Please provide a valid duty description',
  HOURS_AMOUNT_ERR: 'Please approximate hours/month devoted to this potential OBA',
  HOURS_DEVOTED_ERR: 'Please provide the number of hours you devoted',
  OTHERS_COMPENSATION_ERR: 'Please describe compensation',
  OTHER_LICENSES_LABEL: 'Please provide other licenses besides securities license(s)',
  OTHER_LICENSES_PLACEHOLDER: 'Other licenses besides securities license(s)',
  OTHER_LICENSES_ERROR_MESSAGE: 'Please provide valid licenses besides securities license(s).',
  WEBSITES_CONNECTED_LABEL: 'List the websites connected to the business or organization',
  WEBSITES_CONNECTED_PLACEHOLDER: 'https://my.finalis.com',
  WEBSITES_CONNECTED_ERROR_MESSAGE:
    'Please provide the websites connected to the business or organization.',
  OTHER_INFO_LABEL:
    "Provide any other information you believe would be helpful in the Firm's consideration of your request",
  OTHER_INFO_PLACEHOLDER: "Other helpful information in the Firm's consideration of your request",
  OTHER_INFO_ERROR_MESSAGE:
    "Please provide any other information you believe would be helpful in the Firm's consideration of your request.",
  INVESTMENT_LABEL: 'Is the business investment-related?',
  INVESTMENT_TOOLTIP:
    'Pertains to securities, commodities, banking, insurance, or real estate (including, but not limited to, acting as or being associated with a broker-dealer, issuer, investment company, investment adviser, futures sponsor, bank, or savings association)',
};

export const OBACCT_FORM_MESSAGES = {
  ACCOUNT_OWNER_ERR: 'Please provide a valid account owner',
  ACCOUNT_NUMBER_ERR: 'Please provide a valid account number',
  FINANCIAL_INSTITUTION_NAME_ERR: 'Please provide a valid financial institution name',
};

export const COMPLIANCE_EMAIL = 'compliance@finalis.com';
