import * as React from 'react';
import { IconProps } from './types';

export const LightningIcon = ({
  width = '8px',
  height = '11px',
  color = 'currentColor',
  style,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    style={style}
    viewBox="0 0 8 11"
    fill="none"
  >
    <path
      d="M7.93494 3.30029C7.89769 3.21465 7.83719 3.14116 7.76031 3.08813C7.68343 3.03511 7.59323 3.00468 7.49994 3.00029H5.20994L5.84494 0.630293C5.86516 0.556131 5.86801 0.478304 5.85327 0.40286C5.83853 0.327417 5.80659 0.256388 5.75994 0.195293C5.71336 0.134786 5.65354 0.0857507 5.58507 0.0519489C5.5166 0.0181471 5.4413 0.00047678 5.36494 0.00029324H1.86494C1.75206 -0.00357338 1.64119 0.0308931 1.5504 0.0980792C1.45961 0.165265 1.39424 0.261212 1.36494 0.370293L0.0249387 5.37029C0.0047148 5.44446 0.00186292 5.52228 0.0166048 5.59773C0.0313467 5.67317 0.0632851 5.7442 0.109939 5.80529C0.157031 5.86647 0.217652 5.91592 0.287049 5.94975C0.356447 5.98358 0.432736 6.00088 0.509939 6.00029H2.44494L1.53994 9.37029C1.51029 9.47896 1.5182 9.59446 1.56239 9.69807C1.60658 9.80168 1.68446 9.88734 1.78342 9.94115C1.88237 9.99497 1.9966 10.0138 2.10759 9.99458C2.21858 9.97537 2.31983 9.91924 2.39494 9.83529L7.84494 3.83529C7.90983 3.76424 7.95284 3.67596 7.96881 3.58106C7.98477 3.48616 7.97301 3.38867 7.93494 3.30029ZM3.03994 7.64029L3.57494 5.64029C3.59516 5.56613 3.59802 5.4883 3.58327 5.41286C3.56853 5.33742 3.53659 5.26639 3.48994 5.20529C3.44336 5.14479 3.38354 5.09575 3.31507 5.06195C3.2466 5.02815 3.1713 5.01048 3.09494 5.01029H1.17494L2.24494 1.00029H4.70994L4.07494 3.37029C4.05455 3.44582 4.05217 3.52508 4.06799 3.60169C4.08381 3.67831 4.11739 3.75014 4.16602 3.81142C4.21465 3.8727 4.27698 3.92171 4.34801 3.95451C4.41903 3.98732 4.49675 4.003 4.57494 4.00029H6.35994L3.03994 7.64029Z"
      fill={color}
    />
  </svg>
);
