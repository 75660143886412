import gql from 'graphql-tag';

import { GroupSettingsFragment, UserFragment, GroupFragment } from './fragments';

export const LOGIN_AUTH0 = gql`
  mutation LOGIN_AUTH0_MUTATION {
    loginAuth0 {
      token
    }
  }
`;

export const LOGIN_LOCAL = gql`
  mutation LOGIN_LOCAL_MUTATION($email: String!, $password: String!, $totp: String) {
    loginLocal(email: $email, password: $password, totp: $totp) {
      token
    }
  }
`;

export const LOGIN_THIRD_PARTY = gql`
  mutation LOGIN_THIRD_PARTY_MUTATION(
    $provider: ExternalProvider!
    $token: String!
    $totp: String
  ) {
    loginThirdParty(provider: $provider, token: $token, totp: $totp) {
      token
    }
  }
`;

export const LOGOUT = gql`
  mutation LOGOUT_MUTATION {
    logout {
      status
    }
  }
`;

export const SIGNUP_LOCAL = gql`
  mutation SIGNUP_LOCAL_MUTATION($code: String!, $userData: LocalSignUp!) {
    signUpLocal(code: $code, userData: $userData) {
      email
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation CREATE_ORGANIZATION_MUTATION($name: String!, $parentId: String!) {
    createOrganization(name: $name, parentId: $parentId) {
      ...GroupSettingsFragment
    }
  }
  ${GroupSettingsFragment}
`;

export const REMOVE_USER_FROM_ORGANIZATION = gql`
  mutation REMOVE_USER_FROM_ORGANIZATION_MUTATION(
    $groupId: String!
    $userId: String!
    $dealId: String!
  ) {
    removeUserFromGroup(groupId: $groupId, userId: $userId, dealId: $dealId) {
      ...GroupFragment
    }
  }
  ${GroupFragment}
`;

export const UPDATE_ORGANIZATION_NAME = gql`
  mutation UPDATE_ORGANIZATION_NAME_MUTATION($organizationId: String!, $name: String!) {
    updateOrganizationName(organizationId: $organizationId, name: $name) {
      ...GroupFragment
    }
  }
  ${GroupFragment}
`;

export const REMOVE_ORGANIZATION = gql`
  mutation REMOVE_ORGANIZATION_MUTATION(
    $parentGroupId: String!
    $dealId: String!
    $groupId: String!
  ) {
    removeOrganization(parentGroupId: $parentGroupId, dealId: $dealId, groupId: $groupId) {
      ...GroupSettingsFragment
    }
  }
  ${GroupSettingsFragment}
`;

export const CREATE_BIDDER = gql`
  mutation CREATE_BIDDER_MUTATION($name: String!) {
    createBidder(name: $name) {
      ...GroupSettingsFragment
    }
  }
  ${GroupSettingsFragment}
`;

export const INVITE_USERS = gql`
  mutation INVITE_USERS_MUTATION($users: [CreateUserInput!]!) {
    inviteUsers(users: $users) {
      email
      role
      invitationId
      expiresAt
      expired
      id
      displayName
    }
  }
`;

export const RESEND_INVITATION = gql`
  mutation RESEND_INVITATION_MUTATION($invitationId: ID!) {
    resendInvitation(invitationId: $invitationId) {
      email
    }
  }
`;

export const UNLINK_FROM_DEAL = gql`
  mutation UNLINK_FROM_DEAL_MUTATION($dealId: ID!) {
    unlinkFromDeal(dealId: $dealId) {
      status
      statusText
      dealId
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UPDATE_PASSWORD_MUTATION($currentPassword: String!, $newPassword: String!) {
    updatePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      status
      statusText
    }
  }
`;

export const UPDATE_USER_METADATA = gql`
  mutation UPDATE_USER_METADATA($input: UserMetadataInput!) {
    updateUserMetadata(input: $input) {
      key
      type
      value
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UPDATE_PROFILE_MUTATION(
    $newProfile: UpdateProfileInput
    $bankInfo: BankAccountInput
    $statesInfo: StatesInput
  ) {
    updateProfile(newProfile: $newProfile, bankInfo: $bankInfo, statesInfo: $statesInfo) {
      ...UserBasicFragment
    }
  }
  ${UserFragment}
`;

export const UPDATE_USER_ROLE = gql`
  mutation UPDATE_USER_ROLE_MUTATION($newUserRole: UpdateUserRoleInput!) {
    updateUserRole(newUserRole: $newUserRole) {
      id
      dealId
      role
      displayName
    }
  }
`;

export const EDIT_PERMISSIONS = gql`
  mutation EDIT_PERMISSIONS_MUTATION(
    $fsoIds: [ID!]!
    $ownerIds: [ID!]!
    $permissionEntityId: ID!
    $remove: Boolean
  ) {
    setPermission(
      fsoIds: $fsoIds
      ownerIds: $ownerIds
      permissionEntityId: $permissionEntityId
      remove: $remove
    ) {
      id
      entityId
      ownerId
      permissionEntityId
      active
    }
  }
`;

export const REQUEST_RECOVERY_PASSWORD_CODE = gql`
  mutation REQUEST_RECOVERY_PASSWORD_CODE_MUTATION($email: String!) {
    requestRecoveryPasswordCode(email: $email) {
      email
      maxAttempts
    }
  }
`;

export const VALIDATE_RECOVERY_PASSWORD_CODE = gql`
  mutation VALIDATE_RECOVERY_PASSWORD_CODE_MUTATION($email: String!, $code: String!) {
    validateRecoveryPasswordCode(email: $email, code: $code) {
      key
    }
  }
`;

export const VALIDATE_RECOVERY_PASSWORD_KEY = gql`
  mutation VALIDATE_RECOVERY_PASSWORD_KEY_MUTATION(
    $email: String!
    $key: String!
    $newPassword: String!
  ) {
    validateRecoveryPasswordKey(email: $email, key: $key, newPassword: $newPassword) {
      status
      statusText
    }
  }
`;

export const REQUEST_MFA_KEY = gql`
  mutation REQUEST_MFA_KEY_MUTATION {
    requestMFAKey {
      key
      exp
    }
  }
`;

export const REQUEST_MFA_ENABLE = gql`
  mutation REQUEST_MFA_ENABLE_MUTATION($token: String!, $password: String) {
    requestMFAEnable(token: $token, password: $password) {
      status
    }
  }
`;

export const REQUEST_MFA_DISABLE = gql`
  mutation REQUEST_MFA_DISABLE_MUTATION($password: String) {
    requestMFADisable(password: $password) {
      status
    }
  }
`;

export const CREATE_LINK_TOKEN = gql`
  mutation CREATE_LINK_TOKEN {
    createLinkToken {
      expiration
      linkToken
      requestId
    }
  }
`;

export const ADD_BANK_TO_USER = gql`
  mutation ADD_BANK_TO_USER($customerId: String!, $publicToken: String!, $accountId: String!) {
    addBankToUser(customerId: $customerId, publicToken: $publicToken, accountId: $accountId) {
      id
      bankName
      currency
      last4
    }
  }
`;

export const ADD_CARD_TO_USER = gql`
  mutation ADD_CARD_TO_USER($customerId: String!, $stripeCardTokenId: String!) {
    addCardToUser(customerId: $customerId, stripeCardTokenId: $stripeCardTokenId) {
      id
      brand
      expYear
      expMonth
      last4
      holderName
    }
  }
`;

export const FIND_AND_CREATE_PAYMENT_CONFIG = gql`
  mutation FIND_AND_CREATE_PAYMENT_CONFIG {
    findAndCreatePaymentConfig {
      stripeCustomerId
    }
  }
`;

export const SET_DEFAULT_PAYMENT_METHOD = gql`
  mutation SET_DEFAULT_PAYMENT_METHOD($customerId: String!, $defaultMethodId: String!) {
    setDefaultPaymentMethod(customerId: $customerId, defaultMethodId: $defaultMethodId) {
      id
      defaultSource
      invoiceSettings {
        defaultPaymentMethod
      }
      sources {
        __typename
      }
    }
  }
`;

export const DELETE_STRIPE_SOURCE = gql`
  mutation DELETE_STRIPE_SOURCE($customerId: String!, $sourceId: String!) {
    deleteStripeSource(customerId: $customerId, sourceId: $sourceId)
  }
`;

export const SET_COOKIES = gql`
  mutation SET_COOKIES($auth0Token: String!, $finalisToken: String!) {
    setCookies(auth0Token: $auth0Token, finalisToken: $finalisToken)
  }
`;
