import styled from 'styled-components';

export const OptionFormContainer = styled.div`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return `
      display: flex;
      gap: ${spacing['04']};
      flex-direction: column;
  `;
  }}
`;

export const OptionFormSubtitle = styled.div`
  ${({ theme }) => {
    const { colors, spacing, typography } = theme.foundations;
    return `
      font-family:${typography.fonts.body};
      font-style: normal;
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['04']};
      line-height: 150%;
      letter-spacing: 0.25px;
      color: ${colors.palette.neutral['600']};
      margin-bottom: ${spacing['05']};
  `;
  }}
`;
