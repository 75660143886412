import React, { useEffect } from 'react';
import _get from 'lodash/get';

type ScrollOptions = 'top' | 'bottom' | number;

interface UseScrollOptions {
  scrollOnInitTo: ScrollOptions;
}

interface UseScroll {
  scrollTo: (option: ScrollOptions) => void;
  scrollToView: (option: any) => void;
}

export const useScroll = (
  element: React.MutableRefObject<HTMLDivElement | undefined>,
  scrollOptions?: UseScrollOptions
): UseScroll => {
  const scrollTo = (option: ScrollOptions) => {
    if (element.current) {
      const options = {
        top: element.current.scrollTop,
        bottom: element.current.scrollHeight,
      };
      const selectedOption = _get(options, option, option);
      element.current.scrollTo({ behavior: 'smooth', top: selectedOption });
    }
  };

  const scrollToView = (option: any) => {
    if (element.current) {
      element.current.scrollIntoView(option);
    }
  };

  useEffect(() => {
    if (element.current && scrollOptions && scrollOptions.scrollOnInitTo) {
      scrollTo(scrollOptions.scrollOnInitTo);
    }
  }, [element.current]);

  return {
    scrollTo,
    scrollToView,
  };
};
