import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';

interface UseSearchQuery {
  setQueryParams: (params: string) => void;
  getQueryParams: (fields?: string[]) => URLSearchParams;
}

export const SearchQueryContext = React.createContext<UseSearchQuery>({} as UseSearchQuery);
export const SearchQueryProvider = SearchQueryContext.Provider;

export const useSearchQuery = (): UseSearchQuery => {
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState({});

  const setQueryParams = (params: any = {}) => {
    setSearchQuery(query => ({
      ...query,
      ...params,
    }));
  };

  const getQueryParams = (fields: string[] = []) => {
    const params = new URLSearchParams(_get(history, 'location.search', undefined));
    const filtered =
      fields.length > 0
        ? new URLSearchParams(
            fields.reduce((prev, curr) => {
              const value = params.get(curr);
              const prop = value ? { [curr]: value } : {};
              return {
                ...prev,
                ...prop,
              };
            }, {})
          )
        : params;
    return filtered;
  };

  useEffect(() => {
    if (Object.keys(searchQuery).length > 0) {
      const filtered = Object.keys(searchQuery)
        .filter(key => {
          const value = searchQuery[key as keyof typeof searchQuery];
          return value !== undefined && value !== '';
        })
        .reduce(
          (prev, curr) => ({ ...prev, [curr]: searchQuery[curr as keyof typeof searchQuery] }),
          {}
        );
      history.replace({ search: new URLSearchParams(filtered).toString() });
    }
  }, [searchQuery]);

  return {
    setQueryParams,
    getQueryParams,
  };
};
