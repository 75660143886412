import * as React from 'react';
import { IconProps } from './types';

export const ChatConversationIcon = ({
  width = '14px',
  height = '14px',
  color = 'currentColor',
  style = {},
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6667 4.33594H11V2.33594C11 1.8055 10.7893 1.2968 10.4142 0.921724C10.0391 0.546651 9.53044 0.335938 9 0.335938H2.33334C1.8029 0.335938 1.2942 0.546651 0.919122 0.921724C0.54405 1.2968 0.333336 1.8055 0.333336 2.33594V10.3359C0.333993 10.4676 0.373594 10.596 0.44715 10.7052C0.520705 10.8143 0.624923 10.8993 0.746669 10.9493C0.825789 10.9866 0.912552 11.0049 1 11.0026C1.08774 11.0031 1.17472 10.9863 1.25594 10.9531C1.33717 10.9199 1.41104 10.8711 1.47334 10.8093L3.34667 8.92927H4.33334V9.88927C4.33334 10.4197 4.54405 10.9284 4.91912 11.3035C5.2942 11.6786 5.8029 11.8893 6.33334 11.8893H10.9467L12.5267 13.4759C12.589 13.5377 12.6628 13.5866 12.7441 13.6198C12.8253 13.653 12.9123 13.6698 13 13.6693C13.0875 13.6715 13.1742 13.6533 13.2533 13.6159C13.3751 13.5659 13.4793 13.481 13.5529 13.3718C13.6264 13.2627 13.666 13.1342 13.6667 13.0026V6.33594C13.6667 5.8055 13.456 5.2968 13.0809 4.92172C12.7058 4.54665 12.1971 4.33594 11.6667 4.33594ZM4.33334 6.33594V7.59594H3.07334C2.9856 7.59543 2.89862 7.61225 2.8174 7.64542C2.73617 7.6786 2.6623 7.72748 2.6 7.78927L1.66667 8.72927V2.33594C1.66667 2.15913 1.73691 1.98956 1.86193 1.86453C1.98696 1.73951 2.15652 1.66927 2.33334 1.66927H9C9.17681 1.66927 9.34638 1.73951 9.47141 1.86453C9.59643 1.98956 9.66667 2.15913 9.66667 2.33594V4.33594H6.33334C5.8029 4.33594 5.2942 4.54665 4.91912 4.92172C4.54405 5.2968 4.33334 5.8055 4.33334 6.33594ZM12.3333 11.3959L11.6667 10.7293C11.6049 10.6663 11.5313 10.6162 11.45 10.5818C11.3688 10.5475 11.2815 10.5296 11.1933 10.5293H6.33334C6.15653 10.5293 5.98696 10.459 5.86193 10.334C5.73691 10.209 5.66667 10.0394 5.66667 9.86261V6.33594C5.66667 6.15913 5.73691 5.98956 5.86193 5.86453C5.98696 5.73951 6.15653 5.66927 6.33334 5.66927H11.6667C11.8435 5.66927 12.0131 5.73951 12.1381 5.86453C12.2631 5.98956 12.3333 6.15913 12.3333 6.33594V11.3959Z"
      fill={color}
    />
  </svg>
);
