import React from 'react';
import { LoadingRectangle } from '@dealsyte/poki';

import { MainContainer } from './StyledFileroomLoader';

/**
 * Renders fileroom loading skeleton.
 */
function FileroomLoader() {
  return (
    <MainContainer data-testid="FileroomLoader">
      <LoadingRectangle
        rectangleStyle={{
          backgroundImage: 'linear-gradient(to right, #e2e1e1 8%, #e0dddd 18%, #e8e8e8 33%)',
        }}
        height={16}
        width={142}
      />
    </MainContainer>
  );
}

export default FileroomLoader;
