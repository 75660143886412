import styled from 'styled-components';
import { Checkbox as PokiCheckbox } from '@dealsyte/poki';

const ContentContainer = styled.div`
  line-height: 1.3;
  text-align: left;
  color: #646464;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;

const CheckboxLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1;
`;

const Checkbox = styled(PokiCheckbox)`
  margin-right: 8px;
`;

const Header = styled.h1`
  margin-bottom: 24px;
  /* TODO: Change this color def to theme gray color once official 
  colors are updated.*/
  color: #596573;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 24px;
`;

const SubHeader = styled.h2`
  margin: 8px 0;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  /* TODO: Change this color def to theme gray color once official 
  colors are updated.*/
  color: #596573;
  font-family: 'Helvetica Neue';
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 16px;

  &:first-child {
    margin-top: 0;
  }
`;

const Paragraph = styled.p`
  /* TODO: Change this color def to theme gray color once official 
  colors are updated.*/
  color: #a9b2bc;
  font-size: 11px;
  letter-spacing: 0.25px;
  line-height: 16px;
  margin-bottom: 15px;
`;

const Label = styled.label`
  max-width: 400px;
  margin-bottom: 8px;
  /* TODO: Change this color def to theme gray color once official 
  colors are updated.*/
  color: #8c98a6;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const selectStyles = {
  control(styles: any) {
    return { ...styles };
  },
};

export {
  ContentContainer,
  ButtonsContainer,
  CheckboxLabelContainer,
  Checkbox,
  Header,
  SubHeader,
  Paragraph,
  Label,
  selectStyles,
};
