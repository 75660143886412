import _get from 'lodash/get';
import { DayValue } from 'react-modern-calendar-datepicker';
import { NumberFormatValues } from 'react-number-format';

export const formControlChangeHandlers = <P extends object>(setterFunction: Function) => {
  // Types
  type SetterFunction = (field: keyof P, options?: SetterOptions) => (value: any) => void;
  type HandlerFunction<T extends (...args: any) => any> = (
    setter: ReturnType<SetterFunction>
  ) => (...args: Parameters<T>) => void;

  type SetterOptions = {
    defaultValue?: any;
    data?: any;
  };

  // Default Setters and Getters
  const defaultSetterFunction: SetterFunction = (field: keyof P, options?: SetterOptions) => (
    value: any
  ) =>
    setterFunction(
      field,
      value ? value : (options && options.defaultValue) || value,
      options && options.data
    );

  const defaultFieldGetterFunction = <K extends (...args: any) => any>(
    handler: HandlerFunction<K>
  ) => (field: keyof P, options?: SetterOptions) => handler(defaultSetterFunction(field, options));

  // Handlers
  const fieldInputChangeHandler = (setter: Function) => (evt: React.ChangeEvent<any>) => {
    setter(_get(evt, 'target.value', ''));
  };

  const checkboxInputChangeHandler = (setter: Function) => (evt: React.ChangeEvent<any>) => {
    setter(_get(evt, 'target.checked', false));
  };

  const selectInputChangeHandler = (setter: Function) => (value: any) => {
    setter(value);
  };

  const dateInputChangeHandler = (setter: Function) => (value: DayValue) => {
    setter(value);
  };

  const yesNoInputChangeHandler = (setter: Function) => (value: boolean) => {
    setter(value);
  };

  const filesInputChangeHandler = (setter: Function) => (files: File[]) => {
    setter(files);
  };

  const numberInputChangeHandler = (setter: Function) => (values: NumberFormatValues) => {
    setter(values.floatValue);
  };

  return {
    handleFieldChange: defaultFieldGetterFunction<ReturnType<typeof fieldInputChangeHandler>>(
      fieldInputChangeHandler
    ),
    handleCheckboxChange: defaultFieldGetterFunction<ReturnType<typeof checkboxInputChangeHandler>>(
      checkboxInputChangeHandler
    ),
    handleSelectChange: defaultFieldGetterFunction<ReturnType<typeof selectInputChangeHandler>>(
      selectInputChangeHandler
    ),
    handleYesNoChange: defaultFieldGetterFunction<ReturnType<typeof yesNoInputChangeHandler>>(
      yesNoInputChangeHandler
    ),
    handleFilesChange: defaultFieldGetterFunction<ReturnType<typeof filesInputChangeHandler>>(
      filesInputChangeHandler
    ),
    handleDateChange: defaultFieldGetterFunction<ReturnType<typeof dateInputChangeHandler>>(
      dateInputChangeHandler
    ),
    handleNumberInputChange: defaultFieldGetterFunction<
      ReturnType<typeof numberInputChangeHandler>
    >(numberInputChangeHandler),
  };
};
