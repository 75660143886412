import styled from 'styled-components';

export const NewDealTitle = styled.div`
  ${({ theme }) => {
    const { colors, typography, spacing } = theme.foundations;
    return `
      font-family: ${typography.fonts.heading};
      font-weight: ${typography.weights.bold};
      font-size: ${typography.sizes['05']};
      letter-spacing: 0.25px;
      line-height: 22.5px;
      color: ${colors.palette.neutral['600']};
      margin-bottom: ${spacing['02']};
    `;
  }};
`;

export const NewDealSubtitle = styled.div`
  ${({ theme }) => {
    const { colors, typography, spacing } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['04']};
      letter-spacing: 0.25px;
      line-height: 24px;
      color: ${colors.palette.neutral['600']};
      margin-bottom: ${spacing['03']};
    `;
  }};
`;

export const ButtonContainer = styled.div`
  ${({ theme }) => {
    const { colors, typography } = theme.foundations;
    return `
      display: flex;
      width: 100%;
      justify-content: space-between;
      .cancel {
        background-color: ${colors.palette.neutral['50']};
        border-color: ${colors.palette.neutral['50']};
        color: ${colors.palette.neutral['600']};
        font-weight: ${typography.weights.regular};
        font-size: ${typography.sizes['03']};
      }
    `;
  }}
`;

export const SpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputLabelContainer = styled.div`
  ${({ theme }) => {
    const { colors, typography } = theme.foundations;
    return `
      display: flex;
      flex-direction: column;
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['02']};
      letter-spacing: 0.25px;
      line-height: 18px;
      color: ${colors.palette.neutral['500']};
    `;
  }}
`;

export const FileInputContainer = styled.div`
  padding-bottom: 40px;
`;

export const RequiredLabel = styled.span`
  ${({ theme }) => {
    const { colors, typography, spacing } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['02']};
      letter-spacing: 0.25px;
      line-height: 18px;
      color: ${colors.text.light};
      margin-left: ${spacing['02']};
    `;
  }}
`;

export const AlertText = styled.p`
  ${({ theme }) => {
    const { colors, typography } = theme.foundations;
    return `
      font-size: ${typography.sizes['04']};
      color: ${colors.text.dark};
      strong {
        font-weight: ${typography.weights.medium};
      }
    `;
  }}
`;
