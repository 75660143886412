import * as React from 'react';
import { IconProps } from './types';

export const EmptyConversationStateIcon = ({
  width = '181px',
  height = '180px',
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 181 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="90.5" cy="90" r="76" fill="#EDEDF7" />
    <g filter="url(#filter0_d_7552_160108)">
      <path
        d="M108.803 36H30.7169C28.2725 36 25.9281 36.9711 24.1996 38.6996C22.4711 40.4281 21.5 42.7725 21.5 45.2169V71.0355C21.5 73.48 22.4711 75.8243 24.1996 77.5529C25.9281 79.2814 28.2725 80.2524 30.7169 80.2524H35.3529L43.6481 88.5784C43.9352 88.8631 44.2757 89.0884 44.65 89.2413C45.0243 89.3942 45.4251 89.4717 45.8295 89.4694C46.5618 89.4694 47.27 89.2079 47.8265 88.732L57.7193 80.2524H108.803C111.248 80.2524 113.592 79.2814 115.321 77.5529C117.049 75.8243 118.02 73.48 118.02 71.0355V45.2169C118.02 42.7725 117.049 40.4281 115.321 38.6996C113.592 36.9711 111.248 36 108.803 36Z"
        fill="#6A678E"
      />
    </g>
    <rect x="31.0723" y="45.5723" width="40.6821" height="4.78613" rx="2.39306" fill="#EDEDF7" />
    <rect x="31.0723" y="55.1445" width="68.6012" height="4.78613" rx="2.39306" fill="#EDEDF7" />
    <rect x="31.0723" y="64.7168" width="51.8497" height="4.78613" rx="2.39306" fill="#EDEDF7" />
    <g filter="url(#filter1_d_7552_160108)">
      <path
        d="M72.1967 90.2427H150.283C152.728 90.2427 155.072 91.2137 156.8 92.9423C158.529 94.6708 159.5 97.0151 159.5 99.4596V125.278C159.5 127.723 158.529 130.067 156.8 131.796C155.072 133.524 152.728 134.495 150.283 134.495H145.647L137.352 142.821C137.065 143.106 136.724 143.331 136.35 143.484C135.976 143.637 135.575 143.714 135.171 143.712C134.438 143.712 133.73 143.451 133.174 142.975L123.281 134.495H72.1967C69.7522 134.495 67.4079 133.524 65.6793 131.796C63.9508 130.067 62.9798 127.723 62.9798 125.278V99.4596C62.9798 97.0151 63.9508 94.6708 65.6793 92.9423C67.4079 91.2137 69.7522 90.2427 72.1967 90.2427Z"
        fill="#FEFEFE"
      />
    </g>
    <rect x="72.5" y="110.185" width="54.2428" height="4.78613" rx="2.39306" fill="#B9B6D2" />
    <rect x="72.5" y="100" width="61" height="5" rx="2.5" fill="#B9B6D2" />
    <rect x="72.5" y="120" width="68" height="5" rx="2.5" fill="#B9B6D2" />
    <defs>
      <filter
        id="filter0_d_7552_160108"
        x="7.76047"
        y="25.0084"
        width="123.999"
        height="80.9483"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="2.74791"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_7552_160108"
        />
        <feOffset dy="2.74791" />
        <feGaussianBlur stdDeviation="5.49581" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.254902 0 0 0 0 0.305882 0 0 0 0 0.505882 0 0 0 0.1 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7552_160108" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_7552_160108"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_7552_160108"
        x="49.2402"
        y="79.251"
        width="123.999"
        height="80.9483"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="2.74791"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_7552_160108"
        />
        <feOffset dy="2.74791" />
        <feGaussianBlur stdDeviation="5.49581" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.254902 0 0 0 0 0.305882 0 0 0 0 0.505882 0 0 0 0.1 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7552_160108" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_7552_160108"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
