import gql from 'graphql-tag';

import { UserFragment } from 'api/graphql/users/fragments';

export const FsoFragment = gql`
  fragment FsoBasicFragment on Fso {
    childrenAmount
    createdAt
    displaySize
    extension
    id
    index
    size
    status
    name
    path
    conversionStatus
    owner {
      ...UserBasicFragment
    }
    parentId
    trash
    type
    updatedAt
    publishedAmount
    unpublishedAmount
    permissions {
      upload
      download_original
      download_watermark
      rename
      move
      delete
      publish
      unpublish
    }
    Signatures {
      id
      requesterGroupId
      signerGroupId
      status
    }
    starredDate
  }
  ${UserFragment}
`;

export const FsoFragmentRecursive = gql`
  fragment FsoFragmentRecursive on Fso {
    children(navigateAs: $navigateAs) {
      ...FsoBasicFragment
      children(navigateAs: $navigateAs) {
        ...FsoBasicFragment
        children(navigateAs: $navigateAs) {
          ...FsoBasicFragment
        }
      }
    }
  }
  ${FsoFragment}
`;

export const FsoFragmentRecentUploads = gql`
  fragment FsoRecentUploads on Fso {
    id
    name
    path
    createdAt
    extension
    Parent {
      name
    }
    status
    permissions {
      download_original
      download_watermark
    }
  }
`;

export const UploadFsoFragment = gql`
  fragment UploadFsoFragment on UploadFilesNodes {
    id
    name
    extension
    size
    uuid
    status
  }
`;

export const UploadTransactionBodyFragment = gql`
  fragment UploadTransactionBodyFragment on UploadFsosTransaction {
    id
    status
    error
    exceptions {
      infectedFiles {
        id
        name
      }
    }
  }
`;
