import React from 'react';
import ReactSelectComponent, { components } from 'react-select';
import { SelectComponentsProps } from 'react-select/src/Select';
import get from 'lodash/get';

// Components
import { Tooltip } from '@dealsyte/poki';
import { Checkbox } from '../Checkbox/Checkbox';

// Icons
import { CheckIcon, CrossCircleIcon, CloseIcon } from '../../icons';

// Styles
import { CSSWrapper, useDefaultSelectStyles } from './StyledPortalSelect';

interface PortalSelectProps extends SelectComponentsProps {
  portalTargetSelector?: string;
  portaled?: boolean;
}

const withPortal = (SelectComponent: React.ComponentType<PortalSelectProps>) => ({
  portalTargetSelector = '[data-reach-dialog-overlay]',
  portaled,
  ...props
}: SelectComponentsProps) => {
  const options = get(props, 'options', []);
  const isDescriptionSelect = options.some((option: any) => option.description);
  const styles = useDefaultSelectStyles(isDescriptionSelect);
  const portalTarget = portaled && document.querySelector<HTMLElement>(portalTargetSelector);

  const Option = (optionProps: any) => {
    const isOptionSelected: boolean =
      get(optionProps, 'selectProps.value.value') &&
      get(optionProps, 'data.value') === get(optionProps, 'selectProps.value.value');
    const hasDescription = !!get(optionProps, 'data.description');
    const isMultiOption = get(optionProps, 'isMulti', false);

    return (
      <components.Option {...optionProps}>
        {isMultiOption && (
          <div className="checkboxContainer">
            <Checkbox checked={get(optionProps, 'isSelected', false)} />
          </div>
        )}
        <div className="descriptionContainer">
          <div>{get(optionProps, 'data.label')}</div>
          {hasDescription && (
            <div className="description">{get(optionProps, 'data.description')}</div>
          )}
        </div>

        <div className="iconContainer">
          {isOptionSelected && <CheckIcon height={10} width={14} />}
        </div>
      </components.Option>
    );
  };

  const ClearIndicator = (clearProps: any) => {
    const {
      innerProps: { ref, ...restInnerProps },
    } = clearProps;
    return (
      <Tooltip message="Clear all">
        <div {...restInnerProps} ref={ref}>
          <CrossCircleIcon color="#6A678E" style={{ marginRight: '16px' }} />
        </div>
      </Tooltip>
    );
  };

  const MultiValueRemove = (multiValueRemoveProps: any) => (
    <components.MultiValueRemove {...multiValueRemoveProps}>
      <CloseIcon height={8} width={8} />
    </components.MultiValueRemove>
  );

  const MultiValueLabel = (multiValueProps: any) => {
    const label = get(multiValueProps, 'data.label');
    return (
      <components.MultiValueLabel {...multiValueProps}>
        {label.length > 15 ? `${label.substring(0, 15)}...` : label}
      </components.MultiValueLabel>
    );
  };

  return (
    <CSSWrapper>
      <SelectComponent
        {...props}
        classNamePrefix="fns-select"
        closeMenuOnSelect={!props.isMulti}
        hideSelectedOptions={false}
        styles={props.styles || styles}
        menuPortalTarget={portalTarget}
        components={{
          Option,
          ClearIndicator,
          MultiValueRemove,
          MultiValueLabel,
        }}
      />
    </CSSWrapper>
  );
};

export const Select = withPortal(ReactSelectComponent);

export default withPortal(ReactSelectComponent);
