import * as React from 'react';
import { IconProps } from './types';

export const ExclamationMarkMessage = ({
  width = '28px',
  height = '28px',
  color = '#2C6EF2',
  style = {},
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    width={width}
    height={height}
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M23.3327 0.667969H4.66602C3.60515 0.667969 2.58773 1.0894 1.83759 1.83954C1.08744 2.58969 0.666016 3.6071 0.666016 4.66797V18.0013C0.666016 19.0622 1.08744 20.0796 1.83759 20.8297C2.58773 21.5799 3.60515 22.0013 4.66602 22.0013H20.1194L25.0527 26.948C25.1773 27.0715 25.325 27.1693 25.4875 27.2357C25.6499 27.302 25.8239 27.3357 25.9993 27.3346C26.1743 27.3391 26.3478 27.3026 26.506 27.228C26.7495 27.1279 26.9579 26.9581 27.1051 26.7398C27.2522 26.5215 27.3314 26.2645 27.3327 26.0013V4.66797C27.3327 3.6071 26.9113 2.58969 26.1611 1.83954C25.411 1.0894 24.3935 0.667969 23.3327 0.667969ZM24.666 22.788L21.6127 19.7213C21.4881 19.5977 21.3403 19.5 21.1779 19.4336C21.0154 19.3673 20.8415 19.3336 20.666 19.3346H4.66602C4.31239 19.3346 3.97326 19.1942 3.72321 18.9441C3.47316 18.6941 3.33268 18.3549 3.33268 18.0013V4.66797C3.33268 4.31435 3.47316 3.97521 3.72321 3.72516C3.97326 3.47511 4.31239 3.33464 4.66602 3.33464H23.3327C23.6863 3.33464 24.0254 3.47511 24.2755 3.72516C24.5255 3.97521 24.666 4.31435 24.666 4.66797V22.788ZM13.9993 14.0013C13.7356 14.0013 13.4779 14.0795 13.2586 14.226C13.0393 14.3725 12.8684 14.5808 12.7675 14.8244C12.6666 15.068 12.6402 15.3361 12.6916 15.5948C12.7431 15.8534 12.8701 16.091 13.0565 16.2774C13.243 16.4639 13.4806 16.5909 13.7392 16.6423C13.9979 16.6938 14.266 16.6674 14.5096 16.5665C14.7532 16.4656 14.9615 16.2947 15.108 16.0754C15.2545 15.8561 15.3327 15.5983 15.3327 15.3346C15.3327 14.981 15.1922 14.6419 14.9422 14.3918C14.6921 14.1418 14.353 14.0013 13.9993 14.0013ZM13.9993 6.0013C13.6457 6.0013 13.3066 6.14178 13.0565 6.39183C12.8065 6.64188 12.666 6.98101 12.666 7.33464V11.3346C12.666 11.6883 12.8065 12.0274 13.0565 12.2774C13.3066 12.5275 13.6457 12.668 13.9993 12.668C14.353 12.668 14.6921 12.5275 14.9422 12.2774C15.1922 12.0274 15.3327 11.6883 15.3327 11.3346V7.33464C15.3327 6.98101 15.1922 6.64188 14.9422 6.39183C14.6921 6.14178 14.353 6.0013 13.9993 6.0013Z"
      fill={color}
    />
  </svg>
);
