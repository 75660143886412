import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApolloClient } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import IdleTimer from 'react-idle-timer';
import { Modal, Button, EButtonType } from '@dealsyte/poki';
import dayjs from 'dayjs';
import {
  ENV_TIMEOUT,
  IDLE_TIMEOUT,
  LOGOUT_TIMEOUT,
  TIME_OUT_MESSAGE,
  KEEP_ME_ACTIVE_MESSAGE,
} from './ConstantsIdleMonitor';
import { UserContextConsumer } from 'app/users/context/UserContext';
import { compose } from 'redux';
import EventService from 'utils/socket/EventService';

type IExternalProps = RouteComponentProps & {
  client: ApolloClient<any>;
};

type IProps = IExternalProps & {
  logout: () => void;
};

type IState = {
  showModal: boolean;
  timeCounter: number;
  becameIdleTimestap?: dayjs.Dayjs;
};

class IdleMonitor extends Component<IProps, IState> {
  state = {
    showModal: false,
    timeCounter: 0,
  } as IState;

  logoutTimeout?: any;
  idleTimer?: any;

  componentDidUpdate = ({ location: { pathname: prevPath } }: IProps) => {
    const {
      location: { pathname: path },
    } = this.props;

    const totalTime = this.idleTimer && this.idleTimer.getElapsedTime();

    if (prevPath !== path) {
      this.setState({ timeCounter: totalTime });
    }
  };

  onActive = () => {
    EventService.triggerEvent('USER_ACTIVE');
    this.idleTimer.resume();

    if (
      this.state.becameIdleTimestap &&
      dayjs().isAfter(this.state.becameIdleTimestap.add(ENV_TIMEOUT, 'minute'))
    ) {
      this.props.logout();
    }
  };

  onIdle = () => {
    EventService.triggerEvent('USER_IDLE');
    // user became idle
    this.idleTimer.pause();

    this.showLogoutTimeoutWarning();

    this.setState({
      becameIdleTimestap: dayjs(),
    });

    this.logoutTimeout = setTimeout(() => {
      this.props.logout();
    }, LOGOUT_TIMEOUT);
  };

  showLogoutTimeoutWarning = () => {
    this.setState({
      showModal: true,
    });
  };

  closeWarning = () => {
    this.setState({
      showModal: false,
    });
  };

  keepActive = () => {
    this.closeWarning();

    clearTimeout(this.logoutTimeout);
  };

  idleTimerRef = (idleTimer: any) => {
    this.idleTimer = idleTimer;
  };

  render() {
    const { showModal } = this.state;

    if (ENV_TIMEOUT === 0) {
      return null;
    }

    return (
      <>
        <IdleTimer
          ref={this.idleTimerRef}
          onActive={this.onActive}
          onIdle={this.onIdle}
          timeout={IDLE_TIMEOUT}
        />

        <Modal show={showModal} onHide={this.keepActive}>
          <div style={{ padding: '30px 30px 15px' }}>
            <p>{TIME_OUT_MESSAGE}</p>

            <div style={{ marginTop: 15, display: 'flex' }}>
              <Button
                buttonType={EButtonType.primary}
                style={{ marginLeft: 'auto' }}
                onClick={this.keepActive}
              >
                {KEEP_ME_ACTIVE_MESSAGE}
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const WithUserInfoIdleMonitor = (props: IExternalProps) => (
  <UserContextConsumer>
    {({ logout }) => <IdleMonitor {...props} logout={logout} />}
  </UserContextConsumer>
);

export default compose(
  withRouter,
  withApollo
)(WithUserInfoIdleMonitor) as () => JSX.Element;
