import React, { useState } from 'react';

// Hooks
import useStateModal from '../../../../../utils/hooks/useStateModal';

// Handler
import { useKYCClientHandler } from './client/KYCClientHandler';
import { useKYCCounterpartyHandler } from './counterparty/KYCCounterpartyHandler';
import { STEPS } from './KYCFormConstants';

export const useKYCFormHandler = () => {
  const [currentStep, setCurrentStep] = useState(STEPS.OPTION_SELECTOR);
  const [previousStep, setPreviousStep] = useState(STEPS.OPTION_SELECTOR);
  const counterpartyHandler = useKYCCounterpartyHandler();
  const clientHandler = useKYCClientHandler();
  const modal = useStateModal('DRKYCForm');

  return {
    counterpartyHandler,
    clientHandler,
    modal,
    currentStep,
    previousStep,
    setCurrentStep,
    setPreviousStep,
  };
};

type UseKYCFormHandler = ReturnType<typeof useKYCFormHandler>;
export const KYCHandlerContext = React.createContext<UseKYCFormHandler>({} as UseKYCFormHandler);
export const KYCHandlerProvider = KYCHandlerContext.Provider;
