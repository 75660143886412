import get from 'lodash/get';
import { EFormType } from '../../../../../../utils/types/types';
import { KYCClientLabels } from '../KYCFormConstants';
import { KYCClientForm } from './KYCClientHandler';

export const buildKYCClientForm = (
  props: KYCClientForm,
  dealId: string,
  isCapitalRaiseDeal: boolean,
  entityName: string
) => {
  const { title, fullName, emailAddress, transactionStructure } = props;
  const variables = {
    type: EFormType.KYCcl,
    dealId,
    content: [
      {
        question: KYCClientLabels.ENTITY_NAME,
        input: entityName.trim(),
        key: 'clientEntityName',
        value: JSON.stringify(entityName.trim()),
      },
      {
        question: KYCClientLabels.FULL_NAME,
        input: fullName.trim(),
        key: 'clientName',
        value: JSON.stringify(fullName.trim()),
      },
      {
        question: KYCClientLabels.TITLE,
        input: title.trim(),
        key: 'clientEmail',
        value: JSON.stringify(title.trim()),
      },
      {
        question: KYCClientLabels.EMAIL_ADDRESS,
        input: emailAddress.trim(),
        key: 'clientTitle',
        value: JSON.stringify(emailAddress.trim()),
      },
    ],
  };

  if (isCapitalRaiseDeal) {
    variables.content.push({
      question: KYCClientLabels.TRANSACTION_STRUCTURE,
      input: get(transactionStructure, 'label', ''),
      key: 'transactionStructure',
      value: JSON.stringify(transactionStructure) || '',
    });
  }

  return variables;
};
