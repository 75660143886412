import * as yup from 'yup';

// Constants
import { EPSTSellingCompensationOptions } from 'app/compliance/types/formsTypes';

import validateFileExtension, {
  basicRequiredInputValidation,
  REQUIRED_MESSAGE,
  validateFileName,
} from '../../../../utils/helpers/formValidators';

const validations = {
  entityName: basicRequiredInputValidation(),
  securityType: basicRequiredInputValidation(),
  transactionDate: yup
    .object()
    .nullable()
    .shape({
      year: yup.number(),
      month: yup.number(),
      day: yup.number(),
    })
    .required(REQUIRED_MESSAGE),
  transactionAmount: yup
    .number()
    .min(0)
    .required(REQUIRED_MESSAGE),
  description: basicRequiredInputValidation(),
  participation: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .required(REQUIRED_MESSAGE),
  otherParticipation: yup.string().when('participation', {
    is: participation => participation && participation.value.toLowerCase() === 'other',
    then: basicRequiredInputValidation(),
  }),
  isIssuerPubliclyTradedCompany: yup.boolean().required(REQUIRED_MESSAGE),
  symbolAndExchange: yup.string().when('isIssuerPubliclyTradedCompany', {
    is: true,
    then: basicRequiredInputValidation(),
  }),
  previousInvestments: basicRequiredInputValidation(),
  isUserRelatedWithEntity: yup.boolean().required(REQUIRED_MESSAGE),
  relationshipWithEntityDetails: yup.string().when('isUserRelatedWithEntity', {
    is: true,
    then: basicRequiredInputValidation(),
  }),
  hasInteractedWithOtherIndividual: yup.boolean().required(REQUIRED_MESSAGE),
  interactionWithIndividualDetails: yup.string().when('hasInteractedWithOtherIndividual', {
    is: true,
    then: basicRequiredInputValidation(),
  }),
  willReceiveSellingCompensation: yup.boolean().required(REQUIRED_MESSAGE),
  sellingCompensations: yup.array().when('willReceiveSellingCompensation', {
    is: true,
    then: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string(),
          value: yup.string(),
        })
      )
      .required(REQUIRED_MESSAGE),
  }),
  otherSellingCompensations: yup.string().when('sellingCompensations', {
    is: sellingCompensations =>
      sellingCompensations.find(
        (compensation: { label: string; value: string }) =>
          compensation.value.toLowerCase() === EPSTSellingCompensationOptions.OTHERS.toLowerCase()
      ),
    then: basicRequiredInputValidation(),
  }),
  alreadyInvestedEntity: yup.boolean().required(REQUIRED_MESSAGE),
  discloseDate: yup
    .object()
    .nullable()
    .test('Disclose date validation', REQUIRED_MESSAGE, function test(value) {
      if (this.parent.alreadyInvestedEntity) {
        return !!value;
      }
      return true;
    }),
  investmentAmount: yup
    .number()
    .test('Investment validation', REQUIRED_MESSAGE, function test(value) {
      if (this.parent.alreadyInvestedEntity) {
        return typeof value === 'number' && value >= 0;
      }
      return true;
    }),
  isSigned: yup
    .boolean()
    .required(REQUIRED_MESSAGE)
    .test('', 'Your attestation is needed to continue', (value: boolean) => !!value),
  files: yup
    .array()
    .test('', 'Some file has an invalid name', validateFileName)
    .test('', 'Some file has an invalid extension', validateFileExtension),
};

export const validationSchema = yup.object().shape(validations);
