import React from 'react';

import { LogoContainer } from 'app/auth/StyledAuth';
import { FinalisIcon } from 'app/common/icons';
import { FINALIS_URLS } from 'app/utils/constants/app/appConstants';

export const FinalisIconV2 = () => (
  <LogoContainer
    target="_blank"
    rel="noopener noreferrer"
    href={process.env.REACT_APP_FINALIS_WEBSITE_HOMEPAGE_URL || FINALIS_URLS.HOMEPAGE}
    data-testid="login-finalis-logo"
  >
    <FinalisIcon width="207px" height="59px" />
  </LogoContainer>
);
