import styled from 'styled-components';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SpaceBetweenRow = styled(Row)`
  justify-content: space-between;
`;

export const MemberContainer = styled(SpaceBetweenRow)`
  border-bottom: 1px solid rgba(222, 224, 226, 30);
  padding: 8px 30px;
  margin: 0 -30px;
  align-items: center;

  ${({ onClick, theme }) => {
    return `
      cursor: ${onClick ? 'pointer' : 'default'};

      &:hover {
        background-color: ${onClick ? theme.palette.gray.lighter : undefined};
      }
    `;
  }}
`;

export const MemberList = styled(Column)`
  ${MemberContainer}:last-child {
    border-bottom: none;
  }
`;

export const Container = styled.div`
  padding: 0 30px;
  width: 100%;
`;

export const GroupList = styled.div`
  display: flex;
  flex-direction: column;
  width: 210px;
  border-right: 1px solid #dee0e2;
  overflow-y: scroll;
  height: 100%;
`;
