import { gql } from '@apollo/client';

export const GET_MARKETPLACE_PRIMARY_INDUSTRIES = gql`
  query GET_MARKETPLACE_PRIMARY_INDUSTRIES_QUERY {
    marketplacePrimaryIndustries
  }
`;

export const GET_MARKETPLACE_SECONDARY_INDUSTRIES = gql`
  query GET_MARKETPLACE_SECONDARY_INDUSTRIES_QUERY {
    marketplaceSecondaryIndustries
  }
`;

export const GET_MARKETPLACE_DEALS = gql`
  query GET_MARKETPLACE_DEALS_QUERY($filters: [FilterInput!]) {
    marketplaceDeals(filters: $filters) {
      id
      name
      type
      ownerId
      mode
      projectName
      companyAddress
      companyName
      marketplaceDealId
      status
      companyAgent
      expectedCommissions
      loiProvided
      expectedSize
      primaryIndustry
      secondaryIndustries
      companyProfile
      country
      state
      description
      retainerAmount
      grossFeedEarned {
        min
        max
      }
      calculatedPercentRate {
        min
        max
      }
      createdAt
      updatedAt
      publishedAt
      tailPeriodExpiration
      loiSignedDate
      engagementLetterSignedDate
    }
  }
`;

export const GET_MARKETPLACE_DEALS_FILTERS = gql`
  query GET_MARKETPLACE_DEALS_FILTERS_QUERY(
    $filters: [FilterInput!]
    $filterNames: [DealFilterEnum!]!
  ) {
    marketplaceDealFilters(filters: $filters, filterNames: $filterNames) {
      filterName
      values
    }
  }
`;

export const GET_MARKETPLACE_DEALS_V2 = gql`
  query MarketplaceDeals(
    $filters: [FilterInput!]
    $sorts: [SortInput!]
    $pagination: PaginationInput
  ) {
    marketplaceDeals(filters: $filters, sorts: $sorts, pagination: $pagination) {
      id
      name
      type
      marketplaceDealId
      status
      primaryIndustry
      secondaryIndustries
      country
      state
      expectedSize
      tailPeriodDuration
      retainerAmount
      grossFeedEarned {
        min
        max
      }
      calculatedPercentRate {
        min
        max
      }
      loiSignedDate
      description
      ownerId
      engagementLetterSignedDate
      marketplaceDealType
      publishedAt
      nonComplianceDeal
      collaborateInDeal {
        offeringId
        offeringType
        collaborationType
        status
        requestedAt
      }
      owner {
        marketplaceAccessEnabled
      }
    }
  }
`;

export const GET_MM_FILTERS_COUNT = gql`
  query MarketplaceDealFiltersCountTotal(
    $filterNames: [DealFilterEnum!]!
    $filters: [FilterInput!]
  ) {
    marketplaceDealFiltersCountTotal(filterNames: $filterNames, filters: $filters) {
      totalCount
      filterCounts {
        filterName
        values {
          filterParent
          count
          filterElement
        }
      }
    }
  }
`;

export const GET_MARKETPLACE_ONE_DEAL = gql`
  query MarketplaceOneDeal($marketplaceDealId: String!) {
    marketplaceOneDeal(marketplaceDealId: $marketplaceDealId) {
      id
      name
      type
      marketplaceDealId
      mode
      ownerId
      projectName
      companyName
      companyAddress
      companyAgent
      status
      loiProvided
      expectedCommissions
      expectedSize
      primaryIndustry
      secondaryIndustries
      companyProfile
      description
      retainerAmount
      tailPeriodDuration
      grossFeedEarned {
        min
        max
      }
      calculatedPercentRate {
        min
        max
      }
      country
      state
      nonComplianceDeal
      createdAt
      updatedAt
      publishedAt
      tailPeriodExpiration
      engagementLetterSignedDate
      loiSignedDate
      marketplaceDealType
      collaborateInDeal {
        offeringId
        offeringType
        collaborationType
        status
        requestedAt
      }
    }
  }
`;

export const GET_FEE_GUIDE = gql`
  query FeeGuideQuery($dealTypeEnum: String!) {
    feeGuideQuery(DealTypeEnum: $dealTypeEnum) {
      totalDeals
      averageMinValue
      averageMaxValue
      minData {
        expectedDealSize
        FROM_0_TO_1
        FROM_1_TO_2
        FROM_2_TO_3
        FROM_3_TO_4
        FROM_4
      }
      maxData {
        expectedDealSize
        FROM_0_TO_3
        FROM_3_TO_4
        FROM_4_TO_5
        FROM_5_TO_6
        FROM_6
      }
    }
  }
`;

export const GET_MARKETPLACE_USER = gql`
  query MarketplaceUser($userId: ID!) {
    marketplaceUser(userId: $userId) {
      marketplaceAccessEnabled
      skipMarketplaceTour
    }
  }
`;

export const GET_MARKETPLACE_FUNDS = gql`
  query MarketplaceFunds($filters: [FundFilterInput!], $sorts: [FundSortInput!]) {
    marketplaceFunds(filters: $filters, sorts: $sorts) {
      id
      name
      ownerId
      expectedSize
      marketplaceDealId
      primaryIndustry
      secondaryIndustries
      description
      term
      country
      state
      expectedReturn {
        min
        max
      }
      expectedIrr {
        min
        max
      }
      marketplaceDealType
      createdAt
      updatedAt
      publishedAt
      collaborateInFund {
        offeringId
        offeringType
        collaborationType
        status
        requestedAt
      }
      owner {
        id
        marketplaceAccessEnabled
      }
      managementFee
    }
  }
`;

export const GET_MARKETPLACE_FUNDS_FILTERS_COUNT = gql`
  query MarketplaceFundsFiltersCountTotal(
    $filterNames: [FundFilterEnum!]!
    $filters: [FundFilterInput!]
  ) {
    marketplaceFundsFiltersCountTotal(filterNames: $filterNames, filters: $filters) {
      totalCount
      filterCounts {
        filterName
        values {
          filterParent
          count
          filterElement
        }
      }
    }
  }
`;

export const GET_FEE_GUIDE_TO_EXPORT = gql`
  query ExportCompleteFeeGuideQuery {
    exportCompleteFeeGuideQuery
  }
`;

// REMOVE
export const GET_COLLABORATIONS_IN_MY_OFFERINGS = gql`
  query FindCollaborationsInFirmOfferings(
    $offeringId: ID
    $offeringTypesToInclude: [CollaborativeOfferingsEnum!]!
    $collaborationStatusToInclude: [StatusCollaborationEnum!]!
  ) {
    findCollaborationsInFirmOfferings(
      offeringId: $offeringId
      offeringTypesToInclude: $offeringTypesToInclude
      collaborationStatusToInclude: $collaborationStatusToInclude
    ) {
      offeringId
      offeringType
      requesterId
      offeringFirmId
      collaborationType
      status
      requestedAt
      offeringDetail {
        marketplaceDealId
        expectedSize
        primaryIndustry
        name
        type
      }
      offeringOwner
      collaborationId
      newMessagesAmount
      readBy {
        userId
        lastInteraction
      }
    }
  }
`;

// REMOVE
export const GET_COLLABORATIONS_IN_MY_OFFERINGS_V2 = gql`
  query FindCollaborationsInFirmOfferingsV2(
    $offeringTypesToInclude: [CollaborativeOfferingsEnum!]!
    $collaborationStatusToInclude: [StatusCollaborationEnum!]!
  ) {
    findCollaborationsInFirmOfferingsV2(
      offeringTypesToInclude: $offeringTypesToInclude
      collaborationStatusToInclude: $collaborationStatusToInclude
    ) {
      offeringDetail {
        marketplaceDealId
        expectedSize
        primaryIndustry
        name
        type
        term
      }
      offeringFirmId
      offeringType
      newMessagesAmount
      offeringId
      status
      collaborationMessages {
        userName
        message
        userId
        dateTime
        collaborationId
        firmId
      }
    }
  }
`;

export const GET_COLLABORATIONS_IN_MY_OFFERINGS_V3 = gql`
  query FindCollaborationsInFirmOfferingsV3(
    $offeringTypesToInclude: [CollaborativeOfferingsEnum!]!
    $collaborationStatusToInclude: [StatusCollaborationEnum!]!
  ) {
    findCollaborationsInFirmOfferingsV3(
      offeringTypesToInclude: $offeringTypesToInclude
      collaborationStatusToInclude: $collaborationStatusToInclude
    ) {
      offeringDetail {
        marketplaceDealId
        expectedSize
        primaryIndustry
        name
        type
        term
      }
      offeringFirmId
      offeringType
      newMessagesAmount
      offeringId
      status
      collaborationMessages {
        userName
        message
        userId
        dateTime
        collaborationId
        firmId
      }
    }
  }
`;

// REMOVE
export const GET_NEW_MESSAGES_AMOUNT_IN_MY_OFFERINGS_V2 = gql`
  query FindCollaborationsInFirmOfferingsV2(
    $offeringTypesToInclude: [CollaborativeOfferingsEnum!]!
    $collaborationStatusToInclude: [StatusCollaborationEnum!]!
  ) {
    findCollaborationsInFirmOfferingsV2(
      offeringTypesToInclude: $offeringTypesToInclude
      collaborationStatusToInclude: $collaborationStatusToInclude
    ) {
      newMessagesAmount
    }
  }
`;

export const GET_NEW_MESSAGES_AMOUNT_IN_MY_OFFERINGS_V3 = gql`
  query FindCollaborationsInFirmOfferingsV3(
    $offeringTypesToInclude: [CollaborativeOfferingsEnum!]!
    $collaborationStatusToInclude: [StatusCollaborationEnum!]!
  ) {
    findCollaborationsInFirmOfferingsV3(
      offeringTypesToInclude: $offeringTypesToInclude
      collaborationStatusToInclude: $collaborationStatusToInclude
    ) {
      newMessagesAmount
    }
  }
`;

// REMOVE
export const GET_MY_COLLABORATIONS = gql`
  query FindCollaborationsByRequesterFirm(
    $offeringId: ID
    $offeringTypesToInclude: [CollaborativeOfferingsEnum!]!
    $collaborationStatusToInclude: [StatusCollaborationEnum!]!
  ) {
    findCollaborationsByRequesterFirm(
      offeringId: $offeringId
      offeringTypesToInclude: $offeringTypesToInclude
      collaborationStatusToInclude: $collaborationStatusToInclude
    ) {
      offeringId
      offeringFirmId
      offeringType
      requesterId
      collaborationType
      status
      requestedAt
      offeringDetail {
        marketplaceDealId
        expectedSize
        primaryIndustry
        name
        type
        term
      }
      offeringOwnerReferenceName
      collaborationId
      newMessagesAmount
      offeringFirmMembers
    }
  }
`;

// REMOVE
export const GET_NEW_MESSAGES_AMOUNT_IN_MY_COLLABORATIONS = gql`
  query FindCollaborationsByRequesterFirm(
    $offeringId: ID
    $offeringTypesToInclude: [CollaborativeOfferingsEnum!]!
    $collaborationStatusToInclude: [StatusCollaborationEnum!]!
  ) {
    findCollaborationsByRequesterFirm(
      offeringId: $offeringId
      offeringTypesToInclude: $offeringTypesToInclude
      collaborationStatusToInclude: $collaborationStatusToInclude
    ) {
      newMessagesAmount
    }
  }
`;

export const GET_MY_COLLABORATIONS_V2 = gql`
  query FindCollaborationsByRequesterFirmV2(
    $offeringId: ID
    $offeringTypesToInclude: [CollaborativeOfferingsEnum!]!
    $collaborationStatusToInclude: [StatusCollaborationEnum!]!
  ) {
    findCollaborationsByRequesterFirmV2(
      offeringId: $offeringId
      offeringTypesToInclude: $offeringTypesToInclude
      collaborationStatusToInclude: $collaborationStatusToInclude
    ) {
      offeringId
      offeringFirmId
      offeringType
      requesterId
      collaborationType
      status
      requestedAt
      offeringDetail {
        marketplaceDealId
        expectedSize
        primaryIndustry
        name
        type
        term
      }
      offeringOwnerReferenceName
      collaborationId
      newMessagesAmount
      offeringFirmMembers
    }
  }
`;

export const GET_NEW_MESSAGES_AMOUNT_IN_MY_COLLABORATIONS_V2 = gql`
  query FindCollaborationsByRequesterFirmV2(
    $offeringId: ID
    $offeringTypesToInclude: [CollaborativeOfferingsEnum!]!
    $collaborationStatusToInclude: [StatusCollaborationEnum!]!
  ) {
    findCollaborationsByRequesterFirmV2(
      offeringId: $offeringId
      offeringTypesToInclude: $offeringTypesToInclude
      collaborationStatusToInclude: $collaborationStatusToInclude
    ) {
      newMessagesAmount
    }
  }
`;

export const GET_CONVERSATION_MESSAGES = gql`
  query getMessagesConversation($collaborationId: ID!) {
    getMessagesConversation(collaborationId: $collaborationId) {
      userName
      message
      userId
      dateTime
      collaborationId
      firmId
      files {
        fileName
        key
        contentType
      }
    }
  }
`;

export const GET_FIRM_TOAST_LIMIT_QUERY = gql`
  query FirmToastLimitQuery {
    firmToastLimitQuery {
      collaborationEnable
      activeCollaborations
    }
  }
`;

export const GET_USER_CONTEXT_IN_OFFERING = gql`
  query GetUserContextInOffering($marketplaceDealId: String!) {
    getUserContextInOffering(marketplaceDealId: $marketplaceDealId) {
      UserRoleInOffering
      offeringId
      offeringName
    }
  }
`;

export const GET_COLLABORATIONS_IN_MY_FIRMS_OFFERINGS_BASICS = gql`
  query FindCollaborationsInFirmOfferingsBasics(
    $offeringTypesToInclude: [CollaborativeOfferingsEnum!]!
    $collaborationStatusToInclude: [StatusCollaborationEnum!]!
    $offeringId: ID!
  ) {
    findCollaborationsInFirmOfferingsBasics(
      offeringTypesToInclude: $offeringTypesToInclude
      collaborationStatusToInclude: $collaborationStatusToInclude
      offeringId: $offeringId
    ) {
      requesterName
      collaborationId
      lastMessage {
        message
        dateTime
      }
      lastUserInteraction {
        userId
        lastInteraction
      }
      offeringFirmMembers
    }
  }
`;

export const GET_FILE_URL_CONVERSATIONS = gql`
  query GetUrlFile($collaborationId: ID!, $key: ID!) {
    getURLFile(collaborationId: $collaborationId, key: $key) {
      url
    }
  }
`;
