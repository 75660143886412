import * as yup from 'yup';
import {
  basicRequiredInputValidation,
  REQUIRED_MESSAGE,
} from '../../../../../../utils/helpers/formValidators';

const commonSchema = {
  fullName: basicRequiredInputValidation(),
  emailAddress: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .email('Email address must be a valid email'),
  title: basicRequiredInputValidation(),
};

const noCapitalRaiseSchema = yup.object().shape({
  ...commonSchema,
});

const capitalRaiseSchema = yup.object().shape({
  ...commonSchema,
  transactionStructure: yup
    .object()
    .required(REQUIRED_MESSAGE)
    .test('', '', option => !!option && !!option.label && !!option.value),
});

export const validationSchema = {
  noCapitalRaiseSchema,
  capitalRaiseSchema,
};
