import React from 'react';
import dayjs from 'dayjs';
import {
  Card,
  CardBody,
  CardDivider,
  CardFooter,
  Text,
  HStack,
  VStack,
} from 'app/common/components';
import { LockIcon, RightArrow } from 'app/common/icons';
import { EFormStatus } from 'app/utils/types/types';
import { IForm } from 'app/compliance/types/formsTypes';
import * as S from './ComplianceTrackerStyled';
import { inferCardStatus } from './ComplianceTrackerHelpers';

export enum FormStepStatus {
  COMPLETED = 'COMPLETED',
  READY = 'READY',
  BLOCKED = 'BLOCKED',
}

export interface ComplianceTrackerFormCardProps {
  step: string;
  name: string;
  status?: EFormStatus;
  stepStatus?: FormStepStatus;
  form: IForm | null;
  completeAction: () => void;
  viewDetailsAction: () => void;
  editAction: () => void;
}

export const ComplianceTrackerFormCard = ({
  stepStatus = FormStepStatus.BLOCKED,
  status,
  name,
  step,
  form,
  completeAction,
  viewDetailsAction,
}: ComplianceTrackerFormCardProps): JSX.Element => {
  const cardVariant = stepStatus === FormStepStatus.BLOCKED ? 'outline' : 'default';
  const author = !!form ? `${form.author.firstName} ${form.author.lastName}` : '-';
  const createdAt = !!form ? dayjs(form.createdAt).format('MMM D, YYYY') : '-';
  return (
    <Card variant={cardVariant} style={{ height: '100%' }}>
      <VStack style={{ height: '100%' }}>
        <CardBody padding={24} style={{ flex: 1 }}>
          {inferCardStatus(status, stepStatus)}
          <Text variant="title" style={{ marginTop: 24 }}>
            {name}
          </Text>
          {stepStatus !== FormStepStatus.BLOCKED ? (
            <HStack justifyContent="space-between" spacing={24} mt={24} mb={24}>
              <VStack style={{ width: '50%' }}>
                <Text variant="label">Created</Text>
                <Text variant="value">{createdAt}</Text>
              </VStack>
              <VStack style={{ width: '50%' }}>
                <Text variant="label">Creator</Text>
                <Text variant="value">{author}</Text>
              </VStack>
            </HStack>
          ) : (
            <S.RoundIconContainer mt={24}>
              <LockIcon color="#B9B6D2" style={{ width: 16, height: 20 }} />
            </S.RoundIconContainer>
          )}
        </CardBody>
        {stepStatus !== FormStepStatus.BLOCKED && <CardDivider />}
        <CardFooter padding="16px 24px">
          {stepStatus !== FormStepStatus.BLOCKED ? (
            <>
              {stepStatus === FormStepStatus.READY ? (
                <HStack
                  justifyContent="flex-end"
                  spacing={16}
                  alignItems="center"
                  style={{ cursor: 'pointer' }}
                  onClick={completeAction}
                >
                  <Text variant="value"> Complete step</Text>
                  <RightArrow />
                </HStack>
              ) : (
                <HStack style={{ width: '100%' }} justifyContent="flex-end">
                  {/* <HStack alignItems="center" spacing={6}>
                    <HStack
                      justifyContent="flex-end"
                      spacing={16}
                      alignItems="center"
                      style={{ cursor: 'pointer' }}
                      onClick={editAction}
                    >
                      <PencilEditIcon />
                      <Text variant="value">Edit</Text>
                    </HStack>
                  </HStack> */}
                  <HStack
                    spacing={16}
                    onClick={viewDetailsAction}
                    alignItems="center"
                    justifyContent="flex-end"
                    style={{ cursor: 'pointer' }}
                  >
                    <Text variant="value">View details</Text>
                    <RightArrow />
                  </HStack>
                </HStack>
              )}
            </>
          ) : (
            <Text variant="value" style={{ color: '#B9B6D2' }}>
              Approval of engagement letter required.
            </Text>
          )}
        </CardFooter>
      </VStack>
    </Card>
  );
};
