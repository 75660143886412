import { DealType } from 'types/graphql-types';
import { IUser } from 'app/core-tools/due-diligence/types/types';

export type IDeal = {
  id: string;
  name: string;
  createdAt: any;
  updatedAt: any;
  type: DealType;
  companyName: string;
  companyAddress: string;
  companyAgent: string | null;
  projectName: string | null;
  UserDeal: IUserDeal | null;
  DealOwner: Partial<IUser> | null;
  mode: string;
  details: IDetails | null;
  metadata?: Record<string, any>;
  private?: boolean;
  status: boolean;
};

export type IDetails = {
  dealStatus: string;
  expectedCommissions: number;
  expectedSize: number;
  loiProvided: boolean | null;
  closingDate: Date | null;
};

export type IUserDeal = {
  role: string;
  side: string;
};

export type Metric = {
  /** Metric name (will be the main header on the Deal cards) */
  name: string;
  /** Metric type (if it should rendered as a numeric value or a string) */
  type: MetricType;
  /** Metric value (could be a string or a number, but if it is a number, must be sent as string) */
  value: string;
  /** Optional. Defines if a measurement unit must be appended at the end of the given value (ex: '%') */
  measurementUnit?: string;
};

export type INotification = {
  id: string;
  userId: string;
  deal?: IDeal;
  originService: string;
  event: string;
  entityName: string;
  entityId: string;
  threadId?: string;
  detail?: {
    description: string;
    title: string;
  };
  createdAt: string;
  updatedAt: string;
};

export enum MetricType {
  STRING = 'string',
  NUMBER = 'number',
  DECIMAL = 'decimal',
}

export enum EFormStatus {
  APPROVED_WITH_CONDITIONS = 'APPROVED_WITH_CONDITIONS',
  IN_REVIEW = 'IN_REVIEW',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  REQUEST_FOR_INFORMATION = 'REQUEST_FOR_INFORMATION',
  PASS_TO_LEGAL_TEAM = 'PASS_TO_LEGAL_TEAM',
  DRAFT = 'DRAFT',
}

export enum EFormType {
  AMNel = 'AMN_EL',
  CDOC = 'CDOC',
  DCR = 'DCR',
  DRA = 'DRA',
  EL = 'EL',
  FSAext = 'FSA_EXT',
  FSAint = 'FSA_INT',
  INV = 'INV',
  KYC = 'KYC',
  KYCcl = 'KYC_CL',
  KYCco = 'KYC_CO',
  MMAT = 'MMAT',
  MMF = 'MMF',
  NDR = 'NDR',
  OBA = 'OBA',
  OBAcct = 'OBAcct',
  PST = 'PST',
  SE = 'SE',
  U4 = 'U4',
}

export enum EDealStatus {
  INITIAL_ENGAGEMENT = 'INITIAL_ENGAGEMENT',
  ENGAGEMENT_LETTER = 'ENGAGEMENT_LETTER',
  PRE_MARKETING = 'PRE_MARKETING',
  DUE_DILIGENCE_CLOSING = 'DUE_DILIGENCE_CLOSING',
  ON_HOLD = 'ON_HOLD',
  CLOSED = 'CLOSED',
  EXPIRED = 'EXPIRED',
}

export type StyleVal = number | string;

export type TextVariants = 'subheading' | 'title' | 'regular' | 'label' | 'value';

export enum GA_CATEGORY {
  FILTERS = 'Filters',
  UI = 'UI',
  VDR = 'VDR',
}

export enum GA_ACTION {
  SHOW_MORE_BUTTON = 'Show More Button',
  COLLABORATE_BUTTON = 'Collaborate Button',
  OPEN_DEAL_DETAILS = 'Open Deal Details',
  ENTER_FEE_GUIDE = 'Enter Fee Guide',
  FEE_GUIDE_DEAL_TYPE_SELECTION = 'Fee Guide: Deal Type selection',
  SORT_SELECTION = 'Sort selection',
  EDIT_MM_DEAL = 'Edit MM deal',
  UPLOAD_FILE = 'Upload File',
  DOWNLOAD_FILE = 'Download File',
  INVITE_USER = 'Invite User',
  FM_ACCESS_DIRECTORY = 'Access Marketplace Directory Section',
  ACCESS_FM_TRIAL_USER_TYPE = 'Access FM Trial User Type',
}

export type CollaborationFile = {
  fileName: string;
  key: string;
  contentType: string;
};

export type UploadFile = {
  fileName: string;
  base64: string;
};
