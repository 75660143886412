export const EVENT_NAME = {
  LOGIN: 'Login',
  NAVBAR_NAVIGATION: 'Navbar_navigation',
  WF_DATAROOM_LAND: 'WF_Dataroom_land',
  WF_DATAROOM_ROOM: 'WF_Dataroom_Room_click',
  WF_DATAROOM_ROOM_LAND: 'WF_Dataroom_Room_land',
  WF_MYDEALS_CARD_DATAROOM: 'WF_Mydeals_Card_Dataroom_click',
  WF_MYDEALS_CLICK: 'WF_MyDeals-Click',
  WF_MYDEALS_CREATE_DEAL_CLICK: 'WF_MyDeals-CreateDeal',
  WF_MYDEALS_LIST_DATAROOM: 'WF_Mydeals_List_Dataroom_click',
};

export const ACTION = {
  LOGIN: 'login',
  CLICK: 'click',
  LAND: 'land',
};

export const PAGE_NAME = {
  LOGIN: 'Home/Arrival',
};
