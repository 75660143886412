/* eslint-disable camelcase */
// Libraries
import React, { useState, useContext, useEffect } from 'react';
import get from 'lodash/get';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';

// Types
import { IGroup } from 'app/users/types/types';

// Poki
import {
  CaretIcon,
  EDirections,
  OrganizationBadge,
  EButtonSizeType,
  EButtonType,
  EllipsisIcon,
  Alert,
  Input,
  CloseIcon,
  CheckIcon,
} from '@dealsyte/poki';

// Styled Components
import {
  EmptyGroupsContainer,
  MainContainer,
  AvatarContainer,
  BackButton,
  DropDownContainer,
  DealOptionsButton,
  DescriptionContainer,
  EditNameButton,
  InputGroupNameContainer,
  DropdownButton,
} from './StyledSections';

// Mutations
import {
  UPDATE_ORGANIZATION_NAME,
  REMOVE_ORGANIZATION,
} from '../../../../../../../../api/graphql/users/mutations';
import {
  UPDATE_ORGANIZATION_NAME_MUTATION,
  UPDATE_ORGANIZATION_NAME_MUTATIONVariables,
} from '../../../../../../../../api/graphql/users/types/UPDATE_ORGANIZATION_NAME_MUTATION';
import {
  REMOVE_ORGANIZATION_MUTATION,
  REMOVE_ORGANIZATION_MUTATIONVariables,
} from '../../../../../../../../api/graphql/users/types/REMOVE_ORGANIZATION_MUTATION';

// Context
import { AlertContext } from '../../../../../../../notifications/context/Alert/Alert';

function Overview({
  group,
  goBackAction,
  dealId,
  parentGroup,
  refetchInvitations,
}: {
  group: IGroup;
  goBackAction?: React.Dispatch<any>;
  dealId: string;
  parentGroup: any | null;
  refetchInvitations: Function;
}) {
  const { showSuccessToast, showErrorToast } = useContext(AlertContext);
  const [showAlertModal, setShowAlertModal] = useState<boolean>(false);
  const [isEditing, setEdit] = useState<boolean>(false);
  const [groupName, setGroupName] = useState<string>(get(group, 'name', 'No Group Name'));
  const groupCreationDate = get(group, 'createdAt');
  const ownerName = get(group, 'owner.displayName', false);

  const [updateNameOrganization] = useMutation<
    UPDATE_ORGANIZATION_NAME_MUTATION,
    UPDATE_ORGANIZATION_NAME_MUTATIONVariables
  >(UPDATE_ORGANIZATION_NAME, {
    onCompleted() {
      const fallback = { title: 'Successfully updated group name.', description: '' };
      showSuccessToast(fallback);
    },
    onError(error) {
      const fallback = { title: 'Error updating  group name.' };
      showErrorToast(fallback, error);
    },
  });

  const [removeOrganization] = useMutation<
    REMOVE_ORGANIZATION_MUTATION,
    REMOVE_ORGANIZATION_MUTATIONVariables
  >(REMOVE_ORGANIZATION, {
    onCompleted() {
      const fallback = { title: 'Successfully remove group.', description: '' };
      showSuccessToast(fallback);
      refetchInvitations();
    },
    onError(error) {
      const fallback = { title: 'Error removing group.' };
      showErrorToast(fallback, error);
    },
  });

  useEffect(() => {
    setGroupName(get(group, 'name', 'No Group Name'));
  }, [group]);

  const buildGroupContainer = () => {
    const organizationBadge = <OrganizationBadge name={groupName} withLabel />;

    return !isEditing ? (
      <>
        {organizationBadge}
        {
          <DropDownContainer className="dropdown">
            <DealOptionsButton
              className="dropbtn"
              type={EButtonType.default}
              size={EButtonSizeType.small}
            >
              <EllipsisIcon height={18} direction="horizontal" color="#6F7F8F" />
            </DealOptionsButton>
            <div className="dropdown-content">
              <DropdownButton color="#8c98a6" onClick={() => setEdit(true)}>
                Rename
              </DropdownButton>
              <DropdownButton color="#ff5d75" onClick={() => setShowAlertModal(true)}>
                Delete group
              </DropdownButton>
            </div>
          </DropDownContainer>
        }
      </>
    ) : (
      <InputGroupNameContainer>
        <Input
          id="groupName"
          type="text"
          name="groupName"
          placeholder="Group Name"
          onChange={(e: any) => {
            setGroupName(e.target.value);
          }}
          value={groupName}
        />
        <EditNameButton onClick={() => setEdit(false)}>
          <CloseIcon height={12} color="#a9b2bc" />
        </EditNameButton>
        <EditNameButton
          onClick={() => {
            setEdit(false);
            updateNameOrganization({
              variables: {
                organizationId: group.id,
                name: groupName,
              },
            });
          }}
        >
          <CheckIcon height={14} color="#a9b2bc" />
        </EditNameButton>
      </InputGroupNameContainer>
    );
  };

  return (
    <>
      {!group ? (
        <EmptyGroupsContainer>
          <p>You have no teams yet. Create one to start!</p>
        </EmptyGroupsContainer>
      ) : (
        <MainContainer>
          <AvatarContainer>
            {goBackAction && (
              <BackButton size={EButtonSizeType.small} onClick={goBackAction}>
                <CaretIcon
                  height={11}
                  style={{ marginBottom: '.5px' }}
                  direction={EDirections.left}
                />{' '}
              </BackButton>
            )}
            {buildGroupContainer()}
          </AvatarContainer>
          {groupCreationDate && (
            <DescriptionContainer>
              Created on {dayjs(groupCreationDate).format('MM-DD-YYYY')}
              {ownerName && (
                <>
                  {' '}
                  by <b>{ownerName}</b>
                </>
              )}
            </DescriptionContainer>
          )}
          <Alert
            onHide={() => setShowAlertModal(false)}
            disableActionButtons={false}
            show={showAlertModal}
            content={{
              title: `Remove Group`,
              description: `Are you sure you want to delete this group? All members will lose access and you won't be able to undo this later.`,
            }}
            confirmAction={{
              label: 'Delete Group',
              action() {
                if (parentGroup) {
                  removeOrganization({
                    variables: {
                      parentGroupId: parentGroup.id,
                      dealId,
                      groupId: group.id,
                    },
                  });
                } else {
                  showErrorToast({
                    title:
                      'There are some network issues, please try again later or contact support team.',
                  });
                }
              },
              type: EButtonType.danger,
            }}
            declineAction={{
              label: 'Dismiss',
              action() {
                setShowAlertModal(false);
              },
            }}
          />
        </MainContainer>
      )}
    </>
  );
}

export default Overview;
