import React from 'react';

import { Button, HStack, SideDrawer } from 'app/common/components';
import useStateModal from 'app/utils/hooks/useStateModal';
import { useDataRoomFormHandler, DataRoomHandlerProvider } from './DataRoomFormHandler';
import DataRoomForm from './DataRoomForm';

const DataRoomFormDrawer = () => {
  const handler = useDataRoomFormHandler();
  const { modalState, hide } = useStateModal('dataRoomForm');
  const { onSubmitDataRoomForm, submitting, form } = handler;

  const hideModal = () => {
    hide();
    form.resetForm();
  };

  return (
    <DataRoomHandlerProvider value={handler}>
      <SideDrawer
        title="Data room set up"
        show={modalState.show}
        promptOnClose={form.dirty}
        onClose={hideModal}
        footerContent={({ onClose }) => (
          <HStack justifyContent="flex-end" spacing={16}>
            <Button onClick={onClose} className="tertiary">
              Dismiss
            </Button>
            <Button
              onClick={onSubmitDataRoomForm}
              disabled={submitting || !form.isValid}
              style={{ width: 176 }}
            >
              Submit
            </Button>
          </HStack>
        )}
      >
        <DataRoomForm />
      </SideDrawer>
    </DataRoomHandlerProvider>
  );
};

export default DataRoomFormDrawer;
