import React, { useState } from 'react';
import { Tabs } from '@dealsyte/poki';

import PermissionsSettings from './PermissionsSettings';
import WatermarkSettings from './WatermarkSettings';
import { HeaderContainer, BodyContainer } from '../../StyledSettingsModal';

import { MainContainer } from './StyledVdrSettings';

export enum EVdrSettingType {
  PERMISSIONS = 'Permissions',
  WATERMARK = 'Watermark',
}

const VdrSettings = () => {
  const [activeVdrSetting, setActiveVdrSetting] = useState<number>(0);

  const settingsTabs = [
    {
      label: EVdrSettingType.PERMISSIONS,
    },
    {
      label: EVdrSettingType.WATERMARK,
    },
  ];

  return (
    <MainContainer data-testid="VdrSettingsMainContainer">
      <HeaderContainer>
        <Tabs
          options={settingsTabs}
          activeTab={activeVdrSetting}
          onChange={setActiveVdrSetting}
        ></Tabs>
      </HeaderContainer>
      <BodyContainer style={{ overflowY: 'auto' }}>
        {activeVdrSetting === 0 && <PermissionsSettings />}

        {activeVdrSetting === 1 && <WatermarkSettings />}
      </BodyContainer>
    </MainContainer>
  );
};
VdrSettings.displayName = 'VdrSettings';

export default VdrSettings;
