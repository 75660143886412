import React from 'react';
import { DEAL_TYPES } from '../../../../../utils/helpers/deal';
import { OptionLabel } from './KYCFormStyled';

export const KYCLabels = {
  CLIENT_SUBTITLE: 'Details of your client who signed the engagement letter.',
  COUNTERPARTIES_BLOCKED: 'Complete the client KYC/AML to unlock.',
  COUNTERPARTIES_SUBTITLE: 'Acquirers or investors of the deal.',
  DRAWER_TITLE: 'KYC/AML',
  COUNTERPARTY: 'Counterparty',
  CLIENT: 'Client',
};

export const STEPS = {
  OPTION_SELECTOR: 'option',
  CLIENT: 'client',
  COUNTERPARTY: 'counterparty',
};

export const KYCClientLabels = {
  ENTITY_NAME: 'Entity legal name',
  TRANSACTION_STRUCTURE: 'Likely transaction structure',
  FULL_NAME: 'Full name',
  EMAIL_ADDRESS: 'Email address',
  TITLE: 'Title',
  INFO_NOTIFICATION_LABEL:
    'Submitting this form will send an email and workflow to the contact—resolution of this step depends on their response.',
};

export const KYCClientPlaceholders = {
  ENTITY_NAME: '[Entity filled in create deal]',
  TRANSACTION_STRUCTURE: 'Select transaction structure',
  FULL_NAME: 'Enter full name',
  EMAIL_ADDRESS: 'Enter email address',
  TITLE: 'Enter title',
};

export const CAPITAL_RAISE_DEALS = [
  DEAL_TYPES.CAPITAL_CORPORATE,
  DEAL_TYPES.CAPITAL_FUND,
  DEAL_TYPES.CAPITAL_EB5,
];

export const TRANSACTION_STRUCTURE_OPTIONS = [
  {
    value: 'CAPITAL_RAISE_506_B',
    label: 'Capital raise via 506(b)',
    description: (
      <>
        Offered to a selected number of investors who have a <br />
        &apos;pre-existing & substantive relationship&apos; with either your firm or the issuer.
      </>
    ),
  },
  {
    value: 'CAPITAL_RAISE_506_C',
    label: 'Capital raise via 506(c)',
    description: 'Investors targeted via general solicitation on public sales channels.',
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
];

export const KYC_MODAL_LABELS = {
  CLIENT_TEXT: 'Do you want to complete KYC/AML forms for the deal counterparty?',
  COUNTERPARTY_TEXT:
    'The previous form was submitted successfully. Do you want to complete KYC/AML forms for another counterparty?',
  CLIENT_TITLE: 'Add counterparty form?',
  COUNTERPARTY_TITLE: 'Add another counterparty?',
};

export const KYCCounterpartiesLabels = {
  SUBTITLE: 'Perform a due diligence and AML check on the deal.',
  ALERT:
    'Submitting this form will send an email and workflow to the contact—resolution of this step depends on their response.',
  ENTITY_NAME: 'Entity legal name',
  FULL_NAME: 'Full name',
  EMAIL_ADDRESS: 'Email address',
  TITLE: 'Title or profession',
  COUNTERPARTY_TYPES: 'Indicate type of counterparty',
  CONNECTION: 'How did you connect with this counterparty?',
  YEARS_KNWOLEDGE: 'Indicate how many years you have known the counterparty',
  RELATIONSHIP_NATURE: 'Indicate nature of the relationship',
  CONNECTION_AMPLIFY: 'Amplify on how this connection was made',
};

export const CounterpartyTypes = {
  ENTITY: 'Entity / Institution',
  INDIVIDUAL: 'Individual',
};

export const TypesCounterpartyOptions = [
  {
    label: <OptionLabel>{CounterpartyTypes.ENTITY}</OptionLabel>,
    value: true,
  },
  {
    label: CounterpartyTypes.INDIVIDUAL,
    value: false,
  },
];

export const CONNECTION_OPTIONS = [
  {
    value: 'PRE_EXISTING_RELATIONSHIP',
    label: 'Pre-existing relationship - rule 506(b)',
    description:
      "The deal was communicated to a selected number of investors with a 'pre-existing & substantive relationship' with either your firm or the issuer.",
  },
  {
    value: 'GENERAL_SOLICITATION',
    label: 'General solicitation - rule 506(c)',
    description: (
      <>
        The counterparty was targeted via general solicitation on <br />
        public sales channels.
      </>
    ),
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
];
