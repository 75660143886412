import { CompensationValue } from 'app/compliance/constants/constants';
import { PositionMap } from 'app/utils/helpers/formDataMappers';
import { buildInitialDatePickerValue, formatDateInput } from 'app/utils/helpers/helpers';
import { FormType } from 'types/graphql-types';
import { QUESTION_LABELS } from './OBAFormConstants';
import { OBAForm } from './OBAFormHandler';

const mapYesNoAnswer = (value: boolean): string => {
  if (typeof value !== 'boolean') {
    return '';
  }
  return value ? 'Yes' : 'No';
};

const buildCompensation = (compensations: string[], othersDescription: string): string =>
  compensations
    .map(compensation =>
      compensation === CompensationValue.others
        ? `${compensation} (${othersDescription})`
        : compensation
    )
    .join(', ');

const getCompensationsFromString = (compensations: string) => {
  const splitted = compensations.split(',').map(option => option.trim());
  let otherString;
  const compensationArray = splitted.map(compensation => {
    const otherSearch = compensation.match(/\((.*?)\)/);
    if (otherSearch) {
      const [found, extracted] = otherSearch;
      otherString = extracted;
      return compensation.replace(found, '').trim();
    }
    return compensation;
  });
  return {
    compensations: compensationArray,
    otherString,
  };
};

export const buildOBAForm = (values: OBAForm) => {
  const {
    entityName,
    startDate,
    streetAddress,
    city,
    state,
    zipCode,
    country,
    position,
    natureOfBusiness,
    dutyDescription,
    approximateHours,
    devotedHours,
    hasReasonableCompensation,
    isCapitalRaise,
    isInvestmentRelated,
    compensation,
    files,
    othersDescription,
    otherInfo,
    otherLicenses,
    websitesConnectedToBusiness,
    noteComment,
  } = values;
  const res = {
    type: FormType.OBA,
    content: [
      {
        question: QUESTION_LABELS.ENTITY_NAME,
        input: entityName,
        key: 'entityName',
        value: JSON.stringify(entityName),
      },
      {
        question: QUESTION_LABELS.START_DATE,
        input: formatDateInput(startDate),
        key: 'startDate',
        value: JSON.stringify(startDate),
      },
      {
        question: QUESTION_LABELS.IS_BUSINESS_RELATED,
        input: mapYesNoAnswer(isInvestmentRelated),
        key: 'isInvestmentRelated',
        value: JSON.stringify(isInvestmentRelated),
      },
      {
        question: QUESTION_LABELS.POSITION,
        input: position,
        key: 'position',
        value: JSON.stringify(position),
      },
      {
        question: QUESTION_LABELS.STREET_ADDRESS,
        input: streetAddress,
        key: 'streetAddress',
        value: JSON.stringify(streetAddress),
      },
      {
        question: QUESTION_LABELS.CITY,
        input: city,
        key: 'city',
        value: JSON.stringify(city),
      },
      {
        question: QUESTION_LABELS.STATE,
        input: state,
        key: 'state',
        value: JSON.stringify(state),
      },
      {
        question: QUESTION_LABELS.ZIP_CODE,
        input: zipCode,
        key: 'zipCode',
        value: JSON.stringify(zipCode),
      },
      {
        question: QUESTION_LABELS.COUNTRY,
        input: country,
        key: 'country',
        value: JSON.stringify(country),
      },
      {
        question: QUESTION_LABELS.NATURE_OF_BUSINESS,
        input: natureOfBusiness,
        key: 'natureOfBusiness',
        value: JSON.stringify(natureOfBusiness),
      },
      {
        question: QUESTION_LABELS.DUTIES,
        input: dutyDescription,
        key: 'dutyDescription',
        value: JSON.stringify(dutyDescription),
      },
      {
        question: QUESTION_LABELS.MONTHLY_HOURS,
        input: approximateHours || '',
        key: 'approximateHours',
        value: JSON.stringify(approximateHours),
      },
      {
        question: QUESTION_LABELS.TRADING_HOURS,
        input: devotedHours || '',
        key: 'devotedHours',
        value: JSON.stringify(devotedHours),
      },
      {
        question: QUESTION_LABELS.WILL_BE_COMPENSATED,
        input: mapYesNoAnswer(hasReasonableCompensation),
        key: 'hasReasonableCompensation',
        value: JSON.stringify(hasReasonableCompensation),
      },
      {
        question: QUESTION_LABELS.CAPITAL_RAISE_SECURITIES,
        input: mapYesNoAnswer(isCapitalRaise),
        key: 'isCapitalRaise',
        value: JSON.stringify(isCapitalRaise),
      },
      ...(hasReasonableCompensation
        ? [
            {
              question: QUESTION_LABELS.COMPENSATIONS,
              input: buildCompensation(compensation, othersDescription),
              key: 'compensation',
              value: JSON.stringify({
                compensation,
                othersDescription,
              }),
            },
          ]
        : []),
      {
        question: QUESTION_LABELS.OTHER_LICENSES,
        input: otherLicenses,
        key: 'otherLicenses',
        value: JSON.stringify(otherLicenses),
      },
      {
        question: QUESTION_LABELS.WEBSITE,
        input: websitesConnectedToBusiness,
        key: 'websitesConnectedToBusiness',
        value: JSON.stringify(websitesConnectedToBusiness),
      },
      {
        question: QUESTION_LABELS.OTHER_INFO,
        input: otherInfo,
        key: 'otherInfo',
        value: JSON.stringify(otherInfo),
      },
    ],
    files,
    note: noteComment,
  };

  return res;
};

export const OBA_VALUE_INFERENCE_MAP: PositionMap = {
  entityName: { match: QUESTION_LABELS.ENTITY_NAME },
  startDate: {
    match: QUESTION_LABELS.START_DATE,
    value: ({ input }) => buildInitialDatePickerValue(input),
  },
  streetAddress: {
    match: QUESTION_LABELS.STREET_ADDRESS,
  },
  city: {
    match: QUESTION_LABELS.CITY,
  },
  state: {
    match: QUESTION_LABELS.STATE,
  },
  zipCode: {
    match: QUESTION_LABELS.ZIP_CODE,
  },
  country: {
    match: QUESTION_LABELS.COUNTRY,
  },
  position: {
    match: QUESTION_LABELS.POSITION,
  },
  natureOfBusiness: {
    match: QUESTION_LABELS.NATURE_OF_BUSINESS,
  },
  dutyDescription: {
    match: QUESTION_LABELS.DUTIES,
  },
  approximateHours: {
    match: QUESTION_LABELS.MONTHLY_HOURS,
  },
  devotedHours: {
    match: QUESTION_LABELS.TRADING_HOURS,
  },
  hasReasonableCompensation: {
    match: QUESTION_LABELS.WILL_BE_COMPENSATED,
    value: ({ input }) => input === 'Yes',
  },
  isCapitalRaise: {
    match: QUESTION_LABELS.CAPITAL_RAISE_SECURITIES,
    value: ({ input }) => input === 'Yes',
  },
  isInvestmentRelated: {
    match: QUESTION_LABELS.IS_BUSINESS_RELATED,
    value: ({ input }) => input === 'Yes',
  },
  compensation: {
    match: QUESTION_LABELS.COMPENSATIONS,
    value: ({ input }) => getCompensationsFromString(input).compensations,
  },
  othersDescription: {
    match: QUESTION_LABELS.COMPENSATIONS,
    value: ({ input }) => getCompensationsFromString(input).otherString,
  },
  otherInfo: {
    match: QUESTION_LABELS.OTHER_INFO,
  },
  otherLicenses: {
    match: QUESTION_LABELS.OTHER_LICENSES,
  },
  websitesConnectedToBusiness: {
    match: QUESTION_LABELS.WEBSITE,
  },
};
