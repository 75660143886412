import * as React from 'react';
import { IconProps } from './types';

const DealReferralIcon = ({
    width = "24",
    height = "24",
    color = "currentColor",
    style = {}
}: IconProps) => {

    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style} >
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 11.4C14.4301 11.4 16.4 9.43005 16.4 7C16.4 4.56995 14.4301 2.6 12 2.6C9.56997 2.6 7.60002 4.56995 7.60002 7C7.60002 9.43005 9.56997 11.4 12 11.4ZM12 13C15.3137 13 18 10.3137 18 7C18 3.68629 15.3137 1 12 1C8.68631 1 6.00002 3.68629 6.00002 7C6.00002 10.3137 8.68631 13 12 13Z" fill={color} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.8025 10.2366L21.0122 9.9878L20.7634 9.19756C20.6756 8.93415 20.3098 8.93415 20.2366 9.19756L19.9878 9.9878L19.1976 10.2366C18.9342 10.3244 18.9342 10.6902 19.1976 10.7634L19.9878 11.0122L20.2366 11.8024C20.3244 12.0659 20.6903 12.0659 20.7634 11.8024L21.0122 11.0122L21.8025 10.7634C22.0659 10.6756 22.0659 10.3098 21.8025 10.2366Z" fill={color} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.80246 3.23658L4.01222 2.9878L3.76343 2.19756C3.67563 1.93415 3.30978 1.93415 3.2366 2.19756L2.98783 2.9878L2.19758 3.23658C1.93417 3.32439 1.93417 3.69024 2.19758 3.76342L2.98783 4.0122L3.2366 4.80244C3.32441 5.06585 3.69026 5.06585 3.76343 4.80244L4.01222 4.0122L4.80246 3.76342C5.06587 3.67561 5.06587 3.30976 4.80246 3.23658Z" fill={color} />
            <path d="M13.3322 5.90909H11.667C11.5786 5.90909 11.4939 5.93782 11.4315 5.98897C11.369 6.04012 11.3339 6.10949 11.3339 6.18182C11.3339 6.25415 11.369 6.32352 11.4315 6.37467C11.4939 6.42581 11.5786 6.45455 11.667 6.45455H12.3331C12.746 6.45421 13.1444 6.57953 13.4509 6.80619C13.7574 7.03286 13.9501 7.34468 13.9916 7.68114C14.0331 8.01759 13.9204 8.35468 13.6755 8.62695C13.4306 8.89922 13.0708 9.08725 12.6661 9.15455V9.45455C12.6661 9.59921 12.5959 9.73795 12.471 9.84024C12.3461 9.94253 12.1767 10 12 10C11.8234 10 11.6539 9.94253 11.529 9.84024C11.4041 9.73795 11.3339 9.59921 11.3339 9.45455V9.18182H10.6678C10.4911 9.18182 10.3217 9.12435 10.1968 9.02206C10.0719 8.91977 10.0017 8.78103 10.0017 8.63636C10.0017 8.4917 10.0719 8.35296 10.1968 8.25067C10.3217 8.14838 10.4911 8.09091 10.6678 8.09091H12.3331C12.4214 8.09091 12.5061 8.06218 12.5686 8.01103C12.631 7.95988 12.6661 7.89051 12.6661 7.81818C12.6661 7.74585 12.631 7.67648 12.5686 7.62533C12.5061 7.57419 12.4214 7.54545 12.3331 7.54545H11.667C11.254 7.54579 10.8556 7.42047 10.5491 7.19381C10.2426 6.96715 10.05 6.65532 10.0085 6.31886C9.96695 5.98241 10.0796 5.64532 10.3245 5.37305C10.5695 5.10078 10.9292 4.91275 11.3339 4.84545V4.54545C11.3339 4.40079 11.4041 4.26205 11.529 4.15976C11.6539 4.05747 11.8234 4 12 4C12.1767 4 12.3461 4.05747 12.471 4.15976C12.5959 4.26205 12.6661 4.40079 12.6661 4.54545V4.81818H13.3322C13.5089 4.81818 13.6783 4.87565 13.8032 4.97794C13.9282 5.08023 13.9983 5.21897 13.9983 5.36364C13.9983 5.5083 13.9282 5.64704 13.8032 5.74933C13.6783 5.85162 13.5089 5.90909 13.3322 5.90909Z" fill={color} />
            <path d="M21.95 14.55C21.57 14.19 21.07 14 20.56 14C20.37 14 20.17 14.03 19.98 14.09H19.96L16.76 15.27C16.64 15.1 16.52 14.95 16.33 14.81C15.88 14.48 15.34 14.33 14.82 14.33C14.71 14.33 14.6 14.33 14.49 14.35L9.66002 14.94H9.62002L9.52002 14.92C9.31002 14.84 8.94002 14.67 8.46002 14.51C7.97002 14.36 7.35002 14.23 6.60002 14.23H6.57002C5.68002 14.23 4.93002 14.58 4.39002 14.89C3.85002 15.21 3.51002 15.51 3.49002 15.54C3.16002 15.84 3.14002 16.34 3.44002 16.67C3.74002 17 4.24002 17.02 4.57002 16.72C4.57002 16.72 4.85002 16.47 5.24002 16.25C5.63002 16.02 6.14002 15.83 6.58002 15.83H6.60002C7.18002 15.83 7.62002 15.92 7.99002 16.04C8.36002 16.16 8.65002 16.3 8.97002 16.42C9.18002 16.5 9.41002 16.54 9.64002 16.54H9.87002L14.7 15.94H14.84C14.84 15.94 14.9 15.94 14.94 15.94L10.9 17.42C10.66 17.5 10.45 17.57 10.34 17.61C10.32 17.61 10.31 17.63 10.3 17.64L10.12 17.71C9.71002 17.86 9.49002 18.32 9.64002 18.74C9.79002 19.15 10.25 19.37 10.67 19.22L11.44 18.94C12.12 18.72 13.27 18.34 14.33 17.96C14.9 17.75 15.44 17.55 15.87 17.36C15.99 17.31 16.08 17.25 16.19 17.2L20.48 15.63L20.6 15.61C20.71 15.61 20.81 15.65 20.89 15.73C20.98 15.82 21.04 15.94 21.04 16.07V16.14C21 16.31 20.9 16.43 20.76 16.49C19.59 16.96 17.29 17.95 15.11 18.85C14.02 19.3 12.96 19.72 12.1 20.04C11.67 20.2 11.29 20.34 10.98 20.43C10.68 20.53 10.44 20.59 10.35 20.6C10.17 20.63 9.80002 20.64 9.37002 20.64C8.12002 20.64 6.32002 20.52 5.59002 20.49H5.50002C5.26002 20.49 5.02002 20.53 4.78002 20.62L1.52002 21.83C1.11002 21.99 0.90002 22.45 1.05002 22.86C1.21002 23.27 1.67002 23.48 2.08002 23.33L5.32002 22.11L5.48002 22.08H5.50002C6.20002 22.11 8.02002 22.24 9.35002 22.24C9.82002 22.24 10.22 22.23 10.56 22.18C10.82 22.14 11.1 22.06 11.44 21.95C12.63 21.57 14.58 20.78 16.51 19.98C18.43 19.18 20.33 18.37 21.32 17.96C21.99 17.69 22.45 17.09 22.57 16.39C22.59 16.28 22.6 16.16 22.6 16.05C22.6 15.48 22.37 14.94 21.95 14.55Z" fill={color} />
        </svg>
    );
}

export default DealReferralIcon;