import styled, { css } from 'styled-components';

export const tabColors = {
  active: '#185adc',
  inactive: '#2b3664',
  iconDefault: '#6b79b3',
};

export const CardTabsContainer = styled.div`
  display: flex;
  height: 80px;
  padding: 0px 40px;
  border-bottom: 1px solid #edf0fd;
  justify-content: space-between;
`;

export const CardTabsLeftPanel = styled.div`
  display: flex;
`;

export const CardTabsRightPanel = styled.div`
  display: flex;
`;

export const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  min-width: 110px;
  cursor: pointer;
  margin-right: 16px;
  border-bottom: 2px solid #fff;
  &.active {
    border-bottom: 2px solid #2c6ef2;
  }
  &:last-child {
    margin-right: 0px;
  }
`;

export const TabIconContainer = styled.div`
  margin-right: 10px;
`;

export const TabTextContainer = styled.p<{ isActive: boolean }>`
  font-family: Sofia Pro;
  font-size: 16px;
  margin-top: 5px;
  color: ${({ isActive }) => (isActive ? tabColors.active : tabColors.inactive)};
`;

export const CardTabsContainerV2 = styled.div`
  ${({ theme }) => {
    const { colors } = theme.foundations;
    return `
      display: flex;
      height: 58px;
      border-bottom: 1px solid ${colors.palette.neutral[300]};
      justify-content: space-between;
`;
  }}
`;

export const TabContainerV2 = styled.button`
  ${({ theme }) => {
    const { spacing, typography, colors, radii } = theme.foundations;
    return `
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px ${spacing['04']};
      min-width: 110px;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      font-family: ${typography.fonts.body};
      background-color: transparent;
      color: ${colors.palette.neutral[600]};
      border-radius: ${radii.md} ${radii.md} 0px 0px;
      :focus {
        outline: ${spacing['01']} solid ${colors.palette.neutral[400]};
        .counter-container {
          background: ${colors.palette.neutral[600]};
          color: ${colors.palette.white};
        }
        p{
          color: ${colors.palette.neutral[800]};
        }
      }
      :hover {
        .counter-container {
          background: ${colors.palette.neutral[600]};
          color: ${colors.palette.white};
        }
        p{
          color: ${colors.palette.neutral[800]};
        }
      }
      &.active {
        border-bottom: 2px solid ${colors.palette.neutral[700]};
        :focus {
          border-bottom: 2px solid transparent;
        }
      }
      :disabled {
        .counter-container {
          color: ${colors.text.light};
          background: ${colors.palette.neutral[200]};
        }
        p{
          color: ${colors.text.light};
        }
      }

      .counter-container {
        display: flex;
        flex-direction: row;
        padding-inline: ${spacing['01']};
        background: ${colors.palette.neutral[200]};
        border-radius: ${radii.sm};
        line-height: ${spacing['04']};
        font-size: 10px;
        margin-left: ${spacing['02']};
        font-weight: ${typography.weights.regular};
        &.active {
          background: ${colors.palette.neutral[600]};
          color: ${colors.palette.white};
        }
      }
    `;
  }}
`;

export const TabTextContainerV2 = styled.p`
  ${({ theme, isActive }: { theme: any; isActive: boolean }) => {
    const { typography } = theme.foundations;
    return `
      font-size: ${typography.sizes['04']};
      font-weight: ${typography.weights.regular};
      line-height: ${typography.sizes['06']};
      display: inline-block;
      ${isActive &&
        css`
          text-shadow: 0 0 #353347;
          color: #353347;
        `}
    `;
  }}
`;
