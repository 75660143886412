import * as React from 'react';
import { IconProps } from './types';

export const ExcelIcon = ({
  width = '20px',
  height = '20px',
  color = '#32A932',
}: IconProps): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
      fill="#DDFDDD"
    />
    <path
      d="M15.3333 5.33334H4.66667C3.93333 5.33334 3.33333 5.93334 3.33333 6.66667V8V9V11V12V13.3333C3.33333 14.0667 3.93333 14.6667 4.66667 14.6667H15.3333C16.0667 14.6667 16.6667 14.0667 16.6667 13.3333V12V11V9V8V6.66667C16.6667 5.93334 16.0667 5.33334 15.3333 5.33334ZM8.33333 11V9H11.6667V11H8.33333ZM11.6667 12V13.6667H8.33333V12H11.6667ZM8.33333 8V6.33334H11.6667V8H8.33333ZM4.33333 6.66667C4.33333 6.46667 4.46667 6.33334 4.66667 6.33334H7.33333V8H4.33333V6.66667ZM4.33333 9H7.33333V11H4.33333V9ZM4.33333 13.3333V12H7.33333V13.6667H4.66667C4.46667 13.6667 4.33333 13.5333 4.33333 13.3333ZM15.6667 13.3333C15.6667 13.5333 15.5333 13.6667 15.3333 13.6667H12.6667V12H15.6667V13.3333ZM15.6667 11H12.6667V9H15.6667V11ZM12.6667 8V6.33334H15.3333C15.5333 6.33334 15.6667 6.46667 15.6667 6.66667V8H12.6667Z"
      fill={color}
    />
  </svg>
);
