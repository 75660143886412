// Libraries
import React from 'react';
import PropTypes from 'prop-types';

const PlusIcon = props => (
  <svg width={props.width} height={props.height} fill="none">
    <path
      d="M10.6667 5.33427H6.66666V1.33427C6.66666 1.15746 6.59642 0.987889 6.47139 0.862865C6.34637 0.737841 6.1768 0.667603 5.99999 0.667603C5.82318 0.667603 5.65361 0.737841 5.52858 0.862865C5.40356 0.987889 5.33332 1.15746 5.33332 1.33427V5.33427H1.33332C1.15651 5.33427 0.986943 5.40451 0.861919 5.52953C0.736895 5.65456 0.666656 5.82412 0.666656 6.00094C0.666656 6.17775 0.736895 6.34732 0.861919 6.47234C0.986943 6.59736 1.15651 6.6676 1.33332 6.6676H5.33332V10.6676C5.33332 10.8444 5.40356 11.014 5.52858 11.139C5.65361 11.264 5.82318 11.3343 5.99999 11.3343C6.1768 11.3343 6.34637 11.264 6.47139 11.139C6.59642 11.014 6.66666 10.8444 6.66666 10.6676V6.6676H10.6667C10.8435 6.6676 11.013 6.59736 11.1381 6.47234C11.2631 6.34732 11.3333 6.17775 11.3333 6.00094C11.3333 5.82412 11.2631 5.65456 11.1381 5.52953C11.013 5.40451 10.8435 5.33427 10.6667 5.33427Z"
      fill={props.color}
    />
  </svg>
);

PlusIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

PlusIcon.defaultProps = {
  width: '12px',
  height: '12px',
  color: '#47455F',
};

export default PlusIcon;
