import * as React from 'react';
import { IconProps } from './types';

export const HomeIcon = ({
  color = 'currentColor',
  style = {},
  width = '16',
  height = '18',
}: IconProps): JSX.Element => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6667 5.66667L9.66675 1.28334C9.20839 0.873376 8.61502 0.646729 8.00008 0.646729C7.38513 0.646729 6.79176 0.873376 6.33341 1.28334L1.33341 5.66667C1.06872 5.9034 0.857496 6.1938 0.713806 6.51854C0.570115 6.84328 0.497256 7.19491 0.500079 7.55V14.8333C0.500079 15.4964 0.763471 16.1323 1.23231 16.6011C1.70115 17.0699 2.33704 17.3333 3.00008 17.3333H13.0001C13.6631 17.3333 14.299 17.0699 14.7678 16.6011C15.2367 16.1323 15.5001 15.4964 15.5001 14.8333V7.54167C15.5017 7.18797 15.4283 6.83795 15.2846 6.51474C15.141 6.19152 14.9304 5.90247 14.6667 5.66667ZM9.66675 15.6667H6.33341V11.5C6.33341 11.279 6.42121 11.067 6.57749 10.9107C6.73377 10.7545 6.94573 10.6667 7.16675 10.6667H8.83341C9.05443 10.6667 9.26639 10.7545 9.42267 10.9107C9.57895 11.067 9.66675 11.279 9.66675 11.5V15.6667ZM13.8334 14.8333C13.8334 15.0544 13.7456 15.2663 13.5893 15.4226C13.4331 15.5789 13.2211 15.6667 13.0001 15.6667H11.3334V11.5C11.3334 10.837 11.07 10.2011 10.6012 9.73224C10.1323 9.2634 9.49645 9 8.83341 9H7.16675C6.5037 9 5.86782 9.2634 5.39898 9.73224C4.93014 10.2011 4.66675 10.837 4.66675 11.5V15.6667H3.00008C2.77907 15.6667 2.5671 15.5789 2.41082 15.4226C2.25454 15.2663 2.16675 15.0544 2.16675 14.8333V7.54167C2.1669 7.42335 2.19224 7.30642 2.24109 7.19865C2.28995 7.09089 2.36119 6.99476 2.45008 6.91667L7.45008 2.54167C7.60215 2.40807 7.79766 2.33439 8.00008 2.33439C8.2025 2.33439 8.398 2.40807 8.55008 2.54167L13.5501 6.91667C13.639 6.99476 13.7102 7.09089 13.7591 7.19865C13.8079 7.30642 13.8333 7.42335 13.8334 7.54167V14.8333Z"
      fill={color}
    />
  </svg>
);
