import { useRef, useEffect } from 'react';

export const useClickOutside = (
  callback: () => void,
  event: any,
  triggerElement?: React.MutableRefObject<HTMLElement | undefined>
) => {
  const containerRef = useRef<HTMLDivElement>();
  useEffect(() => {
    const container = containerRef.current;
    const trigger = triggerElement && triggerElement.current;
    if (container) {
      const listener = function(evt: MouseEvent) {
        const element = evt.target as (Node | null);
        if (!container.contains(element) && !(trigger && trigger.contains(element))) {
          callback();
        }
      };
      document.addEventListener(event, listener);
      return () => {
        document.removeEventListener(event, listener);
      };
    }
  }, []);

  return { containerRef };
};
