import React, { useState, useRef, useContext } from 'react';
import styled from 'styled-components';
import { Input, Button, SubmitArrowIcon, EDirections, EButtonSizeType } from '@dealsyte/poki';
import { useQuery, useMutation } from '@apollo/client';

import { GET_INVITATION_GROUPS, GET_GROUPS } from 'api/graphql/users/queries';
import { CREATE_ORGANIZATION, CREATE_BIDDER } from 'api/graphql/users/mutations';
import {
  CREATE_ORGANIZATION_MUTATION,
  CREATE_ORGANIZATION_MUTATIONVariables,
} from 'api/graphql/users/types/CREATE_ORGANIZATION_MUTATION';
import {
  GET_INVITATION_GROUPS_QUERY,
  GET_INVITATION_GROUPS_QUERYVariables,
} from 'api/graphql/users/types/GET_INVITATION_GROUPS_QUERY';
import {
  CREATE_BIDDER_MUTATION,
  CREATE_BIDDER_MUTATIONVariables,
} from 'api/graphql/users/types/CREATE_BIDDER_MUTATION';
import { DealSide } from 'app/core-tools/due-diligence/types/types';
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { DealType } from 'types/graphql-types';
import {
  getExternalTeamNotation,
  capitalizeFirstLetter,
} from '../../../../../../utils/helpers/helpers';
import ExternalTeamNotation from 'app/core-tools/due-diligence/components/ExternalTeamNotation';
import {
  GET_GROUPS_QUERYVariables,
  GET_GROUPS_QUERY,
} from 'api/graphql/users/types/GET_GROUPS_QUERY';

export enum EMode {
  LABEL = 'label',
  INPUT = 'input',
}

const Container = styled.div<{ mode: EMode }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(222, 224, 226, 0.3);
  color: #4485eb;
  font-size: 13px;
  padding: ${({ mode }) => (mode === 'label' ? '12px 15px' : '12px 5px')};
  bottom: 0;
  width: 209px;
`;

const ErrorContainer = styled.div<{ inputError: string | undefined }>`
  display: ${({ inputError }) => (inputError ? 'block' : 'none')};
  color: #e64344;
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-top: 5px;
`;

type IProps = {
  dealId: string;
  side: DealSide;
  setActiveItemToLastIdx: () => void;
  dealType: DealType;
  refetchInvitations: Function;
};

function CreateNewGroupButton({
  dealId,
  side,
  setActiveItemToLastIdx,
  dealType,
  refetchInvitations,
}: IProps) {
  const [mode, setMode] = useState<EMode>(EMode.LABEL);
  const [inputValue, setInputValue] = useState<string>('');
  const [inputError, setInputError] = useState<string | undefined>(undefined);
  const { showErrorToast } = useContext(AlertContext);

  const inputRef = useRef(null);

  const { data, loading, error } = useQuery<
    GET_INVITATION_GROUPS_QUERY,
    GET_INVITATION_GROUPS_QUERYVariables
  >(GET_INVITATION_GROUPS, { variables: { dealId, types: ['sell' as any] } });

  const [createOrganization, { error: mutationError }] = useMutation<
    CREATE_ORGANIZATION_MUTATION,
    CREATE_ORGANIZATION_MUTATIONVariables
  >(CREATE_ORGANIZATION, {
    update(cache, { data }) {
      const query = cache.readQuery<
        GET_INVITATION_GROUPS_QUERY,
        GET_INVITATION_GROUPS_QUERYVariables
      >({
        query: GET_INVITATION_GROUPS,
        variables: { dealId, types: ['sellOrganizations' as any] },
      });
      cache.writeQuery<GET_INVITATION_GROUPS_QUERY, GET_INVITATION_GROUPS_QUERYVariables>({
        query: GET_INVITATION_GROUPS,
        variables: { dealId, types: ['sellOrganizations' as any] },
        data: {
          groups:
            (query &&
              query.groups &&
              query.groups.concat(data && (data.createOrganization as any))) ||
            [],
        },
      });
    },
  });

  const [createBidder, { error: bidderError }] = useMutation<
    CREATE_BIDDER_MUTATION,
    CREATE_BIDDER_MUTATIONVariables
  >(CREATE_BIDDER, {
    update(cache, { data }) {
      const cachedInvitationGroups = cache.readQuery<
        GET_INVITATION_GROUPS_QUERY,
        GET_INVITATION_GROUPS_QUERYVariables
      >({
        query: GET_INVITATION_GROUPS,
        variables: { dealId, types: ['buy' as any] },
      });

      const cachedGroups = cache.readQuery<GET_GROUPS_QUERY, GET_GROUPS_QUERYVariables>({
        query: GET_GROUPS,
        variables: { dealId },
      });

      const buyGroup =
        (data &&
          data.createBidder &&
          data.createBidder.filter(group => group && group.type === 'buy')) ||
        null;

      if (buyGroup) {
        cache.writeQuery<GET_INVITATION_GROUPS_QUERY, GET_INVITATION_GROUPS_QUERYVariables>({
          query: GET_INVITATION_GROUPS,
          variables: { dealId, types: ['buy' as any] },
          data: {
            groups:
              (cachedInvitationGroups &&
                cachedInvitationGroups.groups &&
                cachedInvitationGroups.groups.concat(buyGroup)) ||
              [],
          },
        });

        cache.writeQuery<GET_GROUPS_QUERY, GET_GROUPS_QUERYVariables>({
          query: GET_GROUPS,
          variables: { dealId },
          data: {
            groups:
              (cachedGroups && cachedGroups.groups && cachedGroups.groups.concat(buyGroup)) || [],
          },
        });
      }
    },
    onCompleted() {
      refetchInvitations();
    },
  });

  if (mutationError && !inputError) {
    setMode(EMode.INPUT);
    setInputError(mutationError.message);
  }
  if (bidderError && !inputError) {
    setMode(EMode.INPUT);
    setInputError(bidderError.message);
  }
  if (error && !inputError) {
    setMode(EMode.INPUT);
    setInputError(error.message);
  }

  return (
    <Container mode={mode}>
      {mode === EMode.LABEL ? (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setMode(EMode.INPUT);

            setTimeout(() => {
              if (inputRef && inputRef.current) (inputRef.current as any).focus();
            }, 100);
          }}
        >
          <span style={{ fontSize: '15px' }}>+</span> Add a new{' '}
          {side === DealSide.SELL ? (
            'group'
          ) : (
            <ExternalTeamNotation plural={false} capitalize={false} />
          )}
        </span>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: 'calc(100% - 17px)',
            }}
          >
            <Input
              ref={inputRef}
              value={inputValue}
              onChange={e => setInputValue(capitalizeFirstLetter(e.target.value))}
              type="text"
              placeholder={`${
                side === DealSide.SELL
                  ? 'Group'
                  : getExternalTeamNotation(dealType, { plural: false })
              } Name`}
            />
            <Button
              size={EButtonSizeType.small}
              style={{ padding: '0px', height: '35px', width: '50px' }}
              onClick={() => {
                setInputError(undefined);
                if (!loading && !error && inputValue.length >= 5) {
                  if (side === DealSide.SELL) {
                    const parent =
                      data &&
                      data.groups &&
                      data.groups.find(group => group && group.type === 'sell');
                    if (parent) {
                      setMode(EMode.LABEL);
                      createOrganization({
                        variables: {
                          name: inputValue,
                          parentId: (parent.id as unknown) as string,
                        },
                        optimisticResponse: {
                          createOrganization: {
                            __typename: 'Group',
                            id: 'randomId',
                            name: inputValue,
                            type: 'organization',
                            users: [],
                            invites: [],
                            owner: null,
                            createdAt: new Date(),
                            updatedAt: 'UpdateDate',
                            relatedGroups: {
                              __typename: 'RelatedGroups',
                              children: [],
                              parents: [],
                            },
                          },
                        },
                      }).then(() => {
                        setMode(EMode.LABEL);
                      });
                      setInputValue('');
                      setMode(EMode.LABEL);
                      const groupList = document.getElementById('SidebarGroupList');
                      if (groupList) groupList.scrollTop = groupList.scrollHeight;
                      setTimeout(() => setActiveItemToLastIdx(), 50);
                    } else {
                      showErrorToast({
                        title: 'There are some network issues, please try again later',
                      });
                    }
                  } else {
                    setMode(EMode.LABEL);
                    createBidder({
                      variables: {
                        name: inputValue,
                      },
                      optimisticResponse: {
                        createBidder: [
                          {
                            __typename: 'Group',
                            id: 'randomId-buy',
                            name: inputValue,
                            type: 'buy',
                            users: [],
                            invites: [],
                            owner: null,
                            createdAt: 'CreationDate',
                            updatedAt: 'UpdateDate',
                            relatedGroups: {
                              __typename: 'RelatedGroups',
                              children: [],
                              parents: [],
                            },
                          },
                        ],
                      },
                    }).then(() => {
                      setMode(EMode.LABEL);
                    });
                    setInputValue('');
                    setMode(EMode.LABEL);
                    const groupList = document.getElementById('SidebarGroupList');
                    if (groupList) groupList.scrollTop = groupList.scrollHeight;
                    setTimeout(() => setActiveItemToLastIdx(), 50);
                  }
                } else {
                  if (!inputValue) showErrorToast({ title: 'Please type a group name' });
                  else if (inputValue.length < 5)
                    showErrorToast({ title: 'The name should be at least 5 characters long' });
                  else if (loading) showErrorToast({ title: 'Please try again in a few second' });
                  else if (error)
                    showErrorToast({
                      title: 'There was an error retrieving needed data, please try again later',
                    });
                }
              }}
            >
              <SubmitArrowIcon height={10} direction={EDirections.right} />
            </Button>
          </div>
          <ErrorContainer inputError={inputError}>{inputError}</ErrorContainer>
        </>
      )}
    </Container>
  );
}

export default CreateNewGroupButton;
