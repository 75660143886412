import React from 'react';
import { Button, HStack, SideDrawer } from '../../../../common/components';
import { useAmendmentHandler, AmendmentHandlerProvider } from './AmendmentHandler';
import AmendmentForm from '../../../../compliance/components/forms/AmendmentForm/Amendment';

const AmendmentFormDrawer = () => {
  const handler = useAmendmentHandler();
  const { onSubmitAmendmentForm, submitting, form, modal } = handler;
  const { hide, modalState } = modal;
  const { isValid } = form;

  const onClose = () => {
    form.resetForm();
    hide();
  };

  return (
    <AmendmentHandlerProvider value={handler}>
      <SideDrawer
        show={modalState.show}
        title="Client engagement - Amendment"
        onClose={onClose}
        promptOnClose={form.dirty}
        footerContent={({ onClose: closeWithPrompt }) => (
          <HStack justifyContent="flex-end" spacing={16}>
            <Button onClick={closeWithPrompt} className="tertiary">
              Dismiss
            </Button>
            <Button
              style={{ width: 176 }}
              disabled={!isValid || submitting}
              onClick={onSubmitAmendmentForm}
            >
              Submit
            </Button>
          </HStack>
        )}
      >
        <AmendmentForm />
      </SideDrawer>
    </AmendmentHandlerProvider>
  );
};

export default AmendmentFormDrawer;
