import React from 'react';

import { Pill } from 'app/common/components';
import { EFormStatus } from '../../types/formsTypes';
import { FormStepStatus } from './ComplianceTrackerFormCard';
import { getFormStatusPill } from '../FormsBrowser/HelpersFormsBrowser';

export const inferCardStatus = (
  status: EFormStatus = EFormStatus.IN_REVIEW,
  stepStatus: FormStepStatus
) => {
  if (stepStatus === FormStepStatus.BLOCKED) {
    return <Pill label="NOT SUBMITTED" className="gray" />;
  }
  if (stepStatus === FormStepStatus.READY) {
    return <Pill label="NOT SUBMITTED" className="gray" />;
  }

  if (stepStatus === FormStepStatus.COMPLETED) {
    return getFormStatusPill(status);
  }
  return <Pill label="NOT SUBMITTED" className="gray" />;
};
