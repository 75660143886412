import * as React from 'react';
import { IconProps } from './types';

export const CheckCircleIcon = ({
  width = '14',
  height = '14',
  color = 'currentColor',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99992 0.333313C3.33325 0.333313 0.333252 3.33331 0.333252 6.99998C0.333252 10.6666 3.33325 13.6666 6.99992 13.6666C10.6666 13.6666 13.6666 10.6666 13.6666 6.99998C13.6666 3.33331 10.6666 0.333313 6.99992 0.333313ZM9.79992 5.86665L6.59992 9.06665C6.33325 9.33331 5.93325 9.33331 5.66659 9.06665L4.19992 7.59998C3.93325 7.33331 3.93325 6.93331 4.19992 6.66665C4.46659 6.39998 4.86659 6.39998 5.13325 6.66665L6.13325 7.66665L8.86659 4.93331C9.13325 4.66665 9.53325 4.66665 9.79992 4.93331C10.0666 5.19998 10.0666 5.59998 9.79992 5.86665Z"
      fill={color}
    />
  </svg>
);
