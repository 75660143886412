import styled from 'styled-components';

export const SearchBarWrapper = styled.div`
  ${({ theme }) => {
    const { colors, radii } = theme.foundations;
    return `
      height: 40px;
      background: ${colors.background.secondary};
      border-radius: ${radii.md};
      border: 1px solid ${colors.outline.default};
      display: flex;
      align-items: center;
      position: relative;
      input {
        background: transparent;
        border: none;
        width: 100%;
        height: 100%;
        padding: 8px 34px 8px 12px;
      }
      svg {
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
        position: absolute;
      }
    `;
  }}
`;
