// Libraries
import React, { useContext } from 'react';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import _get from 'lodash/get';

// Context
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { FilterContext } from 'app/marketplace/context/FilterContext';

// Hooks
import useStateModal from 'app/utils/hooks/useStateModal';

// Icons
import { ChatBubbleInfoIcon } from 'app/common/icons/index';

// Design System
import { TextArea, Badge } from '@dealsyte/fns-design-system';
import { HStack, SideDrawer } from 'app/common/components';
import { SideDrawerContent, ButtonsContainer } from './StyledSideDrawer';

// Mutations
import { REQUEST_COLLABORATE_IN_OFFERING } from '../../../../../api/graphql/marketplace/mutations';

// Helpers
import collaborationTypeValidationSchema from './HelperSideDrawer';

// Types
import { IProps, CollaborateTypeEnum } from './TypesSideDrawer';
import { MarketplaceType } from '../../../types';

// Constants
import { MAX_COLLAB_PER_FIRM } from '../../../constants/index';

function SideDrawerContainer(): JSX.Element {
  const { showToastV2 } = useContext(AlertContext);
  const { modalState: sideDrawerState, hide } = useStateModal('collaborationSideDrawer');
  const { show, props: sideDrawerProps }: IProps = sideDrawerState;
  const { dealSelected, refetch, type } = sideDrawerProps;
  const { collaborationsMadeToday, getFirmToastLimit } = useContext(FilterContext);
  const [collaborateInOffering] = useMutation(REQUEST_COLLABORATE_IN_OFFERING, {
    onCompleted() {
      showToastV2({
        description: 'Message sent successfully.',
        variant: 'success',
        showCloseIcon: true,
      });
      if (MAX_COLLAB_PER_FIRM - collaborationsMadeToday - 1 === 0) {
        showToastV2({
          description: (
            <p>
              You have reached the daily limit of 3 messages. The <b>Start conversation</b> button
              will be re-enabled tomorrow.
            </p>
          ),
          variant: 'warning',
          showCloseIcon: true,
        });
      } else {
        showToastV2({
          description: `You can send ${MAX_COLLAB_PER_FIRM -
            collaborationsMadeToday -
            1} more message today.`,
          variant: 'regular',
          showCloseIcon: true,
        });
      }
      refetch();
      getFirmToastLimit();
      hide();
    },
    onError(error: any) {
      showToastV2({ description: error.message, variant: 'danger', showCloseIcon: true });
      hide();
    },
  });

  const initialValues = {
    collaborateType: '',
    comment: '',
  };

  const handleCollaborateInOffering = async (values: any) => {
    collaborateInOffering({
      variables: {
        offeringId: _get(dealSelected, 'id', ''),
        offeringType: type,
        input: {
          comment: values.comment,
          type: CollaborateTypeEnum.OTHER,
        },
      },
    });
  };

  const handleClose = (resetForm: () => void) => {
    resetForm();
    hide();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { resetForm }) => {
        await handleCollaborateInOffering(values);
        resetForm();
      }}
      validationSchema={collaborationTypeValidationSchema}
    >
      {({ setFieldValue, values, handleSubmit, resetForm, dirty }) => (
        <SideDrawer
          promptConfig={{
            uiConfig: config => ({
              ...config,
              title: (
                <HStack spacing={16} alignItems="center">
                  <ChatBubbleInfoIcon color="#2C6EF2" />
                  <span> Discarding this message?</span>
                </HStack>
              ),
              description: 'Closing this window will lose the text you were writing.',
              confirmLabel: 'Discard message',
              declineLabel: 'Continue editing',
            }),
          }}
          promptOnClose={dirty}
          show={show}
          onClose={() => handleClose(resetForm)}
          title="Start conversation"
          closeWhenClickOutside={false}
          footerContent={({ onClose: promptClose }) => (
            <ButtonsContainer>
              <button type="submit" className="cancel-button" onClick={promptClose}>
                <p>Cancel</p>
              </button>
              <button
                type="submit"
                className="filter-button"
                onClick={event => {
                  event.preventDefault();
                  handleSubmit();
                }}
              >
                <p>Send message</p>
              </button>
            </ButtonsContainer>
          )}
        >
          <SideDrawerContent>
            <div className="subheader">
              {type === MarketplaceType.FUND ? (
                <h2>Write an introductory message for the fund </h2>
              ) : (
                <h2>Write an introductory message for the deal</h2>
              )}
              <div className="badge-container">
                <Badge
                  title={_get(dealSelected, 'marketplaceDealId')}
                  color="292839"
                  fill="#F8F8FC"
                />
              </div>
            </div>
            {type === MarketplaceType.FUND ? (
              <p>
                Introduce yourself to the fund’s managers to let them know of potential funding
                sources for the opportunities available through their fund.
              </p>
            ) : (
              <p>
                Introduce yourself to the owner of the deal and suggest how you would like to
                collaborate on it.
              </p>
            )}
            <div className="message-box">
              <div className="icon-container">
                <ChatBubbleInfoIcon />
              </div>
              <div className="message-container">
                <p>
                  This action does <b>not</b> imply a commitment to collaborate - it only initiates
                  a non-binding conversation.
                </p>
              </div>
            </div>
            <div className="textarea-container">
              <p>Write your message</p>
              <TextArea
                style={{ minWidth: 502, borderColor: '#807DA1', minHeight: 149, padding: 12 }}
                onChange={(evt: any) => {
                  setFieldValue('comment', _get(evt, 'target.value', ''));
                }}
                value={values.comment}
                placeholder="e.g. Hello! I'm intrigued and would like to know more..."
              />
            </div>
          </SideDrawerContent>
        </SideDrawer>
      )}
    </Formik>
  );
}

export default SideDrawerContainer;
