import * as React from 'react';
import { IconProps } from './types';

export const MoneyIcon = ({
  color = 'currentColor',
  width = '15px',
  height = '14px',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="m6.639 3 .36-.394v2.393a.667.667 0 0 0 1.333 0V2.606l.36.367a.667.667 0 0 0 1.12-.19.667.667 0 0 0-.147-.73L8.14.526a.667.667 0 0 0-.22-.14.667.667 0 0 0-.507 0 .667.667 0 0 0-.22.14l-1.527 1.5a.688.688 0 0 0 .974.973Zm1.026 4a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 2.666a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333ZM3 8.999a.667.667 0 1 0 1.333 0A.667.667 0 0 0 3 9Zm9.333 0a.667.667 0 1 0-1.334 0 .667.667 0 0 0 1.334 0Zm.667-4.666h-2.667a.667.667 0 0 0 0 1.333h2.667a.667.667 0 0 1 .666.667v5.333a.667.667 0 0 1-.666.667H2.332a.667.667 0 0 1-.667-.667V6.333a.667.667 0 0 1 .667-.667h2.667a.667.667 0 1 0 0-1.333H2.332a2 2 0 0 0-2 2v5.333a2 2 0 0 0 2 2h10.667a2 2 0 0 0 2-2V6.333a2 2 0 0 0-2-2Z"
      fill={color}
    />
  </svg>
);
