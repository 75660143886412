import React from 'react';
import { UploadFilesContainer } from './ClosingDocumentsFormStyled';

export const CDOCLabels = {
  CONFLICTS:
    'Do you have any conflict(s) of interest to disclose (e.g., board seat, advisor, family connection, equity interest, connection to the deal via an OBA/PST)?',
  CONFLICTS_DESCRIPTION: 'Please describe',
  CLOSING_DATE: 'Anticipated closing date',
  DEAL_SIZE: 'Anticipated deal size',
  UPLOAD_FILES: (
    <UploadFilesContainer>
      Upload key Deal Documents, including but not limited to the following:
      <ul>
        <li>Executed LOI</li>
        <li>
          Executed Definitive Documents (e.g., Purchase Agreement, Subscription Agreement, etc.)
        </li>
        <li>Funds Flow or Cap Table</li>
      </ul>
    </UploadFilesContainer>
  ),
};
