import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import { compare } from 'natural-orderby';

import { ESortingOrders } from 'app/core-tools/due-diligence/types/types';
import { Fso as IFso, SortingCriterias as ESortingCriterias } from '../types';

export const groupByDate = (
  elements: Object[],
  attribute: string
): { [groupName: string]: any[] } =>
  groupBy(
    elements,
    (element: any) =>
      (element &&
        dayjs(element[attribute])
          .startOf('day')
          .format()) ||
      ''
  );

export const createBlob = function(name?: string, size?: number, mimeType?: string): Blob {
  name = name || 'mock.txt';
  size = size || 128;
  mimeType = mimeType || 'plain/txt';

  function range(count: number) {
    let output = '';
    for (let i = 0; i < count; i++) {
      output += 'a';
    }
    return output;
  }

  const blob = new Blob([range(size)], { type: mimeType });
  // @ts-ignore
  blob.lastModifiedDate = new Date();
  // @ts-ignore
  blob.name = name;

  return blob;
};

export const createFileList = (blobs: Blob[]): FileList => {
  let index = 0;
  const fileList = {
    length: blobs.length,
    item: (index: number) => blobs[index] as Blob,
  } as any;
  blobs.forEach((blob: Blob, idx: number) => {
    fileList[index] = blob as Blob;
  });
  return fileList as FileList;
};

export const sortFsos = (fsosArr: IFso[], criteria: ESortingCriterias, order: ESortingOrders) => {
  if (fsosArr.length === 0) return fsosArr;

  const _sortFsos = (fsosArr: IFso[]) => {
    const _fsosArr = [...fsosArr];

    _fsosArr.sort((fso_i, fso_j) => {
      if (criteria === 'index') {
        const splittedFso_i = fso_i.index.split('.'),
          indexFso_i = splittedFso_i[splittedFso_i.length - 1];
        const splittedFso_j = fso_j.index.split('.'),
          indexFso_j = splittedFso_j[splittedFso_j.length - 1];

        return compare({ order })(indexFso_i, indexFso_j);
      }

      return compare({ order })(fso_i[criteria], fso_j[criteria]);
    });

    return _fsosArr;
  };

  return _sortFsos(fsosArr);
};

export const isFsoNameValid = (name: string, maxLength: number) => {
  const nameIsEmpty = name.length === 0,
    nameExceedsMaxLength = name.length > maxLength;

  return !nameExceedsMaxLength && !nameIsEmpty;
};
