export function getDateTimePickerFormattedDate(stringDate: string | null) {
  if (!stringDate) return null;
  const date = new Date(stringDate);
  return { year: date.getUTCFullYear(), month: date.getUTCMonth() + 1, day: date.getUTCDate() };
}

export const tailPeriodOptions = [
  {
    value: 6,
    label: '6 months',
  },
  {
    value: 7,
    label: '7 months',
  },
  {
    value: 8,
    label: '8 months',
  },
  {
    value: 9,
    label: '9 months',
  },
  {
    value: 10,
    label: '10 months',
  },
  {
    value: 11,
    label: '11 months',
  },
  {
    value: 12,
    label: '12 months',
  },
  {
    value: 13,
    label: '13 months',
  },
  {
    value: 14,
    label: '14 months',
  },
  {
    value: 15,
    label: '15 months',
  },
  {
    value: 16,
    label: '16 months',
  },
  {
    value: 17,
    label: '17 months',
  },
  {
    value: 18,
    label: '18 months',
  },
  {
    value: 19,
    label: '19 months',
  },
  {
    value: 20,
    label: '20 months',
  },
  {
    value: 21,
    label: '21 months',
  },
  {
    value: 22,
    label: '22 months',
  },
  {
    value: 23,
    label: '23 months',
  },
  {
    value: 24,
    label: '24 months',
  },
];

export const getTailPeriodLabel = (status: number | null) => {
  if (!status) return tailPeriodOptions[0];
  const tailPeriodExpiration = tailPeriodOptions.find((x: any) => x.value === status);
  return tailPeriodExpiration || tailPeriodOptions[0];
};
