// vendors
import * as yup from 'yup';
import get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

// constants
import { MAX_DEAL_SIZE, MIN_DEAL_SIZE } from 'app/utils/constants/app/appConstants';

// types
import { EDealStatus } from 'app/utils/types/types';
import { DayValue } from 'react-modern-calendar-datepicker';

const EMPTY_MESSAGE = 'This field cannot be empty';
const PROHIBITED_CHARS_MESSAGE = 'Some characters are not allowed';
const ALPHANUMERIC_REGEX = /^[\w\-\s]*$/;

export const updateDealValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Please provide a name for your deal.')
    .trim(EMPTY_MESSAGE)
    .min(2, 'Should be at least 2 characters')
    .max(120, 'Should not be more than 120 characters')
    .matches(ALPHANUMERIC_REGEX, PROHIBITED_CHARS_MESSAGE),
  type: yup.object().required('Please select the type of transaction.'),
  companyName: yup.string().required('Please provide the target company name.'),
  companyAddress: yup.string().required('Please provide the target company address.'),
  companyAgent: yup.string().required('Please provide the target company agent.'),
  mode: yup.string(),
  expectedSize: yup
    .number()
    .required('Please provide the deal expected size.')
    .min(MIN_DEAL_SIZE, `Should be equal to or higher than $${MIN_DEAL_SIZE}`)
    .max(MAX_DEAL_SIZE, `Should be lower than or equal to $${MAX_DEAL_SIZE}`),
  expectedCommissions: yup
    .number()
    .required('Please provide the expected commissions earnings.')
    .test(
      'should-be-lower-than-expected-deal-size',
      'Should be lower than expected deal size',
      function test(dealCommissions: any): boolean {
        return dealCommissions < get(this, 'parent.expectedSize', 0);
      }
    ),
  dealStatus: yup.object().required(),
  projectName: yup.string(),
  closingDate: yup
    .object()
    .nullable()
    .test(
      'date-not-null-when-status-equals-dd-closing',
      'Please select an estimated closing date',
      function test(closingDate: DayValue) {
        const isDealStatusDDClosing =
          get(this, 'parent.dealStatus.value', '') === EDealStatus.DUE_DILIGENCE_CLOSING;
        return isDealStatusDDClosing ? !_isEmpty(closingDate) : true;
      }
    ),
});
