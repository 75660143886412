import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

if (
  process.env.REACT_APP_DATADOG_RUM_APP_ID &&
  process.env.REACT_APP_DATADOG_RUM_APP_ID !== 'localhost' &&
  process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN &&
  process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN !== 'localhost'
) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    sampleRate: 100,
    replaySampleRate: 100,
    trackInteractions: true,
  });

  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    trackInteractions: true,
  });
}
