import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setRedirecting as setRedirectingAction } from '../../../utils/redux/uiReducer/uiAction';

export const useRedirectionState = () => {
  const dispatch = useDispatch();
  const redirecting = useSelector(
    (state: { ui: { redirecting: boolean } }) => state.ui.redirecting
  );

  const setRedirecting = useCallback(
    (value: boolean) => {
      return dispatch(setRedirectingAction(value));
    },
    [dispatch]
  );

  return {
    redirecting,
    setRedirecting,
  };
};
