import React from 'react';

// Components
import RadioButton from '../RadioButton/RadioButton';

// Style
import * as S from './OptionCardStyled';

export interface OptionCardProps extends Omit<React.InputHTMLAttributes<any>, 'type'> {
  title: string;
  subtitle: string;
  icon?: React.ReactElement;
}

export const OptionCard = ({ title, subtitle, icon, ...props }: OptionCardProps) => (
  <S.OptionCardContainer {...props}>
    <S.ContentContainer>
      <RadioButton {...props} />
      <S.TextContainer>
        <S.CardTitle>{title}</S.CardTitle>
        <S.CardSubtitle>{subtitle}</S.CardSubtitle>
      </S.TextContainer>
    </S.ContentContainer>
    <S.IconContainer>{icon}</S.IconContainer>
  </S.OptionCardContainer>
);
