import React, { useState, useReducer, useContext } from 'react';
import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import { GET_INVITATION_GROUPS } from 'api/graphql/users/queries';
import {
  GET_INVITATION_GROUPS_QUERY,
  GET_INVITATION_GROUPS_QUERYVariables,
} from 'api/graphql/users/types/GET_INVITATION_GROUPS_QUERY';
import GroupItem from './GroupItem/GroupListItem';
import Overview from './sections/Overview';
import Members from './sections/Members';
import { Column, GroupList } from './StyledGroupsSettings';
import CreateNewGroupButton from './CreateNewGroupButton';
import GroupsSettingsLoader from './GroupSettingsLoader';
import { HeaderContainer } from '../../StyledSettingsModal';
import { DealSide } from 'app/core-tools/due-diligence/types/types';
import { Group as IGroup } from 'app/core-tools/due-diligence/vdr/utils/types';
import { UserContext } from 'app/users/context/UserContext';
import { DealType } from 'types/graphql-types';
import { getError } from 'app/utils/helpers/helpers';

export type Action =
  | { type: '/internal/members' }
  | { type: '/external/groups' }
  | { type: '/external/members'; id: string };

/**
 * Renders groups settings component in which admins can see and edit groups configs.
 * @param internal Flag that indicates if selected settings tab is internal or external. Based on that, it will render the proper configs UI.
 * @param showAddButton Flag that indicates if current user should see the CreateNewGroupButton or not.
 */
function GroupsSettings({
  internal,
  showAddButton = false,
}: {
  internal: boolean;
  showAddButton?: boolean;
}) {
  const [path, dispatch] = useReducer((state: string, action: Action) => {
    switch (action.type) {
      case '/internal/members':
      case '/external/groups':
        return action.type;
      case '/external/members':
        return `${action.type}/${action.id}`;
      default:
        return state;
    }
  }, '/internal/members');

  if (path.includes('internal') && !internal) dispatch({ type: '/external/groups' });
  if (path.includes('external') && internal) dispatch({ type: '/internal/members' });

  const [activeItem, setActiveItem] = useState<number>(0);

  const { activeDeal } = useContext(UserContext);

  const { data } = useQuery<GET_INVITATION_GROUPS_QUERY, GET_INVITATION_GROUPS_QUERYVariables>(
    GET_INVITATION_GROUPS,
    {
      variables: { dealId: get(activeDeal, 'id', ''), types: ['sell' as any] },
    }
  );

  const {
    data: invitationsGroupData,
    loading: loadingInvitationsGroupData,
    error: errorOnInvitationsGroupData,
    refetch: refetchInvitations,
  } = useQuery<GET_INVITATION_GROUPS_QUERY, GET_INVITATION_GROUPS_QUERYVariables>(
    GET_INVITATION_GROUPS,
    {
      variables: {
        dealId: get(activeDeal, 'id', ''),
        types: internal ? ['sellOrganizations' as any] : ['buyOrganizations' as any],
      },
    }
  );

  if (loadingInvitationsGroupData) {
    return <GroupsSettingsLoader internal={internal}></GroupsSettingsLoader>;
  }

  if (errorOnInvitationsGroupData) {
    const fallback = 'No data to display. Please try again later.';
    return (
      <div style={{ display: 'flex', width: '100%', margin: 20 }}>
        {getError(errorOnInvitationsGroupData, fallback)}
      </div>
    );
  }

  const setActiveItemToLastIdx = () =>
    // @ts-ignore
    invitationsGroupData && setActiveItem(get(invitationsGroupData, 'groups', []).length);

  const externalMemberId = path.substr(path.lastIndexOf('/') + 1);

  const memberGroup = path.includes('/external/members')
    ? get(invitationsGroupData, `groups[${activeItem}].relatedGroups.children`, []).find(
        (group: IGroup) => get(group, 'id') === externalMemberId
      )
    : undefined;

  if (
    path.includes('/external/members') &&
    (externalMemberId !== 'members' && externalMemberId !== 'groups') &&
    !memberGroup
  ) {
    dispatch({ type: '/external/groups' });
  }

  const renderNewGroupButton = () =>
    showAddButton ? (
      <CreateNewGroupButton
        side={internal ? DealSide.SELL : DealSide.BUY}
        setActiveItemToLastIdx={setActiveItemToLastIdx}
        dealId={get(activeDeal, 'id', '')}
        dealType={get(activeDeal, 'type') as DealType}
        refetchInvitations={refetchInvitations}
      />
    ) : null;
  return (
    <div
      style={{ display: 'flex', width: '100%' }}
      data-testid={
        internal ? 'InternalGroupsSettingsMainContainer' : 'ExternalGroupsSettingsMainContainer'
      }
    >
      <GroupList>
        {renderNewGroupButton()}
        {(path.includes('external/groups') || path.includes('internal')) && (
          <Column id="SidebarGroupList" style={{ overflowY: 'auto', flex: 1, marginTop: 15 }}>
            {invitationsGroupData &&
              invitationsGroupData.groups &&
              invitationsGroupData.groups.map(
                (group, idx) =>
                  group && (
                    <GroupItem
                      idx={idx}
                      key={group.id}
                      {...group}
                      groups={get(group, 'relatedGroups.children')}
                      internal={internal}
                      active={idx === activeItem}
                      setActive={setActiveItem}
                    />
                  )
              )}
          </Column>
        )}
      </GroupList>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <HeaderContainer style={{ position: 'relative', justifyContent: 'left' }}>
          <Overview
            goBackAction={
              path.includes('/external/members')
                ? () => dispatch({ type: '/external/groups' })
                : undefined
            }
            group={
              path.includes('/external/members')
                ? (memberGroup as any)
                : get(invitationsGroupData, `groups[${activeItem}]`)
            }
            dealId={get(activeDeal, 'id', '')}
            parentGroup={
              internal
                ? data && data.groups && data.groups.find(group => group && group.type === 'sell')
                : invitationsGroupData && invitationsGroupData.groups
                ? get(invitationsGroupData, `groups[${activeItem}].relatedGroups.parents[0]`)
                : null
            }
            refetchInvitations={refetchInvitations}
          />
        </HeaderContainer>

        <div style={{ display: 'flex', overflowY: 'auto' }}>
          {
            <Members
              side={internal ? DealSide.SELL : DealSide.BUY}
              group={get(invitationsGroupData, `groups[${activeItem}]`)}
              refetchInvitations={refetchInvitations}
            />
          }
        </div>
      </div>
    </div>
  );
}
GroupsSettings.displayName = 'GroupsSettings';

export default GroupsSettings;
