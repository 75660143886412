import styled from 'styled-components';

export const ButtonContainer = styled.div`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return `
      display: flex;
      flex-direction: column;
      margin-left: ${spacing['04']};
      button {
        flex: 1;
      }
    `;
  }}
`;

export const SelectContainer = styled.div`
  ${({ theme }) => {
    const { shadows, colors, spacing, radii } = theme.foundations;
    return `
      display: flex;
      flex-direction: column;
      position: absolute;
      box-shadow: ${shadows['02']};
      background-color: ${colors.background.primary};
      padding-block: ${spacing['02']};
      border-radius: ${radii.md};
      left: 100rem;
      min-width: 157px;
      z-index: 2;
`;
  }}
`;

export const SelectOption = styled.button`
  ${({ theme }) => {
    const { colors, typography, spacing, radii } = theme.foundations;
    return `
      display: flex;
      flex-direction: row;
      color: ${colors.primary.default};
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['03']};
      line-height: 20px;
      background-color: ${colors.background.primary};
      padding-block: ${spacing['03']};
      padding-inline: ${spacing['04']} ${spacing['03']};
      cursor: pointer;
      align-items: center;
      border-bottom: 1px solid ${colors.palette.neutral[200]};

      p {
        padding-left: ${spacing['03']};
      }

      &:hover{
        background-color: ${colors.background.tertiary};
      }

      &:focus {
        z-index: 2;
        border-radius: ${radii.md};
        box-shadow: 0px 0px 0px 4px ${colors.palette.neutral['400']};
        outline: none;
      }
    `;
  }}
`;
