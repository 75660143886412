import styled, { DefaultTheme } from 'styled-components';

export const FormGroupContainer = styled.div`
  ${({ theme, width }: { theme: DefaultTheme; width?: string }) => {
    const { spacing, colors } = theme.foundations;
    return `
    width: ${width || '100%'};
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: ${spacing['04']};

    &.error {
      & > :nth-child(2) {
        border: 1px solid ${colors.states.error};
        &:focus-within {
          border: 1px solid ${colors.states.error};
        }
      }
    }

  `;
  }}
`;

export const FormGroupFooter = styled.div`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return `
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: ${spacing['01']};
    `;
  }}
`;

export const FormGroupFooterLeftSection = styled.div`
  display: flex;
  align-items: center;
`;
export const FormGroupFooterRightSection = styled.div`
  display: flex;
  align-items: center;
`;

export const FormGroupLabel = styled.label`
  ${({ theme }) => {
    const { colors, typography, spacing } = theme.foundations;
    return `
      display: inline;
      align-items: baseline;
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['03']};
      letter-spacing: 0.25px;
      line-height: 24.5px;
      color: ${colors.text.default};
      margin-bottom: ${spacing['01']};
    `;
  }}
`;

export const RequiredLabel = styled.span`
  ${({ theme }) => {
    const { colors, typography, spacing } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['02']};
      letter-spacing: 0.25px;
      line-height: 18px;
      color: ${colors.text.light};
      margin-left: ${spacing['02']};
    `;
  }}
`;

export const HelperTextContainer = styled.span`
  ${({ theme }) => {
    const { colors, typography } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['02']};
      letter-spacing: 0.25px;
      line-height: 18px;
      color: ${colors.text.light};
    `;
  }}
`;

export const ErrorTextContainer = styled.span`
  ${({ theme }) => {
    const { colors, typography } = theme.foundations;
    return `
      display: flex;
      align-items: center;
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['02']};
      letter-spacing: 0.25px;
      line-height: 18px;
      color: ${colors.states.error};
    `;
  }}
`;
