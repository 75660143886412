import styled from 'styled-components';

export const NotesTextArea = styled.textarea`
  min-height: 56px;
  border-radius: 6px;
  border: 1px solid #d5dcf6;
  padding: 21px 16px 0;
  color: #2b3664;
  font-size: 14px;
  font-family: Sofia Pro;
  margin-right: 1rem;
  width: 100%;
  white-space: pre-wrap;
  resize: vertical;
`;
