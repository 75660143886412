// vendors
import pluralize from 'pluralize';
import compose from 'lodash/fp/compose';
import compact from 'lodash/compact';
import get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import uuid from 'uuid/v1';
import { DayValue } from 'react-modern-calendar-datepicker';
import dayjs from 'dayjs';

// constants
import { MAX_DEAL_SIZE } from '../constants/app/appConstants';

// types
import { DealSide } from 'app/core-tools/due-diligence/types/types';
import { DealType } from '../../../types/graphql-types';
import { EDealStatus } from '../types/types';

enum ExternalTeamName {
  INVESTOR = 'investor',
  BUYER = 'buyer',
}

export type IDateRange = {
  from: DayValue | null;
  to: DayValue | null;
};

export type FinalisDealProps = {
  dealId: string;
  dealName: string;
  dealStatus: string;
  expectedCommissions: number | undefined;
  expectedSize: number | undefined;
  isExternal: boolean | null;
  loiProvided: boolean | null;
  closingDate: string;
};

export const capitalizeFirstLetter = (name: string) =>
  name.charAt(0).toUpperCase() + name.substring(1);

type TeamNotationOptions = { plural?: boolean; capitalize?: boolean };

/**
 * Gets the external team notation based on the deal type.
 * @param dealType The dealType.
 * @param options The options to be applied to the notation name.
 * @returns The proper external team notation.
 */
export function getExternalTeamNotation(
  dealType: DealType,
  { plural = true, capitalize = true }: TeamNotationOptions = {}
): string {
  const optionsToApply = compact([
    capitalize ? capitalizeFirstLetter : null,
    plural ? pluralize : null,
  ]);

  const applyOptions = compose(optionsToApply);

  switch (dealType) {
    case DealType.CA_RA_FUN: {
      return applyOptions(ExternalTeamName.INVESTOR);
    }
    case DealType.M_N_A_B: {
      return applyOptions(ExternalTeamName.BUYER);
    }
    default: {
      return applyOptions(ExternalTeamName.BUYER);
    }
  }
}

/**
 * Formats a DayValue object into a `MM/DD/YYYY` string
 * @param input DayValue object
 */
export function formatDateInput(input?: DayValue): string {
  if (!input) return '';
  // These arrive as regular numbers, they don't have zeroes on the left
  const { month, day, year } = input;

  // So we add them for formatting purposes with padStart
  const mm = month.toString().padStart(2, '0');
  const dd = day.toString().padStart(2, '0');
  return `${mm}/${dd}/${year}`;
}

export function formatDateRange(dateRange: IDateRange): string {
  if (!dateRange.from || !dateRange.to) {
    return '';
  }
  const { from, to } = dateRange;
  return `${from.month}/${from.day}/${from.year} - ${to.month}/${to.day}/${to.year}`;
}

/**
 * Extracts error message from apollo error object
 * @param error
 */
export const getError = (error: any, fallback: string = '') => {
  if (!error) return fallback;
  const { networkError, graphQLErrors } = error;
  if (graphQLErrors && graphQLErrors.length) {
    return get(error, 'graphQLErrors[0].message', fallback);
  }
  if (networkError) {
    return get(error, 'networkError.message', fallback);
  }
  return get(error, 'message', fallback);
};

export const buildInitialDatePickerValue = (dateStr: null | string) => {
  if (!dateStr) return null;
  const dateRaw = new Date(dateStr);
  return {
    year: dateRaw.getFullYear(),
    month: dateRaw.getMonth() + 1,
    day: dateRaw.getDate(),
  } as DayValue;
};

/**
 * Update array deals with extra fields to handle internal changes
 * @param deals
 */
export const updateDealsForm = (deals: any) => {
  deals.forEach(function (item: any) {
    const itemDate = buildInitialDatePickerValue(item.closingDate);
    item.closingDate = itemDate;
    if (!item.dealId) {
      item.dealId = uuid();
      item.isExternal = true;
    } else {
      item.isExternal = false;
    }
  });
  return deals;
};

/**
 * Remove an external deal [Logic delete] from update deals modal
 * @param deals
 * @param dealId
 */
export const removeExternalDeal = (deals: any, dealId: string) => {
  if (!_isArray(deals)) return [];
  return deals.filter((value: any) => value.dealId !== dealId);
};

/**
 * Update a field from a deal
 * @param deals
 * @param field
 * @param value
 * @param dealId
 */
export const updateDealField = (deals: any, field: string, value: any, dealId: string) => {
  let result = _cloneDeep(deals);
  if (_isArray(result)) {
    result.forEach(function (deal: any) {
      if (deal.dealId === dealId) {
        if ((field === 'expectedCommissions' || field === 'expectedSize') && value !== undefined) {
          deal[field] = Number(value);
        } else {
          deal[field] = value;
        }
      }
    });
  }
  return result;
};

/**
 * Add external Empty Deal Card
 * @param deals
 */
export const addExternalEmptyDealCard = (deals: any) => {
  let result = _cloneDeep(deals);
  if (_isArray(result)) {
    result.unshift({
      dealId: uuid(),
      dealName: '',
      dealStatus: '',
      expectedCommissions: undefined,
      expectedSize: undefined,
      isExternal: true,
      closingDate: null,
      loiProvided: false,
    });
  }
  return result;
};

/**
 * Update deals object -- deal update forms
 * @param deals
 */
export const updateObjectDeals = (deals: any) => {
  if (_isArray(deals)) {
    deals.forEach(function (deal: any) {
      if (deal.isExternal) {
        deal.dealId = null;
      }
      const isStatusDDClosing = deal.dealStatus === EDealStatus.DUE_DILIGENCE_CLOSING;
      deal.closingDate =
        deal.closingDate && isStatusDDClosing ? formatDateInput(deal.closingDate) : '';
      if (!isStatusDDClosing) {
        deal.loiProvided = false;
      }
      delete deal.isExternal;
      delete deal.__typename;
    });
  }
  return deals;
};

/**
 * Validate all the deals fields
 * @param result
 */
export const isValidUpdateDealsForm = (result: any, touchedLois: string[]) => {
  const validation = {
    isNotValidName: false,
    isNotValidCommision: false,
    isNotValidSize: false,
    isNotValidDate: false,
    untouchedLois: false,
  };
  // Check if some field is missing or wrong in deal object
  if (_isArray(result)) {
    result.forEach(function (deal: any) {
      const isStatusDDClosing = deal.dealStatus === EDealStatus.DUE_DILIGENCE_CLOSING;

      if (_isEmpty(deal.dealName)) {
        validation.isNotValidName = true;
        return;
      }
      if (!deal.expectedSize || deal.expectedSize > MAX_DEAL_SIZE) {
        validation.isNotValidSize = true;
        return;
      }
      if (
        (!deal.expectedCommissions && deal.expectedCommissions !== 0) ||
        deal.expectedCommissions >= deal.expectedSize
      ) {
        validation.isNotValidCommision = true;
        return;
      }

      if (isStatusDDClosing && !deal.closingDate) {
        validation.isNotValidDate = true;
        return;
      }

      if (isStatusDDClosing && (!deal.dealId || !touchedLois.includes(deal.dealId))) {
        validation.untouchedLois = true;
        return;
      }
    });
  }
  return validation;
};

/**
 * Check if the list of deals change from the original state.
 * @param deals
 * @param originalDeals
 */
export const isChangeFromOriginalState = (deals: any, originalDeals: any) => {
  let result = _cloneDeep(deals);
  let resultOriginalDeals = _cloneDeep(originalDeals);
  let notChange = true;
  // Check if any field is different from the original state in
  // order to enable "Save Changes" button
  if (_isArray(result)) {
    if (result.length !== resultOriginalDeals.length) {
      notChange = false;
      return;
    }

    result.forEach(function (deal: FinalisDealProps) {
      const originalDeal: any = resultOriginalDeals.filter(
        (x: FinalisDealProps) => x.dealId === deal.dealId
      )[0];

      if (!originalDeal) {
        notChange = false;
        return;
      }

      if (
        deal.dealName !== originalDeal.dealName ||
        deal.expectedCommissions !== originalDeal.expectedCommissions ||
        deal.expectedSize !== originalDeal.expectedSize ||
        deal.dealStatus !== originalDeal.dealStatus ||
        !_isEqual(deal.closingDate, originalDeal.closingDate) ||
        deal.loiProvided !== originalDeal.loiProvided
      ) {
        notChange = false;
        return;
      }
    });
  }
  return notChange;
};

export const IsUserSeller = (side: string = ''): boolean => side === DealSide.SELL;

export function getDate(dateTimezone: string, format: string = 'MMM D, YYYY'): string {
  if (_isEmpty(dateTimezone)) return '-';
  const [date] = dateTimezone.toLowerCase().split('t');
  return dayjs(date).format(format);
}

export const getReviewerFullName = (assignedTo: any) => {
  if (!assignedTo) return '-';
  return `${get(assignedTo, 'firstName')} ${get(assignedTo, 'lastName')}`;
};

/**
 * Returns the IDs of the deals that have Due-Dilligence/Closing as status.
 * @param deals the array of deals to be filtered.
 * @returns an array containing the `dealId` of the deals with prop `dealStatus` equal to
 * `EDealStatus.DUE_DILIGENCE_CLOSING`.
 */
export function getDDClosingDealsIds(deals: any): string[] {
  let dealIds: string[] = [];

  function filter(deal: any) {
    if (deal.dealStatus === EDealStatus.DUE_DILIGENCE_CLOSING) {
      dealIds.push(deal.dealId);
    }
  }

  if (_isArray(deals)) {
    deals.forEach(filter);
  }

  return dealIds;
}

export const getTimeElapsed = (startTime: number, endTime: number): string => {
  if (!startTime || !endTime || startTime > endTime) {
    return '-';
  }

  const beforeTime = dayjs.unix(startTime);
  const afterTime = dayjs.unix(endTime);
  const days = afterTime.diff(beforeTime, 'day');

  if (days === 0) {
    const hours = afterTime.diff(beforeTime, 'hour');
    let minutes = afterTime.diff(beforeTime, 'minute');
    minutes -= hours * 60;
    if (hours > 0) {
      return `${hours} hrs ${minutes} min`;
    }
    if (hours === 0) {
      let seconds = afterTime.diff(beforeTime, 'second');
      seconds -= hours * 3600;
      if (minutes > 0) {
        return `${minutes} min`;
      }
      if (seconds && seconds < 60) {
        return `${seconds} sec`;
      }
      return '-';
    }
  }
  return `${days} ${days > 1 ? 'days' : 'day'}`;
};

/**
 * Used with react number format, passed to isAllowed to check if the number is bigger than 100
 * @param props
 * @returns
 */
export const maxPercentValueAllowed = (props: any) => {
  const { floatValue } = props;
  return !floatValue || floatValue <= 100;
};

export const getDateString = (date: string, format: string, uppercase?: boolean) => {
  if (dayjs().diff(dayjs(date), 'day') < 1) return uppercase ? 'TODAY' : 'Today';
  if (dayjs().diff(dayjs(date), 'day') === 1) return uppercase ? 'YESTERDAY' : 'Yesterday';
  return dayjs(date).format(format);
};

export function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}
