import * as React from 'react';
import { IconProps } from './types';

export const StartArrowIcon = ({
  width = '11px',
  height = '12px',
  color = 'currentColor',
  style = {},
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M9.86 4 3.42.307a2.28 2.28 0 0 0-3.42 2V9.72a2.28 2.28 0 0 0 3.42 1.973L9.86 8a2.28 2.28 0 0 0 0-3.947V4Zm-.667 2.793-6.44 3.747a.96.96 0 0 1-.946 0 .947.947 0 0 1-.474-.82V2.28a.947.947 0 0 1 .947-.947c.166.004.328.047.473.127l6.44 3.72a.947.947 0 0 1 0 1.64v-.027Z"
      fill={color}
    />
  </svg>
);
