import styled from 'styled-components';
import { Link } from 'react-router-dom';

// custom theme
import { darkGrayFont } from '../../../../theme/customColors';

type selectorStyleProps = {
  width?: string;
  menuWidth: string;
};

export function selectorStyles({ width = '', menuWidth = '' }: selectorStyleProps) {
  return {
    // @ts-ignore
    control: base => ({
      ...base,
      backgroundColor: '#fafbfc',
      border: '1px solid #eeeeee',
      borderRadius: '2px',
    }),
    // @ts-ignore
    container: base => ({
      ...base,
      fontSize: 12,
      width,
      paddingRight: 10,
    }),
    // @ts-ignore
    valueContainer: base => ({
      ...base,
      fontSize: 12,
    }),
    // @ts-ignore
    singleValue: base => ({
      ...base,
      fontSize: 12,
      color: darkGrayFont,
      overflow: 'visible',
      textTransform: 'capitalize',
    }),
    // @ts-ignore
    option: base => ({
      ...base,
      textTransform: 'capitalize',
      fontSize: 12,
      overflow: 'visible',
    }),
    // @ts-ignore
    menu: base => ({
      ...base,
      width: menuWidth,
    }),
    // @ts-ignore
    placeholder: base => ({
      ...base,
      fontSize: 12,
      color: '#7c9cb2',
    }),
    // @ts-ignore
    menuPortal: base => ({
      ...base,
    }),
  };
}

export const Label = styled.span`
  color: #707f8f;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const EmailLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.blue.dark};
`;

export const Text = styled.div`
  ${({ theme }) => {
    const { colors, typography } = theme.foundations;
    return `
      color: ${colors.text.default};
      font-size: ${typography.sizes['03']};
      font-family: ${typography.fonts.body};
    `;
  }}
`;
