import styled from 'styled-components';

export const DealSizeSubHeader = styled.div`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return `
      flex-direction: row;
      margin-top: ${spacing['03']};
    `;
  }}
`;

export const DealSizeBody = styled.div`
  ${({ theme }) => {
    const { spacing, colors, typography, radii } = theme.foundations;
    // preguntarle a rocio que onda con los colores #fe6262
    return `
      flex-direction: row;
      margin-top: 0;

      .error-label {
        font-size: ${typography.sizes['02']};
        font-family: ${typography.fonts.body};
        color: #fe6262;
        display: inline-block;
        height: 10px;
        margin-top: ${spacing['03']};
      }

      .item-count {
        flex: 1;
        text-align: end;
        color: #807da1;
        font-size: ${typography.sizes['04']};
        font-family: ${typography.fonts.body};
        letter-spacing: 0.5px;
      }

      .input-container {
        margin-top: ${spacing['04']};
        margin-bottom: ${spacing['06']};
        display: flex;
        flex-direction: row;

        .separator {
          margin-left: 10px;
          margin-right: 10px;
          padding-top: ${spacing['04']};
        }

        input {
          padding-left: ${spacing['02']};
          height: ${spacing['08']};
          width: 100%;
          border-radius: ${radii.sm};
          border: 1px solid #807da1;
          color: ${colors.palette.neutral['600']};
        }
      }
    `;
  }}
`;
