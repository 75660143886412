import { EButtonMessages } from 'app/core-tools/due-diligence/types/types';

export const UPLOAD_ERROR_ALERT_MESSAGES = {
  title: {
    default: 'There was an error trying to upload your files',
    infectedFiles: 'Some files could not be uploaded',
  },
  body: {
    default: 'Please try again, or contact our support team.',
    emptyFolders: 'You cannot upload empty folders. Please try again attaching files.',
    infectedFiles:
      'The following files where not uploaded because we found they are infected with virus',
  },
  confirmAction: EButtonMessages.CONFIRM_ACTION,
};

export const RENAME_FSOS_ERROR_ALERT_MESSAGES = {
  title: (fsoType: string) => `Error renaming your ${fsoType}`,
  body: (fsoType: string, repeatedFsoName: string) =>
    `There's already a ${fsoType} named "${repeatedFsoName}". Please choose a different name.`,
  confirmAction: EButtonMessages.CONFIRM_ACTION,
};

export const MOVE_FSOS_ERROR_ALERT_MESSAGES = {
  title: (targetFsoName: string) => `Error moving to "${targetFsoName}"`,
  confirmAction: EButtonMessages.CONFIRM_ACTION,
};

export const UPDATE_WATERMARK_CONFIGS_ERROR_ALERT_MESSAGES = {
  title: 'Error updating Settings',
  confirmAction: EButtonMessages.CONFIRM_ACTION,
};

export const DOWNLOAD_FSOS_ERROR_ALERT_MESSAGES = {
  title: 'Error downloading your files',
  body: {
    tokenError: 'Connection error or missing token, try again later',
  },
  confirmAction: EButtonMessages.CONFIRM_ACTION,
};
