import React, { Dispatch, SetStateAction, useEffect, useMemo, useState, useContext } from 'react';
import get from 'lodash/get';
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import {
  invalidFormatError,
  maxConcurrentFilesError,
  supportedFormatsDisclaimer,
  SUPPORTED_FILE_FORMATS,
} from './FormFileConstants';

// styles components
import * as S from './FormFilePickerStyled';
import { FilePickerDropzone } from './FilePickerDropzone';
import { ExtendedFile, FileStatus, FileUploaderFn } from './ExtendedFile';
import { FilePickerList } from './FilePickerList';

export interface FormFilePickerProps {
  setFiles: Dispatch<SetStateAction<any>>;
  label?: React.ReactNode;
  dropzoneLabel?: string | React.ReactNode;
  dropzoneButtonLabel?: string;
  acceptedFiles?: string | string[];
  description?: string | React.ReactNode;
  maxAllowedFiles?: number;
  maxFileSize?: number;
  autoUpload?: boolean;
  uploaderFunction?: FileUploaderFn;
  error?: string;
  files?: File[];
  displayAs?: 'dropZone' | 'button';
  multiple?: boolean;
}

let renderCount = 0;

export const FormFilePicker = ({
  label,
  error,
  files,
  setFiles,
  uploaderFunction,
  dropzoneButtonLabel,
  dropzoneLabel,
  autoUpload,
  maxAllowedFiles = 30,
  maxFileSize,
  acceptedFiles,
  description,
  multiple = true,
  displayAs = 'dropZone',
}: FormFilePickerProps): JSX.Element => {
  const [filesState, setFileState] = useState<Record<string, ExtendedFile>>({});
  const [internalError, setInternalError] = useState<string>('');
  const uploadFileErrorTypes = {
    INVALID_TYPE_ERROR: 'file-invalid-type',
    FILE_SIZE_ERROR: 'file-too-large',
    MAX_ALLOWED_FILES_ERROR: 'too-many-files',
  };
  const { showToastV2 } = useContext(AlertContext);

  const FILES_MAX_SIZES_IN_BYTES = maxFileSize || 50 * 10 ** 6;

  const storedFiles = useMemo(() => Object.values(filesState), [filesState]);
  const setFileStateFn = (file: ExtendedFile) => {
    setFileState(state => ({
      ...state,
      [file.id]: file,
    }));
  };

  const supportedFileFormats = acceptedFiles || SUPPORTED_FILE_FORMATS;

  useEffect(() => {
    if (files && files.length) {
      setFileState(
        files
          .map(file => {
            const extendedFile = new ExtendedFile(file, setFileStateFn);
            extendedFile.status = FileStatus.Completed;
            return extendedFile;
          })
          .reduce((prev, curr) => ({ ...prev, [btoa(curr.file.name + Math.random())]: curr }), {})
      );
    }
    return () => {
      renderCount = 0;
    };
  }, []);

  useEffect(() => {
    if (
      renderCount > 0 &&
      storedFiles.every(instance => instance.status === FileStatus.Completed)
    ) {
      setFiles(storedFiles.map(item => item.file));
    }
    renderCount += 1;
  }, [storedFiles]);

  useEffect(() => {
    if (internalError) {
      showToastV2({
        description: internalError,
        variant: 'danger',
      });
    }
  }, [internalError]);

  const handleRemoveFile = (id: string): void => {
    const fileStateCopy = Object.values(filesState).filter(file => file.id !== id);
    const newState = fileStateCopy.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {});
    setFileState(newState);
    setInternalError('');
  };

  const handleInternalError = (rejectionError: any) => {
    switch (get(rejectionError[0], 'errors[0].code', null)) {
      case uploadFileErrorTypes.INVALID_TYPE_ERROR:
        setInternalError(invalidFormatError);
        break;
      case uploadFileErrorTypes.FILE_SIZE_ERROR:
        setInternalError(`
        ${
          rejectionError.length > 1
            ? 'Some of your files exceed'
            : `${rejectionError[0].file.name} exceeds`
        } the limit of ${FILES_MAX_SIZES_IN_BYTES / 10 ** 6}MB! Please try again.
        `);
        break;
      case uploadFileErrorTypes.MAX_ALLOWED_FILES_ERROR:
        setInternalError(maxConcurrentFilesError(maxAllowedFiles));
        break;
      default:
        setInternalError('');
        break;
    }
  };

  return displayAs === 'button' ? (
    <>
      <S.FilePickerContainer displayAs={displayAs}>
        {storedFiles.length > 0 ? (
          <FilePickerList
            files={storedFiles}
            onDelete={handleRemoveFile}
            displayAs={displayAs}
            supportedFileFormats={supportedFileFormats}
          />
        ) : (
          <>
            <FilePickerDropzone
              displayAs={displayAs}
              fileStateSetter={setFileStateFn}
              options={{
                multiple: false,
                maxSize: FILES_MAX_SIZES_IN_BYTES,
                maxFiles: maxAllowedFiles,
                onDrop: () => {
                  setInternalError('');
                },
                onDropRejected: rejectionError => handleInternalError(rejectionError),
              }}
              autoUpload={autoUpload}
              fileUploaderFn={uploaderFunction}
              label={dropzoneLabel}
              buttonLabel={dropzoneButtonLabel}
              supportedFileFormats={supportedFileFormats}
              maxFiles={maxAllowedFiles}
              storedFiles={storedFiles}
              setInternalError={setInternalError}
            />
            {(error || internalError) && (
              <S.FilePickerErrorParagraph>{error || internalError}</S.FilePickerErrorParagraph>
            )}
          </>
        )}
      </S.FilePickerContainer>
    </>
  ) : (
    <S.FilePickerContainer displayAs={displayAs}>
      {!multiple && storedFiles.length > 0 ? (
        <FilePickerList
          files={storedFiles}
          onDelete={handleRemoveFile}
          supportedFileFormats={supportedFileFormats}
        />
      ) : (
        <>
          {label && <S.FilePickerLabel>{label}</S.FilePickerLabel>}
          <FilePickerDropzone
            displayAs={displayAs}
            fileStateSetter={setFileStateFn}
            options={{
              maxSize: FILES_MAX_SIZES_IN_BYTES,
              maxFiles: maxAllowedFiles,
              onDrop: () => {
                setInternalError('');
              },
              onDropRejected: rejectionError => handleInternalError(rejectionError),
              multiple,
            }}
            autoUpload={autoUpload}
            fileUploaderFn={uploaderFunction}
            buttonLabel={dropzoneButtonLabel}
            supportedFileFormats={supportedFileFormats}
            maxFiles={maxAllowedFiles}
            storedFiles={storedFiles}
            setInternalError={setInternalError}
          />
          <S.FilePickerDescription>
            {description || supportedFormatsDisclaimer}
          </S.FilePickerDescription>
          {(error || internalError) && (
            <S.FilePickerErrorParagraph>{error || internalError}</S.FilePickerErrorParagraph>
          )}
          <FilePickerList
            files={storedFiles}
            onDelete={handleRemoveFile}
            supportedFileFormats={supportedFileFormats}
          />
        </>
      )}
    </S.FilePickerContainer>
  );
};

export default FormFilePicker;
