import { entitiesReducer, queriesReducer } from 'redux-query';
import jwtDecode from 'jwt-decode';
import AppStorage, { TOKEN_KEY } from 'app/appStorage';
import { reducer as socketReducer } from '../socket';
import pagesReducer from 'app/core-tools/due-diligence/qna/redux/reducers/pages';
import hydration, { HYDRATION_KEY } from './hydration';
import { JWT } from 'app/core-tools/due-diligence/vdr/utils/types';
import { IState } from 'app/core-tools/due-diligence/types/diligence';
import { AnyAction } from 'redux';
import uiReducer from './uiReducer/uiReducer';

const prevStore = AppStorage.getItem(HYDRATION_KEY);

export default (state = prevStore, action: AnyAction) => {
  if (action.type === '@@INIT') {
    if (prevStore) {
      const { auth, queries, logs, alerts, ...store } = prevStore as IState;
      const authToken = AppStorage.getItem(TOKEN_KEY);
      let decodedToken;
      if (authToken) {
        decodedToken = jwtDecode(authToken as string) as JWT;
      }
      if (!decodedToken) {
        state = {};
      } else {
        state = store ? { ...store, entities: { ...store.entities, searched: {} } } : {};
      }
    } else {
      state = {};
    }
  }
  const newState = {
    entities: entitiesReducer((state as IState).entities, action),
    queries: queriesReducer((state as IState).queries, action),
    socket: socketReducer((state as IState).socket, action),
    pages: pagesReducer((state as IState).pages, action),
    ui: uiReducer((state as IState).ui, action),
  };
  hydration(action, newState as IState);

  return newState;
};
