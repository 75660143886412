import styled from 'styled-components';

export const Alert = styled.div`
  ${({ theme }) => {
    const { colors, radii, spacing, shadows } = theme.foundations;
    return `
      background: ${colors.palette.neutral['200']};
      border-radius: ${radii.lg};
      padding: ${spacing['04']};
      margin-bottom: ${spacing['04']};
      display: flex;
      align-items: center;

      &.floating {
        box-shadow: ${shadows['01']};
        &.regular {
          border: 1px solid ${colors.palette.neutral['800']};
        }
        &.danger {
          border: 1px solid ${colors.palette.danger['400']};
        }
        &.warning {
          border: 1px solid ${colors.palette.warning['300']};
        }
        &.success {
          border: 1px solid ${colors.palette.success['300']};
        }
      }

      &.regular {
        background: ${colors.palette.neutral['200']};
        color: ${colors.palette.neutral['800']};
      }

      &.danger {
        background: ${colors.palette.danger['100']};
        color: ${colors.palette.danger['400']};
      }

      &.warning {
        background: ${colors.palette.warning['100']};
        color: ${colors.palette.warning['300']};
      }

      &.success {
        background: ${colors.palette.success['100']};
        color: ${colors.palette.success['300']};
      }

      &.referral {
        background: ${colors.palette.teal['100']};
        color: ${colors.palette.teal['900']};
      }

    `;
  }}
`;

export const AlertText = styled.div`
  ${({ theme }) => {
    const { typography } = theme.foundations;
    return `
    font-family: ${typography.fonts.body};
    font-weight: ${typography.weights.regular};
    font-size: ${typography.sizes['04']};
    letter-spacing: 0.25px;
    line-height: 24px;
    width: 100%;

    & > a {
      color: inherit;
      font-size: inherit;
      font-family: inherit;
    }

  `;
  }}
`;

export const IconContainer = styled.span`
  display: block;
  flex: 0 1 20px;
  margin-right: 16px;
`;

export const CloseIconContainer = styled.span`
  display: block;
  flex: 0 1 20px;
  margin-left: 16px;
  align-self: flex-start;
  cursor: pointer;
`;
