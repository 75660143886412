// Vendors
import React, { useState, useEffect } from 'react';

// Styled components
import { Body, SubHeader, SearchInput } from './StyledMulticheckFilter';
import {
  Checkbox,
  CheckboxItemWrapper,
  CheckboxLabel,
} from '../../../sections/marketplaceBrowser/StyledMarketplaceBrowser';
import ViewMoreButton from '../viewMoreButton/ViewMoreButton';

// Assets
import PlusIcon from '../../../assets/plusIcon';
import MinusIcon from '../../../assets/minusIcon';
import LensIcon from '../../../assets/lensIcon';

type FilterValue = {
  filterElement: string;
  filterParent: string | null;
  count: number;
};

type FilterOptions = {
  filterName: string;
  values: FilterValue[];
};

type ValueSelected = {
  primary: string;
  secondary: string[];
};

type IProps = {
  primaryOptions: FilterOptions;
  secondaryOptions: FilterOptions;
  valuesSelected: ValueSelected[];
  onChangeFilter: (filters: ValueSelected[]) => void;
  marketplaceAccessEnabled?: boolean;
};

const MulticheckFilter = ({
  valuesSelected,
  primaryOptions,
  secondaryOptions,
  onChangeFilter,
  marketplaceAccessEnabled,
}: IProps) => {
  const [collapsableValues, setCollapsableValues] = useState<string[]>([]);
  const [checkedPrimaryMappedValues, setCheckedPrimaryMappedValues] = useState<string[]>([]);
  const [checkedSecondaryMappedValues, setCheckedSecondaryMappedValues] = useState<string[]>([]);
  const [filterViewMore, setFilterViewMore] = useState<any>({});

  const handleCollapsableItem = (primary: string) => {
    if (collapsableValues.includes(primary)) {
      const newCollapsableValues: string[] = collapsableValues.filter((x: string) => x !== primary);
      setCollapsableValues(newCollapsableValues);
    } else {
      setCollapsableValues([...collapsableValues, primary]);
    }
  };

  useEffect(() => {
    const primaryMapper: any[] = [];
    const secondaryMapper: any[] = [];
    valuesSelected.forEach((item: ValueSelected) => {
      primaryMapper.push(item.primary);
      item.secondary.forEach((secondary: string) => {
        secondaryMapper.push(`${item.primary}-${secondary}`);
      });
    });
    setCheckedPrimaryMappedValues(primaryMapper);
    setCheckedSecondaryMappedValues(secondaryMapper);
  }, [valuesSelected]);

  const handleCheckPrimaryFilter = (filter: string) => {
    // The filter exists, so we should remove it from the checked list
    if (checkedPrimaryMappedValues.includes(filter)) {
      onChangeFilter(valuesSelected.filter((item: ValueSelected) => item.primary !== filter));
    } else {
      // Get Secondary Values from the parent
      const secondary: string[] = [];
      secondaryOptions.values.forEach((item: FilterValue) => {
        if (item.filterParent === filter && item.count >= 1) {
          secondary.push(item.filterElement);
        }
      });
      // The filter doesn´t exist, so we should add the secondary values for the primary values checked
      onChangeFilter([...valuesSelected, { primary: filter, secondary }]);
    }
  };

  const handleCheckSecondaryFilter = (secondaryValues: FilterValue) => {
    // The filter exists, so we should remove it from the checked list
    if (
      checkedSecondaryMappedValues.includes(
        `${secondaryValues.filterParent}-${secondaryValues.filterElement}`
      )
    ) {
      const countSubItems = checkedSecondaryMappedValues.filter((item: string) =>
        item.includes(`${secondaryValues.filterParent}-`)
      ).length;
      if (countSubItems <= 1 && secondaryValues.filterParent) {
        onChangeFilter(
          valuesSelected.filter(
            (item: ValueSelected) => item.primary !== secondaryValues.filterParent
          )
        );
      } else {
        onChangeFilter(
          valuesSelected.map((item: ValueSelected) => {
            if (item.primary === secondaryValues.filterParent) {
              item.secondary = item.secondary.filter(
                (subItem: string) => subItem !== secondaryValues.filterElement
              );
            }
            return item;
          })
        );
      }
    } else {
      // The filter doesn´t exist, so we should add the primary value like checked
      const count = checkedPrimaryMappedValues.filter(
        (item: string) => item === secondaryValues.filterParent
      ).length;
      if (count > 0) {
        onChangeFilter(
          valuesSelected.map((item: ValueSelected) => {
            if (item.primary === secondaryValues.filterParent) {
              item.secondary.push(secondaryValues.filterElement);
            }
            return item;
          })
        );
      } else {
        onChangeFilter([
          ...valuesSelected,
          {
            primary: secondaryValues.filterParent || '',
            secondary: [secondaryValues.filterElement],
          },
        ]);
      }
    }
  };

  const isPrimaryValueChecked = (filterElement: FilterValue) =>
    checkedPrimaryMappedValues.includes(filterElement.filterElement);

  const isSecondaryValueChecked = (filterElement: FilterValue) =>
    checkedSecondaryMappedValues.includes(
      `${filterElement.filterParent}-${filterElement.filterElement}`
    );

  const handleResetAllOptions = () => {
    onChangeFilter([]);
  };

  const handleSelectAllOptions = () => {
    const allValues: ValueSelected[] = [];
    let secondaryValues: string[] = [];
    primaryOptions.values.forEach((item: FilterValue) => {
      secondaryOptions.values.forEach((subItem: FilterValue) => {
        if (item.filterElement === subItem.filterParent) {
          secondaryValues.push(subItem.filterElement);
        }
      });
      allValues.push({
        primary: item.filterElement,
        secondary: secondaryValues,
      });
      secondaryValues = [];
    });
    onChangeFilter(allValues);
  };

  const renderExpandableItem = (element: string) => (
    <button className="expandable-icon" onClick={() => handleCollapsableItem(element)}>
      {collapsableValues.includes(element) ? (
        <MinusIcon width="11px" height="2px" />
      ) : (
        <PlusIcon width="11px" height="11px" />
      )}
    </button>
  );

  const renderSecondaryItems = (elements: FilterValue[]) => {
    return elements.map((subItem: FilterValue, index: number) => (
      <CheckboxItemWrapper key={`${index + 1}_secondary`}>
        <Checkbox
          type="checkbox"
          name={`checkboxName2_${index}`}
          onChange={() => handleCheckSecondaryFilter(subItem)}
          checked={isSecondaryValueChecked(subItem)}
          disabled={subItem.count === 0}
        />
        <CheckboxLabel className={subItem.count === 0 ? 'label-disabled' : undefined}>
          {subItem.filterElement}
        </CheckboxLabel>
        <div className="item-count">{subItem.count}</div>
      </CheckboxItemWrapper>
    ));
  };

  const renderPrimaryItems = (elements: FilterValue[]) => {
    return elements.map((item: FilterValue, index: number) => (
      <>
        <div className="primary-item">
          {renderExpandableItem(item.filterElement)}
          <CheckboxItemWrapper key={`${index + 1}_primary`}>
            <Checkbox
              style={{ marginLeft: 10 }}
              type="checkbox"
              name={`checkboxName1_${index}`}
              onChange={() => handleCheckPrimaryFilter(item.filterElement)}
              checked={isPrimaryValueChecked(item)}
              disabled={item.count === 0}
            />
            <CheckboxLabel className={`${item.count === 0 ? `label-disabled` : ''}`}>
              {item.filterElement}
            </CheckboxLabel>
            <div className="item-count">{item.count}</div>
          </CheckboxItemWrapper>
        </div>
        <div
          className="subValue-list-container"
          style={{
            display: collapsableValues.includes(item.filterElement) ? 'block' : 'none',
          }}
        >
          {secondaryOptions && secondaryOptions.values && (
            <RenderMoreThanEight
              filterParent={item.filterElement}
              filters={secondaryOptions.values.filter(
                (subItem: FilterValue) => subItem.filterParent === item.filterElement
              )}
              renderItems={renderSecondaryItems}
            />
          )}
        </div>
      </>
    ));
  };

  const RenderMoreThanEight = ({
    filterParent,
    filters,
    renderItems,
  }: {
    filterParent: string;
    filters: any;
    renderItems: any;
  }) => {
    const firstFilters = filters.slice(0, 8);
    return (
      <div>
        {filterViewMore[filterParent] ? renderItems(filters) : renderItems(firstFilters)}
        <ViewMoreButton
          display={filters.length <= 8 || (filters.length > 8 && filterViewMore[filterParent])}
          handleOnClick={() => {
            setFilterViewMore({ ...filterViewMore, [filterParent]: true });
          }}
        />
      </div>
    );
  };

  return (
    <>
      {marketplaceAccessEnabled && (
        <SubHeader>
          <div onClick={() => handleSelectAllOptions()} style={{ cursor: 'pointer' }}>
            <p>Select all</p>
          </div>
          <div>
            <div className="middle">.</div>
          </div>
          <div onClick={() => handleResetAllOptions()} style={{ cursor: 'pointer' }}>
            <p>Reset to default</p>
          </div>
        </SubHeader>
      )}
      <Body>
        <div style={{ display: 'none' }}>
          <SearchInput placeholder="Search" />
          <div className="lens-icon-container">
            <LensIcon />
          </div>
        </div>
        <div className="body-list-container">
          {primaryOptions && primaryOptions.values && renderPrimaryItems(primaryOptions.values)}
        </div>
      </Body>
    </>
  );
};

export default MulticheckFilter;
