import React, { useContext } from 'react';
import get from 'lodash/get';

// components
import { Alert, FormGroup, Input, Select } from '../../../../../../common/components';
import { formControlChangeHandlers } from '../../../../../components/FormControlChangeHandlers/FormControlChangeHandlers';
import {
  KYCClientLabels,
  KYCClientPlaceholders,
  TRANSACTION_STRUCTURE_OPTIONS,
} from '../KYCFormConstants';
import { KYCHandlerContext } from '../KYCFormHandler';
import { KYCClientForm } from './KYCClientHandler';

// style
import * as S from '../KYCFormStyled';

const KYCClient = () => {
  const { clientHandler } = useContext(KYCHandlerContext);
  const { form, isCapitalRaiseDeal, activeDeal } = clientHandler;
  const { values, setFieldValue, errors, touched } = form;
  const { handleFieldChange, handleSelectChange } = formControlChangeHandlers<KYCClientForm>(
    setFieldValue
  );

  return (
    <>
      <S.Text>Complete the following details.</S.Text>

      <S.EntityLegalNameStyled>{KYCClientLabels.ENTITY_NAME}</S.EntityLegalNameStyled>
      <S.EntityNameStyled> {get(activeDeal, 'companyName', '')} </S.EntityNameStyled>
      {isCapitalRaiseDeal && (
        <FormGroup
          id="transactionStructure"
          label={KYCClientLabels.TRANSACTION_STRUCTURE}
          error={touched.transactionStructure && errors.entityName}
          required
        >
          <Select
            options={TRANSACTION_STRUCTURE_OPTIONS}
            inputId="transactionStructure"
            name="transactionStructure"
            onChange={handleSelectChange('transactionStructure')}
            value={values.transactionStructure}
            maxMenuHeight={200}
            placeholder={KYCClientPlaceholders.TRANSACTION_STRUCTURE}
            isSearchable={false}
          />
        </FormGroup>
      )}

      <S.DrawerTitle style={{ marginBottom: '12px' }}>Point of contact</S.DrawerTitle>
      <FormGroup
        id="fullName"
        label={KYCClientLabels.FULL_NAME}
        error={touched.fullName && errors.fullName}
        required
      >
        <Input
          id="fullName"
          name="fullName"
          placeholder={KYCClientPlaceholders.FULL_NAME}
          onChange={handleFieldChange('fullName')}
          value={values.fullName}
        />
      </FormGroup>
      <FormGroup
        id="emailAddress"
        label={KYCClientLabels.EMAIL_ADDRESS}
        error={touched.emailAddress && errors.emailAddress}
        required
      >
        <Input
          id="emailAddress"
          name="emailAddress"
          placeholder={KYCClientPlaceholders.EMAIL_ADDRESS}
          onChange={handleFieldChange('emailAddress')}
          value={values.emailAddress}
        />
      </FormGroup>
      <FormGroup
        id="title"
        label={KYCClientLabels.TITLE}
        error={touched.title && errors.title}
        required
      >
        <Input
          id="title"
          name="title"
          placeholder={KYCClientPlaceholders.TITLE}
          onChange={handleFieldChange('title')}
          value={values.title}
        />
      </FormGroup>
      <Alert text={KYCClientLabels.INFO_NOTIFICATION_LABEL} style={{ marginTop: '32px' }} />
    </>
  );
};

export default KYCClient;
