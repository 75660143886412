export const actionTypes = {
  CONNECT: 'connect',
  DISCONNECT: 'disconnect',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
  MARK_NOTIFICATION_AS_SHOWN: 'MARK_NOTIFICATION_AS_SHOWN',
  MARK_NOTIFICATION_AS_READ: 'MARK_NOTIFICATION_AS_READ',
  DISCONNECT_SOCKET: 'DISCONNECT_SOCKET',
};

export const actions = {
  addNotification: notification => ({
    type: actionTypes.ADD_NOTIFICATION,
    payload: { notification: { ...notification, timestamp: new Date().getTime() } },
  }),
  markNotificationAsShown: notificationId => ({
    type: actionTypes.MARK_NOTIFICATION_AS_SHOWN,
    payload: { notificationId },
  }),
  markNotificationAsRead: notificationId => ({
    type: actionTypes.MARK_NOTIFICATION_AS_READ,
    payload: { notificationId },
  }),
  clearNotifications: () => ({
    type: actionTypes.CLEAR_NOTIFICATIONS,
  }),
  disconnectSocket: () => ({
    type: actionTypes.DISCONNECT_SOCKET,
  }),
};
