import { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
/*
  Using apollo-upload-client version 17.0.0 as the latest version 18.0.0
  requires node v18 or greater, and upgrading node is not an option at this time. 
*/
import { createUploadLink } from 'apollo-upload-client';
import { ApolloClient, ApolloProvider, InMemoryCache, ApolloLink } from '@apollo/client';
import { ServerError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

import getUserToken from 'app/utils/helpers/getUserToken';
import { ERoutePatterns } from 'app/core-tools/due-diligence/types/types';
import AppStorage, { TOKEN_KEY } from 'app/appStorage';

const UNAUTHENTICATED = 401;

type Auth0 = ReturnType<typeof useAuth0>;

interface GetApolloClientDeps {
  logout: Auth0['logout'];
  getAccessTokenSilently: Auth0['getAccessTokenSilently'];
}

const getApolloClient = ({ logout, getAccessTokenSilently }: GetApolloClientDeps) => () => {
  const onErrorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    if (networkError) console.log(`[Network error]: ${networkError}`);
    if (
      Array.isArray(graphQLErrors) &&
      !window.location.href.includes('/login') &&
      !window.location.href.includes('/vdr')
    ) {
      graphQLErrors.forEach(graphQLError => {
        if (
          graphQLError &&
          graphQLError.extensions &&
          graphQLError.extensions.code === UNAUTHENTICATED
        ) {
          AppStorage.removeKey(TOKEN_KEY);
          logout();
          window.location.href = `${ERoutePatterns.LOGIN}?from=${encodeURI(window.location.href)}`;
        }
      });
    } else if (
      networkError &&
      (networkError as ServerError).statusCode === UNAUTHENTICATED &&
      !window.location.href.includes('/login') &&
      !window.location.href.includes('/vdr')
    ) {
      AppStorage.removeKey(TOKEN_KEY);
      logout();
      window.location.href = `${ERoutePatterns.LOGIN}?from=${encodeURI(window.location.href)}`;
    }
  });

  const httpLink = createUploadLink({
    credentials: 'include',
    uri: process.env.REACT_APP_APOLLO_SERVICE_URL,
  });

  // Recommendation: Use "on" "off".
  // const auth0Enabled = process.env.REACT_APP_AUTH0_STATUS;

  const authLink = setContext(async (_, { headers }) => {
    let token: string;
    if (headers && !!headers.auth0) {
      token = `Bearer ${await getAccessTokenSilently()}`;
    } else {
      token = getUserToken();
    }
    // TODO: We can't use useFeatureFlags hook at this stage
    // on the creation of the Apollo Wrapper. Maybe some FE try harder?
    let auth0Token = '';
    try {
      auth0Token = await getAccessTokenSilently();
    } catch (_e) {}

    return {
      headers: {
        ...headers,
        authorization: token,
        ...(auth0Token !== '' &&
          !window.location.href.includes('/sign-up/') && { 'authorization-auth0': auth0Token }),
      },
    };
  });

  const client = new ApolloClient({
    /*
      Setting the httpLink as any because the createUploadLink function creates a previous version of the ApolloLink class
      which is not compatible with the current version of ApolloLink. However, this does not affect the functionality of the
      link itself and it works as expected.
    */
    link: ApolloLink.from([authLink, onErrorLink, httpLink as any]),
    cache: new InMemoryCache({
      addTypename: true,
    }),
  });
  return client;
};

function ApolloWrapper({ children }: { children: JSX.Element }) {
  const auth0 = useAuth0();

  const client = useMemo(
    () =>
      getApolloClient({
        logout: auth0.logout,
        getAccessTokenSilently: auth0.getAccessTokenSilently,
      })(),
    [auth0.logout, auth0.getAccessTokenSilently]
  );

  if (!client) return null;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default ApolloWrapper;
