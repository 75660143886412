import React, { useState, createContext, useContext } from 'react';
import _get from 'lodash/get';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

// Context
import { AlertContext } from '../../../app/notifications/context/Alert/Alert';

// Handlers
import { useRegulatoryProfileHandler } from '../sections/FormsBrowser/RegulatoryProfileHandler';
import { useComplianceTrackerHandler } from '../sections/ComplianceTracker/ComplianceTrackerHandler';

// Queries & Mutations
import {
  GET_PAGINATED_FORMS,
  GET_FORMS_COUNT,
  GET_FORM,
} from '../../../api/graphql/compliance/queries';
import {
  GET_PAGINATED_FORMS_QUERY,
  GET_PAGINATED_FORMS_QUERYVariables,
} from '../../../api/graphql/compliance/types/GET_PAGINATED_FORMS_QUERY';
import {
  UPDATE_FORM_STATUS_MUTATION,
  UPDATE_FORM_STATUS_MUTATIONVariables,
} from '../../../api/graphql/deals/types/UPDATE_FORM_STATUS_MUTATION';
import { UPDATE_FORM } from '../../../api/graphql/compliance/mutations';
import { UPDATE_FORM_STATUS } from '../../../api/graphql/deals/mutations';

// Types
import { ERoutePatterns } from '../../core-tools/due-diligence/types/types';
import { GET_FORMS_QUERYVariables } from '../../../api/graphql/compliance/types/GET_FORMS_QUERY';
import { GET_FORM_QUERY } from '../../../api/graphql/compliance/types/GET_FORM_QUERY';
import { UPDATE_FORM_MUTATION } from '../../../api/graphql/compliance/types/UPDATE_FORM_MUTATION';
import { useDealRegulationHandler } from '../sections/DealRegulation/DealRegulationHandler';

const useFormsContextHandler = () => {
  const history = useHistory();
  const { showSuccessToast, showErrorToast } = useContext(AlertContext);
  const regulatoryProfile = useRegulatoryProfileHandler();
  const complianceTracker = useComplianceTrackerHandler();
  const dealRegulation = useDealRegulationHandler();

  const [formQueryVariables, setFormQueryVariables] = useState<GET_FORMS_QUERYVariables | null>(
    null
  );

  const getFormQuery = useLazyQuery<GET_FORM_QUERY>(GET_FORM, { fetchPolicy: 'no-cache' });

  const updateFormMutation = useMutation<UPDATE_FORM_MUTATION>(UPDATE_FORM);

  const getFormAllowedPaths = [
    ERoutePatterns.OBA_ATTESTATION,
    ERoutePatterns.PST_ATTESTATION,
    ERoutePatterns.OBACCT_ATTESTATION,
  ];
  const isInFormAttestationPath = getFormAllowedPaths.includes((history.location.pathname ||
    '') as ERoutePatterns);

  const attestationFormsQuery = useQuery<
    GET_PAGINATED_FORMS_QUERY,
    GET_PAGINATED_FORMS_QUERYVariables | null
  >(GET_PAGINATED_FORMS, {
    skip: !isInFormAttestationPath && !formQueryVariables,
    fetchPolicy: 'no-cache',
    variables: formQueryVariables,
  });

  const { data: OBACount, refetch: refetchOBACount } = useQuery(GET_FORMS_COUNT, {
    variables: { searchQuery: { type: 'OBA' } },
  });
  const { data: PSTCount, refetch: refetchPSTCount } = useQuery(GET_FORMS_COUNT, {
    variables: { searchQuery: { type: 'PST' } },
  });
  const { data: OBAccCount, refetch: refetchOBAccCount } = useQuery(GET_FORMS_COUNT, {
    variables: { searchQuery: { type: 'OBAcct' } },
  });
  const { data: MMR, refetch: refetchMMRCount } = useQuery(GET_FORMS_COUNT, {
    variables: { searchQuery: { type: 'MMR' } },
  });

  const regulatoryFormsCount = {
    OBA: _get(OBACount, 'formsCount', 0),
    refetchOBACount,
    PST: _get(PSTCount, 'formsCount', 0),
    refetchPSTCount,
    OBAcc: _get(OBAccCount, 'formsCount', 0),
    refetchOBAccCount,
    MMR: _get(MMR, 'formsCount', 0),
    refetchMMRCount,
  };

  const refetchAll = () => {
    const refetchMap = {
      regulatoryProfile: regulatoryProfile.query.refetch,
      complianceTracker: complianceTracker.query.refetch,
      dealRegulation: dealRegulation.query.refetch,
      attestationForms: attestationFormsQuery.refetch,
      OBACount: refetchOBACount,
      PSTCount: refetchPSTCount,
      OBAcctCount: refetchOBAccCount,
      MMRCount: refetchMMRCount,
    };
    type refetchKeys = keyof typeof refetchMap;
    return (refetchKeys: refetchKeys[] = Object.keys(refetchMap) as refetchKeys[]) => {
      refetchKeys.forEach(key => {
        const refetchFn = _get(refetchMap, key);
        refetchFn && refetchFn();
      });
    };
  };

  const updateFormStatusMutation = useMutation<
    UPDATE_FORM_STATUS_MUTATION,
    UPDATE_FORM_STATUS_MUTATIONVariables
  >(UPDATE_FORM_STATUS, {
    onCompleted() {
      showSuccessToast({
        title: 'Successfully update form status',
      });
      attestationFormsQuery.refetch && attestationFormsQuery.refetch();
    },
    onError(error) {
      const fallback = { title: 'Failed to update form status' };
      showErrorToast(fallback, error);
    },
  });

  return {
    regulatoryFormsCount,
    updateFormStatusMutation,
    attestationFormsQuery,
    regulatoryProfile,
    complianceTracker,
    dealRegulation,
    getFormQuery,
    updateFormMutation,
    setFormQueryVariables,
    refetchAll: refetchAll(),
  };
};

type FormsContextType = ReturnType<typeof useFormsContextHandler>;

const FormsContext = createContext<FormsContextType>({} as FormsContextType);

const FormsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const handler = useFormsContextHandler();
  return <FormsContext.Provider value={handler}>{children}</FormsContext.Provider>;
};

export { FormsContext, FormsContextProvider };
