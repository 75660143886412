import * as React from 'react';
import { IconProps } from './types';

export const LightbulbIcon = ({
  width = '20',
  height = '20',
  color = 'currentColor',
}: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 22 30"
    fill="none"
  >
    <path
      d="M17.7867 2.76017C16.5604 1.75162 15.1244 1.02954 13.5836 0.646631C12.0427 0.263718 10.4358 0.229624 8.88002 0.546839C6.80853 0.965268 4.90801 1.9896 3.4196 3.48988C1.93119 4.99016 0.921987 6.89875 0.520024 8.97351C0.226486 10.5291 0.279827 12.1304 0.67624 13.663C1.07265 15.1957 1.80237 16.622 2.81336 17.8402C3.7516 18.8989 4.2894 20.2529 4.33336 21.6668V25.6668C4.33336 26.7277 4.75479 27.7451 5.50493 28.4953C6.25508 29.2454 7.27249 29.6668 8.33336 29.6668H13.6667C14.7276 29.6668 15.745 29.2454 16.4951 28.4953C17.2453 27.7451 17.6667 26.7277 17.6667 25.6668V21.9202C17.7114 20.3591 18.285 18.8594 19.2934 17.6668C21.0602 15.4812 21.893 12.6866 21.6109 9.89034C21.3288 7.09408 19.9545 4.52216 17.7867 2.73351V2.76017ZM15 25.6668C15 26.0205 14.8595 26.3596 14.6095 26.6096C14.3595 26.8597 14.0203 27.0002 13.6667 27.0002H8.33336C7.97974 27.0002 7.6406 26.8597 7.39055 26.6096C7.1405 26.3596 7.00002 26.0205 7.00002 25.6668V24.3335H15V25.6668ZM17.2267 16.0135C15.886 17.6036 15.1038 19.5896 15 21.6668H12.3334V17.6668C12.3334 17.3132 12.1929 16.9741 11.9428 16.724C11.6928 16.474 11.3536 16.3335 11 16.3335C10.6464 16.3335 10.3073 16.474 10.0572 16.724C9.80717 16.9741 9.66669 17.3132 9.66669 17.6668V21.6668H7.00002C6.96484 19.6243 6.20929 17.6599 4.86669 16.1202C3.98088 15.0588 3.38521 13.7861 3.13777 12.426C2.89033 11.0659 2.99956 9.66486 3.45481 8.35955C3.91006 7.05423 4.69579 5.88916 5.73539 4.97794C6.77499 4.06672 8.03298 3.44044 9.38669 3.16017C10.5501 2.92063 11.7522 2.94316 12.9058 3.22613C14.0595 3.5091 15.1356 4.04541 16.0561 4.79611C16.9766 5.54681 17.7184 6.49308 18.2276 7.5662C18.7368 8.63932 19.0007 9.81237 19 11.0002C19.0099 12.8267 18.3828 14.5995 17.2267 16.0135Z"
      fill={color}
    />
  </svg>
);
