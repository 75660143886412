import React, { useContext } from 'react';

import {
  FormFilePicker,
  FormGroup,
  Input,
  SingleOptionRadioButtons,
  TextArea,
} from 'app/common/components';

import { CDOCLabels } from './ClosingDocumentsFormConstants';
import {
  ClosingDocumentsForm as ClosingDocumentsFormType,
  ClosingDocumentsHandlerContext,
} from './ClosingDocumentsFormHandler';
import { formControlChangeHandlers } from '../../FormControlChangeHandlers/FormControlChangeHandlers';

const ClosingDocumentsForm = () => {
  const { form } = useContext(ClosingDocumentsHandlerContext);
  const { values, touched, setFieldValue, errors } = form;

  const { handleFieldChange, handleFilesChange, handleYesNoChange } = formControlChangeHandlers<
    ClosingDocumentsFormType
  >(setFieldValue);

  return (
    <>
      <FormFilePicker
        error={touched.files && errors.files}
        label={CDOCLabels.UPLOAD_FILES}
        files={values.files}
        setFiles={handleFilesChange('files')}
        acceptedFiles=".jpg, .jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .png, image/jpeg, image/png, text/plain, .doc, .docx, .xls, .xlsx, .ppt, .pptx, application/excel, application/vnd.ms-excel, application/x-excel, application/x-msexcel, application/doc, application/ms-doc, application/msword, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
        description="DOC, DOCX, JPG, JPEG, PDF, PNG, PPT, PPTX, XLS, XLSX and plain text are supported."
      />

      <FormGroup
        label={CDOCLabels.CONFLICTS}
        style={{ marginTop: 24 }}
        required
        error={touched.conflicts && errors.conflicts}
      >
        <SingleOptionRadioButtons
          strictValueOnChange
          value={values.conflicts}
          id="conflicts"
          name="conflicts"
          onChange={handleYesNoChange('conflicts')}
        />
      </FormGroup>

      {values.conflicts && (
        <FormGroup
          id="conflictsDescription"
          label={CDOCLabels.CONFLICTS_DESCRIPTION}
          required
          error={touched.conflictDescription && errors.conflictDescription}
        >
          <TextArea
            id="conflictsDescription"
            name="conflictsDescription"
            value={values.conflictDescription}
            onChange={handleFieldChange('conflictDescription')}
          />
        </FormGroup>
      )}

      <FormGroup
        id="closingDate"
        label={CDOCLabels.CLOSING_DATE}
        required
        error={touched.closingDate && errors.closingDate}
      >
        <Input
          id="closingDate"
          name="closingDate"
          value={values.closingDate}
          onChange={handleFieldChange('closingDate')}
        />
      </FormGroup>

      <FormGroup
        id="dealSize"
        label={CDOCLabels.DEAL_SIZE}
        required
        error={touched.dealSize && errors.dealSize}
      >
        <Input
          id="dealSize"
          name="dealSize"
          value={values.dealSize}
          onChange={handleFieldChange('dealSize')}
        />
      </FormGroup>

      <FormGroup id="notes" label="Do you want to add any comments / notes:">
        <TextArea
          id="notes"
          placeholder="Comments here..."
          value={values.note}
          onChange={handleFieldChange('note')}
        />
      </FormGroup>
    </>
  );
};

export default ClosingDocumentsForm;
