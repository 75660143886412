import styled from 'styled-components';

const fontFamily = 'Effra';

export const DropdownContainer = styled.div`
  display: flex;
`;

export const IconContainer = styled.div`
  margin-right: 8px;
`;

export const DropdownModal = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 210px;
  background-color: #fefefe;
  box-shadow: 0px 4px 16px 4px rgba(65, 78, 129, 0.1);
  border-radius: 6px;
  position: absolute;
  z-index: 1;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const DropdownDivider = styled.div`
  height: 1px;
  border-bottom: 1px solid #ededf7;
`;

export const DropdownOption = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 24px 12px 16px;
`;

export const OptionIconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

export const OptionLabelContainer = styled.div`
  max-width: ${210 - 72}px;
  font-family: ${fontFamily};
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #47455f;
`;

export const ButtonContainer = styled.div`
  display: flex;
  padding: 16px;
`;
