/* eslint-disable camelcase */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useQuery,
  useMutation,
  QueryResult,
  OperationVariables,
  MutationTuple,
} from '@apollo/client';
import _get from 'lodash/get';

import { GET_DEALS_QUERY } from 'api/graphql/deals/types/GET_DEALS_QUERY';
import {
  CREATE_DEAL_MUTATION,
  CREATE_DEAL_MUTATIONVariables,
} from 'api/graphql/deals/types/CREATE_DEAL_MUTATION';
import {
  UNLINK_FROM_DEAL_MUTATION,
  UNLINK_FROM_DEAL_MUTATIONVariables,
} from 'api/graphql/users/types/UNLINK_FROM_DEAL_MUTATION';
import { UNLINK_FROM_DEAL } from 'api/graphql/users/mutations';
import { UserContext } from '../../../app/users/context/UserContext';
import { AlertContext } from '../../../app/notifications/context/Alert/Alert';
import {
  GET_DEALS,
  GET_FILTERED_DEALS,
  GET_FILTERED_DEALS_NAMES,
  GET_FIRM_DEALS,
} from '../../../api/graphql/deals/queries';
import { GET_USER_PROFILE } from '../../../api/graphql/users/queries';
import {
  LOGIN_TO_DEAL_MUTATION,
  LOGIN_TO_DEAL_MUTATIONVariables,
} from '../../../api/graphql/deals/types/LOGIN_TO_DEAL_MUTATION';
import {
  CREATE_DEAL,
  LOGIN_TO_DEAL,
  UPDATE_DEAL_STATUS,
} from '../../../api/graphql/deals/mutations';
import AppStorage, { TOKEN_KEY } from '../../../app/appStorage';
import { GA_ACTION, GA_CATEGORY } from 'app/utils/types/types';
import { GET_FIRM_DEALS_QUERY } from 'api/graphql/deals/types/GET_FIRM_DEALS_QUERY';
import { GET_FILTERED_DEALS_QUERY } from 'api/graphql/deals/types/GET_FILTERED_DEALS_QUERY';
import { IPageQuery, IPaginationInfo } from './TypedFilterQuery';
import { GET_FILTERED_DEALS_NAMES_QUERY } from 'api/graphql/deals/types/GET_FILTERED_DEALS_NAMES_QUERY';
import { sortingOptions } from 'app/home/homev2/components/DealFilters/DealsFiltersConstants';
import { useLazyQuery } from '@apollo/client';
import { GET_MARKETPLACE_DEALS_V2 } from 'api/graphql/marketplace/queries';
import { ERoutePatterns } from 'app/core-tools/due-diligence/types/types';
import useGoogleAnalytics from 'app/utils/hooks/useGoogleAnalytics';
import {
  UPDATE_DEAL_STATUS_MUTATION,
  UPDATE_DEAL_STATUS_MUTATIONVariables,
} from 'api/graphql/deals/types/UPDATE_DEAL_STATUS_MUTATION';
import useGoogleAnalytics4 from 'app/utils/hooks/useGoogleAnalytics4';

interface IsearchQuery {
  searchValue: string;
  page: number;
  pageSize: number;
  filter: string;
  sort: string;
  sortField: string;
}

interface IDealsContext {
  dealsQuery: QueryResult<GET_DEALS_QUERY | GET_FIRM_DEALS_QUERY, OperationVariables>;
  filteredDealsQuery: QueryResult<GET_FILTERED_DEALS_QUERY, OperationVariables>;
  filteredDealsNamesQuery: QueryResult<GET_FILTERED_DEALS_NAMES_QUERY, OperationVariables>;
  selectedDeal: string | null;
  loginToDealMutation: MutationTuple<LOGIN_TO_DEAL_MUTATION, LOGIN_TO_DEAL_MUTATIONVariables>;
  createDealMutation: MutationTuple<CREATE_DEAL_MUTATION, CREATE_DEAL_MUTATIONVariables>;
  unlinkFromDealMutation: MutationTuple<
    UNLINK_FROM_DEAL_MUTATION,
    UNLINK_FROM_DEAL_MUTATIONVariables
  >;
  updateStatusMutation: MutationTuple<
    UPDATE_DEAL_STATUS_MUTATION,
    UPDATE_DEAL_STATUS_MUTATIONVariables
  >;
  setActiveDeal: (dealId: string) => void;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  setPageQuery: React.Dispatch<React.SetStateAction<IPageQuery>>;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  setSort: React.Dispatch<React.SetStateAction<string>>;
  setSortField: React.Dispatch<React.SetStateAction<string>>;
  filterQuery: IsearchQuery;
  dealsPaginationTracker: {
    paginationInfo: IPaginationInfo;
  };
  searchboxValue: any | null;
  setSearchboxValue: React.Dispatch<React.SetStateAction<any | null>>;
  handleViewInMarketplace: (dealId: string) => void;
}

export const DealsContext = React.createContext<IDealsContext>({} as IDealsContext);
const DealsContextProvider = DealsContext.Provider;

interface DealsProviderProps {
  children: React.ReactNode;
}

const useDealsQuery = (firmId: string) => {
  const history = useHistory();
  const allowedPath: string[] = [ERoutePatterns.DEALS_ATTESTATION];
  const isInAllowedPath = allowedPath.includes(history.location.pathname || '');
  const query = firmId ? GET_FIRM_DEALS : GET_DEALS;
  const options = firmId ? { variables: { firmId } } : {};
  return useQuery<GET_DEALS_QUERY | GET_FIRM_DEALS_QUERY>(query, {
    ...options,
    skip: !isInAllowedPath,
  });
};

const useFilteredDealsQuery = (
  userId: string,
  filter: string,
  sort: string,
  firmId: string,
  page: number = 1,
  pageSize: number = 10,
  searchValue: string,
  sortField: string
) => {
  const query = GET_FILTERED_DEALS;
  const options = {
    variables: {
      firmId: firmId ? firmId : userId,
      userId,
      searchValue,
      page,
      pageSize,
      filter,
      sort,
      sortField,
    },
  };
  return useQuery<GET_FILTERED_DEALS_QUERY>(query, options);
};

const useFilteredDealsNamesQuery = (userId: string, firmId: string) => {
  const query = GET_FILTERED_DEALS_NAMES;
  const options = {
    variables: {
      firmId: firmId ? firmId : userId,
      userId,
      searchValue: '',
      page: 1,
      pageSize: 400,
      filter: 'ALL',
      sort: 'ASC',
      sortField: sortingOptions[0][0].value,
    },
  };
  return useQuery<GET_FILTERED_DEALS_NAMES_QUERY>(query, options);
};

export const DealsProvider = ({ children }: DealsProviderProps) => {
  const user = useContext(UserContext);
  const { showSuccessToast, showErrorToast } = useContext(AlertContext);
  const [selectedDeal, setSelectedDeal] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [{ page, pageSize }, setPageQuery] = useState<IPageQuery>({ page: 1, pageSize: 10 });
  const [filter, setFilter] = useState<string>('ALL');
  const [sort, setSort] = useState<string>('DESC');
  const [sortField, setSortField] = useState<string>('createdAt');
  const firmId = _get(user.currentFirm, 'id', '');
  const dealsQuery = useDealsQuery(firmId);
  const [searchboxValue, setSearchboxValue] = useState<any | null>(null);
  const filteredDealsQuery = useFilteredDealsQuery(
    user.id,
    filter,
    sort,
    firmId,
    page,
    pageSize,
    searchValue,
    sortField
  );
  const filteredDealsNamesQuery = useFilteredDealsNamesQuery(user.id, firmId);
  const { trackSingleEvent } = useGoogleAnalytics();
  const { trackSingleEvent: trackSingleEventG4 } = useGoogleAnalytics4();
  const history = useHistory();
  const [getMMDeals] = useLazyQuery(GET_MARKETPLACE_DEALS_V2, {
    fetchPolicy: 'no-cache',
    onCompleted(response: any) {
      trackSingleEvent(GA_CATEGORY.UI, GA_ACTION.OPEN_DEAL_DETAILS);
      trackSingleEventG4(GA_CATEGORY.UI, GA_ACTION.OPEN_DEAL_DETAILS);
      window.open(
        `${ERoutePatterns.MARKETPLACE}/${response.marketplaceDeals[0].marketplaceDealId}`
      );
    },
    onError() {
      const fallback = { title: 'Error while fetching the deal in the marketplace' };
      showErrorToast(fallback);
    },
  });

  const handleViewInMarketplace = (dealId: string) => {
    getMMDeals({
      variables: {
        filters: [
          {
            filterName: 'ID',
            filterType: 'equal',
            value: dealId,
          },
        ],
      },
    });
  };

  const filterQuery = useMemo(() => {
    return { searchValue, page, pageSize, filter, sort, sortField };
  }, [searchValue, page, pageSize, filter, sort, sortField]);

  useEffect(() => {
    if (filteredDealsQuery.refetch) {
      filteredDealsQuery.refetch();
    }

    if (filteredDealsNamesQuery.refetch) {
      filteredDealsNamesQuery.refetch();
    }
  }, [user.currentFirm]);

  const createDealMutation = useMutation<CREATE_DEAL_MUTATION, CREATE_DEAL_MUTATIONVariables>(
    CREATE_DEAL,
    {
      onCompleted: () => {
        if (filteredDealsQuery.refetch) {
          filteredDealsQuery.refetch();
        }
      },
      update(cache, { data }) {
        if (data) {
          const { createDeal } = data;
          const query = GET_FILTERED_DEALS;
          const sourceKey = 'deals';

          const result = cache.readQuery({ query });
          const deals = _get(result, sourceKey, []);

          cache.writeQuery({
            query,
            data: { [sourceKey]: deals.concat({ ...createDeal, DealOwner: { id: user.id } }) },
          });
        }
      },
    }
  );

  const loginToDealMutation = useMutation<LOGIN_TO_DEAL_MUTATION, LOGIN_TO_DEAL_MUTATIONVariables>(
    LOGIN_TO_DEAL,
    {
      refetchQueries: ({ data: { loginToDeal } }) => {
        if (loginToDeal && loginToDeal.token) {
          AppStorage.setItem(TOKEN_KEY, loginToDeal.token);
        }
        return [
          {
            query: GET_USER_PROFILE,
          },
        ];
      },
      awaitRefetchQueries: true,
      update(cache) {
        const query = GET_USER_PROFILE;
        cache.writeQuery({
          query,
          data: null,
        });
      },
      onError(error) {
        const fallback = {
          title: 'Failed to load deal',
          description: 'There was an error trying to connect with that deal, please try later.',
        };
        showErrorToast(fallback, error);
        history.push(ERoutePatterns.HOME);
      },
    }
  );

  const [loginToDeal] = loginToDealMutation;

  const setActiveDeal = useCallback(
    (dealId: string) =>
      loginToDeal({
        variables: { dealId, firmId },
        optimisticResponse: {
          loginToDeal: {
            __typename: 'TokenResponse',
            token: '',
          },
        },
      }),
    [firmId, loginToDeal]
  );

  const unlinkFromDealMutation = useMutation<
    UNLINK_FROM_DEAL_MUTATION,
    UNLINK_FROM_DEAL_MUTATIONVariables
  >(UNLINK_FROM_DEAL, {
    refetchQueries: () => {
      return [{ query: GET_FILTERED_DEALS }, 'GET_FILTERED_DEALS_QUERY'];
    },
    awaitRefetchQueries: true,
    onCompleted({ unlinkFromDeal }) {
      if (unlinkFromDeal && unlinkFromDeal.status === 200) {
        showSuccessToast({
          title: `Deal removed.`,
        });
      }
    },
    onError(error) {
      const fallback = {
        title: 'Deal removal failed. Try again and if it persists, contact support.',
      };
      showErrorToast(fallback, error);
    },
  });

  const updateStatusMutation = useMutation<
    UPDATE_DEAL_STATUS_MUTATION,
    UPDATE_DEAL_STATUS_MUTATIONVariables
  >(UPDATE_DEAL_STATUS, {
    onCompleted() {
      showSuccessToast({
        title: 'Successfully update deal status',
      });
    },
    onError(error) {
      const fallback = { title: 'Failed to update deal status' };
      showErrorToast(fallback, error);
    },
  });

  useEffect(() => {
    if (user && user.activeDeal && selectedDeal !== user.activeDeal.id) {
      setSelectedDeal(user.activeDeal.id);
    }
  }, [user, selectedDeal]);

  useEffect(() => {
    if (filteredDealsQuery.refetch) {
      filteredDealsQuery.refetch();
    }
  }, [searchValue, page, pageSize, filter, sort]);

  const paginationInfo = (query: QueryResult<GET_FILTERED_DEALS_QUERY>): IPaginationInfo => ({
    current: page,
    size: pageSize,
    totalPages: _get(query, 'data.dealsPaginated.totalPages'),
    totalRecord: _get(query, 'data.dealsPaginated.totalRecords'),
  });

  return (
    <DealsContextProvider
      value={{
        dealsQuery,
        filteredDealsQuery,
        filteredDealsNamesQuery,
        selectedDeal,
        loginToDealMutation,
        createDealMutation,
        unlinkFromDealMutation,
        updateStatusMutation,
        setActiveDeal,
        setSearchValue,
        setPageQuery,
        setFilter,
        setSort,
        setSortField,
        filterQuery,
        dealsPaginationTracker: {
          paginationInfo: paginationInfo(filteredDealsQuery),
        },
        searchboxValue,
        setSearchboxValue,
        handleViewInMarketplace,
      }}
    >
      {children}
    </DealsContextProvider>
  );
};
