import { useLazyQuery } from '@apollo/client';
import React, { useState, createContext, ReactNode, useContext } from 'react';
import { useForm } from 'app/utils/hooks/useForm';
import { IFundContext, IFundFilterValues } from './TypedFilterContext';

// Context
import { AlertContext } from 'app/notifications/context/Alert/Alert';

// api
import {
  GET_MARKETPLACE_FUNDS,
  GET_MARKETPLACE_FUNDS_FILTERS_COUNT,
} from 'api/graphql/marketplace/queries';

// Constants
import { DefaultMMSort } from '../constants';

const FundContext = createContext({} as IFundContext);

const LIMIT_ITEMS = 10;

const initialValues: IFundFilterValues = {
  industries: [],
  pagination: {
    limit: LIMIT_ITEMS,
  },
  dealsCount: 0,
  myFunds: false,
  sort: DefaultMMSort,
};

function FundContextProvider({ children }: { children: ReactNode }) {
  const [funds, setFunds] = useState([]);
  const [filtersCount, setFiltersCount] = useState<{
    filterCounts: any[];
    totalCount: number;
  }>({ filterCounts: [], totalCount: 0 });
  const { showErrorToast } = useContext(AlertContext);
  const { setFieldValue, values: filterValues, resetForm } = useForm<IFundFilterValues>({
    initialValues,
  });

  const [getFunds] = useLazyQuery(GET_MARKETPLACE_FUNDS, {
    fetchPolicy: 'no-cache',
    onCompleted(response: any) {
      setFunds(response.marketplaceFunds);
    },
    onError(error: any) {
      const fallback = { title: 'Error in Filters Service', description: error };
      showErrorToast(fallback, error);
    },
  });
  const [getFundsFilterCount] = useLazyQuery(GET_MARKETPLACE_FUNDS_FILTERS_COUNT, {
    fetchPolicy: 'no-cache',
    onCompleted(response: any) {
      setFiltersCount(response.marketplaceFundsFiltersCountTotal);
    },
    onError(error: any) {
      const fallback = { title: 'Error in Filters Service', description: error };
      showErrorToast(fallback, error);
    },
  });

  return (
    <FundContext.Provider
      value={{
        filterState: {
          filterValues: filterValues,
          resetForm: resetForm,
          setFieldValue: setFieldValue,
        },
        funds: {
          data: funds,
          refetch: getFunds,
        },
        filtersCount: {
          data: filtersCount,
          refetch: getFundsFilterCount,
        },
      }}
    >
      {children}
    </FundContext.Provider>
  );
}

export { FundContext, FundContextProvider };
