import React from 'react';
import { IconProps } from './types';

export const FinalisLogov3 = ({
  width = '66px',
  height = '14px',
  style = {},
  color,
}: IconProps): JSX.Element => {
  return (
    <svg width={width} height={height} style={style} viewBox="0 0 66 14" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.0366 1.88916V12.1107H20.1493L16.475 9.17947L17.9872 7.97097L21.194 10.5197L21.1854 3.47883L10.37 12.1107H7.34193L20.1493 1.88916H23.0366ZM4.91004 1.88916L8.58429 4.82025L7.07206 6.02874L3.86531 3.48003L3.87381 10.521L14.6893 1.88916H17.7174L4.91004 12.1107H2.02261V1.88916H4.91004Z"
        fill={color ? color : '#185ADC'}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.9561 4.93689C45.9724 4.93689 46.582 5.45852 46.9344 5.9939H46.9886V5.12027H48.75V11.8709H46.9886V11.0254H46.9344C46.5144 11.603 45.8775 12.0964 44.8206 12.0964C43.5605 12.0964 41.8665 11.0393 41.8665 8.48844C41.8665 6.31817 43.3029 4.93689 44.9561 4.93689ZM30.1554 1.89276L30.2206 1.89298V3.71092H29.4853C29.0908 3.71092 28.8115 4.05299 28.8115 4.41079V5.12027H30.2206V6.72687H28.8115V11.8709H26.9685V6.72687H26.0471V5.12027H26.9685V3.71092C26.9685 2.74204 27.7067 1.88916 28.71 1.88916L28.9332 1.88938C29.342 1.89006 29.9559 1.89208 30.1554 1.89276ZM33.1215 5.12027V11.8709H31.2922V5.12027H33.1215ZM38.402 4.90893C39.8653 4.90893 40.8816 5.92357 40.8816 7.99511V11.8709H39.0523V8.12213C39.0523 7.10749 38.5918 6.58587 37.8329 6.58587C37.0333 6.58587 36.4506 7.17783 36.4506 8.30539V11.8709H34.6214V5.12027H36.4237V5.96583H36.4778C36.925 5.21889 37.6567 4.90893 38.402 4.90893ZM52.093 1.89298V11.8709H50.2636V1.89298H52.093ZM45.4033 6.5578C44.265 6.5578 43.723 7.53016 43.723 8.50275C43.723 9.4891 44.2514 10.4474 45.3897 10.4474C46.257 10.4474 47.0156 9.65839 47.0156 8.51673C47.0156 7.33281 46.3112 6.5578 45.4033 6.5578ZM33.1215 1.89298V3.71092H31.2922V1.89298H33.1215ZM59.0349 4.89484C60.4712 4.89484 61.2705 5.7545 61.4061 6.9382H59.7938C59.753 6.45917 59.455 6.17717 59.0483 6.17717C58.6147 6.17717 58.3843 6.47315 58.3843 6.76913C58.3843 7.14976 58.5877 7.38916 59.6852 7.78377C61.0539 8.24882 61.5551 8.96769 61.5551 9.94006C61.5551 11.1803 60.6472 12.1107 59.0483 12.1107C57.3954 12.1107 56.6095 11.2086 56.5415 9.89779H58.1812C58.2218 10.4334 58.4929 10.814 59.0891 10.814C59.5498 10.814 59.8342 10.4898 59.8342 10.095C59.8342 9.6301 59.5226 9.3481 58.5471 9.01007C57.3274 8.60138 56.6907 7.96715 56.6907 6.91013C56.6907 5.69814 57.6392 4.89484 59.0349 4.89484ZM55.4151 5.12027V11.8709H53.5859V5.12027H55.4151ZM55.4151 1.89298V3.71092H53.5859V1.89298H55.4151Z"
        fill={color ? color : '#1D1B2C'}
      />
      <path
        d="M62.7553 1.88916C62.0921 1.88916 61.5683 2.42385 61.5683 3.07959C61.5683 3.73534 62.0921 4.26667 62.7553 4.26667C63.4186 4.26667 63.9458 3.73534 63.9458 3.07959C63.9458 2.42385 63.4186 1.88916 62.7553 1.88916ZM62.7553 4.06153C62.2077 4.06153 61.7724 3.62437 61.7724 3.07959C61.7724 2.53482 62.2077 2.09093 62.7553 2.09093C63.3064 2.09093 63.7417 2.53482 63.7417 3.07959C63.7417 3.62437 63.3064 4.06153 62.7553 4.06153ZM63.2009 2.88119C63.2009 2.68615 63.0785 2.51128 62.8166 2.51128H62.3608V3.67817H62.5785V3.26791H62.7009L62.9968 3.67817H63.2417L62.939 3.2511C63.1227 3.20738 63.2009 3.08968 63.2009 2.88119ZM62.5785 2.71305H62.8064C62.9356 2.71305 62.99 2.81057 62.99 2.89464C62.99 2.97871 62.9356 3.07623 62.8064 3.07623H62.5785V2.71305Z"
        fill={color ? color : '#6A678E'}
      />
    </svg>
  );
};
