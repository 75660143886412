import * as React from 'react';
import { IconProps } from './types';

export const ReturnArrowIcon = ({
  color = 'currentColor',
  style = {},
  width = '16px',
  height = '17px',
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path d="M12.6672 4.66968H11.3339V10.003H12.6672V4.66968Z" fill={color} />
    <path d="M11.3338 8.66968H6.66711V10.003H11.3338V8.66968Z" fill={color} />
    <path d="M6.6672 6.66968V12.0117L3.33386 9.35701L6.6672 6.66968Z" fill={color} />
  </svg>
);
