import React, { useContext } from 'react';

import { FormsU4Context, FormsU4ContextType } from '../compliance/context/FormU4Context';
import { UserContext } from '../users/context/UserContext';
import { IContext } from '../users/context/TypedUserContext';

interface Contexts {
  userContext: IContext;
  formsU4Context: FormsU4ContextType;
}

interface MainConsumerProps {
  children: (ctx: Contexts) => React.ReactElement;
}

export const MainConsumer = ({ children }: MainConsumerProps) => {
  const userContext = useContext(UserContext);
  const formsU4Context = useContext(FormsU4Context);
  return children({ userContext, formsU4Context });
};
