import styled from 'styled-components';
import { Input as PokiInput, Button } from '@dealsyte/poki';

export const Input = styled(PokiInput)`
  flex: 1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;

  &:focus {
    z-index: 1;
  }
`;

export const MainContainer = styled.div`
  display: flex;
`;

export const ButtonContainer = styled.div`
  margin-left: -1px;
`;

export const EyeButton = styled(Button)`
  color: ${({ theme }) => theme.palette.gray.darker};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 100%;
  position: relative;

  &:focus {
    z-index: 1;
  }
`;
