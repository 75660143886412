import styled from 'styled-components';

export const Text = styled.p`
  ${({ theme }) => {
    const { typography, colors } = theme.foundations;
    return `
      display: flex;
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['04']};
      color: ${colors.palette.neutral['600']};
      line-height: 150%;
      letter-spacing: 0.25px;
    `;
  }}
`;

export const DocumentInfoContainer = styled.div`
  ${({ theme }) => {
    const { colors, radii, spacing } = theme.foundations;
    return `
      display: flex;
      align-content: center;
      justify-content: center;
      background: ${colors.palette.neutral['200']};
      border-radius: ${radii.lg};
      padding: ${spacing['04']};
      margin-top: ${spacing['03']};

      .icon-container{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 19px;
      }
    `;
  }}
`;

export const UploadButtonContainer = styled.div`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return `
      display: flex;
      margin-top: ${spacing['05']};
      margin-bottom: ${spacing['06']};
    `;
  }}
`;

export const FooterContainer = styled.div`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return `
      display: flex;
      justify-content: flex-end;
      button {
        justify-content: center;
        width: 176px;
        height: ${spacing['08']};
      }
  `;
  }}
`;
