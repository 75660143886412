import { Omit } from 'react-redux';
import { IUser, Role, DealSide, LinkedEntity, IGroup } from './types';
import { UiState } from 'utils/redux/uiReducer/uiReducer';

export enum TabName {
  ALL = 'all',
  ASSIGNED_TO_ME = 'me',
  PRIVATE_DRAFTS = 'private_drafts',
  INTERNAL_DRAFTS = 'internal_drafts',
  PENDING_APPROVAL = 'pending_approval',
  SUBMITTED = 'submitted',
  ANSWERED = 'answered',
  FLAGGED = 'flagged',
  CLOSED = 'closed',
  REJECTED = 'rejected',
}

export enum Sort {
  A_GOES_FIRST = 1,
  B_GOES_FIRST = -1,
  EQUAL = 0,
}

export enum Priority {
  LOW = 0,
  MEDIUM = 1,
  HIGH = 2,
}

export enum Status {
  PRIVATE = 'private',
  DRAFT = 'draft',
  REVIEW = 'in_review',
  SUBMITTED = 'submitted',
}

export enum QuestionStatus {
  PRIVATE = 'private',
  DRAFT = 'draft',
  REVIEW = 'in_review',
  SUBMITTED = 'submitted',
  CLOSED = 'closed',
  REJECTED = 'rejected',
}

export enum EntityType {
  QUESTION = 'question',
  ANSWER = 'answer',
  FOLLOWUP = 'followup',
  COMMENT = 'comment',
}

export enum EntitiesType {
  QUESTIONS = 'questions',
  ANSWERS = 'answers',
  FOLLOWUPS = 'followups',
  COMMENTS = 'comments',
}

export interface IQuestion {
  id: string;
  displayId: string;
  groupId: string;
  creator: IUser;
  lastEditor?: IUser;
  lastEditedAt?: string;
  publisher?: IUser;
  publishedAt?: string;
  submitter?: IUser;
  submittedAt?: string;
  closer?: IUser;
  closedAt?: string;
  text: string;
  status: QuestionStatus;
  priority: Priority;
  hasAttachments: boolean;
  deleter?: IUser;
  deletedAt: string | null;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string | null;
  category: string | null;
  subcategory: string | null;
  links: LinkedEntity[];
}

export interface IAnswer {
  id: string;
  creator?: IUser;
  lastEditor?: IUser;
  lastEditedAt?: string;
  publisher?: IUser;
  publishedAt?: string;
  submitter?: IUser;
  submittedAt?: string;
  text: string;
  status: Status;
  hasAttachments: boolean;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
  questionId: string;
  links: LinkedEntity[];
}

export interface IFollowup {
  id: string;
  status: Status.PRIVATE | Status.SUBMITTED;
  hasAttachments: string;
  creator: IUser;
  text: string;
  updatedAt?: string;
  createdAt?: string;
  lastEditedAt?: string;
  deletedAt?: string;
  questionId: string;
  threadId: string;
}

export type RelatedModel = 'question' | 'answer';

export interface IComment {
  id: string;
  creator: IUser;
  text: string;
  relatedModel: RelatedModel;
  createdAt: string;
  updatedAt: string;
  relatedModelId: string;
}

export interface ICategory {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string | null;
  parentId: string | null;
}

export interface IOrganization {
  id: string;
  name: string;
}

export interface IFlag {
  id: string;
  creatorId: string;
  urgent: boolean;
  text: string;
  updatedAt: string;
  createdAt: string;
  questionId: string;
  threadId: string;
}

export interface IDelegation {
  id: string;
  creatorId: string;
  lastEditorId: string;
  delegatedUserId: string;
  relatedModel: RelatedModel;
  createdAt: string;
  updatedAt: string;
  relatedModelId: string;
}

export type RelatedModelWithFollowup = RelatedModel | 'followup';

export interface IReadReceipt {
  id: string;
  userId: string;
  relatedModel: RelatedModelWithFollowup;
  createdAt: string;
  relatedModelId: string;
}

export interface IExtendedGroup extends Omit<IGroup, 'users'> {
  users: IUser[];
}

export interface IEntity<Type> {
  allIds: string[];
  byId: { [key: string]: Type };
}

export interface IQuery {
  url: string;
  isFinished: boolean;
  isPending: boolean;
  isMutation: boolean;
  queryCount: number;
  lastUpdated: number;
  status: number;
}

export interface IExtendedDelegation extends IDelegation {
  delegatedUser: IUser | undefined;
}

export type IReduxNotification = {
  shown: boolean;
  read: boolean;
  timestamp: number;
  originService?: string;
};

export interface IReadReceipt {
  id: string;
  userId: string;
  relatedModel: 'question' | 'answer' | 'followup';
  createdAt: string;
  relatedModelId: string;
}

export interface ReadReceipts {
  own: {
    questions: IEntity<IReadReceipt[]>;
    answers: IEntity<IReadReceipt[]>;
    followups: IEntity<IReadReceipt[]>;
  };
  others: {
    questions: IEntity<IReadReceipt[]>;
    answers: IEntity<IReadReceipt[]>;
    followups: IEntity<IReadReceipt[]>;
  };
}

export interface IState {
  entities: {
    questions?: IEntity<IQuestion>;
    answers?: IEntity<IAnswer>;
    followups?: IEntity<IFollowup>;
    comments?: IEntity<IComment>;
    users?: IEntity<IUser>;
    organizations?: IEntity<IOrganization>;
    delegations: IEntity<IDelegation>;
    readReceipts: ReadReceipts;
    groups: IEntity<IGroup>;
    profile: {
      firstName: string;
      lastName: string;
      email: string;
      preferences: {
        emailSettings: any;
        notificationSettings: any;
      };
    };
  };
  auth: {
    authenticated: boolean;
    token: string;
    role: Role;
    side: DealSide;
    userId: string;
  };
  queries: {
    [key: string]: IQuery;
  };
  socket: {
    notifications: { [key: string]: IReduxNotification };
  };
  // TODO: set the actual type of these reducers
  logs: any;
  alerts: any;
  pages: any;
  ui: UiState;
}

export enum ThreadStatus {
  PRIVATE_DRAFT_QUESTION = 'private_draft_question',
  PUBLIC_DRAFT_QUESTION = 'public_draft_question',
  UNANSWERED = 'unanswered',
  PRIVATE_DRAFT_ANSWER = 'private_draft_answer',
  PUBLIC_DRAFT_ANSWER = 'public_draft_answer',
  REVIEW = 'in_review',
  REJECTED = 'rejected',
  ANSWERED = 'answered',
  CLOSED = 'closed',
}

export const ThreadStatusLabels: { [key in ThreadStatus]: string } = {
  [ThreadStatus.PRIVATE_DRAFT_QUESTION]: 'My Draft',
  [ThreadStatus.PUBLIC_DRAFT_QUESTION]: 'Team Draft',
  [ThreadStatus.UNANSWERED]: 'Unanswered',
  [ThreadStatus.PRIVATE_DRAFT_ANSWER]: 'My Draft',
  [ThreadStatus.PUBLIC_DRAFT_ANSWER]: 'Team Draft',
  [ThreadStatus.REVIEW]: 'In Review',
  [ThreadStatus.REJECTED]: 'Rejected',
  [ThreadStatus.ANSWERED]: 'Answered',
  [ThreadStatus.CLOSED]: 'Closed',
};

export enum ThreadStatusChangeAction {
  SHARE_WITH_SIDE = 'share_with_side',
  REQUEST_APPROVAL = 'request_review',
  REQUEST_CHANGES = 'request_changes',
  REJECT = 'reject',
  SUBMIT = 'submit',
  CLOSE = 'close',
}

export interface IThread extends Pick<IQuestion, 'id' | 'displayId' | 'groupId' | 'priority'> {
  question: IQuestion;
  status: ThreadStatus;
  answer: IAnswer | null;
  followups: IFollowup[];
  comments: IComment[];
  creator: IUser;
  category: ICategory | null;
  subcategory: ICategory | null;
  isRead: boolean;
  flags: IFlag[];
  owner: IUser | null;
  usersWithAccess?: IUser[];
}
