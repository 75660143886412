import { types } from '../actions/pages';

const initialState = {
  threaded: {
    page: 1,
    maxPage: null,
  },
  cronology: {
    page: 1,
    maxPage: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.NEXT_PAGE: {
      const { entityName } = action.payload;
      const entityState = state[entityName];
      return {
        ...state,
        [entityName]: {
          ...entityState,
          page: entityState.maxPage > entityState.page ? entityState.page + 1 : entityState.page,
        },
      };
    }
    case types.SET_PAGE: {
      const { entityName, pageNumber } = action.payload;
      const entityState = state[entityName];
      return {
        ...state,
        [entityName]: {
          ...entityState,
          page: entityState.maxPage > entityState.page ? pageNumber : entityState.page,
        },
      };
    }
    case types.SET_MAX_PAGE: {
      const { entityName, maxPage } = action.payload;
      const entityState = state[entityName];
      return {
        ...state,
        [entityName]: {
          ...entityState,
          maxPage,
        },
      };
    }
    default:
      return state;
  }
};
