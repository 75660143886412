export const supportedFormatsDisclaimer =
  'CSV, DOC, DOCX, JPG, JPEG, PDF, PNG, PPT, PPTX, XLS, XLSX and plain text are supported.';

export const SUPPORTED_FILE_FORMATS =
  '.jpeg, .png, image/jpeg, image/png, image/x-png, text/csv,text/plain, text/*, .doc, .docx, .xls, .xlsx, application/excel, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/x-excel, application/x-msexcel, application/doc, application/ms-doc, application/msword, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .ppt, .pptx, application/ppt, application/pptx';

export const invalidFormatError =
  'Format not allowed: Please check allowed file types and try again.';

export const maxConcurrentFilesError = maxAllowedFiles =>
  `You have exceeded the upload limit of ${maxAllowedFiles} files. Retry with fewer files.`;

export const maxAllowedFilesError = maxFiles =>
  `You have reached the maximum of ${maxFiles} files. Adjust your selection and retry.`;

export const invalidNameFile =
  'Invalid File Name: Ensure the file name contains only letters, numbers, spaces, dashes, underscores, parentheses, and retry.';
