import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import get from 'lodash/get';
import { useQuery, useMutation } from '@apollo/client';
import Select from 'react-select';
import { Formik } from 'formik';
import { Modal, Button, EButtonType, FormGroup, Input } from '@dealsyte/poki';
import EventService from 'utils/socket/EventService';
import {
  GET_GROUPS_QUERY,
  GET_GROUPS_QUERYVariables,
} from 'api/graphql/users/types/GET_GROUPS_QUERY';
import { GET_GROUPS, GET_PERMISSION_ENTITIES } from 'api/graphql/users/queries';
import {
  GET_PERMISSION_ENTITIES_QUERY,
  GET_PERMISSION_ENTITIES_QUERYVariables,
} from 'api/graphql/users/types/GET_PERMISSION_ENTITIES_QUERY';
import { CREATE_FILEROOM } from 'api/graphql/core-tools/vdr/mutations';
import {
  CREATE_FILEROOM_MUTATION,
  CREATE_FILEROOM_MUTATIONVariables,
} from 'api/graphql/core-tools/vdr/types/CREATE_FILEROOM_MUTATION';
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { GET_FILEROOMS } from 'api/graphql/core-tools/vdr/queries';
import { UserContext } from 'app/users/context/UserContext';
import { useEditPermissionMutation } from 'api/graphql/users/hooks';
import { ETransactionType } from 'app/core-tools/due-diligence/vdr/utils/types';
import { IPermissionEntity, DealSide as EDealSide } from 'app/core-tools/due-diligence/types/types';
import { getError } from 'app/utils/helpers/helpers';
import {
  ContentContainer,
  ButtonsContainer,
  CheckboxLabelContainer,
  Checkbox,
  Header,
  SubHeader,
  Paragraph,
  Label,
  selectStyles,
} from './StyledCreateFileroomModal';
import { capitalizeFirstLetter } from '../../../../../../utils/helpers/helpers';

/**
 * Renders a modal to create a new fileroom.
 * @param show Flag that indicates if the modal should or not be rendered.
 * @param setShow Dispatcher function for -show- prop.
 */
function CreateFileroomModal({
  show,
  setShow,
}: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}) {
  const { activeDeal } = useContext(UserContext);

  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(true);

  const { showSuccessToast, showErrorToast } = useContext(AlertContext);

  const { data: permissionEntitiesData } = useQuery<
    GET_PERMISSION_ENTITIES_QUERY,
    GET_PERMISSION_ENTITIES_QUERYVariables
  >(GET_PERMISSION_ENTITIES, {
    variables: { dealId: get(activeDeal, 'id', '') },
  });

  const {
    data: groupsData,
    // TODO: Handle errorOnGroupsData when UX definitions are ready
  } = useQuery<GET_GROUPS_QUERY, GET_GROUPS_QUERYVariables>(GET_GROUPS, {
    variables: { dealId: get(activeDeal, 'id', '') },
  });

  const { editPermissionsMutation } = useEditPermissionMutation();

  const [createFileroomMutation, { loading: loadingCreateFileroomMutation }] = useMutation<
    CREATE_FILEROOM_MUTATION,
    CREATE_FILEROOM_MUTATIONVariables
  >(CREATE_FILEROOM, {
    onCompleted() {
      showSuccessToast({ title: 'Successfully created your Data Room.' });

      setShow(false);
    },
    onError(error) {
      showErrorToast({ title: getError(error) });
    },
    update(cache, { data: { createFileRoom } }: any) {
      const fileRoomsCacheData = cache.readQuery({
        query: GET_FILEROOMS,
        variables: { dealId: get(activeDeal, 'id', '') },
      });

      if (get(fileRoomsCacheData, 'fileRooms')) {
        cache.writeQuery({
          query: GET_FILEROOMS,
          variables: { dealId: get(activeDeal, 'id', '') },
          data: {
            fileRooms: get(fileRoomsCacheData, 'fileRooms').concat([createFileRoom]),
          },
        });
      }
    },
  });

  const downloadPermissionEntityIds = permissionEntitiesData
    ? permissionEntitiesData.permissionEntities &&
      permissionEntitiesData.permissionEntities.reduce((acc, permissionEntity) => {
        if (
          permissionEntity &&
          permissionEntity.name !== ETransactionType.watermarkDownload &&
          permissionEntity.name !== ETransactionType.originalDownload
        )
          return acc;

        return {
          ...acc,
          [((permissionEntity as unknown) as IPermissionEntity).name]:
            permissionEntity && permissionEntity.id,
        };
      }, {})
    : ({} as { [key: string]: string });

  const buySideGroups = groupsData
    ? groupsData.groups && groupsData.groups.filter(group => group && group.type === EDealSide.BUY)
    : [];

  const buySideGroupsOptions = buySideGroups
    ? buySideGroups.map(group => ({
        value: (group && group.id) || '',
        label: (group && group.name) || '',
      }))
    : [];

  const formInitialValues = {
    name: '',
    downloadPermissions: false,
    watermarkPermissions: false,
    sideGroups: undefined,
  };

  const emptyStringRegex = /^(?!\s*$).+/;

  const checkEmptyString = (name: string): boolean => {
    return !emptyStringRegex.test(name);
  };

  return (
    <Formik
      initialValues={formInitialValues}
      onSubmit={async (values, { resetForm }) => {
        if (checkEmptyString(values.name)) {
          showErrorToast({ title: 'Please type a data room name' });
        } else {
          const createdFileroom = await createFileroomMutation({
            variables: { name: capitalizeFirstLetter(values.name.trim()) },
          });

          const createdFileroomId = get(createdFileroom, 'data.createFileRoom.id');

          if (
            createdFileroomId &&
            values.sideGroups &&
            ((values.sideGroups as unknown) as { label: string; value: string }[]).length > 0
          ) {
            ((values.sideGroups as unknown) as { label: string; value: string }[]).forEach(
              selectedSideGroup => {
                if (values.downloadPermissions)
                  editPermissionsMutation({
                    ownerId: selectedSideGroup.value,
                    fsoIds: [createdFileroomId],
                    permissionEntityId:
                      downloadPermissionEntityIds &&
                      (downloadPermissionEntityIds as any)[ETransactionType.originalDownload],
                    remove: false,
                  });

                if (values.watermarkPermissions)
                  editPermissionsMutation({
                    ownerId: selectedSideGroup.value,
                    fsoIds: [createdFileroomId],
                    permissionEntityId:
                      downloadPermissionEntityIds &&
                      (downloadPermissionEntityIds as any)[ETransactionType.watermarkDownload],
                    remove: false,
                  });
              }
            );
          }

          resetForm(formInitialValues);
          setDisableSubmitButton(true);
        }
      }}
    >
      {form => {
        return (
          <Modal
            show={show}
            style={{ width: '35vw', border: 'none' }}
            onHide={() => {
              setShow(false);
              EventService.triggerEvent('MODAL_HIDE', ['create_vdr_settings']);
            }}
          >
            <Header>Create a new data room</Header>

            <ContentContainer>
              <FormGroup
                label="Data room name"
                id="name"
                required
                input={
                  <Input
                    id="name"
                    name="name"
                    onChange={e => {
                      setDisableSubmitButton(checkEmptyString(e.target.value));
                      form.handleChange(e);
                    }}
                    value={form.values.name}
                    onBlur={form.handleBlur}
                  />
                }
              />
            </ContentContainer>

            <ContentContainer>
              <SubHeader>Permissions</SubHeader>

              <CheckboxLabelContainer>
                <div style={{ marginBottom: 8 }}>
                  <Checkbox
                    id="create-fileroom-watermark-permissions-checkbox"
                    checked={form.values.watermarkPermissions}
                    onChange={() => {
                      form.setFieldValue('watermarkPermissions', !form.values.watermarkPermissions);
                    }}
                  />

                  <Label htmlFor="create-fileroom-watermark-permissions-checkbox">Watermark</Label>
                </div>

                <Paragraph>
                  Allow external teams (e.g., buyers, investors) to download all files in this Data
                  Room as a watermarked PDF.
                </Paragraph>
              </CheckboxLabelContainer>

              <CheckboxLabelContainer>
                <div style={{ marginBottom: 8 }}>
                  <Checkbox
                    id="create-fileroom-download-permissions-checkbox"
                    checked={form.values.downloadPermissions}
                    onChange={() => {
                      form.setFieldValue('downloadPermissions', !form.values.downloadPermissions);
                    }}
                  />

                  <Label htmlFor="create-fileroom-download-permissions-checkbox">Download</Label>
                </div>

                <Paragraph>
                  Allow external teams (e.g., buyers, investors) to download all files in this data
                  room in their original format.
                </Paragraph>
              </CheckboxLabelContainer>
            </ContentContainer>

            <ContentContainer>
              <SubHeader>Access </SubHeader>

              <Paragraph>
                These teams will only be able to see the existence of this Data Room. Any files or
                folders uploaded to this Data Room will need to be published for these groups to
                access them.
              </Paragraph>

              <Select
                options={buySideGroupsOptions}
                label="Select Team"
                styles={selectStyles}
                onChange={(selectedSideGroup: any) => {
                  form.setFieldValue('sideGroups', selectedSideGroup);
                }}
                isMulti
                value={form.values.sideGroups}
                name="colors"
                className="basic-multi-select"
                classNamePrefix="select"
              />
              <Paragraph style={{ margin: '45px 0 10px 0' }}>
                To change these settings later, go to Settings.
              </Paragraph>
            </ContentContainer>
            <ButtonsContainer>
              <Button
                disabled={loadingCreateFileroomMutation}
                buttonType={EButtonType.secondary}
                onClick={() => setShow(false)}
                style={{ borderBottomLeftRadius: 3 }}
              >
                Cancel
              </Button>

              <Button
                disabled={loadingCreateFileroomMutation || disableSubmitButton}
                buttonType={EButtonType.primary}
                onClick={() => form.handleSubmit()}
                style={{ borderBottomRightRadius: 3 }}
              >
                Create
              </Button>
            </ButtonsContainer>
          </Modal>
        );
      }}
    </Formik>
  );
}

export default CreateFileroomModal;
