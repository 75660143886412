class Currency {
  currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  formatToCurrency(value: number): string {
    return this.currencyFormatter.format(value);
  }
}

const currency = new Currency();

export default currency;
