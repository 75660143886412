import * as React from 'react';
import { IconProps } from './types';

export const DownloadIcon = ({
  width = '14px',
  height = '14px',
  color = 'currentColor',
  style = {},
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M13.0007 8.3335C12.8238 8.3335 12.6543 8.40373 12.5292 8.52876C12.4042 8.65378 12.334 8.82335 12.334 9.00016V11.6668C12.334 11.8436 12.2637 12.0132 12.1387 12.1382C12.0137 12.2633 11.8441 12.3335 11.6673 12.3335H2.33398C2.15717 12.3335 1.9876 12.2633 1.86258 12.1382C1.73756 12.0132 1.66732 11.8436 1.66732 11.6668V9.00016C1.66732 8.82335 1.59708 8.65378 1.47206 8.52876C1.34703 8.40373 1.17746 8.3335 1.00065 8.3335C0.82384 8.3335 0.654271 8.40373 0.529246 8.52876C0.404222 8.65378 0.333984 8.82335 0.333984 9.00016V11.6668C0.333984 12.1973 0.544698 12.706 0.919771 13.081C1.29484 13.4561 1.80355 13.6668 2.33398 13.6668H11.6673C12.1978 13.6668 12.7065 13.4561 13.0815 13.081C13.4566 12.706 13.6673 12.1973 13.6673 11.6668V9.00016C13.6673 8.82335 13.5971 8.65378 13.4721 8.52876C13.347 8.40373 13.1775 8.3335 13.0007 8.3335ZM6.52732 9.4735C6.59072 9.53419 6.66548 9.58177 6.74732 9.6135C6.82712 9.64877 6.9134 9.66698 7.00065 9.66698C7.0879 9.66698 7.17418 9.64877 7.25398 9.6135C7.33582 9.58177 7.41058 9.53419 7.47398 9.4735L10.1407 6.80683C10.2662 6.68129 10.3367 6.51103 10.3367 6.3335C10.3367 6.15596 10.2662 5.9857 10.1407 5.86016C10.0151 5.73463 9.84485 5.6641 9.66732 5.6641C9.48978 5.6641 9.31952 5.73463 9.19398 5.86016L7.66732 7.3935V1.00016C7.66732 0.823352 7.59708 0.653782 7.47206 0.528758C7.34703 0.403734 7.17746 0.333496 7.00065 0.333496C6.82384 0.333496 6.65427 0.403734 6.52925 0.528758C6.40422 0.653782 6.33398 0.823352 6.33398 1.00016V7.3935L4.80732 5.86016C4.74516 5.798 4.67137 5.7487 4.59015 5.71506C4.50894 5.68142 4.42189 5.6641 4.33398 5.6641C4.24608 5.6641 4.15903 5.68142 4.07782 5.71506C3.9966 5.7487 3.92281 5.798 3.86065 5.86016C3.79849 5.92232 3.74919 5.99612 3.71554 6.07733C3.6819 6.15854 3.66459 6.24559 3.66459 6.3335C3.66459 6.4214 3.6819 6.50845 3.71554 6.58966C3.74919 6.67088 3.79849 6.74467 3.86065 6.80683L6.52732 9.4735Z"
      fill={color}
    />
  </svg>
);
