import * as React from 'react';
import { IconProps } from './types';

export const LockIcon = ({
  width = '10px',
  height = '12px',
  color = 'currentColor',
  style = {},
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.21808 3.02692V4.69921H1.66065C0.942091 4.69921 0.359985 5.28132 0.359985 5.99988V10.4593C0.359985 11.1779 0.942091 11.76 1.66065 11.76H8.34981C9.06838 11.76 9.65048 11.1779 9.65048 10.4593V5.99988C9.65048 5.65511 9.5133 5.32415 9.26942 5.08027C9.02554 4.8364 8.69456 4.69921 8.34981 4.69921H7.79238V3.02692C7.79238 2.0311 7.26108 1.11085 6.39881 0.612883C5.53654 0.115693 4.474 0.115693 3.61166 0.612883C2.74939 1.1108 2.21808 2.03113 2.21808 3.02692ZM8.53562 5.99988V10.4593C8.53562 10.5087 8.51602 10.5559 8.48118 10.5907C8.44635 10.6255 8.39917 10.6451 8.34981 10.6451H1.66065C1.55831 10.6451 1.47484 10.5617 1.47484 10.4593V5.99988C1.47484 5.89754 1.55831 5.81407 1.66065 5.81407H8.34981C8.39917 5.81407 8.44635 5.83367 8.48118 5.86851C8.51602 5.90335 8.53562 5.95053 8.53562 5.99988ZM6.67752 3.02692V4.69921H3.33294V3.02692C3.33294 2.10367 4.08198 1.35464 5.00523 1.35464C5.92849 1.35464 6.67752 2.10367 6.67752 3.02692Z"
      fill={color}
    />
  </svg>
);
