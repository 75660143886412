// vendors
import React from 'react';
import _get from 'lodash/get';
import dayjs from 'dayjs';

// Components
import { Button as CButton } from 'app/common/components/Button/Button';

// Styled Components
import MarketplaceDealDetails from './StyledMarketplaceConfig';

// Types
import { ERoutePatterns } from 'app/core-tools/due-diligence/types/types';
import { IMarketplaceDeal, MarketplaceDealType } from 'app/marketplace/types';

// Hooks
import useGoogleAnalytics from 'app/utils/hooks/useGoogleAnalytics';

// icons
import {
  ExclamationMarkIcon,
  ExternalLinkIcon,
  ChatInfoIcon,
  SiteMapIcon,
  CalendarIcon,
  OpenEyeIcon,
} from 'app/common/icons';
import { GA_CATEGORY, GA_ACTION } from 'app/utils/types/types';
import useGoogleAnalytics4 from 'app/utils/hooks/useGoogleAnalytics4';
import { Tooltip } from '../../../../../../../common/components';

export type IProps = {
  mDeal: IMarketplaceDeal | null;
};

const MarketplaceConfig = ({ mDeal }: IProps) => {
  const marketplaceEnabled = _get(mDeal, 'owner.marketplaceAccessEnabled', false);
  const { trackSingleEvent } = useGoogleAnalytics();
  const { trackSingleEvent: trackSingleEventG4 } = useGoogleAnalytics4();
  const handleCardOnClick = () => {
    trackSingleEvent(GA_CATEGORY.UI, GA_ACTION.OPEN_DEAL_DETAILS);
    trackSingleEventG4(GA_CATEGORY.UI, GA_ACTION.OPEN_DEAL_DETAILS);
    window.open(`${ERoutePatterns.MARKETPLACE}/${_get(mDeal, 'marketplaceDealId')}`);
  };

  const renderViewInMarketplaceButton = () => {
    if (mDeal && (mDeal.status === 'CLOSED' || mDeal.status === 'EXPIRED')) {
      return (
        <Tooltip message="Expired or closed deals are not listed on the marketplace.">
          <div>
            <CButton className="secondary" onClick={handleCardOnClick} disabled>
              View in Marketplace
            </CButton>
          </div>
        </Tooltip>
      );
    }
    return (
      <div>
        <CButton
          className="secondary"
          onClick={handleCardOnClick}
          disabled={_get(mDeal, 'marketplaceDealId') === null}
        >
          View in Marketplace
        </CButton>
      </div>
    );
  };

  const buildMarketplaceConfig = () => {
    if (marketplaceEnabled) {
      return (
        <MarketplaceDealDetails>
          <div className="top-container">
            <div>
              <p className="title">Deal ID</p>
              <p className="value">
                <div className="icon-container">
                  <SiteMapIcon />
                </div>
                {_get(mDeal, 'marketplaceDealId', null) ? _get(mDeal, 'marketplaceDealId') : '-'}
              </p>
            </div>
            <div>
              <p className="title">Published Date</p>
              <p className="value">
                <div className="icon-container">
                  <CalendarIcon />
                </div>
                <>{dayjs(_get(mDeal, 'publishedAt')).format('MMM D, YYYY')}</>
              </p>
            </div>
            <div className="right-container">
              <p className="title">Collaboration</p>
              <p className="value">
                <div className="icon-container">
                  <OpenEyeIcon color="#807DA1" />
                </div>
                {_get(mDeal, 'marketplaceDealType', '') === MarketplaceDealType.Collaborative
                  ? 'Collaborative'
                  : 'Non-collaborative'}
              </p>
            </div>
          </div>
          <div className="line" />
          <div className="bottom-container">
            <div className="left-container">
              <div className="icon-container">
                <ChatInfoIcon />
              </div>
              <p className="description">
                View this deal in the Marketplace to set collaboration options and specify your
                deal’s details.
              </p>
            </div>
            {renderViewInMarketplaceButton()}
          </div>
        </MarketplaceDealDetails>
      );
    }
    if (marketplaceEnabled === false) {
      return (
        <div className="join-marketplace">
          <ExclamationMarkIcon />
          <p>
            These settings are not available as you have not joined the Finalis Marketplace™ yet.
            <br />
            <div className="external-icon-container">
              <a href={ERoutePatterns.MARKETPLACE}>Go to Marketplace</a>
              <ExternalLinkIcon />
            </div>
          </p>
        </div>
      );
    }
    return null;
  };

  return buildMarketplaceConfig();
};

export default MarketplaceConfig;
