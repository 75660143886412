// vendors
import * as React from 'react';
import { CloseIcon } from 'app/common/icons';
import { HStack } from '../Stack/HStack';

// HOC
import { ModalPromptOptions, withClosePrompt } from './ModalClosePrompt';

// styled components
import {
  FinalisModalContainer,
  FinalisModalCloseButton,
  FinalisModalCloseIcon,
  ModalHeader as ModalHeaderComponent,
  ModalFooter as ModalFooterComponent,
  ModalCloseButton,
} from './StyledModal';

export interface FinalisModalProps extends ModalPromptOptions {
  dimensions?: {
    minHeight?: string | number;
    maxHeight?: string | number;
    minWidth?: string | number;
    maxWidth?: string | number;
  };
  onClose?: () => void;
  showCloseButton?: boolean;
  visible: boolean;
  style?: React.CSSProperties;
}

const ModalComponent: React.FunctionComponent<FinalisModalProps> = (props: FinalisModalProps) => {
  const { children, dimensions = {}, onClose, showCloseButton = true, visible, style } = props;

  const { minHeight, maxHeight = '75vh', minWidth, maxWidth = '45rem' } = dimensions;

  const renderCloseButton = (): React.ReactElement<{}> | null => {
    if (!showCloseButton || !onClose) {
      return null;
    }

    return (
      <FinalisModalCloseButton type="button" onClick={onClose}>
        <FinalisModalCloseIcon />
      </FinalisModalCloseButton>
    );
  };

  return (
    <FinalisModalContainer
      style={{ minHeight, maxHeight, minWidth, maxWidth, ...style }}
      show={visible}
      closeButton={false}
      onHide={onClose}
    >
      {renderCloseButton()}
      {children}
    </FinalisModalContainer>
  );
};

interface ModalHeaderProps {
  children?: React.ReactNode;
  onClose?: () => void;
}
export const ModalHeader = ({ children, onClose }: ModalHeaderProps) => {
  return (
    <ModalHeaderComponent>
      <HStack justifyContent="space-between" alignItems="center">
        {children}
        <ModalCloseButton onClick={onClose}>
          <CloseIcon />
        </ModalCloseButton>
      </HStack>
    </ModalHeaderComponent>
  );
};

export const ModalFooter = ModalFooterComponent;

export const Modal = withClosePrompt(ModalComponent);
export default withClosePrompt(ModalComponent);
