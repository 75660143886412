import gql from 'graphql-tag';

import { UploadTransactionBodyFragment, UploadFsoFragment, FsoFragment } from './fragments';

export const CREATE_FILEROOM = gql`
  mutation CREATE_FILEROOM_MUTATION($name: String!) {
    createFileRoom(name: $name) {
      ...FsoBasicFragment
    }
  }
  ${FsoFragment}
`;

export const RENAME_FILEROOM = gql`
  mutation RENAME_FILEROOM_MUTATION($fileRoomId: ID!, $name: String!) {
    renameFileRoom(fileRoomId: $fileRoomId, name: $name) {
      id
      name
    }
  }
`;

export const DELETE_FILEROOM = gql`
  mutation DELETE_FILEROOM_MUTATION($fileRoomId: ID!) {
    deleteFileRoom(fileRoomId: $fileRoomId) {
      id
      name
    }
  }
`;

export const CREATE_FOLDER = gql`
  mutation CREATE_FOLDER_MUTATION($name: String!, $parentId: ID, $navigateAs: ID) {
    createFolder(name: $name, parentId: $parentId) {
      ...FsoBasicFragment
      children(navigateAs: $navigateAs) {
        ...FsoBasicFragment
      }
    }
  }
  ${FsoFragment}
`;

export const IMPORT_FROM_GOOGLE_DRIVE = gql`
  mutation IMPORT_FROM_GOOGLE_DRIVE_MUTATION(
    $parentId: ID!
    $files: [ImportedFiles!]!
    $oauthToken: String!
  ) {
    importFromGoogleDrive(parentId: $parentId, files: $files, oauthToken: $oauthToken) {
      ok
    }
  }
`;

export const RENAME_FILE = gql`
  mutation RENAME_FILE_MUTATION($fsoId: ID!, $name: String!) {
    renameFso(fsoId: $fsoId, name: $name) {
      id
      name
      updatedAt
    }
  }
`;

export const MOVE_TO_TRASH = gql`
  mutation MOVE_TO_TRASH_MUTATION($fsoIds: [ID!]!) {
    moveToTrash(fsoIds: $fsoIds) {
      id
      trash
    }
  }
`;

export const RESTORE_FROM_TRASH = gql`
  mutation RESTORE_FROM_TRASH_MUTATION($fsoId: ID!) {
    restoreFromTrash(fsoId: $fsoId) {
      id
      trash
    }
  }
`;

export const MOVE_FSO = gql`
  mutation MOVE_FSO_MUTATION($targetId: ID!, $fsoIds: [ID!]!, $navigateAs: ID) {
    moveFso(targetId: $targetId, fsoIds: $fsoIds) {
      childrenAmount
      id
      index
      name
      path
      parentId
      children(navigateAs: $navigateAs) {
        childrenAmount
        id
        index
        name
        path
        parentId
      }
    }
  }
`;

export const UPDATE_FSOS_PUBLISH_STATUS = gql`
  mutation UPDATE_FSOS_PUBLISH_STATUS_MUTATION(
    $fileRoomId: ID!
    $fsoIds: [ID!]!
    $status: String!
  ) {
    updatePublishStatus(fileRoomId: $fileRoomId, fsoIds: $fsoIds, status: $status) {
      id
      status
      publishedAmount
      unpublishedAmount
    }
  }
`;

export const CREATE_TRANSACTION = gql`
  mutation CREATE_TRANSACTION_MUTATION(
    $fsos: [UploadFsosNodes!]
    $parentId: String!
    $options: UploadFsosOptions
  ) {
    createUploadFsosTransaction(fsos: $fsos, parentId: $parentId, options: $options) {
      ...UploadTransactionBodyFragment
      files {
        pending {
          ...UploadFsoFragment
        }
        complete {
          ...UploadFsoFragment
        }
      }
    }
  }
  ${UploadTransactionBodyFragment}
  ${UploadFsoFragment}
`;

export const CHANGE_TRANSACTION_STATUS = gql`
  mutation CHANGE_TRANSACTION_STATUS_MUTATION($id: ID!, $status: String!) {
    updateUploadFsosTransaction(id: $id, status: $status) {
      ...UploadTransactionBodyFragment
      files {
        pending {
          ...UploadFsoFragment
        }
        complete {
          ...UploadFsoFragment
        }
      }
    }
  }
  ${UploadTransactionBodyFragment}
  ${UploadFsoFragment}
`;

export const UPDATE_WATERMARK_CONFIGS = gql`
  mutation UPDATE_WATERMARK_CONFIGS_MUTATION($key: AllowedKeys!, $value: WatermarkConfigValue!) {
    updateConfig(key: $key, value: $value) {
      key
      value
      type
    }
  }
`;

export const LINK_ENTITIES = gql`
  mutation LINK_ENTITIES_MUTATION($link: LinkInput!, $to: LinkInput!) {
    createLink(link: $link, to: $to) {
      id
      targetId
      originId
      createdAt
    }
  }
`;

export const DELETE_ENTITY_LINK = gql`
  mutation DELETE_ENTITY_LINK_MUTATION($linkId: ID!) {
    deleteLink(linkId: $linkId)
  }
`;

export const REQUEST_FSO_SIGNATURE = gql`
  mutation REQUEST_FSO_SIGNATURE_MUTATION($fsoId: ID!, $groupsIds: [ID!]!) {
    requestSignature(fsoId: $fsoId, groupsIds: $groupsIds) {
      id
      Signatures {
        id
        status
        requesterGroupId
        signerGroupId
      }
    }
  }
`;

export const SIGN_FSO = gql`
  mutation SIGN_FSO_MUTATION($fsoId: ID!) {
    signFso(fsoId: $fsoId) {
      url
    }
  }
`;

export const PREVIEW_FSO_SIGNATURE = gql`
  mutation PREVIEW_FSO_SIGNATURE_MUTATION($fsoId: ID!, $signerGroupId: ID) {
    previewFsoSignature(fsoId: $fsoId, signerGroupId: $signerGroupId) {
      url
    }
  }
`;

export const CHECK_FSO_SIGNATURE = gql`
  mutation CHECK_FSO_SIGNATURE_MUTATION($fsoId: ID!) {
    checkFsoSignature(fsoId: $fsoId) {
      signed
    }
  }
`;

export const TOGGLE_STARRED_FSO_STATE = gql`
  mutation TOGGLE_STARRED_FSO_STATE_MUTATION($fsoId: ID!) {
    toggleStarredFsoState(fsoId: $fsoId) {
      starredDate
    }
  }
`;
