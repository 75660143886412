import * as React from 'react';
import { IconProps } from './types';

export const CreditCardIcon = ({
  width = '14px',
  height = '11px',
  color = 'currentColor',
  style = {},
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    width={width}
    height={height}
    viewBox="0 0 14 11"
    fill="none"
  >
    <path
      d="M3.66732 7.4987H5.66732C5.84413 7.4987 6.0137 7.42846 6.13872 7.30344C6.26375 7.17841 6.33398 7.00884 6.33398 6.83203C6.33398 6.65522 6.26375 6.48565 6.13872 6.36063C6.0137 6.2356 5.84413 6.16536 5.66732 6.16536H3.66732C3.49051 6.16536 3.32094 6.2356 3.19591 6.36063C3.07089 6.48565 3.00065 6.65522 3.00065 6.83203C3.00065 7.00884 3.07089 7.17841 3.19591 7.30344C3.32094 7.42846 3.49051 7.4987 3.66732 7.4987ZM11.6673 0.832031H2.33398C1.80355 0.832031 1.29484 1.04274 0.919771 1.41782C0.544698 1.79289 0.333984 2.3016 0.333984 2.83203V8.83203C0.333984 9.36246 0.544698 9.87117 0.919771 10.2462C1.29484 10.6213 1.80355 10.832 2.33398 10.832H11.6673C12.1978 10.832 12.7065 10.6213 13.0815 10.2462C13.4566 9.87117 13.6673 9.36246 13.6673 8.83203V2.83203C13.6673 2.3016 13.4566 1.79289 13.0815 1.41782C12.7065 1.04274 12.1978 0.832031 11.6673 0.832031ZM12.334 8.83203C12.334 9.00884 12.2637 9.17841 12.1387 9.30344C12.0137 9.42846 11.8441 9.4987 11.6673 9.4987H2.33398C2.15717 9.4987 1.9876 9.42846 1.86258 9.30344C1.73756 9.17841 1.66732 9.00884 1.66732 8.83203V4.83203H12.334V8.83203ZM12.334 3.4987H1.66732V2.83203C1.66732 2.65522 1.73756 2.48565 1.86258 2.36063C1.9876 2.2356 2.15717 2.16536 2.33398 2.16536H11.6673C11.8441 2.16536 12.0137 2.2356 12.1387 2.36063C12.2637 2.48565 12.334 2.65522 12.334 2.83203V3.4987Z"
      fill={color}
    />
  </svg>
);
