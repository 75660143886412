import * as React from 'react';
import { IconProps } from './types';

export const PercentageIcon = ({
  width = '24',
  height = '25',
  color = 'currentColor',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 14.0027C16.4067 14.0027 15.8266 14.1786 15.3333 14.5083C14.8399 14.8379 14.4554 15.3065 14.2284 15.8546C14.0013 16.4028 13.9419 17.006 14.0576 17.588C14.1734 18.1699 14.4591 18.7044 14.8787 19.124C15.2982 19.5436 15.8328 19.8293 16.4147 19.945C16.9967 20.0608 17.5999 20.0014 18.1481 19.7743C18.6962 19.5473 19.1648 19.1627 19.4944 18.6694C19.8241 18.176 20 17.596 20 17.0027C19.9991 16.2073 19.6828 15.4447 19.1204 14.8823C18.5579 14.3199 17.7954 14.0036 17 14.0027ZM17 18.0027C16.8022 18.0027 16.6089 17.944 16.4444 17.8342C16.28 17.7243 16.1518 17.5681 16.0761 17.3854C16.0004 17.2026 15.9806 17.0016 16.0192 16.8076C16.0578 16.6136 16.153 16.4354 16.2929 16.2956C16.4327 16.1557 16.6109 16.0605 16.8049 16.0219C16.9989 15.9833 17.2 16.0031 17.3827 16.0788C17.5654 16.1545 17.7216 16.2827 17.8315 16.4471C17.9414 16.6116 18 16.8049 18 17.0027C17.9998 17.2678 17.8944 17.5221 17.7069 17.7096C17.5194 17.8971 17.2652 18.0025 17 18.0027Z"
      fill={color}
    />
    <path
      d="M7 4.00269C6.40666 4.00269 5.82664 4.17863 5.33329 4.50828C4.83994 4.83792 4.45543 5.30646 4.22836 5.85464C4.0013 6.40281 3.94189 7.00601 4.05765 7.58796C4.1734 8.1699 4.45912 8.70445 4.87868 9.124C5.29824 9.54356 5.83279 9.82928 6.41473 9.94504C6.99667 10.0608 7.59987 10.0014 8.14805 9.77432C8.69623 9.54726 9.16477 9.16274 9.49441 8.6694C9.82405 8.17605 10 7.59603 10 7.00268C9.99913 6.2073 9.68278 5.44475 9.12036 4.88232C8.55794 4.3199 7.79538 4.00355 7 4.00269ZM7 8.00268C6.80222 8.00268 6.60888 7.94404 6.44443 7.83415C6.27998 7.72427 6.15181 7.56809 6.07612 7.38537C6.00043 7.20264 5.98063 7.00158 6.01922 6.80759C6.0578 6.61361 6.15304 6.43543 6.29289 6.29558C6.43275 6.15573 6.61093 6.06049 6.80491 6.0219C6.99889 5.98331 7.19996 6.00312 7.38269 6.07881C7.56541 6.15449 7.72159 6.28267 7.83147 6.44711C7.94135 6.61156 8 6.8049 8 7.00268C7.99982 7.26785 7.89441 7.5221 7.70691 7.70959C7.51941 7.89709 7.26516 8.00251 7 8.00268Z"
      fill={color}
    />
    <path d="M18 5.00269L6 19.0027" stroke={color} strokeWidth="2" strokeLinecap="round" />
  </svg>
);
