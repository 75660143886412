import React, { useContext } from 'react';
import NumberFormat from 'react-number-format';
import get from 'lodash/get';

// Components
import {
  FormGroup,
  FormFilePicker,
  Input,
  Select,
  SingleOptionRadioButtons,
  DatePicker,
} from '../../../../../common/components';
import { DollarIcon } from '../../../../../common/icons';

// Constants
import { AdditionalInfoTexts } from '../NewDealFormTexts';
import {
  DEAL_INDUSTRY_TYPES_OPTIONS,
  getIndustryTypeSubOptions,
} from '../../../../../utils/helpers/dealIndustryTypes';
import { DEAL_STATUS_OPTIONS } from '../../../../../utils/helpers/deal';
import { EFeatureFlag } from 'app/FeatureFlag';

// Style
import * as S from '../NewDealFormStyled';

// Context
import { NewDealHandlerContext, NewDealForm } from '../NewDealFormHandler';

// Helper
import { formControlChangeHandlers } from '../../../FormControlChangeHandlers/FormControlChangeHandlers';
import { tailPeriodOptions } from '../../../../../navigation/GlobalSidebar/components/SettingsModal/components/MarketplaceSettings/HelpersMarketplaceSettings';
import { formatDateInput, maxPercentValueAllowed } from '../../../../../utils/helpers/helpers';

// Hook
import { useFeatureFlags } from 'app/utils/hooks/hooks';

const NDAdditionalInfo = () => {
  const { form, isStatusDDClosing } = useContext(NewDealHandlerContext);
  const { values, setFieldValue, errors, touched } = form;
  const {
    handleYesNoChange,
    handleFilesChange,
    handleSelectChange,
    handleNumberInputChange,
    handleDateChange,
  } = formControlChangeHandlers<NewDealForm>(setFieldValue);
  const FFEnable = useFeatureFlags(EFeatureFlag.CAAS_NEW_DEAL_EL_STEP); // REMOVE TO ENABLE IN DEPLOY

  const handleIndustrySelectChange = (field: string, subField?: string) => (value: any) => {
    setFieldValue(field, value);
    if (subField) {
      setFieldValue(subField, null);
    }
  };

  const MinFeeInput = React.useMemo(
    () => (props: any) => <Input prefix="Min" suffix="%" {...props} />,
    []
  );
  const MaxFeeInput = React.useMemo(
    () => (props: any) => <Input prefix="Max" suffix="%" {...props} />,
    []
  );

  const ExpectedDealSizeInput = React.useMemo(
    () => (props: any) => <Input leftIcon={<DollarIcon />} {...props} />,
    []
  );

  const DATA_TEST_ID_BASE = "NDAdditionalInfo__"

  const isCollaborative = values.marketplaceDealType === 'collab';
  return (
    <>
      <S.NewDealTitle>{AdditionalInfoTexts.TITLE}</S.NewDealTitle>
      <S.NewDealSubtitle>
        {values.engagementLetterProvided
          ? AdditionalInfoTexts.SUBTITLE_EL
          : AdditionalInfoTexts.SUBTITLE_W_EL}
      </S.NewDealSubtitle>
      <FormGroup
        label={AdditionalInfoTexts.DEAL_STAGE_LABEL}
        required
        error={touched.dealStatus && errors.dealStatus}
      >
        <Select
          options={DEAL_STATUS_OPTIONS}
          inputId="dealStatus"
          name="dealStatus"
          data-testid={DATA_TEST_ID_BASE+"dealStatus"}
          placeholder={AdditionalInfoTexts.DEAL_STAGE_PLACEHOLDER}
          value={values.dealStatus}
          onChange={handleSelectChange('dealStatus')}
          maxMenuHeight={200}
          isSearchable={false}
        />
      </FormGroup>
      {isStatusDDClosing && (
        <FormGroup label="Whether a copy of LOI/Term sheet of this deal has been provided to compliance at compliance@finalis.com?">
          <SingleOptionRadioButtons
            strictValueOnChange
            value={values.loiProvided}
            id="loiProvided"
            name="loiProvided"
            data-testid={DATA_TEST_ID_BASE+"loiProvided"}
            onChange={handleYesNoChange('loiProvided')}
          />
        </FormGroup>
      )}
      {isStatusDDClosing && (
        <FormGroup label="Estimated Closing date">
          <DatePicker
            value={values.closingDate}
            formatInputText={() => formatDateInput(values.closingDate)}
            calendarPopperPosition="bottom"
            onChange={handleDateChange('closingDate')}
            inputPlaceholder="Select a date"
            data-testid={DATA_TEST_ID_BASE+"closingDate"}
            portalTarget="#drawer-portal"
          />
        </FormGroup>
      )}

      <FormGroup label={AdditionalInfoTexts.P_INDUSTRY_LABEL} required>
        <Select
          placeholder={AdditionalInfoTexts.P_INDUSTRY_PLACEHOLDER}
          options={DEAL_INDUSTRY_TYPES_OPTIONS}
          inputId="industryType"
          name="industryType"
          data-testid={DATA_TEST_ID_BASE+"industryType"}
          value={values.industryType}
          onChange={handleIndustrySelectChange('industryType', 'industryTypeSubOptions')}
          maxMenuHeight={200}
          isSearchable={false}
        />
      </FormGroup>
      {get(values, 'industryType.value', '') && (
        <FormGroup
          label={AdditionalInfoTexts.S_INDUSTRY_LABEL}
          required={values.marketplaceDealType === 'collab'}
          optional={values.marketplaceDealType !== 'collab'}
        >
          <Select
            placeholder={AdditionalInfoTexts.S_INDUSTRY_PLACEHOLDER}
            options={getIndustryTypeSubOptions(get(values, 'industryType.value', null))}
            inputId="industryTypeSubOptions"
            name="industryTypeSubOptions"
            data-testid={DATA_TEST_ID_BASE+"industryTypeSubOptions"}
            value={values.industryTypeSubOptions}
            onChange={handleIndustrySelectChange('industryTypeSubOptions')}
            maxMenuHeight={200}
            isSearchable={false}
          />
        </FormGroup>
      )}

      <FormGroup
        label={AdditionalInfoTexts.DEAL_SIZE_LABEL}
        required={isCollaborative}
        optional={!isCollaborative}
        error={touched.expectedSize && errors.expectedSize}
      >
        <NumberFormat
          id="expectedSize"
          name="expectedSize"
          data-testid={DATA_TEST_ID_BASE+"expectedSize"}
          placeholder={AdditionalInfoTexts.DEAL_SIZE_PLACEHOLDER}
          value={values.expectedSize}
          onValueChange={handleNumberInputChange('expectedSize')}
          thousandSeparator
          decimalSeparator={false}
          allowNegative={false}
          customInput={ExpectedDealSizeInput}
        />
      </FormGroup>
      <FormGroup
        label={AdditionalInfoTexts.RETAINER_QUESTION}
        required={isCollaborative}
        optional={!isCollaborative}
      >
        <SingleOptionRadioButtons
          strictValueOnChange
          value={values.monthlyRetainer}
          id="monthlyRetainer"
          name="monthlyRetainer"
          data-testid={DATA_TEST_ID_BASE+"monthlyRetainer"}
          onChange={handleYesNoChange('monthlyRetainer')}
        />
      </FormGroup>

      {values.monthlyRetainer && (
        <FormGroup
          label="Monthly retainer amount"
          required
          error={touched.retainerAmount && errors.retainerAmount}
        >
          <NumberFormat
            id="retainerAmount"
            name="retainerAmount"
            data-testid={DATA_TEST_ID_BASE+"retainerAmount"}
            value={values.retainerAmount}
            onValueChange={handleNumberInputChange('retainerAmount')}
            thousandSeparator
            decimalSeparator={false}
            allowNegative={false}
            customInput={ExpectedDealSizeInput}
          />
        </FormGroup>
      )}
      <FormGroup
        label={AdditionalInfoTexts.SUCCESS_FEE_LABEL}
        required={isCollaborative}
        optional={!isCollaborative}
      >
        <S.SpaceBetweenContainer>
          <S.InputLabelContainer style={{ marginRight: 10 }}>
            <NumberFormat
              id="minFee"
              name="minFee"
              data-testid={DATA_TEST_ID_BASE+"minFee"}
              placeholder={AdditionalInfoTexts.SUCCESS_FEE_PLACEHOLDER}
              value={values.minFee}
              onValueChange={handleNumberInputChange('minFee')}
              thousandSeparator
              allowNegative={false}
              customInput={MinFeeInput}
              isAllowed={maxPercentValueAllowed}
              decimalScale={2}
            />
          </S.InputLabelContainer>
          <S.InputLabelContainer>
            <NumberFormat
              id="maxFee"
              name="maxFee"
              data-testid={DATA_TEST_ID_BASE+"maxFee"}
              placeholder={AdditionalInfoTexts.SUCCESS_FEE_PLACEHOLDER}
              value={values.maxFee}
              onValueChange={handleNumberInputChange('maxFee')}
              displayType="input"
              thousandSeparator
              allowNegative={false}
              customInput={MaxFeeInput}
              decimalScale={2}
              isAllowed={maxPercentValueAllowed}
            />
          </S.InputLabelContainer>
        </S.SpaceBetweenContainer>
      </FormGroup>
      <FormGroup
        label={AdditionalInfoTexts.TAIL_PERIOD_LABEL}
        required={isCollaborative}
        optional={!isCollaborative}
      >
        <Select
          options={tailPeriodOptions}
          placeholder={AdditionalInfoTexts.TAIL_PERIOD_PLACEHOLDER}
          inputId="tailPeriodDuration"
          name="tailPeriodDuration"
          data-testid={DATA_TEST_ID_BASE+"tailPeriodDuration"}
          value={values.tailPeriodDuration}
          onChange={handleSelectChange('tailPeriodDuration')}
          maxMenuHeight={200}
          isSearchable={false}
        />
      </FormGroup>

      {!FFEnable && values.engagementLetterProvided && (
        <>
          <S.NewDealSubtitle>{AdditionalInfoTexts.ENGAGEMENT_LETTER_TITLE}</S.NewDealSubtitle>
          <FormFilePicker
            label="Upload your engagement letter for compliance and legal review."
            data-testid={DATA_TEST_ID_BASE+"files"}
            setFiles={handleFilesChange('files')}
            files={values.files}
            acceptedFiles=".jpg, .jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .png, image/jpeg, image/png, text/plain, .doc, .docx, .xls, .xlsx, .ppt, .pptx, application/excel, application/vnd.ms-excel, application/x-excel, application/x-msexcel, application/doc, application/ms-doc, application/msword, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
            description="DOC, DOCX, JPG, JPEG, PDF, PNG, PPT, PPTX, XLS, XLSX and plain text are supported."
          />
        </>
      )}
    </>
  );
};

export default NDAdditionalInfo;
