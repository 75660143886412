import * as React from 'react';
import { IconProps } from './types';

export const RightArrow = ({
  width = '6px',
  height = '9px',
  color = 'currentColor',
  style = {},
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 6 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.36015 4.02661L1.58682 0.259943C1.52484 0.197457 1.45111 0.147861 1.36987 0.114016C1.28863 0.0801698 1.20149 0.0627441 1.11348 0.0627441C1.02548 0.0627441 0.938339 0.0801698 0.8571 0.114016C0.77586 0.147861 0.702126 0.197457 0.640151 0.259943C0.515983 0.384851 0.446289 0.553819 0.446289 0.729943C0.446289 0.906067 0.515983 1.07503 0.640151 1.19994L3.94015 4.53328L0.640151 7.83327C0.515983 7.95818 0.446289 8.12715 0.446289 8.30328C0.446289 8.4794 0.515983 8.64837 0.640151 8.77327C0.701894 8.83627 0.775524 8.88638 0.856774 8.92071C0.938024 8.95504 1.02528 8.97291 1.11348 8.97327C1.20169 8.97291 1.28894 8.95504 1.37019 8.92071C1.45144 8.88638 1.52507 8.83627 1.58682 8.77327L5.36015 5.00661C5.42782 4.94418 5.48183 4.86841 5.51876 4.78408C5.5557 4.69975 5.57477 4.60868 5.57477 4.51661C5.57477 4.42454 5.5557 4.33347 5.51876 4.24914C5.48183 4.16481 5.42782 4.08904 5.36015 4.02661Z"
      fill={color}
    />
  </svg>
);
