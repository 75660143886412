import * as React from 'react';
import { IconProps } from './types';

export const LeftArrowOutlineIcon = ({
  width = '8px',
  height = '10px',
  color = 'currentColor',
  style = {},
}: IconProps) => (
  <svg
    width={width}
    height={height}
    style={style}
    viewBox="0 0 8 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33343 4.33411H2.27343L4.47343 2.14077C4.59896 2.01524 4.66949 1.84498 4.66949 1.66744C4.66949 1.48991 4.59896 1.31964 4.47343 1.19411C4.34789 1.06857 4.17763 0.998047 4.00009 0.998047C3.82256 0.998047 3.6523 1.06857 3.52676 1.19411L0.193427 4.52744C0.132733 4.59084 0.0851566 4.66561 0.053427 4.74744C-0.0132517 4.90975 -0.0132517 5.0918 0.053427 5.25411C0.0851566 5.33594 0.132733 5.41071 0.193427 5.47411L3.52676 8.80744C3.58874 8.86993 3.66247 8.91952 3.74371 8.95337C3.82495 8.98721 3.91209 9.00464 4.00009 9.00464C4.0881 9.00464 4.17524 8.98721 4.25648 8.95337C4.33772 8.91952 4.41145 8.86993 4.47343 8.80744C4.53591 8.74546 4.58551 8.67173 4.61935 8.59049C4.6532 8.50925 4.67063 8.42212 4.67063 8.33411C4.67063 8.2461 4.6532 8.15896 4.61935 8.07772C4.58551 7.99648 4.53591 7.92275 4.47343 7.86077L2.27343 5.66744H7.33343C7.51024 5.66744 7.67981 5.5972 7.80483 5.47218C7.92986 5.34715 8.00009 5.17759 8.00009 5.00077C8.00009 4.82396 7.92986 4.65439 7.80483 4.52937C7.67981 4.40435 7.51024 4.33411 7.33343 4.33411Z"
      fill={color}
    />
  </svg>
);
