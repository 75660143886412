import * as React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { CheckIcon } from '../../icons/index';

const height = 32;
const padding = 4;
const circleDiameter = 24;
const width = 64;

const Container = styled.div`
  display: inline-block;
  vertical-align: middle;
  height: ${height}px;
  position: relative;
`;

const BaseCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const SwitchContainer = styled.span`
  position: relative;
  display: inline-block;
  width: ${width}px;
  height: ${height}px;
`;

const ToggleSwitch = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.2s;
  border-radius: ${height}px;
  align-items: center;
  justify-content: center;

  &:before {
    border-radius: 50%;
    position: absolute;
    content: '';
    height: ${circleDiameter}px;
    width: ${circleDiameter}px;
    left: ${padding}px;
    bottom: ${padding}px;
    background-color: white;
    transition: 0.4s;
  }

  &:hover {
    background-color: ${darken(0.03, '#ccc')};
  }

  ${BaseCheckbox}:focus + ${SwitchContainer} & {
    box-shadow: 0 0 0 1px #5dadf8;
  }

  ${BaseCheckbox}:checked + ${SwitchContainer} & {
    background-color: #47455f;
  }

  ${BaseCheckbox}:checked + ${SwitchContainer} &:hover {
    background-color: #47455f;
    border-color: #47455f;
  }

  ${BaseCheckbox}:checked + ${SwitchContainer} &:before {
    transform: translateX(${width - circleDiameter - padding * 2}px);
  }

  ${BaseCheckbox}:disabled + ${SwitchContainer} & {
    background-color: #aaa;
    cursor: not-allowed;
  }

  ${BaseCheckbox}:disabled + ${SwitchContainer} &:before {
    background-color: #f3f3f3;
    cursor: not-allowed;
  }
`;

const CheckIconContainter = styled.div`
  width: ${width / 2}px;
  height: ${height}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type SwitchProps = {
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  disabled?: boolean;
  disableOnClick?: boolean;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  className?: string;
  id?: string;
  partial?: boolean;
  height?: string;
};

class Switch extends React.Component<SwitchProps> {
  inputRef = React.createRef<HTMLInputElement>();

  onClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const inputNode = this.inputRef && this.inputRef.current;

    if (inputNode) {
      inputNode.click();
    }

    if (this.props.onClick) {
      this.props.onClick(event);
    }
  };

  render() {
    const {
      checked,
      onChange,
      disabled,
      style,
      className,
      id,
      disableOnClick,
      partial,
      containerStyle,
    } = this.props;

    if (checked && partial) {
      console.error('Checkbox cannot be checked and partial at the same time');
    }

    return (
      <Container style={containerStyle}>
        <BaseCheckbox
          id={id}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          ref={this.inputRef}
        />
        <SwitchContainer
          onClick={disabled || disableOnClick ? undefined : this.onClick}
          style={style}
          className={className}
        >
          <ToggleSwitch>
            {checked && (
              <CheckIconContainter>
                <CheckIcon color="#fff" />
              </CheckIconContainter>
            )}
          </ToggleSwitch>
        </SwitchContainer>
      </Container>
    );
  }
}

export default Switch;
