import * as React from 'react';

import { IconProps } from './types';

export const FolderVDRList = ({
  width = '20px',
  height = '20px',
  color = '#47455F',
}: IconProps): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
      fill="#EDEDF7"
    />
    <path
      d="M16.0833 5.95817C15.7081 5.57507 15.1986 5.35891 14.6667 5.35706H9.75834L9.54166 4.67971C9.40273 4.28333 9.1466 3.94026 8.80834 3.69752C8.4691 3.45628 8.06406 3.32897 7.65001 3.33345H5.33334C4.80502 3.33565 4.29882 3.54927 3.92446 3.92805C3.5501 4.30683 3.33771 4.82028 3.33333 5.35706V13.9679C3.33769 14.5055 3.5498 15.0198 3.92394 15.3999C4.29807 15.78 4.80426 15.9956 5.33334 16H14.6667C15.1964 15.9978 15.7039 15.7829 16.0785 15.4023C16.4531 15.0217 16.6645 14.5062 16.6667 13.9679V7.35524C16.6588 6.83012 16.4495 6.32883 16.0833 5.95817ZM4.86666 4.88291C4.98975 4.75529 5.1575 4.68226 5.33334 4.67971H7.65001C7.78989 4.68276 7.92592 4.72684 8.04167 4.80668C8.15175 4.89446 8.23545 5.01179 8.28334 5.14538L8.64168 6.25455C8.68707 6.38716 8.77141 6.50243 8.88334 6.58477C8.99746 6.66797 9.13454 6.71239 9.275 6.71174H14.6667C14.845 6.71389 15.0155 6.78681 15.1417 6.91494C15.2027 6.97729 15.2511 7.0513 15.284 7.13266C15.3169 7.21402 15.3336 7.30113 15.3333 7.38909V7.63466C15.0016 7.458 14.6329 7.36506 14.2583 7.36372H5.71667C5.34213 7.36506 4.97341 7.458 4.64167 7.63466V5.35706C4.64418 5.17839 4.71606 5.00797 4.84166 4.88291H4.86666ZM15.1417 14.4759C15.0155 14.604 14.845 14.677 14.6667 14.6792H5.33334C5.15786 14.6749 4.99073 14.6021 4.86661 14.476C4.74249 14.3499 4.6709 14.1801 4.66667 14.0018V9.37037C4.7495 9.14882 4.89667 8.95811 5.08867 8.82352C5.28067 8.68893 5.50841 8.61682 5.74167 8.6168H14.2583C14.4916 8.61682 14.7193 8.68893 14.9113 8.82352C15.1033 8.95811 15.2505 9.14882 15.3333 9.37037V13.9679C15.335 14.0634 15.3165 14.1582 15.2792 14.2458C15.2418 14.3334 15.1864 14.4119 15.1167 14.4759H15.1417Z"
      fill={color}
    />
  </svg>
);
