import styled from 'styled-components';

export const Title = styled.div`
  ${({ theme }) => {
    const { colors, typography } = theme.foundations;
    return `
        color: ${colors.palette.neutral[600]};
        font-size: ${typography.sizes['06']};
        font-family: ${typography.fonts.body};
        font-weight: ${typography.weights.bold};
        font-style: normal;
        text-align: justify;
        text-justify: inter-word;
        margin-top: 58px;    
        line-height: 125%;
        letter-spacing: 0.25px;
        flex: none;
        order: 0;
        flex-grow: 0;
    `;
  }}
`;

export const Text = styled.div`
  ${({ theme }) => {
    const { colors, typography } = theme.foundations;
    return `
        color: ${colors.palette.neutral[600]};
        font-size: ${typography.sizes['04']};
        font-family: ${typography.fonts.body};
        font-weight: ${typography.weights.regular};
        font-style: normal;
        text-align: justify;
        text-justify: inter-word;
        margin-bottom: 18px;    
        line-height: 150%;
        letter-spacing: 0.25px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
    `;
  }}
`;

export const ModalHeader = styled.div`
  ${({ theme }) => {
    const { spacing, typography, colors } = theme.foundations;
    return `
      display: flex;
      align-items: center;
      height: 96px;
      padding: 0px ${spacing['06']};
      font-family: ${typography.fonts.body};
      font-size: 22px;
      color: ${colors.text.default};
      font-weight: ${typography.weights.bold};
      background: ${colors.background.primary};
    `;
  }}
`;

export const ModalBody = styled.div`
  padding: 32px;
`;

export const ModalCloseButton = styled.button`
  ${({ theme }) => {
    const { sizes, colors } = theme.foundations;
    return `
      width: ${sizes['06']};
      height: ${sizes['06']};
      outline: 0;
      border: 0;
      background: ${colors.palette.transparent};
      color: ${colors.text.dark};
      cursor: pointer;
    `;
  }}
`;
