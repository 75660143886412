import styled from 'styled-components';

export const Overlay = styled.div`
  ${({ theme }) => {
    const { colors, sizes } = theme.foundations;
    return `
      position: fixed;
      width: 100vw;
      height: 100vh;
      background: transparent;
      box-shadow: 0 0 0 ${sizes['01']} inset ${colors.palette.teal['400']};
      pointer-events: none;
      z-index: 2147483002;
    `;
  }}
`;

export const NotchContainer = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 37px;
`;

export const Notch = styled.div`
  ${({ theme }) => {
    const { colors, spacing, sizes } = theme.foundations;
    return `
      display: flex;
      min-width: 292px;
      padding: ${spacing['02']} ${spacing['04']};
      background: ${colors.palette.teal['400']};
      border-radius: 0px 0px ${sizes['02']} ${sizes['02']};
      align-items: center;
      pointer-events: auto;
    `;
  }}
`;

export const NotchText = styled.p`
  ${({ theme }) => {
    const { typography, colors } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['03']};
      color: ${colors.text.dark};

      em {
        font-weight: ${typography.weights.medium};
        font-size: inherit;
      }

      a {
        text-decoration: underline;
        color: inherit;
      }

    `;
  }}
`;
