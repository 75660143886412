import { DoubleChevronIcon, FinalisLogo, FinalisLogov2 } from 'app/common/icons';
import { Tooltip } from 'app/common/components';
import { ERoutePatterns } from 'app/core-tools/due-diligence/types/types';
import React, { useContext, useState } from 'react';
import { LoadingRectangle } from '@dealsyte/poki';

import * as S from './StyledSidebar';
import { LinkProps, useLocation } from 'react-router-dom';
import { FinalisLogov3 } from 'app/common/icons/FinalisLogov3';
import { analyticsEvents } from 'app/analytics/useAnalyticsEvents';
import { ACTION, EVENT_NAME } from 'app/analytics/constants';
import { UserContext } from 'app/users/context/UserContext';

export type ItemSidebar = {
  name: string;
  icon: React.ReactNode;
  active?: boolean;
  hasNotification?: boolean;
  id: string;
  disabled?: boolean;
  hide?: boolean;
  collapsed?: boolean;
  external?: boolean;
} & LinkProps;

export interface SidebarItems {
  categoryName?: string;
  sidebarItems: ItemSidebar[];
}

export interface SidebarLinks {
  route: ERoutePatterns;
  name: string;
  icon: React.ReactNode;
  hide?: boolean;
}

export interface SidebarProps {
  items: SidebarItems[];
  firmName: string;
  firmLogo?: string | null;
  links: SidebarLinks[];
  isLoading?: boolean;
}

const SidebarItem = ({
  name,
  onClick,
  active,
  id,
  children,
  collapsed,
  href,
  to,
  external,
  ...rest
}: ItemSidebar) => {
  const { id: userId } = useContext(UserContext);
  const { createEvent } = analyticsEvents();
  const location = useLocation();
  const trackRouteChange = async (destinationRoute: string) => {
    await createEvent([
      {
        eventName: EVENT_NAME.NAVBAR_NAVIGATION,
        action: ACTION.CLICK,
        timestamp: new Date().toISOString(),
        userId: userId,
        object: 'Navbar',
        target: [
          { id: 'end', object: destinationRoute },
          { id: 'initial', object: location.pathname },
        ],
      },
    ]);
    if (name === 'My Deals') {
      await createEvent([
        {
          eventName: EVENT_NAME.WF_MYDEALS_CLICK,
          action: ACTION.CLICK,
          timestamp: new Date().toISOString(),
          userId: userId,
          object: 'n/a',
          target: [{ id: 'user_id', object: userId }],
        },
      ]);
    }
  };
  return external ? (
    collapsed ? (
      <S.SidebarItemButton
        as="a"
        href={href}
        active={active}
        collapsed={collapsed}
        onClick={onClick}
        key={id}
        data-testid={'SidebarItemButton_' + 'navbar-item_external_collapsed' + '_' + name}
        data-name={name}
        className={`${active ? 'active' : ''}`}
      >
        <Tooltip message={name} placement="right">
          {children}
        </Tooltip>
      </S.SidebarItemButton>
    ) : (
      <S.SidebarItemButton
        as="a"
        href={href}
        active={active}
        collapsed={collapsed}
        onClick={onClick}
        key={id}
        data-testid={'SidebarItemButton_' + 'navbar-item_external' + '_' + name}
        data-name={name}
        className={`${active ? 'active' : ''}`}
      >
        {children}
      </S.SidebarItemButton>
    )
  ) : collapsed ? (
    <S.SidebarItem
      active={active}
      collapsed={collapsed}
      onClick={async () => {
        trackRouteChange(to.toString());
        return onClick;
      }}
      key={id}
      data-testid={'SidebarItemButton_' + 'navbar-item_collapsed' + '_' + name}
      data-name={name}
      className={`${active ? 'active' : ''}`}
      to={to}
    >
      <Tooltip message={name} placement="right">
        {children}
      </Tooltip>
    </S.SidebarItem>
  ) : (
    <S.SidebarItem
      active={active}
      collapsed={collapsed}
      onClick={async () => {
        trackRouteChange(to.toString());
        return onClick;
      }}
      key={id}
      data-testid={'SidebarItemButton_' + 'navbar-item' + '_' + name}
      data-name={name}
      className={`${active ? 'active' : ''}`}
      to={to}
    >
      {children}
    </S.SidebarItem>
  );
};

export const Sidebar = ({ items, firmName, links, firmLogo, isLoading = false }: SidebarProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const toggleSideBar = () => {
    setCollapsed(!collapsed);
  };
  const hasFirm = firmName.length > 0;
  const splitFirm = firmName.split(' ');
  const firmInitals =
    splitFirm.length > 1
      ? `${splitFirm[0].charAt(0)}${splitFirm[1].charAt(0)}`
      : splitFirm[0].charAt(0);

  return (
    <S.SidebarWrapper data-testid="sidebar" collapsed={collapsed}>
      {collapsed ? (
        <S.SidebarButtonContainer>
          <S.SidebarButton
            onClick={toggleSideBar}
            collapsed={collapsed}
            data-testid="chevron-navbar"
            className="collapsed"
          >
            <DoubleChevronIcon height="5px" width="7px" color="#47455F" />
          </S.SidebarButton>
        </S.SidebarButtonContainer>
      ) : (
        <S.SidebarButtonContainer>
          <Tooltip message="Collapse menu" placement="right">
            <S.SidebarButton
              onClick={toggleSideBar}
              collapsed={collapsed}
              data-testid="chevron-navbar"
              className="expanded"
            >
              <DoubleChevronIcon height="5px" width="7px" color="#47455F" />
            </S.SidebarButton>
          </Tooltip>
        </S.SidebarButtonContainer>
      )}
      <S.SidebarSection hasFirm={hasFirm} collapsed={collapsed} isLoading={isLoading}>
        {isLoading ? (
          <S.SkeletonWrapper data-testid="navbar-loading">
            <S.FirmSkeletonWrapper>
              <LoadingRectangle
                rectangleStyle={{
                  borderRadius: '6px',
                  backgroundImage:
                    'linear-gradient(to right, #e2e1e1 8%, #e0dddd 18%, #e8e8e8 33%)',
                }}
                height={40}
                width={40}
              />
              <LoadingRectangle
                rectangleStyle={{
                  borderRadius: '6px',
                  backgroundImage:
                    'linear-gradient(to right, #e2e1e1 8%, #e0dddd 18%, #e8e8e8 33%)',
                }}
                height={24}
                width={140}
              />
            </S.FirmSkeletonWrapper>
            {Array(5)
              .fill('')
              .map(() => (
                <LoadingRectangle
                  rectangleStyle={{
                    borderRadius: '6px',
                    backgroundImage:
                      'linear-gradient(to right, #e2e1e1 8%, #e0dddd 18%, #e8e8e8 33%)',
                  }}
                  height={32}
                  width={172}
                />
              ))}
          </S.SkeletonWrapper>
        ) : (
          <>
            {hasFirm && (
              <S.SidebarFirm collapsed={collapsed} data-testid="SidebarFirm_header-navbar">
                {firmLogo ? (
                  <S.SidebarFirmLogo data-testid="SidebarFirm_header-logo">
                    <img
                      src={firmLogo}
                      alt={firmName}
                      style={{ overflow: 'hidden', borderRadius: '4px' }}
                    />
                  </S.SidebarFirmLogo>
                ) : (
                  <S.SidebarFirmLogo data-testid="SidebarFirm_header-logo_firmInitals">
                    {firmInitals}
                  </S.SidebarFirmLogo>
                )}

                {!collapsed && (
                  <S.SidebarFirmName data-testid="SidebarFirm_header-title">
                    {firmName}
                  </S.SidebarFirmName>
                )}
              </S.SidebarFirm>
            )}
            {items.map(({ categoryName, sidebarItems }, index) => (
              <S.SidebarItemContainer
                key={categoryName || index}
                data-testid={`${categoryName ? categoryName.toLowerCase() : 'main'}-navbar`}
              >
                {categoryName &&
                  (collapsed ? (
                    <S.SidebarDivider />
                  ) : (
                    <S.SidebarCategory data-testid="SidebarCategory_navbar-title_categoryName">
                      {categoryName}
                    </S.SidebarCategory>
                  ))}
                {sidebarItems.map(
                  ({ name, icon, active, onClick, hasNotification, id, hide, to, ...rest }) =>
                    !hide && (
                      <SidebarItem
                        active={active}
                        collapsed={collapsed}
                        onClick={onClick}
                        id={id}
                        name={name}
                        data-testid={'SidebarItem_navbar-item' + '_' + name}
                        to={to}
                        icon={icon}
                        {...rest}
                      >
                        <S.SidebarIcon active={active} data-testid="navbar-icon">
                          {icon}
                          {hasNotification && (
                            <S.NotificationDot>
                              <S.Dot>
                                <div className="sonar-wave"></div>
                              </S.Dot>
                            </S.NotificationDot>
                          )}
                        </S.SidebarIcon>
                        {!collapsed && <S.SidebarItemName>{name}</S.SidebarItemName>}
                      </SidebarItem>
                    )
                )}
              </S.SidebarItemContainer>
            ))}
            <S.SidebarFooter data-testid="footer-navbar">
              {!collapsed && (
                <S.SidebarLinksContainer>
                  {links.map(
                    ({ name, icon, route, hide }) =>
                      !hide && (
                        <Tooltip message={name}>
                          <S.SidebarLinkButtonIcon
                            href={route}
                            target="_blank"
                            key={name + route}
                            data-testid={`footer-item-${
                              name ? name.replace(/ /g, '_').toLowerCase() : 'link'
                            }`}
                          >
                            {icon}
                          </S.SidebarLinkButtonIcon>
                        </Tooltip>
                      )
                  )}
                </S.SidebarLinksContainer>
              )}

              <S.SidebarPowerBySection collapsed={collapsed}>
                <Tooltip placement="bottom-end" message="Visit www.finalis.com">
                  <S.SidebarPoweredByLogo
                    onClick={() => {
                      window.open(ERoutePatterns.FINALIS_URL, '_blank');
                    }}
                    data-testid="footer-logo"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    isHovered={isHovered}
                    collapsed={collapsed}
                  >
                    {!collapsed && (
                      <S.SidebarPowerByTitle>
                        Powered by <FinalisLogov3 color={!isHovered ? '#6A678E' : ''} />
                      </S.SidebarPowerByTitle>
                    )}
                    {collapsed && <FinalisLogov2 color={isHovered ? '#185ADC' : '#6A678E'} />}
                  </S.SidebarPoweredByLogo>
                </Tooltip>
              </S.SidebarPowerBySection>
            </S.SidebarFooter>
          </>
        )}
      </S.SidebarSection>
    </S.SidebarWrapper>
  );
};
