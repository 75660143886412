import styled, { DefaultTheme } from 'styled-components';

export const InputContainer = styled.div`
  ${({ theme, disabled }: { theme: DefaultTheme; disabled?: boolean | undefined }) => {
    const {
      foundations: { colors, radii, sizes, spacing, typography, shadows },
    } = theme;
    return `
        position: relative;
        display: flex;
        align-items: center;
        border: 1px solid ${disabled ? colors.background.other : colors.outline.default};
        border-radius: ${radii.sm};
        height: ${sizes['09']};
        padding: 0px;
        background: ${disabled ? colors.background.secondary : colors.background.primary};
        transition: box-shadow 300ms ease-out;

        input {
          flex: 1;
          border-radius: ${radii.sm};
          font-family: ${typography.fonts.body};
          font-size: ${typography.sizes['04']};
          color: ${colors.text.default};
          background: transparent;
          padding: 0px ${spacing['03']};
          outline: none;
          border: none;
          width: 100%;
          height: 100%;

          &::placeholder {
            color: ${colors.text.light};
          }

          &:disabled {
            color: ${colors.text.light};
          }
        }

        &.error {
          border: 1px solid ${colors.states.error};
          &:focus-within {
            border: 1px solid ${colors.states.error};
          }
        }

        &:disabled {
          background: ${colors.background.secondary};
          border: 1px solid ${colors.background.other};
        }

        &:focus-within {
            border: 1px solid ${colors.outline.dark};
            box-shadow: ${shadows['01']};
        }
      `;
  }}
`;

export const Overlays = styled.div`
  ${() => {
    return `
      position: absolute;
      pointer-events: none;
      display: flex;

      &.right-overlay {
        right: 0;
      }

      &.left-overlay {
        left: 0;
      }
    `;
  }}
`;

export const Prefix = styled.div`
  ${({ theme }) => {
    const {
      foundations: { colors, sizes, spacing, typography },
    } = theme;
    return `
      display: flex;
      align-items: center;
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['04']};
      color: ${colors.text.default};
      padding: 0px ${spacing['02']};
      height: ${sizes['06']};
      user-select: none;
      border-right: 1px solid ${colors.outline.default};
    `;
  }}
`;

export const Sufix = styled.div`
  ${({ theme }) => {
    const {
      foundations: { colors, sizes, spacing, typography },
    } = theme;
    return `
      display: flex;
      align-items: center;
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['04']};
      color: ${colors.text.default};
      padding: 0px ${spacing['02']};
      height: ${sizes['06']};
      user-select: none;
    `;
  }}
`;

export const IconContainer = styled.div`
  ${({ theme }) => {
    const {
      foundations: { colors, sizes, spacing, typography },
    } = theme;
    return `
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: ${typography.fonts.body};
      font-size: ${typography.sizes['04']};
      color: ${colors.text.light};
      padding: 0px ${spacing['02']};
      height: ${sizes['06']};
      max-height: ${sizes['06']};
      overflow: hidden;
      user-select: none;
    `;
  }}
`;
