import get from 'lodash/get';
import { EFormType } from '../../../../../../utils/types/types';
import { CounterpartyTypes, KYCCounterpartiesLabels } from '../KYCFormConstants';
import { KYCCounterpartyForm } from './KYCCounterpartyHandler';

export const buildKYCCounterpartytForm = (
  props: KYCCounterpartyForm,
  dealId: string,
  isCapitalRaiseDeal: boolean
) => {
  const {
    typesCounterparty,
    entityName,
    connection,
    yearsKnowledge,
    relationshipNature,
    connectionAmplify,
    fullName,
    emailAddress,
    title,
  } = props;
  const getCounterpartyType = (): string =>
    typesCounterparty ? CounterpartyTypes.ENTITY : CounterpartyTypes.INDIVIDUAL;
  const variables = {
    type: EFormType.KYCco,
    dealId,
    content: [
      {
        question: KYCCounterpartiesLabels.COUNTERPARTY_TYPES,
        input: getCounterpartyType(),
        key: 'typesCounterparty',
        value: JSON.stringify(getCounterpartyType()),
      },
      {
        question: KYCCounterpartiesLabels.FULL_NAME,
        input: fullName.trim(),
        key: 'counterpartyName',
        value: JSON.stringify(fullName.trim()),
      },
      {
        question: KYCCounterpartiesLabels.EMAIL_ADDRESS,
        input: emailAddress.trim(),
        key: 'emailAddress',
        value: JSON.stringify(emailAddress.trim()),
      },
      {
        question: KYCCounterpartiesLabels.TITLE,
        input: title.trim(),
        key: 'title',
        value: JSON.stringify(title.trim()),
      },
    ],
  };

  if (getCounterpartyType() === CounterpartyTypes.ENTITY) {
    variables.content.push({
      question: KYCCounterpartiesLabels.ENTITY_NAME,
      input: entityName.trim(),
      key: 'entityName',
      value: JSON.stringify(entityName.trim()),
    });
  }

  if (isCapitalRaiseDeal) {
    variables.content.push({
      question: KYCCounterpartiesLabels.CONNECTION,
      input: get(connection, 'label', ''),
      key: 'connection',
      value: JSON.stringify(connection) || '',
    });
    if (get(connection, 'value', '') === 'PRE_EXISTING_RELATIONSHIP') {
      variables.content.push(
        {
          question: KYCCounterpartiesLabels.YEARS_KNWOLEDGE,
          input: yearsKnowledge.trim(),
          key: 'yearsKnowledge',
          value: JSON.stringify(yearsKnowledge.trim()),
        },
        {
          question: KYCCounterpartiesLabels.RELATIONSHIP_NATURE,
          input: relationshipNature.trim(),
          key: 'relationshipNature',
          value: JSON.stringify(relationshipNature.trim()),
        }
      );
    } else if (get(connection, 'value', '') === 'OTHER') {
      variables.content.push({
        question: KYCCounterpartiesLabels.CONNECTION_AMPLIFY,
        input: connectionAmplify.trim(),
        key: 'connectionAmplify',
        value: JSON.stringify(connectionAmplify.trim()),
      });
    }
  }

  return variables;
};
