import * as React from 'react';
import { IconProps } from './types';

export const DateIcon = ({
  width = '14px',
  height = '14px',
  color = 'currentColor',
  style,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    style={style}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6672 1.66988H10.3339V1.00321C10.3339 0.826404 10.2636 0.656834 10.1386 0.53181C10.0136 0.406786 9.84401 0.336548 9.6672 0.336548C9.49039 0.336548 9.32082 0.406786 9.19579 0.53181C9.07077 0.656834 9.00053 0.826404 9.00053 1.00321V1.66988H5.00053V1.00321C5.00053 0.826404 4.93029 0.656834 4.80527 0.53181C4.68024 0.406786 4.51067 0.336548 4.33386 0.336548C4.15705 0.336548 3.98748 0.406786 3.86246 0.53181C3.73743 0.656834 3.6672 0.826404 3.6672 1.00321V1.66988H2.33386C1.80343 1.66988 1.29472 1.88059 0.919649 2.25567C0.544576 2.63074 0.333862 3.13945 0.333862 3.66988V11.6699C0.333862 12.2003 0.544576 12.709 0.919649 13.0841C1.29472 13.4592 1.80343 13.6699 2.33386 13.6699H11.6672C12.1976 13.6699 12.7063 13.4592 13.0814 13.0841C13.4565 12.709 13.6672 12.2003 13.6672 11.6699V3.66988C13.6672 3.13945 13.4565 2.63074 13.0814 2.25567C12.7063 1.88059 12.1976 1.66988 11.6672 1.66988ZM12.3339 11.6699C12.3339 11.8467 12.2636 12.0163 12.1386 12.1413C12.0136 12.2663 11.844 12.3365 11.6672 12.3365H2.33386C2.15705 12.3365 1.98748 12.2663 1.86246 12.1413C1.73743 12.0163 1.6672 11.8467 1.6672 11.6699V7.00321H12.3339V11.6699ZM12.3339 5.66988H1.6672V3.66988C1.6672 3.49307 1.73743 3.3235 1.86246 3.19848C1.98748 3.07345 2.15705 3.00321 2.33386 3.00321H3.6672V3.66988C3.6672 3.84669 3.73743 4.01626 3.86246 4.14129C3.98748 4.26631 4.15705 4.33655 4.33386 4.33655C4.51067 4.33655 4.68024 4.26631 4.80527 4.14129C4.93029 4.01626 5.00053 3.84669 5.00053 3.66988V3.00321H9.00053V3.66988C9.00053 3.84669 9.07077 4.01626 9.19579 4.14129C9.32082 4.26631 9.49039 4.33655 9.6672 4.33655C9.84401 4.33655 10.0136 4.26631 10.1386 4.14129C10.2636 4.01626 10.3339 3.84669 10.3339 3.66988V3.00321H11.6672C11.844 3.00321 12.0136 3.07345 12.1386 3.19848C12.2636 3.3235 12.3339 3.49307 12.3339 3.66988V5.66988Z"
      fill={color}
    />
  </svg>
);
