import * as React from 'react';
import { IconProps } from './types';

export const PenIcon = ({
  color = 'currentColor',
  width = '11px',
  height = '11px',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.024 6.67566e-06C7.46784 -0.00139948 6.93345 0.219391 6.54111 0.613843L5.78172 1.37323L5.00056 0.592063H4.99986C4.45001 0.0422167 3.55775 0.0422167 3.00726 0.592063L0 3.60004L0.762893 4.36294L3.77087 1.35712C3.90095 1.23196 4.10697 1.23196 4.23704 1.35712L5.0182 2.13828L1.3768 5.77842C0.519696 6.63061 0.0394558 7.79154 0.0429658 9.00006V10.0801H1.12297C2.33163 10.0836 3.49248 9.60335 4.3446 8.74623L9.50898 3.58184C10.1087 2.98138 10.288 2.07926 9.96319 1.2953C9.63765 0.511315 8.87265 0.000150528 8.02406 0.000150528L8.024 6.67566e-06ZM3.5816 7.98121C2.92981 8.63371 2.04527 9.00004 1.1228 9.00004C1.1228 8.07754 1.48914 7.19302 2.14164 6.54124L5.78106 2.90182L7.22106 4.34182L3.5816 7.98121ZM8.744 2.81881L7.98462 3.57819L6.54462 2.13819L7.304 1.37881C7.49244 1.17701 7.7547 1.061 8.03104 1.05607C8.30738 1.05185 8.57315 1.15943 8.76863 1.35418C8.96339 1.54964 9.07097 1.81543 9.06674 2.09177C9.06182 2.3681 8.9458 2.63036 8.744 2.81881Z"
      fill={color}
    />
  </svg>
);
