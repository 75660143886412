import useStateModal from 'app/utils/hooks/useStateModal';
import React, { useState } from 'react';
import { useCAExternalHandler } from './CAExternalFormHandler';
import { useCAInternalHandler } from './CAInternalFormHandler';
import { STEPS } from './CAConstants';

export const useCollaborationAgreementsFormHandler = () => {
  const [currentStep, setCurrentStep] = useState(STEPS.OPTION_SELECTOR);
  const [optionSelected, setOptionSelected] = useState('');
  const externalHandler = useCAExternalHandler();
  const internallHandler = useCAInternalHandler();

  const modal = useStateModal('collaborationAgreementsForm');

  return {
    modal,
    currentStep,
    optionSelected,
    setCurrentStep,
    setOptionSelected,
    internallHandler,
    externalHandler,
  };
};

type UseCollaborationAgreementsFormHandler = ReturnType<
  typeof useCollaborationAgreementsFormHandler
>;

export const CollaborationAgreementsHandlerContext = React.createContext<
  UseCollaborationAgreementsFormHandler
>({} as UseCollaborationAgreementsFormHandler);

export const CollaborationAgreementsHandlerProvider =
  CollaborationAgreementsHandlerContext.Provider;
