import React from 'react';

import { ConversationsContextProvider } from 'app/marketplace/context/ConversationsContext';
import { DealsProvider } from 'app/compliance/context/DealsContext';
import { FilterContextProvider } from 'app/marketplace/context/FilterContext';
import { FormsContextProvider } from 'app/compliance/context/FormsContext';
import { FormsContextU4Provider } from 'app/compliance/context/FormU4Context';
import { FundContextProvider } from 'app/marketplace/context/FundContext';
import { SearchQueryProvider, useSearchQuery } from 'app/utils/hooks/useSearchQuery';
import UserContextProvider from 'app/users/context/UserContextProvider';

interface ContextProvidersProps {
  children?: React.ReactNode;
}

export const ContextProviders = ({ children }: ContextProvidersProps) => {
  const searchQueryHandler = useSearchQuery();
  return (
    <UserContextProvider>
      <DealsProvider>
        <SearchQueryProvider value={searchQueryHandler}>
          <FormsContextProvider>
            <FormsContextU4Provider>
              <FilterContextProvider>
                <FundContextProvider>
                  <ConversationsContextProvider>{children}</ConversationsContextProvider>
                </FundContextProvider>
              </FilterContextProvider>
            </FormsContextU4Provider>
          </FormsContextProvider>
        </SearchQueryProvider>
      </DealsProvider>
    </UserContextProvider>
  );
};
