import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

const routerLink = new HttpLink({
  uri: process.env.REACT_APP_APOLLO_ROUTER_URL,
  credentials: 'include',
});

const UNAUTHENTICATED = 401;

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
  // TODO: allow this code if the client needs to connect with 2.0 another feature besides events.
  // if (Array.isArray(graphQLErrors)) {
  //   graphQLErrors.forEach(graphQLError => {
  //     if (graphQLError.extensions.code === UNAUTHENTICATED) {
  //       window.location.href = `${ERoutePatterns.LOGOUT}`;
  //     }
  //   });
  // } else if (networkError && (networkError as ServerError).statusCode === UNAUTHENTICATED) {
  //   window.location.href = `${ERoutePatterns.LOGOUT}`;
  // }
});

const apolloRouterClient = new ApolloClient({
  link: ApolloLink.from([onErrorLink, routerLink]),
  cache: new InMemoryCache(),
});

export default apolloRouterClient;
