import { PositionMap } from 'app/utils/helpers/formDataMappers';
import { FormType } from 'types/graphql-types';
import { DRLabels } from './DataRoomFormConstants';
import { DataRoomForm } from './DataRoomFormHandler';

export const buildDRForm = (props: DataRoomForm, dealId: string) => {
  const mapYesNoToString = (value: boolean | undefined): string => (value ? 'Yes' : 'No');
  const { dataRoomAccess, finalisVDR, dataRoomLink, note } = props;
  const variables = {
    type: FormType.DRA,
    dealId,
    content: [
      {
        question: DRLabels.DATA_ROOM_ACCESS,
        input: mapYesNoToString(dataRoomAccess),
        key: 'dataRoomAccess',
        value: JSON.stringify(dataRoomAccess),
      },
    ],
    note,
  };

  if (dataRoomAccess) {
    variables.content.push({
      question: DRLabels.FINALIS_VDR,
      input: mapYesNoToString(finalisVDR),
      key: 'finalisVDR',
      value: JSON.stringify(finalisVDR),
    });

    if (!finalisVDR) {
      if (dataRoomLink) {
        variables.content.push({
          question: DRLabels.DATA_ROOM_LINK,
          input: dataRoomLink.trim(),
          key: 'dataRoomLink',
          value: JSON.stringify(dataRoomLink.trim()),
        });
      }
      variables.content.push({
        question: DRLabels.OBSERVATIONS,
        input: DRLabels.INVITATION_SEND,
        key: '',
        value: '',
      });
    }
  }
  return variables;
};

export const DRA_VALUE_INFERENCE_MAP: PositionMap = {
  dataRoomAccess: {
    match: DRLabels.DATA_ROOM_ACCESS,
    value: ({ input }) => input === 'Yes',
  },
  finalisVDR: {
    match: DRLabels.FINALIS_VDR,
    value: ({ input }) => input === 'Yes',
  },
  dataRoomLink: {
    match: DRLabels.DATA_ROOM_LINK,
  },
};
