import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { showModal, hideModal } from 'utils/redux/uiReducer/uiAction';

const useStateModal = (id: string = '') => {
  const dispatch = useDispatch();
  const modalState = useSelector((state: any) =>
    get(state, `ui.modals.${id}`, { show: false, props: {} })
  );

  const show = (props?: any) => {
    dispatch(showModal(id, props));
  };

  const hide = () => {
    dispatch(hideModal(id));
  };

  const globalShow = (key: string, props?: any) => {
    dispatch(showModal(key, props));
  };

  const globalHide = (key: string) => {
    dispatch(hideModal(key));
  };

  return {
    show,
    hide,
    globalShow,
    globalHide,
    modalState,
  };
};

export default useStateModal;
