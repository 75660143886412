import React from 'react';
import { useIntercomModifier } from 'app/utils/hooks/useElementModifier';
import { SideDrawerBaseProps } from './TypesSideDrawer';

interface WithModifierProps extends SideDrawerBaseProps {}

export const withIntercomModifier = <P extends WithModifierProps>(
  Component: React.ComponentType<P>
) => (props: P) => {
  useIntercomModifier(props.show);
  return <Component {...props} />;
};
