import * as React from 'react';
import { IconProps } from './types';

export const GridIcon = ({
  color = 'currentColor',
  width = '10px',
  height = '10px',
  style = {},
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M4 5.5H0.5C0.367392 5.5 0.240215 5.55268 0.146447 5.64645C0.0526784 5.74021 0 5.86739 0 6V9.5C0 9.63261 0.0526784 9.75979 0.146447 9.85355C0.240215 9.94732 0.367392 10 0.5 10H4C4.13261 10 4.25979 9.94732 4.35355 9.85355C4.44732 9.75979 4.5 9.63261 4.5 9.5V6C4.5 5.86739 4.44732 5.74021 4.35355 5.64645C4.25979 5.55268 4.13261 5.5 4 5.5ZM3.5 9H1V6.5H3.5V9ZM9.5 0H6C5.86739 0 5.74021 0.0526784 5.64645 0.146447C5.55268 0.240215 5.5 0.367392 5.5 0.5V4C5.5 4.13261 5.55268 4.25979 5.64645 4.35355C5.74021 4.44732 5.86739 4.5 6 4.5H9.5C9.63261 4.5 9.75979 4.44732 9.85355 4.35355C9.94732 4.25979 10 4.13261 10 4V0.5C10 0.367392 9.94732 0.240215 9.85355 0.146447C9.75979 0.0526784 9.63261 0 9.5 0ZM9 3.5H6.5V1H9V3.5ZM9.5 5.5H6C5.86739 5.5 5.74021 5.55268 5.64645 5.64645C5.55268 5.74021 5.5 5.86739 5.5 6V9.5C5.5 9.63261 5.55268 9.75979 5.64645 9.85355C5.74021 9.94732 5.86739 10 6 10H9.5C9.63261 10 9.75979 9.94732 9.85355 9.85355C9.94732 9.75979 10 9.63261 10 9.5V6C10 5.86739 9.94732 5.74021 9.85355 5.64645C9.75979 5.55268 9.63261 5.5 9.5 5.5ZM9 9H6.5V6.5H9V9ZM4 0H0.5C0.367392 0 0.240215 0.0526784 0.146447 0.146447C0.0526784 0.240215 0 0.367392 0 0.5V4C0 4.13261 0.0526784 4.25979 0.146447 4.35355C0.240215 4.44732 0.367392 4.5 0.5 4.5H4C4.13261 4.5 4.25979 4.44732 4.35355 4.35355C4.44732 4.25979 4.5 4.13261 4.5 4V0.5C4.5 0.367392 4.44732 0.240215 4.35355 0.146447C4.25979 0.0526784 4.13261 0 4 0ZM3.5 3.5H1V1H3.5V3.5Z"
      fill={color}
    />
  </svg>
);
