// Libraries
import React from 'react';
import { IconProps } from './types';

export const EmptyNotesU4StateIcon = ({
  width = '153px',
  height = '153px',
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 153 153"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="76.5" cy="76.4995" r="76" fill="#EDEDF7" />
    <mask id="mask0_2700_13557" maskUnits="userSpaceOnUse" x="0" y="0" width="153" height="153">
      <circle cx="76.5" cy="76.4995" r="76" fill="#FEFEFE" />
    </mask>
    <g mask="url(#mask0_2700_13557)">
      <g filter="url(#filter0_d_2700_13557)">
        <rect
          x="32.5391"
          y="76.4995"
          width="88.6667"
          height="80.4706"
          rx="5.96078"
          fill="#FEFEFE"
        />
        <rect
          x="32.5391"
          y="35.5191"
          width="88.6667"
          height="35.0196"
          rx="5.96078"
          fill="#807DA1"
        />
        <rect
          x="41.4805"
          y="99.5975"
          width="59.6078"
          height="5.96078"
          rx="2.98039"
          fill="#EDEDF7"
        />
        <rect
          x="41.4805"
          y="55.6367"
          width="59.6078"
          height="5.96078"
          rx="2.98039"
          fill="#FEFEFE"
        />
        <rect x="41.4805" y="120.46" width="59.6078" height="5.96078" rx="2.98039" fill="#EDEDF7" />
        <rect
          x="41.4805"
          y="141.323"
          width="59.6078"
          height="5.96078"
          rx="2.98039"
          fill="#EDEDF7"
        />
        <rect
          x="41.4805"
          y="89.1662"
          width="35.7647"
          height="5.96078"
          rx="2.98039"
          fill="#D8D7EA"
        />
        <rect
          x="41.4805"
          y="45.2054"
          width="35.7647"
          height="5.96078"
          rx="2.98039"
          fill="#D8D7EA"
        />
        <rect
          x="41.4805"
          y="110.029"
          width="35.7647"
          height="5.96078"
          rx="2.98039"
          fill="#D8D7EA"
        />
        <rect
          x="41.4805"
          y="130.892"
          width="35.7647"
          height="5.96078"
          rx="2.98039"
          fill="#D8D7EA"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_2700_13557"
        x="12.5391"
        y="19.5191"
        width="128.667"
        height="161.451"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_2700_13557"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="8" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.254902 0 0 0 0 0.305882 0 0 0 0 0.505882 0 0 0 0.1 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2700_13557" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2700_13557"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
