import styled from 'styled-components';
import { ThemeAsProp } from '@dealsyte/poki';

const rowHeight = 40;
const headerRowHeight = (rowHeight * 5) / 6;

export const SettingsDescription = styled.p`
  padding-bottom: 50px;
  font-size: ${({ theme }) => theme.fontSizes.regular};
`;

export const SideSection = styled.div`
  padding-bottom: 60px;
`;

export const TableContainer = styled.div`
  padding-bottom: 30;
`;

export const TableRow = styled.div<ThemeAsProp>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${rowHeight}px;
  padding: 0 20px;
  font-weight: 200;
  font-size: ${({ theme }) => theme.fontSizes.small};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.rowSeparator};
`;

export const TitleRow = styled(TableRow)`
  display: flex;
  justify-content: space-between;
  background-color: #fafbfc;
  height: ${headerRowHeight}px;
  box-shadow: inset 0 7px 9px -8.5px rgba(161, 161, 161, 0.2);
  user-select: none;
  cursor: default;
`;
