import React, { Suspense, lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { ERoutePatterns } from 'app/core-tools/due-diligence/types/types';
import FsoSignatureCompletedPage from 'app/core-tools/due-diligence/vdr/file-browser/components/fso-signature/FsoSignatureCompletedPage/FsoSignatureCompletedPage';

const Logout = lazy(() => import('../../auth/Logout/Logout'));
const ProxyLogin = lazy(() => import('../../auth/ProxyLogin/ProxyLogin'));
const Auth0Login = lazy(() => import('../../auth/Auth0/Auth0Login'));
const SignUp = lazy(() => import('../../auth/SignUp/SignUp'));
const PasswordRecovery = lazy(() => import('../../auth/PasswordRecovery/PasswordRecovery'));

function PublicRoutes() {
  return (
    <Suspense fallback={<div />}>
         
      <Switch>

        <Route path={ERoutePatterns.PROXY_LOGIN} component={ProxyLogin} />

        <Route path={ERoutePatterns.RECOVERY} component={PasswordRecovery} />

        <Route path={`${ERoutePatterns.SIGNUP}/:signupToken`} component={SignUp} />
        
        <Route path={ERoutePatterns.LOGIN} component={Auth0Login} />

        <Route path={ERoutePatterns.LOGOUT} component={Logout} />

        <Route path={ERoutePatterns.SIGNATURE_COMPLETED} component={FsoSignatureCompletedPage} />

        <Redirect to={ERoutePatterns.LOGIN} />

      </Switch>
    </Suspense>
  );
}

export default PublicRoutes;
