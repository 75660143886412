// vendors
import * as yup from 'yup';

const collaborationTypeValidationSchema = yup.object().shape({
  comment: yup
    .string()
    .required('Please provide a comment.')
    .test('empty-check', 'Please provide a comment', comment => !comment || comment.length > 1),
  collaborateType: yup.string(),
});

export default collaborationTypeValidationSchema;
