/* eslint-disable camelcase */
import React, { useContext } from 'react';
import useStateModal from 'app/utils/hooks/useStateModal';
import { Button, HStack, Modal, ModalFooter, Text } from 'app/common/components';
import { KYCHandlerContext } from '../KYCFormHandler';
import { KYC_MODAL_LABELS, STEPS } from '../KYCFormConstants';
import { CloseIcon, QuestionMarkIcon } from '../../../../../../common/icons';
import * as S from './KYCSubmitModalStyled';

export const KYCSubmitModal = () => {
  const { previousStep, setCurrentStep } = useContext(KYCHandlerContext);
  const { modalState, hide } = useStateModal('successfullKYCSubmitModal');
  const { globalShow } = useStateModal();

  const closeHandler = () => {
    hide();
  };

  const handleAddCounterparty = () => {
    setCurrentStep(STEPS.COUNTERPARTY);
    globalShow('DRKYCForm');
    closeHandler();
  };

  return (
    <Modal visible={modalState.show} showCloseButton={false} onClose={closeHandler}>
      {({ closeWithPrompt }) => (
        <>
          <S.ModalHeader>
            <HStack justifyContent="space-between" alignItems="center">
              <S.Title>
                <QuestionMarkIcon
                  width={26}
                  height={26}
                  style={{ marginRight: '18px', color: '#2C6EF2' }}
                />
                {previousStep === STEPS.CLIENT
                  ? KYC_MODAL_LABELS.CLIENT_TITLE
                  : KYC_MODAL_LABELS.COUNTERPARTY_TITLE}
              </S.Title>
              <S.ModalCloseButton onClick={closeWithPrompt}>
                <CloseIcon />
              </S.ModalCloseButton>
            </HStack>
          </S.ModalHeader>
          <S.ModalBody>
            <Text mb={24} style={{ marginBottom: '0px', lineHeight: '150%' }}>
              {previousStep === STEPS.CLIENT
                ? KYC_MODAL_LABELS.CLIENT_TEXT
                : KYC_MODAL_LABELS.COUNTERPARTY_TEXT}
            </Text>
          </S.ModalBody>
          <ModalFooter>
            <HStack justifyContent="flex-end" spacing={16}>
              <Button onClick={closeWithPrompt} className="tertiary">
                Not now
              </Button>
              <Button onClick={handleAddCounterparty}>Add counterparty</Button>
            </HStack>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
